import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import { useDispatch } from "react-redux";
import styles from './images.module.sass'
import { extarctImageFromUrlAction } from "../../redux/action/common/commonAction";
import Skeleton from '@mui/material/Skeleton';

const SnapShot = ({ url, id, placeholder, type, alt, width, height, borderRadius }) => {
  
    const imgRef = useRef(null);
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)

    const lazyLoadImages = (url, id) => {
        const observer = new IntersectionObserver(
            (entries) => {
              entries.forEach(async(entry) => {
                if (entry.isIntersecting) {
                  let res = await extractImageFromCdn(url, id);
                  observer.unobserve(entry.target); 
                }
              });
            },
            { threshold: 0.1 }
          );
      
        if (imgRef.current) {
            observer.observe(imgRef.current);
        }
      
        return () => observer.disconnect()
    }

    const extractImageFromCdn = async(url, id) => {
        if(url !== null && url !== undefined && url.includes('cdn')){
            try {
                setLoading(true)
                const response = await dispatch(extarctImageFromUrlAction(url))
                const logo = document.getElementById(id)
                if(logo){
                    logo.src = response
                }
            }catch (error){
                const logo = document.getElementById(id)
                if(logo){
                    logo.src = placeholder
                    setLoading(false)
                }
            }finally{
                setLoading(false)
            }
        }else{
            const logo = document.getElementById(id)
            if(logo){
                setLoading(false)
                logo.src = placeholder
            }
        }
    }

    useEffect(() => {
        lazyLoadImages(url, id)
        // extractImageFromCdn(url, id)
    }, [url]);

  return (
    <div className={cn(styles.snapShot)} ref={imgRef}>
        {type === 'Images' && <img id={id} alt={alt} width={width} height={height} className={cn(styles.images, {[styles.active] : !loading})}/>}
        {type === 'Videos' && <video id={id} alt={alt} width={width} height={height} className={cn(styles.images, {[styles.active] : !loading})} controls/>}
        {loading && <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : (borderRadius === 16 ? '16px' : '8px')}} animation="wave" variant='square' width={width} height={height}/>}
    </div>
  );
};

export default SnapShot;