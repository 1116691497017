import { START_BULK_UPLOAD, BULK_UPLOAD_SUCCESS, BULK_UPLOAD_FAILURE, RESET_BULK_UPLOAD_STATE } from '../../actions/itemsNbundles/bulkFileUploadAction';

const initialState = {
  loading: false,
  economy: null,
  completedCalls: 0,
  failedCalls: 0,
  totalCalls: 0,
  error: null,
};

export const itemsNBundleUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_BULK_UPLOAD:
      return {
        ...state,
        economy: action.economy,
        completedCalls: 0,
        loading: true,
        error: null,
      };
    case BULK_UPLOAD_SUCCESS:
      return {
        ...state,
        totalCalls: action.totalLength,
        completedCalls: state.completedCalls + action.payloadLength
      };
    case BULK_UPLOAD_FAILURE:
      return {
        ...state,
        totalCalls: action.totalLength,
        completedCalls: state.completedCalls + action.payloadLength,
        failedCalls: state.failedCalls + action.payloadLength,
        error: action.error
      };
    case RESET_BULK_UPLOAD_STATE: {
      return {
        ...initialState
      }
    }
    default:
      return state;
  }
};
