import React, {useState, useEffect, useContext} from 'react'
import cn from 'classnames'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styles from './getdocuments.module.sass'
import Card from '../../../../components/Card'
import addIcon from '../../../../media/images/icons/light.png'
import Form from '../../../../components/Form'
import { ToastContainer } from 'react-toastify';
import Loader from '../../../../components/Loaders/LoaderProgress'
import DocumentTable from '../../../../components/AppSettingComponent/DocumentComponent/Table'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import { getAllDocumentsAction } from '../../../../redux/action/AppSettings/Document/documentAction'
import { Skeleton } from '@mui/material';
import EmptyStates from '../../../../components/EmptyStates'
import SkeletonTable from '../../../../components/Skeleton/TableSkeleton';
import EmptyStateSkeleton from '../../../../components/Skeleton/EmptyStateSkeleton'
import Control from './Filters/Control'
import Navigation from '../../../../components/Header/SecondaryNavigation';

const GetDocuments = ({className}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {appid} = useParams()
  // Filters
  const filters = {}
  // Search
  const [search, setSearch] = useState(null)
  // Sort
  const sortList = [{id: 1, name: 'Document Name', value: 'name'}, {id: 2, name: 'Created At', value: 'created_at'}, {id: 3, name: 'Updated At', value: 'updated_at'}]
  const [sortValue, setSortValue] = useState({name: 'updated_at', value: 0})
  const [selectedSort, setSelectedSort] = useState('updated_at')
  const [sort, setSort] = useState([{id: 1, name: 'Ascending'}, {id : 2, name : 'Descending'}])
  const [sortIndex, setSortIndex] = useState(0)
  // Pagination
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)

  const getDocumets = async(search) => {
    let document = {
      projectId : appid,
      // Search
      ...(search && search !== null && search !== '' && {search : search}),
      //Sort
      sortOrder : (sortIndex == 0 ? 'asc' : 'desc'),
      sortField : selectedSort,
      // Pagination
      offset: ((page-1)*limit),
      limit: limit
    }
    if(!search){
      await dispatch(getAllDocumentsAction(document, configureSkeleton))
    }else{
      await dispatch(getAllDocumentsAction(document, configureSkeleton))
    }
  }

  useEffect(() => {
    if(selectedSort !== null){
      getDocumets(search)
    }
  }, [page, selectedSort])

  let documents = useSelector((state) => {
    return state.document.documents
  })

  let isLoading = useSelector((state) => {
    return state.document.isLoading
  })

  let totalDocument = useSelector((state)=>{
    return state.document.totalCount
  })

  let isFiltered = useSelector((state)=>{
    return state.document.isFiltered
  })

  const [showSkeleton, setShowSkeleton] = useState(true)

  const configureSkeleton = () => {
    setShowSkeleton(true)
    setTimeout(() => {
      setShowSkeleton(false)
    }, 1000)
  }

  const onSearch = (search) => {
    if(search == ''){
      setSearch(null)
      getDocumets(null)
    }else{
      setSearch(search)
      getDocumets(search)
    }
  }

  const handlePageChange = (event, value)  => {
    setPage(value)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }


  // Loader
  if(isLoading){
    return(
      <>
        <div className={styles.loader}>
          <Loader />
        </div>
      </>
    )
  }

  return(
    <>
      <Navigation type='Documents' search={search} setSearch={onSearch} data={sortList} setPage={setPage} sortIndex={sortIndex} setSortIndex={setSortIndex} selected={selectedSort} setSelected={setSelectedSort} sort={sort} setSort={setSort} value={sortValue} setValue={setSortValue}/>
      <Card layOut={`Get`} className={styles.card} classCardHead={styles.head}>

        <div className={styles.wrapper}>
          {showSkeleton && documents?.length === 0 &&
            <EmptyStateSkeleton />
          }
          {!showSkeleton && !isFiltered && documents?.length === 0 &&
            <EmptyStates route={`/create-document/${appid}`} type="Documents" />
          }
          {showSkeleton && documents?.length > 0 && 
            <SkeletonTable rows={documents.length} column={3} controls={1} type={['recatangle', 'recatangle', 'circular']} colWidth={[36, 100, 36]} colHeight={[36, 15, 36]} />
          }
          {!showSkeleton && !isFiltered && documents?.length > 0 &&
            <DocumentTable items={documents} title="Last edited" />
          }
          {!showSkeleton && isFiltered &&
            (documents?.length > 0 ?
              <DocumentTable items={documents} title="Last edited" />
                :
              <div className={styles.message}>No Result Found</div>
            )
          }
        </div>
          {documents?.length > 0 &&
            <div className={styles.pagination}>
              <Stack spacing={2}>
                <Pagination page={page} renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    sx={{
                      '&.MuiPaginationItem-root': {
                        minWidth: '28px',
                        height: '28px',
                        fontSize: '12px'
                      },
                      '&.Mui-selected': {
                        color: 'rgb(255, 255, 255)',
                        fontWeight : 'bold',
                        border : '1px solid rgb(42, 133, 255)',
                        background : 'rgba(42, 133, 255)'
                      },
                      '&.MuiPaginationItem-page.Mui-selected:hover' : {
                        color: 'rgb(255, 255, 255)',
                        fontWeight : 'bold',
                        border : '1px solid rgb(42, 133, 255)',
                        background : 'rgba(42, 133, 255)'
                      }
                    }}
                  />
                )} siblingCount={1} count={Math.ceil(totalDocument/limit)} color="primary" onChange={handlePageChange} />
            </Stack>
          </div>
        }
      </Card>
      <div className="divider"></div>
      <div className={styles.msg}>
        <p className={styles.alert}>Learn more about Document <a className={styles.infoLink} href="#" target="_blank">Click Here</a></p>
      </div>
    </>
  )

}

export default GetDocuments