import React, { useEffect, useState } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../Checkbox";
import Icon from "../../../../Icon";
import Control from "./Control/index";
import { useParams } from "react-router-dom";
import Currency from '../../../../../media/images/placeholders/Currency.png'
import moment from "moment";
import { useDispatch } from "react-redux";
import { extarctImageFromUrlAction } from "../../../../../redux/action/common/commonAction";
import SnapShot from "../../../../Images";
/* Deleted Schedule, Modal, Modal Products and Actions */

const Row = ({ item, value, onChange }) => {
    
    const {appid} = useParams()

    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());
    
    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                    <div className={styles.item}>
                        <div className={styles.preview}>
                            <img id={`icon_${item.id}`} alt="icon" />
                            <SnapShot id={`icon_${item?.id}`} url={item?.currency?.iconUrl} placeholder={Currency} type='Images' width={36} height={36}/>
                        </div>
                    </div>
                </div>
                <div className={styles.col}>{(item.currency?.type == 'Virtual' || item.currency?.type == 'virtual') ? <div className={styles.itemName}>{item.currency?.name}</div> : <><div className={styles.itemName}> {item.currency?.name} {item?.type ? `(${item?.type?.substring(3)?.charAt(0)?.toUpperCase() + item?.type?.slice(4)})` : ''}</div></>}</div>
                <div className={styles.col}>{item.currency?.currencyId}</div>
                <div className={styles.col}>{item.currency?.code}</div>
                <div className={styles.col}>
                    {(item.currency?.type == 'Virtual' || item.currency?.type == 'virtual') ? <div className={styles.Virtual}>Virtual</div> : <div className={styles.Real}>RMG</div>}
                </div>
                <div className={styles.col}>
                    {item.balance ? Number(item.balance).toFixed(2) : '-'}
                </div>
                <div className={styles.col}>
                    {moment(item.updatedAt).format('DD-MM-YYYY hh:mm A')}
                </div>
            </div>
        </>
    );
};

export default Row;
