import React, { useEffect, useState, useRef, useContext} from "react";
import cn from "classnames";
import axios from "axios";
import customAxios from "../../../../../../utils/interceptor";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { WithContext as ReactTags } from "react-tag-input";
import { useDispatch, useSelector } from "react-redux";
import styles from "./StoreInformation.module.sass";
import Card from "../../../../../../components/Card";
import TextInput from "../../../../../../components/TextInput";
import TextArea from "../../../../../../components/TextArea";
import Tooltip from "../../../../../../components/Tooltip";
import PlatformDropdown from '../../../../../../components/MultiselectDropDown/PlatformDropdown'
import LocationDropdown from '../../../../../../components/MultiselectDropDown/LocationDropdown';
import {ReactComponent as AddButtonIcon} from '../../../../../../media/images/icons/add_button.svg';
import {ReactComponent as AddMetaIcon} from '../../../../../../media/images/icons/add_meta.svg';
import {ReactComponent as RemoveIcon} from '../../../../../../media/images/icons/remove.svg';
import Bundle from '../../../../../../media/images/placeholders/Bundles.png'
import CommonDropdown from './CommonDropdown'
import ProgressionLevelMultiSelect from '../../../../../../components/Dropdown/ProgressionLevelTypeableDropdown';
import { BASE_URL, GET_APPS, GET_STORES, GET_PROGRESSION_SYSTEM, GET_ITEMS, GET_BUNDLES } from '../../../../../../resources/APIEndpoints';
import Control from "../Control";
import ContentTable from '../../../../../../components/BuildComponents/EconomyComponent/StoreComponent/ContentTableLayout'
import Checkbox from "../../../../../../components/Checkbox"
import ItemDropdown from "./ItemDropdown"
import { getAllItemAction } from "../../../../../../redux/action/builds/economy/items/itemAction";
import { editStoreAction } from '../../../../../../redux/action/builds/economy/stores/storeAction';
import { getAllBundlesAction } from '../../../../../../redux/action/builds/economy/bundles/bundleAction';
import {getAllCurrencyAction} from '../../../../../../redux/action/builds/economy/currency/currencyAction'
import TagInputWithDropdown from "../../../../../../components/TagInputWithDropdown";
import Toast from "../../../../../../components/Toast";
import Store from '../../../../../../media/images/placeholders/Store.png'
import FileUploadSidebar from "../../../../../../components/FileUploadSidebar";
import {addMediaAction, getAllMediaAction } from "../../../../../../redux/action/AppSettings/Media/mediaAction";
import TooltipTitle from "../../../../../../Tooltip/TooltipTitle";
import JSONMetaDataValueInput from "../../../../../../components/MetaInput";
import app,{increaseUnreadCountForAllUsers} from "../../../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";
import GlobalStateContext from "../../../../../../components/Context/GlobalStates/GlobalStateContext.js";
import { extarctImageFromUrlAction } from "../../../../../../redux/action/common/commonAction.js";
import SnapShot from "../../../../../../components/Images";


const StoreInformation = ({className,isChanged, setIsChanged,onClose}) => {

    const globalStates = useContext(GlobalStateContext)
    const {id} = useParams()
    const [platform, setPlatform] = useState([])
    // const [platId, setPlatId] = useState([])
    const [platformOption, setPlatformOption] = useState([])
    const dispatch = useDispatch()
    const [locations, setLocations] = useState([])
    const [locationOption, setLocationOption] = useState([])
    const navigate = useNavigate()
    const {appid} = useParams()
    const [allCurrency, setAllCurrency] = useState([])
    const [allItems, setAllItems] = useState([])
    const [getAllBundle, setAllBundle] = useState([])
    const [items, setItems] = useState([])
    const [unlockItemId, setUnlockItemId] = useState()
    const [lockItemErr, setLockItemErr] = useState(false)
    const [itemIcon, setItemIcon] = useState(null)
    const [lockItems, setLockItems] = useState()
    const [lockProgression, setLockProgression] = useState()
    const [tags, setTags] = useState([]);
    const [PSOptions, setPSOptions] = useState([])
    const [PS, setPS] = useState([])
    const [lockPSErr, setLockPSErr] = useState(false)
    const placeholder = Store;
    const [{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'});
    const ref = useRef()
    const ref1 = useRef()
    const lockBy = [{id: 1, title : "Item/Bundle", active : lockItems }, {id : 2, title : "Progression System",  active : lockProgression}]
    const [isCalled, setIsCalled] = useState(false)
    const [logo1, setLogo1] = useState([])
    const KeyCodes = { comma: 188, enter: 13 };
    const delimiters = [KeyCodes.comma, KeyCodes.enter];
    const handleDelete = (i) => {
        setTags(tags.filter((tag, index) => index !== i));
    };
    
    const handleAddition = (tag) => {
        if(tags.length < 10){
            setTags([...tags, tag]); 
        }else{
            toast.warning(<Toast type='Warning' messages='Only 10 tags are allowed'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        }
    };
    
    const handleDrag = (tag, currPos, newPos) => {
        const newTags = [...tags].slice();
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
        
        setTags(newTags);
    };
    
    const handleTagClick = (index, event) => {
        event.stopPropagation();
    };
    
    const onClearAll = () => {
        setTags([]);
    };
    
    const onTagUpdate = (i, newTag) => {
        const updatedTags = tags.slice();
        updatedTags.splice(i, 1, newTag);
        setTags(updatedTags);
    };
    
    const [storeName, setStoreName] = useState()
    const [storeID, setStoreID] = useState()
    const [storeDescription, setStoreDescription] = useState()
    const [metaData, setMetaData] = useState([{key : '' , value : null}])
    const [Category, setCategory] = useState([{catName : '' , catID : '', ID:'',categoryIcon:'',storeContent:[]}])
    const [storeIcon, setStoreIcon] = useState()

    const [checkObject, setCheckObject] = useState()
    const [selectedItemId, setSelectedItemId] = useState([])

    const addMetaData = () => {
        const metaDataField = []
        metaDataField.push(...metaData, {key : '' , value : null})
        setMetaData(metaDataField)
    }

    const addCategory = () => {
        const CategoryField = []
        CategoryField.push(...Category, {catName : '' , catID : '', ID:'',categoryIcon:'',storeContent:[]})
        setCategory(CategoryField)
        const IconSrc = []
        IconSrc.push(...logo1, {src1: placeholder , alt1: 'Upload an Image'})
        setLogo1(IconSrc)
        sessionStorage.setItem('contents', JSON.stringify(CategoryField))

    }

    const removeCategory = (index) => {
        let removeCatField = [...Category]
        let itemToRemove = removeCatField.filter((e,i,c)=>(i == index))
        let hasArcBoolean = typeof itemToRemove[0].archive === 'boolean';
        if (hasArcBoolean) {
            removeCatField[index].archive=true;
            Array.isArray(removeCatField[index].storeContent) && removeCatField[index].storeContent.map((e,i)=>{
                let ContToRemove = removeCatField[index].storeContent.filter((q,c)=>(i == c))
                let hasContBoolean = typeof ContToRemove[0].archive === 'boolean';
                if (hasContBoolean) {
                    e.archive=true;
                    e.priceList&& e.priceList.map((ePrice,iPrice)=>{
                        let PriceToRemove = e.priceList.filter((p,o)=>(iPrice == o))
                        let hasPriceBoolean = typeof PriceToRemove[0].archive === 'boolean';
                        if (hasPriceBoolean) {
                            ePrice.archive=true;
                        }
                        else{
                            e.priceList.filter((r,x)=>(iPrice!=x))
                        }
                    })
                }
                else{
                    removeCatField[index].storeContent.filter((eCont,iCont)=>(iCont!=i))
                }                
            })
        }
        else{
            removeCatField= removeCatField.filter((e,i,c)=>(i!=index))
            }
        setCategory(removeCatField)
        sessionStorage.setItem('contents', JSON.stringify(removeCatField))
    }


    const removeMetaData = (index) => {
        let removeField = metaData
        let metaFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setMetaData(metaFields)
    }

    const onChangeKey = (e, index) => {
        let keys = [...metaData]
        keys[index].key = e.target.value
        setMetaData(keys)
    }

    const onChangeValue = (e, index) => {
        let values = [...metaData]
        values[index].value = e.target.value
        setMetaData(values)
    }

    const onChangeCategoryID = (e, index) => {
        let val = [...Category]
        val[index].catID = e.target.value
        setCategory(val)
        sessionStorage.setItem('contents', JSON.stringify(val))
    }
    const onChangeCategoryName = (e, index) => {
        let val = [...Category]
        val[index].catName = e.target.value
        setCategory(val)
        sessionStorage.setItem('contents', JSON.stringify(val))
    }
    const cancel = () => {
        setIsChanged(false)
        navigate(`/store/${appid}`)
    }

    const Cancel = () => {
        setStoreIcon()
        setLogo({src: placeholder , alt: 'Upload an Image'})
        ref.current.value = ''
    }

    const CancelCategoryIcon = (i) => {

        const refLog=[...logo1];

        Category[i].categoryIcon= null
        refLog[i].src1=placeholder;
        refLog[i].alt1='Upload an Image';
        setLogo1(refLog);
        sessionStorage.setItem('contents', JSON.stringify(Category))
    }

    const onChangeLevel = (value, index) => {
        let unlockLevelSystem = [...PS]
        unlockLevelSystem[index].lockedLevel = parseInt(value)
        setPS(unlockLevelSystem)
    }
    
    const onChangeStoreName = (e) => {
        if(e.target.value == ''){
            setStoreName('')
        }else{
            errors.nameError = ''
            setStoreName(e.target.value)
        }
    }

    const onChangeStoreID = (e) => {
        if(e.target.value == ''){
            setStoreID('')
        }else{
            errors.idError = ''
            setStoreID(e.target.value)
        }
    }

    const onChangeStoreDescription = (e) => {
        setStoreDescription(e.target.value)
    }
    const onTagInput = () => {
        if(document.getElementById('tagsInput')){
            document.getElementById('tagsInput').focus()
        }
    }

    const uploadLogo = async(e) =>{
      
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        }

        var file = e.target.files[0];
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}`)
        setStoreIcon(res.data.getUrl);
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
            toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        })
        .catch(function (error) {
            toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        });
    }


    const onSelectedCategoryLogo = async(e,i) =>{
        
        logo1[i].src1 = e
        logo1[i].alt1 = e
        const tempCategory=[...Category]
        tempCategory[i].categoryIcon=e
        setCategory(tempCategory)
    }

    const progressToastId = useRef(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadingMedia, setUploadingMedia] = useState([]);


    // A) Add item
    const addUploadingItem = (file) => {
        const newItem = { fileName: file.name, progress: 0 };
        setUploadingMedia((prev) => [...prev, newItem]);
        return newItem;
    };
    
    // B) Update item
    const updateUploadingItem = (fileName, progress) => {
        setUploadingMedia((prev) =>
        prev.map((item) =>
            item.fileName === fileName ? { ...item, progress } : item
        )
        );
    };
    
    // C) Remove item
    const removeUploadingItem = (fileName) => {
        setUploadingMedia((prev) =>
        prev.filter((item) => item.fileName !== fileName)
        );
    };


    // Show a toast if one doesn’t already exist
  const startProgressToast = () => {
    // if (!progressToastId.current) {
    //   progressToastId.current = toast(
    //     <Toast type="Info" messages="Starting upload..." />,
    //     {
    //       autoClose: false,
    //       closeOnClick: false,
    //       draggable: false,
    //       progress: 0,
    //     }
    //   );
    // }
  };


  const handleUploadProgress = (progressEvent,name) => {
    // if (!progressToastId.current) return; // no toast to update yet

    const { loaded, total } = progressEvent;
    const percent = Math.floor((loaded * 100) / total);
    updateUploadingItem(name, percent);
    // const fraction = percent / 100; // toastify expects a fraction (0-1)

    // toast.update(progressToastId.current, {
    //   progress: fraction,
    //   render: (
    //     <Toast
    //       type="Info"
    //       messages={`Uploading file... ${percent}%`}
    //     />
    //   ),
    // });
  };

  const dismissProgressToast = () => {
    // if (progressToastId.current) {
    //   toast.dismiss(progressToastId.current);
    //   progressToastId.current = null;
    // }
  };
    

    const uploadCategoryLogo = async(e,i,getMedia) =>{
        
        const tempLogo=[...logo1]
        if(e.target.files[0]) {
            tempLogo[i].src1 = URL.createObjectURL(e.target.files[0]);
            tempLogo[i].alt1 = e.target.files[0].name;
        }
        setLogo1(tempLogo)

        // Converting Image to base64 and then converting to binary to upload
        var file1 = e.target.files[0];
        startProgressToast();
        addUploadingItem(file1);
        const file_binary1 = await convertbase64(file1)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file1.type}&projectId=${appid}&category=${`icons`}&fileName=${file1.name}`)

        const tempCategory=[...Category]
        tempCategory[i].categoryIcon=res.data.getUrl;
        setCategory(tempCategory);
        sessionStorage.setItem('contents', JSON.stringify(tempCategory))

        var config1 = {
            headers : {'Content-Type' : `${file1.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary1),
            onUploadProgress: ((z)=> handleUploadProgress(z,e.target.files[0].name)),
        }
    
        axios(config1)
        .then(function (response) {
            let setSidebar=true
            if(setSidebar){
                let uploadLogo = {
                    projectId : appid,
                    contents : [{
                        contentType : file1.type,
                        fileName : file1.name,
                        category : "icons",
                        url : res.data.getUrl,
                        fileSize : file1.size/1024
                    }]
                }
                dispatch(addMediaAction(uploadLogo, null, null, appid, 0, null,'icons'))
                // setSidebar(false)
            }
            removeUploadingItem(e.target.files[0].name);
            setUploadProgress(0);
            getMedia()
            setTimeout(()=>{
                dismissProgressToast();
            },[1500])
            toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        })
        .catch(function (error) {
            toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        });
        
    }

    const onLockBy = (e, title) => {
        if(e.target.checked){
            if(title == 'Item/Bundle'){
                setLockItems(true)
            }else{
                setLockProgression(true)
            }
        }else{
            if(title == 'Item/Bundle'){
                setLockItems(false)
            }else{
                setLockProgression(false)
            }
        }
    }

    const convertbase64 = (file) => {
        return new Promise((resolve, err)=>{
        const fileReader = new FileReader();
        fileReader.onload = (eve) =>{
            resolve(fileReader.result)
        }
        fileReader.readAsDataURL(file)
        })
    }

    function convertDataURIToBinary(dataURI) {
        var BASE64_MARKER = ';base64,';
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));
              
        for(var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }


    //get optional Data


    const getOptionalData = async() => {
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await axios.get(`${BASE_URL}/master-data`, headers)
        setLocationOption(res.data.data.countryMaster)

        let Platforms = []
        res.data.data.gamePlatforms && res.data.data.gamePlatforms.map((e, i) =>{
            Platforms.push({id : e.id, name : (e.platformName !== 'ios' ? e.platformName.charAt(0).toUpperCase() + e.platformName.slice(1) : e.platformName === 'ios' ? 'iOS' : '-')})
        })
        setPlatformOption(Platforms)
       
    }

    // useEffect(()=> {
        // platformOption&& platformOption.map((e)=>{         
        //     if (e.id==platId) {
        //         setPlatform(e.name)
        //     }
        // })
    // }, [platId,platformOption])


    const getPS = async() => {
        let items = {
            projectId : appid
        }
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await customAxios.post(BASE_URL + GET_PROGRESSION_SYSTEM, items, headers)
        let progressionSystem = res.data.data.levelDetails
        progressionSystem && progressionSystem.forEach((e,i) => {
            return setPSOptions(prevState => [...prevState, {name : e.name, id : e.id, levelSystemLevelMapping:e.levelSystemLevelMapping}])
        })
    }


    const getAllCurrencyData = () => {
        let getAllCurrency = {
            projectId : appid,
        }
        dispatch(getAllCurrencyAction(getAllCurrency))
    }

    function customizeCurrencyData (index){
        if(currenciesData && currenciesData.length > 0){
            const updatedArray = currenciesData && currenciesData.map((ele) => {
                const matchObj = Category[index]&& Category[index].storeContent.find(element => element.currencyId == ele.id)
                if(matchObj){
                    return {
                        ...ele,
                        quantity : matchObj.quantity,
                        status : true
                    }
                }else{
                    return {
                        ...ele,
                        quantity : null,
                        status : false
                    }
                }
            })
            let updatedArr=allCurrency;
            updatedArr[index]=updatedArray
            setAllCurrency(updatedArr)
        }
    }

        // Get Item API Call
    const [itemOptions, setItemOption] = useState()
    const [lockByOptionType, setLockByOptionType] = useState(null)

    const getItems = async() => {
        let items = {
            projectId : appid,
            offset: 0,
            limit: 1000
        }
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await customAxios.post(BASE_URL + GET_ITEMS, items, headers)
        return res.data.data.items
    }

    const getBundles = async() => {
        let bundles = {
            projectId : appid,
            offset: 0,
            limit: 1000
        }
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await customAxios.post(BASE_URL + GET_BUNDLES, bundles, headers)
        return res.data.data.bundles
    }

    const getLockByOptions = async() => {
        try {
            const [items, bundles] = await Promise.all([getItems(), getBundles()])
            const lockByOptions = [...items && items.map(x => ({...x, lockByOptionType: 'Item'})), ...bundles && bundles.map(x => ({...x, lockByOptionType: 'Bundle'}))]
            setItemOption(lockByOptions)
        }catch{
            console.log('Please Refresh')
        }finally{
            console.log('Data Fetched')
        }
    }

    useEffect(() => {
        getLockByOptions()
    }, [])

    const getAllItemsData = () => {
        let items = {
            offset: ((itemPage-1)*itemLimit),
            limit:itemLimit,
            projectId : appid
        }
        dispatch(getAllItemAction(items))
    }

    const getAllBundlesData = () => {
        let bundles = {
            offset: ((bundlePage-1)*bundleLimit),
            limit:bundleLimit,
            projectId : appid
        }
        dispatch(getAllBundlesAction(bundles))
    }

    function customizeItemData(index) {
        if(itemsData && itemsData.length > 0){
            const updatedArray = itemsData && itemsData.map((value) => {
                const matchObj = Category[index]&&Category[index].storeContent.find(element => element.itemId == value.id)
                if(matchObj){
                    return {
                        ...value,
                        quantity : matchObj.quantity,
                        status : true
                    }
                }else{
                    return {
                        ...value,
                        quantity : null,
                        status : false
                    }
                }
            })
            let updatedArr=allItems;
            updatedArr[index]=updatedArray
            setAllItems(updatedArr)
        }
    }
    function customizeBundleData (index) {
        if(bundleData && bundleData.length > 0){
            const updatedArray = bundleData && bundleData.map((value) => {
                const matchObj = Category[index]&&Category[index].storeContent.find(element => element.bundleId == value.id)
                if(matchObj){
                    return {
                        ...value,
                        quantity : matchObj.quantity,
                        status : true
                    }
                }else{
                    return {
                        ...value,
                        quantity : null,
                        status : false
                    }
                }
            })
            let updatedArr=getAllBundle;
            updatedArr[index]=updatedArray
            setAllBundle(updatedArr)
        }
    }

    const [itemPage,setItemPage]=useState(1);
    const [itemLimit,setItemLimit]=useState(10);
    const [bundlePage,setBundlePage]=useState(1);
    const [bundleLimit,setBundleLimit]=useState(10);

    let currenciesData = useSelector((state) => {
        return state.currencies.currency
    })

    let itemsData = useSelector((state) => {
        return state.item.items
    })

    let bundleData = useSelector((state) => {
        return state.bundles.bundles
    })


    useEffect(() => {
        setPS(PS)
    }, [PS])

    useEffect(() => {
        for (let index = 0; index < Category.length; index++) {
            customizeBundleData(index)
        }        
    }, [bundleData])


    useEffect(() => {
        for (let index = 0; index < Category.length; index++) {
            customizeItemData(index)
        }
    }, [itemsData, isCalled])

    useEffect(()=>{
        for (let index = 0; index < Category.length; index++) {
            customizeCurrencyData(index)
        }
    }, [currenciesData, isCalled])


    useEffect(()=>{
        // getAllBundlesData()
        // getAllItemsData()
        getAllCurrencyData()
        getItemById()
    }, [])

    useEffect(()=>{
        getAllItemsData()
    },[itemPage])

    useEffect(()=>{
        getAllBundlesData()
    },[bundlePage])

    useEffect(()=> {
        getPS()
        setLocations([])
        setPlatform([])
        getOptionalData()
    }, [])

    // useEffect(() => {
    //     for (let index = 0; index <= Category.length; index++) {
    //         customizeItemData(index)
    //     }
    // }, [itemsData,Category])

    // useEffect(() => {
    //     for (let index = 0; index <= Category.length; index++) {
    //         customizeBundleData(index)
    //     }        
    // }, [bundleData,Category])


    const [errors, setErrors] = useState({nameError : '', idError : ''})

    const validation = (data) => {
        const error = {}
        let isValid = true

        if (data.stores[0].name == '') {
            error.nameError = 'Display Name is required';
            isValid = false;
            const element = document.getElementById('storeName');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        }
      
        if (data.stores[0].storeId == '') {
            error.idError = 'Store ID is required';
            isValid = false;
            if(data.stores[0].name != ''){
                const element = document.getElementById('storeId');
                const offset = 140;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        setErrors(error);

        if(isValid){
            return true
        }
    }



    function compareThisCategory(edtCategory,checkObj) {
        
        let contentCheck = ((checkObj && compareContentArrays(edtCategory?.storeContent,checkObj?.storesContents))||(checkObj.storesContents==null && edtCategory.storeContent.length==0))
        if (
            (edtCategory?.categoryIcon==checkObj?.iconUrl|| ((checkObj?.iconUrl==undefined&&edtCategory?.categoryIcon==undefined)|| (checkObj?.iconUrl==null&&edtCategory?.categoryIcon==null)))&&
            (edtCategory?.catName==checkObj?.name|| ((checkObj?.name==undefined&&edtCategory?.catName==undefined)|| (checkObj?.name==null&&edtCategory?.catName==null)))&&
            (edtCategory?.catID==checkObj?.storeCategoryId|| ((checkObj?.storeCategoryId==undefined&&edtCategory?.catID==undefined)|| (checkObj?.storeCategoryId==null&&edtCategory?.catID==null)))&&
            (contentCheck)
        ) {
            return true;
        }
        else{
            return false;
        }

    }



    //Edit Stores

    const db = getFirestore(app);

    const editStores =async ()=>{

        let isLock=false;
        // let UnlockOperator=[];
        // if (lockItems) {
        //     isLock=true;
        //     const obj={ storeId:storeID,unlockItemId:unlockItemId}
        //     UnlockOperator.push(obj);
        // }
        // if (lockProgression) {
        //     isLock=true;
        //     PS.forEach((e)=>{
        //         UnlockOperator.push({storeId:storeID,levelSystemId:e.id,lockedLevel:e.lockedLevel})
        //     })
        // }
        let unlockItems = []
        selectedItemId && selectedItemId.map((e,i) =>{
            if(e.lockByOptionType === 'Item'){
                if(e?.id !== undefined){
                    unlockItems.push({archive: e.archive, id: e.id, unlockItemId : e.unlockItemId, unlockBundleId: null, name:e.name, unlockLevelSystemId : null, lockedLevel : null, lockByOptionType: 'Item'})
                }else{
                    if(e.unlockItemId === unlockItemId){
                        unlockItems.push({archive: false, unlockItemId : e.unlockItemId, unlockBundleId: null, name:e.name, unlockLevelSystemId : null, lockedLevel : null, lockByOptionType: 'Item'})
                    }
                }
            }else{
                if(e?.id !== undefined){
                    unlockItems.push({archive: e.archive, id: e.id, unlockItemId : null, unlockBundleId: (e.unlockBundleId ? e.unlockBundleId : e?.unlockItemId ? e?.unlockItemId : null), name:e.name, unlockLevelSystemId : null, lockedLevel : null, lockByOptionType: 'Bundle'})
                }else{
                    if(e.unlockItemId === unlockItemId){
                        unlockItems.push({archive:false, unlockItemId : null, unlockBundleId: e?.unlockItemId, name:e.name, unlockLevelSystemId : null, lockedLevel : null, lockByOptionType: 'Bundle'})
                    }
                }
            }
        })

        let unlockPS = []
        PS && PS.map((e,i) =>{
            unlockPS.push({archive:e.archive,id: e.dbID, unlockItemId : null,name:e.name, unlockLevelSystemId : e.id, lockedLevel : e.lockedLevel})
        })

        if (lockItems===false) {
            unlockItems.map((e)=>(e.archive=true))
        }
        if (lockProgression===false) {
            unlockPS.map((e)=>(e.archive=true,e.lockedLevel=null))
        }
        let unlockItemData = [...unlockItems, ...unlockPS]
        const strCategories = [];
        for(var i = 0; i < Category.length ; i++){
            const catData={
                archive:Category[i].archive,
                storeCategoryId:Category[i].catID,
                name: Category[i].catName,
                id:Category[i].ID,
                iconUrl: Category[i].categoryIcon,
                storeContents:[]
            }
            if (Array.isArray(Category)){
            for (let c = 0; c < Category[i].storeContent.length; c++) {
                const contData = {
                    storesCategoryId:Category[i].ID,
                    id:Category[i].storeContent[c].id,
                    quantity: Category[i].storeContent[c].quantity,
                    itemId: (Category[i].storeContent[c].itemId!==null?Category[i].storeContent[c].itemId:null),
                    bundleId:(Category[i].storeContent[c].bundleId!==null?Category[i].storeContent[c].bundleId:null),
                    currencyId:(Category[i].storeContent[c].currencyId!==null?Category[i].storeContent[c].currencyId:null),
                    archive:Category[i].storeContent[c].archive,
                    storeContentPrice: []
                }
                if (Array.isArray(Category[i].storeContent[c].priceList)) {
                for (let z = 0; z < Category[i].storeContent[c].priceList.length; z++) {
                     
                    const contPriceData = {
                        // storeContentId:content[i][c].id,
                        id : Category[i].storeContent[c].priceList[z].id,
                        archive:Category[i].storeContent[c].priceList[z].archive,
                        storeContentId : Category[i].storeContent[c].priceList[z].storeContentId,
                        priceTypes: Category[i].storeContent[c].priceList[z].priceTypes,
                        price: parseInt(Category[i].storeContent[c].priceList[z].price),
                        discount: Category[i].storeContent[c].priceList[z].discount,
                        productId: Category[i].storeContent[c].priceList[z]?.productId,
                        bonusCashAllowance: Category[i].storeContent[c].priceList[z].bonusCashAllowance,
                        gamePlatformMasterId: (Category[i].storeContent[c].priceList[z].gamePlatformMasterId===null ? 1 : Category[i].storeContent[c].priceList[z].gamePlatformMasterId),
                        currencyId: ((Category[i].storeContent[c].priceList[z].priceTypes === 'virtual currency' || Category[i].storeContent[c].priceList[z].priceTypes === 'RMG' || Category[i].storeContent[c].priceList[z].priceTypes === 'real money' || Category[i].storeContent[c].priceList[z].priceTypes === 'virtual') ?  Category[i].storeContent[c].priceList[z].currencyId :null),
                        realWorldCurrencyId:(Category[i].storeContent[c].priceList[z].priceTypes==="IAP" ? Category[i].storeContent[c].priceList[z].realWorldCurrencyId : null),
                    };
                    contData.storeContentPrice.push(contPriceData)
                }
                
                } catData.storeContents.push(contData)
                
            }}
            const isCategorySame=checkObject&&checkObject?.storesCategories?.[i]&&compareThisCategory(Category[i],checkObject?.storesCategories[i])
            if (!isCategorySame) {
                strCategories.push(catData)
            }
        }

        const metaDataList = [...metaData];
        const finalMetaData = {};
        for(var m = 0; m < metaDataList.length ; m++){
            finalMetaData[metaDataList[m].key] = metaDataList[m].value;
        }

        let storeTags = []
        tags && tags.forEach((e)=>{
            storeTags.push(e.text)
        })
        let platformIds=[]
        let locationIds=[]

        platform&& platform.map((e,i)=>{
            platformIds.push(e.id)
        })
        locations&& locations.map((e,i)=>{
            locationIds.push(e.id)
        })



    const editStore ={

        stores: [
            {
                storeId: storeID,
                name: storeName,
                description: storeDescription,
                iconUrl: (storeIcon!==null||storeIcon!==undefined?storeIcon:""),
                gamePlatformMasterId:1,
                storePlatformIds:platformIds,
                storeLocationIds:locationIds,
                isLocked: isLock,
                meta: finalMetaData,
                tags: storeTags,
                id:ID,
                storeCategories: strCategories,
                rewardUnlockCondition : unlockItemData.length > 0 ? unlockItemData : null
            }
        ]
    }
    if(validation(editStore)){
        try {
            await dispatch(editStoreAction(editStore, navigate, appid, globalStates.setShowOverlay))
            // Access the response data (e.g., game ID) 
            setIsChanged(false);
            const user = JSON.parse(localStorage.getItem('user'))
            const memberID = user?.id
            const memberName = user?.name
            const orgId = (localStorage.getItem('orgId'))

            // Log activity in Firestore
            const activityLog = {
                timestamp: new Date().toISOString(),
                action_type: "UPDATE",
                app_id:appid,
                resource: "STORE",
                resource_id: id, // Use the ID from response
                description: `Store '${storeName}' updated successfully`,
                quantity: 1,
...(localStorage.getItem('environment')&&{environment:localStorage.getItem('environment')}),
operation_status: "SUCCESS",
                activity_feed: true,
                priority: "LOW",
                performed_by_id: memberID,
                organization_id: orgId,
                performed_by:memberName,
                request_json:JSON.stringify(editStore),
            };
            await addDoc(collection(db, "activityFeed"), activityLog);
await increaseUnreadCountForAllUsers( appid);
            console.log("Activity logged successfully");
        
            setIsChanged(false)
        } catch (error) {
            
        }
    }
    
}

    const [ID,setID]=useState();

    //Load Data
    const getItemById = async() => {
        let storeById = {
            projectId : appid,
            ids : [id]
        }
        setID(id);
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await customAxios.post(BASE_URL + GET_STORES, storeById, headers)
        let itemId = res.data.data.stores[0]
        setCheckObject(itemId)
        setLogo({src: (itemId.iconUrl != null ? itemId.iconUrl : placeholder), alt: 'Upload an Image'});
        setStoreIcon(itemId.iconUrl)
        setStoreName(itemId.name)
        setStoreID(itemId.storeId)
        setStoreDescription(itemId.description)
        itemId.storePlatforms&& itemId.storePlatforms.map((e,i)=>{
            setPlatform(prev=>[...prev,{id:e.storePlatformMaster.id, name: ((e.storePlatformMaster.name !== 'ios' && e.storePlatformMaster.name !== 'Ios') ? e.storePlatformMaster.name.charAt(0).toUpperCase() + e.storePlatformMaster.name.slice(1) : (e.storePlatformMaster.name === 'ios' || e.storePlatformMaster.name === 'Ios') ? 'iOS' : '-')}]) 
        })
        itemId.storeLocations&& itemId.storeLocations.map((e,i)=>{
            setLocations(prev=>[...prev,{id:e.storeLocationMaster.id,name:e.storeLocationMaster.name,country_code:(e.storeLocationMaster.country_code&& e.storeLocationMaster.country_code)}])
        })
        let contentData = Category
        let tempCat=itemId.storesCategories;
        tempCat.sort((a, b) => {
            if (a.isDefault && !b.isDefault) {
              return -1;
            }
            if (b.isDefault && !a.isDefault) {
              return 1;
            }
            return 0;
          });
        itemId.storesCategories && tempCat&& tempCat.forEach((e,i)=>{
            setLogo1(prevState=>[...prevState,{src1:(e.iconUrl!==null&&e.iconUrl!==""?e.iconUrl:placeholder),alt1:"upload an Image"}]);
            contentData[i]={archive:e.archive, catName:e.name, catID:e.storeCategoryId, ID:e.id, categoryIcon:e.iconUrl, storeContent:[],isDefault:e.isDefault}; 
            let cont=[];
            if (e.storesContents) {
                for (let index = 0; index < e.storesContents.length; index++) {
                if(e.storesContents[index].archive === false){
                    const {storeContentPrice,item,currency,bundle,...obj}=e.storesContents[index];
                    obj.priceList=storeContentPrice;
                    obj.iconUrl=(item?item.iconUrl:bundle?bundle.iconUrl:currency?currency.iconUrl:"");
                    obj.displayId=(item?item.itemId:bundle?bundle.bundleId:currency?currency.currencyId:"");
                    obj.name=(item?item.name:bundle?bundle.name:currency?currency.name:"");
                    cont.push(obj);
                }
            }
            }
            
            contentData[i].storeContent=cont;
            setCategory(contentData);
            sessionStorage.setItem('contents', JSON.stringify(contentData))
        })
        setCategory(contentData);
        //if (Category.length>0) {
            sessionStorage.setItem('contents', JSON.stringify(contentData))
        //}
        if(itemId.rewardsUnlockConditions){
            itemId.rewardsUnlockConditions && itemId.rewardsUnlockConditions.forEach((ele, i) => {
                if(ele && ele.unlockItemId != null){
                    setLockByOptionType('Item')
                    if(ele.id){
                        setSelectedItemId(prevState => [...prevState,{id:ele.id, unlockItemId:ele.unlockItem.id, archive:ele.archive, name : ele.unlockItem.name, lockByOptionType: 'Item'}])
                        setUnlockItemId(ele.unlockItem.id)
                    }
                    if (!ele.archive) {
                        setLockItems(true)
                    }   
                }else if(ele && ele.unlockBundleId != null){
                    setLockByOptionType('Bundle')
                    if(ele.id){
                        setSelectedItemId(prevState => [...prevState,{id:ele.id, unlockItemId:null, unlockBundleId: ele.unlockBundle.id, archive:ele.archive,name : ele.unlockBundle.name, lockByOptionType: 'Bundle'}])
                        setUnlockItemId(ele.unlockBundle.id)
                    }
                    if (!ele.archive) {
                        setLockItems(true)
                    }
                }else{
                    if (!ele.archive) {
                        setLockProgression(true)
                    }
                    if(ele.id){
                        if (!ele.archive) {
                            setPS(prevState => [...prevState, {dbID : ele.id ,id : ele.unlockLevel.id, name : ele.unlockLevel.levelName, lockedLevel: ele.lockedLevel,archive:ele.archive}])
                        }
                        else{
                            setPS(prevState => [...prevState, {dbID : ele.id ,id : ele.unlockLevel.id, name : ele.unlockLevel.levelName, lockedLevel: null,archive:ele.archive}])
                        }
                    }
                }
            })
        }
        itemId.tagsMapping && itemId.tagsMapping.forEach(e => {
            e.tags && setTags(tags => [...tags, {id : e.tags.id, text : e.tags.name}])
        });
        let meta = []
        if(itemId.meta === null || JSON.stringify(itemId.meta) === '{}'){
            setMetaData([{key : '', value : null}])
        }else{
            itemId.meta && Object.keys(itemId.meta).forEach(e => {
                meta.push({key : e, value : itemId.meta[e]})
            });
            setMetaData(meta)
        }
        setIsCalled(true)
    }

    function compareUnlockConditionsArrays(arr1, arr2) {
        let array1=arr1&& arr1.filter(item => !item?.archive)
        let array2=arr2&& arr2.filter(item => !item?.archive)
        if (array1?.length !== array2?.length) {
            return false
        }
        for (let i = 0; i < array1.length; i++) {
            if (
                (parseInt(array1[i]?.lockedLevel)===array2[i]?.lockedLevel|| array2[i]?.lockedLevel==undefined|| array2[i]?.lockedLevel==null)&&
                (array1[i]?.unlockLevelSystemId===array2[i]?.unlockLevelSystemId || array2[i]?.unlockLevelSystemId==undefined|| array2[i]?.unlockLevelSystemId==null)&&
                (array1[i]?.unlockItemId===array2[i]?.unlockItemId || array2[i]?.unlockItemId==undefined|| array2[i]?.unlockItemId==null)
            ) {
            }
            else{
                return false;
            }
        }

        return true;
    }

    function comparePlatformArrays(arr1, arr2) {
        let array1=arr1&& arr1.filter(item => !item?.archive)
        let array2=arr2&& arr2.filter(item => !item?.archive)
        if (array1?.length !== array2?.length) {
            return false
        }
        for (let i = 0; i < array1.length; i++) {
            if (
                (parseInt(array1[i]?.id)===array2[i]?.storePlatformMaster.id|| array2[i]?.storePlatformMaster.id==undefined|| array2[i]?.storePlatformMaster.id==null)
            ) {
            }
            else{
                return false;
            }
        }

        return true;
    }

    function compareLocationArrays(arr1, arr2) {
        let array1=arr1&& arr1.filter(item => !item?.archive)
        let array2=arr2&& arr2.filter(item => !item?.archive)
        if (array1?.length !== array2?.length) {
            return false
        }
        for (let i = 0; i < array1.length; i++) {
            if (
                (parseInt(array1[i]?.id)===array2[i]?.storeLocationMaster.id|| array2[i]?.storeLocationMaster.id==undefined|| array2[i]?.storeLocationMaster.id==null)
            ) {
            }
            else{
                return false;
            }
        }

        return true;
    }

    function comparePricingArrays(arr1, arr2) {
        let array1=arr1&& arr1.filter(item => !item.archive)
        let array2=arr2&& arr2.filter(item => !item.archive)
        if (array1?.length !== array2?.length) {
            return false
        }
        for (let i = 0; i < array1?.length; i++) {
            if (
                (array1[i]?.realWorldCurrencyId===array2[i]?.realWorldCurrencyId|| array2[i]?.realWorldCurrencyId==undefined|| array2[i]?.realWorldCurrencyId==null)&&
                (array1[i]?.currencyId===array2[i]?.currencyId || array2[i]?.currencyId==undefined|| array2[i]?.currencyId==null)&&
                (parseInt(array1[i]?.price)===array2[i]?.price||array1[i]?.price===array2[i]?.price)&&
                array1[i].priceTypes===array2[i].priceTypes
            ) {
            }
            else{
                return false;
            }
        }

        return true;
    }

    function compareContentArrays(arr1, arr2) {
        let array1=arr1&& arr1.filter(item => !item?.archive)
        let array2=arr2&& arr2.filter(item => !item?.archive)
        if (array1?.length !== array2?.length) {
            return false;
        }
        for (let i = 0; i < array1.length; i++) {
            let pricecheck =((array2[i] && comparePricingArrays(array1[i].priceList,array2[i].storeContentPrice))||(array2[i].storeContentPrice==null && array1[i].priceList.length==0))
            if (
                (array1[i]?.displayId===array2[i]?.item?.itemId|| array2[i]?.item?.itemId==undefined|| array2[i]?.item?.itemId==null)&&
                (array1[i]?.displayId===array2[i]?.bundle?.bundleId|| array2[i]?.bundle?.bundleId==undefined|| array2[i]?.bundle?.bundleId==null)&&
                (array1[i]?.displayId===array2[i]?.currency?.currencyId || array2[i]?.currency?.currencyId==undefined|| array2[i]?.currency?.currencyId==null)&&
                (parseInt(array1[i].quantity)===array2[i].quantity||array1[i].quantity===array2[i].quantity)&&
                (pricecheck)
            ) {
            }
            else{
                return false;
            }
        }

        return true;
    }

    function compareCategoryArrays(arr1, arr2) {
        let array1=arr1&& arr1.filter(item => !item?.archive)
        let array2=arr2&& arr2.filter(item => !item?.archive)
        if (array1?.length !== array2?.length) {
            return false
        }
        for (let i = 0; i < array1.length; i++) {
            let contentCheck = ((array2[i] && compareContentArrays(array1[i]?.storeContent, array2[i]?.storesContents))||(array2[i].storesContents==null && array1[i].storeContent.length==0))
            if (
                (array1[i]?.categoryIcon==array2[i]?.iconUrl|| ((array2[i]?.iconUrl==undefined&&array1[i]?.categoryIcon==undefined)|| (array2[i]?.iconUrl==null&&array1[i]?.categoryIcon==null)))&&
                (array1[i]?.catName==array2[i]?.name|| ((array2[i]?.name==undefined&&array1[i]?.catName==undefined)|| (array2[i]?.name==null&&array1[i]?.catName==null)))&&
                (array1[i]?.catID==array2[i]?.storeCategoryId|| ((array2[i]?.storeCategoryId==undefined&&array1[i]?.catID==undefined)|| (array2[i]?.storeCategoryId==null&&array1[i]?.catID==null)))&&
                (contentCheck)
            ) {
            }
            else{
                return false;
            }
        }

        return true;
    }


    const setchange = () => {
        let metaDataList = [...metaData];
        let finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }

        let itemTags = []
        tags && tags.forEach((e)=>{
            itemTags.push(e.text)
        })

        let checkTags = []
        checkObject?.tagsMapping && checkObject?.tagsMapping.forEach((e)=>{
            if (e.tags!==null&&e.tags!==undefined) {
            checkTags.push(e?.tags?.name)
            }
        })
        let unlockItems = []
        if(unlockItemId){
            unlockItems.push({id: selectedItemId[0].id, unlockItemId : unlockItemId, unlockLevelSystemId: null})
        }

        let unlockPS = []
        PS && PS.map((e,i) =>{
            unlockPS.push({id: e.dbID, unlockItemId : null, unlockLevelSystemId : e.id, lockedLevel : e.lockedLevel})
        })

        let unlockItemData = [...unlockPS,...unlockItems]

        // let cnt = content;

        let platformcheck = checkObject&& ((checkObject.storePlatforms&& comparePlatformArrays(platform, checkObject.storePlatforms))||!checkObject?.storePlatforms)
        let unlockCheck = checkObject && ((checkObject.rewardsUnlockConditions&& compareUnlockConditionsArrays(unlockItemData, checkObject.rewardsUnlockConditions))||!checkObject?.rewardsUnlockConditions)
        let locationcheck = checkObject&& ((checkObject.storeLocations&& compareLocationArrays(locations, checkObject.storeLocations))||!checkObject?.storeLocations)
        let categorycheck = checkObject&& ((checkObject.storesCategories&& compareCategoryArrays(Category, checkObject.storesCategories))||!checkObject?.storesCategories)
        if (checkObject&&
            (storeIcon==checkObject.iconUrl)&&
            (storeName==checkObject.name)&&
            (storeID==checkObject.storeId)&&
            (storeDescription==checkObject.description||((storeDescription===undefined||storeDescription==''||storeDescription=="")&&((checkObject.description===undefined||checkObject.description==''||checkObject.description==""||checkObject.description==null))))&&
            // (unlockCheck)&&
            (platformcheck)&&
            (locationcheck)&&
            (categorycheck)&&
            //((JSON.stringify(finalMetaData) == JSON.stringify(checkObject.meta))||((JSON.stringify(finalMetaData)=='{"":""}')&&(checkObject.meta==null)))&&
            (JSON.stringify(itemTags) == JSON.stringify(checkTags)|| (checkObject.tagsMapping==null && itemTags.length==0))
            ) {
                setIsChanged(false)
            }
            else {
                setIsChanged(true)
            }
    }

    useEffect(()=>{
        setchange()
        
    },[platform,locations,items,unlockItemId,lockItems,selectedItemId,lockProgression,tags,PS,storeName,storeID,storeDescription,metaData,Category,storeIcon])



    const [realWorldCurrencyOptions, setRealWorldCurrencyOptions] = useState([])
    
    const getRealWorldCurrency = async() => {
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await axios.post(`${BASE_URL}/currencies/get-world-currency`, {}, headers)
        res.data.data && res.data.data.forEach((element) => {
            setRealWorldCurrencyOptions(prevState => [...prevState, {realWorldCurrencyId : element.id, name : element.code.concat(" (", `${element.symbol}`, ")"),code:element.code, type : 'IAP'}])
        })
    }


    const [visibleHelp, setVisibleHelp] = useState(false)

    const [media, setMedia] = useState([])

    const getMedia = async() => {
        let getMediaData = {
            projectId : appid,
            category: 'icons',
            typeId : 0
        }
        await dispatch(getAllMediaAction(getMediaData))
    }

    let files = useSelector((state) => {
        return state.media.media.mediaDetails
    })
    const [visibleCategorySidebar, setVisibleCategorySidebar] = useState(false)
    const [catIndex,setCatIndex]= useState(null)

    const configureMedia = () => {
        if(files && files.length > 0){
            setMedia(files && files.map((e, i) => ({...e, status : false})))
        }else{
            setMedia([])
        }
    }

    useEffect(() => {
        getMedia()
        getRealWorldCurrency()
    }, [])

    useEffect(() => {
        configureMedia()
    }, [files])

    return (
        <>

            <ToastContainer />
            <Card className={cn(styles.card, className)} title="Store Information" classTitle="title-purple">
            <div className={styles.cardDescription}>
            <div className={styles.preview}>
                <div className={styles.previewImage}>
                    <SnapShot id='logo' url={storeIcon} alt={storeName} placeholder={Store} type='Images' width={60} height={60} borderRadius={16}/>
                    {/* <img id='logo' alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img> */}
                </div>
                <div className={styles.previewInput}> 
                    <input type="file" className='file-input' accept="image/jpeg, image/png, image/webp, .jpeg, .jpg, .png, .webp" ref={ref} onChange={(e)=>{uploadLogo(e)}}/>
                    <label className={styles.button} onClick={() => setVisibleHelp(true)}><AddButtonIcon className={styles.addIcon}/>Upload Icon</label>
                </div>
                {storeIcon != null && 
                    <div className={styles.previewCancel}>
                        <button className={styles.buttonCancel} onClick={Cancel}>Remove</button>
                    </div>
                }
            </div>
            <FileUploadSidebar media={media} setMedia={setMedia} types='Images' id={`icon`} multiple={false} visible={visibleHelp} setVisible={setVisibleHelp} icon={storeIcon} setIcon={setStoreIcon} setValue={setLogo} onClose={onClose} />
            <div className={styles.createItem}>
                <div className={styles.group}>
                    <TextInput tooltip={TooltipTitle.StoreName} id='storeName' className={styles.field} errorMessage={errors.nameError} label="Display Name" name="displayName" type="text" value={storeName} required onChange={(e)=>{onChangeStoreName(e)}}/>
                    <TextInput isId={true} tooltip={TooltipTitle.StoreId} id='storeId' className={styles.field} errorMessage={errors.idError} label="Store ID" name="itemId" type="text" required value={storeID} onChange={(e)=>{onChangeStoreID(e)}} />
                </div>

                <div className={styles.textArea}>
                    <TextArea type='STORE_DESCRIPTION' tooltip={TooltipTitle.StoreDescription} className={styles.field} rows={5} label="Store Description" value={storeDescription} onChange={(e)=>{onChangeStoreDescription(e)}}/>
                </div>
                

            </div>

            <div className={styles.createItem}>   
                    <div className={styles.group}>
                        <div className={styles.lockGroup}>
                            <PlatformDropdown className={styles.selexctField} label="Select Platform" placeholder="Choose Game Platform" options={platformOption} value={platform} setValue={setPlatform} />
                        </div>                
                        <div className={styles.lockGroup}>
                            <LocationDropdown className={styles.selectField} label="Choose Location" placeholder="Choose Location" options={locationOption} value={locations} setValue={setLocations} />
                        </div>
                    </div>
                </div>
            </div>
            </Card>



            {Category && Category.map((e,i)=>{
                return(
                    e.archive!==true &&
                    <>
                    <Card className={cn(styles.card, className)} title={(i==0)?`Default Category `:`Category ${i+1}`} classTitle="title-purple" style={{margin:"0px"}} head={<div>{Category && i === 0? <span className={styles.addIcon} onClick={()=>addCategory(i)}><AddMetaIcon /></span>:""}{Category && i > 0? <span style={{position:"absolute", right:"26px"}} onClick={()=>removeCategory(i)}><RemoveIcon /></span>:""}</div>}>
                <div className={styles.cardDescription}>
                    <div className={styles.preview}>
                        <div className={styles.previewImage}>
                            <SnapShot id={`storeCategory_${i}`} url={e?.categoryIcon} alt={e?.catName} placeholder={Store} type='Images' width={60} height={60} borderRadius={16}/>
                            {/* <img src={(logo1[i]?logo1[i].src1:placeholder)}style={{maxWidth: "100px", maxHeight: "100px"}}></img> */}
                        </div>
                        <div className={styles.previewInput} onClick={() => {setVisibleCategorySidebar(true);setCatIndex(i)}}> 
                            <input type="file" /*id={`icon${i}`}*/ className='file-input' accept="image/jpeg, image/png, image/webp, .jpeg, .jpg, .png, .webp" ref={ref1} onChange={(e)=>{uploadCategoryLogo(e,i)}}/>
                            <label /*for={`icon${i}`}*/ className={styles.button}><AddButtonIcon className={styles.addIcon}/>Upload Icon</label>
                        </div>
                        {Category[i].categoryIcon &&
                            <div className={styles.previewCancel}>
                                <button className={styles.buttonCancel} id={`removeButton${i}`} onClick={()=>{CancelCategoryIcon(i)}}>Remove</button>
                            </div>
                        }
                    </div>

                    
                    <div className={styles.createItem}>
                    <div className={styles.group}>
                        <TextInput tooltip={TooltipTitle.StoreCategoryName} className={styles.field} label="Category Name" name="catName" type="text" required value={(Category[i].catName !== '' || Category[i].catName !== undefined || Category[i].catName !== null) ? Category[i].catName : ''} onChange={(e)=>{onChangeCategoryName(e, i)}}/>
                        <TextInput isId={true} tooltip={TooltipTitle.StoreCategoryId} className={styles.field} label="Category ID" name="catID" type="text" required value={(Category[i].catID !== '' || Category[i].catID !== undefined || Category[i].catID !== null) ? Category[i].catID : ''} onChange={(e)=>{onChangeCategoryID(e, i)}}/>
                        
                    </div>
                    </div>
                    <div className={styles.cardDescription}>
                    <div className={styles.createBundle}>
                        <div className={styles.groupHeading}>
                        <Control type='Store' pageItem={itemPage} pageBundle={bundlePage} itemLimit={itemLimit} itemPage={itemPage} setItemPage={setItemPage} bundleLimit={bundleLimit} bundlePage={bundlePage} setBundlePage={setBundlePage} currency={allCurrency[i]} fullCurrencyList={allCurrency} setCurrency={setAllCurrency} bundle={getAllBundle[i]} fullBundleList={getAllBundle} setBundle={setAllBundle} categoryIndex={i} item={allItems[i]} fullItemsList={allItems} setItem={setAllItems} setValue={setCategory} width='medium' fullCategories={Category} customizeBundleData={customizeBundleData} customizeItemData={customizeItemData}/>
                        </div>
                    </div>

                    {Category[i].storeContent && Category[i].storeContent.length > 0 &&Category[i].storeContent.some(e=>e.archive!==true) &&
                        <div className={styles.createBundle}>
                            <div className={styles.content}>
                                <div className={styles.wrapper}>
                                    <ContentTable platformOption={platformOption} realWorldCurrencyOptions={realWorldCurrencyOptions} items={Category} categoryIndex={i} setValue={setCategory} allItems={allItems} setAllItems={setAllItems} allBundles={getAllBundle} setAllBundle={setAllBundle}/>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                </div>
                    </Card>

                    </>
                )
            })}

            <FileUploadSidebar type={"store"} catIndex={catIndex} customUploadFunction={uploadCategoryLogo} customUploadingMedia={uploadingMedia} customSelectMediaFunction={onSelectedCategoryLogo} media={media} setMedia={setMedia} types='Images' id={`icon${'-'}`} multiple={false} visible={visibleCategorySidebar} setVisible={setVisibleCategorySidebar} onClose={onClose} />


            <Card className={cn(styles.card, className)} title="Access & Eligibility" classTitle="title-blue">
                <div className={styles.cardDescription}>
                    <div className={styles.createItem}>
                        <div className={styles.group}>
                            <div className={styles.fieldRadio}>
                                <div className={styles.radioLabel}>
                                    <span>Lock by<span><Tooltip className={styles.tooltip} title={TooltipTitle.StoreLock} icon="info"/></span></span>
                                </div>
                                <div className={styles.variants}>
                                    {lockBy.map((x, index) => (
                                        <Checkbox key={x.id} value={x.active} className={styles.checkbox} content={x.title} onChange={(e)=>{onLockBy(e, x.title)}} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    

                    <div className={styles.createItem}>
                        {lockItems &&
                            <div className={styles.group}>
                                <div className={styles.lockGroup}>
                                    <div className={styles.radioLabel}>
                                        <span>Choose Item/Bundle<span><Tooltip className={styles.tooltip} title={TooltipTitle.StoreItemBundle} icon="info"/></span></span>
                                    </div>
                                    <ItemDropdown placeholder='Select Item/Bundle' className={styles.selectField} groupBy={1} options={itemOptions} value={selectedItemId} setValue={setSelectedItemId} setUnlockItemId={setUnlockItemId} error={setLockItemErr} setType={setLockByOptionType}/>
                                    {lockItemErr && <span className={styles.danger}>*Item is required.</span>}
                                </div>
                            </div>
                            }
                        <div className={styles.group}>
                            {lockProgression ?
                                <div className={styles.lockGroup} style={{marginTop:"8px"}}>
                                    <div className={styles.radioLabel}>
                                        <span>Choose Progression System<span><Tooltip className={styles.tooltip} title={TooltipTitle.StorePS} icon="info"/></span></span>
                                    </div>
                                    <CommonDropdown placeholder='Select Progression System' className={styles.selectField} options={PSOptions} value={PS} setValue={setPS} error={setLockPSErr}/>
                                    {lockPSErr && <span className={styles.danger}>*Progression System is required.</span>}
                                </div>
                            : ''}
                            {lockProgression &&
                            <div className={styles.lockGroup} >
                                {PS && PS.map((element, index) =>{
                                    
                                    return(!element.archive &&
                                        <>
                                        <div style={{marginTop:"8px"}}>
                                            <ProgressionLevelMultiSelect placeholder='Select Level' label={element.name} className={styles.selectField} options={(PSOptions.find(x=>x.name==element.name)?.levelSystemLevelMapping)&&(PSOptions.find(x=>x.name==element.name)?.levelSystemLevelMapping)} ind={index} value={PS} setValue={setPS}/>                                  
                                        </div>
                                        </>
                                    )
                                })}
                            </div>
                            }
                        </div>
                        </div>
                    {/* <div className={styles.createItem}>
                        <div className={styles.group}>
                            {lockProgression ?
                                <div className={styles.lockGroup}>
                                    <div className={styles.radioLabel}>
                                        <span>Choose Progression System</span>
                                    </div>
                                    <CommonDropdown placeholder='Select Progression System' className={styles.selectField} options={PSOptions} value={PS} setValue={setPS} error={setLockPSErr}/>
                                    {lockPSErr && <span className={styles.danger}>*Progression System is required.</span>}
                                </div>
                            : ''}
                            {lockItems ?
                                <div className={styles.lockGroup}>
                                    <div className={styles.radioLabel}>
                                        <span>Choose Item/Bundle</span>
                                    </div>
                                    <ItemDropdown className={styles.selectField} groupBy={7} options={itemOptions} value={selectedItemId} setValue={setSelectedItemId} error={setLockItemErr}/>
                                    {lockItemErr && <span className={styles.danger}>*Item is required.</span>}
                                </div>
                            : ''}
                        </div>
                    </div>
                    {lockProgression &&
                    <div className={styles.createItem}>
                        <div className={styles.group}>
                        {PS && PS.map((element, index) => 
                                !element.archive && (
                                    <>
                                        <TextInput className={styles.field} label={`${element.name}`} currency='Level' value={element.lockedLevel} style='grey' type="number" onWheelCapture={e => {e.currentTarget.blur()}} required onChange={(e) => {onChangeLevel(e.target.value, index)}}/>
                                    </>
                                )
                            )}
                        </div>
                    </div>
                    } */}
                </div>

            </Card>

            <Card className={cn(styles.card, className)} title="Custom Data" classTitle="title-red">
                
                <div className={styles.cardDescription}>
                <div className={styles.createItem}>
                    
                <TagInputWithDropdown tooltip={TooltipTitle.StoreTags} tags={tags} setTags={setTags}/>

                    <div className={styles.groupHeading}>
                        <span>Meta Data</span><span><Tooltip className={styles.tooltip} title={TooltipTitle.StoreMetaData} icon="info"/></span><span className={styles.addIcon} onClick={addMetaData}><AddMetaIcon /></span>
                    </div>

                    {metaData && metaData.length > 0 &&
                        <div className={styles.groupMeta}>
                            <div className={styles.field}>Key</div>
                            <div className={styles.field}>Value</div>
                        </div>
                    }

                    <div className={styles.groupMeta}>
                        {metaData && metaData.map((e,i)=>{
                            return(
                                <>
                                    <TextInput className={styles.field} label="" name="key" type="text" required value={(metaData[i].key !== '' || metaData[i].key !== undefined || metaData[i].key !== null) ? metaData[i].key : ''} onChange={(e)=>{onChangeKey(e, i)}}/>
                                    <JSONMetaDataValueInput key={i} className={styles.field} data={(typeof e.value == 'string') ? JSON.stringify(e.value) : e.value} value={metaData} setValue={setMetaData} index={i}/>
                                    {/* <TextInput className={styles.field} label="" name="value" type="text" required  value={(metaData[i].value !== '' || metaData[i].value !== undefined || metaData[i].value !== null) ? metaData[i].value : ''} onChange={(e)=>{onChangeValue(e, i)}}/> */}
                                    <span className={styles.remove} onClick={()=>{removeMetaData(i)}}><RemoveIcon className={styles.icon} /></span>
                                </>
                            )
                        })}
                    </div>
                </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)}>
                <div className={styles.cardAction}>
                    <div className={styles.cardSave}>
                        <button className={cn(styles.button, 'button-save-small')} onClick={editStores}>Save</button>
                    </div>
                    <div className={styles.cardCancel}>
                        <button className={cn(styles.button, 'button-cancel-small')} onClick={cancel}>Cancel</button>
                    </div>
                </div>
            </Card>




        </>
    )
}

export default StoreInformation