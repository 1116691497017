import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import Icon from "../../../../../Icon";
import {ReactComponent as ArchiveSvg} from '../../../../../../media/images/icons/archive.svg'
import {ReactComponent as Trophy} from '../../../../../../media/images/icons/trophy.svg'
import Vertical3Dots from "../../../../../../media/images/icons/vertical3Dots.svg"
import VerticalOptionsTooltip from "../../../../../VertcalOptionsTooltip"
import Modal from "../../../../../Modals/ModalComponent";
import DeleteModal from "../../../../../Modals/Others/DeleteModal";
import ArchiveModal from "../../../../../Modals/Others/ArchieveModal";
import { useAccess } from "../../../../../Page";

const Control = ({className, usedFor, isLast, PD, scheduleInstance, status, id, name, type, archive, interval, instanceId, access, appid}) => {

  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
  const [visibleArchiveModal, setVisibleArchiveModal] = useState(false)
  const { accessPermissions } = useAccess();
  const {allowTournamentEdit,allowInstantBattleEdit,allowLeaderboardEdit,allowTournamentDelete,allowInstantBattleDelete,allowLeaderboardDelete,allowInstantBattleResult} = accessPermissions || {};

  const actions = [
    {
      pathName : `/view-competition-instant-battle/${appid}/${id}`,
      action: () => console.log("view"),
    },
    {
      name : "Edit",
      icon: "edit",
      pathName : `/edit-competition-instant-battle/${appid}/${id}`,
      action: () => console.log("edit"),
    },
    {
      name : "Result",
      icon : 'trophy',
      pathName : `/instant-battle-result/${appid}/${id}`,
    },
    // {
    //   name: "Archive",
    //   icon: "download",
    //   action: () => setVisibleArchiveModal(true),
    // },
    {
      name: "Delete",
      icon: "trash",
      action:  () => setVisibleDeleteModal(true),
    }
  ];

  return (
    <>
      <div className={cn(styles.control, className)}>
      {access ? 
          (actions && actions.slice(0, 1).map((x, index) => {
            if(
              (!allowInstantBattleEdit && x?.pathName?.includes("/edit-competition-instant-battle"))||
              (!allowInstantBattleResult && x?.pathName?.includes("/instant-battle-result"))||
              (!allowInstantBattleDelete && x?.icon== "trash")
            ){
              return ;
            }
            return(
              <Link to={x.pathName} state={{PD:PD, id : id, appid : appid, name: name, interval:interval, instanceId:instanceId}} className={styles.button} key={index} onClick={x.action}>
                {x.icon == 'download' ?  <ArchiveSvg /> : x.icon == 'trophy' ? <Trophy /> : <Icon name={x.icon} size="20" className={styles.icon}/>}
              </Link>
            )
          }))
        : 
          <VerticalOptionsTooltip usedFor={usedFor} isLast={isLast} data={actions} PD={PD} status={status} scheduleInstance={scheduleInstance} instanceId={instanceId} name={name} interval={interval}>
            <div className={styles.button}>
              <img src={Vertical3Dots} width={20} height={20} className={styles.icon} />
            </div>
          </VerticalOptionsTooltip>
        }  
      </div>
      <Modal visible={visibleDeleteModal} onClose={() => setVisibleDeleteModal(false)}>
        <DeleteModal id={id} name={name} type={type} onClose={() => setVisibleDeleteModal(false)}/>
      </Modal>
      {/* <Modal visible={visibleArchiveModal} onClose={() => setVisibleArchiveModal(false)}>
        <ArchiveModal id={id} name={name} status={archive} type={type} onClose={() => setVisibleArchiveModal(false)}/>
      </Modal> */}
    </>
  );
};

export default Control;
