import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import Modal from "../../../../../../components/Modals/ModalComponent";
// import FileUploadModal from "../../../../../../components/Modals/Others";
// import FileUploadPreviewModal from "../../../../../../components/Modals/Others/FileUploadPreview";
import ImportModal from "../../../../../../components/Modals/Others/Import";
import Icon from "../../../../../../components/Icon";

const ControlFileUpload = ({className, usedFor, size, type, onFileChange, onValidation, data, setData, files, fileErrors, action, revalidate, setBulkUpload, override}) => {

  const [visibleModal, setVisibleModal] = useState(false);

  return (
    <>
      {usedFor === 'empty state' &&
        <div className={cn(styles.control, className)} onClick={() => {setVisibleModal(true)}}>
          <button className={cn(styles.button, "button-white-small")}>Import</button>
        </div>
      }
      {usedFor === 'navigation' &&
        <div className={styles.import} onClick={() => {setVisibleModal(true)}}>Import</div>
      }
      <Modal width={`large`} visible={visibleModal} onClose={() => {setVisibleModal(false)}}>
        <ImportModal type={type} onFileChange={onFileChange} onValidation={onValidation} data={data} setData={setData} files={files} fileErrors={fileErrors} action={action} revalidate={revalidate} setBulkUpload={setBulkUpload} override={override} onClose={() => {setVisibleModal(false)}}/>
      </Modal>
    </>
  );
};

export default ControlFileUpload;