import { GET_ORG_PROFILE, EDIT_USER_PROFILE, GET_USER_PROFILE, EDIT_ORG_PROFILE } from "../../../actionTypes/actionTypes";
import ProfileServices from "../../../../services/organisationServices/profileServices/profileServices";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from "../../../../components/Toast";

export const getUserProfileAction = () => {
    return async (dispatch) => {
        await ProfileServices.getUserProfile()
        .then(res=>{
            dispatch(getUserProfile(res.data.data, GET_USER_PROFILE))
        })
    }
}

export const getOrgProfileAction = (body) => {
    return async (dispatch) => {
        await ProfileServices.getOrgProfile(body)
        .then(res=>{
            dispatch(getOrgProfile(res.data.data, GET_ORG_PROFILE))
        })
    }
}

export const editUserProfileAction = (body, setDisableField) => {
    return async (dispatch) => {
        await ProfileServices.editUserProfile(body)
        .then(res=>{
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            localStorage.setItem('userName', JSON.stringify(body.name))
            if(setDisableField){
                setDisableField(true)
            }
            dispatch(getUserProfileAction({}))
            dispatch(editUserProfile(res.data.data, GET_USER_PROFILE))
            return res;
        }).catch(error => {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            throw error;
        })
    }
}

export const editOrgProfileAction = (body, setAppName, setDisableField) => {
    return async (dispatch) => {
        await ProfileServices.editOrgProfile(body)
        .then(res=>{
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            localStorage.setItem('orgName', JSON.stringify(body.name))
            setAppName(body.name)
            if(setDisableField){
                setDisableField(true)
            }
            let org = {organisationId: localStorage.getItem('orgId')}
            dispatch(getOrgProfileAction(org))
            dispatch(editOrgProfile(res.data.data, GET_ORG_PROFILE))
            let updatedOrg = JSON.parse(localStorage.getItem('user'))
            updatedOrg.organisations = updatedOrg?.organisations && updatedOrg?.organisations.map(x => x?.id === body?.organisationId ? {...x, ...(body?.name !== undefined && {name: body?.name}), ...(body?.location !== undefined && {location: body?.location}), ...(body?.defaultLogoUrl !== undefined && {default_logo_url: body?.defaultLogoUrl})} : {...x})
            localStorage.setItem('user', JSON.stringify(updatedOrg))
            window.location.reload()
            return res;
        }).catch(error => {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            throw error;
        })
    }
}

export const changeMemberPasswordAction = (body, setDisablePassword, setCurrentPassword, setNewPassword, setConfirmPassword, setRules) => {
    return async (dispatch) => {
        await ProfileServices.changeMemberPassword(body)
        .then(res => {
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            if(setDisablePassword){
                setDisablePassword(true)
            }
            setCurrentPassword('')
            setNewPassword('')
            setConfirmPassword('')
            setRules({lengthRule: null, capitalRule: null, smallRule: null, numberRule: null, specialRule: null})
            dispatch(getUserProfileAction({}))
            return res;
        }).catch(error => {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            throw error;
        })
    }
}


export const getUserProfile = (data, type) => {
    return {
        type : type,
        payload : data
    }
}

export const getOrgProfile = (data, type) => {
    return {
        type : type,
        payload : data
    }
}

export const editUserProfile = (data, type) => {
    return {
        type : type,
        payload : data
    }
}

export const editOrgProfile = (data, type) => {
    return {
        type : type,
        payload : data
    }
}