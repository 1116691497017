import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import Icon from "../../../../Icon";
import Modal from "../../../../Modals/ModalComponent";
import DeleteItem from "../../../../Modals/Build/Economy/Items/DeleteModal";
import ArchiveItem from "../../../../Modals/Build/Economy/Items/ArchiveModal";
import { useAccess } from "../../../../Page";

const Control = ({ className, appid }) => {
  
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleArchiveModal, setVisibleArchiveModal] = useState(false)
  const { accessPermissions } = useAccess();
  // const { allowGlobalAppsSettingsButton} = accessPermissions || {};
  const actions = [
    {
      icon: "edit",
      pathName : `/app-information/${appid}`,
      action: () => console.log("edit"),
    }
  ];

  return (
    <>
      <div className={cn(styles.control, className)}>
        {/* {actions&& actions.map((x, index) => (
          <Link to={x.pathName} state={{appid : appid}} className={styles.button} key={index} onClick={x.action}>
            <Icon name={x.icon} size="20" />
          </Link>
        ))} */}
      </div>
    </>
  );
};

export default Control;
