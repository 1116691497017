import React, { useState } from 'react'
import styles from './tooltip.module.sass'
import cn from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler'

const TextTooltip = ({ className, text, children }) => {
    
    const [visible, setVisible] = useState(false)
    const [top, setTop] = useState(null)

    const handleMouseEnter = (e) => {
        const rect = e.target.getBoundingClientRect()
        setTop(rect.top)
        setVisible(true);
    };
    
    const handleMouseLeave = (e) => {
        const rect = e.target.getBoundingClientRect()
        setTop(rect.top - 14)
        setVisible(false);
    };
    
    return (
        <div className={cn(styles.custom, {[styles.active] : visible})} onMouseEnter={(e) => {handleMouseEnter(e)}} onMouseLeave={(e) => {handleMouseLeave(e)}}>
            {children}
            <div className={styles.tooltipContainer} style={{left: '44px', top: top}}>
                <div className={styles.text}>{text}</div>
            </div>
        </div>
    )
}

export default TextTooltip