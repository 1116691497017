import React, {useContext, useEffect, useState} from 'react'
import axios from 'axios';
import { Link, json, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import styles from './progressionSystem.module.sass'
import Card from '../../../../../../components/Card';
import TextInput from '../../../../../../components/TextInput';
import TextArea from '../../../../../../components/TextArea';
import Radio from '../../../../../../components/Radio';
import Dropdown from '../../../../../../components/Dropdown/CreatableDropdown';
import Icon from '../../../../../../components/Icon';
import addIcon from '../../../../../../media/images/icons/add.png'
import editIcon from '../../../../../../media/images/icons/edit.png'
import Rewards from './Rewards'
import Control from './Control';
import JsonControl from './JsonControl';
import { createProgressionSystemAction } from '../../../../../../redux/action/builds/progression/progressionSystem/progressionSystemAction';
import { getAllProgressionMarkerAction } from '../../../../../../redux/action/builds/progression/progressionMarker/progressionMarkerAction';
import {ReactComponent as AddButtonIcon} from '../../../../../../media/images/icons/add_button.svg'
import {ReactComponent as AddIcon} from '../../../../../../media/images/icons/add_meta.svg'
import {ReactComponent as EditIcon} from '../../../../../../media/images/icons/editSvg.svg'
import {ReactComponent as RemoveIcon} from '../../../../../../media/images/icons/remove.svg'
import { WithContext as ReactTags } from "react-tag-input";
import Tooltip from '../../../../../../components/Tooltip';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProgressionSystem from '../../../../../../media/images/placeholders/Progression_System.png'
import { useRef } from 'react';
import Toast from '../../../../../../components/Toast';
import RewardsTooltip from '../../../../../../components/CustomTooltips/RewardsTooltip'
import {ReactComponent as Previous} from '../../../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../../../media/images/icons/next.svg'
import TagInputWithDropdown from "../../../../../../components/TagInputWithDropdown";
import FileUploadSidebar from '../../../../../../components/FileUploadSidebar';
import { getAllMediaAction } from '../../../../../../redux/action/AppSettings/Media/mediaAction';
import TooltipTitle from '../../../../../../Tooltip/TooltipTitle';
import JSONMetaDataValueInput from '../../../../../../components/MetaInput';
import XLSX from 'xlsx';
import { BASE_URL } from '../../../../../../resources/APIEndpoints';
import bundlePlaceHolder from '../../../../../../media/images/placeholders/Bundles.png'
import PMPlaceHolder from '../../../../../../media/images/placeholders/Progression_Marker.png'
import currencyPlaceHolder from '../../../../../../media/images/placeholders/Currency.png'
import itemPlaceHolder from '../../../../../../media/images/placeholders/Items.png'
import AddContentModal from "../../../../../../components/Modals/AddRewardModal";
import Modal from "../../../../../../components/Modals/ModalComponent";
import app,{increaseUnreadCountForAllUsers} from "../../../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";
import GlobalStateContext from '../../../../../../components/Context/GlobalStates/GlobalStateContext.js';
import { extarctImageFromUrlAction } from '../../../../../../redux/action/common/commonAction.js';
import SnapShot from '../../../../../../components/Images';

const eventOption = ['Select Events']

const ProgressionSystemInfo = ({className, onClose, generateData,isChanged, setIsChanged}) => {

    const {appid} = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const ref = useRef()

    const globalStates = useContext(GlobalStateContext)
    const [visibleHelp, setVisibleHelp] = useState(false);
    const placeholder = ProgressionSystem
    const [{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'}); 
    const [type, setType] = useState()
    const [progressionIcon, setProgressionIcon] = useState(null)
    const [displayName, setDisplayName] = useState('')
    const [psId, setPSID] = useState('')
    const [progressionId, setProgressionId] = useState(null)
    const [progressionDescription, setProgressionDescription] = useState()
    const [parameter, setParameter] = useState(true)
    const [system, setSystem] = useState(true)
    const [progression, setProgression] = useState(null)
    const [events, setEvents] = useState(eventOption[0])
    const [progressionType, setProgressionType] = useState(false)
    const [progressionSchedule, setProgressionSchdule] = useState(false)
    const [progressionTime, setProgressionTime] = useState()
    const [indexLevel, setIndexLevel] = useState()
    const [metaData, setMetaData] = useState([{key : '' , value : null}])
    const [levels, setLevels] = useState([{no: 1, name : '', incrementalParameterValue : 0, cumulativeParameterValue : 0, archive: false, rewardDetails : {currencies: [], items:[], bundles: [], progressionMarkers:[], rewardSets: []}}])
    const [page, setPage] = useState(1)

    
    useEffect(()=>{
        getProgressionMarker()
        setStorage();
    }, [])

    const setStorage = () => {
        sessionStorage.setItem('Level', JSON.stringify(levels))
    }


    const onPrevious = () => {
        setPage(prevPage => prevPage - 1)
    }

    const onNext = () => {
        setPage(nextPage => nextPage + 1)
    }

    const getProgressionMarker = () => {
        let PM = {
            projectId : appid,
            ids : [],
            offset : 0,
            limit : 500
        }
        dispatch(getAllProgressionMarkerAction(PM))
    }

    let progressionMarker = useSelector((state)=>{
        return state.progressionMarkers.progressionMarker
    })

    const uploadLogo = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        } 
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}`)
        setProgressionIcon(res.data.getUrl);
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
            toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        })
        .catch(function (error) {
            //toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        });
    }

    const Cancel = () => {
        setProgressionIcon(null)
        setLogo({src: placeholder , alt: 'Upload an Image'})
        ref.current.value = ''
    }


    const onDisplayName= (e) => {
        if(e.target.value == ''){
            setDisplayName('')
        }else{
            errors.nameError = ''
            setDisplayName(e.target.value)
        }
    }

    const onProgressionId = (e) => {
        if(e.target.value == ''){
            setPSID('')
        }else{
            errors.idError = ''
            setPSID(e.target.value) 
        }
    }

    const onProgressionDetails= (e) => {
        setProgressionDescription(e.target.value)
    }

    const onProgressionTime= (e) => {
        setProgressionTime(e.target.value)
    }

    const addMetaData = () => {
        const metaDataField = []
        metaDataField.push(...metaData, {key : '' , value : null})
        setMetaData(metaDataField)
    }

    const [tags, setTags] = useState([]);

    const KeyCodes = { comma: 188, enter: 13 };
      
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleDelete = (i) => {
      setTags(tags.filter((tag, index) => index !== i));
    };
  
    const handleAddition = (tag) => {
        if(tags.length < 10){
            setTags([...tags, tag]);
        }else{
            toast.warning(<Toast type='Warning' messages='Only 10 tags are allowed'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        }
    }
  
    const handleDrag = (tag, currPos, newPos) => {
      const newTags = [...tags].slice();
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
  
      setTags(newTags);
    };
  
    const handleTagClick = (index, event) => {
        event.stopPropagation();
    };
  
    const onClearAll = () => {
      setTags([]);
    };
  
    const onTagUpdate = (i, newTag) => {
      const updatedTags = tags.slice();
      updatedTags.splice(i, 1, newTag);
      setTags(updatedTags);
    };

    // To Convert Base64 to Binary
    function convertDataURIToBinary(dataURI) {
        var BASE64_MARKER = ';base64,';
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));
              
        for(var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }
        
    // To Convert file into base64 format
    const convertbase64 = (file) => {
        return new Promise((resolve, err)=>{
        const fileReader = new FileReader();
        fileReader.onload = (eve) =>{
            resolve(fileReader.result)
        }
        fileReader.readAsDataURL(file)
        })
    }

    const addLevels = () => {
        const levelFields = [...levels]
        levelFields.push({no: levels.length + 1, name : '', incrementalParameterValue : null, cumulativeParameterValue : null, archive: false, rewardDetails : {currencies: [], items:[], bundles: [], progressionMarkers:[], rewardSets: []}})
        setLevels(levelFields)
        sessionStorage.setItem('Level', JSON.stringify(levelFields))
    }

    const onChangeLevel = (index, value) => {
        if (/^[a-zA-Z0-9- ]+$/i.test(value) || value == '') {
            const updatedInputData = [...levels];
            updatedInputData[index].name = value;
            setLevels(updatedInputData);
            sessionStorage.setItem('Level', JSON.stringify(levels))
        }
    }

    const onChangeIncrementalParameter = (index, value) => {
        const updatedInputData = [...levels];
        updatedInputData[index].incrementalParameterValue = parseInt(value);
        setLevels(updatedInputData);
        let testValue = 0
        for(var i = 0 ; i < updatedInputData.length ; i++){
            testValue = testValue + updatedInputData[i].incrementalParameterValue
            updatedInputData[i].cumulativeParameterValue = parseInt(testValue);
            setLevels(updatedInputData);
        }
        setLevels(updatedInputData);
        sessionStorage.setItem('Level', JSON.stringify(updatedInputData))
    }


    const handleKeyDown = (event) => {
        if (event.key === '.') {
          event.preventDefault();
        }
    };

    const removeLevel = (index) => {
        let removeField = [...levels]
        let levelFields = removeField.filter((e,i,c)=>{
            return e.no != index
        })
        let testValue = 0
        for(var i = 0 ; i < levelFields.length ; i++){
            testValue = testValue + levelFields[i].incrementalParameterValue
            levelFields[i].cumulativeParameterValue = parseInt(testValue);
            setLevels(levelFields);
        }
        sessionStorage.setItem('Level', JSON.stringify(levelFields))
    }

    const data = (data) => {
        let existingLevels = JSON.parse(sessionStorage.getItem('Level')).map(x => ({...x, no: 0, archive: true}))
        let newLevels = [{no: 1, name : `Level 1`, incrementalParameterValue : 0, cumulativeParameterValue : 0, archive: false, rewardDetails : {currencies: [], items:[], bundles: [], progressionMarkers:[], rewardSets: []}}]
        const levelFields = [...existingLevels, ...newLevels]
        let baseValueParam = data.baseValue
        let incrementalParameter = [1, baseValueParam]
        let cumulativeParameter = [1, baseValueParam]
        for(var i = 1 ; i < data.levels ; i++){
            let incrementalValueParameter = incrementalParameter[i] * data.multiplyer
            let cumulativeValueParameter = cumulativeParameter[i] + incrementalValueParameter
            cumulativeParameter.push(cumulativeValueParameter)
            incrementalParameter.push(incrementalValueParameter)
            if(incrementalParameter[i] > Number.MAX_SAFE_INTEGER || incrementalParameter[i] > Number.MAX_SAFE_INTEGER){
                toast.error(<Toast type='Error' messages='Value Exceeds largest Integer value'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FF6A55'}})
                break;
            }else{
                levelFields.push({no: i+1, name : `Level ${(i+1).toString()}`, incrementalParameterValue : Math.round(incrementalParameter[i]), cumulativeParameterValue : parseInt(cumulativeParameter[i]), archive: false, rewardDetails : {currencies: [], items:[], bundles: [], progressionMarkers:[], rewardSets: []}})
            }
        }
        setLevels(levelFields.sort((a, b) => (a.archive === b.archive) ? 0 : a.archive ? 1 : -1))
        sessionStorage.setItem('Level', JSON.stringify(levelFields.sort((a, b) => (a.archive === b.archive) ? 0 : a.archive ? 1 : -1)))
    }

    const onChangeKey = (e, index) => {
        let keys = [...metaData]
        keys[index].key = e.target.value
        setMetaData(keys)
    }

    const onChangeValue = (e, index) => {
        let values = [...metaData]
        values[index].value = e.target.value
        setMetaData(values)
    }

    const removeMetaData = (index) => {
        let removeField = metaData
        let metaFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setMetaData(metaFields)
    }

    const [errors, setErrors] = useState({nameError : '', idError : '', pmError : ''})

    const validation = (data) => {
        const error = {}
        let isValid = true

        if (data.name == '') {
            error.nameError = 'Display Name is required';
            isValid = false;
            const element = document.getElementById('psName');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        }
      
        if (data.levelSystemId == '') {
            error.idError = 'PS ID is required';
            isValid = false;
            if(data.name != ''){
                const element = document.getElementById('psId');
                const offset = 140;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        if (data.progressionMarkerId == null) {
            error.pmError = 'PM is required';
            isValid = false;
            if(data.name != '' && data.levelSystemId != ''){
                const element = document.getElementById('pmName');
                const offset = 100;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        setErrors(error);

        if(isValid){
            return true
        }
    }

    const parseRewards = (rewards, type) => {
        if (!rewards) return [];
        let rows = rewards.split(', ').map(reward => {
          const match = reward.match(/(.+)\((\d+)\)/);
          return match ? {name: match[1], id: match[1], quantity: parseInt(match[2]), rewardType: type } : null;
        })
        return rows
    };

    const transformData = (data) => {
        return data.map((entry, index) => ({
            no: index+1,
            name : entry['Level Name'],
            incrementalParameterValue: entry["Incremental Parameter Value"],
            cumulativeParameterValue: entry["Cummulative Parameter Value"],
            archive: false,
            rewardDetails: {
                items: parseRewards(entry.Items, 'Items'),
                bundles: parseRewards(entry.Bundles, 'Bundle'),
                currencies: parseRewards(entry.Currencies, 'Currency'),
                progressionMarkers: parseRewards(entry["Progression Markers"], 'PM'),
                rewardSets : []
            }
        }));
      };

    const convertToJson = (data) => {
        let rows = transformData(data)
        return rows
    }

    const convertIds = (data) => {
        const categorizedRewards = {
            rewardDetails : {
                items: { ids: [] },
                bundles: { ids: [] },
                currencies: { ids: [] },
                progressionMarkers: { ids: [] }
            },
            projectId : appid
        }

        data && data.forEach(entry => {
            const { rewardDetails } = entry;
            rewardDetails.currencies.forEach(currency => {
                if (!categorizedRewards.rewardDetails.currencies.ids.includes(currency.id)) {
                    categorizedRewards.rewardDetails.currencies.ids.push(currency.id);
                }
            });
        
            rewardDetails.items.forEach(item => {
                if (!categorizedRewards.rewardDetails.items.ids.includes(item.id)) {
                    categorizedRewards.rewardDetails.items.ids.push(item.id);
                }
            });
        
            rewardDetails.bundles.forEach(bundle => {
                if (!categorizedRewards.rewardDetails.bundles.ids.includes(bundle.id)) {
                    categorizedRewards.rewardDetails.bundles.ids.push(bundle.id);
                }
            });
        
            rewardDetails.progressionMarkers.forEach(marker => {
                if (!categorizedRewards.rewardDetails.progressionMarkers.ids.includes(marker.id)) {
                    categorizedRewards.rewardDetails.progressionMarkers.ids.push(marker.id);
                }
            });
        });
        return categorizedRewards;
    }

    const convertToDesiredData = (data1, data2) => {
        const lookupMap = data2.reduce((acc, item) => {
            acc.id = acc.uuid
            acc[item.id] = item;
            return acc;
        }, {});
      
        // Enhance data1 with additional fields from data2
        const enhanced = data1.map(entry => {
            const { rewardDetails } = entry;
      
            const enhancedRewardDetails = {
              items: rewardDetails.items.map(item => ({
                ...item,
                ...lookupMap[item.id]
              })),
              bundles: rewardDetails.bundles.map(bundle => ({
                ...bundle,
                ...lookupMap[bundle.id]
              })),
              currencies: rewardDetails.currencies.map(currency => ({
                ...currency,
                ...lookupMap[currency.id]
              })),
              progressionMarkers: rewardDetails.progressionMarkers.map(marker => ({
                ...marker,
                ...lookupMap[marker.id]
              })),
              rewardSets : []
            };
      
            return {
              ...entry,
              rewardDetails: enhancedRewardDetails
            };
        });
        return enhanced;
    }

    const onCSV = async(event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = async(e) => {
            const arrayBuffer = e.target.result;
            const data = new Uint8Array(arrayBuffer);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet);
            let xyz = await convertToJson(json)
            setLevels(xyz)
            sessionStorage.setItem('Level', JSON.stringify(xyz))
            let testObject = convertIds(xyz)
            const token = localStorage.getItem('token')
            const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
            axios.post(`${BASE_URL}/rewards-details/get`, testObject, headers)
            .then(res=>{
                let currencies = res.data.currencies.map(i => ({uuid : i.id, id : i.currencyId, rewardId : i.currencyId, description: i.description, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? currencyPlaceHolder : i.iconUrl), name : i.name, status : false, rewardType: 'Currency'}));
                let items = res.data.items.map(i => ({uuid : i.id, id : i.itemId, rewardId : i.itemId, description: i.description, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? itemPlaceHolder : i.iconUrl), name : i.name, status : false, rewardType: 'Items'}));
                let bundles = res.data.bundles.map(i => ({uuid : i.id, id : i.bundleId, rewardId : i.bundleId, description: i.description, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? bundlePlaceHolder : i.iconUrl), name : i.name, status : false, rewardType: 'Bundle'}));
                let progressionMarkers = res.data.progressionMarkers.map(i => ({uuid : i.id, id : i.progressionMarkerId, rewardId : i.progressionMarkerId, description: i.description, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? PMPlaceHolder : i.iconUrl), name : i.name, status : false, rewardType: 'PM'}));
                const rewardList = [...currencies, ...items, ...bundles, ...progressionMarkers]
                const mergedRewards = convertToDesiredData(xyz, rewardList);
                let existingLevels = JSON.parse(sessionStorage.getItem('Level')).map(x => ({...x, no: 0, archive: true}))
                let allLevels = [...mergedRewards, ...existingLevels]
                setLevels(allLevels)
                sessionStorage.setItem('Level', JSON.stringify(allLevels))
            })
        };   
        reader.readAsArrayBuffer(file);
    }

    function removeIconKey(data) {
        return data && data.map(item => {
            const unarchivedRewards = {};
            Object.keys(item.rewardDetails).forEach(key => {
              unarchivedRewards[key] = item.rewardDetails[key].filter(reward => !reward.archive);
            });
        
            return {
              ...item,
              rewardDetails: unarchivedRewards,
            };
        })
    }
    const db = getFirestore(app);
    const save = async() => {
        const metaDataList = [...metaData];
        const finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }
        let PSTags = []
        tags && tags.forEach((e)=>{
            PSTags.push(e.text)
        })

        const updatedData = await removeIconKey(levels)

        let progressionSystem = {
            projectId : appid,
            name : displayName,
            levelSystemId : psId,
            iconUrl : progressionIcon,
            description : progressionDescription,
            progressionMarkerId : progressionId,
            appEventId : null,
            levelSystemTypeId: 1,
            levelDetails : updatedData,
            rewardGrantScheduleType : (progressionSchedule ? "custom" : "on-completion"),
            tags: PSTags,
            meta: finalMetaData
        }
        if(validation(progressionSystem)){
            try {
                // Await the response from createGameAction
                const res = await dispatch(createProgressionSystemAction(progressionSystem, navigate, appid, globalStates.setShowOverlay))
                // Access the response data (e.g., game ID)
                if (res) {
                    const matchIdFromResponse = res?.data?.data?.levelDetails?.[0]?.levelSystem?.id;
    
                    setIsChanged(false);
                    const user = JSON.parse(localStorage.getItem('user'))
                    const memberID = user?.id
                    const memberName = user?.name
                    const orgId = (localStorage.getItem('orgId'))
    
                    // Log activity in Firestore
                    const activityLog = {
                        timestamp: new Date().toISOString(),
                        action_type: "CREATE",
                        app_id:appid,
                        resource: "PROGRESSION SYSTEM",
                        resource_id: matchIdFromResponse, // Use the ID from response
                        description: `Progression System '${displayName}' created successfully`,
                        quantity: 1,
...(localStorage.getItem('environment')&&{environment:localStorage.getItem('environment')}),
operation_status: "SUCCESS",
                        activity_feed: true,
                        priority: "LOW",
                        performed_by_id: memberID,
                        organization_id: orgId,
                        performed_by:memberName
                    };
                    await addDoc(collection(db, "activityFeed"), activityLog);
await increaseUnreadCountForAllUsers( appid);
                    console.log("Activity logged successfully");
                }
            } catch (error) {
                console.error("Error creating game or logging activity:", error);
            }
        }
    }

    const cancel = () => {
        navigate(`/progression-system/${appid}`)
        setIsChanged(false)
    }

    const onTagInput = () => {
        if(document.getElementById('PSTagInput')){
            document.getElementById('PSTagInput').focus()
        }
    }

    const [visibleFile, setVisibleFile] = useState(false)

    const setchange = () => {
        if (
            (progressionIcon==null||progressionIcon==undefined)&&
            (displayName===undefined||displayName==''||displayName=="")&&
            (psId===undefined||psId==''||psId=="")&&
            (progressionDescription==undefined||progressionDescription==''||progressionDescription=="")&&
            (parameter===true)&&
            (progressionId===undefined||progressionId==''||progressionId==""||progressionId===null)&&
            (system===true)&&
            (tags.length==0)&&
            (metaData.length==1&&metaData[0].key == "" && (metaData[0].value == "" || metaData[0].value == null))&&
            (levels.length==1&& levels[0].name =='')
        )
        {
            setIsChanged(false)
        }
        else
        {
            setIsChanged(true)
        }
    }


    useEffect(()=>{
        setchange()
    },[type,progressionIcon,displayName,psId,progressionId,progressionDescription,parameter,system,progression,progressionSchedule,metaData,levels,tags])
    const [media, setMedia] = useState([])

    const getMedia = async() => {
        let getMediaData = {
            projectId : appid,
            category: 'icons',
            typeId : 0
        }
        await dispatch(getAllMediaAction(getMediaData))
    }

    let files = useSelector((state) => {
        return state.media.media.mediaDetails
    })

    const configureMedia = () => {
        if(files && files.length > 0){
            setMedia(files && files.map((e, i) => ({...e, status : false})))
        }else{
            setMedia([])
        }
    }

    useEffect(() => {
        getMedia()
        getAllRewards()
    }, [])

    useEffect(() => {
        configureMedia()
    }, [files])


    const customJsonSave = (json,onclose)=>{
        if(json?.length>0 &&(json[0]?.incrementalParameterValue==0||json[0]?.incrementalParameterValue==null)&&(json[0]?.cumulativeParameterValue==0||json[0]?.cumulativeParameterValue==null)){
            setLevels(json);
            localStorage.setItem('PDRanks', JSON.stringify(json))
            onclose();
        }
        else{
            let initialLevel=[{no: 1, name : '', incrementalParameterValue : 0, cumulativeParameterValue : 0, rewardDetails : {currencies: [], items:[], bundles: [], progressionMarkers:[], rewardSets: []}}]
            setLevels(initialLevel);
            localStorage.setItem('PDRanks', JSON.stringify(initialLevel))
            onclose();
        }
    }


  const [item, setItem] = useState([])
  const [currency, setCurrency] = useState([])
  const [bundle, setBundle] = useState([])
  const [pm, setPM] = useState([])

  const getAllRewards = () => {
      let search = {
          projectId : appid,
          search : ''
      }
      const token = localStorage.getItem('token')
      const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
      axios.post(`${BASE_URL}/inventory/search`, search, headers)
      .then(res=>{
          let currencies = res.data.data.currencies.map(i => ({uuid:i.id, id : i.currencyId, description: i.description,  iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? null : i.iconUrl), name : i.name, status : false, rewardType: 'Currency', type:i.type}));
          let items = res.data.data.inventoryItems.map(i => ({uuid:i.id, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? null : i.iconUrl), id : i.itemId, description: i.description,  name : i.name, status : false, rewardType: 'Items'}));
          let bundles = res.data.data.inventoryBundles.map(i => ({uuid: i.id, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? null : i.iconUrl), id : i.bundleId, name : i.name, status : false, rewardType: 'Bundle'}));
          let pm = res.data.data.progressionMarkers.map(i => ({uuid: i.id, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? null : i.iconUrl), id : i.progressionMarkerId, name : i.name, status : false, rewardType: 'PM'}));
          setItem(items)
          setCurrency(currencies)
          setBundle(bundles)
          setPM(pm)
      })
  }

    return (
        <>
            <ToastContainer />
            <Card className={cn(styles.card, className)} title="Information" classTitle="title-purple">
                    <div className={styles.description}>
                        <div className={styles.preview}>
                            <div className={styles.previewImage}>
                                <SnapShot id='logo' url={progressionIcon} alt={displayName} placeholder={ProgressionSystem} type='Images' width={60} height={60} borderRadius={16}/>
                                {/* <img id='logo' alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img> */}
                            </div>
                            <div className={styles.previewInput}> 
                                <input type="file" className='file-input' accept="image/jpeg, image/png, image/webp, .jpeg, .jpg, .png, .webp" ref={ref} onChange={(e)=>{uploadLogo(e)}}/>
                                <label className={styles.button} onClick={() => setVisibleFile(true)}><AddButtonIcon className={styles.addIcon}/>Upload Icon</label>
                            </div>
                            {progressionIcon != null &&
                                <div className={styles.previewCancel}>
                                    <button className={styles.buttonCancel} onClick={Cancel}>Remove</button>
                                </div>
                            }
                        </div>
                        <FileUploadSidebar media={media} setMedia={setMedia} types='Images' id={`icon`} multiple={false} visible={visibleFile} setVisible={setVisibleFile} icon={progressionIcon} setIcon={setProgressionIcon} setValue={setLogo} onClose={onClose} />
                        <div className={styles.group}>
                            <TextInput id='psName' className={styles.field} errorMessage={errors.nameError} tooltip={TooltipTitle.PSName} label="Display Name*" name="DisplayName" type="text" required onChange={(e)=>{onDisplayName(e)}}/>
                            <TextInput isId={true} value={psId} id='psId' className={styles.field} errorMessage={errors.idError} tooltip={TooltipTitle.PSID} label="Progression System ID*" name="progressionId" type="text" required onChange={(e)=>{onProgressionId(e)}} />
                        </div>
                        <TextArea type= 'PROGRESSION_SYSTEM_DESCRIPTION' className={styles.field} rows={5} value={progressionDescription} tooltip={TooltipTitle.PSDescription} label="Progression System Description" onChange={(e)=>{onProgressionDetails(e)}} />
                        <div className={styles.group}>
                            <div className={styles.fieldRadio}>
                                <div className={styles.radioLabel}>
                                    <span>Select Parameter<span><Tooltip className={styles.tooltip} title={TooltipTitle.PSParameter} icon="info"/></span></span>
                                </div>
                                <div className={styles.variants}>
                                    <Radio className={styles.radio} name="parameter" value={parameter} onChange={() => setParameter(true)} content="Progression Marker" />
                                    <Radio className={styles.radio} name="parameter" value={!parameter} onChange={() => setParameter(false)} content="Statistics" />
                                </div>
                            </div>
                            {parameter ?
                                <>
                                    <Dropdown id='pmName' placeholder='Select Progression Marker' label="Choose Progression Marker*" errorMessage={errors.pmError} tooltip={TooltipTitle.PSPM} type={"progression marker"} options={progressionMarker} value={progression} setValue={setProgression} setId={setProgressionId} groupBy={2} />
                                </>
                            : ''}
                        </div>
                    </div>
                </Card>

                <Card className={cn(styles.card, className)} title="Type" classTitle="title-blue">
                    <div className={styles.cardDescription}>
                        <div className={styles.createProgressionSystem}>
                            <div className={styles.group}>
                                <div className={styles.fieldRadio}>
                                    <div className={styles.radioLabel}>
                                        <span>Progression System Type<span><Tooltip className={styles.tooltip} title={TooltipTitle.PSType} icon="info"/></span></span>
                                    </div>
                                    <div className={styles.variants}>
                                        <Radio className={styles.radio} name="PSType" value={system} onChange={() => setSystem(true)} content="Linear" />
                                        <Radio disabled={true} className={styles.radio} name="PSType" value={!system} onChange={() => setSystem(false)} content="Non-Linear" />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.group}>
                                <div className={styles.levelSystem}>
                                    <div className={styles.levelSystemGroup}>
                                        <div className={styles.levelLabel}>
                                            <span className={styles.levelLabelTxt}>Level</span><span className={styles.levelLabelImg} onClick={addLevels}><AddIcon /></span>
                                        </div>
                                        <div className={styles.configure}>
                                            <Control width="small" levelsDetail={levels} setter={data} className={styles.control} />
                                        </div>
                                        <label className={styles.pdUpload}>
                                            <div className={styles.previewInput}> 
                                              <input id='csv' type="file" className='file-input' accept=".xlsx, .xls, .csv" onChange={(e) => {onCSV(e)}}/>
                                              <label for='csv' className={styles.button}><Icon name='upload' size='20' /> CSV</label>
                                            </div>
                                        </label>
                                        <JsonControl data={levels} value={levels} setValue={setLevels}/>
                                    </div>
                                </div>
                            </div>

                            {levels && levels?.length > 0 ?
                                <div className={styles.groupByThree}>
                                    <p></p>
                                    <p className={styles.fieldTitle}>Level Name</p>
                                    <p className={styles.fieldTitle}>Incremental Parameter Value</p>
                                    <p className={styles.fieldTitle}>Cumulative Parameter Value</p>
                                    <p className={styles.fieldTitle}>Rewards</p>
                                </div>
                            : ''} 
                            
                            {levels && levels?.length > 0 && levels.filter(x => !x.archive).map((e,i)=>{
                                if (i<page*10 && i>(page-1)*10-1) {
                                    return(
                                        <>
                                            <div key={i} className={styles.groupByThree}>
                                                <p className={styles.index}>{i+1}</p>
                                                <TextInput className={styles.field} type="text" key={i} value={e.name} onChange={(e)=> onChangeLevel(i, e.target.value) }/>
                                                <TextInput disabled={i == 0 ? true : false} className={styles.field} type="number" onWheelCapture={e => {e.currentTarget.blur()}} key={i} value={i == 0 ? 0 : e.incrementalParameterValue} onChange={(e) => onChangeIncrementalParameter(i, e.target.value)} onKeyDown={handleKeyDown}/>
                                                <TextInput disabled className={styles.field} type="number" onWheelCapture={e => {e.currentTarget.blur()}} key={i} value={e.cumulativeParameterValue} onKeyDown={handleKeyDown}/>
                                                <p className={styles.field}>
                                                    {levels && ((levels[i].rewardDetails?.currencies?.length + levels[i].rewardDetails?.items?.length + levels[i].rewardDetails?.bundles?.length + levels[i].rewardDetails?.progressionMarkers?.length) === 0) ? 
                                                        <>
                                                            {i != 0 ?
                                                                <span key={i} onClick={() => {setVisibleHelp(true); setIndexLevel(e.no-1) ; setType('add')}}><AddIcon className={styles.icon}/></span> 
                                                            : ''} 
                                                        </>
                                                    : (i != 0 ? 
                                                        <>
                                                            <div className={styles.levelRewards}>
                                                                <div className={styles.reward}>
                                                                    {levels && ((levels[i].rewardDetails?.currencies?.length + levels[i].rewardDetails?.items?.length + levels[i].rewardDetails?.bundles?.length + levels[i].rewardDetails?.progressionMarkers?.length) > 0) ? 
                                                                        (levels[i].rewardDetails  && [...levels[i].rewardDetails?.currencies, ...levels[i].rewardDetails?.items, ...levels[i].rewardDetails?.bundles, ...levels[i].rewardDetails?.progressionMarkers].slice(0,2).map((x,index) => {   
                                                                            if(x.archive === false){
                                                                                return <span><span>{index > 0 && ', '}</span><span>{x.name}</span><span>&nbsp;</span><span>({x.quantity})</span></span>
                                                                            }
                                                                        })) 
                                                                    : ''}
                                                                    {/* <RewardsTooltip text='Rewards' data={levels && levels[i].rewardDetails}><span>{levels[i].rewardDetails.length > 1 ? <span>&nbsp;&#43;&nbsp;{levels[i].rewardDetails.length-2}</span>  : ''}</span></RewardsTooltip> */}
                                                                </div>
                                                                <div className={styles.editIcon} key={i} onClick={() => {setVisibleHelp(true); setIndexLevel(e.no-1) ; setType('edit')}}><EditIcon className={styles.icon}/></div>
                                                            </div>
                                                        </>
                                                    : '')
                                                    }
                                                </p>  
                                                <p className={styles.remove}>
                                                    {i != 0 ?
                                                        <span className={styles.removeIcon} onClick={()=>{removeLevel(e.no)}}><RemoveIcon /></span>
                                                    : ''}
                                                </p>
                                            </div>
                                        </>
                                    )
                                }
                            })}
                            

                            <div>
                                <Modal heading={true} width={'medium'} visible={visibleHelp} onClose={() => setVisibleHelp(false)}>
                                    <AddContentModal amountKey="quantity" categoryIndex={indexLevel} onClose={() => setVisibleHelp(false)} type={"Task"} currency={currency} setCurrency={setCurrency} item={item} setItem={setItem} bundles={bundle} setBundle={setBundle} PM={pm} setPM={setPM} value={levels} setValue={setLevels} />
                                </Modal>
                            </div>


                    {levels.filter(x => !x.archive).length>10 && <div className={styles.pagination}>
                      <div className={styles.icons}>
                        <button disabled={page <= 1 ? true : false}>
                          <Previous className={styles.previous} disabled={true} onClick={onPrevious}/>
                        </button>
                        <span className={styles.pageno}>{page+"/"+ Math.ceil(levels.filter(x => !x.archive).length/10)}</span>
                        <button disabled={levels.filter(x => !x.archive).length <= page*10 ? true : false}>
                          <Next className={styles.next} onClick={onNext}/>
                        </button>
                      </div>
                    </div>}
                        </div>
                    </div>
                </Card>

                <Card className={cn(styles.card, className)} title="Custom Data" classTitle="title-red">
                    <div className={styles.cardDescription}>
                        <TagInputWithDropdown tooltip={TooltipTitle.PSTags} tags={tags} setTags={setTags}/>
                        <div className={styles.createProgressionSystem}>
                            <div className={styles.groupHeading}>
                                <span>Meta Data</span><span><Tooltip className={styles.tooltip} title={TooltipTitle.PSMetaData} icon="info"/></span><span className={styles.addIcon} onClick={addMetaData}><AddIcon /></span>
                            </div>

                            {metaData && metaData.length > 0 &&
                                <div className={styles.groupMeta}>
                                    <div className={styles.field}>Key</div>
                                    <div className={styles.field}>Value</div>
                                </div>
                            }

                            <div className={styles.groupMeta}>
                                {metaData && metaData.map((e,i)=>{
                                    return(
                                        <>
                                            <TextInput className={styles.field} label="" name="key" type="text" required value={(metaData[i].key != '' || metaData[i].key != undefined || metaData[i].key != null) ? metaData[i].key : ''} onChange={(e)=>{onChangeKey(e,i)}}/>
                                            <JSONMetaDataValueInput key={i} className={styles.field} data={(typeof e.value == 'string') ? JSON.stringify(e.value) : e.value} value={metaData} setValue={setMetaData} index={i}/>
                                            {/* <TextInput className={styles.field} label="" name="value" type="text" required value={(metaData[i].value != '' || metaData[i].value != undefined || metaData[i].value != null) ? metaData[i].value : ''} onChange={(e)=>{onChangeValue(e,i)}}/> */}
                                            <span className={styles.remove} onClick={()=>{removeMetaData(i)}}><RemoveIcon className={styles.icon} /></span>
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </Card>

                <Card className={cn(styles.card, className)}>
                    <div className={styles.cardAction}>
                        <div className={styles.cardSave}>
                            <button className={cn(styles.button, 'button-save-small')} onClick={save}>Save</button>
                        </div>
                        <div className={styles.cardCancel}>
                            <button className={cn(styles.button, 'button-cancel-small')} onClick={cancel}>Cancel</button>
                        </div>
                    </div>
                </Card>
        </>
    )
}

export default ProgressionSystemInfo