import React, { useEffect, useState } from "react";
import styles from './overview.module.sass'
import Card from "../../../../../components/Card";
import cn from "classnames";
import { ReactComponent as  SpecterSvg} from "../../../../../media/images/Spectre.svg";
import { Navigate, useNavigate } from "react-router-dom";
import { routes } from "../../../../../routes/routes";
import { ReactComponent as  Cube} from "../../../../../media/images/icons/cube.svg";
import { buyAddOnsAction, updateCardDetailsAction } from "../../../../../redux/action/organisation/Billing/billingAction";
import { useDispatch } from "react-redux";
import Checkbox from "../../../../../components/Checkbox";
import Icon from "../../../../../components/Icon";
import Visa from '../../../../../media/images/visa.png'
import MasterCard from '../../../../../media/images/mastercard_black.png'
import Rupay from '../../../../../media/images/payment.png'
import {ReactComponent as Paypal} from '../../../../../media/images/icons/visa.svg'
import Modal from "../../../../../components/Modals/ModalComponent";
import AddOnModal from "../../../../../components/Modals/Billing/AddOnModal";
import TopUpModal from "../../../../../components/Modals/Billing/TopUpModal";
import moment from "moment";
import { useAccess } from "../../../../../components/Page";
const Overview = ({className, plan, payments, currency}) => {

    const { accessPermissions } = useAccess(); 
    const{allowPlanProceedAccess,allowBillingPaymentEdit} = accessPermissions || {};
    const Navigate = useNavigate()
    const dispatch = useDispatch()
    const [visibleAddOnModal, setVisibleAddOnModal] = useState(false)
    const [visibleTopUpModal, setVisibleTopUpModal] = useState(false)

    const upgradePlan = () => {
        sessionStorage.setItem('plan_code', JSON.stringify(plan?.plan?.planCode || null))
        sessionStorage.setItem('active_plan_code', JSON.stringify(plan?.plan?.planCode || null))
        Navigate(`${routes.plans.path}`)
    }

    const onUpdatePaymentMethod = () => {
        let subscriptionId = {externalSubscriptionId: plan?.subscriptionId}
        dispatch(updateCardDetailsAction(subscriptionId, Navigate))
    }

    const redirectPageAddOn = (type) => {
        Navigate(`/add-on?subscriptionId=${plan.subscriptionId}&planCode=${plan?.plan?.planCode}&type=${type}`)
    }

    const redirectPageTopUp = (type) => {
        Navigate(`/top-up?subscriptionId=${plan.subscriptionId}&planCode=${plan?.plan?.planCode}&type=${type}`)
    }

    return(
        <>
            <Card className={styles.card} classCardHead={styles.head} classTitle={cn("title-purple", styles.title)}>
                <div className={styles.wrapper}>
                    <div className={styles.cardHead}>
                        <p className={styles.title}>Billing Details</p>
                        <p className={styles.info}>Your current plan and billing details.</p>
                    </div>
                    <div className={styles.table}>
                        {/* <div className={styles.planFeatures}>
                            <div className={styles.specter}>
                                {plan?.planDetails?.customFieldHash?.cfPlanimageurl ? 
                                    <img src={plan?.planDetails?.customFieldHash?.cfPlanimageurl} alt="plan_icon" width={120} height={120}/>
                                :
                                    <SpecterSvg />
                                }
                            </div>
                            <div className={styles.seperator}></div>
                            <div className={styles.features}>
                                {plan && plan?.planDetails && plan?.planDetails?.featureDetails && plan?.planDetails?.featureDetails?.features && plan?.planDetails?.featureDetails?.features.map(x => {
                                    return(
                                        <div className={styles.feature}>
                                            <div className={styles.cube}>
                                                <Cube />
                                            </div>
                                            <div className={styles.title}>
                                                {x.name}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div> */}

                        <div className={styles.fields}>
                            <div className={styles.container}>
                                <div className={styles.addOn}>
                                    <div className={styles.headerName}>Item Name</div>
                                    <div className={styles.header}>Quantity</div>
                                    <div className={styles.header}>Amount</div>
                                </div>
                                <div className={styles.head}>
                                    <div className={styles.subtitle}>Plan</div>
                                    {allowPlanProceedAccess && <div onClick={upgradePlan} className={styles.buy}>Upgrade</div>}
                                </div>
                                <div className={styles.addOn}>
                                    <div className={styles.fieldName}>{plan?.plan?.name}</div>
                                    <div className={styles.field}>{plan?.plan?.quantity}</div>
                                    <div className={styles.field}><span className={styles.symbol}>{plan.currencyCode === 'INR' ? <>&#8377;</> : plan.currencyCode === 'USD' ? <>&#36;</> : null}</span>{plan?.plan?.total}</div>
                                </div>
                            </div>
                                    
                            <div className={styles.container}>
                                <div className={styles.head}>
                                    <div className={styles.subtitle}>Add Ons</div>
                                    {plan.addons && plan.addons?.length !== 0 && allowPlanProceedAccess && <div className={styles.buy} onClick={() => {setVisibleAddOnModal(true)}}>Upgrade Add-Ons</div>}
                                    {plan.addons && plan.addons?.length === 0 && allowPlanProceedAccess && <div className={styles.buy} onClick={() => {setVisibleAddOnModal(true)}}>Buy Add-Ons</div>}
                                </div>
                                {plan.addons && plan.addons?.length === 0 ?
                                    <>
                                        <div className={styles.addOn}>
                                            <div className={styles.fieldName}>No Add-Ons Opted</div>
                                            <div className={styles.field}>-</div>
                                            <div className={styles.field}>-</div>
                                        </div>
                                    </>
                                :
                                    <>
                                        {plan.addons && plan.addons.map(x => (
                                            <>
                                                <div className={styles.addOn}>
                                                    <div className={styles.fieldName}>{x?.name}</div>
                                                    <div className={styles.field}>{x?.quantity ? x?.quantity : '-'}</div>
                                                    <div className={styles.field}>{x?.total ? <>{currency === 'Rupee' ? <>&#8377;</> : <>&#36;</>}{x?.total}</> : '-'}</div>
                                                </div>
                                            </>
                                        ))}
                                    </>
                                }
                                    
                                </div>

                                <div className={styles.container}>
                                    <div className={styles.head}>
                                        <div className={styles.subtitle}>Top Ups</div>
                                        {plan.addons && plan.addons.filter(x => x?.type === 'one-time')?.length !== 0 && allowPlanProceedAccess && <div className={styles.buy} onClick={() => {setVisibleTopUpModal(true)}}>Upgrade Top-Ups</div>}
                                        {plan.addons && plan.addons.filter(x => x?.type === 'one-time')?.length === 0 && allowPlanProceedAccess && <div className={styles.buy} onClick={() => {setVisibleTopUpModal(true)}}>Buy Top-Ups</div>}
                                    </div>
                                    {plan.addons && plan.addons.filter(x => x?.type === 'one-time')?.length === 0 ?
                                        <>
                                            <div className={styles.addOn}>
                                                <div className={styles.fieldName}>No Top-Ups Opted</div>
                                                <div className={styles.field}>-</div>
                                                <div className={styles.field}>-</div>
                                            </div>
                                        </>
                                    : 
                                        <>
                                            {plan.addons && plan.addons.filter(x => x?.type === 'one-time').map(x => (
                                                <>
                                                    <div className={styles.addOn}>
                                                        <div className={styles.fieldName}>{x?.name}</div>
                                                        <div className={styles.field}>{x?.quantity ? x?.quantity : '-'}</div>
                                                        <div className={styles.field}>{x?.price ? <>{currency === 'Rupee' ? <>&#8377;</> : <>&#36;</>}{x?.price}</> : '-'}</div>
                                                    </div>
                                                </>
                                            ))}
                                        </>
                                    }
                            </div>

                            <div className={styles.container}>
                                
                                <div className={styles.seperator}></div>

                                <div className={styles.addOn}>
                                    <div className={styles.fieldName}>Net Total</div>
                                    <div className={styles.field}>-</div>
                                    <div className={styles.field}>{plan.subTotal && plan.subTotal !== null && plan.subTotal !== '' ? <><span className={styles.symbol}>{plan.currencyCode === 'INR' ? <>&#8377;</> : plan.currencyCode === 'USD' ? <>&#36;</> : null}</span><span className={styles.amount}>{Math.floor(plan.subTotal)}</span><span className={styles.price}>{(plan.subTotal - Math.floor(plan.subTotal)) > 0 ? '.' + ((plan.subTotal - Math.floor(plan.subTotal)) * 100).toFixed(0) : ''}</span></> : '-'}</div>
                                </div>
                                
                                {plan?.taxes && plan?.taxes?.map(x => (
                                    <div className={styles.addOn}>
                                        <div className={styles.fieldName}>{x?.taxName}</div>
                                        <div className={styles.field}>-</div>
                                        <div className={styles.field}>{x?.taxAmount && x?.taxAmount !== null && x?.taxAmount !== '' ? <><span className={styles.symbol}>{plan.currencyCode === 'INR' ? <>&#8377;</> : plan.currencyCode === 'USD' ? <>&#36;</> : null}</span><span className={styles.amount}>{Math.floor(x?.taxAmount)}</span><span className={styles.price}>{(x?.taxAmount - Math.floor(x?.taxAmount)) > 0 ? '.' + ((x?.taxAmount - Math.floor(x?.taxAmount)) * 100).toFixed(0) : ''}</span></> : '-'}</div>
                                    </div>
                                ))}

                                <div className={styles.seperator}></div>

                                <div className={styles.addOn}>
                                    <div className={styles.fieldName}>Amount Due</div>
                                    <div className={styles.field}>-</div>
                                    <div className={styles.field}>{plan.amount && plan.amount !== null && plan.amount !== '' ? <><span className={styles.symbol}>{plan.currencyCode === 'INR' ? <>&#8377;</> : plan.currencyCode === 'USD' ? <>&#36;</> : null}</span><span className={styles.amount}>{Math.floor(plan.amount)}</span><span className={styles.price}>{(plan.amount - Math.floor(plan.amount)) > 0 ? '.' + ((plan.amount - Math.floor(plan.amount)) * 100).toFixed(0) : ''}</span></> : '-'}</div>
                                </div>

                            </div>

                            <div className={styles.cards}>

                                <div className={styles.card}>
                                    <div className={styles.head}>
                                        <p className={styles.titles}>Next Billing Date</p>
                                        <p className={styles.info}>Your next billing date.</p>
                                    </div>
                                    <div className={styles.body}>
                                        <div className={styles.info}>
                                            <div className={styles.date}>{moment(plan?.nextBillingAt).format('DD-MM-YYYY')}</div>
                                            <div className={styles.amount}>
                                                {plan.amount && plan.amount !== null && plan.amount !== '' ? <><span className={styles.symbol}>{plan.currencyCode === 'INR' ? <>&#8377;</> : plan.currencyCode === 'USD' ? <>&#36;</> : null}</span><span className={styles.amount}>{Math.floor(plan.amount)}</span><span className={styles.price}>{(plan.amount - Math.floor(plan.amount)) > 0 ? '.' + ((plan.amount - Math.floor(plan.amount)) * 100).toFixed(0) : ''}</span></> : '-'}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.card}>
                                    <div className={styles.head}>
                                        <p className={styles.titles}>Payment Method</p>
                                        <p className={styles.info}>Your payment method.</p>
                                    </div>
                                    <div className={styles.body}>
                                        <div className={styles.detail}>
                                            <div className={styles.paymentsMethod}>
                                                <div className={styles.left}>
                                                    <div className={styles.cc}>
                                                        <div className={styles.type}>
                                                            {payments[0]?.cardType === 'visa' && <><img src={Visa} alt={payments[0]?.cardType} width={30} height={30}/></>}
                                                            {payments[0]?.cardType === 'mastercard' && <><img src={MasterCard} alt={payments[0]?.cardType} width={30} height={30} /></>}
                                                            {payments[0]?.cardType === 'rupay' && <><img src={Rupay} alt={payments[0]?.cardType} width={30} height={30} /></>}
                                                            {payments[0]?.cardType === 'paypal' && <><Paypal /></>}
                                                        </div>
                                                        {allowBillingPaymentEdit&&<span className={styles.decorate} onClick={onUpdatePaymentMethod}><Icon name='edit' size='16'/></span>}
                                                    </div>
                                                    <div className={styles.cardDetail}>
                                                        <div className={styles.digit}>{"**** **** **** " + payments[0]?.lastFourDigits}</div>
                                                        <div className={styles.expiry}>Exp Date: {payments[0]?.expiryMonth}/{(payments[0]?.expiryYear)?.toString()?.slice(-2)}</div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </Card>
            <Modal width='large' visible={visibleAddOnModal} onClose={() => {setVisibleAddOnModal(false)}}>
                <AddOnModal planCode={plan?.plan?.planCode} subscriptionId={plan?.subscriptionId} onClose={() => {setVisibleAddOnModal(false)}}/>
            </Modal>
            <Modal width='large' visible={visibleTopUpModal} onClose={() => {setVisibleTopUpModal(false)}}>
                <TopUpModal planCode={plan?.plan?.planCode} subscriptionId={plan?.subscriptionId} onClose={() => {setVisibleTopUpModal(false)}}/>
            </Modal>
        </>
    )

}

export default Overview