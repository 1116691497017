import React, { useEffect, useState } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../Checkbox";
import Icon from "../../../../Icon";
import Control from "./Control";
import Schedule from "./Schedule";
import { Link, useParams } from "react-router-dom";
import Competition from '../../../../../media/images/placeholders/Currency.png'
import TooltipGlodal from "../../../../TooltipGlodal";
import DateTooltip from "../../../../CustomTooltips/DateTooltip";
import moment from "moment";
import 'moment-timezone';
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { extarctImageFromUrlAction } from "../../../../../redux/action/common/commonAction";
import SnapShot from "../../../../Images";
/* Deleted Schedule, Modal, Modal Products and Actions */

const Row = ({ item, className, access, value, onChange,isLast }) => {
    
    const {appid} = useParams()

    let userTimeZone = useSelector((state) => {
        return state.auth.user.timezone        
    })

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                    <div className={styles.item}>
                        {item.icon !== undefined &&
                            <SnapShot id={`tournamentIcon_${item.id}`} url={item.icon} alt={item.name} placeholder={Competition} type='Images' width={36} height={36}/>
                        }
                    </div>
                </div>
                <div className={styles.col}>
                    <Link to={`/view-competition-tournament/${appid}/${item.id}`}>
                        <TooltipGlodal width={140} itemId={`tournament${item.id}`} title={item.name}><div className={styles.itemName}><span id={`tournament${item.id}`}>{item.name}</span></div></TooltipGlodal>
                        <div className={styles.itemId}>{item.competitionId}</div>
                    </Link>
                </div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 &&
                        <>{item.minPlayers !== null ? item.minPlayers : ''}/{item.maxPlayers !== null ? item.maxPlayers : <>&infin;</>}</>
                    } 
                </div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 &&
                        <>
                            <TooltipGlodal width={95} itemId={`tournament${item.match != null ? item.match.name : item.defaultStatistic != null ? item.defaultStatistic.name : '-'}`} title={item.match != null ? item.match.name : item.defaultStatistic != null ? item.defaultStatistic.name : '-'}><div className={styles.sourceName} id={`tournament${item.match != null ? item.match.name : item.defaultStatistic != null ? item.defaultStatistic.name : '-'}`}>{item.match != null ? item.match.name : item.defaultStatistic != null ? item.defaultStatistic.name : '-'}</div></TooltipGlodal>
                            <div className={styles.id}>{item.leaderboardSourceTypeMaster != null ? item.leaderboardSourceTypeMaster.name : '-'}</div>
                        </>
                    }
                </div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 &&
                        <>
                            {item.scheduleDetails?.length === 0 && <>-</>}
                            {item.scheduleDetails?.length > 0 && 
                                <DateTooltip userTimeZone={userTimeZone} isLast={isLast} timeZone={(item.scheduleDetails?.length > 0 && item.scheduleDetails[0].timezone != null && item.scheduleDetails[0].timezone != '') ? item.scheduleDetails[0].timezone : null} startDate={item.scheduleDetails[0].startDate} endDate={item.scheduleDetails[0].endDate}>
                                    {item.scheduleDetails[0].startDate != null ? moment(item.scheduleDetails[0].startDate).tz(userTimeZone).format('MMM DD') : ''}  {item.scheduleDetails[0].endDate != null ? <> &#45; {moment(item.scheduleDetails[0].endDate).tz(userTimeZone).format('MMM DD')}</> : <><span>&#45;</span> <span className={styles.infinity}><span className={styles.icon}>&#8734;</span></span></>}
                                </DateTooltip>
                            }
                            
                            {item.scheduleDetails?.length > 0 && 
                                <div className={styles.instance}>
                                    {item.scheduleDetails[0].scheduleInstances?.length}/{item.scheduleDetails[0].recurrenceCount}&nbsp;({item.scheduleDetails[0].recurrenceFrequency != null ? item.scheduleDetails[0].recurrenceFrequency : null}{item.scheduleDetails[0].intervalUnit != null ? (item.scheduleDetails[0].intervalUnit.intervalName === 'all_time' ? ' All Time' : item.scheduleDetails[0].intervalUnit.intervalName === 'custom' ? ' custom' : <>&nbsp;{item.scheduleDetails[0].intervalUnit.intervalName}</>) : ''})
                                </div>
                            }
                        </>
                    }
                </div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 &&
                        <Schedule userTimeZone={userTimeZone} isLast={isLast} usedFor='instance' as='schedule' type='Tournament' offset={item.prizeDistributionOffset} instanceId={item.scheduleDetails?.length > 0 ? item.scheduleDetails[0].scheduleInstances[0].id : null} scheduleId={item.scheduleDetails?.length > 0 ? item.scheduleDetails[0].id : null} schedule={item.scheduleDetails?.length > 0 ? item.scheduleDetails[0].scheduleInstances : []} id={item.id} interval={item.intervalId} name={item.name != null ? item.name : ''} status={item.scheduleDetails?.length > 0 ? item.scheduleDetails[0].status : null} appid={appid} timeZone={(item.scheduleDetails?.length > 0 && item.scheduleDetails[0].timezone != null && item.scheduleDetails[0].timezone != '') ? item.scheduleDetails[0].timezone : null}/>
                    }
                </div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 &&
                        <Schedule userTimeZone={userTimeZone} usedFor='status' as='schedule' type='Tournament' offset={item.prizeDistributionOffset} instanceId={item.scheduleDetails?.length > 0 ? item.scheduleDetails[0].scheduleInstances[0].id : null} scheduleId={item.scheduleDetails?.length > 0 ? item.scheduleDetails[0].id : null} schedule={item.scheduleDetails?.length > 0 ? item.scheduleDetails[0].scheduleInstances : []} id={item.id} interval={item.intervalId} name={item.name != null ? item.name : ''} status={item.scheduleDetails?.length > 0 ? item.scheduleDetails[0].status : null} appid={appid} timeZone={(item.scheduleDetails?.length > 0 && item.scheduleDetails[0].timezone != null && item.scheduleDetails[0].timezone != '') ? item.scheduleDetails[0].timezone : null}/>
                    }
                </div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 &&
                        <Control isLast={isLast} PD={item.prizeDistributionRule != null && item.prizeDistributionRule?.length > 0 ? true : false} access={access} id={item.id} name={item.name != null ? item.name : ''} archive={item.archive} type='tournament' appid={appid} interval={item.intervalId} instanceId={item.scheduleDetails?.length > 0 ? item.scheduleDetails[0].scheduleInstances[0].id : null} scheduleInstance={item.scheduleDetails?.length > 0 ? item.scheduleDetails[0].scheduleInstances : null} status={item.scheduleDetails?.length > 0 ? item.scheduleDetails[0].scheduleInstances[0].status : null} usedFor='competition' />
                    }
                </div>
            </div>
        </>
    );
};

export default Row;
