import React, {useState, useEffect, useContext} from 'react'
import cn from 'classnames'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styles from './getMembers.module.sass'
import Card from '../../../../../components/Card'
import Icon from '../../../../../components/Icon'
import addIcon from '../../../../../media/images/icons/light.png'
import Form from '../../../../../components/Form'
import { ToastContainer } from 'react-toastify';
import MembersTableLayout from '../../../../../components/AppSettingComponent/MemberComponent/OrgMemberTableComponent'
import Control from './Control'
import { getAcceptedMembersAction, getInvitedMembersAction } from '../../../../../redux/action/AppSettings/Members/MembersAction'
import Loader from '../../../../../components/Loaders/LoaderProgress'
import {ReactComponent as Previous} from '../../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../../media/images/icons/next.svg'
import EmptyState from '../../../../../components/EmptyStates'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import {ReactComponent as ArchiveSvg} from '../../../../../media/images/icons/archive.svg'
import GlobalStateContext from '../../../../../components/Context/GlobalStates/GlobalStateContext'
import Navigation from '../../../../../components/Header/SecondaryNavigation';
import SkeletonTable from '../../../../../components/Skeleton/TableSkeleton';
import SkeletonEmptyState from '../../../../../components/Skeleton/EmptyStateSkeleton';
import OrganizationHeader from '../../../../../components/Header/OrganizationHeader'
import { getallAppAction } from '../../../../../redux/action/apps/appsAction'
import { BASE_URL, GET_APPS } from '../../../../../resources/APIEndpoints'
import axios from 'axios'

const Tabs = ["Accepted", "Invited"];

const GetMembers = ({className}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {appid} = useParams()

  const [activeIndex, setActiveIndex] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState()
  const [apps, setApps] = useState([])
  // Skeleton
  const [showSkeleton, setShowSkeleton] = useState(true)
  const [appList, setAppsList] = useState([])
  const getAllApps = async(orgID,api_keys) => {
    let appsDetails = []
      const token = localStorage.getItem('token')
      const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ?  JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ?  JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey :  JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}

      let getAllAppsByOrg = {
        organisationId: localStorage.getItem('orgId'),
        getAllApps:true
      }
      // let appid
      let res = await axios.post(BASE_URL + GET_APPS, getAllAppsByOrg, headers)
      if(res && res.status == 200 && res?.data?.data?.projectDetails){
          // appid=res.data.data.projectDetails && res.data.data.projectDetails[0].id
          setAppsList(res.data.data.projectDetails);
      }
  }
  // const getAllApps = () => {
  //   let apps = {
  //     organisationId: localStorage.getItem('orgId')
  //   }
  //   dispatch(getallAppAction(apps))
  // }

  useEffect(()=>{
    getAllApps()
  }, [])

  // let appList = useSelector((state) => {
  //   return state.apps.app
  // })

  const configureApps = () => {
    if(appList && appList.length > 0){
      setApps(appList && appList.map((e) => ({...e, checked : true, permission : {id : 1, name : 'View Only'}})))
    }
  }

  useEffect(() => {
    configureApps()
  }, [appList])

  const [userAccess, setUserAccess] = useState(false)
  const globalStates = useContext(GlobalStateContext)
  
    const getUserAccess = () => {
      if(globalStates && globalStates.user && globalStates.user.memberAccessConfig?.length > 0){
        let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
        if(appPermission?.length > 0){
          setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
        }
      }
    }
    
    useEffect(() => {
      getUserAccess()
    }, [])

  const getAllAppMembers = () => {
    let getMember = {
      organisationId : localStorage.getItem('orgId'),
      projectId : appid,
      status : 'invited'
    }
    dispatch(getInvitedMembersAction(getMember, configureSkeleton))
  }

  useEffect(() =>{
    getAllAppMembers()
  }, [page])

  const configureSkeleton = () => {
    setShowSkeleton(true)
    setTimeout(() => {
      setShowSkeleton(false)
    }, 1000)
  }

  let acceptedMembers = useSelector((state) => {
    return state.members.acceptedMembers
  })

  let invitedMembers = useSelector((state) => {
    return state.members.invitedMembers
  })

  let isLoading = useSelector((state)=>{
    return state.members.isLoading
  })

  // Loader
  if(isLoading){
    return(
      <>
        <div className={styles.loading}>
          <Loader />
        </div>
      </>
    )
  }

  return(
    <>
        <ToastContainer />
        <OrganizationHeader title='Invited Members' apps={apps} setApps={setApps}/>
        <Card layOut={`Get`} className={styles.card} classCardHead={styles.head} classTitle={cn("title-purple", styles.title)}>
          <div className={styles.member}>
            <div className={styles.wrapper}>
              {showSkeleton && invitedMembers?.length === 0 && 
                <SkeletonEmptyState />
              }
              {showSkeleton && invitedMembers?.length > 0 && 
                <SkeletonTable rows={invitedMembers.length} column={5} controls={1} type={['recatangle', 'recatangle', 'recatangle', 'recatangle', 'circular']} colWidth={[36, 100, 100, 100, 36]} colHeight={[36, 15, 25, 25, 36]} />
              }
              {!showSkeleton && invitedMembers?.length === 0 &&
                <EmptyState useFor='org' type="OrgMembers" memberAction='add' apps={apps} setApps={setApps} access={globalStates?.user?.type !== 'admin'}/>
              }
              {!showSkeleton && invitedMembers?.length > 0 &&
                <MembersTableLayout to='invited' items={invitedMembers} title="Last edited" apps={apps} setApps={setApps} userType={globalStates?.user?.type}/>
              }
            </div>
          </div>
        </Card>        
    </>
  )

}

export default GetMembers