import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import Icon from "../../../../../Icon";
// import Modal from "../../../../../../Modals/ModalComponent";
// import DeleteMarkerModal from "../../../../../../Modals/Build/Progression/DeleteModal";
// import ArchiveMarkerModal from "../../../../../../Modals/Build/Progression/ArchiveModal";
import {ReactComponent as ArchiveSvg} from '../../../../../../media/images/icons/archive.svg'
import EyeIcon from '../../../../../../media/images/icons/eye.png'
import { useAccess } from "../../../../../Page";

const Control = ({className, access, id, appid, archiveStatus, deleteStatus, markerName}) => {

  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleArchiveModal, setVisibleArchiveModal] = useState(false)
  const { accessPermissions } = useAccess();
  const { allowDefaultEventEdit} = accessPermissions || {};

  const actions = [
    {
      pathName : `/view-default-events/${appid}/${id}`,
      action: () => console.log("view"),
    },
    {
      icon: "edit",
      pathName : `/edit-default-events/${appid}/${id}`,
      action: () => console.log("edit"),
    },
    // {
    //   icon: "download",
    //   action: () => setVisibleArchiveModal(true),
    // },
    // {
    //   icon: "trash",
    //   action:  () => setVisibleModal(true),
    // }
  ];

  
  return (
    <>
      <div className={cn(styles.control, className)}>
      {access ? 
          (actions && actions.slice(0, 1).map((x, index) => {
            if(
              (!allowDefaultEventEdit &&x?.pathName?.includes("/edit-item"))
            ){
              return ;
            }
            return(
              <Link to={x.pathName} state={{id : id, appid : appid, markerName : markerName, archiveStatus : archiveStatus}} className={styles.button} key={index} onClick={x.action}>
                <img src={EyeIcon} width={20} height={20} className={styles.icon} />
              </Link>
            )
          }))
        : 
          (actions && actions.slice(1).map((x, index) => {
            if(
              (!allowDefaultEventEdit &&x?.pathName?.includes("/edit-item"))
            ){
              return ;
            }
            return(
              <Link to={x.pathName} state={{id : id, appid : appid, markerName : markerName, archiveStatus : archiveStatus}} className={styles.button} key={index} onClick={x.action}>
                {x.icon == 'download' ?  <ArchiveSvg /> : <Icon name={x.icon} size="20" className={styles.icon}/>}
              </Link>
            )
          }))
        }  
      </div>
      {/* <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <DeleteMarkerModal onClose={() => setVisibleModal(false)}/>
      </Modal>
      <Modal visible={visibleArchiveModal} onClose={() => setVisibleArchiveModal(false)}>
        <ArchiveMarkerModal onClose={() => setVisibleArchiveModal(false)}/>
      </Modal> */}
    </>
  );
};

export default Control;
