import react, { useEffect, useState } from 'react'
import Card from '../../../components/Card'
import cn from 'classnames'
import styles from './activity.module.sass'
import {ReactComponent as EmptyFolder} from '../../../media/images/placeholders/emptyFolder.svg'
import {ReactComponent as Game} from '../../../media/images/icons/game.svg'
import {ReactComponent as Key} from '../../../media/images/icons/key.svg'
import {ReactComponent as Design} from '../../../media/images/icons/design.svg'
import {ReactComponent as Edit} from '../../../media/images/icons/edit.svg'
import Icon from '../../../components/Icon'
import { useSelector } from 'react-redux'
import { collection, getDocs, where, query, orderBy, limit, startAfter, getFirestore } from "firebase/firestore"; 
import { Link, useParams } from 'react-router-dom'
import app from "../../../firebase.js"
import moment from 'moment'
import { ReactComponent as  ServerIcon} from "../../../media/images/icons/server_icon.svg";

const Activity = ({className, data}) => {

    const {appid} = useParams()
    const db = getFirestore(app)
    const [activities, setActivities] = useState([]);
        
    const fetchActivities = async () => {
        try {
          const q = query(
            collection(db, "activityFeed"),
            where("activity_feed", "==", true), // Only include documents where activity_feed is true
            where("app_id", "==", appid),
            orderBy("timestamp", "desc"),
            limit(4)
          );
        
          const querySnapshot = appid && await getDocs(q);
          const newActivities = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          console.log(newActivities, "New Activity")
          setActivities((prevActivities) => {
            const prevActivityIds = new Set(prevActivities.map((activity) => activity.id));
            const uniqueActivities = newActivities.filter((activity) => !prevActivityIds.has(activity.id) && activity?.environment === localStorage.getItem('environment'));
            return [...prevActivities, ...uniqueActivities];
          });

        } catch (error) {
          console.error("Error fetching activities: ", error);
        } finally {
        
        }
    }

    useEffect(() => {
        fetchActivities()
    }, [])

    const boldText = (text) => {
        return text.replace(/'(.*?)'/g, (match, p1) => {
          return `<span style="color: #2A85FF">${p1}</span>`;
        });
    }

    if(activities){
        return(
            <>
                <Card className={cn(styles.card, className)} title="Activity" classTitle="title-blue">
                    <div className={styles.progressionContainer}>
                        <div className={styles.progression}>
                            {activities && activities.length === 0 &&
                                <div className={styles.emptyState}>
                                    <div className={styles.emptyFolder}>
                                        <EmptyFolder />
                                    </div>
                                    <div className={styles.emptyTitle}><span>You have no <Link to={`/activityFeed/${appid}`}>Activity</Link> yet.</span></div>
                                </div>
                            }

                            {activities && activities.length > 0 &&
                            <>
                                <div className={styles.activityFeedsContainer}>
                                    {activities && activities.slice(0,5).map((activity,i) => {
                                        return(
                                            <>
                                                <li key={activity.id} className={styles.activityItem}>
                                                    <span className={styles.iconWrapper}>
                                                    <div onClick={activity?.environment === 'Development' ? () =>{} : null} style={{marginLeft:"auto"}}>
                                                            <div className={cn(styles.itemContainer, {[styles.active]: true}, {[styles.disabled] : (activity?.environment !== 'Development' ? true : false)})}>
                                                                <div className={activity?.environment == 'Production' ? styles.production : activity?.environment == 'Quality Assurance' ? styles.staging :activity?.environment == 'Development' ? styles.develop:styles.global} title={activity?.environment??"Global"}>
                                                                    <ServerIcon className={styles.logo} />
                                                                </div>
                                                                {/* <div className={styles.appName}>
                                                                    <p className={styles.env}>{activity?.environment??"Global"}</p>
                                                                </div> */}
                                                            </div> 
                                                    </div>
                                                    {/* <Icon className={cn(styles.activityIcon, {[styles.create] : (activity?.action_type === "CREATE")},  {[styles.edit] : (activity?.action_type === "UPDATE")},  {[styles.delete] : activity?.action_type === "ARCHIVE"})} name={activity?.action_type === "UPDATE" ? "edit" : activity?.action_type === "ARCHIVE" ? "trash": activity?.action_type === "CREATE" ? "file-add": "activity"} size="20" /> */}
                                                    </span>
                                                    <div className={styles.activityContent}>
                                                    <div className={styles.performedBy}>
                                                        <div className={styles.activity}>
                                                            <div title={activity.description} className={styles.description} dangerouslySetInnerHTML={{__html: boldText(activity.description)}}></div>
                                                            <div className={styles.userdetail}>
                                                            <div className={styles.name}>by {activity.performed_by}</div>
                                                            <div className={styles.timeStamp}>
                                                                <div className={styles.date}>
                                                                <div>{activity?.timestamp && moment(activity?.timestamp).format('DD/MM/YYYY hh:mm A')}</div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </>
                                        )
                                    })}
                                </div>
                                <div className={styles.viewAll}>
                                    <Link className={styles.all} to={`/activityFeed/${appid}`}>View All</Link>
                                </div>
                            </>
                            }
                        </div>
                    </div>
                </Card>
            </>
        )
    }

}

export default Activity