import React, { useState } from "react";
import styles from "./table.module.sass";
import cn from "classnames";
import Checkbox from "../../../Checkbox";
import Row from "./Row";

const Table = ({type, items, title, to, setAppName, setShowLoader, setLoaderType, userType }) => {

  const desiredLength = 10;
  items = items.concat(Array(desiredLength - items.length).fill({})).slice(0, desiredLength)

  return (
    <>
        <div className={styles.wrapper}>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.col}>Name</div>
              <div className={styles.col}>Status</div>
              <div className={styles.col}>No of Members</div>
              <div className={styles.col}>Created At</div>
              <div className={styles.col}></div>
            </div>
            {items && items.map((x, index) => (
              <Row userType={userType} setLoaderType={setLoaderType} setAppName={setAppName} setShowLoader={setShowLoader} to={to} type={type} item={x} key={index} index={index} />))}
          </div>
        </div>
    </>
  );
};

export default Table;
