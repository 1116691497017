import React , {useEffect, useState} from 'react'
import styles from './cardLayout.module.sass'
import cn from "classnames";
import Control from "./Control/index";
import Icon from "../../../Icon";
import Checkbox from "../../../Checkbox";
import { Link } from 'react-router-dom';
import { routes } from '../../../../routes/routes';
import App from '../../../../media/images/placeholders/App.png'
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { extarctImageFromUrlAction } from '../../../../redux/action/common/commonAction';
import SnapShot from '../../../Images';

const AppsCardLayout = ({className,setLoaderType,item,value,onChange,released,withoutСheckbox, setAppName, setShowLoader}) => {

    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch()

    const handleClick = () => {
        onChange();
        setVisible(!visible);
    };

    
    const refreshPage = (name,x) => {
        setAppName(name)
        localStorage.setItem('AppIcon', x.iconUrl)
        localStorage.setItem('App', name)
        localStorage.setItem('environment', "Development")
        setShowLoader(true)
        setLoaderType(4)
        setTimeout(() => {
            setShowLoader(false)
        }, 4000)
        // window.location.reload()
    }


    return (
        <>
            <div onClick={()=>{refreshPage(item.name,item)}} className={cn(styles.product, className, {[styles.active]: visible})}>
                <Link to={`/app-overview/${item.id}`}>
                    <div>
                        <div className={styles.card}>
                            <div className={styles.cardHeader}>
                                <div className={styles.cardItemImage}>
                                    <SnapShot id={`appIcon_${item.id}`} url={item.iconUrl} alt={item.name} placeholder={App} type='Images' width={44} height={44}/>
                                    {/* <img id={`appIcon_${item.id}`} alt="Items" /> */}
                                </div>
                                <div className={styles.itemDetails}>
                                    <div className={styles.itemContent}>
                                        <div className={styles.itemName}><div className={styles.overflow}>{item.name}</div></div>
                                        <div className={styles.createdAt}>
                                            <span>
                                                {moment().diff(item.updatedAt, 'minutes') > 0 && moment().diff(item.updatedAt, 'minutes') < 60 ? 
                                                `${moment().diff(item.updatedAt, 'minutes')} minutes ago`
                                                :  moment().diff(item.updatedAt, 'minutes') > 60 && moment().diff(item.updatedAt, 'minutes') < 1140 ?
                                                `${moment().diff(item.updatedAt, 'hours')} hours ago`
                                                : moment().diff(item.updatedAt, 'minutes') > 1140 && moment().diff(item.updatedAt, 'minutes') < 43200 ?
                                                `${moment().diff(item.updatedAt, 'days')} days ago`
                                                :  moment().diff(item.updatedAt, 'minutes') > 43200 && moment().diff(item.updatedAt, 'minutes') < 525600 ?
                                                `${moment().diff(item.updatedAt, 'months')} months ago`
                                                : moment().diff(item.updatedAt, 'minutes') > 525600 ?
                                                `${moment().diff(item.updatedAt, 'years')} years ago`
                                                : `${moment().diff(item.updatedAt, 'seconds')} seconds ago`}
                                            </span>
                                        </div>
                                        {/* <div className={styles.price}>{item.active ? 'Active' : 'Archive'}</div> */}
                                    </div>
                                </div>
                                <div className={styles.itemAction}>
                                    <Control className={styles.control} appid={item.id} />
                                </div>
                            </div>
                            {/* <div className={styles.createdAt}>
                                <span className={styles.title}>Last Updated </span>
                                <span>
                                    {moment().diff(item.updatedAt, 'minutes') > 0 && moment().diff(item.updatedAt, 'minutes') < 60 ? 
                                    `${moment().diff(item.updatedAt, 'minutes')} minutes ago`
                                    :  moment().diff(item.updatedAt, 'minutes') > 60 && moment().diff(item.updatedAt, 'minutes') < 1140 ?
                                    `${moment().diff(item.updatedAt, 'hours')} hours ago`
                                    : moment().diff(item.updatedAt, 'minutes') > 1140 && moment().diff(item.updatedAt, 'minutes') < 43200 ?
                                    `${moment().diff(item.updatedAt, 'days')} days ago`
                                    :  moment().diff(item.updatedAt, 'minutes') > 43200 && moment().diff(item.updatedAt, 'minutes') < 525600 ?
                                    `${moment().diff(item.updatedAt, 'months')} months ago`
                                    : moment().diff(item.updatedAt, 'minutes') > 525600 ?
                                    `${moment().diff(item.updatedAt, 'years')} years ago`
                                    : `${moment().diff(item.updatedAt, 'seconds')} seconds ago`}
                                </span>
                            </div> */}
                        </div>
                    </div>
                </Link>
            </div>
        </>
    )
    }

    export default AppsCardLayout