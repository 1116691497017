import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./schedule.module.sass";
import {ReactComponent as Result} from '../../../../../../media/images/icons/Result.svg'
import {ReactComponent as Pause} from '../../../../../../media/images/icons/Pause.svg'
import {ReactComponent as Stop} from '../../../../../../media/images/icons/Stop.svg'
import {ReactComponent as Calendar} from '../../../../../../media/images/icons/Calendar.svg'
import {ReactComponent as OutlineCalendar} from '../../../../../../media/images/icons/OutlineCalendar.svg'
import ScheduleModal from "../../../../../../components/EngageComponents/ScheduleModal";
import Modal from "../../../../../../components/Modals/ModalComponent";
import { useAccess } from "../../../../../../components/Page";

const Control = ({className, type, id, as, status, setId, saveTaskGroup, name}) => {

    const [visibleModal, setVisibleModal] = useState(false);
    const { accessPermissions } = useAccess();
    const {allowMissionSchedule,allowMissionScheduleStop} = accessPermissions || {};

    const save = () => {
        saveTaskGroup(true, setVisibleModal, setId)
    }

    return (
        <>
            {allowMissionSchedule&&<div className={cn(styles.control, className)}>
                <button className={styles.Cancel} onClick={save}>Save & Schedule</button>
            </div>}
            <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
                <ScheduleModal name={name} as={as} liveOpsStatus={status} type={type} id={id} onClose={() => setVisibleModal(false)}  />
            </Modal>
        </>
    );
};

export default Control;
