import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import Icon from "../../../../../Icon";
import EyeIcon from '../../../../../../media/images/icons/eye.png'
import Vertical3Dots from "../../../../../../media/images/icons/vertical3Dots.svg"
import VerticalOptionsTooltip from "../../../../../VertcalOptionsTooltip"
import {ReactComponent as ArchiveSvg} from "../../../../../../media/images/icons/archive.svg"
import Modal from "../../../../../Modals/ModalComponent";
import DeleteModal from "../../../../../Modals/Others/DeleteModal";
import ArchiveModal from "../../../../../Modals/Others/ArchieveModal";
import { useAccess } from "../../../../../Page";

const Control = ({className, access,isLast, appid, id, name, status, type}) => {

  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
  const [visibleArchiveModal, setVisibleArchiveModal] = useState(false)
  const { accessPermissions } = useAccess();
  const { allowTaskEdit,allowTaskDelete} = accessPermissions || {};
  const actions = [
    {
      pathName : `/view-task/${appid}/${id}`,
      action: () => console.log("edit"),
    },
    {
      icon: "edit",
      name:"Edit",
      pathName : `/edit-task/${appid}/${id}`,
      action: () => console.log("edit"),
    },
    // {
    //   icon: "download",
    //   name:"Archive",
    //   action: () => setVisibleArchiveModal(true),
    //   id : id
    // },
    {
      name:"Delete",
      icon: "trash",
      action:  () => setVisibleDeleteModal(true),
    }
  ];

  return (
    <>
      <div className={cn(styles.control, className)}>
      {access ? 
          (actions && actions.slice(0, 1).map((x, index) => {
            if(
              (!allowTaskEdit && x?.pathName?.includes("/edit-task"))||
              (!allowTaskDelete && x?.icon== "trash")
            ){
              return ;
            }
            return(
              <Link to={x.pathName} state={{id : id}} className={styles.button} key={index} onClick={x.action}>
                <img src={EyeIcon} width={20} height={20} className={styles.icon} />
              </Link>
            )
          }))
        : 
          (actions && actions.slice(1).map((x, index) => {
            return(
              <Link to={x.pathName} state={{id : id}} className={styles.button} key={index} onClick={x.action}>
                {x.icon == 'download' ?  <ArchiveSvg /> : <Icon name={x.icon} size="20" className={styles.icon}/>}
              </Link>
            )
          }))
        }  
      </div>
      <Modal visible={visibleDeleteModal} onClose={() => setVisibleDeleteModal(false)}>
        <DeleteModal id={id} name={name} type={type} onClose={() => setVisibleDeleteModal(false)}/>
      </Modal>
    </>
  );
};

export default Control;
