import {FILTER_GAMES_SUCCESS, FILTER_GAMES_ERROR, GET_GAMES, ADD_GAMES, EDIT_GAMES, GET_GAMES_SUCCESS, GET_GAMES_ERROR, ADD_GAMES_SUCCESS, ADD_GAMES_ERROR, EDIT_GAMES_SUCCESS, EDIT_GAMES_ERROR, GET_ALL_GAMES, GET_ALL_GAMES_SUCCESS, GET_ALL_GAMES_ERROR } from "../../../actionTypes/actionTypes";
import gameServices from "../../../../services/buildServices/gameServices/gameServices";
import Toast from "../../../../components/Toast";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const getAllGamesAction = (body, showSkeleton) => {
    return async (dispatch) => {
        dispatch(getGames(null, null, GET_GAMES))
        await gameServices.getGames(body)
        .then(res => {
            if(showSkeleton){
                showSkeleton()
            }
            dispatch(getGames(res.data.data.gameDetails, (body?.showArchived ? res.data.data.archiveCount : res.data.data.totalCount), (body?.showArchived ? FILTER_GAMES_SUCCESS : GET_GAMES_SUCCESS)))
        }).catch(err=>{
            dispatch(getGames(err.response, null, (body?.showArchived ? FILTER_GAMES_ERROR : GET_GAMES_ERROR)))
        })
    }
}

export const filterGamesAction = (body, showSkeleton, isFiltered) => {
    return async (dispatch) => {
        await gameServices.getGames(body)
        .then(res => {
            if(showSkeleton){
                showSkeleton()
            }
            dispatch(filterGames(res.data.data.gameDetails, ((body?.search && body?.search !== '') ? res.data.data.searchCount : (body?.search === undefined && isFiltered) ? res.data.data.filterCount : res.data.data.totalCount), FILTER_GAMES_SUCCESS))
        }).catch(err=>{
            dispatch(filterGames(err, null, FILTER_GAMES_ERROR))
        })
    }
}

export const getListOfAllGamesAction = (body) => {
    return async (dispatch) => {
        await gameServices.getGames(body)
        .then(res => {
            dispatch(getALLGames(res.data.data.gameDetails, GET_ALL_GAMES_SUCCESS))
        }).catch(err=>{
            dispatch(getALLGames(err, GET_ALL_GAMES_ERROR))
        })
    }
}

export const createGameAction = (body, navigate, appid, setDisable) => {
    return async (dispatch) => {
        if(setDisable){
            setDisable(true)
        }
        try {
            const res = await gameServices.addGames(body); // Assuming this returns a promise
            // Dispatching the action to store the created game details
            dispatch(addGames(res.data.data, ADD_GAMES_SUCCESS));
            // Show success message
            toast.success(
                <Toast type="Success" messages={res.data.message} />,
                {
                    position: 'top-center',
                    icon: false,
                    hideProgressBar: true,
                    autoClose: 2000
                }
            );
            // Navigate after a short delay
            setTimeout(() => {
                if(setDisable){
                    setDisable(false)
                }
                navigate(`/games/${appid}`);
            }, 2000);
            return res; // Return the response to be used by the caller
        } catch (error) {
            // Dispatching error
            dispatch(addGames(error, ADD_GAMES_ERROR));
            // Display error messages, if any
            if (error.response?.data?.errors) {
                error.response.data.errors.forEach((err) => {
                    toast.error(
                        <Toast type="Error" messages={err.message} />,
                        {
                            position: 'top-center',
                            icon: false,
                            hideProgressBar: true,
                            autoClose: 2000
                        }
                    );
                });
            }
            if(setDisable){
                setDisable(false)
            }
            throw error; // Re-throw the error to be caught by the caller
        }
    };
};


export const editGameAction = (body, navigate, appid, setDisable) => {
    return async (dispatch) => {
        if(setDisable){
            setDisable(true)
        }
        await gameServices.editGames(body)
        .then(res => {
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            setTimeout(()=>{
                if(setDisable){
                    setDisable(false)
                }
                navigate(`/games/${appid}`)
            }, 2000)
            return res;
        }).catch(error => {
            if(setDisable){
                setDisable(false)
            }
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            throw error;
        })
    }
}

export const archiveGameAction = (body, appid, onClose, status) => {
    return async (dispatch) => {
        await gameServices.archiveGames(body)
        .then(res => {
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            let data = {projectId: appid, showArchived: status, isDefault: false, offset: 0, limit: 8}
            dispatch(getAllGamesAction(data))
            onClose()
            return res;
        }).catch(error => {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            throw error;
        })
    } 
}

export const getGames = (data, count, type) => {
    return{
        type : type,
        payload : data,
        totalCount : count
    }
}

export const getALLGames = (data, type) => {
    return{
        type : type,
        payload : data,
    }
}

export const addGames = (data, type) => {
    return{
        type : type,
        payload : data,
    }
}

export const editGames = (data, type) => {
    return{
        type : type,
        payload : data,
    }
}

export const filterGames = (data, count, type) => {
    return{
        type : type,
        payload : data,
        totalCount : count
    }
}