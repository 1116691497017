import React, { useState } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../Checkbox";
import Icon from "../../../../Icon";
import Control from "./Control/index";
import { useParams } from "react-router-dom";
import Game from '../../../../../media/images/placeholders/Game.png'
import Tooltip from '../../../../CustomTooltips/ReusableTooltip'
import {ReactComponent as GoldMedal} from '../../../../../media/images/placeholders/Gold.svg'
import {ReactComponent as SilverMedal} from '../../../../../media/images/placeholders/Silver.svg'
import {ReactComponent as BronzeMedal} from '../../../../../media/images/placeholders/Bronze.svg'
import {ReactComponent as UserProfile} from '../../../../../media/images/icons/Player_Icon.svg'
import SnapShot from "../../../../Images";
import PlayerProfile from '../../../../../media/images/placeholders/Profile_Placeholder.png'
/* Deleted Schedule, Modal, Modal Products and Actions */

const Row = ({item, value, onChange, usedFor}) => {
    
    const {appid} = useParams()

    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());

    if(usedFor === 'leaderboard'){
        return (
            <>
                <div className={styles.row}>
                    <div className={styles.col}>{item.rank == 1 ? <GoldMedal /> : item.rank == 2 ? <SilverMedal /> : item.rank == 3 ? <BronzeMedal /> : item.rank}</div>
                    <div className={styles.col}>
                        <div className={styles.player}>
                            <div className={styles.playerInsigne}>
                                <SnapShot id={`playerIcon_${item.id}`} url={item?.users?.thumbUrl} alt={item?.user?.displayName} placeholder={PlayerProfile} type='Images' width={36} height={36}/>
                                {/* {(item.users && item.users.thumbUrl != null) && <img src={(item.users && item.users.thumbUrl && item.users.thumbUrl != null) ? item.users.thumbUrl : ''} alt={`${item.userName}`}/> }
                                {(item.users && item.users.thumbUrl == null) && <UserProfile /> } */}
                            </div>
                            <div className={styles.playerDetails}>
                                <div className={styles.userName}>{(item.users && item.users != null && item.users.displayName != null) ? item.users.displayName : (item.users && item.users != null && item.users.username != null) ? item.users.username : (item.users && item.users != null && item.users.firstName != null) ? item.users.firstName : (item.users && item.users != null && item.users.customId != null) ? item.users.customId : (item.users && item.users != null && item.users.linkedAccounts != null && item.users.linkedAccounts.length > 0) ? item.users.linkedAccounts[0].userId : (item.users && item.users != null && item.users.email != null && item.users.email != '') ? item.users.email : '-'}</div>
                                <div className={styles.playerId}>{(item.users && item.users.id != null) ? item.users.id : '-'}</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.col}><div className={styles.score}>{item.score}</div></div>
                    <div className={styles.col}>
                        {[...item.prizes.items, ...item.prizes.currencies, ...item.prizes.bundles, ...item.prizes.progressionMarkers] && [...item.prizes.items, ...item.prizes.currencies, ...item.prizes.bundles, ...item.prizes.progressionMarkers].slice(0,1).map((e,i) => {
                            return(
                                <>
                                    <span className={styles.name}>{e.name}</span><span className={styles.quantity}>({e.amount})</span>
                                    {([...item.prizes.items, ...item.prizes.currencies, ...item.prizes.bundles, ...item.prizes.progressionMarkers] && [...item.prizes.items, ...item.prizes.currencies, ...item.prizes.bundles, ...item.prizes.progressionMarkers].length > 1) ? 
                                        <Tooltip type='Task' text='Rewards' data={[...item.prizes.items, ...item.prizes.currencies, ...item.prizes.bundles, ...item.prizes.progressionMarkers]}><span className={styles.count}>+ {[...item.prizes.items, ...item.prizes.currencies, ...item.prizes.bundles, ...item.prizes.progressionMarkers].length - 1}</span></Tooltip>  
                                    : ''}
                                </>
                            )
                        })}
                    </div>
                </div>
            </>
        );
    }else if(usedFor === 'competition'){
        return(
            <>
                <div className={styles.row}>
                    <div className={styles.col}>{item.rank == 1 ? <GoldMedal /> : item.rank == 2 ? <SilverMedal /> : item.rank == 3 ? <BronzeMedal /> : item.rank}</div>
                    <div className={styles.col}>
                        <div className={styles.player}>
                            <div className={styles.playerInsigne}>
                                {(item.users && item.users.thumbUrl != null) && <img src={(item.users && item.users.thumbUrl && item.users.thumbUrl != null) ? item.users.thumbUrl : ''} alt={`${item.userName}`}/> }
                                {(item.users && item.users.thumbUrl == null) && <UserProfile /> }
                            </div>
                            <div className={styles.playerDetails}>
                                <div className={styles.userName}>{(item.users && item.users != null && item.users.displayName != null) ? item.users.displayName : (item.users && item.users != null && item.users.username != null) ? item.users.username : (item.users && item.users != null && item.users.firstName != null) ? item.users.firstName : (item.users && item.users != null && item.users.customId != null) ? item.users.customId : (item.users && item.users != null && item.users.linkedAccounts != null && item.users.linkedAccounts.length > 0) ? item.users.linkedAccounts[0].userId : (item.users && item.users != null && item.users.email != null && item.users.email != '') ? item.users.email : '-'}</div>
                                <div className={styles.playerId}>{(item.users && item.users.id != null) ? item.users.id : '-'}</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.col}><div className={styles.score}>{item.score}</div></div>
                    <div className={styles.col}>
                        {[...item.prizes.items, ...item.prizes.currencies, ...item.prizes.bundles, ...item.prizes.progressionMarkers] && [...item.prizes.items, ...item.prizes.currencies, ...item.prizes.bundles, ...item.prizes.progressionMarkers].slice(0,1).map((e,i) => {
                            return(
                                <>
                                    <span className={styles.name}>{e.name}</span><span className={styles.quantity}>({e.amount})</span>
                                    {([...item.prizes.items, ...item.prizes.currencies, ...item.prizes.bundles, ...item.prizes.progressionMarkers] && [...item.prizes.items, ...item.prizes.currencies, ...item.prizes.bundles, ...item.prizes.progressionMarkers].length > 1) ? 
                                        <Tooltip type='Task' text='Rewards' data={[...item.prizes.items, ...item.prizes.currencies, ...item.prizes.bundles, ...item.prizes.progressionMarkers]}><span className={styles.count}>+ {[...item.prizes.items, ...item.prizes.currencies, ...item.prizes.bundles, ...item.prizes.progressionMarkers].length - 1}</span></Tooltip>  
                                    : ''}
                                </>
                            )
                        })}
                    </div>
                </div>
            </>
        )
    }else if(usedFor === 'instant battle'){
        return(
            <>
                <div className={styles.row}>
                    <div className={styles.col}>{item.rank == 1 ? <GoldMedal /> : item.rank == 2 ? <SilverMedal /> : item.rank == 3 ? <BronzeMedal /> : item.rank}</div>
                    <div className={styles.col}>
                        <div className={styles.player}>
                            <div className={styles.playerInsigne}>
                                {(item.users && item.users.thumbUrl != null) && <img src={(item.users && item.users.thumbUrl && item.users.thumbUrl != null) ? item.users.thumbUrl : ''} alt={`${item.userName}`}/> }
                                {(item.users && item.users.thumbUrl == null) && <UserProfile /> }
                            </div>
                            <div className={styles.playerDetails}>
                                <div className={styles.userName}>{(item.users && item.users != null && item.users.displayName != null) ? item.users.displayName : (item.users && item.users != null && item.users.username != null) ? item.users.username : (item.users && item.users != null && item.users.firstName != null) ? item.users.firstName : (item.users && item.users != null && item.users.customId != null) ? item.users.customId : (item.users && item.users != null && item.users.linkedAccounts != null && item.users.linkedAccounts.length > 0) ? item.users.linkedAccounts[0].userId : (item.users && item.users != null && item.users.email != null && item.users.email != '') ? item.users.email : '-'}</div>
                                <div className={styles.playerId}>{(item.users && item.users.id != null) ? item.users.id : '-'}</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.col}><div className={styles.score}>{item.score}</div></div>
                    <div className={styles.col}>
                        {[...item.prizes.items, ...item.prizes.currencies, ...item.prizes.bundles, ...item.prizes.progressionMarkers] && [...item.prizes.items, ...item.prizes.currencies, ...item.prizes.bundles, ...item.prizes.progressionMarkers].slice(0,1).map((e,i) => {
                            return(
                                <>
                                    <span className={styles.name}>{e.name}</span><span className={styles.quantity}>({e.amount})</span>
                                    {([...item.prizes.items, ...item.prizes.currencies, ...item.prizes.bundles, ...item.prizes.progressionMarkers] && [...item.prizes.items, ...item.prizes.currencies, ...item.prizes.bundles, ...item.prizes.progressionMarkers].length > 1) ? 
                                        <Tooltip type='Task' text='Rewards' data={[...item.prizes.items, ...item.prizes.currencies, ...item.prizes.bundles, ...item.prizes.progressionMarkers]}><span className={styles.count}>+ {[...item.prizes.items, ...item.prizes.currencies, ...item.prizes.bundles, ...item.prizes.progressionMarkers].length - 1}</span></Tooltip>  
                                    : ''}
                                </>
                            )
                        })}
                    </div>
                </div>
            </>
        )
    }
    else{
        return(<></>)
    }
};

export default Row;
