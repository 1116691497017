import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import styles from './quickAction.module.sass'
import Icon from "../../Icon";
import { ReactComponent as  Apps} from "../../../media/images/icons/apps.svg";
import { ReactComponent as  Settings} from "../../../media/images/icons/settings.svg";
import { ReactComponent as  AccessManager} from "../../../media/images/icons/access_manager.svg";
import { ReactComponent as  Sandbox} from "../../../media/images/icons/sandbox.svg";
import OutsideClickHandler from "react-outside-click-handler";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useAccess } from "../../Page";
const QuickAction = ({className}) => {

    const {pathname} = useLocation()
    const [show, setShow] = useState(false)
    const { accessPermissions } = useAccess(); 
    const { isAdminEmail, allowAppsPage, allowSettings, allowOrganizationSettings, allowUserSettingsProfile,allowMembersAccess,allowAcceptedMembers,allowInvitedMembers,allowSandboxButton,allowUsage,allowBilling,allowBillingInvoices,allowBillingSettings} = accessPermissions || {};

    const quickMenu = [
        {
            title: "Apps",
            icon : <Apps />,
            active : false,
            url: "/apps"
        },
        {
            title: "Settings",
            icon : <Settings />,
            active : false,
            url: allowOrganizationSettings?"/settings-organization-profile":"/settings-member-profile"
        },
        {
            title: "People",
            icon : <AccessManager />,
            active : false,
            url: allowAcceptedMembers?"/access-manager-accepted":"/access-manager-invited"
        },
        {
            title: 'Billing',
            icon : <Icon name='payment' size='16' className={styles.payment} fill='#6F767E' />,
            active: false,
            url: allowBilling?'/billing-overview':allowBillingInvoices?'/invoices':allowBillingSettings?'/billing-settings':''
        },
        {
            title: 'Sandbox',
            icon : <Sandbox />,
            active: false,
            url: (window.location.origin.includes('console') ? `https://sandbox.specterapp.xyz/` : window.location.origin.includes('staging') ? `https://sandboxdev.netlify.app/` : `https://sandboxdev.netlify.app/`)
        },
        {
            title: 'Usage',
            icon : <Icon name='pie-chart' size='16' className={styles.payment} fill='#6F767E' />,
            active: false,
            url: '/usage-analytics'
        },
        // {
        //     title: 'Notifications',
        //     icon : <Icon name="notification" size='16' className={styles.payment} fill='#6F767E' />,
        //     active: false,
        //     url: null
        // }
    ]

    return(
        <>
            <OutsideClickHandler onOutsideClick={() => setShow(false)}>
                <div className={styles.containerQuickAction}>
                    <div className={styles.quickAction}>
                        <div className={cn(styles.action, {[styles.active] : show})} onClick={() => setShow(!show)}>
                            <div className={styles.bentoMenu}>
                                <div className={styles.bentoDot}></div>
                                <div className={styles.bentoDot}></div>
                                <div className={styles.bentoDot}></div>
                                <div className={styles.bentoDot}></div>
                                <div className={styles.bentoDot}></div>
                                <div className={styles.bentoDot}></div>
                                <div className={styles.bentoDot}></div>
                                <div className={styles.bentoDot}></div>
                                <div className={styles.bentoDot}></div>
                            </div>
                            <div className={styles.body}>
                                <div className={styles.menu} >
                                    {quickMenu && quickMenu.map((item, index) => (
                                        ((!allowAppsPage&& item?.url=== "/apps")||
                                        (!allowUsage&& item?.url=== "/usage-analytics")||
                                        (!allowSandboxButton&& item?.title=== 'Sandbox')||
                                        (!allowBilling&& item?.url=== '/billing-overview')||
                                        (!allowBillingInvoices&& item?.url=== '/invoices')||
                                        (!allowBillingSettings&& item?.url=== '/billing-settings')||
                                        // (!isAdminEmail&& item?.url=== "/billing-overview")||
                                        ((!allowSettings||(!allowOrganizationSettings&&!allowUserSettingsProfile))&& (item?.url=== "/settings-organization-profile"||item?.url=== "/settings-member-profile"))||
                                        ((!allowMembersAccess||(!allowAcceptedMembers&&!allowInvitedMembers))&& (item?.url=== "/access-manager-accepted"||item?.url=== "/access-manager-invited"))||
                                        (item?.url=="")
                                        ?
                                        ""
                                        :
                                        <NavLink target={item.title === 'Sandbox' ? '_blank' : null} to={item.url} className={styles.options} key={index}>
                                            <div className={styles.icon}>
                                                {item.icon}
                                            </div>
                                            <div className={styles.title}>
                                                {item.title}
                                            </div>
                                        </NavLink>)
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </OutsideClickHandler>
        </>
    )
}

export default QuickAction