import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./File.module.sass";
import Icon from "../../Icon";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from "../../Toast";
import { addMediaAction } from "../../../redux/action/AppSettings/Media/mediaAction";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../../resources/APIEndpoints";

const FileUpload = ({ className, getMedia, setUploadingMedia, activeIndex, access, disabled, id, usedFor, setSidebar,folderName, label, value, setValue, icon, setIcon, setFile, data, tooltip, title, success, error, accept, a,type,catIndex,customUploadFunction, ...props }) => {

    let filePlaceHolder = []
    const {appid} = useParams()
    const dispatch = useDispatch()

    const progressToastId = useRef(null);
    const fileInputRef = useRef()

    const [total, setTotal] =  useState()
    const [loaded, setLoaded] = useState()
    const [percentage, setPercentage] = useState()
    const [files , setFiles] = useState(null)
    const [uploadProgress, setUploadProgress] = useState(0);

    const onUploadProgress = (progressEvent) => {
        for(var i = 1 ; i <= document.getElementsByClassName('progress-bar-images').length; i++){
            const {loaded, total} = progressEvent;
            let percentage = Math.floor((loaded*100)/total)
            if(document.getElementById(`successfull${i}`)){
                document.getElementById(`successfull${i}`).style.width = `${percentage}%`
                if(percentage == 100){
                    document.getElementById(`percentage${i}`).style.display = 'none'
                    document.getElementById(`view${i}`).style.display = 'block'
                }
            }
        }
    }

    // Show a toast if one doesn’t already exist
    const startProgressToast = () => {
        // if (!progressToastId.current) {
        //   progressToastId.current = toast(
        //     <Toast type="Info" messages="Starting upload..." />,
        //     {
        //       autoClose: false,
        //       closeOnClick: false,
        //       draggable: false,
        //       progress: 0,
        //     }
        //   );
        // }
    };

    const addUploadingItem = (file) => {
        const newItem = {
        fileName: file.name,
        progress: 0,
        };
        setUploadingMedia((prev) => [...prev, newItem]);
        return newItem;
    };
    
    // 2) When we get onUploadProgress events
    const updateUploadingItem = (fileName, progress) => {
        setUploadingMedia((prev) =>
        prev.map((item) =>
            item.fileName === fileName ? { ...item, progress } : item
        )
        );
    };
  
    // 3) When done or error
    const removeUploadingItem = (fileName) => {
        setUploadingMedia((prev) => prev.filter((item) => item.fileName !== fileName));
    };

    const handleUploadProgress = (progressEvent,name) => {
        // if (!progressToastId.current) return; // no toast to update yet

        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);
        updateUploadingItem(name, percent);

        // const fraction = percent / 100; // toastify expects a fraction (0-1)

        // toast.update(progressToastId.current, {
        //   progress: fraction,
        //   render: (
        //     <Toast
        //       type="Info"
        //       messages={`Uploading file... ${percent}%`}
        //     />
        //   ),
        // });
    };

    const dismissProgressToast = () => {
        if (progressToastId.current) {
        toast.dismiss(progressToastId.current);
        progressToastId.current = null;
        }
    }
    
    
    const onFileUpload = async(e) => {
        const files = e?.target?.files ? e?.target?.files[0] : e?.dataTransfer?.files ? e?.dataTransfer?.files[0] : []
        const maxSize = 1 * 1024 * 1024 * 1024
        if(files && files.size < maxSize){
            if (type=="store") {
                customUploadFunction(e,catIndex,getMedia)
            }
            else if(usedFor == 'media'){
                let fileContent = []
                for(var i = 0; i< e.target.files.length; i++){
                    let fileData = e.target.files[i]
                    let fileName = fileData.name
                    let fileType = fileData.type
                    let fileSize = fileData.size/1024
                    let file_binary = await convertbase64(fileData)
                    fileContent.push({contentType : `${fileType}`, fileSize : fileSize,category : folderName, fileName : fileName, url : null, data : convertDataURIToBinary(file_binary),fileData:fileData})
                }
                const forEachPromise = new Promise((resolve) => {
                    const token = localStorage.getItem('token')
                    const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
                    fileContent && fileContent.forEach(async(e, i) => {
                        startProgressToast();
                        addUploadingItem(e.fileData);
                        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${e.contentType}&projectId=${appid}&category=${folderName}&fileName=${e.fileName}`,headers)
                        let fileUrl = res.data.getUrl
                        fileContent[i].url = fileUrl
                        var config = {headers : {'Content-Type' : `${e.contentType}`}, method: 'put', url: res.data.postUrl,data : e.data, onUploadProgress:((z)=> handleUploadProgress(z,e.fileName)),}
                        axios(config)
                        setUploadProgress(0);
                        removeUploadingItem(e.fileName)
                        setTimeout(()=>{
                            dismissProgressToast();
                        },[1500])
                        .then(function (response) {
                            e.target.value = ''
                        })
                        .catch(function (error) {
    
                        })
                        // If it's the last iteration, resolve the Promise
                        if(i === fileContent.length - 1) {
                            resolve();
                        }
                    })   
                });
                
                await forEachPromise;
    
                let files = {
                    projectId : appid,
                    contents : fileContent && fileContent.map(obj => {const { data, ...rest } = obj; return rest;})
                }
                dispatch(addMediaAction(files, getMedia, setValue, appid,folderName))
            }else if(usedFor == 'multiUpload'){
                let multipleFiles = (e?.target?.files ? e?.target?.files : e?.dataTransfer?.files ? e?.dataTransfer?.files : []);   
                let screenshotHolder = []
                for(var i = 0; i< multipleFiles.length; i++){
                    screenshotHolder.push(multipleFiles[i])
                }
                const token = localStorage.getItem('token')
                const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}        
                screenshotHolder && screenshotHolder.forEach(async (element, index) => {
                    startProgressToast();
                    addUploadingItem(element);
                    const file_binary = await convertbase64(element)
                    const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${element.type}&projectId=${appid}&category=${folderName}&fileName=${element.name}`,headers)
                    let imageUrl = res.data.getUrl
                    //setValue(prevState => [...prevState, imageUrl])
                    var config = {headers : {'Content-Type' : `${element.type}`}, method: 'put',url: res.data.postUrl,data :convertDataURIToBinary(file_binary), onUploadProgress:((z)=> handleUploadProgress(z,element.name))}
                    axios(config)
                    .then(function (response) {
                        if(setSidebar){
                            let uploadLogo = {
                                projectId : appid,
                                contents : [{
                                    contentType : element.type,
                                    fileName : element.name,
                                    category : folderName,
                                    url : res.data.getUrl,
                                    fileSize : element.size/1024
                                }]
                            }
                            dispatch(addMediaAction(uploadLogo, getMedia, null, appid,folderName))
                        }
                        setUploadProgress(0);
                        toast.success(<Toast type='Success' messages={`${element.name} Uploaded Successfully`}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
                        removeUploadingItem(element.name)
                        setTimeout(()=>{
                            dismissProgressToast();
                        },[1500])
                    })
                    .catch(function (error) {
                        
                    });
                });
            }
            else{
                if(e?.target?.files || e?.dataTransfer?.files) {
                    setFile({src: URL.createObjectURL(e?.target?.files ? e?.target?.files[0] : e?.dataTransfer?.files ? e?.dataTransfer?.files[0] : []), alt: (e?.target?.files ? e?.target?.files[0].name : e?.dataTransfer?.files ? e?.dataTransfer?.files[0].name : [])});    
                } 
                var file = (e?.target?.files ? e?.target?.files[0] : e?.dataTransfer?.files ? e?.dataTransfer?.files[0] : []);   
                const file_binary = await convertbase64(file)
                const token = localStorage.getItem('token')
                const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
                startProgressToast();
                addUploadingItem(file);
                const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}&projectId=${appid}&category=${folderName}&fileName=${file.name}`,headers)
                setIcon(res.data.getUrl)
                var config = {
                    headers : {'Content-Type' : `${file.type}`},
                    method: 'put',
                    url: res.data.postUrl,
                    data :convertDataURIToBinary(file_binary),
                    onUploadProgress: ((z)=> handleUploadProgress(z,file.name)),
                }
                axios(config)
                .then(function (response) {
                    if(setSidebar){
                        let uploadLogo = {
                            projectId : appid,
                            contents : [{
                                contentType : file.type,
                                fileName : file.name,
                                category : folderName,
                                url : res.data.getUrl,
                                fileSize : file.size/1024
                            }]
                        }
                        dispatch(addMediaAction(uploadLogo, getMedia, null, appid,folderName))
                    }
                    setUploadProgress(0);
                    removeUploadingItem(file.name)
                    setSidebar(false)
                    setTimeout(()=>{
                        dismissProgressToast();
                    },[1500])
                    toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
                })
                .catch(function (error) {
                    toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
                });
            }
        }else{
            toast.error(<Toast type='Error' messages='File Size Should Be Less Than 1GB'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        }
    }
    
    // To Convert Base64 to Binary
    function convertDataURIToBinary(dataURI) {
        var BASE64_MARKER = ';base64,';
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));
                  
        for(var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }
            
    // To Convert file into base64 format
    const convertbase64 = (file) => {
        return new Promise((resolve, err)=>{
        const fileReader = new FileReader();
        fileReader.onload = (eve) =>{
            resolve(fileReader.result)
        }
        fileReader.readAsDataURL(file)
        })
    }

    // Handle file selection via the file input
    const handleFileSelect = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles(selectedFiles);
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.classList.add('dragover');
    };
    
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.classList.add('dragover');
    };
    
    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.classList.remove('dragover');
    };
    
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.classList.remove('dragover');
        const droppedFiles = e;
        onFileUpload(droppedFiles)
    }

    return (
        <>
        <div onDragEnter={handleDragEnter} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
            <label for={id} className={cn(styles.file, className)}>
                <div className={usedFor == 'media' ? styles.wrap : styles.wrapLogo}>
                    <input disabled={disabled} ref={fileInputRef} multiple id={id} accept={(folderName== 'bundles' || folderName == 'misc') ? '*' : folderName === 'icons' ? ".jpg, .jpeg, .png, .gif, .svg, .webp" : folderName === 'videos' ? 'video/mp4, video/webm, video/avi, video/quicktime, video/x-matroska' : folderName === 'assets' ? '.jpg, .jpeg, .png, .gif, .svg, .webp' : folderName == null ? '.jpg, .jpeg, .png, .gif, .svg, .webp' : null } className={cn(styles.input, {[styles.disabled] : disabled})} type="file" onChange={(e)=>{onFileUpload(e)}}/>
                    <div className={styles.box}>
                        <label for={id}><Icon name="upload" size="24" />{title}</label>
                    </div>
                </div>
            </label>
            {/* {uploadProgress > 0 && uploadProgress < 100 && (
                <div className={styles.progressBar}>
                <div
                    className={styles.progress}
                    style={{ width: `${uploadProgress}%` }}
                />
                </div>
            )}  */}
        </div>
        </>
    );
};

export default FileUpload;
