import React, { useContext, createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import styles from "./Page.module.sass";
import Sidebar from "../Sidebar";
import Header from "../Header";
import {ReactComponent as Circle} from '../../media/images/smaller_circle.svg'
import {ReactComponent as OutlineCircle} from '../../media/images/full_hollow_circle.svg'
import Cross from '../../media/images/fill_cross.png'
import {ReactComponent as OutlineCross} from '../../media/images/hollow_crossed.svg'
import Lottie from "lottie-react";
import Loader from '../../media/lottieAnimation/Specter_Animation.json'
import Modal from "../Modals/ModalComponent";
import IntroductionModal from "../Modals/IntroductionModal";
import GlobalStateContext from "../Context/GlobalStates/GlobalStateContext";
import { useLocation } from "react-router-dom";
// import { LDProvider, useFlags } from 'launchdarkly-react-client-sdk';
import { toast, ToastContainer } from "react-toastify";
import Toast from "../Toast";
import { useParams } from 'react-router-dom'
import { BASE_URL, GET_ACCESS_CONFIG} from '../../resources/APIEndpoints';
import axios from 'axios';

const AccessContext = createContext();

export const LDWrapper = ({ children }) => {
  
  const [accessPermissions, setAccessPermissions] = useState({});
  const {appid} = useParams()

  async function getAppFlagsAccessConfig() {
      try {
        const token = localStorage.getItem("token");
        const environment = localStorage.getItem("environment");
        const apiKeys = JSON.parse(localStorage.getItem("apiKeys"));
    
        const headers = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Api-Key":
              environment === "Production"
                ? apiKeys.productionAPIKey
                : environment === "Staging"
                ? apiKeys.stagingAPIKey
                : apiKeys.devAPIKey,
          },
        };
    
        const requestOrgData = {
          organisationId: localStorage.getItem("orgId"),
          // memberIds: [JSON.parse(localStorage.getItem('user'))?.id],
          memberEmails: [JSON.parse(localStorage.getItem('user'))?.email],
          env: ["global"],
        };
        const requestAppData = {
          organisationId: localStorage.getItem("orgId"),
          // memberIds: [JSON.parse(localStorage.getItem('user'))?.id],
          memberEmails: [JSON.parse(localStorage.getItem('user'))?.email],
          env: ["global",environment?.toLowerCase()],
          ...(appid&&{projectIds: [appid]}),
        };
        const orgFlagData = await axios.post(BASE_URL + GET_ACCESS_CONFIG, requestOrgData, headers);

        const existingFlags = accessPermissions || {};
        const orgFlagArr =orgFlagData.data.data.accessDetails.filter(e=>(e?.orgFlags?.length>0))?.[0]?.orgFlags
        orgFlagArr && orgFlagArr.map((e)=>{
            existingFlags[e.key]=e.selected
        })
        if(appid){
          const appFlagData = await axios.post(BASE_URL + GET_ACCESS_CONFIG, requestAppData, headers);
          const DataFlagArr =appFlagData.data.data.accessDetails.filter(e=>(e?.projectFlags?.length>0))
          DataFlagArr && DataFlagArr.forEach((e)=>{
              e?.projectFlags && e?.projectFlags.forEach(({ key, selected }) => {
                  existingFlags[key] = selected; // Replace or add the flag
              });
          })
        }
        // Update the existing Flags object with the new flags data
        // Store the updated Flags object back in localStorage
        setAccessPermissions(existingFlags); 

      } catch (error) {
        console.error("Error fetching app flags:", error);
        return {};
      }
  }

  // Fetch access details when the app loads
  useEffect(() => {
    getAppFlagsAccessConfig();
  }, [appid]);

  return (
    <AccessContext.Provider value={{ accessPermissions, setAccessPermissions }}>
      {children}
    </AccessContext.Provider>
  );
};


const PageContent = ({type, view, background, wide, children, title, showLoader, setShowLoader, loaderType, setLoaderType}) => {
    const {appid} = useParams()
    const [visible, setVisible] = useState(false);
    const [isChanged,setIsChanged] = useState(false);
    const [appName, setAppName] = useState("")
    const { pathname } = useLocation()
    const navigate = useNavigate(); // Initialize useNavigate

    const { accessPermissions,setAccessPermissions } = useAccess();
    
    const { 
        isAdminEmail, allowAppsPage, allowOrganizationSettings,allowUserSettingsProfile,allowAcceptedMembers,allowInvitedMembers,allowUsage,allowUpgradePlansAccess, allowGlobalAppsInfo, allowAppMedia, allowDocument, allowDocumentCreate, allowDocumentEdit, allowAppAPI, allowActivity,
        allowAppsOverview, allowEnvironmentAppInfo, allowTerminal, allowGames, allowGamesCreate, allowGamesEdit, allowMatches, allowMatchesCreate, allowMatchesEdit,
        allowVirtualCurrency,allowItem,allowBundle,allowStore,allowVirtualCurrencyCreate,allowItemCreate,allowBundleCreate,allowStoreCreate,allowVirtualCurrencyEdit,allowItemEdit,allowBundleEdit,allowStoreEdit,
        allowProgressionMarker,allowProgressionMarkerCreate,allowProgressionMarkerEdit,allowProgressionSystem,allowProgressionSystemCreate,allowProgressionSystemEdit,allowDefaultEvent,allowDefaultEventEdit,allowCustomEvent,allowCustomEventCreate,allowCustomEventEdit,
        allowRmgCurrency,allowRmgCurrencyCreate,allowRmgCurrencyEdit,allowSpecterBank,allowSpecterBankTransaction,allowPayout,
        allowPlayers,allowPlayersCreate,allowPlayersEdit,allowTask,allowTaskCreate,allowTaskEdit,allowMission,allowMissionCreate,allowMissionEdit,allowStepSeries,allowStepSeriesCreate,allowStepSeriesEdit,allowTournament,allowTournamentCreate,allowTournamentEdit,allowInstantBattle,allowInstantBattleCreate,allowInstantBattleEdit,allowLeaderboard,allowLeaderboardCreate,allowLeaderboardEdit,allowTournamentResult,allowInstantBattleResult,allowLeaderboardResult,
        allowBilling,allowBillingInvoices,allowBillingSettings} = accessPermissions || {};
    
    const LD_CLIENT_SIDE_ID = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID;
    const getLocalStorageData = () => {
        const appName = localStorage.getItem('App')
        setAppName(appName)
    }


  async function getAppFlagsAccessConfig() {
    try {
      const token = localStorage.getItem("token");
      const environment = localStorage.getItem("environment");
      const apiKeys = JSON.parse(localStorage.getItem("apiKeys"));
  
      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Api-Key":
            environment === "Production"
              ? apiKeys.productionAPIKey
              : environment === "Staging"
              ? apiKeys.stagingAPIKey
              : apiKeys.devAPIKey,
        },
      };
  
      const requestData = {
        organisationId: localStorage.getItem("orgId"),
        // memberIds: [JSON.parse(localStorage.getItem('user'))?.id],
        memberEmails: [JSON.parse(localStorage.getItem('user'))?.email],
        env: ["global",environment?.toLowerCase()],
        projectIds: [appid],
      };
      const orgFlagData = await axios.post(BASE_URL + GET_ACCESS_CONFIG, requestData, headers);
      const existingFlags = accessPermissions || {};
      const DataFlagArr =orgFlagData.data.data.accessDetails.filter(e=>(e?.projectFlags?.length>0))
      DataFlagArr && DataFlagArr.forEach((e)=>{
          e?.projectFlags && e?.projectFlags.forEach(({ key, selected }) => {
              existingFlags[key] = selected; // Replace or add the flag
          });
      })
      // Update the existing Flags object with the new flags data
      
      // Store the updated Flags object back in localStorage
      setAccessPermissions(existingFlags); 

    } catch (error) {
      console.error("Error fetching app flags:", error);
      return {};
    }
  }

  useEffect(() => {
    if (appid) {
      getAppFlagsAccessConfig(appid)
    }
  }, [appid])


    useEffect(()=>{
        getLocalStorageData()
    }, [])

    const globalStates = useContext(GlobalStateContext)

    useEffect(() => {
        // Check if the user is unauthorized for /RMG
        const pathsToCheck = ['/RMG', '/specter-bank', '/payout', '/billing-overview', '/invoices', '/billing-settings'];
        if (
            // (isAdminEmail==false && pathsToCheck.some(path => pathname.includes(path)))||
            (allowAppsPage==false && pathname.includes('/apps'))||
            (allowOrganizationSettings==false && pathname.includes('/settings-organization-profile'))||
            (allowUserSettingsProfile==false && pathname.includes('/settings-member-profile'))||
            (allowAcceptedMembers==false && pathname.includes('/access-manager-accepted'))||
            (allowUsage==false && pathname.includes('/usage-analytics'))||
            (allowUpgradePlansAccess==false && pathname.includes('/plans'))||
            (allowInvitedMembers==false && pathname.includes('/access-manager-invited'))||
            (allowGlobalAppsInfo==false && pathname.includes('/app-information'))||
            (allowAppMedia==false && pathname.includes('/app-media'))||
            (allowAppMedia==false && pathname.includes('/media/folders'))||
            (allowDocument==false && pathname.includes('/app-documents'))||
            (allowDocumentCreate==false && pathname.includes('/create-document'))||
            (allowDocumentEdit==false && pathname.includes('/edit-document'))||
            (allowAppAPI==false && pathname.includes('/app-api'))||
            (allowActivity==false && pathname.includes('/activityFeed'))||
            (allowAppsOverview==false && pathname.includes('/app-overview'))||
            (allowEnvironmentAppInfo==false && pathname.includes('/env-information'))||
            (allowTerminal==false && pathname.includes('/terminal'))||
            (allowGames==false && pathname.includes('/games'))||
            (allowGamesCreate==false && pathname.includes('/create-game'))||
            (allowGamesEdit==false && pathname.includes('/edit-game'))||
            (allowMatches==false && pathname.includes('/matches'))||
            (allowMatchesCreate==false && pathname.includes('/create-match'))||
            (allowMatchesEdit==false && pathname.includes('/edit-match'))||
            (allowVirtualCurrency==false && pathname.includes('/currency'))||
            (allowVirtualCurrencyCreate==false && pathname.includes('/create-currency'))||
            (allowVirtualCurrencyEdit==false && pathname.includes('/edit-currency'))||
            (allowItem==false && pathname.includes('/item'))||
            (allowItemCreate==false && pathname.includes('/create-item'))||
            (allowItemEdit==false && pathname.includes('/edit-item'))||
            (allowBundle==false && pathname.includes('/bundle'))||
            (allowBundleCreate==false && pathname.includes('/create-bundle'))||
            (allowBundleEdit==false && pathname.includes('/edit-bundle'))||
            (allowStore==false && pathname.includes('/store'))||
            (allowStoreCreate==false && pathname.includes('/create-store'))||
            (allowStoreEdit==false && pathname.includes('/edit-store'))||
            (allowProgressionMarker==false && pathname.includes('/progression-marker'))||
            (allowProgressionMarkerCreate==false && pathname.includes('/create-progression-marker'))||
            (allowProgressionMarkerEdit==false && pathname.includes('/edit-progression-marker'))||
            (allowProgressionSystem==false && pathname.includes('/progression-system'))||
            (allowProgressionSystemCreate==false && pathname.includes('/create-progression-system'))||
            (allowProgressionSystemEdit==false && pathname.includes('/edit-progression-system'))||
            (allowDefaultEvent==false && pathname.includes('/default-events'))||
            (allowDefaultEventEdit==false && pathname.includes('/edit-default-events'))||
            (allowCustomEvent==false && pathname.includes('/custom-events'))||
            (allowCustomEventCreate==false && pathname.includes('/create-events'))||
            (allowCustomEventEdit==false && pathname.includes('/edit-custom-events'))||
            (allowRmgCurrency==false && pathname.includes('/RMG'))||
            (allowRmgCurrencyCreate==false && pathname.includes('/create-RMG'))||
            (allowRmgCurrencyEdit==false && pathname.includes('/edit-RMG'))||
            (allowSpecterBank==false && (pathname.includes('/specter-bank')&&!pathname.includes('/specter-bank/transactions')))||
            (allowSpecterBankTransaction==false && pathname.includes('/specter-bank/transactions'))||
            (allowPayout==false && pathname.includes('/payout'))||

            // (allowPlayersCreate==false && pathname.includes('/player-details'))||
            (allowPlayers==false && pathname.includes('/players'))||
            (allowPlayersEdit==false && pathname.includes('/player-details'))||
            (allowTask==false && pathname.includes('/task'))||
            (allowTaskCreate==false && pathname.includes('/create-task'))||
            (allowTaskEdit==false && pathname.includes('/edit-task'))||
            (allowMission==false && pathname.includes('/mission'))||
            (allowMissionCreate==false && pathname.includes('/create-mission'))||
            (allowMissionEdit==false && pathname.includes('/edit-mission'))||
            (allowStepSeries==false && pathname.includes('/step-series'))||
            (allowStepSeriesCreate==false && pathname.includes('/create-step-series'))||
            (allowStepSeriesEdit==false && pathname.includes('/edit-step-series'))||
            (allowTournament==false && pathname.includes('/competition-tournament'))||
            (allowTournamentCreate==false && pathname.includes('/create-competition-tournament'))||
            (allowTournamentEdit==false && pathname.includes('/edit-competition-tournament'))||
            (allowInstantBattle==false && pathname.includes('/competition-instant-battle'))||
            (allowInstantBattleCreate==false && pathname.includes('/create-competition-instant-battle'))||
            (allowInstantBattleEdit==false && pathname.includes('/edit-competition-instant-battle'))||
            (allowLeaderboard==false && pathname.includes('/leaderboard'))||
            (allowLeaderboardCreate==false && pathname.includes('/create-leaderboard'))||
            (allowLeaderboardEdit==false && pathname.includes('/edit-leaderboard'))||
            (allowTournamentResult==false && pathname.includes('/competition-result'))||
            (allowInstantBattleResult==false && pathname.includes('/instant-battle-result'))||
            (allowLeaderboardResult==false && pathname.includes('/leaderboard-details'))||
            (allowBilling==false && pathname.includes('/billing-overview'))||
            (allowBillingInvoices==false && pathname.includes('/invoices'))||
            (allowBillingSettings==false && pathname.includes('/billing-settings'))
            ) {
            navigate('/forbidden',{ replace: true }); // Redirect to the unauthorized page
        }
    }, [isAdminEmail, pathname, navigate,allowAppsPage,allowOrganizationSettings,allowUserSettingsProfile]);

    const style = {
        display :'block',
        margin : '0px auto',
        width : '160px',
        height : '160px'
    }

    const checkLinks = () => {
        if (pathname.includes('/app-overview') || pathname.includes('/termianl') || pathname.includes('/player-details') || pathname.includes('/env-information') || (type === 'AppSettings' && (!window.location.pathname.includes('/app-document') && !window.location.pathname.includes('/app-accepted-members') && !window.location.pathname.includes('/app-invited-members')))) return true
    }

    return (
        <>
        <ToastContainer />
        {showLoader ?
            <div className={styles.loaderContainer}>
                <div className={styles.loaderWrapper}>
                    <div className={styles.loader}>
                        <Lottie animationData={Loader} loop={true} autoPlay={true} style={style} />
                    </div>
                    <div className={styles.loaderTitle}>
                        {loaderType === 0 && <div className={styles.title}>Crafting your Specter experience...<br />Welcome aboard!</div>}
                        {loaderType === 1 && <div className={styles.title}>Welcoming you back to where the magic happens!</div>}
                        {loaderType === 2 && <div className={styles.title}>Shifting realms to better suit your needs...<br />Hang tight!</div>}
                        {loaderType === 3 && <div className={styles.title}>Laying the foundation for your next big hit...<br />Stay tuned!</div>}
                        {loaderType === 4 && <div className={styles.title}>Navigating to new possibilities...<br />Where to next?</div>}
                        {loaderType === 5 && <div className={styles.title}>Returning you to the big picture...<br /> Let's see what's next!</div>}
                    </div>
                </div>
            </div>
        :
            <>
            <Modal width='large' visible={globalStates.showQuickGuideModal} onClose={() => globalStates.setShowQuickGuideModal(false)}>
              <IntroductionModal setLoaderType={setLoaderType} setShowLoader={setShowLoader} setShowAnimation={globalStates.setShowAnimation} onClose={()=> globalStates.setShowQuickGuideModal(false)}/>
            </Modal>
            <div className={cn(styles.page, {[styles.wide]: wide})}>
            {/* <LDProvider
                clientSideID={LD_CLIENT_SIDE_ID}
                user={{
                key: JSON.parse(localStorage.getItem('user'))?.id,
                name: JSON.parse(localStorage.getItem('user'))?.name,
                email: JSON.parse(localStorage.getItem('user'))?.email
                }}
            > */}
                <Sidebar wide={wide} loaderType={loaderType} setLoaderType={setLoaderType} appName={appName} setAppName={setAppName} showLoader={showLoader} setShowLoader={setShowLoader} isChanged={isChanged} setIsChanged={setIsChanged} type={type} className={cn(styles.sidebar, {[styles.visible]: visible})} onClose={() => setVisible(false)} />
                <Header title={title} wide={wide} loaderType={loaderType} setLoaderType={setLoaderType} appName={appName} setAppName={setAppName} showLoader={showLoader} setShowLoader={setShowLoader} type={type} isChanged={isChanged} setIsChanged={setIsChanged} onOpen={() => setVisible(true)} onClose={() => setVisible(false)}/>
                <div className={cn(styles.inner, {[styles.view] : view}, {[styles.resize] : checkLinks()})}>
                    {background &&
                        <>
                            <div className={styles.background}>
                                <img src={Cross} width={22} height={22} className={styles.cross}/>
                                <OutlineCircle className={styles.outlineCircle} />
                                <OutlineCross className={styles.outLineCross}/>
                                <OutlineCross className={styles.bottomOutLineCross}/>
                                <Circle className={styles.circleBottomRight} />
                                <Circle className={styles.circleBottomLeft} />
                            </div>
                        </>
                    }
                        <div className={cn(styles.container, {[styles.wide]: wide}, {[styles.view] : (title ? title.includes('View ') : false)})}>
                            {React.Children.map(children, child => 
                                React.cloneElement(child, {loaderType, setLoaderType, isChanged, setIsChanged, showLoader, setShowLoader, appName, setAppName })
                            )}
                        </div>
                </div>
            {/* </LDProvider> */}
            </div>
            </>
        }
        <div className={cn(styles.overlay, {[styles.active] : globalStates.showOverlay})}></div>
        </>
    );
};

export const useAccess = () => useContext(AccessContext);

const Page = (props) => {
    return (
      <LDWrapper>
        <PageContent {...props} />
      </LDWrapper>
    );
  };

export default Page;
