import React, { useState, useEffect, useContext } from "react";
import styles from "./Sidebar.module.sass";
import { Link, NavLink,useNavigate, useLocation, useParams } from "react-router-dom";
import cn from "classnames";
import Icon from "../Icon";
import Theme from "../Theme";
// import Help from "./Help";
import Dropdown from "./Dropdown";
import Image from "../Image";
import { ReactComponent as  DirtcubeSvg} from "../../media/images/dirtcube_profile.svg";
import { ReactComponent as  SpectreProfileSvg} from "../../media/images/Spectre.svg";
import { ReactComponent as  AddIconSvg} from "../../media/images/SidebarAdd.svg";
import { ReactComponent as  ActiveApps} from "../../media/images/icons/active.svg";
import { ReactComponent as  Divider} from "../../media/images/icons/Divider.svg";
import { ReactComponent as  GameIcon} from "../../media/images/icons/AppInformation.svg";
import { ReactComponent as  ServerIcon} from "../../media/images/icons/server_icon.svg";
import { ReactComponent as  Folder} from "../../media/images/icons/folder.svg";
import { getallAppAction } from "../../redux/action/apps/appsAction";
import EnvironmentSwitchTooltip from '../../components/CustomTooltips/EnvironmentSwitchTooltip'
import WorkspaceTooltip from '../../components/CustomTooltips/WorkspaceTooltip'
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL, GET_APPS, GET_ACCESS_CONFIG} from "../../resources/APIEndpoints";
import { element } from "prop-types";
import { AppMenu } from "../AppMenu";
import AppPlaceHolder from '../../media/images/placeholders/App.png'
import AddAppsModal from "../../components/Organisation/Apps/Modals/AddAppModal";
import Modal from "../../components/Modals/ModalComponent";
import ConfirmNavigationModal from "../../components/ConfirmNavigationModal";
import { getAllGamesAction } from "../../redux/action/builds/games/gamesAction";
import { ReactComponent as  Home} from "../../media/images/icons/home.svg";
import { ReactComponent as  Build} from "../../media/images/icons/build.svg";
import { ReactComponent as  Engage} from "../../media/images/icons/engage.svg";
import { ReactComponent as  Community} from "../../media/images/icons/community_growth.svg";
import { ReactComponent as  Dashboard} from "../../media/images/icons/dashboard.svg";
import { ReactComponent as  Apps} from "../../media/images/icons/apps.svg";
import { ReactComponent as  ActivityFeed} from "../../media/images/icons/activity_feed.svg";
import { ReactComponent as  Settings} from "../../media/images/icons/settings.svg";
import { ReactComponent as  AccessManager} from "../../media/images/icons/access_manager.svg";
import { ReactComponent as  TerminalIcon} from "../../media/images/icons/terminal.svg";
import { ReactComponent as  MediaIcon} from "../../media/images/icons/image_icon.svg";
import { ReactComponent as  MemberIcon} from "../../media/images/icons/members_icon.svg";
import { ReactComponent as  ApiIcon} from "../../media/images/icons/api_icon.svg";
import { ReactComponent as  Info} from "../../media/images/icons/info.svg";
import { ReactComponent as  Pricing} from "../../media/images/icons/trophy.svg";
import { getOrgProfileAction } from "../../redux/action/organisation/ProfileAction/profileAction";
import GlobalStateContext from "../Context/GlobalStates/GlobalStateContext";
 
import { extarctImageFromUrlAction } from "../../redux/action/common/commonAction";
import SnapShot from "../Images";
import TextTooltip from "../CustomTooltips/Tooltip";
import { useAccess } from "../Page";
const Sidebar = ({ className, wide, onClose, type, isChanged,setIsChanged, showLoader, setShowLoader, appName, setAppName, setLoaderType}) => {

    const { pathname } = useLocation();
    const dispatch = useDispatch()
    const {appid} = useParams()
    const Navigate = useNavigate();
    const [visibleHelp, setVisibleHelp] = useState(false);
    const [visible, setVisible] = useState(false);
    const [GamesMenu, setGamesMenu] = useState([])
    const [visibleModal, setVisibleModal] = useState(false);
    const [visibleMod, setVisibleMod] = useState(false);
    const [lnk, setLnk] = useState()
    const [appIcon,setAppIcon]=useState(null)
    const [workSpace, setWorkSpace] = useState([])
    const globalStates = useContext(GlobalStateContext)    
    const { accessPermissions,setAccessPermissions } = useAccess();
    const { 
        allowAppsCreate, allowGlobalAppsInfo, allowAppMedia, allowDocument, allowAppAPI, allowActivity, allowAppsOverview, allowEnvironmentAppInfo, allowTerminal, allowVirtualCurrency,allowItem,allowBundle,allowStore, allowRmgCurrency,
        allowProgressionMarker,allowProgressionSystem,allowDefaultEvent,allowCustomEvent,allowRMGCurrency,allowSpecterBank,allowPayout,allowPlayers,allowTask,allowMission,allowStepSeries,allowTournament,allowInstantBattle,allowLeaderboard
    } = accessPermissions || {};

    const navigation = [
        {
            title: "Overview",
            icon: <Dashboard />,
            url: `/app-overview/${appid}`,
        },
        {
            title: "Build",
            slug: "build",
            icon: <Build />,
            add: false,
            dropdown: [
                {
                    title: "Games",
                    url: `/games/${appid}`,
                },
                {
                    title: "Matches",
                    url: `/matches/${appid}`,
                },
                {
                    title: "Economy",
                    url: allowVirtualCurrency?`/currency/${appid}`:allowItem?`/item/${appid}`:allowBundle?`/bundle/${appid}`:allowStore?`/store/${appid}`:"",
                },
                {
                    title: "Progression",
                    url: allowProgressionMarker?`/progression-marker/${appid}`:allowProgressionSystem?`/progression-system/${appid}`:"",
                },
                {
                    title: "Events",
                    url: allowDefaultEvent?`/default-events/${appid}`:allowCustomEvent?`/custom-events/${appid}`:"",
                },
                {
                    title: "RMG",
                    url: allowRmgCurrency? `/RMG/${appid}` : allowSpecterBank ? `/specter-bank/${appid}` : allowPayout ? `/payout/${appid}` : "",
                },
            ],
        },
        {
            title: "Engage",
            slug: "engage",
            icon: <Engage />,
            add: false,
            dropdown: [
                {
                    title: "Players",
                    url: allowPlayers?`/players/${appid}`:"",
                },
                {
                    title: "Achievements",
                    url: allowTask?`/task/${appid}`:allowMission?`/mission/${appid}`:allowStepSeries?`/step-series/${appid}`:"",
                },
                {
                    title: "Competitions",
                    url: allowTournament?`/competition-tournament/${appid}`:allowInstantBattle?`/competition-instant-battle/${appid}`:"",
                },
                {
                    title: "Leaderboards",
                    url: allowLeaderboard?`/leaderboard/${appid}`:"",
                }
            ],
        },
        {
            title: "Terminal",
            icon: <TerminalIcon />,
            url: `/terminal/${appid}`,
        },
        {
            title: localStorage.getItem('environment')+' App Info',
            // title: "Environment Settings",
            icon: <Settings />,
            url: `/env-information/${appid}`,
        }
    ];

    const organizationMenu = [
        {
            title: "Dashboard",
            icon : <Dashboard />,
            active : true,
            url: "/home"
        },
        {
            title: "Apps",
            icon : <Apps />,
            active : true,
            url: "/apps"
        },
        {
            title: "Settings",
            icon : <Settings />,
            active : true,
            url: "/settings-organization-profile"
        },
        {
            title: "Access Manager",
            icon : <AccessManager />,
            active : true,
            url: "/access-manager-accepted"
        },
        {
            title: "Notifications",
            active : true,
            url: "/notificationFeed"
        },
        {
            title: 'Plans & Billing',
            icon : <Icon name='payment' size='24' className={styles.payment}/>,
            active: true,
            url: '/billing-overview'
        }
    ];


    const appSettings = [
        {
            title: "Global App Info",
            icon : <Info className={styles.payment} style={{fill: '#6F767E !important'}}/>,
            active : true,
            url: `/app-information/${appid}`
        },
        {
            title: "Media",
            icon : <MediaIcon />,
            active : true,
            url: `/app-media/${appid}`,
        },
        {
            title: "Documents",
            icon : <Folder />,
            active : true,
            url: `/app-documents/${appid}`,
        },
        {
            title: "API",
            icon : <ApiIcon />,
            active : true,
            url : `/app-api/${appid}`
        },
        // {
        //     title: "Members",
        //     icon : <MemberIcon />,
        //     active : true,
        //     url : `/app-accepted-members/${appid}`
        // },
        {
            title: "Activity",
            active : true,
            url : `/activityFeed/${appid}`
        }
    ];

    const getAllApps = async() => {
        let getAllApps = {offset : 0, limit : 50, organisationId: localStorage.getItem('orgId')}
        await dispatch(getallAppAction(getAllApps, setShowLoader))
    }

    let apps = useSelector((state)=>{
        return state.apps.app
    })

    useEffect(()=>{
        configureApps()
    }, [apps])

    const configureApps = async() => {
        if(apps && apps.length > 0){
            let appsMenu = await Promise.all(apps && apps.map(async element => (
                {
                    id : element.id,
                    appName : element.name,
                    appIconUrl : (element.iconUrl != null ? element.iconUrl : null),
                    url : `/app-overview/${element.id}`
                }
            )))
            setGamesMenu(appsMenu)
        }
    }

    const getAppName = (x) => {
        setAppName(x.appName)
        localStorage.setItem('App', x.appName)
        localStorage.setItem('AppIcon', x.appIconUrl)
    }
    const getAppIcon = () => {
        let appIcon=localStorage.getItem('AppIcon')
        setAppIcon(appIcon)
    }

    const refreshPage = () => {
        localStorage.setItem('environment', "Development")
        loaderConfigure(4)
    }

    const [isAppMenu, setIsAppMenu] = useState(false)
    const [appNameHolder, setAppNameHolder] = useState('')

    // async function getAppFlagsAccessConfig() {
    //     try {
    //       const token = localStorage.getItem("token");
    //       const environment = localStorage.getItem("environment");
    //       const apiKeys = JSON.parse(localStorage.getItem("apiKeys"));
      
    //       const headers = {
    //         headers: {
    //           Authorization: `Bearer ${token}`,
    //           "Api-Key":
    //             environment === "Production"
    //               ? apiKeys.productionAPIKey
    //               : environment === "Staging"
    //               ? apiKeys.stagingAPIKey
    //               : apiKeys.devAPIKey,
    //         },
    //       };
      
    //       const requestData = {
    //         organisationId: localStorage.getItem("orgId"),
    //         // memberIds: [JSON.parse(localStorage.getItem('user'))?.id],
    //         memberEmails: [JSON.parse(localStorage.getItem('user'))?.email],
    //         env: ["global",environment?.toLowerCase()],
    //         projectIds: [appid],
    //       };
    //       const orgFlagData = await axios.post(BASE_URL + GET_ACCESS_CONFIG, requestData, headers);
          
    //       const existingFlags = accessPermissions || {};
    //       const DataFlagArr =orgFlagData.data.data.accessDetails.filter(e=>(e?.projectFlags?.length>0))
    //       DataFlagArr && DataFlagArr.forEach((e)=>{
    //           e?.projectFlags && e?.projectFlags.forEach(({ key, selected }) => {
    //               existingFlags[key] = selected; // Replace or add the flag
    //           });
    //       })
    //       // Update the existing Flags object with the new flags data
          
    //       // Store the updated Flags object back in localStorage
    //       setAccessPermissions(existingFlags); 
    
    //     } catch (error) {
    //       console.error("Error fetching app flags:", error);
    //       return {};
    //     }
    // }
    
    const onNavigateApps = (x) => {
        if (isChanged==true) {
            setIsAppMenu(true)
            setLnk(x.url)
            setAppNameHolder(x)
            setVisibleMod(true);
        } else {
            setIsAppMenu(false)
            getAppName(x)
            Navigate(x.url)
            refreshPage()
        }
        // getAppFlagsAccessConfig()
    }

    const onNavigateLinks = (x) => {
        if (isChanged==true) {
            setLnk(x)
            setVisibleMod(true);
        } else {
            Navigate(x)
        }
    }

    const onCls = () => {
        setVisibleMod(false)
        setLnk()
        setAppNameHolder('')
    }

    const onProceed = () => {
        setVisibleMod(false)
        Navigate(lnk)
        setIsChanged(false)
        if(isAppMenu){
            getAppName(appNameHolder)
            setIsAppMenu(false)
            refreshPage()
        }
    }
    
    useEffect(()=>{
        getAllApps()
    }, [])

    const [organisation, setOrganisation] = useState({name : '', iconUrl : ''})

    const getLocalStorageData = () => {
        const env = localStorage.getItem('environment')
        setEnvironment(env == 'Staging' ? 'Quality Assurance' : env)
    }

    const getWorkSpace = async() => {
        const user = JSON.parse(localStorage.getItem('user'))
        if(user?.hasOwnProperty('organisations') && user?.organisations !== null){
            let organisationList = await Promise.all(
                user.organisations && user.organisations.map(async x => ({
                    ...x,
                    default_logo_url: (x?.default_logo_url !== '' && x?.default_logo_url !== null) ? x?.default_logo_url : null
                }))
            )
            setWorkSpace(organisationList)
        }
    }
    
    const loaderConfigure = (loaderType) => {
        setLoaderType(loaderType)
        setShowLoader(true)
        setTimeout(() => {
            setShowLoader(false)
        }, 4000)
    }

    const [environmentOption, setEnvironmentOption] = useState([{id : 1, name : 'Production', active: (localStorage.getItem('environment') =='Production' ? true :false) },{id : 2, name : 'Quality Assurance', active: (localStorage.getItem('environment') =='Staging' ? true :false)}, {id : 3, name : 'Development', active: (localStorage.getItem('environment') =='Development' ? true :false)}])
    const [environment, setEnvironment] = useState()

    const resetEnvironment = () => {
        localStorage.setItem('environment', 'Development')
        loaderConfigure(5)
        onClose()
    }
    
    const getOrgProfile = () => {
        let org = {organisationId: localStorage.getItem('orgId')}
        dispatch(getOrgProfileAction(org))
    }

    let localAppIcon=localStorage.getItem('AppIcon')

    useEffect(()=>{
        getLocalStorageData()
        getOrgProfile()
        getWorkSpace()
    }, [])

    useEffect(()=>{
        getAppIcon()
    },[localAppIcon])

    let OrgProfile = useSelector((state) => {
        return state.organisation.orgProfile
    })

    const configOrganisation = async() => {
        if(OrgProfile){
            let organisationIconURL = (OrgProfile.defaultLogoUrl !== '' && OrgProfile.defaultLogoUrl !== null) ? OrgProfile.defaultLogoUrl : null
            setOrganisation(org => ({...org, name : OrgProfile.name, iconUrl : organisationIconURL}))
        }
    }
    
    useEffect(()=>{
        configOrganisation()
    }, [OrgProfile])

    return(
        <>
            <div className={cn(styles.sidebar, className, {[styles.active]: visible}, {[styles.wide] : wide})}>
                <div className={styles.mainSidebar}>
                    <div className={styles.mainSidebarContainer}>
                        <div className={styles.appSideBar}>
                            <div className={styles.appSideBarContainer}>
                                <div className={styles.orgApps}>
                                    {type !== 'Organization' &&
                                        <div className={styles.orgLogo}>
                                            <div className={styles.org}>
                                                {organisation.iconUrl !== null && <SnapShot id={`organisation_active_${organisation.id}`} url={organisation.iconUrl} alt={organisation.name} placeholder={OrgProfile} type='Images' width={28} height={28}/>}
                                                {organisation.iconUrl === null && <div className={styles.backGround}><div className={styles.txt}>P</div></div>}
                                            </div>
                                        </div>
                                    }
                                    {GamesMenu && GamesMenu.map((x, index) =>
                                        (
                                            <>
                                                <div className={cn(styles.appsList, {[styles.active]: type !== 'Organization'})}>
                                                    <div className={cn(styles.activeApps, {[styles.active]: x.url.includes(appid)})}>
                                                        <ActiveApps />
                                                    </div>
                                                    <div className={styles.appIcon} onClick={()=>onNavigateApps(x)}>
                                                        <div key={index} state={{orgId : x.id}}>
                                                            <TextTooltip text={x?.appName}><SnapShot id={`siderbarIcon_${x.id}`} className={cn(styles.appIcons, {[styles.active]: x.url.includes(appid)})} url={x.appIconUrl} alt={`${x.appName}`} placeholder={AppPlaceHolder} title={`${x.appName}`} type='Images' width={28} height={28} /></TextTooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    )}
                                </div>
                                {globalStates?.user?.type === 'admin' && allowAppsCreate&&
                                    <div className={styles.addApps}>
                                        <div className={styles.appAddIcon}>
                                            <Link onClick={()=>{setVisibleModal(true)}} className={styles.button}>
                                                <AddIconSvg className={styles.appAddIcon}/>
                                            </Link>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={styles.navSideBar}>
                            <div className={styles.navBarContainer}>
                                <button className={styles.close} onClick={onClose}>
                                    <Icon name="close" size="24" />
                                </button>
                                <div className={styles.orgTitle}>
                                    <WorkspaceTooltip xw={true} wide={false} data={workSpace} setLoaderType={setLoaderType} showLoader={showLoader} setShowLoader={setShowLoader}>
                                        <div className={styles.organization}>
                                            <div className={styles.organizationName}>
                                                <div className={styles.orgText}>
                                                    <span className={styles.name}>{organisation.name}</span>
                                                </div>
                                                <div className={styles.arrow}>
                                                    <Icon name='arrow-next' size='16' />
                                                </div>
                                            </div>
                                        </div>
                                    </WorkspaceTooltip>

                                    {/* {type === 'Organization' &&
                                        <>
                                            <div className={styles.organisation}>
                                                <div className={styles.orgIcon}>
                                                    <img src={organisation.iconUrl != null ? organisation.iconUrl : ProfilePlaceHolder} alt="orgLogo" />
                                                </div>
                                                <div className={styles.orgName}>
                                                    <h5 className={styles.orgText}>{organisation.name}</h5>
                                                </div>
                                            </div>
                                        </>
                                    } */}
                                </div>
                                {type == 'App' ?
                                    <div className={styles.menu}>
                                        {navigation && navigation.map((x, index) =>
                                            x.url ? (
                                                (
                                                    (!allowAppsOverview&& x?.url?.includes("/app-overview"))||
                                                    (!allowEnvironmentAppInfo&& x?.url?.includes("/env-information"))||
                                                    (!allowTerminal&& x?.url?.includes("/terminal"))
                                                )?
                                                ""
                                                :
                                                <div className={cn(styles.item, {[styles.active]: x.url.includes(pathname.slice(0,16))})}
                                                    onClick={()=>onNavigateLinks(x.url)} key={index}>
                                                        {x.icon}{x.title}
                                                </div>
                                            ) : (
                                                <Dropdown onProceed={onProceed} onCls={onCls} NavTo={onNavigateLinks} className={styles.dropdown} visibleSidebar={visible}
                                                    setValue={setVisible} key={index} item={x} onClose={onClose} />
                                            )
                                        )}
                                    </div>
                                : ''}
                                {type == 'Organization' ?
                                    <div className={styles.menu}>
                                    {organizationMenu.map((x, index) =>
                                        x.url ? (
                                            <div className={cn(styles.item, {[styles.active]: x.url.includes(pathname.slice(0,16))})}
                                            onClick={x.active ? ()=>onNavigateLinks(x.url) : null} key={index} >
                                               {x?.icon?x?.icon:<div style={{ fill: "#6F767E" }} className={cn(styles.notify)} ><Icon name="notification" size="20"/></div>}{x.title}
                                            </div>
                                        ) : (
                                            <Dropdown onProceed={onProceed} onCls={onCls} NavTo={x.active ? onNavigateLinks : null} className={styles.dropdown} visibleSidebar={visible}
                                                setValue={setVisible} key={index} item={x} onClose={onClose}/>
                                        )
                                    )}
                                    </div>
                                : ''}

                                {type == 'AppSettings' ?
                                    <div className={styles.menu}>
                                    {appSettings.map((x, index) =>
                                        x?.url ? (
                                            (allowGlobalAppsInfo==false&& x?.url?.includes('/app-information')||
                                            allowAppMedia==false&& x?.url?.includes('/app-media')||
                                            allowDocument==false&& x?.url?.includes('/app-documents')||
                                            allowAppAPI==false&& x?.url?.includes('/app-api')||
                                            allowActivity==false&& x?.url?.includes('/activityFeed')
                                            )?
                                            ""
                                            :
                                            <>
                                                <div className={cn(styles.item, {[styles.active]: (x?.title !== 'Documents' ? x.url.includes(pathname.slice(0,16)) : pathname.includes('document'))})}
                                                onClick={x.active ? ()=>onNavigateLinks(x.url) : null} key={index} >
                                                    {x?.icon?x?.icon:<div style={{ fill: "#6F767E" }} className={cn(styles.notify)} ><Icon name="notification" size="24"/></div>}{x.title}
                                                </div>
                                            </>
                                        ) : (
                                            <Dropdown onProceed={onProceed} onCls={onCls} NavTo={x.active ? onNavigateLinks : null} className={styles.dropdown} visibleSidebar={visible}
                                                setValue={setVisible} key={index} item={x} onClose={onClose}/>
                                        )
                                    )}
                                    </div>
                                : ''}

                                <button className={styles.toggle} onClick={() => setVisible(!visible)}>
                                    <Icon name="arrow-right" size="24" />
                                    <Icon name="close" size="24" />
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal width='small' visible={visibleModal} onClose={() => setVisibleModal(false)}>
                <AddAppsModal setLoaderType={setLoaderType} setApp={setAppName} setShowLoader={setShowLoader} onClose={() => setVisibleModal(false)}/>
            </Modal>
            <Modal visible={visibleMod} onClose={() => onCls()}>
                <ConfirmNavigationModal onProceed={()=>onProceed()} onClose={() => onCls()}/>
            </Modal>
        </>
    )
};

export default Sidebar;
