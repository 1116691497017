import React, { useEffect, useState } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../Checkbox";
import Icon from "../../../../Icon";
import Control from "./Control/index";
import { useParams } from "react-router-dom";
import Item from '../../../../../media/images/placeholders/Items.png'
import Bundle from '../../../../../media/images/placeholders/Bundles.png'
import moment from "moment";
import Tooltip from "../../../../CustomTooltips/ReusableTooltip";
import { extarctImageFromUrlAction } from "../../../../../redux/action/common/commonAction";
import { useDispatch } from "react-redux";
/* Deleted Schedule, Modal, Modal Products and Actions */

const Row = ({usedFor, item, value, onChange }) => {

    const [type, setType] = useState([])
    const dispatch = useDispatch()

    const configureData = () => {
        if(item && item.item != null){
            let types = []
            if(item.item.isStackable == true){
                types.push({name: 'Stackable', quantity: item.item.stackCapacity})
            }
            if(item.item.isEquippable == true){
                types.push({name: 'Equippable'})
            }
            if(item.item.isTradable == true){
                types.push({name: 'Tradable'})
            }
            if(item.item.isRentable == true){
                types.push({name: 'Rentable'})
            }
            if(item.item.isTimeStackable == true){
                types.push({name: 'Time Stackable'})
            }
            if(item.item.isConsumable == true){
                types.push({name: 'Consumable', quantity: (item.item.consumeByUses != null ? item.item.consumeByUses : `${item.item.consumeByTime}s`)})
            }
            setType(types)
        }else if(item && item.bundle != null){
            let types = []
            if(item.bundle.isStackable == true){
                types.push({name: 'Stackable', quantity: item.bundle.stackCapacity})
            }
            if(item.bundle.isEquippable == true){
                types.push({name: 'Equippable'})
            }
            if(item.bundle.isTradable == true){
                types.push({name: 'Tradable'})
            }
            if(item.bundle.isRentable == true){
                types.push({name: 'Rentable'})
            }
            if(item.bundle.isTimeStackable == true){
                types.push({name: 'Time Stackable'})
            }
            if(item.bundle.isConsumable == true){
                types.push({name: 'Consumable', quantity: (item.bundle.consumeByUses != null ? item.bundle.consumeByUses : `${item.item.consumeByTime}s`)})
            }
            setType(types)
        }
    }

    const extractImageFromCdn = async(url, id, type) => {
        if(url !== null && url !== undefined && url.includes('cdn')){
            try {
                const response = await dispatch(extarctImageFromUrlAction(url))
                const logo = document.getElementById(`itemIcon_${id}`)
                if(logo){
                    logo.src = response
                }
            }catch (error){
                console.error('Error fetching image:', error);
            }
        }else{
            const logo = document.getElementById(`itemIcon_${id}`)
            if(logo){
                logo.src = (type === 'Item' ? Item : type === 'Bundle' ? Bundle : null)
            }
        }
    }

    useEffect(() =>{
        configureData()
        extractImageFromCdn((item && item.itemId != null ? item.item.iconUrl : item && item.bundleId != null ? item.bundle.iconUrl : ''), (item && item.itemId != null ? item.item.id : item && item.bundleId != null ? item.bundle.id : ''), (item && item.itemId != null ? 'Item' : item && item.bundleId != null ? 'Bundle' : ''))
    }, [item])

    if(item){
        return (
            <>
                <div className={styles.row}>
                    <div className={styles.col}>
                        <div className={styles.item}>
                            <div className={styles.preview}>
                                <img id={`itemIcon_${(item && item.itemId != null ? item.item.id : item && item.bundleId != null ? item.bundle.id : '')}`} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.col}><div className={styles.itemName}>{item && item.itemId != null ? item.item.name : item && item.bundleId != null ? item.bundle.name : ''}</div><div className={styles.itemId}>{item && item.itemId != null ? item.item.itemId : item && item.bundleId != null ? item.bundle.bundleId : ''}</div></div>
                    <div className={styles.col}>
                        {type && type.length > 0 ?
                            type && type.slice(0,1).map((e, i) => {
                                return(
                                    <>
                                        <div className={styles.type}>
                                            <div className={styles.stackable}>{e.name} {e.name == 'Stackable' && `(${e.quantity})`}</div><span>&nbsp;</span>{type.length > 1 && <Tooltip text='Types' type='Task' data={type}>+{type.length - 1}</Tooltip>}
                                        </div>
                                    </>
                                )
                            })
                        : '-'}
                        
                    </div>
                    {usedFor == 0 && <div className={styles.col}>{item.totalQuantity != null ? item.totalQuantity : '-'}</div>}
                    {usedFor == 0 && <div className={styles.col}>{item && item.totalUses != null ? item.totalUses : '-'}</div>}
                    {usedFor == 1 && <div className={styles.col}>{item.quantity != null ? item.quantity : '-'}</div>}
                    {usedFor == 1 && <div className={styles.col}>{item && item.totalUsesAvailable != null ? item.totalUsesAvailable : '-'}</div>}
                    {usedFor == 1 && <div className={styles.col}>{item.stackId != null ? item.stackId : '-'}</div>}
                    {usedFor == 1 && <div className={styles.col}>{item.isEquipped == true ? 'Yes' : item.isEquipped == false ? 'No' : '-'}</div>}
                    {usedFor == 1 && <div className={styles.col}>{item.isOpened != null && item.isOpened == true ? 'Yes' : item.isOpened != null && item.isOpened == false ? 'No' : '-'}</div>}
                    <div className={styles.col}>{(item?.addedAt)?moment(item.addedAt).format('DD-MM-YYYY hh:mm A'):"-"}</div>
                </div>  
            </>
        );
    }
};

export default Row;
