
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import styles from './addMembers.module.sass';
import Icon from '../../../Icon';
import SendInviteInput from '../SendInviteInput';
import { toast } from 'react-toastify';
import Toast from '../../../Toast';
import Checkbox from '../../../Checkbox';
import AppPlaceholder from '../../../../media/images/placeholders/App.png';
import {
  sendMemberInviteAction,
  editInvitedMemberPermissionAction,
  editMemberPermissionAction,
} from '../../../../redux/action/AppSettings/Members/MembersAction';
import app, { increaseUnreadCountForAllUsers } from '../../../../firebase.js';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { BASE_URL,UPDATE_ACCESS_CONFIG, GET_ACCESS_CONFIG} from '../../../../resources/APIEndpoints';
import axios from 'axios';
// Icons
import buildIcon from '../../../../media/images/icons/build.svg';
import engageIcon from '../../../../media/images/icons/engage.svg';
import terminalIcon from '../../../../media/images/icons/terminal.svg';
import devIcon from '../../../../media/images/icons/devEnv.svg';
import qaIcon from '../../../../media/images/icons/qaEnv.svg';
import prodIcon from '../../../../media/images/icons/prodEnv.svg';
import orgIcon from '../../../../media/images/icons/office.svg';
import globalIcon from '../../../../media/images/icons/global.svg';
// ─────────────────────────────────────────────────────────────
// MAPS / CONSTANTS
import { CheckMemberOnLogin } from '../../../../firebase.js';
import { getallAppAction } from '../../../../redux/action/apps/appsAction';
import SnapShot from '../../../Images/index.js';
import { useAccess } from '../../../Page';

const environmentIcons = {
  Development: devIcon,
  QA: qaIcon,
  Production: prodIcon,
};

const categoryIcons = {
  Build: buildIcon,
  Engage: engageIcon,
  Terminal: terminalIcon,
};

const IMMUTABLE_FLAGS = new Set([
  // "allowAppsPage",
  // "allowGlobalAppsInfo",
  // "allowAppsOverview",
  // "allowEnvironmentAppInfo"
]);


// Organization-level flags

const orgLevelFlags = [
  { key: 'allowAppsPage', label: 'View Apps' },
  { key: 'allowAppsCreate', label: 'Create Apps' },
  { key: 'allowAppsAddMembers', label: 'Add Members to Apps' },
  { key: 'allowMembersAccess', label: 'View Members' },
  { key: 'allowAcceptedMembers', label: 'View Accepted Members' },
  { key: 'allowInvitedMembers', label: 'View Invited Members' },
  { key: 'allowAddInviteMembers', label: 'Add or Invite Members' },
  { key: 'allowEditMemberAccess', label: 'Edit Member Access' },
  { key: 'allowOrganizationSettings', label: 'View Organization Settings' },
  { key: 'allowOrganizationSettingsEdit', label: 'Edit Organization Settings' },
  { key: 'allowSettings', label: 'Access Settings' },
  { key: 'allowUserSettingsProfile', label: 'View User Profile Settings' },
  { key: 'allowUserSettingsProfileEdit', label: 'Edit User Profile Settings' },
  { key: 'allowUsage', label: 'View Usage Analytics' },
  { key: 'allowNotifications', label: 'View Notifications' },
  // { key: 'allowInfoHelpButton', label: 'Access Help & Info' },
  { key: 'allowSandboxButton', label: 'Access Sandbox' },

  { key: 'allowBilling', label: 'View Billing Details' },
  { key: 'allowBillingPaymentEdit', label: 'Edit Payment Details' },
  { key: 'allowBillingInvoices', label: 'View Billing Invoices' },
  { key: 'allowBillingInvoicesDownload', label: 'Download Billing Invoice' },
  { key: 'allowBillingSettings', label: 'View Billing Settings' },
  { key: 'allowBillingSettingsEdit', label: 'Edit Billing Settings' },

  { key: 'allowUpgradePlansAccess', label: 'View Plans'},
  { key: 'allowPlanProceedAccess',label: 'Manage Plan Upgrades'},
  // { key: 'isAdminEmail', label: 'Administrator Email (Internal Flag)' },
];
const globalAppFlags = [
  // Global App Info
  { key: 'allowGlobalAppsInfo', label: 'View App Information' },
  { key: 'allowGlobalAppInfoEdit', label: 'Edit App Information' },
  { key: 'allowGlobalAppsSettingsButton', label: 'Access App Settings' },
  // Remote Assets
  { key: 'allowRemoteAssetsDownload', label: 'Download Remote Assets' },
  { key: 'allowRemoteAssetsDownloadAll', label: 'Download All Remote Assets' },
  { key: 'allowRemoteAssetsUpload', label: 'Upload Remote Assets' },
  { key: 'allowRemoteAssetsDelete', label: 'Delete Remote Assets' },
  // Media
  { key: 'allowAppMedia', label: 'View App Media' },
  { key: 'allowAppMediaDownload', label: 'Download App Media' },
  { key: 'allowAppMediaDelete', label: 'Delete App Media' },
  { key: 'allowMediaFolderDownloadAll', label: 'Download All Media Folders' },
  { key: 'allowMediaFolderDeleteAll', label: 'Delete All Media Folders' },
  { key: 'allowMediaFolderUpload', label: 'Upload Media Folders' },
  // Documents
  { key: 'allowDocument', label: 'View Documents' },
  { key: 'allowDocumentCreate', label: 'Create Documents' },
  { key: 'allowDocumentEdit', label: 'Edit Documents' },
  // Other
  { key: 'allowActivity', label: 'View Activity' },
  { key: 'allowAppApi', label: 'Access API Keys' },
];
const ENVIRONMENTS = ['Development', 'QA', 'Production'];
const environmentCategories = [
  {
    title: "Overview",
    flags: [
      { key: "allowAppsOverview", label: "View Environment Overview" },
    ],
  },
  {
    title: "Build",
    children: [
      {
        title: "Games",
        flags: [
          { key: "allowGames", label: "View Games" },
          { key: "allowGamesCreate", label: "Create Games" },
          { key: "allowGamesEdit", label: "Edit Games" },
          { key: "allowGamesArchive", label: "Archive Games" },
        ],
      },
      {
        title: "Matches",
        flags: [
          { key: "allowMatches", label: "View Matches" },
          { key: "allowMatchesCreate", label: "Create Matches" },
          { key: "allowMatchesEdit", label: "Edit Matches" },
          { key: "allowMatchesArchive", label: "Archive Matches" },
        ],
      },
      {
        title: "Economy",
        subcategories: [
          {
            title: "Virtual Currency",
            flags: [
              { key: "allowVirtualCurrency", label: "View Virtual Currency" },
              { key: "allowVirtualCurrencyCreate", label: "Create Virtual Currency" },
              { key: "allowVirtualCurrencyEdit", label: "Edit Virtual Currency" },
              { key: "allowVirtualCurrencyArchive", label: "Archive Virtual Currency" },
            ],
          },
          {
            title: "Items",
            flags: [
              { key: "allowItem", label: "View Items" },
              { key: "allowItemCreate", label: "Create Items" },
              { key: "allowItemEdit", label: "Edit Items" },
              { key: "allowItemArchive", label: "Archive Items" },
            ],
          },
          {
            title: "Bundles",
            flags: [
              { key: "allowBundle", label: "View Bundles" },
              { key: "allowBundleCreate", label: "Create Bundles" },
              { key: "allowBundleEdit", label: "Edit Bundles" },
              { key: "allowBundleArchive", label: "Archive Bundles" },
            ],
          },
          {
            title: "Stores",
            flags: [
              { key: "allowStore", label: "View Stores" },
              { key: "allowStoreCreate", label: "Create Stores" },
              { key: "allowStoreEdit", label: "Edit Stores" },
              { key: "allowStoreArchive", label: "Archive Stores" },
            ],
          },
        ],
      },
      {
        title: "Progression",
        subcategories: [
          {
            title: "Progression Markers",
            flags: [
              { key: "allowProgressionMarker", label: "View Progression Markers" },
              { key: "allowProgressionMarkerCreate", label: "Create Progression Markers" },
              { key: "allowProgressionMarkerEdit", label: "Edit Progression Markers" },
              { key: "allowProgressionMarkerArchive", label: "Archive Progression Markers" },
            ],
          },
          {
            title: "Progression Systems",
            flags: [
              { key: "allowProgressionSystem", label: "View Progression Systems" },
              { key: "allowProgressionSystemCreate", label: "Create Progression Systems" },
              { key: "allowProgressionSystemEdit", label: "Edit Progression Systems" },
              { key: "allowProgressionSystemArchive", label: "Archive Progression Systems" },
            ],
          },
        ],
      },
      {
        title: "Events",
        subcategories: [
          {
            title: "Default Events",
            flags: [
              { key: "allowDefaultEvent", label: "View Default Events" },
              { key: "allowDefaultEventEdit", label: "Edit Default Events" },
            ],
          },
          {
            title: "Custom Events",
            flags: [
              { key: "allowCustomEvent", label: "View Custom Events" },
              { key: "allowCustomEventCreate", label: "Create Custom Events" },
              { key: "allowCustomEventEdit", label: "Edit Custom Events" },
              { key: "allowCustomEventArchive", label: "Archive Custom Events" },
          
            ],
          },
        ],
      },
      {
        title: "RMG",
        subcategories: [
          {
            title: "RMG Currencies",
            flags: [
              { key: "allowRmgCurrency", label: "View RMG Currency" },
              { key: "allowRmgCurrencyCreate", label: "Create RMG Currency" },
              { key: "allowRmgCurrencyEdit", label: "Edit RMG Currency" },
              { key: "allowRmgCurrencyArchive", label: "Archive RMG Currency" },
            ],
          },
          {
            title: "Specter Bank",
            flags: [
              { key: "allowSpecterBank", label: "View Specter Bank" },
              { key: "allowSpecterBankTransaction", label: "View Transaction History" },
            ],
          },
          {
            title: "Payout",
            flags: [
              { key: "allowPayout", label: "View Payouts" },
              { key: "allowPayoutCreate", label: "Create Payouts" },
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Engage",
    children: [
      {
        title: "Players",
        flags: [
          { key: "allowPlayers", label: "View Players" },
          { key: "allowPlayersCreate", label: "Create Players" },
          { key: "allowPlayersEdit", label: "Edit Players" },
        ],
      },
      {
        title: "Achievements",
        subcategories: [
          {
            title: "Tasks",
            flags: [
              { key: "allowTask", label: "View Tasks" },
              { key: "allowTaskCreate", label: "Create Tasks" },
              { key: "allowTaskEdit", label: "Edit Tasks" },
              { key: "allowTaskDelete", label: "Delete Tasks" },
              { key: "allowTaskSchedule", label: "Schedule Tasks" },
              { key: "allowTaskScheduleStop", label: "Stop Task Schedules" },
            ],
          },
          {
            title: "Missions",
            flags: [
              { key: "allowMission", label: "View Missions" },
              { key: "allowMissionCreate", label: "Create Missions" },
              { key: "allowMissionEdit", label: "Edit Missions" },
              { key: "allowMissionDelete", label: "Delete Missions" },
              { key: "allowMissionSchedule", label: "Schedule Missions" },
              { key: "allowMissionScheduleStop", label: "Stop Mission Schedules" },
            ],
          },
          {
            title: "Step Series",
            flags: [
              { key: "allowStepSeries", label: "View Step Series" },
              { key: "allowStepSeriesCreate", label: "Create Step Series" },
              { key: "allowStepSeriesEdit", label: "Edit Step Series" },
              { key: "allowStepSeriesDelete", label: "Delete Step Series" },
              { key: "allowStepSeriesSchedule", label: "Schedule Step Series" },
              { key: "allowStepSeriesScheduleStop", label: "Stop Step Series Schedules" },
            ],
          },
        ],
      },
      {
        title: "Competitions",
        subcategories: [
          {
            title: "Tournaments",
            flags: [
              { key: "allowTournament", label: "View Tournaments" },
              { key: "allowTournamentCreate", label: "Create Tournaments" },
              { key: "allowTournamentEdit", label: "Edit Tournaments" },
              { key: "allowTournamentDelete", label: "Delete Tournaments" },
              { key: "allowTournamentResult", label: "View Tournament Results" },
              { key: "allowTournamentSchedule", label: "Schedule Tournaments" },
              { key: "allowTournamentScheduleStop", label: "Stop Tournament Schedules" },
            ],
          },
          {
            title: "Instant-Battles",
            flags: [
              { key: "allowInstantBattle", label: "View Instant Battles" },
              { key: "allowInstantBattleCreate", label: "Create Instant Battles" },
              { key: "allowInstantBattleEdit", label: "Edit Instant Battles" },
              { key: "allowInstantBattleDelete", label: "Delete Instant Battles" },
              { key: "allowInstantBattleResult", label: "View Instant Battle Results" },
              { key: "allowInstantBattleSchedule", label: "Schedule Instant Battles" },
              { key: "allowInstantBattleScheduleStop", label: "Stop Instant Battle Schedules" },
            ],
          },
        ],
      },
      {
        title: "Leaderboards",
        flags: [
          { key: "allowLeaderboard", label: "View Leaderboards" },
          { key: "allowLeaderboardCreate", label: "Create Leaderboards" },
          { key: "allowLeaderboardEdit", label: "Edit Leaderboards" },
          { key: "allowLeaderboardDelete", label: "Delete Leaderboards" },
          { key: "allowLeaderboardResult", label: "View Leaderboard Results" },
          { key: "allowLeaderboardSchedule", label: "Schedule Leaderboards" },
          { key: "allowLeaderboardScheduleStop", label: "Stop Leaderboard Schedules" },
        ],
      },
    ],
  },
  {
    title: "Terminal",
    flags: [
      { key: "allowTerminal", label: "Access Terminal" },
    ],
  },
  {
    title: "Environment App Info",
    flags: [
      { key: "allowEnvironmentAppInfo", label: "View Environment App Info" },
      { key: "allowEnvironmentAppInfoEdit", label: "Edit Environment App Info" },
    ],
  },
];


// ─────────────────────────────────────────────────────────────
// HELPER FUNCTIONS

/** Builds an initial "false" state for all flags (org + global + env) */
function getInitialFlagState() {
  const state = {};
  // Org-level
  orgLevelFlags.forEach((f) => {
    state[f.key] = IMMUTABLE_FLAGS.has(f.key)?true:(f?.key==""&&JSON.parse(localStorage.getItem('user'))?.email?.includes("@dirtcube.xyz"))?true:false;
  });
  // Global app-level
  globalAppFlags.forEach((f) => {
    state[f.key] = IMMUTABLE_FLAGS.has(f.key) ? true :false;
  });
  // Env-level
  ENVIRONMENTS.forEach((env) => {
    environmentCategories.forEach((cat) => {
      // direct cat.flags
      if (cat.flags) {
        cat.flags.forEach((flag) => {
          state[`${env}.${flag.key}`] = IMMUTABLE_FLAGS.has(flag.key) ? true :false;
        });
      }
      // children
      if (cat.children) {
        cat.children.forEach((child) => {
          if (child.flags) {
            child.flags.forEach((flag) => {
              state[`${env}.${flag.key}`] = IMMUTABLE_FLAGS.has(flag.key) ? true :false;
            });
          }
          if (child.subcategories) {
            child.subcategories.forEach((sub) => {
              sub.flags.forEach((flag) => {
                state[`${env}.${flag.key}`] = IMMUTABLE_FLAGS.has(flag.key) ? true :false;
              });
            });
          }
        });
      }
    });
  });
  return state;
}

/** Gathers all flags from a single environment category (including children/subcategories) */
function gatherModuleKeys(module, env) {
  let results = [];
  if (module.flags) {
    module.flags.forEach((flag) => {
      const compositeKey = env ? `${env}.${flag.key}` : flag.key;
      results.push(compositeKey);
    });
  }
  if (module.children) {
    module.children.forEach((child) => {
      results = results.concat(gatherModuleKeys(child, env));
    });
  }
  if (module.subcategories) {
    module.subcategories.forEach((sub) => {
      results = results.concat(gatherModuleKeys(sub, env));
    });
  }
  return results;
}

/** Check if all given keys in flagState are true */
function areAllCheckedApp(flagState, keys) {
  return keys.every((k) => !!flagState[k]);
}

// ─────────────────────────────────────────────────────────────
// Collapsible UI Components

function CollapsibleSection({ id, title, isOpen, toggleOpen, children, styleClass, icon }) {
  return (
    <div className={cn(styles.collapsibleSection, styleClass)}>
      <button className={styles.collapsibleHeader} onClick={() => toggleOpen(id)}>
        <div className={styles.collapsibleTitle}>
          {icon && (
            <img
              src={icon}
              alt="icon"
              style={{ width: 20, height: 20, marginRight: '6px' }}
            />
          )}
          <span>{title}</span>
        </div>
        <div className={cn(styles.chevron, { [styles.rotate]: isOpen })}>
          <Icon name="arrow-down" size="20" />
        </div>
      </button>
      {isOpen && <div className={styles.collapsibleContent}>{children}</div>}
    </div>
  );
}

function PermissionCheckbox({ label, checked, onChange, disabled}) {
  return (
    <label className={styles.permissionCheckbox}>
      <input type="checkbox" checked={checked} disabled={disabled} onChange={(e) => onChange(e.target.checked)} />
      <span>{label}</span>
    </label>
  );
}

function SelectAllToggle({ allChecked, onToggle, label = 'Select All' }) {
  return (
    <div className={styles.selectAllToggle}>
      <span className={styles.toggleLabel}>{label}</span>
      <label className={styles.toggleSwitch}>
        <input
          type="checkbox"
          checked={allChecked}
          onChange={(e) => onToggle(e.target.checked)}
        />
        <span className={styles.slider} />
      </label>
    </div>
  );
}

// ─────────────────────────────────────────────────────────────
// LOGIC FOR SINGLE-OPEN + RESET DESCENDANTS

function parseSectionId(sectionId) {
  // If "global-app-123", treat as "env-Global-app-123"
  if (sectionId.startsWith('global-app-')) {
    const appId = sectionId.substring('global-app-'.length);
    return ['env', 'Global', 'app', appId];
  }
  return sectionId.split('-');
}

function getSectionLevels(sectionId) {
  const segments = parseSectionId(sectionId);
  const result = {
    environment: null,
    category: null,
    child: null,
    sub: null,
    appId: null,
  };
  let i = 0;
  while (i < segments.length) {
    const seg = segments[i];
    switch (seg) {
      case 'env':
        result.environment = segments[i + 1];
        i += 2;
        break;
      case 'cat':
        result.category = segments[i + 1];
        i += 2;
        break;
      case 'child':
        result.child = segments[i + 1];
        i += 2;
        break;
      case 'sub':
        result.sub = segments[i + 1];
        i += 2;
        break;
      case 'app':
        result.appId = segments[i + 1];
        i += 2;
        break;
      default:
        i++;
    }
  }
  return result;
}

function getSectionLevel(sectionId) {
  const { environment, category, child, sub } = getSectionLevels(sectionId);
  if (environment && !category && !child && !sub) {
    return 'environment';
  }
  if (environment && category && !child && !sub) {
    return 'category';
  }
  if (environment && category && child && !sub) {
    return 'child';
  }
  if (environment && category && child && sub) {
    return 'sub';
  }
  return 'unknown';
}

/** Tells us if openPath is "within" parentPath (same or deeper). */
function isDescendant(openPath, parentPath) {
  if (openPath.appId !== parentPath.appId) return false;

  // If parent's environment is set, openPath must match it
  if (parentPath.environment) {
    if (openPath.environment !== parentPath.environment) {
      return false;
    }
  }
  // If parent's category is set, openPath.category must match
  if (parentPath.category) {
    if (openPath.category !== parentPath.category) return false;
  }
  // If parent's child is set, openPath.child must match
  if (parentPath.child) {
    if (openPath.child !== parentPath.child) return false;
  }
  // If parent's sub is set, openPath.sub must match
  if (parentPath.sub) {
    if (openPath.sub !== parentPath.sub) return false;
  }

  return true;
}
function toggleSectionFn(appIndex, sectionId, setAppsList) {
  setAppsList((prev) => {
    const newArr = [...prev];
    const newOpen = new Set(newArr[appIndex].openSections);

    // parse the newly toggled section
    const newPath = getSectionLevels(sectionId);
    const newLevel = getSectionLevel(sectionId);
    const isAlreadyOpen = newOpen.has(sectionId);

    // Helper: close "id" and any of its descendants
    function closeSubtree(parentId, openSet) {
      // get parent path
      const parentPath = getSectionLevels(parentId);
      // remove the parent itself
      openSet.delete(parentId);

      // remove all descendants
      for (const candidateId of Array.from(openSet)) {
        const candidatePath = getSectionLevels(candidateId);
        // if candidate is descendant of parent => remove it
        if (isDescendant(candidatePath, parentPath)) {
          openSet.delete(candidateId);
        }
      }
    }

    // 1) Single‐open logic at the same level:
    //    If we find a sibling, close *that sibling* + *all its descendants*.
    for (const openId of Array.from(newOpen)) {
      if (openId === sectionId) continue; // skip self
      const openPath = getSectionLevels(openId);
      const openLevel = getSectionLevel(openId);

      if (openLevel !== newLevel) {
        continue; // different level => not siblings
      }

      // Check if they’re siblings at environment, category, child, or sub
      // We do the same environment checks you had...
      if (newLevel === 'environment') {
        // all environment-level siblings must be closed
        if (openLevel === 'environment') {
          closeSubtree(openId, newOpen);
        }
      } else if (newLevel === 'category') {
        // same environment => siblings
        if (openPath.environment === newPath.environment) {
          closeSubtree(openId, newOpen);
        }
      } else if (newLevel === 'child') {
        if (
          openPath.environment === newPath.environment &&
          openPath.category === newPath.category
        ) {
          closeSubtree(openId, newOpen);
        }
      } else if (newLevel === 'sub') {
        if (
          openPath.environment === newPath.environment &&
          openPath.category === newPath.category &&
          openPath.child === newPath.child
        ) {
          closeSubtree(openId, newOpen);
        }
      }
    }

    // 2) Now handle if we are closing the newly toggled section
    if (isAlreadyOpen) {
      // we are toggling it closed => close subtree of *this* item
      closeSubtree(sectionId, newOpen);
    } else {
      // otherwise open it
      newOpen.add(sectionId);
    }

    newArr[appIndex].openSections = newOpen;
    return newArr;
  });
}

// ─────────────────────────────────────────────────────────────
// MAIN COMPONENT

const AddMemberModal = ({
  className,
  memberAction = 'Add',
  memberEmail,
  id,
  usedFor,
  onClose,
  apps,
  permissions,
}) => {
  const dispatch = useDispatch();
  const { appid } = useParams();
  const db = getFirestore(app);

  // Org-level
  const [orgExpanded, setOrgExpanded] = useState(false);
  const [orgFlagState, setOrgFlagState] = useState(() => {
    const initial = {};
    orgLevelFlags.forEach((flag) => {
      initial[flag.key] = IMMUTABLE_FLAGS.has(flag.key) ? true :(flag?.key==""&&JSON.parse(localStorage.getItem('user'))?.email?.includes("@dirtcube.xyz"))?true:false;
    });
    return initial;
  });

  // If adding new
  const [email, setEmail] = useState('');
  const [permissionOption] = useState([
    { id: 1, name: 'View Only' },
    { id: 2, name: 'Can Edit' },
  ]);
  const [permission, setPermission] = useState('View Only');
  const [userPermission, setUserPermission] = useState(1);

  // "Select All" for apps
  const [checkAllCheckBox, setCheckAllCheckBox] = useState(false);

  // List of apps
  const [appsList, setAppsList] = useState([]);

  // On load
  useEffect(() => {
    if (apps && apps.length > 0) {
      if (memberAction === 'Add') {
        // new invites
        const newAppsList = apps.map((app) => ({
          ...app,
          checked: false,
          expanded: false,
          openSections: new Set(),
          flagState: getInitialFlagState(),
          permission: { name: 'View Only', id: 1 },
        }));
        setAppsList(newAppsList);
      } else {
        // editing existing
        getOrgFlagsAccessConfig()
        configureAppAccess();
      }
    }
  }, [apps]);

  // Setup existing permissions
  async function configureAppAccess() {
    if (permissions && permissions.length > 0) {
      const appAccess = await Promise.all(apps.map(async(app) => {
        const matchObj = permissions.find((perm) => perm.projectId === app.id);
        if (matchObj) {
          return {
            ...app,
            permission: {
              name:
                matchObj.permissionId === 1
                  ? 'View Only'
                  : matchObj.permissionId === 2
                  ? 'Can Edit'
                  : '',
              id: matchObj.permissionId,
            },
            checked: true,
            expanded: false,
            openSections: new Set(),
            flagState: (memberAction === 'edit'?
              await getAppFlagsAccessConfig(app.id):
              getInitialFlagState()),
          };
        } else {
          return {
            ...app,
            permission: { name: 'View Only', id: 1 },
            checked: false,
            expanded: false,
            openSections: new Set(),
            flagState: getInitialFlagState(),
          };
        }
      }));
      setAppsList(appAccess);

      // If everything is checked, set checkAll
      const anyUnchecked = appAccess.some((a) => !a.checked);
      setCheckAllCheckBox(!anyUnchecked);
    } else {
      // no existing
      const appAccess = apps.map((app) => ({
        ...app,
        permission: { name: 'View Only', id: 1 },
        checked: false,
        expanded: false,
        openSections: new Set(),
        flagState: getInitialFlagState(),
      }));
      setAppsList(appAccess);
      setCheckAllCheckBox(false);
    }
  }

  // ─────────────────────────────────────────────────────────────
  // HANDLERS

  const handleEmailChange = (e) => setEmail(e.target.value);

  // Check/uncheck entire app
  const handleToggleApp = (e, index) => {
    const updated = [...appsList];
    const wasChecked = updated[index].checked;
    updated[index].checked = e.target.checked;
    if (!wasChecked && e.target.checked) {
      updated[index].expanded = false;
      updated[index].openSections = new Set();
      updated[index].flagState = getInitialFlagState();
    }
    setAppsList(updated);

    const anyUnchecked = updated.some((a) => !a.checked);
    setCheckAllCheckBox(!anyUnchecked);
  };

  // "Check all" for apps
  const handleCheckAllApps = (checked) => {
    setCheckAllCheckBox(checked);
    const updated = appsList.map((app) => ({ ...app, checked }));
    setAppsList(updated);
  };

  // Single open logic for the entire "App" card
  const toggleAppExpand = (index) => {
    setAppsList((prev) =>
      prev.map((app, i) => {
        if (i === index) {
          return { ...app, expanded: !app.expanded };
        }
        return { ...app, expanded: false };
      })
    );
  };

  // Single open logic for environment/category/child/sub + reset desc
  const toggleSection = (appIndex, sectionId) => {
    toggleSectionFn(appIndex, sectionId, setAppsList);
  };

  // Org-level collapse
  const toggleOrgSection = () => {
    setOrgExpanded(!orgExpanded);
  };

  function handleOrgFlagChange(flagKey, value) {
    setOrgFlagState((prev) => ({
      ...prev,
      [flagKey]: value,
    }));
  }

  function handleSelectAllOrg(newValue) {
    setOrgFlagState((prev) => {
      const updated = { ...prev };
      orgLevelFlags.forEach((flag) => {
        if (IMMUTABLE_FLAGS.has(flag.key)) {
          updated[flag.key] = true; // Ensure immutable flags remain true
        } else {
        updated[flag.key] = (flag?.key==""&&JSON.parse(localStorage.getItem('user'))?.email?.includes("@dirtcube.xyz"))?true:newValue;
        }
      });
      return updated;
    });
  }

  // For environment-level flags
  const handleAppFlagChange = (appIndex, flagKey, checked) => {
    setAppsList((prev) => {
      const newList = [...prev];
      const newFlagState = { ...newList[appIndex].flagState };
      newFlagState[flagKey] = checked;
      newList[appIndex].flagState = newFlagState;
      return newList;
    });
  };

  useEffect(() => {
    setAppsList((prevAppsList) => {
      const newAppsList = prevAppsList.map((app) => {
        const hasAnyTrueFlag = Object.values(app.flagState).some((value) => value === true);
        return { ...app, checked: hasAnyTrueFlag };
      });
      return JSON.stringify(newAppsList) !== JSON.stringify(prevAppsList) ? newAppsList : prevAppsList;
    });
  }, [appsList]);

  // "Select All" for subset of keys
  function handleSelectAll(keys, newValue, appIndex) {
    if (appIndex === null) return; // org-level logic if needed
  
    const ENVIRONMENTS = ["QA", "Development", "Production"]; // Allowed environments
  
    setAppsList((prev) => {
      const newArr = [...prev];
      const newFlagState = { ...newArr[appIndex].flagState };
  
      keys.forEach((k) => {
        let pureKey = k; // Default: use the full key
        let detectedEnv = null;
  
        // Check if key starts with any of the known environments
        ENVIRONMENTS.forEach((env) => {
          if (k.startsWith(`${env}.`)) {
            detectedEnv = env;
            pureKey = k.replace(`${env}.`, ""); // Extract the actual flag key
          }
        });
  
        if (IMMUTABLE_FLAGS.has(pureKey)) {
          newFlagState[k] = true; // Keep immutable flags always true
        } else {
          newFlagState[k] = newValue;
        }
      });
  
      newArr[appIndex].flagState = newFlagState;
      return newArr;
    });
  }

  // Gather all flags for "Grant Full Access"
  function getAllAppFlagKeys() {
    let results = globalAppFlags.map((f) => f.key);
    ENVIRONMENTS.forEach((env) => {
      environmentCategories.forEach((cat) => {
        results = results.concat(gatherModuleKeys(cat, env));
      });
    });
    return results;
  }


const keys=[
  "isAdminEmail",
  "allowAppsPage",
  "allowAppsCreate",
  "allowAppsAddMembers",
  "allowSettings",
  "allowOrganizationSettings",
  "allowOrganizationSettingsEdit",
  "allowUserSettingsProfile",
  "allowUserSettingsProfileEdit",
  "allowMembersAccess",
  "allowAcceptedMembers",
  "allowInvitedMembers",
  "allowAddInviteMembers",
  "allowEditMemberAccess",
  "allowUsage",
  "allowSandboxButton",
  "allowUpgradePlansAccess",
  "allowPlanProceedAccess",
  "allowNotifications",
  // "allowInfoHelpButton",
  "allowGlobalAppsSettingsButton",
  "allowGlobalAppsInfo",
  "allowGlobalAppInfoEdit",
  "allowRemoteAssetsUpload",
  "allowRemoteAssetsDownloadAll",
  "allowRemoteAssetsDownload",
  "allowRemoteAssetsDelete",
  "allowAppMedia",
  "allowAppMediaDownload",
  "allowAppMediaDelete",
  "allowMediaFolderDownloadAll",
  "allowMediaFolderDeleteAll",
  "allowMediaFolderUpload",
  "allowDocument",
  "allowDocumentCreate",
  "allowDocumentEdit",
  "allowAppApi",
  "allowActivity",
  "allowAppsOverview",
  "allowEnvironmentAppInfo",
  "allowEnvironmentAppInfoEdit",
  "allowTerminal",
  "allowGames",
  "allowGamesCreate",
  "allowGamesEdit",
  "allowGamesArchive",
  "allowMatches",
  "allowMatchesCreate",
  "allowMatchesEdit",
  "allowMatchesArchive",
  "allowVirtualCurrency",
  "allowVirtualCurrencyCreate",
  "allowVirtualCurrencyEdit",
  "allowVirtualCurrencyArchive",
  "allowItem",
  "allowItemCreate",
  "allowItemEdit",
  "allowItemArchive",
  "allowBundle",
  "allowBundleCreate",
  "allowBundleEdit",
  "allowBundleArchive",
  "allowStore",
  "allowStoreCreate",
  "allowStoreEdit",
  "allowStoreArchive",
  "allowProgressionMarker",
  "allowProgressionMarkerCreate",
  "allowProgressionMarkerEdit",
  "allowProgressionMarkerArchive",
  "allowProgressionSystem",
  "allowProgressionSystemCreate",
  "allowProgressionSystemEdit",
  "allowProgressionSystemArchive",
  "allowDefaultEvent",
  "allowDefaultEventEdit",
  "allowCustomEvent",
  "allowCustomEventCreate",
  "allowCustomEventEdit",
  "allowCustomEventArchive",
  "allowCustomEventDelete",
  "allowRmgCurrency",
  "allowRmgCurrencyCreate",
  "allowRmgCurrencyEdit",
  "allowRmgCurrencyArchive",
  "allowSpecterBank",
  "allowSpecterBankTransaction",
  "allowPayout",
  "allowPayoutCreate",
  "allowPlayers",
  "allowPlayersCreate",
  "allowPlayersEdit",
  "allowTask",
  "allowTaskCreate",
  "allowTaskEdit",
  "allowTaskDelete",
  "allowTaskSchedule",
  "allowTaskScheduleStop",
  "allowMission",
  "allowMissionCreate",
  "allowMissionEdit",
  "allowMissionDelete",
  "allowMissionSchedule",
  "allowMissionScheduleStop",
  "allowStepSeries",
  "allowStepSeriesCreate",
  "allowStepSeriesEdit",
  "allowStepSeriesDelete",
  "allowStepSeriesSchedule",
  "allowStepSeriesScheduleStop",
  "allowTournament",
  "allowTournamentCreate",
  "allowTournamentEdit",
  "allowTournamentDelete",
  "allowTournamentResult",
  "allowTournamentSchedule",
  "allowTournamentScheduleStop",
  "allowInstantBattle",
  "allowInstantBattleCreate",
  "allowInstantBattleEdit",
  "allowInstantBattleDelete",
  "allowInstantBattleResult",
  "allowInstantBattleSchedule",
  "allowInstantBattleScheduleStop",
  "allowLeaderboard",
  "allowLeaderboardCreate",
  "allowLeaderboardEdit",
  "allowLeaderboardDelete",
  "allowLeaderboardResult",
  "allowLeaderboardSchedule",
  "allowLeaderboardScheduleStop",
  "economyFullAccess",
  "progressionFullAccess",
  "eventsFullAccess",
  "rmgFullAccess",
  "achievementsFullAccess",
  "competitionsFullAccess"
]

function buildFinalPermissions(orgFlagState, appsList) {
  // 1) Flatten orgFlagState into an array
  const orgFlags = Object.entries(orgFlagState).map(([key, value]) => ({
    key,
    selected: value,
  }));

  // 2) For each app, flatten app.flagState into an array
  const apps = appsList.map((app) => {
    const flagsArray = Object.entries(app.flagState).map(([key, value]) => ({
      key,
      selected: value,
    }));
    return {
      appId: app.id,
      flags: flagsArray,
    };
  });

  // 3) Return final object
  return {
    orgFlags,
    apps,
  };
}
async function buildAccessConfig(orgFlagState, appsList, organisationId, memberId, memberEmail) {
  const accessConfig = [];

  // Add orgFlags for the global environment
  const orgFlags = Object.entries(orgFlagState).map(([key, selected]) => ({
    key,
    selected,
  }));

  accessConfig.push({
    organisationId,
    // memberId,
    memberEmail,
    env: "global",
    orgFlags,
    projectFlags:[],archive:false,projectId:null
  });

  memberAction !== 'add' && await UpdateAccessConfigCall({organisationId,memberEmail,env: "global",orgFlags,projectFlags:[],archive:false,projectId:null})

  // Process apps and their flags
  appsList.forEach( async (app) => {
    const { id: projectId, flagState } = app;

    const flagsByEnv = Object.entries(flagState).reduce(
      (acc, [key, selected]) => {
        const [env, ...rest] = key.split(".");
        const flagKey = rest.length ? rest.join(".") : key;

        if (["QA", "Development", "Production"].includes(env)) {
          if(isKeyInEnvironmentCategories(flagKey)){

            acc[env] = acc[env] || [];
            acc[env].push({ key: flagKey, selected });
          
          }
        } else {
          if(
            globalAppFlags?.some((e)=>(e?.key==key))
          ){
            acc.global = acc.global || [];
            acc.global.push({ key, selected });
          }
        }

        return acc;
      },
      {}
    );

    // Add global project flags
    if (flagsByEnv.global && flagsByEnv.global.length > 0) {
      accessConfig.push({
        organisationId,
        // memberId,
        memberEmail,
        projectId,
        env: "global",
        projectFlags: flagsByEnv.global,
        orgFlags:[],archive:false
      });
    }

    memberAction !== 'add' && await UpdateAccessConfigCall({organisationId,memberEmail,projectId,env: "global",projectFlags: flagsByEnv.global,orgFlags:[],archive:false})

    // Add flags for QA, Development, and Production
    for (const env of ["QA", "Development", "Production"]){
      if (flagsByEnv[env] && flagsByEnv[env].length > 0) {
        accessConfig.push({
          organisationId,
          // memberId,
          memberEmail,
          projectId,
          env:env?.toLowerCase(),
          projectFlags: flagsByEnv[env],
          orgFlags:[],archive:false
        });
        memberAction !== 'add' && await UpdateAccessConfigCall({organisationId,memberEmail,projectId,env:env?.toLowerCase(),projectFlags: flagsByEnv[env],orgFlags:[],archive:false})
      }
    };
  });

  // const token = localStorage.getItem('token')
  // const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ?  JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ?  JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey :  JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
  // let getAllAppsByOrg = {
  //     organisationId:  JSON.parse(localStorage.getItem('orgId'))
  // }
  // memberAction !== 'add' && await axios.post(BASE_URL + UPDATE_ACCESS_CONFIG, { "accessConfig": accessConfig }, headers);
  return accessConfig ;
}

const UpdateAccessConfigCall= async (accessConfig) =>{
  const token = localStorage.getItem('token')
  const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ?  JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ?  JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey :  JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
  // let getAllAppsByOrg = {
  //     organisationId:  JSON.parse(localStorage.getItem('orgId'))
  // }
  memberAction !== 'add' && await axios.post(BASE_URL + UPDATE_ACCESS_CONFIG, { "accessConfig": [accessConfig] }, headers);
}
const { accessPermissions,setAccessPermissions } = useAccess();
const getNewOrgFlagsAccessConfig = async () => {
  const token = localStorage.getItem('token')
  const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ?  JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ?  JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey :  JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
  let getAppAccess = {
  "organisationId": localStorage.getItem('orgId'),
  // "memberIds": [JSON.parse(localStorage.getItem('user'))?.id],
  "memberEmails": [JSON.parse(localStorage.getItem('user'))?.email],
  "env": ["global"]
  // "projectIds": ["a5e0ea8a-f01b-4cf1-b08a-44dddf0a6b41"]
  }
  const orgFlagData = await axios.post(BASE_URL + GET_ACCESS_CONFIG, getAppAccess, headers);
  const orgFlagArr =orgFlagData.data.data.accessDetails.filter(e=>(e?.orgFlags?.length>0))?.[0]?.orgFlags
  
  const existingFlags = accessPermissions || {};
  // Update the existing Flags object with the new flags data
  orgFlagArr&&orgFlagArr.forEach(({ key, selected }) => {
      existingFlags[key] = selected; // Replace or add the flag
  });
  // Store the updated Flags object back in localStorage
  setAccessPermissions(existingFlags); 

}

  // Send Invite
  const sendInvite = async () => {
    const filtered = appsList
      .filter((obj) => obj.checked)
      .map(({ id, permission, flagState }) => ({
        id,
        permission,
        flagState,
      }));

    if (filtered.length === 0) {
    toast.error(
    <Toast type="Error" messages="At least one App should be selected" />,
    {
          position: 'top-center',
    icon: false,
    hideProgressBar: true,
    autoClose: 2000,
    }
      );
    return;
    }
    
    const access = await buildAccessConfig(orgFlagState, appsList?.filter(obj => obj.checked === true),localStorage.getItem('orgId'),id,memberEmail);

    const inviteData = {
      email,
      organisationId: localStorage.getItem('orgId'),
      // permissions: finalPermissions
      "accessConfig":access,
      permissions: filtered.map((f) => ({
        projectId: f.id,
        permissionId: f.permission.id,
      })),
    };

    try {
      await dispatch(sendMemberInviteAction(inviteData, null));
      const user = JSON.parse(localStorage.getItem('user'));
      const activityLog = {
        timestamp: new Date().toISOString(),
        action_type: 'CREATE',
        resource: 'MEMBER',
        description: 'Member invited successfully',
        quantity: 1,
        ...(localStorage.getItem('environment') && {
          environment: localStorage.getItem('environment'),
        }),
        operation_status: 'SUCCESS',
        activity_feed: false,
        priority: 'LOW',
        performed_by_id: user?.id,
        organization_id: localStorage.getItem('orgId'),
        performed_by: user?.name,
        request_json: JSON.stringify(inviteData),
      };
      await addDoc(collection(db, 'activityFeed'), activityLog);
      await increaseUnreadCountForAllUsers(appid);
      await getNewOrgFlagsAccessConfig();
      onClose();
    } catch (err) {
      console.error('Error sending invite:', err);
    }
  };
  function isKeyInEnvironmentCategories(key, categories=environmentCategories) {
    for (const category of categories) {
      // Check the flags at the current level
      if (category.flags && category.flags.some((flag) => flag.key === key)) {
        return true;
      }
  
      // Check children
      if (category.children && isKeyInEnvironmentCategories(key, category.children)) {
        return true;
      }
  
      // Check subcategories
      if (category.subcategories && isKeyInEnvironmentCategories(key, category.subcategories)) {
        return true;
      }
    }
  
    return false;
  }

  async function convertFlagsToFlagState(data) {
    const flagState = {};
  
    data.forEach((item) => {
      const { env, orgFlags, projectFlags } = item;
      // Add orgFlags (if global environment)
      if (env === "global" && orgFlags?.length>0) {
        orgFlags.forEach(({ key, selected }) => {
          flagState[key] = IMMUTABLE_FLAGS.has(key) ? true : selected;
        });
      }
  
      // Add projectFlags
      if (projectFlags) {
        projectFlags.forEach(({ key, selected }) => {
          if (env === "global") {
            if (globalAppFlags?.some((e)=>(e?.key==key))) {
              flagState[key] = IMMUTABLE_FLAGS.has(key) ? true : selected;
              
            }
          } else {
            if (isKeyInEnvironmentCategories(key)) {
              if(env?.toLowerCase()=="qa"){
                flagState[`${env.toUpperCase()}.${key}`] = IMMUTABLE_FLAGS.has(key) ? true : selected
              }
              else{
              flagState[`${env.charAt(0).toUpperCase() + env.slice(1)}.${key}`] = IMMUTABLE_FLAGS.has(key) ? true : selected
              }
            }
          }
        });
      }
    });
  
    return flagState;
  }

  const getAllApps = async() => {
    let getAllApps = {offset : 0, limit : 50, organisationId: localStorage.getItem('orgId')}
    await dispatch(getallAppAction(getAllApps))
  }
  
  async function getAppFlagsAccessConfig(pid) {
    try {
      const token = localStorage.getItem("token");
      const environment = localStorage.getItem("environment");
      const apiKeys = JSON.parse(localStorage.getItem("apiKeys"));
  
      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Api-Key":
            environment === "Production"
              ? apiKeys.productionAPIKey
              : environment === "Staging"
              ? apiKeys.stagingAPIKey
              : apiKeys.devAPIKey,
        },
      };
  
      const requestData = {
        organisationId: localStorage.getItem("orgId"),
        // memberIds: [id],
        memberEmails: [memberEmail],
        env: ["global", "development", "production", "qa"],
        projectIds: [pid],
      };
  
      const response = await axios.post(BASE_URL + GET_ACCESS_CONFIG, requestData, headers);
      const appFlagData = response.data.data.accessDetails;
  
      const filteredFlags = appFlagData.filter((e) => e?.projectFlags?.length > 0);
      return convertFlagsToFlagState(filteredFlags);
    } catch (error) {
      console.error("Error fetching app flags:", error);
      return {};
    }
  }
  
  const getOrgFlagsAccessConfig = async () => {
    const token = localStorage.getItem('token')
    const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ?  JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ?  JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey :  JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
    let getAppAccess = {
    "organisationId": localStorage.getItem('orgId'),
    // "memberIds": [id],
    "memberEmails": [memberEmail],
    "env": ["global"]
    // "projectIds": ["a5e0ea8a-f01b-4cf1-b08a-44dddf0a6b41"]
  }
    const orgFlagData = await axios.post(BASE_URL + GET_ACCESS_CONFIG, getAppAccess, headers);
    const orgFlagArr =orgFlagData.data.data.accessDetails.filter(e=>(e?.orgFlags?.length>0))?.[0]?.orgFlags
    const orgFlag=orgFlagState
    orgFlagArr && orgFlagArr.map((e)=>{
      if (orgLevelFlags.some((x)=>x.key==e.key)) {
        orgFlag[e.key]=(e?.key==""&&JSON.parse(localStorage.getItem('user'))?.email?.includes("@dirtcube.xyz"))?true:e.selected        
      }
    })
    setOrgFlagState(orgFlag)
  }

  // Edit existing user
  const onEditAccess = async () => {
    // Gather which apps are checked
    const filtered = appsList
      .filter((obj) => obj.checked)
      .map(({ id: projectId, permission }) => ({
        projectId,
        permissionId: permission.id,
        organisationId:localStorage.getItem('orgId')
      }));
    const Unchecked = appsList
      .filter((obj) => !obj.checked)
      .map(({ id: projectId, permission }) => ({
        projectId,
        permissionId: permission.id,
        organisationId:localStorage.getItem('orgId')
      }));

    if (filtered.length === 0) {
      toast.error(
        <Toast type="Error" messages="At least one App should be selected" />,
        {
          position: 'top-center',
          icon: false,
          hideProgressBar: true,
          autoClose: 2000,
        }
      );
      return;
    }
    
    const permissionData =[]
    filtered && filtered.map((e, i) => {permissionData.push({memberId: id, projectId : e.projectId, permissionId : e.permissionId, organisationId: e.organisationId,archive: false})})
    Unchecked && Unchecked.map((e, i) => {permissionData.push({memberId: id, projectId : e.projectId, permissionId : e.permissionId, organisationId:e.organisationId, archive: true})})

    try {
      if (usedFor === 'invited') {
        // For invited members
        let memberPermission = {
          permissions : permissionData && permissionData.map((e, i) => ({memberId: id, projectId : e.projectId, permissionId : e.permissionId, archive:e.archive, organisationId:e?.organisationId}))
        }
        await dispatch(editInvitedMemberPermissionAction(memberPermission, null));
        await buildAccessConfig(orgFlagState, appsList?.filter(obj => obj.checked === true),localStorage.getItem('orgId'),id,memberEmail);
      } else {
        // For active members
        let memberPermission = {
          updateMemberAccess : permissionData && permissionData.map((e, i) => ({memberId: id, projectId : e.projectId, permissionId : e.permissionId, archive:e.archive, organisationId:e.organisationId}))
        }
        await dispatch(editMemberPermissionAction(memberPermission, null));
        await buildAccessConfig(orgFlagState, appsList?.filter(obj => obj.checked === true),localStorage.getItem('orgId'),id,memberEmail);
      }

      const user = JSON.parse(localStorage.getItem('user'));
      const activityLog = {
        timestamp: new Date().toISOString(),
        action_type: 'UPDATE',
        resource: 'MEMBER',
        description: 'Member permissions updated successfully',
        quantity: 1,
        ...(localStorage.getItem('environment') && {
          environment: localStorage.getItem('environment'),
        }),
        operation_status: 'SUCCESS',
        activity_feed: false,
        priority: 'LOW',
        performed_by_id: user?.id,
        organization_id: localStorage.getItem('orgId'),
        performed_by: user?.name,
        request_json: JSON.stringify(permissionData),
      };
      await addDoc(collection(db, 'activityFeed'), activityLog);
      await increaseUnreadCountForAllUsers(appid);
      await getNewOrgFlagsAccessConfig();
      await CheckMemberOnLogin([{api_keys: JSON.parse(localStorage.getItem('apiKeys')), id: localStorage.getItem('orgId')}], id)
      await getAllApps();
      onClose();
    } catch (error) {
      console.error('Error updating member permissions:', error);
    }
  };

  // ─────────────────────────────────────────────────────────────
  // RENDER
  return (
    <div className={cn(styles.addMembersModal, className)}>
      <div className={styles.addMemberContainer}>
        {/* HEADER */}
        <div className={styles.memberHeader}>
          <div className={styles.headingContainer}>
            <div className={styles.headingBlock} />
            <div className={styles.headingContent}>
              {memberAction === 'add' ? 'Add Members' : 'Edit Member Access'}
            </div>
          </div>
        </div>

        {/* BODY */}
        <div className={cn(styles.memberBody, { [styles.active]: memberAction === 'edit' })}>
          {/* Invite row (Add only) */}
          {memberAction === 'add' && (
            <div className={styles.inviteBody}>
              <div className={styles.inviteForm}>
                <SendInviteInput
                  usedFor={usedFor}
                  onChange={handleEmailChange}
                  options={permissionOption}
                  value={permission}
                  setValue={setPermission}
                  permission={userPermission}
                  setPermission={setUserPermission}
                  className={styles.field}
                  placeholder="Type Email"
                  currency={<Icon name="profile-circle" size="20" />}
                />
                <Link
                  className={cn(styles.save)}
                  onClick={sendInvite}
                >
                  <span>Invite</span>
                </Link>
              </div>
            </div>
          )}

          {/* Permission Config */}
          <div className={styles.memberLists}>
            <div className={styles.sectionHeader}>
              <h3 className={styles.sectionTitle}>Permission Configuration</h3>
              <p className={styles.sectionDescription}>
                Manage detailed access across your organization and apps.
              </p>
            </div>
            <div className={styles.seprator} />

            {/* ORG-LEVEL */}
            <div className={styles.orgLevelBlock}>
              <CollapsibleSection
                id="orgPermissions"
                title="Organization Permissions"
                isOpen={orgExpanded}
                toggleOpen={() => setOrgExpanded(!orgExpanded)}
                icon={orgIcon}
              >
                <div className={styles.selectAllContainer}>
                  <SelectAllToggle
                    allChecked={orgLevelFlags.every((f) => orgFlagState[f.key])}
                    onToggle={handleSelectAllOrg}
                    label="Grant Complete Access for Org Permissions"
                  />
                </div>
                <div className={styles.checkboxRow}>
                  {orgLevelFlags.map((flag) => (
                      <PermissionCheckbox
                        key={flag.key}
                        label={flag.label}
                        checked={orgFlagState[flag.key]}
                        onChange={(val) => {
                          if (!IMMUTABLE_FLAGS.has(flag.key)) { // Prevent modification
                            handleOrgFlagChange(flag.key, val);
                          }
                        }}
                        disabled={IMMUTABLE_FLAGS.has(flag.key)}
                      />
                  ))}
                </div>
              </CollapsibleSection>
            </div>

            {/* APP LIST */}
            {/* <div className={styles.appAccessSection}>
              <div className={styles.checkAll}>
                <div className={styles.title}>App Access</div>
                {appsList.length > 0 && (
                  <Checkbox
                    value={checkAllCheckBox}
                    onChange={(e) => handleCheckAllApps(e.target.checked)}
                  />
                )}
              </div>
              <div className={styles.seprator} />

              {appsList.length === 0 ? (
                <p className={styles.warning}>No Apps Found.</p>
              ) : (
                <div className={styles.appList}>
                  {appsList.map((item, index) => (
                    <div key={item.id}>
                      <div className={styles.membersList}>
                            <div className={styles.check}>
                              <Checkbox
                                className={styles.checkbox}
                                value={item.checked}
                                onChange={(e) => handleToggleApp(e, index)}
                              />
                            </div>
                        <div className={styles.player}>
                          <div className={styles.playerInsigne}>
                            <SnapShot 
                              id={`app_access_${item.id}`}
                              url={item.iconUrl}
                              placeholder={AppPlaceholder}
                              width={36}
                              height={36}
                              type="Images"
                            />
                          </div>
                          <div className={styles.playerDetails}>
                            <div className={styles.userName}>{item.name}</div>
                            <div className={styles.playerId}>{item.id}</div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.seprator} />
                    </div>
                  ))}
                </div>
              )}
            </div> */}

            {/* DETAILED PERMISSIONS FOR EACH CHECKED APP */}
            <div className={styles.appDetailsSection}>
              <div className={styles.checkAll}>
                <div className={styles.title}>App Access</div>
              </div>
              <p className={styles.sectionDescription}>
                Configure detailed permissions for each app across environments.
                {/* {appsList.length > 0 && (
                  <Checkbox
                    value={checkAllCheckBox}
                    onChange={(e) => handleCheckAllApps(e.target.checked)}
                  />
                )} */}
              </p>
              <div className={styles.seprator} />

              {
              // !appsList.some((o) => o.checked) ? (
              //   <p className={styles.warning}>No Apps Selected</p>
              // ) : 
              (
                appsList
                  // .filter((o) => o.checked)
                  .map((item,index) => {
                    const realIndex = appsList.findIndex((app) => app.id === item.id);
                    return (
                      <div key={`details-${item.id}`} className={styles.appDetailsCard}>
                        {/* App Card Header */}
                        <button
                          onClick={() => toggleAppExpand(realIndex)}
                          className={styles.appDetailsHeader}
                        >
                          <div className={styles.player}>
                            {/* <div className={styles.check} style={{marginRight:"4px"}}>
                              <Checkbox
                                className={styles.checkbox}
                                value={item.checked}
                                onChange={(e) => handleToggleApp(e, index)}
                              />
                            </div> */}
                            <div className={styles.playerInsigne}>
                              {/* <img
                                src={item.iconUrl || AppPlaceholder}
                                className={styles.appIcon}
                                alt={item.name}
                              /> */}
                              <SnapShot 
                                id={`app_permission_${item.id}`}
                                url={item.iconUrl}
                                placeholder={AppPlaceholder}
                                width={36}
                                height={36}
                                type="Images"
                              />
                            </div>
                            <div className={styles.playerDetails}>
                              <div className={styles.userName}>{item.name}<span className={styles.playerId}>{item.checked&&` (Access Granted)`}</span></div>
                              <div className={styles.playerId}>{item.id}</div>
                            </div>
                          </div>
                          <div
                            className={cn(styles.chevron, {
                              [styles.rotate]: item.expanded,
                            })}
                          >
                            <Icon name="arrow-down" size="20" />
                          </div>
                        </button>

                        {/* If expanded, show the nested collapsibles */}
                        {item.expanded &&(
                          <div className={styles.appDetailsContent}>
                            {/* Full Access */}
                            <SelectAllToggle
                              label={`Grant Complete Access for ${item.name}`}
                              allChecked={areAllCheckedApp(item.flagState, getAllAppFlagKeys())}
                              onToggle={(val) =>
                                handleSelectAll(getAllAppFlagKeys(), val, realIndex)
                              }
                            />

                            {/* GLOBAL */}
                            <CollapsibleSection
                              id={`global-app-${item.id}`}
                              title="App Permissions"
                              isOpen={item.openSections.has(`global-app-${item.id}`)}
                              toggleOpen={(secId) => toggleSection(realIndex, secId)}
                              styleClass={styles.innerCollapsible}
                              icon={globalIcon}
                            >
                              <SelectAllToggle
                                label="Grant Complete Access for Global App Permissions"
                                allChecked={globalAppFlags.every((f) => item.flagState[f.key])}
                                onToggle={(val) =>
                                  handleSelectAll(
                                    globalAppFlags.map((f) => f.key),
                                    val,
                                    realIndex
                                  )
                                }
                              />
                              <div className={styles.checkboxRow}>
                                {globalAppFlags.map((flag) => (
                                  <PermissionCheckbox
                                    key={flag.key}
                                    label={flag.label}
                                    checked={item.flagState[flag.key]}
                                    onChange={(val) => {
                                      if (!IMMUTABLE_FLAGS.has(flag.key)) { // Prevent modification
                                        handleAppFlagChange(realIndex, flag.key, val);
                                      }
                                    }}
                                    disabled={IMMUTABLE_FLAGS.has(flag.key)}
                                  />
                                ))}
                              </div>
                            </CollapsibleSection>

                            {/* ENVIRONMENTS */}
                            {ENVIRONMENTS.map((env) => {
                              const envId = `env-${env}-app-${item.id}`;
                              return (
                                <CollapsibleSection
                                  key={envId}
                                  id={envId}
                                  title={`${env} Environment Permissions`}
                                  isOpen={item.openSections.has(envId)}
                                  toggleOpen={(secId) => toggleSection(realIndex, secId)}
                                  styleClass={styles.innerCollapsible}
                                  icon={environmentIcons[env]}
                                >
                                  {environmentCategories.map((cat) => {
                                    const safeCat = cat.title.replace(/\s+/g, '_');
                                    const catId = `env-${env}-cat-${safeCat}-app-${item.id}`;
                                    const catKeys = gatherModuleKeys(cat, env);

                                    return (
                                      <CollapsibleSection
                                        key={catId}
                                        id={catId}
                                        title={cat.title=="Environment App Info"?`${env} App Info`:cat.title}
                                        isOpen={item.openSections.has(catId)}
                                        toggleOpen={(secId) => toggleSection(realIndex, secId)}
                                        styleClass={styles.innermostCollapsible}
                                        icon={categoryIcons[cat.title]}
                                      >
                                        {cat.children&&<SelectAllToggle
                                          label={`Grant Complete Access for ${cat.title}`}
                                          allChecked={areAllCheckedApp(item.flagState, catKeys)}
                                          onToggle={(val) =>
                                            handleSelectAll(catKeys, val, realIndex)
                                          }
                                        />}

                                        {/* If cat has direct flags */}
                                        {cat.flags && cat.flags.length > 0 && (
                                          <div className={styles.permBlock}>
                                            <SelectAllToggle
                                              label={`Grant Complete Access for ${cat.title}`}
                                              allChecked={cat.flags.every(
                                                (f) => item.flagState[`${env}.${f.key}`]
                                              )}
                                              onToggle={(val) =>
                                                handleSelectAll(
                                                  cat.flags.map((f) => `${env}.${f.key}`),
                                                  val,
                                                  realIndex
                                                )
                                              }
                                            />
                                            <div className={styles.checkboxRow}>
                                              {cat.flags.map((f) => (
                                                <PermissionCheckbox
                                                  key={f.key}
                                                  label={f.label}
                                                  checked={item.flagState[`${env}.${f.key}`]}
                                                  onChange={(val) =>{
                                                    if (!IMMUTABLE_FLAGS.has(f.key)) { // Prevent modification
                                                      handleAppFlagChange(
                                                        realIndex,
                                                        `${env}.${f.key}`,
                                                        val
                                                      )
                                                      }
                                                  }
                                                  }
                                                  disabled={IMMUTABLE_FLAGS.has(f.key)} 
                                                />
                                              ))}
                                            </div>
                                          </div>
                                        )}

                                        {/* Children */}
                                        {cat.children &&
                                          cat.children.map((child) => {
                                            const safeChild = child.title.replace(/\s+/g, '_');
                                            const childId = `env-${env}-cat-${safeCat}-child-${safeChild}-app-${item.id}`;
                                            const childKeys = gatherModuleKeys(child, env);

                                            return (
                                              <CollapsibleSection
                                                key={childId}
                                                id={childId}
                                                title={child.title}
                                                isOpen={item.openSections.has(childId)}
                                                toggleOpen={(secId) =>
                                                  toggleSection(realIndex, secId)
                                                }
                                                styleClass={styles.innermostCollapsible}
                                              >
                                                {child.subcategories &&<SelectAllToggle
                                                  label={`Grant Complete Access for ${child.title}`}
                                                  allChecked={areAllCheckedApp(
                                                    item.flagState,
                                                    childKeys
                                                  )}
                                                  onToggle={(val) =>
                                                    handleSelectAll(childKeys, val, realIndex)
                                                  }
                                                />}

                                                {/* Child flags */}
                                                {child.flags && child.flags.length > 0 && (
                                                  <div className={styles.permBlock}>
                                                    <SelectAllToggle
                                                      label={`Grant Complete Access for ${child.title}`}
                                                      allChecked={child.flags.every(
                                                        (f) =>
                                                          item.flagState[`${env}.${f.key}`]
                                                      )}
                                                      onToggle={(val) =>
                                                        handleSelectAll(
                                                          child.flags.map(
                                                            (f) => `${env}.${f.key}`
                                                          ),
                                                          val,
                                                          realIndex
                                                        )
                                                      }
                                                    />
                                                    <div className={styles.checkboxRow}>
                                                      {child.flags.map((f) => (
                                                        <PermissionCheckbox
                                                          key={f.key}
                                                          label={f.label}
                                                          checked={
                                                            item.flagState[`${env}.${f.key}`]
                                                          }
                                                          onChange={(val) =>{
                                                            if (!IMMUTABLE_FLAGS.has(f.key)) { // Prevent modification
                                                              handleAppFlagChange(
                                                                realIndex,
                                                                `${env}.${f.key}`,
                                                                val
                                                              )
                                                            }
                                                          }
                                                          }
                                                          disabled={IMMUTABLE_FLAGS.has(f.key)} 
                                                        />
                                                      ))}
                                                    </div>
                                                  </div>
                                                )}

                                                {/* Subcategories */}
                                                {child.subcategories &&
                                                  child.subcategories.map((sub) => {
                                                    const safeSub = sub.title.replace(
                                                      /\s+/g,
                                                      '_'
                                                    );
                                                    const subId = `env-${env}-cat-${safeCat}-child-${safeChild}-sub-${safeSub}-app-${item.id}`;
                                                    const subKeys = gatherModuleKeys(sub, env);

                                                    return (
                                                      <CollapsibleSection
                                                        key={subId}
                                                        id={subId}
                                                        title={sub.title}
                                                        isOpen={item.openSections.has(subId)}
                                                        toggleOpen={(secId) =>
                                                          toggleSection(realIndex, secId)
                                                        }
                                                        styleClass={styles.innermostCollapsible}
                                                      >
                                                        <SelectAllToggle
                                                          label={`Grant Complete Access for ${sub.title}`}
                                                          allChecked={areAllCheckedApp(
                                                            item.flagState,
                                                            subKeys
                                                          )}
                                                          onToggle={(val) =>
                                                            handleSelectAll(
                                                              subKeys,
                                                              val,
                                                              realIndex
                                                            )
                                                          }
                                                        />
                                                        <div className={styles.checkboxRow}>
                                                          {sub.flags.map((f) => (
                                                            <PermissionCheckbox
                                                              key={f.key}
                                                              label={f.label}
                                                              checked={
                                                                item.flagState[
                                                                  `${env}.${f.key}`
                                                                ]
                                                              }
                                                              onChange={(val) =>{
                                                                if (!IMMUTABLE_FLAGS.has(f.key)) { // Prevent modification
                                                                  handleAppFlagChange(
                                                                    realIndex,
                                                                    `${env}.${f.key}`,
                                                                    val
                                                                  )
                                                                }
                                                              }
                                                              }
                                                              disabled={IMMUTABLE_FLAGS.has(f.key)} 
                                                            />
                                                          ))}
                                                        </div>
                                                      </CollapsibleSection>
                                                    );
                                                  })}
                                              </CollapsibleSection>
                                            );
                                          })}
                                      </CollapsibleSection>
                                    );
                                  })}
                                </CollapsibleSection>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    );
                  })
              )}
            </div>

            {/* FOOTER */}
            {memberAction === 'edit' && (
            <div className={styles.memberFooter}>
              <button
                className={cn(styles.button, 'button-save-small')}
                onClick={onEditAccess}
              >
                Edit
              </button>
              <button
                className={cn(styles.button, 'button-cancel-small')}
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMemberModal;
