import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./schedule.module.sass";
import {ReactComponent as Result} from '../../../../../../../media/images/icons/Result.svg'
import {ReactComponent as Pause} from '../../../../../../../media/images/icons/Pause.svg'
import {ReactComponent as Stop} from '../../../../../../../media/images/icons/Stop.svg'
import {ReactComponent as Calendar} from '../../../../../../../media/images/icons/Calendar.svg'
import {ReactComponent as OutlineCalendar} from '../../../../../../../media/images/icons/OutlineCalendar.svg'
import Modal from "../../../../../../Modals/ModalComponent";
import ScheduleModal from "../../../../../ScheduleModal";
import StopScheduleModal from "../../../../../StopScheduleModal";
import moment from "moment";
import 'moment-timezone';
import DateTooltip from "../../../../../../CustomTooltips/DateTooltip";
import { useAccess } from "../../../../../../Page";

const Control = ({className, userTimeZone, timeZone, name, isLast, usedFor, instanceStatus, instanceStart, instanceEnd, type, id, as, status}) => {

    const [visibleModal, setVisibleModal] = useState(false);
    const [visibleStopModal, setVisibleStopModal] = useState(false);
    const { accessPermissions } = useAccess();
    const {allowMissionSchedule,allowMissionScheduleStop,allowStepSeriesSchedule,allowStepSeriesScheduleStop} = accessPermissions || {};
  
    const actions = [
        {
            name: 'Stop',
            icon: <Stop />,
            action : () => setVisibleStopModal(true),
        },
        {
            name: 'Calendar',
            icon: <Calendar />,
            action: () => setVisibleModal(true),
        }
    ];

  return (
    <>
        <div className={cn(styles.control, className)}>
            {instanceStart === null && usedFor === 'status' && 
                <>
                    <div className={styles.leaderboardActions}>
                        <div className={styles.schdule}>
                            <div className={styles.icons} onClick={() => setVisibleModal(true)}>
                                <OutlineCalendar />
                            </div>
                        </div>
                    </div>
                </>
            }
            {instanceStart === null && usedFor === 'instance' && 
                <>
                    {instanceStart == null && instanceEnd == null && <>-</>}
                </>
            }
            {instanceStart !== null &&
                <>
                    {usedFor === 'instance' && 
                        <>
                            <div className={styles.status}>
                                <div className={instanceStatus == 'yet to start' ? styles.purple : instanceStatus == 'in progress' ? styles.green : (instanceStatus == 'completed' || instanceStatus === 'expired') ? styles.grey : instanceStatus == 'stopped' ? styles.red : ''}>{instanceStatus === 'yet to start' ? 'Yet-to-Start' : instanceStatus === 'in progress' ? 'In-progress' : (instanceStatus == 'completed' || instanceStatus === 'expired') ? 'Completed' : instanceStatus === 'stopped' ? 'Stopped'  : ''}</div>
                            </div>
                            <div className={styles.leaderboardActions}>
                                <DateTooltip userTimeZone={userTimeZone} isLast={isLast} timeZone={timeZone} startDate={instanceStart != null ? instanceStart : null} endDate={instanceEnd != null ? instanceEnd : null}>
                                    <span className={styles.date}>{instanceStart != null ? moment(instanceStart).tz(userTimeZone).format('MMM DD') : null}</span> <span className={styles.date}>{instanceEnd != null ? <> &#8208; {moment(instanceEnd).tz(userTimeZone).format('MMM DD')}</> : <span className={styles.icon}><span>&#45;</span> &#8734;</span>}</span>
                                </DateTooltip>
                            </div>
                        </>
                    }
                    {usedFor === 'status' && 
                        <>
                            <div className={styles.status}>
                                <div className={styles.green}>{status === 'inactive' ? 'Inactive' : status === 'active' ? 'Active' : status === 'stopped' ? 'Stopped'  : ''}</div>
                            </div>
                            <div className={styles.leaderboardActions}>
                                <div className={styles.iconContainer}>
                                    {status == 'inactive' && instanceStatus === 'yet to start' ?
                                        (actions && actions.map((x,i) => {
                                            if(
                                                (type == 'Mission'&& !allowMissionSchedule && x?.name=='Calendar')||
                                                (type == 'Mission'&& !allowMissionScheduleStop && x?.name== "Stop")||
                                                (type == 'StepSeries'&& !allowStepSeriesSchedule && x?.name== "Calendar")||
                                                (type == 'StepSeries'&& !allowStepSeriesScheduleStop && x?.name== "Stop")
                                            ){
                                                return ;
                                            }
                                            return(
                                                <div className={cn(styles.icons)} onClick={x.action}>
                                                    {x.icon}
                                                </div>
                                            )
                                        }))
                                    : status == 'active' ?
                                        (actions && actions.slice(0, -1).map((x,i) => {
                                            if(
                                                (type == 'Mission'&& !allowMissionSchedule && x?.name=='Calendar')||
                                                (type == 'Mission'&& !allowMissionScheduleStop && x?.name== "Stop")||
                                                (type == 'StepSeries'&& !allowStepSeriesSchedule && x?.name== "Stop")||
                                                (type == 'StepSeries'&& !allowStepSeriesScheduleStop && x?.name== "Stop")
                                            ){
                                                return ;
                                            }
                                            return(
                                                <div className={styles.icons} onClick={x.action}>
                                                    {x.icon}
                                                </div>
                                            )
                                        }))
                                    : ''}
                                </div>
                            </div> 
                        </>
                    }
                </>
            }
        </div>
        <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
            <ScheduleModal timeZone={timeZone} name={name} as={as} liveOpsStatus={status} type={type} id={id} onClose={() => setVisibleModal(false)}  />
        </Modal>
        <Modal visible={visibleStopModal} onClose={() => setVisibleStopModal(false)}>
            <StopScheduleModal name={name} as={as} type={type} id={id} onClose={() => setVisibleStopModal(false)}  />
        </Modal>
    </>
  );
};

export default Control;
