import { GET_APPS, GET_APP_OVERVIEW, ADD_APPS, EDIT_APPS, FILTER_APPS, GET_APP_LIST, GET_APP_DETAILS, GET_APP_LIST_SUCCESS, GET_APP_LIST_ERROR, FILTER_APPS_SUCCESS, FILTER_APPS_ERROR, GET_APP_OVERVIEW_SUCCESS, GET_APP_OVERVIEW_ERROR, GET_APP_DETAILS_SUCCESS, GET_APP_DETAILS_ERROR } from "../../actionTypes/actionTypes";
import AppServices from '../../../services/appServices/appServices'
import Toast from '../../../components/Toast'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CheckMemberOnLogin} from "../../../firebase.js"
import axios from "axios";
import { BASE_URL,ADD_ACCESS_CONFIG, GET_ACCESS_CONFIG} from '../../../resources/APIEndpoints';

export const getallAppAction = (body, navigation) => {
    return async (dispatch) => {
        await AppServices.getAllApps(body)
        .then(res=>{
            dispatch(getAllApps(res.data.data.projectDetails, res.data.data.totalCount))
        })
    }
}

export const getAppDetailsAction = (body) => {
    return async (dispatch) => {
        dispatch(getAppDetails(null, GET_APP_DETAILS))
        await AppServices.getAllApps(body)
        .then(res => {
            dispatch(getAppDetails(res.data.data.projectDetails, GET_APP_DETAILS_SUCCESS))
        }).catch(err => {
            dispatch(getAppDetails(err.response, GET_APP_DETAILS_ERROR))
        })
    }
}

export const getListOfAppsAction = (body, configureSkeleton) => {
    return async (dispatch) => {
        dispatch(getAppList(null, null, GET_APP_LIST))
        await AppServices.getAllApps(body)
        .then(res => {
            if(configureSkeleton){
                configureSkeleton()
            }
            dispatch(getAppList(res.data.data.projectDetails, res.data.data.totalCount, GET_APP_LIST_SUCCESS))
        }).catch(err => {
            dispatch(getAppList(err.response, null, GET_APP_LIST_ERROR))
        })
    }
}

export const filterAppsAction = (body, configureSkeleton) => {
    return async (dispatch) => {
        await AppServices.getAllApps(body)
        .then(res=>{
            if(configureSkeleton){
                configureSkeleton()
            }
            dispatch(filterApps(res.data.data.projectDetails, ((body?.search && body?.search !== '') ? res.data.data.searchCount : res.data.data.totalCount) , FILTER_APPS_SUCCESS))
        }).catch(err => {
            dispatch(filterApps(err.response, null, FILTER_APPS_ERROR))
        })
    }
}

export const getAppOverviewAction = (body) => {
    return async (dispatch) => {
        dispatch(getAppOverview(null, GET_APP_OVERVIEW))
        await AppServices.getAppOverview(body)
        .then(res=>{
            dispatch(getAppOverview(res.data.data, GET_APP_OVERVIEW_SUCCESS))
        }).catch(err => {
            dispatch(getAppOverview(err.response, GET_APP_OVERVIEW_ERROR))
        })
    }
}

export const createAppAction = (body, navigate, setApp, setShowLoader, setLoaderType) => {
    return async (dispatch) => {
        await AppServices.createApps(body)
        .then(async res=>{
            console.log('Before Try')
            try {
                const api_keys = {
                    productionAPIKey:JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey, 
                    stagingAPIKey: JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey,
                    devAPIKey: JSON.parse(localStorage.getItem('apiKeys')).devAPIKey
                }
                let orgId = JSON.parse(localStorage.getItem('orgId'))
                let userId = JSON.parse(localStorage.getItem('user'))?.id
                let isMemberLoggedIn = await CheckMemberOnLogin([{api_keys: api_keys, id: orgId}], userId)
                console.log('Before Try', isMemberLoggedIn)
            } catch (error) {
                console.log("error while setting app",error)
            }
            const token = localStorage.getItem('token')
            const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ?  JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ?  JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey :  JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
            const Environments = ["Development", "Production", "QA"]
            const accessConfig = [
                {
                    "organisationId": (localStorage.getItem('orgId')),
                    // "memberId": JSON.parse(localStorage.getItem('user'))?.id,
                    "memberEmail": JSON.parse(localStorage.getItem('user'))?.email,
                    "projectId": res.data.data.projectDetails.id,
                    "orgFlags":[],
                    "archive":false,
                    "env": "global",
                    "projectFlags": [
                        {
                            "key": "allowGlobalAppsSettingsButton",
                            "selected": true
                        },
                        {
                            "key": "allowGlobalAppsInfo",
                            "selected": true
                        },
                        {
                            "key": "allowGlobalAppInfoEdit",
                            "selected": true
                        },
                        {
                            "key": "allowRemoteAssetsUpload",
                            "selected": true
                        },
                        {
                            "key": "allowRemoteAssetsDownloadAll",
                            "selected": true
                        },
                        {
                            "key": "allowRemoteAssetsDownload",
                            "selected": true
                        },
                        {
                            "key": "allowRemoteAssetsDelete",
                            "selected": true
                        },
                        {
                            "key": "allowAppMedia",
                            "selected": true
                        },
                        {
                            "key": "allowAppMediaDownload",
                            "selected": true
                        },
                        {
                            "key": "allowAppMediaDelete",
                            "selected": true
                        },
                        {
                            "key": "allowMediaFolderDownloadAll",
                            "selected": true
                        },
                        {
                            "key": "allowMediaFolderDeleteAll",
                            "selected": true
                        },
                        {
                            "key": "allowMediaFolderUpload",
                            "selected": true
                        },
                        {
                            "key": "allowDocument",
                            "selected": true
                        },
                        {
                            "key": "allowDocumentCreate",
                            "selected": true
                        },
                        {
                            "key": "allowDocumentEdit",
                            "selected": true
                        },
                        {
                            "key": "allowAppApi",
                            "selected": true
                        },
                        {
                            "key": "allowActivity",
                            "selected": true
                        }
                    ]
                }
            ]
            Environments?.map((e)=>{
                accessConfig.push(
                    {
                        "organisationId": (localStorage.getItem('orgId')),
                        // "memberId": JSON.parse(localStorage.getItem('user'))?.id,
                        "memberEmail": JSON.parse(localStorage.getItem('user'))?.email,
                        "orgFlags":[],
                        "archive":false,
                        "projectId": res.data.data.projectDetails.id,
                        "env": e?.toLowerCase(),
                        "projectFlags": [
                            {
                                "key": "allowAppsOverview",
                                "selected": true
                            },
                            {
                                "key": "allowGames",
                                "selected": true
                            },
                            {
                                "key": "allowGamesCreate",
                                "selected": true
                            },
                            {
                                "key": "allowGamesEdit",
                                "selected": true
                            },
                            {
                                "key": "allowGamesArchive",
                                "selected": true
                            },
                            {
                                "key": "allowMatches",
                                "selected": true
                            },
                            {
                                "key": "allowMatchesCreate",
                                "selected": true
                            },
                            {
                                "key": "allowMatchesEdit",
                                "selected": true
                            },
                            {
                                "key": "allowMatchesArchive",
                                "selected": true
                            },
                            {
                                "key": "allowVirtualCurrency",
                                "selected": true
                            },
                            {
                                "key": "allowVirtualCurrencyCreate",
                                "selected": true
                            },
                            {
                                "key": "allowVirtualCurrencyEdit",
                                "selected": true
                            },
                            {
                                "key": "allowVirtualCurrencyArchive",
                                "selected": true
                            },
                            {
                                "key": "allowItem",
                                "selected": true
                            },
                            {
                                "key": "allowItemCreate",
                                "selected": true
                            },
                            {
                                "key": "allowItemEdit",
                                "selected": true
                            },
                            {
                                "key": "allowItemArchive",
                                "selected": true
                            },
                            {
                                "key": "allowBundle",
                                "selected": true
                            },
                            {
                                "key": "allowBundleCreate",
                                "selected": true
                            },
                            {
                                "key": "allowBundleEdit",
                                "selected": true
                            },
                            {
                                "key": "allowBundleArchive",
                                "selected": true
                            },
                            {
                                "key": "allowStore",
                                "selected": true
                            },
                            {
                                "key": "allowStoreCreate",
                                "selected": true
                            },
                            {
                                "key": "allowStoreEdit",
                                "selected": true
                            },
                            {
                                "key": "allowStoreArchive",
                                "selected": true
                            },
                            {
                                "key": "allowProgressionMarker",
                                "selected": true
                            },
                            {
                                "key": "allowProgressionMarkerCreate",
                                "selected": true
                            },
                            {
                                "key": "allowProgressionMarkerEdit",
                                "selected": true
                            },
                            {
                                "key": "allowProgressionMarkerArchive",
                                "selected": true
                            },
                            {
                                "key": "allowProgressionSystem",
                                "selected": true
                            },
                            {
                                "key": "allowProgressionSystemCreate",
                                "selected": true
                            },
                            {
                                "key": "allowProgressionSystemEdit",
                                "selected": true
                            },
                            {
                                "key": "allowProgressionSystemArchive",
                                "selected": true
                            },
                            {
                                "key": "allowDefaultEvent",
                                "selected": true
                            },
                            {
                                "key": "allowDefaultEventEdit",
                                "selected": true
                            },
                            {
                                "key": "allowCustomEvent",
                                "selected": true
                            },
                            {
                                "key": "allowCustomEventCreate",
                                "selected": true
                            },
                            {
                                "key": "allowCustomEventEdit",
                                "selected": true
                            },
                            {
                                "key": "allowCustomEventArchive",
                                "selected": true
                            },
                            {
                                "key": "allowRmgCurrency",
                                "selected": true
                            },
                            {
                                "key": "allowRmgCurrencyCreate",
                                "selected": true
                            },
                            {
                                "key": "allowRmgCurrencyEdit",
                                "selected": true
                            },
                            {
                                "key": "allowRmgCurrencyArchive",
                                "selected": true
                            },
                            {
                                "key": "allowSpecterBank",
                                "selected": true
                            },
                            {
                                "key": "allowSpecterBankTransaction",
                                "selected": true
                            },
                            {
                                "key": "allowPayout",
                                "selected": true
                            },
                            {
                                "key": "allowPayoutCreate",
                                "selected": true
                            },
                            {
                                "key": "allowPlayers",
                                "selected": true
                            },
                            {
                                "key": "allowPlayersCreate",
                                "selected": true
                            },
                            {
                                "key": "allowPlayersEdit",
                                "selected": true
                            },
                            {
                                "key": "allowTask",
                                "selected": true
                            },
                            {
                                "key": "allowTaskCreate",
                                "selected": true
                            },
                            {
                                "key": "allowTaskEdit",
                                "selected": true
                            },
                            {
                                "key": "allowTaskDelete",
                                "selected": true
                            },
                            {
                                "key": "allowTaskSchedule",
                                "selected": true
                            },
                            {
                                "key": "allowTaskScheduleStop",
                                "selected": true
                            },
                            {
                                "key": "allowMission",
                                "selected": true
                            },
                            {
                                "key": "allowMissionCreate",
                                "selected": true
                            },
                            {
                                "key": "allowMissionEdit",
                                "selected": true
                            },
                            {
                                "key": "allowMissionDelete",
                                "selected": true
                            },
                            {
                                "key": "allowMissionSchedule",
                                "selected": true
                            },
                            {
                                "key": "allowMissionScheduleStop",
                                "selected": true
                            },
                            {
                                "key": "allowStepSeries",
                                "selected": true
                            },
                            {
                                "key": "allowStepSeriesCreate",
                                "selected": true
                            },
                            {
                                "key": "allowStepSeriesEdit",
                                "selected": true
                            },
                            {
                                "key": "allowStepSeriesDelete",
                                "selected": true
                            },
                            {
                                "key": "allowStepSeriesSchedule",
                                "selected": true
                            },
                            {
                                "key": "allowStepSeriesScheduleStop",
                                "selected": true
                            },
                            {
                                "key": "allowTournament",
                                "selected": true
                            },
                            {
                                "key": "allowTournamentCreate",
                                "selected": true
                            },
                            {
                                "key": "allowTournamentEdit",
                                "selected": true
                            },
                            {
                                "key": "allowTournamentDelete",
                                "selected": true
                            },
                            {
                                "key": "allowTournamentResult",
                                "selected": true
                            },
                            {
                                "key": "allowTournamentSchedule",
                                "selected": true
                            },
                            {
                                "key": "allowTournamentScheduleStop",
                                "selected": true
                            },
                            {
                                "key": "allowInstantBattle",
                                "selected": true
                            },
                            {
                                "key": "allowInstantBattleCreate",
                                "selected": true
                            },
                            {
                                "key": "allowInstantBattleEdit",
                                "selected": true
                            },
                            {
                                "key": "allowInstantBattleDelete",
                                "selected": true
                            },
                            {
                                "key": "allowInstantBattleResult",
                                "selected": true
                            },
                            {
                                "key": "allowInstantBattleSchedule",
                                "selected": true
                            },
                            {
                                "key": "allowInstantBattleScheduleStop",
                                "selected": true
                            },
                            {
                                "key": "allowLeaderboard",
                                "selected": true
                            },
                            {
                                "key": "allowLeaderboardCreate",
                                "selected": true
                            },
                            {
                                "key": "allowLeaderboardEdit",
                                "selected": true
                            },
                            {
                                "key": "allowLeaderboardDelete",
                                "selected": true
                            },
                            {
                                "key": "allowLeaderboardResult",
                                "selected": true
                            },
                            {
                                "key": "allowLeaderboardSchedule",
                                "selected": true
                            },
                            {
                                "key": "allowLeaderboardScheduleStop",
                                "selected": true
                            },
                            {
                                "key": "allowTerminal",
                                "selected": true
                            },
                            {
                                "key": "allowEnvironmentAppInfo",
                                "selected": true
                            },
                            {
                                "key": "allowEnvironmentAppInfoEdit",
                                "selected": true
                            }
                        ]
                    }
                )
            })
            await axios.post(BASE_URL + ADD_ACCESS_CONFIG, { "accessConfig": accessConfig }, headers);
            setApp(body.name)
            localStorage.setItem('AppIcon', body.iconUrl)
            localStorage.setItem('App', body.name)
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            setLoaderType(3)
            setShowLoader(true)
            console.log('Before Set Time Out')
            setTimeout(() => {
                console.log('Navigat to overview')
                navigate(`/app-overview/${res.data.data.projectDetails.id}`)
                localStorage.setItem('environment', "Development")
                setTimeout(() => {
                    console.log('Loader Disable')
                    setShowLoader(false)
                }, 4000)
            }, 300)
            return res; 
        }).catch(error=>{
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                    toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
                })
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            throw error;
        })
    }
}

export const editAppAction = (body, appid, setDisable) => {
    return async (dispatch) => {
        if(setDisable){
            setDisable(true)
        }
        await AppServices.editApps(body)
        .then(res=>{
            localStorage.setItem('AppIcon', body.iconUrl)
            localStorage.setItem('App', body.name)
            toast.success(<Toast type='Success' messages='App Updated Sucessfully'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            setTimeout(()=>{
                if(setDisable){
                    setDisable(false)
                }
                window.location.reload()
            }, 2000)
            return res;
        }).catch(error => {
            if(setDisable){
                setDisable(false)
            }
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            throw error;
        })
    }
}

export const addAPIKeyAction = (body, appid) => {
    return async (dispatch) => {
        await AppServices.addAPIKeys(body)
        .then(res => {
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            dispatch(getAppDetailsAction({ids : [appid], organisationId: localStorage.getItem('orgId')}))
            return res;
        }).catch(error => {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
            })
            throw error;
        })
    }
}

export const getAllApps = (data, count) => {
    return {
        type : GET_APPS,
        payload : data,
        count : count
    }
}

export const getAppOverview = (data, type) => {
    return {
        type : type,
        payload : data
    }
}

export const createApps = (data) => {
    return {
        type : ADD_APPS,
        payload : data
    }
}

export const editApps = (data) => {
    return {
        type : EDIT_APPS,
        payload : data
    }
}

export const filterApps = (data, count, type) => {
    return {
        type : type,
        payload : data,
        count: count
    }
}

export const getAppList = (data, count, type) => {
    return {
        type : type,
        payload : data,
        count : count
    }
}

export const getAppDetails = (data, type) => {
    return {
        type : type,
        payload : data,
    }
}

export const addAPIKeys = (data, type) => {
    return {
        type : type,
        payload : data
    }
}