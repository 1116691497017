import React, { useContext, useEffect, useState } from "react";
import cn from "classnames";
import Toast from "../../../../../../components/Toast";
import axios from "axios";
import customAxios from "../../../../../../utils/interceptor";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import CurrencyDropdown from "../../../../../../components/Dropdown/CurrencyDropdown";
import PlatformDropdown from "../../../../../../components/Dropdown/PlatformDropdown"
import Multiselect from "../../../../../../components/MultiselectDropDown/PricingDropdown";
import CommonDropdown from '../../../Store/EditStores/StoreInformation/CommonDropdown'
import ProgressionLevelMultiSelect from '../../../../../../components/Dropdown/ProgressionLevelTypeableDropdown';
// import Multiselect from 'multiselect-react-dropdown';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import styles from "./bundleInformation.module.sass";
import Card from "../../../../../../components/Card";
import Icon from "../../../../../../components/Icon";
import TextInput from "../../../../../../components/TextInput";
import TextArea from "../../../../../../components/TextArea";
import DaysDropdown from "../../../../../../components/Dropdown/DaysDropdown"
import ItemDropdown from "../../../Store/EditStores/StoreInformation/ItemDropdown"
import Control from "../Control";
import PSDropdown from "../../../../../../components/Dropdown/PSDropdown"
import Radio from "../../../../../../components/Radio";
import Checkbox from "../../../../../../components/Checkbox"
import addIcon from "../../../../../../media/images/icons/add.png"
import removeIcon from "../../../../../../media/images/icons/remove.png"
import ContentTable from '../../../../../../components/BuildComponents/EconomyComponent/BundlesComponent/ContentTableLayout'
import TagInputWithDropdown from "../../../../../../components/TagInputWithDropdown";
import { editItem, getAllItemAction } from "../../../../../../redux/action/builds/economy/items/itemAction";
import { useDispatch, useSelector } from "react-redux";
import {getAllCurrencyAction} from '../../../../../../redux/action/builds/economy/currency/currencyAction'
import { WithContext as ReactTags } from "react-tag-input";
import Select from 'react-select';
import {ReactComponent as AddButtonIcon} from '../../../../../../media/images/icons/add_button.svg'
import {ReactComponent as AddMetaIcon} from '../../../../../../media/images/icons/add_meta.svg'
import {ReactComponent as RemoveIcon} from '../../../../../../media/images/icons/remove.svg'
import Bundles from '../../../../../../media/images/placeholders/Bundles.png'
import './style.css'
import Tooltip from "../../../../../../components/Tooltip";
import { BASE_URL, GET_BUNDLES, GET_CURRENCY, GET_ITEMS, GET_PROGRESSION_SYSTEM } from "../../../../../../resources/APIEndpoints";
import { ToastContainer, toast } from "react-toastify";
import { useRef } from "react";
import { editBundleAction, getAllBundlesAction } from "../../../../../../redux/action/builds/economy/bundles/bundleAction";
import FileUploadSidebar from "../../../../../../components/FileUploadSidebar";
import { getAllMediaAction } from "../../../../../../redux/action/AppSettings/Media/mediaAction";
import TooltipTitle from "../../../../../../Tooltip/TooltipTitle";
import JSONMetaDataValueInput from "../../../../../../components/MetaInput";
import GlobalStateContext from "../../../../../../components/Context/GlobalStates/GlobalStateContext";
import _ from 'lodash'
import { extarctImageFromUrlAction } from "../../../../../../redux/action/common/commonAction";
import SnapShot from "../../../../../../components/Images";
const daysOptions = ["Seconds", "Minutes", "Hours"]
const pricingOption = [{id: 1, name : "Virtual"}, {id:2, name :"In-App Purchase"}, {id :3, name: "RMG"}]

const BundleInformation = ({className,isChanged, setIsChanged,onClose}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const {appid} = useParams()
    const {id} = useParams()
    const globalStates = useContext(GlobalStateContext)
    const ref = useRef()

    const placeholder = Bundles
    const [{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'}); 
    const [itemIcon, setItemIcon] = useState()
    const [itemName, setItemName] = useState('')
    const [itemId, setItemId] = useState('')
    const [itemDescription, setItemDescription] = useState()

    const [pricing, setPricing] = useState([])

    const [lockItems, setLockItems] = useState()
    const [lockProgression, setLockProgression] = useState()

    const [itemType, setItemType] = useState(false);
    const [itemCount, setItemCount] = useState()
    const [itemTime, setItemTime] = useState()
    const [days, setDays] = useState(daysOptions[0])
    const [items, setItems] = useState()
    const [progressionSystem, setProgressionSystem] = useState([])
    const [quantity, setQuantity] = useState(null)
    const [isStackable, setIsStackable] = useState(false)
    const [isEquippable, setIsEquippable] = useState(false)
    const [isTradeable, setIsTradeable] = useState(false)
    const [isRentable, setIsRentable] = useState(false)
    const [isTimeStackable, setIsTimeStackable] = useState(false)
    const [unlockItemId, setUnlockItemId] = useState()
    const [unlockSystemId, setUnlockSystemId] = useState()
    const [content, setContent] = useState([])
    const [isLimited, setIsLimited] = useState(false)

    const lockBy = [{id: 1, title : "Item/Bundle", active : lockItems }, {id : 2, title : "Progression System",  active : lockProgression}]

    const itemSubType = [{id: 1, title: "Stackable", active : isStackable}, {id: 2,title: "Equippable", active : isEquippable}, {id: 3, title: "Tradeable", active : isTradeable}, {id: 4,title: "Rentable", active : isRentable}, {id: 5,title: "Time Stackable", active : false}];

    const [tags, setTags] = useState([]);
    const [metaData, setMetaData] = useState([{key : '' , value : null}])

    const [checkObject, setCheckObject] = useState()
    const [checkPricing, setCheckPricing] = useState([])

    const [selectedItemId, setSelectedItemId] = useState([])
    
    const [stacksCount, setStacksCount] = useState(null)

    const onChangeStacks = (e) => {
        setStacksCount(parseInt(e.target.value))
    }

    const [maxCollectionInstances, setMaxCollectionInstances] = useState(null)

    const onChangemaxCollectionInstance = (e) => {
        setMaxCollectionInstances(parseInt(e.target.value))
    }

    const [isCalled, setIsCalled] = useState(false)

    const [userAccess, setUserAccess] = useState(false)
    const [editAccess, setEditAccess] = useState(true)

    const getUserAccess = () => {
        if(globalStates && globalStates.user && globalStates.user.type === 'admin'){
            setEditAccess(globalStates.access)
            setUserAccess(false)
        }else if(globalStates && globalStates.user && globalStates.user.type === 'invited_member' && globalStates.user.memberAccessConfig?.length > 0){
            let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
            if(appPermission?.length > 0){
                setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
                setEditAccess(globalStates.access)
            }
        }else{

        }
    }

    useEffect(() => {
        getUserAccess()
    }, [globalStates.access])

    // const getUserAccess = () => {
    //     if(globalStates && globalStates.user && globalStates.user.memberAccessConfig?.length > 0){
    //         let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
    //         if(appPermission?.length > 0){
    //             setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
    //             globalStates.setAccess(true)
    //         }
    //     }
    // }
    
    // useEffect(() => {
    //     getUserAccess()
    // }, [])

    // const onToggleAccess = () => {
    //     if(!userAccess){
    //         setEditAccess(!editAccess)
    //         globalStates.setAccess(false)
    //     }
    // }
    
    const getItemById = async() => {

        let bundleById = {
            projectId : appid,
            ids : [id]
        }

        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}

        let res = await customAxios.post(BASE_URL + GET_BUNDLES, bundleById, headers)
        let itemId = res.data.data.bundles[0]

        setCheckObject(itemId)
        setLogo({src: (itemId.iconUrl != null ? itemId.iconUrl : Bundles), alt: 'Upload an Image'});
        setItemIcon(itemId.iconUrl)
        setItemName(itemId.name)
        setItemId(itemId.bundleId)
        setBundle(itemId.isManual)
        setItemDescription(itemId.description)
        let contentData = []
        itemId.inventoryBundlesContents && itemId.inventoryBundlesContents.forEach((e,i) =>{
            if(e.currency == null && e.contentBundle == null){
                if(e.archive == false){
                    contentData.push({id: e.id, iconUrl : e.item.iconUrl, name : e.item.name, itemId : e.item.id, quantity : e.quantity, type : (e.item.isComsumable == true ? 'Consumable' : e.item.isComsumable == false ? 'Durable': ''), archive : false, displayId : e.item.itemId})
                    setContent(prevState => [...prevState, {id: e.id, iconUrl : e.item.iconUrl, name : e.item.name, itemId : e.item.id, quantity : e.quantity, type : (e.item.isComsumable == true ? 'Consumable' : e.item.isComsumable == false ? 'Durable': ''), archive : false, displayId : e.item.itemId}])
                }
            }else if(e.item == null && e.contentBundle == null){
                if(e.archive == false){
                    contentData.push({id: e.id, iconUrl : e.currency.iconUrl, name : e.currency.name, currencyId : e.currency.id, quantity : e.quantity, type : null, archive : false, displayId : e.currency.currencyId})
                    setContent(prevState => [...prevState, {id: e.id, iconUrl : e.currency.iconUrl, name : e.currency.name, currencyId : e.currency.id, quantity : e.quantity, type : null, archive : false, displayId : e.currency.currencyId}])
                }
            }else if(e.curency == null && e.item == null){
                if(e.archive == false){
                    contentData.push({id: e.id, iconUrl : e.contentBundle.iconUrl, name : e.contentBundle.name, bundleId : e.contentBundle.id, quantity : e.quantity, type : null, archive : false, displayId : e.contentBundle.bundleId})
                    setContent(prevState => [...prevState, {id: e.id, iconUrl : e.contentBundle.iconUrl, name : e.contentBundle.name, bundleId : e.contentBundle.id, quantity : e.quantity, type : null, archive : false, displayId : e.contentBundle.bundleId}])
                }
            }
        })
        setMaxCollectionInstances(itemId.maxCollectionInstance)
        sessionStorage.setItem('contents', JSON.stringify(contentData))
        const pricing = []
        if(itemId.inventoryBundlesPrices != null && itemId.inventoryBundlesPrices.length > 0){
            for(var i = 0 ; i < itemId.inventoryBundlesPrices.length ; i++){
                let currentPricing = itemId.inventoryBundlesPrices[i]
                if(currentPricing.priceTypes == 'virtual currency' || currentPricing.priceTypes == 'virtual'){
                    if(currentPricing.archive == false){
                        const index = pricing.findIndex(object => object.name == 'Virtual')
                        if(index === -1){
                            pricing.push({id : 1, name : "Virtual"})
                        }
                        setVirtualCurrency(prevState => [...prevState, {id : currentPricing.id, currencyName : (currentPricing.currency != null ? currentPricing.currency.name : null), inventoryItemId : currentPricing.inventoryItemId, priceTypes: currentPricing.priceTypes, currencyId : currentPricing.currencyId, price : currentPricing.price, discount : currentPricing.discount, archive : currentPricing.archive}])
                        setShowVirtual(true)
                    }
                }else if(currentPricing.priceTypes == 'RMG' || currentPricing.priceTypes == 'real money'){
                    if(currentPricing.archive == false){
                        const index = pricing.findIndex(object => object.name == 'RMG')
                        if(index === -1){
                            pricing.push({id : 2, name : "RMG"})
                        }
                        setRealMoney(prevState => [...prevState, {id : currentPricing.id, currencyName : (currentPricing.curency != null ? currentPricing.currency.name : null), inventoryItemId : currentPricing.inventoryItemId, priceTypes: currentPricing.priceTypes, currencyId : currentPricing.currencyId,  price : currentPricing.price , discount : currentPricing.discount, bonusCashAllowance : currentPricing.bonusCashAllowance, archive : currentPricing.archive}])
                        setShowRMG(true)
                    }
                }else if(currentPricing.priceTypes == 'IAP'){
                    if(currentPricing.archive == false){
                        const index = pricing.findIndex(object => object.name == 'In-App Purchase')
                        if(index === -1){
                            pricing.push({id : 3, name : "In-App Purchase"})
                        }
                        setIAP(prevState => [...prevState, {id : currentPricing.id, realWorldCurrencyId: (currentPricing.realWorldCurrency != null ? currentPricing.realWorldCurrency.id : null) , currencyName : (currentPricing.realWorldCurrency != null ? currentPricing.realWorldCurrency.code.concat(" (", currentPricing.realWorldCurrency.symbol, ")") : null), inventoryItemId : currentPricing.inventoryItemId, priceTypes : 'IAP', price : currentPricing.price , productId: currentPricing.productId, discount : currentPricing.discount, gamePlatformName : (currentPricing.gamePlatformMasterId == 1 ? 'Android' : 'iOS'), gamePlatformMasterId: currentPricing.gamePlatformMasterId, archive : currentPricing.archive}])
                        setShowInApp(true)
                    }
                }
            }
        }
        setPricing(pricing)
        setIsLimited(itemId.quantity != null ? true : false)
        setItemType(itemId.isConsumable)
        setBy(itemId.consumeByUses == null ? true : false)
        setItemCount(itemId.consumeByUses != null ? itemId.consumeByUses : null)
        setItemTime((itemId.consumeByTime != null && itemId.consumeByTimeFormat == 'Seconds') ? itemId.consumeByTime : (itemId.consumeByTime != null && itemId.consumeByTimeFormat == 'Minutes') ? (itemId.consumeByTime/60) : (itemId.consumeByTime != null && itemId.consumeByTimeFormat == 'Hours') ? (itemId.consumeByTime/(60*60)) : null)
        setDays(itemId.consumeByTime != null ? itemId.consumeByTimeFormat : null)
        setQuantity(itemId.quantity)
        setIsStackable(itemId.isStackable)
        setIsEquippable(itemId.isEquippable)
        setIsTradeable(itemId.isTradable)
        setIsRentable(itemId.isRentable)
        setStacksCount(itemId.stackCapacity)
        if(itemId.rewardsUnlockConditions){
            itemId.rewardsUnlockConditions && itemId.rewardsUnlockConditions.forEach((ele, i) => {
                if(ele && ele.unlockItemId != null){
                    setLockByOptionType('Item')
                    if(ele.id){
                        setSelectedItemId(prevState => [...prevState,{id:ele.id, unlockItemId:ele.unlockItem.id, archive:ele.archive, name : ele.unlockItem.name, lockByOptionType: 'Item'}])
                        setUnlockItemId(ele.unlockItem.id)
                    }
                    if (!ele.archive) {
                        setLockItems(true)
                    }   
                }else if(ele && ele.unlockBundleId != null){
                    setLockByOptionType('Bundle')
                    if(ele.id){
                        setSelectedItemId(prevState => [...prevState,{id:ele.id, unlockItemId:null, unlockBundleId: ele.unlockBundle.id, archive:ele.archive,name : ele.unlockBundle.name, lockByOptionType: 'Bundle'}])
                        setUnlockItemId(ele.unlockBundle.id)
                    }
                    if (!ele.archive) {
                        setLockItems(true)
                    }
                }else{
                    if (!ele.archive) {
                        setLockProgression(true)
                    }
                    if(ele.id){
                        if (!ele.archive) {
                            setPS(prevState => [...prevState, {dbID : ele.id ,id : ele.unlockLevel.id, name : ele.unlockLevel.levelName, lockedLevel: ele.lockedLevel,archive:ele.archive}])
                        }
                        else{
                            setPS(prevState => [...prevState, {dbID : ele.id ,id : ele.unlockLevel.id, name : ele.unlockLevel.levelName, lockedLevel: null,archive:ele.archive}])
                        }
                    }
                }
            })
        }
        // if(itemId.rewardsUnlockConditions){
        //     itemId.rewardsUnlockConditions && itemId.rewardsUnlockConditions.forEach((ele, i) => {
        //         if(ele && ele.unlockItemId != null){
        //             setItems(ele.unlockItem.name)
        //             setUnlockItemId(ele.unlockItem.id)
        //             if(ele.id){
        //                 setSelectedItemId(ele.id)
        //             }
        //             setLockItems(true)
        //         }else{
        //             setLockProgression(true)
        //             if(ele.id){
        //                 setPS(prevState => [...prevState, {dbID : ele.id ,id : ele.unlockLevel.id, name : ele.unlockLevel.levelName, lockedLevel: ele.lockedLevel}])
        //             }
        //         }
        //     })
        // }
        itemId.tagsMapping && itemId.tagsMapping.forEach(e => {
            e.tags && setTags(tags => [...tags, {id : e.tags.id, text : e.tags.name}])
        });
        let meta = []
        if(itemId.meta == null || JSON.stringify(itemId.meta) == '{}'){
            setMetaData([{key : '', value : null}])
        }else{
            itemId.meta && Object.keys(itemId.meta).forEach(e => {
                meta.push({key : e, value : itemId.meta[e]})
            });
            setMetaData(meta)
        }
        setIsCalled(true)
    }

    const [platform, setPlatform] = useState([])
    const [platformOptions, setPlatformOptions] = useState([])

    const getMetaData = async() => {
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await axios.get(`${BASE_URL}/master-data`, headers)
        res.data.data.gamePlatforms && res.data.data.gamePlatforms.forEach((element) => {
            setPlatformOptions(prevState => [...prevState, {id : element.id, name : (element.platformName !== 'ios' ? element.platformName.charAt(0).toUpperCase() + element.platformName.slice(1) : element.platformName === 'ios' ? 'iOS' : '-')}])
        })
    }

    const [realWorldCurrency, setRealWorldCurrency] = useState([])
    
    const getRealWorldCurrency = async() => {
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await customAxios.post(`${BASE_URL}/currencies/get-world-currency`, headers)
        res.data.data && res.data.data.forEach((element) => {
            setRealWorldCurrency(prevState => [...prevState, {realWorldCurrencyId : element.id, name : element.code.concat(" (", `${element.symbol}`, ")"), type : 'IAP'}])
        })
    }

    const [allCurrency, setAllCurrency] = useState([])
    const [currencyPage, setCurrencyPage] = useState(1)
    const [currencyLimit, setCurrencyLimit] = useState(10)

    const getAllCurrencyData = async() => {
        let getAllCurrency = {
            projectId : appid,
            offset: ((currencyPage-1)*currencyLimit),
            limit: currencyLimit
        }
        await dispatch(getAllCurrencyAction(getAllCurrency))
    }

    useEffect(() => {
        getAllCurrencyData()
    }, [currencyPage])

    const [virtualCurrencyData, setVirtualCurrencyData] = useState([])
    const [realMoneyData, setRealMoneyData] = useState([])

    const customizeCurrencyData = () => {
        if(currenciesData && currenciesData.length > 0){
            const updatedArray = currenciesData && currenciesData.map((ele) => {
                const matchObj = content.find(element => element.currencyId == ele.id)
                if(matchObj){
                    return {
                        ...ele,
                        quantity : matchObj.quantity,
                        status : true
                    }
                }else{
                    return {
                        ...ele,
                        quantity : null,
                        status : false
                    }
                }
            })
            setAllCurrency(updatedArray)
            // setAllCurrency(currenciesData && currenciesData.map(value => ({...value, status : false, quantity : null})))
            let vc = currenciesData && currenciesData.filter((e,i) => e.type == 'virtual')
            let vcData = []
            vc && vc.forEach((e,i) =>{
                vcData.push( {name : e.name, id : e.id, type : 'virtual currency'})
            })
            setVirtualCurrencyData(vcData)
            let rm = currenciesData && currenciesData.filter((e,i) => e.type == 'real')
            let rmData = []
            rm && rm.forEach((e,i) =>{
                rmData.push({name : e.name, id : e.id, type : 'RMG'})
            })
            setRealMoneyData(rmData)
        }
    }

    const [allItems, setAllItems] = useState([])
    const [itemPage, setItemPage] = useState(1)
    const [itemLimit, setItemLimit] = useState(10)

    // Get Item API Call
    const [itemOptions, setItemOption] = useState()
    const [lockByOptionType, setLockByOptionType] = useState(null)

    const getItems = async() => {
        let items = {
            projectId : appid,
            offset: 0,
            limit: 1000
        }
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await customAxios.post(BASE_URL + GET_ITEMS, items, headers)
        return res.data.data.items
    }

    const getBundles = async() => {
        let bundles = {
            projectId : appid,
            offset: 0,
            limit: 1000
        }
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await customAxios.post(BASE_URL + GET_BUNDLES, bundles, headers)
        return res.data.data.bundles
    }

    const getLockByOptions = async() => {
        try {
            const [items, bundles] = await Promise.all([getItems(), getBundles()])
            const lockByOptions = [...items && items.map(x => ({...x, lockByOptionType: 'Item'})), ...bundles && bundles.map(x => ({...x, lockByOptionType: 'Bundle'}))]
            setItemOption(lockByOptions)
        }catch{
            console.log('Please Refresh')
        }finally{
            console.log('Data Fetched')
        }
    }

    useEffect(() => {
        getLockByOptions()
    }, [])
    
    const getAllItemsData = () => {
        let items = {
            projectId : appid,
            offset: ((itemPage-1)*itemLimit),
            limit: itemLimit
        }
        dispatch(getAllItemAction(items))
    }

    useEffect(() => {
        getAllItemsData()
    }, [itemPage])

    const customizeItemData = () => {
        if(itemsData && itemsData.length > 0){
            const updatedArray = itemsData && itemsData.map(ele => {
                const matchObj = content.find(element => element.itemId == ele.id)
                if(matchObj){
                    return {
                        ...ele,
                        quantity : matchObj.quantity,
                        status : true
                    }
                }else{
                    return {
                        ...ele,
                        quantity : null,
                        status : false
                    }
                }
            })
            setAllItems(updatedArray)
        }
    }

    const [getAllBundle, setAllBundle] = useState([])
    const [bundlePage, setBundlePage] = useState(1)
    const [bundleLimit, setBundleLimit] = useState(10)

    const getAllBundlesData = () => {
        let bundles = {
            projectId : appid,
            offset: ((bundlePage-1)*bundleLimit),
            limit: bundleLimit
        }
        dispatch(getAllBundlesAction(bundles))
    }

    useEffect(() => {
        getAllBundlesData()
    }, [bundlePage])

    const customizeBundleData = () => {
        if(bundleData && bundleData.length > 0){
            const updatedArray = bundleData && bundleData.map(ele => {
                const matchObj = content.find(element => element.bundleId == ele.id)
                if(matchObj){
                    return {
                        ...ele,
                        quantity : matchObj.quantity,
                        status : true
                    }
                }else{
                    return {
                        ...ele,
                        quantity : null,
                        status : false
                    }
                }
            })
            setAllBundle(updatedArray)
        }
    }

    let currenciesData = useSelector((state) => {
        return state.currencies.currency
    })

    useEffect(()=>{
        customizeCurrencyData()
    }, [currenciesData, isCalled])

    let itemsData = useSelector((state) => {
        return state.item.items
    })

    useEffect(() => {
        customizeItemData()
    }, [itemsData, isCalled])

    let bundleData = useSelector((state) => {
        return state.bundles.bundles
    })

    useEffect(() => {
        customizeBundleData()
    }, [bundleData, isCalled])

    const [PSOptions, setPSOptions] = useState([])
    const [PS, setPS] = useState([])

    const getPS = async() => {
        // let items = {
        //     projectId : appid
        // }
        // const token = localStorage.getItem('token')
        // const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        // let res = await customAxios.post(BASE_URL + GET_PROGRESSION_SYSTEM, items, headers)
        // let progressionSystem = res.data.data.levelDetails
        // progressionSystem && progressionSystem.forEach((e,i) => {
        //     return setPSOptions(prevState => [...prevState, {name : e.name, id : e.id}])
        // })
        let items = {
            projectId : appid
        }
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await customAxios.post(BASE_URL + GET_PROGRESSION_SYSTEM, items, headers)
        let progressionSystem = res.data.data.levelDetails
        progressionSystem && progressionSystem.forEach((e,i) => {
            return setPSOptions(prevState => [...prevState, {name : e.name, id : e.id, levelSystemLevelMapping:e.levelSystemLevelMapping}])
        })
    }

    useEffect(()=>{
        getPS()
        getRealWorldCurrency()
        getMetaData()
        // getAllCurrencyData()
        // getAllItemsData()
        storeContent()
        getItemById()
    }, [])

    const uploadLogo = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]),alt: e.target.files[0].name});    
        }      
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}`)
        setItemIcon(res.data.getUrl);
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
            toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        })
        .catch(function (error) {
            toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        });
    }

        // To Convert Base64 to Binary
        function convertDataURIToBinary(dataURI) {
            var BASE64_MARKER = ';base64,';
            var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
            var base64 = dataURI.substring(base64Index);
            var raw = window.atob(base64);
            var rawLength = raw.length;
            var array = new Uint8Array(new ArrayBuffer(rawLength));
              
            for(var i = 0; i < rawLength; i++) {
                array[i] = raw.charCodeAt(i);
            }
            return array;
        }
        
        // To Convert file into base64 format
        const convertbase64 = (file) => {
            return new Promise((resolve, err)=>{
            const fileReader = new FileReader();
            fileReader.onload = (eve) =>{
                resolve(fileReader.result)
            }
            fileReader.readAsDataURL(file)
            })
        }

    const Cancel = () => {
        setItemIcon(null)
        setLogo({src: placeholder , alt: 'Upload an Image'})
        ref.current.value = ''
    }

    const onSubType = (e, title) => {
        if(e.target.checked == true){
            if(title == 'Stackable'){
                setIsStackable(true)
                setStacksCount(null)
            }else if(title == 'Equippable'){
                setIsEquippable(true)
            }else if(title == 'Tradeable'){
                setIsTradeable(true)
            }else if(title == 'Rentable'){
                setIsRentable(true)
            }else if(title == 'Time Stackable'){
                setIsTimeStackable(true)
                setBy(true)
            }
        }else{
            if(title == 'Stackable'){
                setIsStackable(false)
                setStacksCount(null)
            }else if(title == 'Equippable'){
                setIsEquippable(false)
            }else if(title == 'Tradeable'){
                setIsTradeable(false)
            }else if(title == 'Rentable'){
                setIsRentable(false)
            }else if(title == 'Time Stackable'){
                setIsTimeStackable(false)
                setBy(false)
            }
        }
    }

    const onTagInput = () => {
        if(document.getElementById('tagsInput')){
            document.getElementById('tagsInput').focus()
        }
    }

    const KeyCodes = { comma: 188, enter: 13 };
      
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleDelete = (i) => {
      setTags(tags.filter((tag, index) => index !== i));
    };
  
    const handleAddition = (tag) => {
        if(tags.length < 10){
            setTags([...tags, tag]);
        }else{
            toast.warning(<Toast type='Warning' messages='Only 10 tags are allowed'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        }
    };
  
    const handleDrag = (tag, currPos, newPos) => {
      const newTags = [...tags].slice();
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
  
      setTags(newTags);
    };
  
    const handleTagClick = (index, event) => {
        event.stopPropagation();
    };
  
    const onClearAll = () => {
      setTags([]);
    };
  
    const onTagUpdate = (i, newTag) => {
      const updatedTags = tags.slice();
      updatedTags.splice(i, 1, newTag);
      setTags(updatedTags);
    };

    const[virtualCurrency, setVirtualCurrency] = useState([])

    const addVirtualCurrency = () => {
        const currencyFields = [...virtualCurrency]
        currencyFields.push({priceTypes: '', currencyId : null, price : null , discount : null, archive : false})
        setVirtualCurrency(currencyFields)
    }

    const removeVirtualCurrency = (index) => {
        let removeField = [...virtualCurrency]
        let currentFields = removeField.filter((e,i,c)=>{
            return (i == index ? e.archive = true : e.archive == false)
        })
        setVirtualCurrency(currentFields)
        const activeVirtualCurrency = currentFields.filter(item => !item.archive)
        if(activeVirtualCurrency.length == 0){
            setPricing(pricing.filter((values) => values.name != 'Virtual'))
            setShowVirtual(false)
        }
    }

    const onVirtualCurrency = (e, index, type) => {
        let virtualCurrencyList = [...virtualCurrency]
        if(type == 'currency'){
            virtualCurrencyList[index].currencyId = e.value
            virtualCurrencyList[index].priceTypes = (e.currencyTypes == 'real' ? 'real money' : e.currencyTypes == 'virtual' ? 'virtual currency'  : '')
        }else{
            virtualCurrencyList[index][e.target.name] = (type == 'price' ? parseInt(e.target.value) : parseInt(e.target.value))
        }
        setVirtualCurrency(virtualCurrencyList)
    }

    const[IAP, setIAP] = useState([])

    const addIAP = () => {
        const IAPFields = []
        IAPFields.push(...IAP, {priceTypes: '', realWorldCurrencyId : null, gamePlatformMasterId: null,  itemPrice : null , itemDiscount : null, archive : false})
        setIAP(IAPFields)
    }

    const removeIAP = (index) => {
        let removeField = IAP
        let currentFields = removeField.filter((e,i,c)=>{
            return (i == index ? e.archive = true : e.archive == false)
        })
        setIAP(currentFields)
        const activeVirtualCurrency = currentFields.filter(item => !item.archive)
        if(activeVirtualCurrency.length == 0){
            setPricing(pricing.filter((values) => values.name != 'In-App Purchase'))
            setShowInApp(false)
        }
    }

    const onIAP = (e, index, type) => {
        let IAPList = [...IAP]
        if(type == 'currency'){
            IAPList[index].currencyId = e.value
            IAPList[index].priceTypes = (e.currencyTypes == 'real' ? 'real money' : e.currencyTypes == 'virtual' ? 'virtual currency'  : '')
        }else{
            IAPList[index][e.target.name] = ((type == "product") ? e.target.value : parseInt(e.target.value))
        }
        setIAP(IAPList)
    }

    const[realMoney, setRealMoney] = useState([])

    const addRealMoney = () => {
        const realMoneyFields = []
        realMoneyFields.push(...realMoney, {priceTypes: '', currencyId : null, bonusCashAllowance : null,  itemPrice : null , itemDiscount : null, archive : false})
        setRealMoney(realMoneyFields)
    }

    const removeRealMoney = (index) => {
        let removeField = realMoney
        let currentFields = removeField.filter((e,i,c)=>{
            return (i == index ? e.archive = true : e.archive == false)
        })
        setRealMoney(currentFields)
        const activeVirtualCurrency = currentFields.filter(item => !item.archive)
        if(activeVirtualCurrency.length == 0){
            setPricing(pricing.filter((values) => values.name != 'RMG'))
            setShowRMG(false)
        }
    }

    const onRealMoney = (e, index, type) => {
        let realMoneyList = [...realMoney]
        if(type == 'currency'){
            realMoneyList[index].currencyId = e.value
            realMoneyList[index].priceTypes = (e.currencyTypes == 'real' ? 'real money' : e.currencyTypes == 'virtual' ? 'virtual currency'  : '')
        }else{
            realMoneyList[index][e.target.name] = (type == 'price' ? parseInt(e.target.value) : parseInt(e.target.value))
        }
        setRealMoney(realMoneyList)
    }

    const addMetaData = () => {
        const metaDataField = []
        metaDataField.push(...metaData, {key : '' , value : null})
        setMetaData(metaDataField)
    }

    const onChangeKey = (e, index) => {
        let keys = [...metaData]
        keys[index].key = e.target.value
        setMetaData(keys)
    }

    const onChangeValue = (e, index) => {
        let values = [...metaData]
        values[index].value = e.target.value
        setMetaData(values)
    }

    const removeMetaData = (index) => {
        let removeField = metaData
        let metaFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setMetaData(metaFields)
    }

    const onLockBy = (e, title) => {
        if(e.target.checked){
            if(title == 'Item/Bundle'){
                setLockItems(true)
            }else{
                setLockProgression(true)
            }
        }else{
            if(title == 'Item/Bundle'){
                setLockItems(false)
            }else{
                setLockProgression(false)
            }
        }
    }

    const checkPricingDiffrence = (oldArray, newArray) => {
        const updatedObjects = [];  
            // Compare and find updated objects
            for (const newObj of newArray) {
                const oldObj = oldArray.find(obj => obj.id === newObj.id); // Assuming 'id' is a unique identifier
                if (oldObj) {
                    let hasChanged = false;
                    for (const key in newObj) {
                        if (newObj[key] !== oldObj[key]) {
                            hasChanged = true;
                            break;
                        }
                    }
                    if (hasChanged) {
                        updatedObjects.push(newObj);
                    }
                }else {
                    updatedObjects.push(newObj);
                }
            }
        return { updated: updatedObjects };
    }

    const [errors, setErrors] = useState({nameError : '', idError : '', typeError : ''})

    const validation = (data) => {
        const error = {}
        let isValid = true

        if (data.bundles[0].name && data.bundles[0].name == '') {
            error.nameError = 'Display Name is required';
            isValid = false;
            const element = document.getElementById('bundleName');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        }
      
        if (data.bundles[0].bundleId && data.bundles[0].bundleId == '') {
          error.idError = 'Bundle ID is required';
          isValid = false;
          if(data.bundles[0].name && data.bundles[0].name != ''){
                const element = document.getElementById('bundleId');
                const offset = 140;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
          }
        }

        // if (data.bundles[0].isConsumable == '') {
        //     error.typeError = 'Bundle Type is required';
        //     isValid = false;
        // }

        setErrors(error);

        if(isValid){
            return true
        }
    }

    const checkBundleContent = (arr1, arr2) => {
        const updatedObjects = arr2 && arr2.filter((item2) => {
            const item1 = _.find(arr1, { id: item2.id });
        
            if (!item1) return false; // Skip if there's no corresponding object in the first array
            
            if(item1.itemId){
                return (
                    item1.itemId !== item2.itemId ||
                    item1.archive !== item2.archive ||
                    item1.quantity !== item2.quantity
                )
            }

            if(item1.currencyId){
                return (
                    item1.currencyId !== item2.currencyId ||
                    item1.archive !== item2.archive ||
                    item1.quantity !== item2.quantity
                )
            }

            if(item1.contentBundleId){
                return (
                    item1.contentBundleId !== item2.contentBundleId ||
                    item1.archive !== item2.archive ||
                    item1.quantity !== item2.quantity
                )
            }
        
            return false; // Skip other price types if needed
        })

        // Step 2: Find newly added objects in the second array (not in the first array)
        const newObjects = arr2 && arr2.filter(item2 => !_.find(arr1, { id: item2.id }));

        // Step 3: Combine the changed and newly added objects
        return [...updatedObjects, ...newObjects]
    }

    const checkPricingDetail = (arr1, arr2) => {
        // Step 1: Filter for changed objects in both arrays
        const updatedObjects = arr2 && arr2.filter((item2) => {
            const item1 = _.find(arr1, { id: item2.id });
        
            if (!item1) return false; // Skip if there's no corresponding object in the first array
            
            if (item1.priceTypes === 'virtual currency') {
                // Check only specified fields for virtual currency
                return (
                  item1.currencyId !== item2.currencyId ||  // Check currencyId
                  item1.archive !== item2.archive ||         // Check archive
                  parseFloat(item1.price) !== parseFloat(item2.price) ||  // Check price (amount)
                  parseFloat(item1.discount) !== parseFloat(item2.discount)  // Check discount
                );
            }

            if (item1.priceTypes === 'RMG') {
                return (
                    item1.currencyId !== item2.currencyId ||          // Check currencyId
                    item1.archive !== item2.archive ||                 // Check archive
                    parseFloat(item1.bonusCashAllowance) !== parseFloat(item2.bonusCashAllowance) || // Check bonusCashAllowance
                    item1.hosting !== item2.hosting ||                 // Check hosting
                    item1.hostingFee !== item2.hostingFee ||           // Check hostingFee
                    parseFloat(item1.price) !== parseFloat(item2.price) // Check price (amount)
                );
            }
            if (item1.priceTypes === 'IAP') {
                return (
                    item1.realWorldCurrencyId !== item2.realWorldCurrencyId ||          // Check currencyId
                    item1.archive !== item2.archive ||                 // Check archive 
                    item1.discount !== item2.discount || 
                    item1.productId !== item2.productId || 
                    parseFloat(item1.gamePlatformMasterId) !== parseFloat(item2.gamePlatformMasterId) ||
                    parseFloat(item1.price) !== parseFloat(item2.price) // Check price (amount)
                );
            }
        
            return false; // Skip other price types if needed
        })

        // Step 2: Find newly added objects in the second array (not in the first array)
        const newObjects = arr2 && arr2.filter(item2 => !_.find(arr1, { id: item2.id }));

        // Step 3: Combine the changed and newly added objects
        return [...updatedObjects, ...newObjects]
    }

    const compareMeta = (meta1, meta2) => {
        const keys1 = Object.keys(meta1);
        const keys2 = Object.keys(meta2);
        // If the number of keys or their values differ, return the whole meta2
        if (keys1.length !== keys2.length || !_.isEqual(meta1, meta2)) {
            return meta2; // Return the whole meta2 object
        }
        // Return null if there are no changes
        return null;
    }

    const compareRewardUnlock = (referenceArray, updatedArray) => {
        const findById = (arr, id) => arr && arr.find(item => item.id === id);
        // Iterate through the updated array to check for changes
        const result = updatedArray && updatedArray.filter(updatedItem => {
            const referenceItem = findById(referenceArray, updatedItem.id);
            // If no matching referenceItem exists, it's a new object
            if (!referenceItem) {
                updatedItem.archive = false;
                delete updatedItem.id;
                return true;
            }
            // Check if the relevant fields have changed
            const hasChanges = (
                referenceItem.archive !== updatedItem.archive ||
                referenceItem.unlockItemId !== updatedItem.unlockItemId ||
                referenceItem.unlockBundleId !== updatedItem.unlockBundleId ||
                referenceItem.unlockLevelSystemId !== updatedItem.unlockLevelSystemId ||
                referenceItem.lockedLevel !== updatedItem.lockedLevel
            );
            return hasChanges; // Include the object if any relevant field has changed
        });

        return result;
    }
    
    const filterChanged = (obj1, obj2) => {
        return _.reduce(obj2, (result, value, key) => {
          // Always include 'id' and 'uuid' keys
          if (key === 'id' || key === 'projectId') {
            result[key] = value;
          }
          // Special case: compare 'tags' in obj2 with 'tagsMapping' in obj1
          else if (key === 'tags') {
            const tagsFromMapping = obj1['tagsMapping'] && obj1['tagsMapping'].map(mapping => mapping.tags.name);
            if (!_.isEqual(tagsFromMapping, value)) {
              result[key] = value; // map tags changes to 'tagsMapping'
            }
          }
          // Compare nested objects (like 'meta')
          else if (key === 'meta' && _.isObject(value) && _.isObject(obj1[key])) {
            const metaChanges = compareMeta(obj1.meta, obj2.meta);
            if (metaChanges) {
              result.meta = metaChanges; // Include the whole new meta if there are changes
            }
          }
          else if(key === 'bundlePrices'){
            const updatedPrices = checkPricingDetail(obj1.inventoryBundlesPrices, obj2.bundlePrices)
            if(updatedPrices?.length > 0){
                result[key] = updatedPrices
            }
          }
          else if(key === 'bundleContent'){
            const updatedContent = checkBundleContent(obj1.inventoryBundlesContents, obj2.bundleContent)
            if(updatedContent?.length > 0){
                result[key] = updatedContent
            }
          }
          else if(key === 'rewardUnlockCondition'){
            let updatedRewardUnlock = compareRewardUnlock(obj1.rewardsUnlockConditions, obj2.rewardUnlockCondition)
            if(updatedRewardUnlock?.length > 0){
                result[key] = updatedRewardUnlock
            }
          }
          // Normal comparison: add the key if values differ
          else if (!_.isEqual(obj1[key], value)) {
            result[key] = value;
          }
          return result;
        }, {})
    }

    const edit = async() => {
        setIsChanged(false)
        const metaDataList = [...metaData];
        const finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }

        let itemTags = []
        tags && tags.forEach((e)=>{
            itemTags.push(e.text)
        })

        let bundlePrices = [...virtualCurrency, ...realMoney, ...IAP]
        
        const filteredPrice = bundlePrices.filter(item => {
            const bundlePrices = item.price;
            return bundlePrices !== null && bundlePrices !== undefined ;
        });

        let unlockItems = []
        selectedItemId && selectedItemId.map((e,i) =>{
            if(e.lockByOptionType === 'Item'){
                if(e?.id !== undefined){
                    unlockItems.push({archive: e.archive, id: e.id, unlockItemId : e.unlockItemId, unlockBundleId: null, name:e.name, unlockLevelSystemId : null, lockedLevel : null, lockByOptionType: 'Item'})
                }else{
                    if(e.unlockItemId === unlockItemId){
                        unlockItems.push({archive: false, unlockItemId : e.unlockItemId, unlockBundleId: null, name:e.name, unlockLevelSystemId : null, lockedLevel : null, lockByOptionType: 'Item'})
                    }
                }
            }else{
                if(e?.id !== undefined){
                    unlockItems.push({archive: e.archive, id: e.id, unlockItemId : null, unlockBundleId: (e.unlockBundleId ? e.unlockBundleId : e?.unlockItemId ? e?.unlockItemId : null), name:e.name, unlockLevelSystemId : null, lockedLevel : null, lockByOptionType: 'Bundle'})
                }else{
                    if(e.unlockItemId === unlockItemId){
                        unlockItems.push({archive:false, unlockItemId : null, unlockBundleId: e?.unlockItemId, name:e.name, unlockLevelSystemId : null, lockedLevel : null, lockByOptionType: 'Bundle'})
                    }
                }
            }
        })

        let unlockPS = []
        PS && PS.map((e,i) =>{
            unlockPS.push({archive:e.archive,id: e.dbID, unlockItemId : null, unlockBundleId: null, name:e.name, unlockLevelSystemId : e.id, lockedLevel : e.lockedLevel})
        })

        if (lockItems===false) {
            unlockItems.map((e)=>(e.archive=true))
        }
        if (lockProgression===false) {
            unlockPS.map((e)=>(e.archive=true,e.lockedLevel=null))
        }

        let unlockItemData = [...unlockItems, ...unlockPS]

        const contents = JSON.parse(sessionStorage.getItem('contents'))
        const addedContent = contents.map(({iconUrl, name, type, displayId, ...content}) => content).map(obj => {const { bundleId, ...rest } = obj; return { contentBundleId: bundleId, ...rest };})

        const editItemData = {
            bundles : [
                {
                    id : id,
                    projectId : appid,
                    ...(itemIcon != checkObject.iconUrl && {iconUrl : itemIcon}),
                    ...(itemName != checkObject.name && {name : itemName}),
                    ...(itemId != checkObject.bundleId && {bundleId : itemId}),
                    ...(itemDescription != checkObject.description && {description : itemDescription}),
                    ...(isEquippable != checkObject.isEquippable && {isEquippable : isEquippable}),
                    ...(isTradeable != checkObject.isTradable && {isTradable : isTradeable}),
                    ...(isStackable != checkObject.isStackable && {isStackable : isStackable}),
                    ...(isRentable != checkObject.isRentable && {isRentable: isRentable}),
                    ...(itemType != checkObject.isConsumable && {isConsumable : itemType}),
                    ...(itemType != checkObject.isConsumable && {itemType : itemType}),
                    ...(by && {consumeByUses : null, consumeByTimeFormat : days, consumeByTime : (days == 'Seconds' ? parseInt(itemTime) : days == 'Minutes' ? (parseInt(itemTime)*60) : days == 'Hours' ? (parseInt(itemTime)*60*60) : days == 'Days'? (parseInt(itemTime)*24*60*60) : days == 'Months' ? (parseInt(itemTime)*30.42*24*60*60) : days == 'Years' ? (parseInt(itemTime)*365*24*60*60) : null)}),
                    ...(itemCount != checkObject.consumeByUses && !by && {consumeByUses : itemCount, consumeByTime : null}),
                    quantity : (isLimited ? quantity : null),
                    stackCapacity : (isStackable ? stacksCount : null),
                    bundlePrices: filteredPrice.map(({currencyName,gamePlatformName, ...rest}) => {return rest;}),
                    ...(itemType != checkObject.isConsumable && {bundleTypeId : (itemType == false ? "1" : "2")}),
                    ...(lockItems != checkObject.isLocked && {isLocked : ((lockItems == false && lockProgression == false) ? false : true)}),
                    ...(JSON.stringify(finalMetaData) != JSON.stringify(checkObject.meta) && {meta : finalMetaData}),
                    ...(JSON.stringify(itemTags) != JSON.stringify(checkObject.tags) && {tags : itemTags}),
                    active : true,
                    archive : false,
                    rewardUnlockCondition : unlockItemData.length > 0 ? unlockItemData : null,
                    bundleContent : addedContent,
                    maxCollectionInstance:maxCollectionInstances,
                    isManual : bundle
                }
            ]
        }
        let bundles = await filterChanged(checkObject, editItemData.bundles[0])
        if((lockItems && unlockItemId == undefined || (lockProgression && PS.length == 0))){
            if(lockItems && unlockItemId == undefined){
                setLockItemErr(true)
            }
            if(lockProgression && PS.length == 0){
                setLockPSErr(true)
            }
        }else{
            let bundleData = {
                bundles: [bundles]
            }
            if(validation(bundleData)){
                dispatch(editBundleAction(bundleData, navigate, appid, globalStates.setShowOverlay))
                setIsChanged(false)
            }
        }   
    }

    const [lockItemErr, setLockItemErr] = useState(false)
    const [lockPSErr, setLockPSErr] = useState(false)

    const cancel = () => {
        // navigate(`/bundle/${appid}`)
        setEditAccess(true)
        globalStates.setAccess(true)
        setIsChanged(false)
    }

    const [showVirtual, setShowVirtual] = useState(false)
    const [showInApp, setShowInApp] = useState(false)
    const [showRMG, setShowRMG] = useState(false)

    const onSelect = (selectedList, selectedItem) => {
        if(selectedItem.pricingName == 'Virtual'){
            setShowVirtual(!showVirtual)
        }else if(selectedItem.pricingName == 'In-App Purchase'){
            setShowInApp(!showInApp)
        }else{
            setShowRMG(!showRMG)
        }
    }

    const onRemove = (selectedList, removedItem) => {
        if(removedItem.pricingName == 'Virtual'){
            setShowVirtual(!showVirtual)
        }else if(removedItem.pricingName == 'In-App Purchase'){
            setShowInApp(!showInApp)
        }else{
            setShowRMG(!showRMG)
        }
    }

    const [by, setBy] = useState(false)

    const onChangeLevel = (value, index) => {
        let unlockLevelSystem = [...PS]
        unlockLevelSystem[index].lockedLevel = parseInt(value)
        setPS(unlockLevelSystem)
    }

    const storeContent = () => {
        sessionStorage.setItem('contents', JSON.stringify(content))
    }


    useEffect(()=>{
        storeContent()
    }, [])

    useEffect(()=>{

    }, [content])

    function comparePricingArrays(arr1, arr2) {
        let array1=arr1&& arr1.filter(item => !item.archive)
        let array2=arr2&& arr2.filter(item => !item.archive)
        if (array1?.length !== array2?.length) {
            return false
        }
        for (let i = 0; i < array1.length; i++) {
            if (
                (array1[i]?.realWorldCurrencyId===array2[i]?.realWorldCurrencyId|| array2[i]?.realWorldCurrencyId==undefined|| array2[i]?.realWorldCurrencyId==null)&&
                (array1[i]?.currencyId===array2[i]?.currencyId || array2[i]?.currencyId==undefined|| array2[i]?.currencyId==null)&&
                (parseInt(array1[i]?.price)===array2[i]?.price)&&
                array1[i].priceTypes===array2[i].priceTypes
            ) {
            }
            else{
                return false;
            }
        }

        return true;
    }

    function compareUnlockConditionsArrays(arr1, arr2) {
        let array1=arr1&& arr1.filter(item => !item?.archive)
        let array2=arr2&& arr2.filter(item => !item?.archive)
        if (array1?.length !== array2?.length) {
            return false
        }
        for (let i = 0; i < array1.length; i++) {
            if (
                (parseInt(array1[i]?.lockedLevel)===array2[i]?.lockedLevel|| array2[i]?.lockedLevel==undefined|| array2[i]?.lockedLevel==null)&&
                (array1[i]?.unlockLevelSystemId===array2[i]?.unlockLevelSystemId || array2[i]?.unlockLevelSystemId==undefined|| array2[i]?.unlockLevelSystemId==null)&&
                (array1[i]?.unlockItemId===array2[i]?.unlockItemId || array2[i]?.unlockItemId==undefined|| array2[i]?.unlockItemId==null)
            ) {
            }
            else{
                return false;
            }
        }

        return true;
    }

    function compareContentArrays(arr1, arr2) {
        let array1=arr1&& arr1.filter(item => !item?.archive)
        let array2=arr2&& arr2.filter(item => !item?.archive)
        if (array1?.length !== array2?.length) {
            return false;
        }
    
        // Iterate over the arrays
        for (let i = 0; i < array1.length; i++) {
            if (
                (array1[i]?.displayId===array2[i]?.item?.itemId|| array2[i]?.item?.itemId==undefined|| array2[i]?.item?.itemId==null)&&
                (array1[i]?.displayId===array2[i]?.currency?.currencyId || array2[i]?.currency?.currencyId==undefined|| array2[i]?.currency?.currencyId==null)&&
                parseInt(array1[i].quantity)===array2[i].quantity
            ) {
            }
            else{
                return false;
            }
        }

        return true;
    }

    


    const setchange = () => {

        let itemPrices = [...virtualCurrency, ...realMoney, ...IAP]

        let metaDataList = [...metaData];
        let finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }

        let itemTags = []
        tags && tags.forEach((e)=>{
            itemTags.push(e.text)
        })

        let checkTags = []
        checkObject?.tagsMapping && checkObject?.tagsMapping.forEach((e)=>{
            if (e.tags!==null&&e.tags!==undefined) {
            checkTags.push(e?.tags?.name)
            }
        })

        let unlockItems = []
        if(unlockItemId){
            unlockItems.push({id: selectedItemId, unlockItemId : unlockItemId, unlockLevelSystemId: null})
        }

        let unlockPS = []
        PS && PS.map((e,i) =>{
            unlockPS.push({id: e.dbID, unlockItemId : null, unlockLevelSystemId : e.id, lockedLevel : e.lockedLevel})
        })

        let unlockItemData = [...unlockPS,...unlockItems]

        let cnt = content;

        let pricecheck =checkObject && comparePricingArrays(itemPrices,checkObject.inventoryBundlesPrices)
        let contentCheck = checkObject && compareContentArrays(cnt, checkObject.inventoryBundlesContents)
        let unlockCheck = checkObject && ((checkObject.rewardsUnlockConditions&& compareUnlockConditionsArrays(unlockItemData, checkObject.rewardsUnlockConditions))||!checkObject?.rewardsUnlockConditions)
        if (checkObject&&
            (itemIcon == checkObject.iconUrl)&&
            (itemName == checkObject.name)&&
            (itemId == checkObject.bundleId)&&
            (itemDescription == checkObject.description||((itemDescription===undefined||itemDescription==''||itemDescription=="")&&((checkObject.description===undefined||checkObject.description==''||checkObject.description==""||checkObject.description==null))))&&
            (isEquippable == checkObject.isEquippable)&&
            (isTradeable == checkObject.isTradable)&&
            (isStackable == checkObject.isStackable)&&
            (maxCollectionInstances == checkObject.maxCollectionInstance)&&
            (isRentable == checkObject.isRentable)&&
            (itemType == checkObject.isConsumable)&&
            (quantity == checkObject.quantity)&&
            // (pricecheck)&&
            // (contentCheck)&&
            (stacksCount == checkObject.maxNumberOfStack)&&
            //((JSON.stringify(finalMetaData) == JSON.stringify(checkObject.meta))||((JSON.stringify(finalMetaData)=='{"":""}')&&(checkObject.meta==null)))&&
            (JSON.stringify(itemTags) == JSON.stringify(checkTags)|| (checkObject.tagsMapping==null && itemTags.length==0))
            ) {
                setIsChanged(false)
            }
            else {
            setIsChanged(true)
        }
    }

    useEffect(()=>{
        setchange()
    }, [itemIcon,itemName,itemId,virtualCurrency,IAP,realMoney,content,itemDescription,isStackable,isEquippable,isTradeable,isRentable,isTimeStackable,itemCount,itemTime,quantity,days,items,progressionSystem,itemType,isLimited,pricing,lockItems, lockProgression, stacksCount, maxCollectionInstances, tags, metaData])

    const [visibleHelp, setVisibleHelp] = useState(false)

    const [media, setMedia] = useState([])

    const getMedia = async() => {
        let getMediaData = {
            projectId : appid,
            category: 'icons',
            typeId : 0
        }
        await dispatch(getAllMediaAction(getMediaData))
    }

    let files = useSelector((state) => {
        return state.media.media.mediaDetails
    })

    const configureMedia = () => {
        if(files && files.length > 0){
            setMedia(files && files.map((e, i) => ({...e, status : false})))
        }else{
            setMedia([])
        }
    }

    useEffect(() => {
        getMedia()
    }, [])

    useEffect(() => {
        configureMedia()
    }, [files])

    const [bundle, setBundle] = useState(false)

    return (
        <>
            <ToastContainer />
            <Card className={cn(styles.card, className)} title="Bundle Information" classTitle="title-purple">
                <div className={styles.cardDescription}>
                    <div className={styles.preview}>   
                        <div className={styles.previewImage}>
                            <SnapShot id='logo' url={itemIcon} alt={itemName} placeholder={Bundles} type='Images' width={60} height={60} borderRadius={16}/>
                            {/* <img id='logo' alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img> */}
                        </div>
                        <div className={styles.previewInput}> 
                            <input disabled={editAccess} type="file" className='file-input' accept="image/jpeg, image/png, image/webp, .jpeg, .jpg, .png, .webp" ref={ref} onChange={(e)=>{uploadLogo(e)}} />
                            {!editAccess && <label className={styles.button} onClick={() => setVisibleHelp(true)}><AddButtonIcon className={styles.addIcon}/>Upload Icon</label>}
                        </div>
                        {itemIcon != null && !editAccess &&
                        <div className={styles.previewCancel}>
                            <button className={styles.buttonCancel} onClick={Cancel}>Remove</button>
                        </div>
                        }
                    </div>
                    <FileUploadSidebar media={media} setMedia={setMedia} types='Images' id={`icon`} multiple={false} visible={visibleHelp} setVisible={setVisibleHelp} icon={itemIcon} setIcon={setItemIcon} setValue={setLogo} onClose={onClose} />
                    <div className={styles.createItem}>
                        <div className={styles.group}>
                            <TextInput disabled={editAccess} tooltip={TooltipTitle.BundleName} id='bundleName' className={styles.field} errorMessage={errors.nameError} label="Display Name*" name="displayName" type="text" required value={itemName} onChange={(e)=>{e.target.value == '' ? setItemName('') : setItemName(e.target.value); errors.nameError = ''}} />
                            <TextInput disabled={editAccess} tooltip={TooltipTitle.BundleId} id='bundleId' className={styles.field} errorMessage={errors.idError} label="Bundle ID*" name="itemId" type="text" required value={itemId} onChange={(e)=>{e.target.value == '' ? setItemId('') : setItemId(e.target.value); errors.idError = ''}} />
                        </div>

                        <div className={styles.textArea}>
                            <TextArea type='BUNDLE_DESCRIPTION' disabled={editAccess} tooltip={TooltipTitle.BundleDescription} className={styles.field} rows={5} label="Bundle Description" value={itemDescription} onChange={(e)=>{setItemDescription(e.target.value)}}/>
                        </div>
                    </div>

                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Add Content to Bundle" classTitle="title-red"
                head={
                    <div className={styles.createBundle}>
                        <div className={styles.groupHeading}>
                            <Control type='Bundle' access={editAccess} pageItem={itemPage} pageCurrnecy={currencyPage} pageBundle={bundlePage} currency={allCurrency} setCurrency={setAllCurrency} currencyCount={currencyLimit} currencyPage={setCurrencyPage} item={allItems} setItem={setAllItems} itemCount={itemLimit} itemPage={setItemPage} bundles={getAllBundle} setBundle={setAllBundle} bundleCount={bundleLimit} bundlePage={setBundlePage} setValue={setContent} setCalled={setIsCalled} value={content}  width='medium'/>
                        </div>
                    </div>
                }
            >
                {content && content.length > 0 &&
                    <div className={styles.cardDescription}>
                        <div className={styles.createBundle}>
                            <div className={styles.content}>
                                <div className={styles.wrapper}>
                                    <ContentTable access={editAccess} items={content} setValue={setContent} allCurrency={allCurrency} setAllCurrency={setAllCurrency} allItems={allItems} setAllItems={setAllItems} allBundles={getAllBundle} setAllBundle={setAllBundle}/>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Card>

            <Card className={cn(styles.card, className)} title="Pricing" classTitle="title-green">
                <div className={styles.cardDescription}>

                    <div className={styles.createItem}>

                        <div className={styles.labelHeading}>
                            <span>Choose Pricing Options<span><Tooltip className={styles.tooltip} title={TooltipTitle.BundlePricing} icon="info"/></span></span>
                        </div>
                        <div className={styles.selectInput}>
                            <Multiselect disabled={editAccess} type='edit' placeholder='Choose Pricing Options' options={pricingOption} value={pricing} setValue={setPricing} setShowVirtual={setShowVirtual} setShowRMG={setShowRMG} setShowInApp={setShowInApp} setVirtualCurrency={setVirtualCurrency} setIAP={setIAP} setRealMoney={setRealMoney}/>
                        </div>
                        {showVirtual && 
                            <>
                            <div className={styles.groupHeading}>
                                <span>Virtual</span>{!editAccess && <span className={styles.addIcon} onClick={addVirtualCurrency}><AddMetaIcon /></span>}
                            </div>
                            <div className={styles.groupByThree}>
                                {virtualCurrency && virtualCurrency.map((e,index)=>{
                                    if(e.archive == false){
                                        return(
                                            <>
                                                <div className={styles.field}>
                                                    <CurrencyDropdown disabled={editAccess} type="virtual currency" label="Select Currency" options={virtualCurrencyData} value={e.currencyName} data={virtualCurrency} setData={setVirtualCurrency} index={index}/>
                                                </div>
                                                <TextInput disabled={editAccess} className={styles.field} label="Price" name="price" type="number" onWheelCapture={e => {e.currentTarget.blur()}} required defaultValue={e.price} onChange={(e)=>{onVirtualCurrency(e, index, "price")}}/>
                                                <TextInput disabled={editAccess} className={styles.field} label="Discount" name="discount" type="number" onWheelCapture={e => {e.currentTarget.blur()}} required defaultValue={e.discount} onChange={(e)=>{onVirtualCurrency(e, index)}}/>
                                                {!editAccess && <span className={styles.remove} onClick={()=>{removeVirtualCurrency(index)}}><img className={styles.icon} src={removeIcon} alt="add" /></span>}
                                            </>
                                        )
                                    }
                                })}
                                </div>
                            </>
                        }
                    </div>

                    <div className={styles.createItem}>
                        {showInApp &&
                            <>
                                <div className={styles.groupHeading}>
                                    <span>IAP</span>{!editAccess && <span className={styles.addIcon} onClick={addIAP}><AddMetaIcon /></span>}
                                </div>
                                <div className={styles.groupByFive}>
                                    {IAP && IAP.map((e, index)=>{
                                        if(e.archive == false){
                                            return(
                                                <>
                                                    <div className={styles.field}>
                                                        <CurrencyDropdown disabled={editAccess} type="IAP" label="Select Currency" options={realWorldCurrency} value={e.currencyName} data={IAP} setData={setIAP} index={index}/>
                                                    </div>
                                                    <div className={styles.field}>
                                                        <PlatformDropdown disabled={editAccess} label="Select Platform" options={platformOptions} value={e.gamePlatformName} data={IAP} setData={setIAP} index={index}/>
                                                    </div>
                                                    <TextInput disabled={editAccess} className={styles.field} label="Product ID" name="productId" type="text" required defaultValue={e.productId} onChange={(e)=>{onIAP(e, index, "product")}}/>
                                                    <TextInput disabled={editAccess} className={styles.field} label="Price" name="price" type="number" onWheelCapture={e => {e.currentTarget.blur()}} required defaultValue={e.price} onChange={(e)=>{onIAP(e, index, "price")}}/>
                                                    <TextInput disabled={editAccess} className={styles.field} label="Discount" name="discount" type="number" onWheelCapture={e => {e.currentTarget.blur()}} defaultValue={e.discount} required onChange={(e)=>{onIAP(e, index)}}/>
                                                    {!editAccess && <span className={styles.remove} onClick={()=>{removeIAP(index)}}><img className={styles.icon} src={removeIcon} alt="add" /></span>}
                                                </>
                                            )
                                        }
                                    })}
                                </div>
                            </>
                        }
                    </div>

                    <div className={styles.createItem}>
                        {showRMG &&
                            <>
                                <div className={styles.groupHeading}>
                                    <span>Real Money</span>{!editAccess && <span className={styles.addIcon} onClick={addRealMoney}><AddMetaIcon /></span>}
                                </div>
                                <div className={styles.groupByFour}>
                                    {realMoney && realMoney.map((e, index) => {
                                        if(e.archive == false){
                                            return (
                                                <>
                                                    <div className={styles.field}>
                                                        <CurrencyDropdown disabled={editAccess} type="RMG" label="Select Currency" options={realMoneyData} value={e.currencyName} data={realMoney} setData={setRealMoney} index={index}/>
                                                    </div>
                                                    <TextInput disabled={editAccess} className={styles.field} label="Price" name="price" type="text" required defaultValue={e.price} onChange={(e)=>{onRealMoney(e, index, "price")}} />
                                                    <TextInput disabled={editAccess} className={styles.field} label="Discount" name="discount" type="number" onWheelCapture={e => {e.currentTarget.blur()}} defaultValue={e.discount} required onChange={(e)=>{onRealMoney(e, index)}} />
                                                    <TextInput disabled={editAccess} className={styles.field} label="Bonus Cash Allowance" name="bonusCashAllowance" type="number" onWheelCapture={e => {e.currentTarget.blur()}} required defaultValue={e.bonusCashAllowance} onChange={(e)=>{onRealMoney(e, index)}} />
                                                    {!editAccess && <span className={styles.remove} onClick={()=>{removeRealMoney(index)}}><img className={styles.icon} src={removeIcon} alt="add" /></span>}
                                                </>
                                            )
                                        }
                                    })}
                                </div>
                            </>
                        }
                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Access & Eligibility" classTitle="title-blue">
                <div className={styles.cardDescription}>
                    <div className={styles.createItem}>
                        <div className={styles.group}>
                            <div className={styles.fieldRadio}>
                                <div className={styles.radioLabel}>
                                    <span>Lock by<span><Tooltip className={styles.tooltip} title={TooltipTitle.BundleLock} icon="info"/></span></span>
                                </div>
                                <div className={styles.variants}>
                                    {lockBy.map((x, index) => (
                                        <Checkbox disable={editAccess} value={x.active} key={x.id} className={styles.checkbox} content={x.title} onChange={(e)=>{onLockBy(e, x.title)}}/>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.createItem}>
                            <div className={styles.group}>
                                {lockItems &&
                                    <div className={styles.lockGroup}>
                                        <div className={styles.radioLabel}>
                                            <span>Choose Item/Bundle<span><Tooltip className={styles.tooltip} title={TooltipTitle.StoreItemBundle} icon="info"/></span></span>
                                        </div>
                                        <ItemDropdown placeholder='Select Item/Bundle' disabled={editAccess} className={styles.selectField} groupBy={1} options={itemOptions} value={selectedItemId} setValue={setSelectedItemId} setUnlockItemId={setUnlockItemId} error={setLockItemErr} setType={setLockByOptionType}/>
                                        {lockItemErr && <span className={styles.danger}>*Item is required.</span>}
                                    </div>
                                }
                                {lockProgression ?
                                    <div className={styles.lockGroup}>
                                        <div className={styles.radioLabel}>
                                            <span>Choose Progression System<span><Tooltip className={styles.tooltip} title={TooltipTitle.StorePS} icon="info"/></span></span>
                                        </div>
                                        <CommonDropdown disabled={editAccess} placeholder='Select Progression System' className={styles.selectField} options={PSOptions} value={PS} setValue={setPS} error={setLockPSErr}/>
                                        {lockPSErr && <span className={styles.danger}>*Progression System is required.</span>}
                                    </div>
                                : ''}
                            </div>
                        <div className={styles.group}>
                            {lockProgression &&
                                <>
                                    {PS && PS.map((element, index) =>{
                                        
                                        return(!element.archive &&
                                            <>
                                                <div style={{marginTop:"8px"}} className={styles.field}>
                                                    <ProgressionLevelMultiSelect disabled={editAccess} placeholder='Select Level' label={element.name} options={(PSOptions.find(x=>x.name==element.name)?.levelSystemLevelMapping)&&(PSOptions.find(x=>x.name==element.name)?.levelSystemLevelMapping)} ind={index} value={PS} setValue={setPS}/>                                  
                                                </div>
                                            </>
                                        )
                                    })}
                                </>
                            }
                        </div>
                    </div>

                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Item Type" classTitle="title-yellow">
                <div className={styles.cardDescription}>

                    <div className={styles.createItem}>
                        <div className={styles.groupByThrees}>
                            <div className={styles.fieldRadio}>
                                <div className={styles.radioLabel}>
                                    <span>Item Type*<span><Tooltip className={styles.tooltip} title={TooltipTitle.BundleType} icon="info"/></span></span>
                                </div>
                                <div className={styles.variants}>
                                    <Radio disabled={editAccess} className={styles.radio} value={!itemType} name="itemType" onChange={() => setItemType(false)} content="Durable" />
                                    <Radio disabled={editAccess} className={styles.radio} value={itemType} name="itemType" onChange={() => setItemType(true)} content="Consumable" />
                                </div>
                            </div>
                            {itemType && 
                                <div className={styles.fieldRadio}>
                                    <div className={styles.radioLabel}>
                                        <span>Consumable By<span><Tooltip className={styles.tooltip} title={TooltipTitle.BundleBy} icon="info"/></span></span>
                                    </div>
                                    <div className={styles.variants}>
                                        <Radio disabled={editAccess} className={styles.radio} value={!by} name="by" onChange={() => setBy(false)} content="By Uses" />
                                        <Radio disabled={editAccess} className={styles.radio} value={by} name="by" onChange={() => setBy(true)} content="By Time" />
                                    </div>
                                </div>
                            }
                            {itemType ?
                                <>
                                    <div className={styles.field}>
                                        {!by && <TextInput disabled={editAccess} value={itemCount} label="By Uses" name="count" type="number" onWheelCapture={e => {e.currentTarget.blur()}} min="0" required onChange={(e)=>{setItemCount(e.target.value)}}/>}
                                        {by && <TextInput disabled={editAccess} value={itemTime}  label="By Time" name="time" type="text" required  onChange={(e)=>{setItemTime(e.target.value)}} />}
                                    </div>
                                </>
                            : ''}
                            {/* {itemType ?
                                <>
                                    <TextInput className={styles.field} value={itemCount} label="By Count" name="count" type="number" onWheelCapture={e => {e.currentTarget.blur()}} min="0" required onChange={(e)=>{setItemCount(e.target.value)}}/>
                                    <TextInput className={styles.field} value={itemTime}  label="By Time" name="time" type="text" required  onChange={(e)=>{setItemTime(e.target.value)}} />
                                </>
                            : ''} */}
                        </div>
                    </div>

                    {itemType && by ?
                    <div className={styles.createItem}>
                        <div className={styles.itemDropdown}>
                            <DaysDropdown options={daysOptions} value={days} groupBy={3} setValue={setDays}/>
                        </div>
                    </div>
                    : ''}

                    <div className={styles.createItem}>
                        <div className={styles.label}>Item Sub Type<span><Tooltip className={styles.tooltip} title={TooltipTitle.BundleSubType} icon="info"/></span></div>
                        <div className={styles.list}>
                            {itemSubType.map((x, index) => (
                                <Checkbox disable={editAccess} key={x.id} value={x.active} className={styles.checkbox} content={x.title} onChange={(e)=>{onSubType(e, x.title)}}/>
                            ))}
                        </div>
                    </div>

                    <div className={styles.createItem}>
                        <div className={styles.group}>
                            <TextInput disabled={editAccess} className={styles.field} value={maxCollectionInstances} label="Max Collection Instances" name="maxCollectionInstance" type="number" required onWheelCapture={e => {e.currentTarget.blur()}} onChange={(e)=>{onChangemaxCollectionInstance(e)}} />
                        {isStackable ?
                            <TextInput disabled={editAccess} tooltip={TooltipTitle.BundleMaxStack} className={styles.field} label="Stack Capacity" value={stacksCount} name="NumberOfStacks" type="number" required onWheelCapture={e => {e.currentTarget.blur()}} onChange={(e)=>{onChangeStacks(e)}} />
                        : ''}
                        </div>
                    </div>

                    <div className={styles.createItem}>
                        <div className={styles.label}>Quantity</div>
                        <div className={styles.list}>
                            <Checkbox disable={editAccess} className={styles.checkbox} content="Is Limited" value={isLimited} onChange={()=>{setIsLimited(!isLimited);setQuantity(null)}}/>
                        </div>
                    </div>
                    {isLimited &&
                    <div className={styles.createItem}>
                        <div className={styles.group}>
                            <TextInput disabled={editAccess} tooltip={TooltipTitle.BundleQuantity} className={styles.field} value={quantity} label="Set Quantity" name="setQuantity" type="number" required onWheelCapture={e => {e.currentTarget.blur()}} onChange={(e)=>{setQuantity(parseInt(e.target.value))}} />
                        </div>
                    </div>
                    }

                    <div className={styles.radioInput}>
                        <div className={styles.radioButton}>
                            <div className={styles.radioLabel}>
                                <span>Open Bundle<span><Tooltip className={styles.tooltip} title={TooltipTitle.BundleBy} icon="info"/></span></span>
                            </div>
                            <div className={styles.variants}>
                                <Radio disabled={editAccess} className={styles.radio} value={!bundle} name="open" onChange={() => setBundle(false)} content="Automatic" />
                                <Radio disabled={editAccess} className={styles.radio} value={bundle} name="open" onChange={() => setBundle(true)} content="Manual" />
                            </div>
                        </div>
                    </div>

                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Custom Data" classTitle="title-red">
                <div className={styles.cardDescription}>
                    <div className={styles.createItem}>
                        
                        <TagInputWithDropdown disabled={editAccess}  tooltip={TooltipTitle.BundleTags} tags={tags} setTags={setTags}/>

                        <div className={styles.groupHeading}>
                            <span>Meta Data</span><span><Tooltip className={styles.tooltip} title={TooltipTitle.BundleMetaData} icon="info"/></span>{!editAccess && <span className={styles.addIcon} onClick={addMetaData}><AddMetaIcon /></span>}
                        </div>

                        {metaData && metaData.length > 0 &&
                            <div className={styles.groupMeta}>
                                <div className={styles.field}>Key</div>
                                <div className={styles.field}>Value</div>
                            </div>
                        }

                        <div className={styles.groupMeta}>
                            {metaData && metaData.map((e,i)=>{
                                return(
                                    <>
                                        <TextInput disabled={editAccess} className={styles.field} label="" name="key" type="text" required value={(metaData[i].key != '' || metaData[i].key != undefined || metaData[i].key != null) ? metaData[i].key : ''} onChange={(e)=>{onChangeKey(e, i)}}/>
                                        <JSONMetaDataValueInput disabled={editAccess} key={i} className={styles.field} data={(typeof e.value == 'string') ? JSON.stringify(e.value) : e.value} value={metaData} setValue={setMetaData} index={i}/>
                                        {!editAccess && <span className={styles.remove} onClick={()=>{removeMetaData(i)}}><RemoveIcon className={styles.icon} /></span>}
                                    </>
                                )
                            })}
                        </div>

                    </div>
                </div>
            </Card>
            {!editAccess &&
                <Card className={cn(styles.card, className)}>
                    <div className={styles.cardAction}>
                        <div className={styles.cardSave}>
                            <button className={cn(styles.button, 'button-save-small')} onClick={edit}>Save</button>
                        </div>
                        <div className={styles.cardCancel}>
                            <button className={cn(styles.button, 'button-cancel-small')} onClick={cancel}>Cancel</button>
                        </div>
                    </div>
                </Card>
            }
        </>
    )
}

export default BundleInformation