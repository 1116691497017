import react, { useEffect, useState } from 'react'
import Card from '../../../components/Card'
import cn from 'classnames'
import styles from './Matches.module.sass'
import {ReactComponent as EmptyFolder} from '../../../media/images/placeholders/emptyFolder.svg'
import MatchPlaceHolder from '../../../media/images/placeholders/Mission.png'
import moment from 'moment'
import { Link, useParams } from 'react-router-dom'
import SnapShot from '../../../components/Images'
import { useDispatch } from 'react-redux'

const Matches = ({className, data}) => {

    const {appid} = useParams()
    const dispatch = useDispatch()

    if(data){
        return(
            <>
                <Card className={cn(styles.card, className)} title='Matches' classTitle="title-yellow">
                    <div className={styles.gamesContainer}>

                        {data && data.length == 0 &&
                            <div className={styles.games}>
                                <div className={styles.emptyState}>
                                    <div className={styles.emptyFolder}><EmptyFolder /></div>
                                    <div className={styles.emptyTitle}><span>You have no <Link className={styles.all} to={`/matches/${appid}`}>Matches</Link> yet!</span></div>
                                </div>
                            </div>
                        }

                        {data && data.length > 0 &&
                            <>
                                <div className={styles.wrapper}>
                                    <div className={styles.table}>
                                        {data && data.slice(0, 6).map((e, index) => (
                                            <>
                                                <div className={styles.tableRow}>
                                                    <div className={styles.tableCol}>
                                                        <SnapShot id={`matchIcon_${e.id}`} url={e.logo} alt={e.name} placeholder={MatchPlaceHolder} type='Images' width={24} height={24}/>
                                                        {/* <img src={(e.iconUrl && e.iconUrl != null && e.iconUrl != '') ? e.iconUrl : MatchPlaceHolder} alt={`${e.name}`} /> */}
                                                    </div>
                                                    <div className={styles.tableCol}><div className={styles.overflowText}>{(e.name && e.name != '' && e.name != null) ? e.name : '-'}</div></div>
                                                    <div className={styles.tableCol}><div className={styles.createdAt}>Created At</div><div className={styles.date}>{(e.createdAt && e.createdAt != null) ?  moment(e.createdAt).format('DD/MM/YYYY hh:mm A') : '-'}</div></div>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                </div>
                                <div className={styles.viewAll}>
                                    <Link className={styles.all} to={`/matches/${appid}`}>View All</Link>
                                </div>
                            </>
                        }

                    </div>
                </Card>
            </>
        )
    }
}

export default Matches