import React, { useState } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../Checkbox";
import Icon from "../../../../Icon";
import Control from "./Control";
import { useParams } from "react-router-dom";
import App from '../../../../../media/images/placeholders/App.png'
import MemberPermissionDropdown from "../../MemberPermissionDropdown";
/* Deleted Schedule, Modal, Modal Products and Actions */
import UserProfile from '../../../../../media/images/placeholders/Profile_Placeholder.png'
import Tooltip from "../../../../CustomTooltips/ReusableTooltip";
import SnapShot from "../../../../Images";

const Row = ({type, item, value, index, onChange, to, apps, setApps, userType }) => {

    const {appid} = useParams()
    const [permissionOption, setPermissionOption] = useState([{id : 1, name : 'View Only'},{id : 2, name : 'Can Edit'}])
    const [permissionId, setPermissionId] = useState(appid && (item.permissions && item.permissions?.length > 0 && (item.permissions && item.permissions.find(item => item.projectId === appid))?.permissionId === 1) ? 1 : appid && (item.permissions && item.permissions.length > 0 && (item.permissions && item.permissions.find(item => item.projectId === appid))?.permissionId === 2) ? 2  : null)
    const [permission, setPermission] = useState(appid && (item.permissions && item.permissions?.length > 0 && (item.permissions && item.permissions.find(item => item.projectId === appid))?.permissionId === 1) ? 'View Only' : appid && (item.permissions && item.permissions.length > 0 && (item.permissions && item.permissions.find(item => item.projectId === appid))?.permissionId === 2) ? 'Can Edit' : '')

    return (
        <>
            {to == 'accepted' &&
                <div className={styles.row}>
                    <div className={styles.col}>
                    {Object.keys(item).length !== 0 &&
                        <div className={styles.player}>
                            <div className={styles.memberIcon}>
                                <SnapShot id={`memberIcon_accepted_${item.id}`} url={item?.profileUrl} placeholder={UserProfile} width={32} height={32} type="Images" />
                                {/* <img src={(item.memberDetails != null && item.memberDetails.profile_url != null) ? item.memberDetails.profile_url : UserProfile} alt={item.name} width={32} height={32}/> */}
                            </div>
                            <div className={styles.playerDetails}>
                                <div className={styles.userName}>{(item.name != null) ? item.name : '-'}</div>
                                <div className={styles.playerId}>{(item.email != null) ? item.email : '-'}</div>
                            </div>
                        </div>
                    }
                    </div>
                    <div className={styles.col}>{item.active == true ? <div className={styles.price}>Active</div> : item.active == false ? <div className={styles.price}>InActive</div> : ''}</div>
                    <div className={styles.col}>
                        <div className={styles.appIcon}>
                            {item.permissions && item.permissions.slice(0,5).map((e,i) => (
                                <>
                                    <Tooltip text={e.projectName}>
                                    {/* {(e.projectId != null && e.iconUrl != null) ?  : (e.projectId != null && e.iconUrl == null) ? <img src={App} width={32} height={32} alt={e.name}/>  : ''} */}
                                        <SnapShot id={`appIcon_accepted_${index}_${e.projectId}`} url={e.iconUrl} placeholder={App} width={32} height={32} type="Images" />
                                    </Tooltip>
                                </>
                            ))}
                            {(item.permissions && item.permissions.length > 5) ? <Tooltip type='' text='Apps' data={item.permissions.map((e)=>{return {name:e.projectName}})}><span className={styles.count}>+ {item.permissions.length - 5}</span></Tooltip>  
                            : ''}
                        </div>
                    </div>
                    <div className={styles.col}>
                        {Object.keys(item).length !== 0 &&
                            <Control memberAction='edit' to={to} className={styles.control} memberEmail={(item && item?.email != null && item?.email != undefined) ? item?.email : null} id={(item && item.id) ? item.id : null} apps={apps} setApps={setApps} permissions={item.permissions}/>
                        }
                    </div>
                </div>
            }
            {to == 'invited' &&
                <div className={styles.row}>
                    <div className={styles.col}>
                        {Object.keys(item).length !== 0 &&
                            <div className={styles.player}>
                                <div className={styles.memberIcon}>
                                    <SnapShot id={`memberIcon_invited_${item.id}`} url={item?.memberDetails?.profile_url} placeholder={UserProfile} width={32} height={32} type="Images" />
                                    {/* <img src={UserProfile} alt={item.name}/> */}
                                </div>
                                <div className={styles.playerDetails}>
                                    <div className={styles.userName}>{item.name ? item.name : '-'}</div>
                                    <div className={styles.playerId}>{item.email ? item.email : '-'}</div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={styles.col}>{item.active == true ? <div className={styles.price}>Active</div> : item.active == false ? <div className={styles.price}>InActive</div> : ''}</div>
                    <div className={styles.col}>
                        <div className={styles.appIcon}>
                            {item.permissions && item.permissions.slice(0,5).map((e,i) => (
                                <>
                                    <Tooltip text={e.projectName}>
                                        <SnapShot id={`appIcon_invited_${index}_${e.projectId}`} url={e.iconUrl} placeholder={App} width={32} height={32} type="Images" />
                                        {/* {(e.projectId != null && e.iconUrl != null) ? <img src={e.iconUrl} width={32} height={32} alt={e.name}/> : (e.projectId != null && e.iconUrl == null) ? <img src={App} width={32} height={32} alt={e.name}/>  : ''} */}
                                    </Tooltip>
                                </>
                            ))}
                            {(item.permissions && item.permissions.length > 5) ? <Tooltip type='' text='Apps' data={item.permissions.map((e)=>{return {name:e.projectName}})}><span className={styles.count}>+ {item.permissions.length - 5}</span></Tooltip>  
                            : ''}
                        </div>
                    </div>
                    <div className={styles.col}>
                    {Object.keys(item).length !== 0 &&
                        <Control memberAction='edit' to={to} className={styles.control} memberEmail={(item && item?.memberDetails?.email != null && item?.memberDetails?.email != undefined) ? item?.memberDetails?.email : null} id={(item && item?.memberDetails?.id != null && item?.memberDetails?.id != undefined) ? item?.memberDetails?.id : null} apps={apps} setApps={setApps} permissions={item.permissions}/>
                    }
                    </div>
                </div>
            }
        </>
    );
};

export default Row;
