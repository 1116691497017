import React, { useState, useEffect } from 'react'
import styles from './tooltip.module.sass'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import { ReactComponent as  ServerIcon} from "../../../media/images/icons/server_icon.svg";
import OutsideClickHandler from 'react-outside-click-handler';
import OrgProfile from '../../../media/images/placeholders/Profile_Placeholder.png';
import SnapShot from '../../Images';

const Tooltip = ({className, wide, xw, size, data, children, showLoader, setShowLoader, setLoaderType }) => {

    const [visible, setVisible] = useState(false);
    const Navigate = useNavigate()

    const onChangeWorkSpace = (org) => {
        localStorage.setItem('orgId', org.id)
        localStorage.setItem('orgName', JSON.stringify(org.name))
        sessionStorage.setItem('gstTreatment', JSON.stringify(org?.gst_treatment))
        sessionStorage.setItem('location', JSON.stringify(org?.location))
        localStorage.setItem('apiKeys', JSON.stringify(org.api_keys))
        sessionStorage.setItem('customerId', JSON.stringify(org.external_customer_id))
        loaderConfigure(2)
        Navigate('/home') 
    }

    const loaderConfigure = (loaderType) => {
        setLoaderType(loaderType)
        setShowLoader(true)
        setTimeout(() => {
            setShowLoader(false)
        }, 4000)
    }

    return (
       <>
            <OutsideClickHandler  onOutsideClick={() => setVisible(false)}>
                <div className={cn(styles.custom, className, {[styles.active] : visible}, {[styles.wide] : wide}, {[styles.xw] : xw})}>
                    <div className={styles.children} onClick={() => setVisible(!visible)}>{children}</div>
                        <div className={styles.tooltipContainer}>
                            {data && data.map((x, index) => (
                                <>
                                    <div className={cn(styles.org, {[styles.active] : localStorage.getItem('orgId') === x.id})} onClick={() => onChangeWorkSpace(x)}>
                                        <div className={styles.logo}>
                                            <SnapShot id={`organisation_${xw}_${x.id}_${index}`} url={x.default_logo_url} alt={x.name} placeholder={OrgProfile} type='Images' width={28} height={28}/>
                                        </div>
                                        <div className={styles.title}>{x.name}</div>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
            </OutsideClickHandler>
       </>
    )
}

export default Tooltip