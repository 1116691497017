import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from './fileImport.module.sass'
import Icon from "../../../../Icon";
import CurrencyPlaceHolder from '../../../../../media/images/placeholders/Currency.png'
import ItemPlaceHolder from '../../../../../media/images/placeholders/Items.png'
import StorePlaceHolder from '../../../../../media/images/placeholders/Store.png'
import BundlePlaceHolder from '../../../../../media/images/placeholders/Bundles.png'
import {ReactComponent as Success} from '../../../../../media/images/icons/success.svg'
import {ReactComponent as Error} from '../../../../../media/images/icons/error.svg'
import ErrorTooltip from '../../../../../components/CustomTooltips/ErrorsTooltip'
import SnapShot from "../../../../Images";

const FileImport = ({className, type, data, setData}) => {

    const [activeIndexes, setActiveIndexes] = useState([0])
    const [categoryActiveIndex, setCategoryActiveIndex] = useState([0])
    const [contentActiveIndex, setContentActiveIndex] = useState([0])

    const [editIndex, setEditIndex] = useState(null);
    const [subEditIndex, setSubEditIndex] = useState(null);
    const [section, setSection] = useState(null)
    const [segment, setSegment] = useState(null)
    const [editField, setEditField] = useState(null);
    const [editValue, setEditValue] = useState('');
    const [show, setShow] = useState(type === 'Items' ? 'itemPrices' : type === 'Bundles' ? 'bundlePrices' : '')

    const handleEditElement = (index, subIndex, field, type) => {
        setEditIndex(index)
        setSubEditIndex(null)
        setEditField(field)
        if(field === 'itemType'){
            setEditValue(data[index][field] === true ? 'Consumable' : data[index][field] === false ? 'Durable' : data[index][field])
        }else if(field === 'isDefaultLoadout' || editField === 'equippedByDefault'){
            setEditValue(data[index][field] === true ? 'Yes' : data[index][field] === false ? 'No' : data[index][field])
        }
        else{
            setEditValue(data[index][field]);
        }
    }

    const onSaveElement = () => {
        const newData = [...data];
        if(editField === 'isDefaultLoadout' || editField === 'equippedByDefault'){
            newData[editIndex][editField] = ((editValue === 'Yes' || editValue === 'yes') ? true : (editValue === 'No' || editValue === 'no') ? false : editValue)
        }
        else if(editField === 'consumeByUses' || editField === 'maxCollectionInstance' || editField === 'stackCapacity' || editField === 'consumeByTime' || editField === 'quantity'){
            newData[editIndex][editField] = parseValidInt(editValue)
        }else if(editField === 'itemType'){
            newData[editIndex][editField] = ((editValue === 'Consumable' || editValue === 'consumable') ? true : (editValue === 'Durable' || editValue === 'durable') ? false : editValue);
        }
        else{
            newData[editIndex][editField] = editValue;
        }
        setData(newData)
        setEditIndex(null)
        setEditField(null)
        setSubEditIndex(null)
    }

    const handleEditPricing = (index, i, field, type, key) => {
        setEditIndex(index);
        setSubEditIndex(i)
        setEditField(field);
        if(field === 'gamePlatformMasterId'){
            setEditValue(data[index][key][i][field] === 1 ? 'Android' : data[index][key][i][field] === 2 ? 'iOS' : data[index][key][i][field]);
        }else{
            setEditValue(data[index][key][i][field]);
        }
    }

    const onSavePricing = (key) => {
        const newData = [...data]
        if(editField === 'bonusCashAllowance' || editField === 'discount'){
            newData[editIndex][key][subEditIndex][editField] = parseValidInt(editValue);
        }else if(editField === 'gamePlatformMasterId'){
            newData[editIndex][key][subEditIndex][editField] = editValue === 'Android' ? 1 : editValue === 'iOS' ? 2 : editValue;
        }
        else{
            newData[editIndex][key][subEditIndex][editField] = editValue;
        }
        setData(newData)
        setEditIndex(null)
        setEditField(null)
        setSubEditIndex(null)
    }

    const handleEditLockBy = (index, i, field, type) => {
        setEditIndex(index);
        setSubEditIndex(i)
        setEditField(field);
        setEditValue(data[index].rewardUnlockCondition[i][field]);
    }

    const onSaveLockBy = () => {
        const newData = [...data];
        if(editField === ''){
            newData[editIndex].rewardUnlockCondition[subEditIndex][editField] = parseValidInt(editValue);
        }else{
            newData[editIndex].rewardUnlockCondition[subEditIndex][editField] = editValue;
        }
        setData(newData)
        setEditIndex(null)
        setEditField(null)
        setSubEditIndex(null)
    }

    const handleEditContent = (index, i, field, type) => {
        setEditIndex(index);
        setSubEditIndex(i)
        setEditField(field);
        setEditValue(data[index].bundleContent[i][field]);
    }

    const onSaveContent = () => {
        const newData = [...data];
        newData[editIndex].bundleContent[subEditIndex][editField] = editValue;
        setData(newData)
        setEditIndex(null)
        setEditField(null)
        setSubEditIndex(null)
    }

    const handleEdit = (index, i, field, type) => {
        if(type === 'pricing'){
            setEditIndex(index);
            setSubEditIndex(i)
            setEditField(field);
            setEditValue(data[index].itemPrices[i][field]);
        }else if(type === 'lockBy'){
            setEditIndex(index);
            setSubEditIndex(i)
            setEditField(field);
            setEditValue(data[index].rewardUnlockCondition[i][field]);
        }else if(type === 'contents'){
            setEditIndex(index);
            setSubEditIndex(i)
            setEditField(field);
            setEditValue(data[index].bundleContent[i][field]);
        }else{
            setEditIndex(index);
            setSubEditIndex(null)
            setEditField(field);
            setEditValue(data[index][field]);
        }
    }
    
    const handleEditStore = (storeIndex, categoryIndex, contentIndex, pricingIndex, field, type) => {
        if(type === 'pricing'){
            setEditField(field)
            setEditIndex(storeIndex)
            setSubEditIndex(categoryIndex)
            setSection(contentIndex)
            setSegment(pricingIndex)
            setEditValue(data[storeIndex].storeCategories[categoryIndex].storeContents[contentIndex].storeContentPrice[pricingIndex][field])
        }else if(type === 'content'){
            setEditField(field)
            setEditIndex(storeIndex)
            setSubEditIndex(categoryIndex)
            setSection(contentIndex)
            setSegment(pricingIndex)
            setEditValue(data[storeIndex].storeCategories[categoryIndex].storeContents[contentIndex][field])
        }else if(type === 'category'){
            setEditField(field)
            setEditIndex(storeIndex)
            setSubEditIndex(categoryIndex)
            setSection(contentIndex)
            setSegment(pricingIndex)
            setEditValue(data[storeIndex].storeCategories[categoryIndex][field])
        }else{
            setEditField(field)
            setEditIndex(storeIndex)
            setSubEditIndex(categoryIndex)
            setSection(contentIndex)
            setSegment(pricingIndex)
            setEditValue(data[storeIndex][field])
        }
    }

    const handleSavePricing = () => {
        if(editField === 'bonusCashAllowance' || editField === 'discount'){
            const newData = [...data];
            newData[editIndex].itemPrices[subEditIndex][editField] = parseInt(editValue);
            setData(newData)
            setEditIndex(null)
            setEditField(null)
            setSubEditIndex(null)
        }else if(editField === 'gamePlatformMasterId'){
            const newData = [...data];
            newData[editIndex].itemPrices[subEditIndex][editField] = editValue === 'Android' ? 1 : editValue === 'iOS' ? 2 : editValue;
            setData(newData);
            setEditIndex(null);
            setEditField(null);
            setSubEditIndex(null)
        }
        else{
            const newData = [...data];
            newData[editIndex].itemPrices[subEditIndex][editField] = editValue;
            setData(newData)
            setEditIndex(null)
            setEditField(null)
            setSubEditIndex(null)
        }
    }
    
    const handleSaveRewards = () => {
        const newData = [...data];
        newData[editIndex].rewardUnlockCondition[subEditIndex][editField] = editValue;
        setData(newData)
        setEditIndex(null)
        setEditField(null)
        setSubEditIndex(null)
    }

    const handleSaveContent = () => {
        const newData = [...data];
        newData[editIndex].bundleContent[subEditIndex][editField] = editValue;
        setData(newData)
        setEditIndex(null)
        setEditField(null)
        setSubEditIndex(null)
    }

    const parseValidInt = (str) => {
        if (!isNaN(str) && !isNaN(parseInt(str))) {
          return parseInt(str);
        }
        return str;
    }

    const handleSaveStore = async(type, category) => {
        if(type === 'Pricing'){
            const newData = [...data];
            newData[editIndex].storeCategories[subEditIndex].storeContents[section].storeContentPrice[segment][editField] = ((editField === 'price' || editField === 'discount' || editField === 'bonusCashAllowance') ? parseValidInt(editValue): editField === 'gamePlatformMasterId' ? (editValue === 'Android' ? 1 : editValue === 'iOS' ? 2 : editValue) : editValue);
            setData(newData)
            setEditIndex(null)
            setEditField(null)
            setSubEditIndex(null)
            setSection(null)
            setSegment(null)
        }else if(type === 'Content'){
            const newData = [...data];
            newData[editIndex].storeCategories[subEditIndex].storeContents[section][editField] = editValue;
            setData(newData)
            setEditIndex(null)
            setEditField(null)
            setSubEditIndex(null)
            setSection(null)
            setSegment(null)
        }else if(type === 'Store'){
            const newData = [...data];
            newData[editIndex][editField] = editValue;
            setData(newData)
            setEditIndex(null)
            setEditField(null)
            setSubEditIndex(null)
            setSection(null)
            setSegment(null)
        }else if(type === 'category'){
            const newData = [...data];
            let matchCategory = newData[editIndex].storeCategories && newData[editIndex].storeCategories.find(cat => cat.storeCategoryId === editValue)
            if(matchCategory && matchCategory?.errors?.catIdErr?.length === 0){
                let categoryToRemove = newData[editIndex].storeCategories.find(cat => cat.storeCategoryId === category);
                if(categoryToRemove){
                    const updatedCategories = newData[editIndex].storeCategories && newData[editIndex].storeCategories.map(cat => {
                        if (cat.storeCategoryId === editValue) {
                          return {
                            ...cat,
                            storeContents: [...cat.storeContents, ...categoryToRemove.storeContents] // Append all prices, including duplicates
                          };
                        }
                        return cat;
                    });
                    // Remove the selected category
                    const filteredCategories = updatedCategories && updatedCategories.filter(cat => cat.storeCategoryId !== category);
                    newData[editIndex].storeCategories = filteredCategories
                    setData(newData)
                    setEditIndex(null)
                    setEditField(null)
                    setSubEditIndex(null)
                    setSection(null)
                    setSegment(null)
                }
            }else{
                newData[editIndex].storeCategories[subEditIndex][editField] = editValue;
                setData(newData)
                setEditIndex(null)
                setEditField(null)
                setSubEditIndex(null)
                setSection(null)
                setSegment(null)
            }
        }
    }

    const handleChange = (e) => {
        let {name, value} = e.target
        setEditValue(value)
    }

    const toggleAccordion = (index, type) => {
        setShow(type)
        setActiveIndexes([index])
    }

    const toggleCategoryAccordion = (index) => {
        setCategoryActiveIndex([index])
    }

    const toggleContentAccordion = (index) => {
        setContentActiveIndex([index])
    }

    const checkCurrencyError = (obj) => {
        for (const key in obj.errors) {
            if (obj.errors[key].length > 0) {
              return false;
            }
        }
        return true
    }

    const checkFieldError = (obj, type) => {
        let pricingKey = type === 'Items' ? 'itemPrices' : type === 'Bundles' ? 'bundlePrices' : ''
        for (const key in obj.errors) {
            if (obj.errors[key].length > 0) {
              return false;
            }
        }
        // Check errors in each itemPrices object
        for (const item of obj[pricingKey]) {
            for (const key in item.errors) {
              if (item.errors[key].length > 0) {
                return false;
              }
            }
        }
        if(type === 'Bundles'){
            for (const item of obj.bundleContent) {
                for (const key in item.errors) {
                  if (item.errors[key].length > 0) {
                    return false;
                  }
                }
            }
        }
        for (const item of obj.rewardUnlockCondition) {
            for (const key in item.errors) {
              if (item.errors[key].length > 0) {
                return false;
              }
            }
        }
        return true;
    }

    const checkStoreContent = (obj) => {
        if (obj.errors) {
            for (let key in obj.errors) {
              if (obj.errors[key].length !== 0) {
                return false;
              }
            }
        }
        // Iterate over all properties of the object
        for (let key in obj) {
            if (Array.isArray(obj[key])) {
              // If the property is an array, check each element
              for (let item of obj[key]) {
                if (!checkStoreContent(item)) {
                  return false;
                }
              }
            }else if (typeof obj[key] === 'object' && obj[key] !== null) {
              // If the property is an object, recurse into it
              if (!checkStoreContent(obj[key])) {
                return false;
              }
            }
        }
        return true;
    }

    const [errorList, setErrorList] = useState([])

    const checkCurrencyErrors = (errors) => {
        const errorTypes = {iconErr: 'Currency Icon', nameErr: 'Display Name', idErr: 'Currency ID', codeErr: 'Currency Code', typeErr: 'Currency Type', descriptionErr: 'Currency Description'}
        let list = Object.keys(errors).filter((key) => errors[key].length > 0).map((key) => errorTypes[key])
        let errorDetails = `Error in ${list.join(', ')}`
        setErrorList([errorDetails])
    }

    const checkItemErrors = (e) => {
        const errorMessages = new Set();
        const mainErrors = {iconErr: 'Item Icon', nameErr: 'Display Name', idErr: 'Item ID', descriptionErr: 'Item Description', typeErr: 'Item Type', byErr: 'Consume By', countErr: 'Consume By Uses', timeErr: 'Consume By Time', unitErr: 'Time Format', subTypeErr: 'Item Subtype', isLimitedErr: 'Is Limited', quantityErr: 'Limited Quantity', maxInstanceErr:'Max Instance', stackErr:'Stack Capacity', defaultLoadOutErr:'Default Load-Out', equippedByDefaultErr:'Equip By Default'}
        const pricingErrors = {currencyTypeErr: 'Currency Type', currencyIdErr: 'Currency ID', realWorldCurrencyErr: 'Real World Currency', amountErr: 'Amount', discountErr: 'Discount', BCAErr: 'Bonus Cash Allowance', platformErr: 'Platform', productErr: 'Product'}
        const lockByErrors = {itemIdErr: 'Item Id', lockItemIdErr: 'Unlock Item ID', lockBundleIdErr: 'Unlock Bundle ID', lockPSError: 'Unlock PS ID', lockPSLevelErr: 'Unlock PS Level'}
        const extractErrors = (errors, typeMap) => {
            Object.keys(errors).filter((key) => errors[key].length > 0).forEach((key) => errorMessages.add(typeMap[key]));
        }
        // Collect errors from the main object
        extractErrors(e.errors, mainErrors)
        // Collect errors from each item in itemPrices
        e.itemPrices.forEach((item) => {
            extractErrors(item.errors, pricingErrors);
        })
        e.rewardUnlockCondition.forEach((item) => {
            extractErrors(item.errors, lockByErrors);
        })
        let allErrors = Array.from(errorMessages)
        let errorDetails = `Error in ${allErrors.join(', ')}`
        setErrorList([errorDetails])
    }

    const checkBundleErrors = (e) => {
        const errorMessages = new Set();
        const mainErrors = {iconErr: 'Bundle Icon', nameErr: 'Display Name', idErr: 'Bundle ID', descriptionErr: 'Bundle Description', typeErr: 'Bundle Type', byErr: 'Consume By', countErr: 'Consume By Uses', timeErr: 'Consume By Time', unitErr: 'Time Format', subTypeErr: 'Bundle Subtype', isLimitedErr: 'Is Limited', quantityErr: 'Limited Quantity', maxInstanceErr:'Max Instance', stackErr:'Stack Capacity', defaultLoadOutErr:'Default Load-Out', equippedByDefaultErr:'Equip By Default'}
        const pricingErrors = {currencyTypeErr: 'Currency Type', currencyIdErr: 'Currency ID', realWorldCurrencyErr: 'Real World Currency', amountErr: 'Amount', discountErr: 'Discount', BCAErr: 'Bonus Cash Allowance', platformErr: 'Platform', productErr: 'Product'}
        const lockByErrors = {bundleIdErr: 'Bundle Id', lockItemIdErr: 'Unlock Item ID', lockBundleIdErr: 'Unlock Bundle ID', lockPSError: 'Unlock PS ID', lockPSLevelErr: 'Unlock PS Level'}
        const contentErrors = {currencyIdErr: 'Content Currency ID', itemIdErr: 'Content Item ID', bundleIdErr: 'Content Bundle ID', contentQuantityErr: 'Quantity'}
        const extractErrors = (errors, typeMap) => {
            Object.keys(errors).filter((key) => errors[key].length > 0).forEach((key) => errorMessages.add(typeMap[key]));
        }
        // Collect errors from the main object
        extractErrors(e.errors, mainErrors)
        // Collect errors from each item in itemPrices
        e.bundlePrices.forEach((item) => {
            extractErrors(item.errors, pricingErrors);
        })
        e.rewardUnlockCondition.forEach((item) => {
            extractErrors(item.errors, lockByErrors);
        })
        e.bundleContent.forEach((item) => {
            extractErrors(item.errors, contentErrors);
        })
        let allErrors = Array.from(errorMessages)
        let errorDetails = `Error in ${allErrors.join(', ')}`
        setErrorList([errorDetails])
    }

    const storeErrors = (e) => {
        const errorMessages = new Set();
        const mainErrors = {iconErr: 'Store Icon', nameErr: 'Display Name', idErr: 'Store ID', descriptionErr: 'Store Description', platformErr: 'Store Platform', locationErr: 'Store Location'}
        const catError = {catIdErr : 'Store Category'}
        const contentErrors = {contentIdErr : 'Content ID', itemIdErr: 'Content Item ID', bundleIdErr: 'Content Bundle ID', contentQuantityErr: 'Content Quantity'}
        const contentPricingErrors = {currencyTypeErr: 'Currency Type', currencyIdErr: 'Currency ID', realWorldCurrencyErr: 'Real World Currency', amountErr: 'Amount', discountErr: 'Discount', BCAErr: 'Bonus Cash Allowance', platformErr: 'Platform', productErr: 'Product'}
        const lockByErrors = {bundleIdErr: 'Bundle Id', lockItemIdErr: 'Unlock Item ID', lockBundleIdErr: 'Unlock Bundle ID', lockPSError: 'Unlock PS ID', lockPSLevelErr: 'Unlock PS Level'}

        const extractErrors = (errors, typeMap) => {
            Object.keys(errors).filter((key) => errors[key].length > 0).forEach((key) => errorMessages.add(typeMap[key]));
        }
        // Collect errors from the main object
        extractErrors(e.errors, mainErrors)
        // Collect errors from the Content
        e.storeCategories && e.storeCategories.map(x => {
            extractErrors(x.errors, catError)
            x.storeContents && x.storeContents.map(z => {
                extractErrors(z.errors, contentErrors)
                z.storeContentPrice && z.storeContentPrice.map(item => {
                    extractErrors(item.errors, contentPricingErrors)
                })
            })
        })
        // Collect errors from the Lock By
        e.rewardUnlockCondition && e.rewardUnlockCondition.forEach((item) => {
            extractErrors(item.errors, lockByErrors)
        })
        let allErrors = Array.from(errorMessages)
        let errorDetails = `Error in ${allErrors.join(', ')}`
        setErrorList([errorDetails])
    }

    return(
        <>
            <div className={styles.wrapper}>
               <div className={styles.container}>
                    {type === 'Currency' &&
                        <div className={styles.tableWrapper}>
                            <div className={styles.table}>
                                <div className={styles.collapsableRow}>
                                    <table className={styles.tabularDataOutput}>
                                        <thead className={styles.tableHead}>
                                            <th className={styles.heading}></th>
                                            <th className={styles.heading}></th>
                                            <th className={styles.heading}>Icon</th>
                                            <th className={styles.heading}>Display Name</th>
                                            <th className={styles.heading}>ID</th>
                                            <th className={styles.heading}>Currency Code</th>
                                            <th className={styles.heading}>Currency Type</th>
                                        </thead>
                                        <tbody className={styles.tableBody}>
                                        {data && data.map((e, index) => {
                                            return(
                                                <>
                                                    <tr className={styles.tableRow}>
                                                        <td className={styles.tableCol}>
                                                            {checkCurrencyError(e) ? 
                                                                <Success /> 
                                                            :
                                                                <div onMouseEnter={() => checkCurrencyErrors(e.errors)}><ErrorTooltip position='left' errors={errorList}><Error /></ErrorTooltip></div>
                                                            }
                                                        </td>
                                                        <td className={styles.tableCol}>{index+1}</td>
                                                        <td className={styles.tableCol}>
                                                            {/* <div className={styles.icon}>
                                                                {(e.iconUrl !== null && e.iconUrl !== 'N/A' && e.iconUrl !== '') ? <img src={e.iconUrl} width={32} height={32}/> : (e.iconUrl === null || e.iconUrl === 'N/A' || e.iconUrl === '') ?  <img src={CurrencyPlaceHolder} width={32} height={32}/> : ((e.iconUrl === null || e.iconUrl === 'N/A' || e.iconUrl === '') && type === 'Bundles') ?  <img src={BundlePlaceHolder} width={32} height={32}/> : ''}
                                                            </div> */}
                                                            <div className={styles.icon}>
                                                                <SnapShot id={`currency_${e?.name}_${index}`} url={e.iconUrl} alt={e?.name} placeholder={CurrencyPlaceHolder} width={28} height={28} type="Images" /> 
                                                            </div>
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {editIndex === index && editField === 'name' ? 
                                                                <>
                                                                    <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='name'/></div><div><button className={styles.save} onClick={onSaveElement}>Save</button></div></div>
                                                                </>
                                                            :
                                                                <>
                                                                    <div className={styles.error}><ErrorTooltip position='bottom-left' errors={e.errors.nameErr}><div className={styles.content}>{e.name}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEdit(index, null, 'name', 'item')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                </>
                                                            }
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {editIndex === index && editField === 'currencyId' ? 
                                                                <>
                                                                    <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='currencyId'/></div><div><button className={styles.save} onClick={onSaveElement}>Save</button></div></div>
                                                                </>
                                                            :
                                                                <>
                                                                    <div className={styles.error}><ErrorTooltip position='bottom-left' errors={e.errors.idErr}><div className={e.errors.idErr?.length > 0 ? styles.title : styles.content}>{e.currencyId}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEdit(index, null, 'currencyId', 'item')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                </>
                                                            }
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {editIndex === index && editField === 'code' ? 
                                                                <>
                                                                    <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='code'/></div><div><button className={styles.save} onClick={onSaveElement}>Save</button></div></div>
                                                                </>
                                                            :
                                                                <>
                                                                    <div className={styles.error}><ErrorTooltip position='bottom-left' errors={e.errors.codeErr}><div className={e.errors.codeErr?.length > 0 ? styles.title : styles.content}>{e.code}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEdit(index, null, 'code', 'item')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                </>
                                                            }
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {editIndex === index && editField === 'type' ? 
                                                                <>
                                                                    <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='type'/></div><div><button className={styles.save} onClick={onSaveElement}>Save</button></div></div>
                                                                </>
                                                            :
                                                                <>
                                                                    <div className={styles.error}><ErrorTooltip position='bottom-left' errors={e.errors.typeErr}><div className={e.errors.typeErr?.length > 0 ? styles.title : styles.content}>{e.type}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEdit(index, null, 'type', 'item')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                </>
                                                            }
                                                        </td>
                                                    </tr>
                                                </>
                                            )
                                        })}           
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }
                    {type === 'Items' &&
                        <div className={styles.tableWrapper}>
                            <div className={styles.table}>
                                <div className={styles.collapsableRow}>
                                    <table className={styles.tabularDataOutput}>
                                        <thead className={styles.tableHead}>
                                            <th className={styles.heading}></th>
                                            <th className={styles.heading}></th>
                                            <th className={styles.heading}>Icon</th>
                                            <th className={styles.heading}>Display Name</th>
                                            <th className={styles.heading}>ID</th>
                                            <th className={styles.heading}>Type</th>
                                            <th className={styles.heading}>Subtype</th>
                                            <th className={styles.heading}>Consume By Uses</th>
                                            <th className={styles.heading}>Consume By Time</th>
                                            <th className={styles.heading}>Time Format</th>
                                            <th className={styles.heading}>Max Collection Instance</th>
                                            <th className={styles.heading}>Stack Capacity</th>
                                            <th className={styles.heading}>Limited Quantity</th>
                                            <th className={styles.heading}>Price</th>
                                            <th className={styles.heading}>Lock By</th>
                                            <th className={styles.heading}>Default Load Out</th>
                                            <th className={styles.heading}>Equip By Default</th>
                                        </thead>
                                        <tbody className={styles.tableBody}>
                                        {data && data.map((e, index) => {
                                            return(
                                                <>
                                                    <tr className={styles.tableRow}>
                                                        <td className={styles.tableCol}>
                                                            {checkFieldError(e, 'Items') ? 
                                                                <Success /> 
                                                            : 
                                                            <div onMouseEnter={() => checkItemErrors(e)}><ErrorTooltip position='left' errors={errorList}><Error /></ErrorTooltip></div>
                                                            }
                                                        </td>
                                                        <td className={styles.tableCol}>{index+1}</td>
                                                        <td className={styles.tableCol}>
                                                            {/* <div className={styles.icon}>
                                                                {(e.iconUrl !== null && e.iconUrl !== 'N/A' && e.iconUrl !== '') ? <img src={e.iconUrl} width={32} height={32}/> : ((e.iconUrl === null || e.iconUrl === 'N/A' || e.iconUrl === '') && type === 'Items') ?  <img src={ItemPlaceHolder} width={32} height={32}/> : ((e.iconUrl === null || e.iconUrl === 'N/A' || e.iconUrl === '') && type === 'Bundles') ?  <img src={BundlePlaceHolder} width={32} height={32}/> : ''}
                                                            </div> */}
                                                            <div className={styles.icon}>
                                                                <SnapShot id={`items_${e?.name}_${index}`} url={e.iconUrl} alt={e?.name} placeholder={ItemPlaceHolder} width={28} height={28} type="Images" /> 
                                                            </div>
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {editIndex === index && editField === 'name' ? 
                                                                <>
                                                                    <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='name'/></div><div><button className={styles.save} onClick={onSaveElement}>Save</button></div></div>
                                                                </>
                                                            :
                                                                <>
                                                                    <div className={styles.error}><ErrorTooltip position='bottom-left' errors={e.errors.nameErr}><div className={styles.content}>{e.name}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditElement(index, null, 'name', 'item')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                </>
                                                            }
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {editIndex === index && editField === 'itemId' ? (
                                                                <>
                                                                    <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='itemId'/></div><div><button className={styles.save} onClick={onSaveElement}>Save</button></div></div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className={styles.error}><ErrorTooltip position='bottom-left' errors={e.errors.idErr}><div className={e.errors.idErr?.length > 0 ? styles.title : styles.content}>{e.itemId}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditElement(index, null, 'itemId', 'item')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                </>
                                                            )}
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {editIndex === index && editField === 'itemType' ? (
                                                                <>
                                                                    <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='itemType'/></div><div><button className={styles.save} onClick={onSaveElement}>Save</button></div></div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className={styles.error}><ErrorTooltip position='bottom-left' errors={e.errors.typeErr}><div className={e.errors.typeErr?.length > 0 ? styles.title : styles.content}>{e.itemType === false ? 'Durable' : e.itemType === true ? 'Consumable' : e.itemType}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditElement(index, null, 'itemType', 'item')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                </>
                                                            )}  
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {['isEquippable', 'isTradable', 'isStackable', 'isRentable', 'isTimeStackable'].filter(key => e[key] === true).slice(0,1).map((e) => {
                                                                return(
                                                                    <>
                                                                        <span>{e === 'isEquippable' ? 'Equippable' : e === 'isTradable' ? 'Tradable' : e === 'isStackable' ? 'Stackable' : e === 'isRentable' ? 'Rentable' : e === 'isTimeStackable' ? 'Time Stackable' : ''}</span>
                                                                    </>
                                                                )
                                                            })}
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {editIndex === index && editField === 'consumeByUses' ? (
                                                                <>
                                                                    <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='consumeByUses'/></div><div><button className={styles.save} onClick={onSaveElement}>Save</button></div></div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className={styles.error}><ErrorTooltip position='bottom-left' errors={e.errors.countErr}><div className={e.errors.countErr?.length > 0 ? styles.title : styles.content}>{e.consumeByUses ? e.consumeByUses : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditElement(index, null, 'consumeByUses', 'item')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                </>
                                                            )}
                                                                
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {editIndex === index && editField === 'consumeByTime' ? (
                                                                <>
                                                                    <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='consumeByTime'/></div><div><button className={styles.save} onClick={onSaveElement}>Save</button></div></div>
                                                                </>
                                                                ) : (
                                                                <>
                                                                    <div className={styles.error}><ErrorTooltip position='bottom-left' errors={e.errors.timeErr}><div className={e.errors.timeErr?.length > 0 ? styles.title : styles.content}>{(e.consumeByTime != null && e.consumeByTimeFormat == 'seconds') ? e.consumeByTime : (e.consumeByTime != null && e.consumeByTimeFormat == 'minutes') ? (e.consumeByTime/60) : (e.consumeByTime != null && e.consumeByTimeFormat == 'hours') ? (e.consumeByTime/(60*60)) : (e.consumeByTime ? e.consumeByTime : '-')}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditElement(index, null, 'consumeByTime', 'item')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                </>
                                                            )}     
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {editIndex === index && editField === 'consumeByTimeFormat' ? (
                                                                <>
                                                                    <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='consumeByTimeFormat'/></div><div><button className={styles.save} onClick={onSaveElement}>Save</button></div></div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className={styles.error}><ErrorTooltip position='bottom-left' errors={e.errors.unitErr}><div className={e.errors.unitErr?.length > 0 ? styles.title : styles.content}>{e.consumeByTimeFormat ? e.consumeByTimeFormat : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditElement(index, null, 'consumeByTimeFormat', 'item')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                </>
                                                            )}
                                                                
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {editIndex === index && editField === 'maxCollectionInstance' ? (
                                                                <>
                                                                    <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='maxCollectionInstance'/></div><div><button className={styles.save} onClick={onSaveElement}>Save</button></div></div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className={styles.error}><ErrorTooltip position='bottom-left' errors={e.errors.maxInstanceErr}><div className={e.errors.maxInstanceErr?.length > 0 ? styles.title : styles.content}>{e.maxCollectionInstance ? e.maxCollectionInstance : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditElement(index, null, 'maxCollectionInstance', 'item')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                </>
                                                            )}
                                                                
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {editIndex === index && editField === 'stackCapacity' ? (
                                                                <>
                                                                    <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='stackCapacity'/></div><div><button className={styles.save} onClick={onSaveElement}>Save</button></div></div>
                                                                </>
                                                                ) : (
                                                                <>
                                                                    <div className={styles.error}><ErrorTooltip position='bottom-left' errors={e.errors.stackErr}><div className={e.errors.stackErr?.length > 0 ? styles.title : styles.content}>{e.stackCapacity ? e.stackCapacity : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditElement(index, null, 'stackCapacity', 'item')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                </>
                                                            )}
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {editIndex === index && editField === 'quantity' ? (
                                                                <>
                                                                    <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='quantity'/></div><div><button className={styles.save} onClick={onSaveElement}>Save</button></div></div>
                                                                </>
                                                                ) : (
                                                                <>
                                                                    <div className={styles.error}><ErrorTooltip position='bottom-left' errors={e.errors.quantityErr}><div className={e.errors.quantityErr?.length > 0 ? styles.title : styles.content}>{e.quantity != null ? e.quantity : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditElement(index, null, 'quantity', 'item')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                </>
                                                            )}
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {e.itemPrices && e.itemPrices?.length > 0 ? <div className={styles.pricing} onClick={() => toggleAccordion(index, 'itemPrices')}>{e?.itemPrices[0]?.price} {e?.itemPrices?.length > 0 && <Icon name='arrow-down' size='12' />}</div> : '-'}
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {e.rewardUnlockCondition && e.rewardUnlockCondition?.length > 0 ? <div className={styles.pricing} onClick={() => toggleAccordion(index, 'rewardUnlock')}>{e?.rewardUnlockCondition[0]?.unlockItemId !== null ? e?.rewardUnlockCondition[0]?.unlockItemId : e?.rewardUnlockCondition[0]?.unlockBundleId !== null ? e?.rewardUnlockCondition[0]?.unlockBundleId : e?.rewardUnlockCondition[0]?.unlockLevelSystemId !== null ? e?.rewardUnlockCondition[0]?.unlockLevelSystemId : null} {e?.rewardUnlockCondition?.length > 0 && <Icon name='arrow-down' size='12' />}</div> : '-'}
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {editIndex === index && editField === 'isDefaultLoadout' ? (
                                                                <>
                                                                    <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='isDefaultLoadout'/></div><div><button className={styles.save} onClick={onSaveElement}>Save</button></div></div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className={styles.error}><ErrorTooltip position='bottom-left' errors={e.errors.defaultLoadOutErr}><div className={e.errors.defaultLoadOutErr?.length > 0 ? styles.title : styles.content}>{e.isDefaultLoadout === true ? 'Yes' : e.isDefaultLoadout === false ? 'No' : e.isDefaultLoadout}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditElement(index, null, 'isDefaultLoadout', 'item')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                </>
                                                            )}
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {editIndex === index && editField === 'equippedByDefault' ? (
                                                                <>
                                                                    <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='equippedByDefault'/></div><div><button className={styles.save} onClick={onSaveElement}>Save</button></div></div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className={styles.error}><ErrorTooltip position='bottom-left' errors={e.errors.equippedByDefaultErr}><div className={e.errors.equippedByDefaultErr?.length > 0 ? styles.title : styles.content}>{e.equippedByDefault === true ? 'Yes' : e.equippedByDefault === false ? 'No' : e.equippedByDefault}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditElement(index, null, 'equippedByDefault', 'item')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                </>
                                                            )}
                                                        </td>
                                                    </tr>
                                                    {activeIndexes.includes(index) && type === 'Items' && show === 'itemPrices' && e.itemPrices && e.itemPrices?.length > 0 && (
                                                        <>
                                                            <tr className={styles.tableRow}>
                                                                <td colspan={17} aria-colspan={17} colSpan={17}>
                                                                    <table className={styles.accordianTable}>
                                                                        <thead className={styles.accordianTableHead}>
                                                                            <th className={styles.accordianTableHeading}></th>
                                                                            <th className={styles.accordianTableHeading}></th>
                                                                            <th className={styles.accordianTableHeading}>Currency Type</th>
                                                                            <th className={styles.accordianTableHeading}>Currency ID</th>
                                                                            <th className={styles.accordianTableHeading}>Price Amount</th>
                                                                            <th className={styles.accordianTableHeading}>Discount</th>
                                                                            <th className={styles.accordianTableHeading}>Bonus Cash Allowance</th>
                                                                            <th className={styles.accordianTableHeading}>Real World Currency ID</th>
                                                                            <th className={styles.accordianTableHeading}>Platform</th>
                                                                            <th className={styles.accordianTableHeading}>Product ID</th>
                                                                        </thead>
                                                                        <tbody className={styles.accordianTableBody}>
                                                                            {e.itemPrices && e.itemPrices.map((x, i) => {
                                                                                return(
                                                                                    <>
                                                                                        <tr className={styles.accordianTableRow}>
                                                                                            <td className={styles.accordianTableCol} colSpan={2}></td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {editIndex === index && subEditIndex === i && editField === 'priceTypes' ? (
                                                                                                    <>
                                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='priceTypes'/></div><div><button className={styles.save} onClick={() => {onSavePricing('itemPrices')}}>Save</button></div></div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.currencyTypeErr}><div className={x?.errors?.currencyTypeErr?.length > 0 ? styles.title : styles.content}>{x.priceTypes === 'virtual currency' ? 'virtual' : x.priceTypes === 'RMG' ? 'RMG' : x.priceTypes}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditPricing(index, i, 'priceTypes', 'pricing', 'itemPrices')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                    </>
                                                                                                )}
                                                                                            </td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {editIndex === index && subEditIndex === i && editField === 'currencyClientID' ? (
                                                                                                    <>
                                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='currencyClientID'/></div><div><button className={styles.save} onClick={() => {onSavePricing('itemPrices')}}>Save</button></div></div>
                                                                                                    </>
                                                                                                    ) : (
                                                                                                    <>
                                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.currencyIdErr}><div className={x?.errors?.currencyIdErr?.length > 0 ? styles.title : styles.content}>{x.currencyClientID ? x.currencyClientID : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditPricing(index, i, 'currencyClientID', 'pricing', 'itemPrices')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                    </>
                                                                                                )}
                                                                                            </td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {editIndex === index && subEditIndex === i && editField === 'price' ? (
                                                                                                    <>
                                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='price'/></div><div><button className={styles.save} onClick={() => {onSavePricing('itemPrices')}}>Save</button></div></div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.amountErr}><div className={x?.errors?.amountErr?.length > 0 ? styles.title : styles.content}>{x.price}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditPricing(index, i, 'price', 'pricing', 'itemPrices')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                    </>
                                                                                                )}
                                                                                            </td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {editIndex === index && subEditIndex === i && editField === 'discount' ? (
                                                                                                    <>
                                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='discount'/></div><div><button className={styles.save} onClick={() => {onSavePricing('itemPrices')}}>Save</button></div></div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.discountErr}><div className={x?.errors?.discountErr?.length > 0 ? styles.title : styles.content}>{x.discount}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditPricing(index, i, 'discount', 'pricing', 'itemPrices')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                    </>
                                                                                                )}
                                                                                            </td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {editIndex === index && subEditIndex === i && editField === 'bonusCashAllowance' ? (
                                                                                                    <>
                                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='bonusCashAllowance'/></div><div><button className={styles.save} onClick={() => {onSavePricing('itemPrices')}}>Save</button></div></div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.BCAErr}><div className={x?.errors?.BCAErr?.length > 0 ? styles.title : styles.content}>{x.bonusCashAllowance ? x.bonusCashAllowance : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditPricing(index, i, 'bonusCashAllowance', 'pricing', 'itemPrices')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                    </>
                                                                                                )}
                                                                                            </td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {x.realWorldCurrencyId ? x.realWorldCurrencyId : 'N/A'}
                                                                                            </td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {editIndex === index && subEditIndex === i && editField === 'gamePlatformMasterId' ? (
                                                                                                    <>
                                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='gamePlatformMasterId'/></div><div><button className={styles.save} onClick={() => {onSavePricing('itemPrices')}}>Save</button></div></div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.platformErr}><div className={x?.errors?.platformErr?.length > 0 ? styles.title : styles.content}>{x.gamePlatformMasterId === 1 ? 'Android' : x.gamePlatformMasterId === 2 ? 'iOS' : (!x.gamePlatformMasterId || x.gamePlatformMasterId === null || x.gamePlatformMasterId === '' || x.gamePlatformMasterId == 'N/A') ?  '-' : ''}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditPricing(index, i, 'gamePlatformMasterId', 'pricing', 'itemPrices')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                    </>
                                                                                                )}
                                                                                            </td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {editIndex === index && subEditIndex === i && editField === 'productId' ? (
                                                                                                    <>
                                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='productId'/></div><div><button className={styles.save} onClick={() => {onSavePricing('itemPrices')}}>Save</button></div></div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.productErr}><div className={x?.errors?.productErr?.length > 0 ? styles.title : styles.content}>{x.productId ? x.productId : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditPricing(index, i, 'productId', 'pricing', 'itemPrices')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                    </>
                                                                                                )}
                                                                                            </td>
                                                                                        </tr>
                                                                                    </>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )}
                                                    {activeIndexes.includes(index) && type === 'Items'  && show === 'rewardUnlock' && e.rewardUnlockCondition && e.rewardUnlockCondition?.length > 0 && (
                                                        <>
                                                            <tr className={styles.tableRow}>
                                                                <td colspan={17} aria-colspan={17} colSpan={17}>
                                                                    <table className={styles.accordianTable}>
                                                                        <thead className={styles.accordianTableHead}>
                                                                            <th className={styles.accordianTableHeading}></th>
                                                                            <th className={styles.accordianTableHeading}></th>
                                                                            <th className={styles.accordianTableHeading}>Item ID</th>
                                                                            <th className={styles.accordianTableHeading}>Unlock Item ID</th>
                                                                            <th className={styles.accordianTableHeading}>Unlock Bundle ID</th>
                                                                            <th className={styles.accordianTableHeading}>Unlock Progression System ID</th>
                                                                            <th className={styles.accordianTableHeading}>Unlock Progression System Level</th>
                                                                        </thead>
                                                                        <tbody className={styles.accordianTableBody}>
                                                                            {e.rewardUnlockCondition && e.rewardUnlockCondition.map((x, i) => {
                                                                                return(
                                                                                    <>
                                                                                        <tr className={styles.accordianTableRow}>
                                                                                            <td className={styles.accordianTableCol} colSpan={2}></td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {x.itemId ? x.itemId : '-'}
                                                                                            </td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {editIndex === index && subEditIndex === i && editField === 'unlockItemId' ? (
                                                                                                    <>
                                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='unlockItemId'/></div><div><button className={styles.save} onClick={onSaveLockBy}>Save</button></div></div>
                                                                                                    </>
                                                                                                    ) : (
                                                                                                    <>
                                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.lockItemIdErr}><div className={x?.errors?.lockItemIdErr?.length > 0 ? styles.title : styles.content}>{x.unlockItemId ? x.unlockItemId : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditLockBy(index, i, 'unlockItemId', 'lockBy')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                    </>
                                                                                                )}
                                                                                            </td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {editIndex === index && subEditIndex === i && editField === 'unlockBundleId' ? (
                                                                                                    <>
                                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='unlockBundleId'/></div><div><button className={styles.save} onClick={onSaveLockBy}>Save</button></div></div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.lockBundleIdErr}><div className={x?.errors?.lockBundleIdErr?.length > 0 ? styles.title : styles.content}>{x.unlockBundleId ? x.unlockBundleId : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditLockBy(index, i, 'unlockBundleId', 'lockBy')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                    </>
                                                                                                )}
                                                                                            </td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {editIndex === index && subEditIndex === i && editField === 'unlockLevelSystemId' ? (
                                                                                                    <>
                                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='unlockLevelSystemId'/></div><div><button className={styles.save} onClick={onSaveLockBy}>Save</button></div></div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.lockPSError}><div className={x?.errors?.lockPSError?.length > 0 ? styles.title : styles.content}>{x.unlockLevelSystemId ? x.unlockLevelSystemId : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditLockBy(index, i, 'unlockLevelSystemId', 'lockBy')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                    </>
                                                                                                )}
                                                                                            </td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {editIndex === index && subEditIndex === i && editField === 'lockedLevel' ? 
                                                                                                    <>
                                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='lockedLevel'/></div><div><button className={styles.save} onClick={onSaveLockBy}>Save</button></div></div>
                                                                                                    </>
                                                                                                :
                                                                                                    <>
                                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x.errors.lockPSLevelErr}><div className={styles.content}>{x.lockedLevel ? x.lockedLevel : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditLockBy(index, i, 'lockedLevel', 'lockBy')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                    </>
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                    </>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )}
                                                </>
                                            )
                                        })}           
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }

                    {type === 'Bundles' &&
                        <div className={styles.tableWrapper}>
                            <div className={styles.table}>
                                <div className={styles.collapsableRow}>
                                    <table className={styles.tabularDataOutput}>
                                        <thead className={styles.tableHead}>
                                            <th className={styles.heading}></th>
                                            <th className={styles.heading}></th>
                                            <th className={styles.heading}>Icon</th>
                                            <th className={styles.heading}>Display Name</th>
                                            <th className={styles.heading}>ID</th>
                                            <th className={styles.heading}>Type</th>
                                            <th className={styles.heading}>Subtype</th>
                                            <th className={styles.heading}>Consume By Uses</th>
                                            <th className={styles.heading}>Consume By Time</th>
                                            <th className={styles.heading}>Time Format</th>
                                            <th className={styles.heading}>Max Collection Instance</th>
                                            <th className={styles.heading}>Stack Capacity</th>
                                            <th className={styles.heading}>Limited Quantity</th>
                                            <th className={styles.heading}>Contents</th>
                                            <th className={styles.heading}>Price</th>
                                            <th className={styles.heading}>Lock By</th>
                                        </thead>
                                        <tbody className={styles.tableBody}>
                                        {data && data.map((e, index) => {
                                            return(
                                                <>
                                                    <tr className={styles.tableRow}>
                                                        <td className={styles.tableCol}>
                                                            {checkFieldError(e, "Bundles") ? 
                                                                <Success /> 
                                                            : 
                                                                <div onMouseEnter={() => checkBundleErrors(e)}><ErrorTooltip position='left' errors={errorList}><Error /></ErrorTooltip></div>
                                                            }
                                                        </td>
                                                        <td className={styles.tableCol}>{index+1}</td>
                                                        <td className={styles.tableCol}>
                                                            <div className={styles.icon}>
                                                                <SnapShot id={`bundles_${e?.name}_${index}`} url={e.iconUrl} alt={e?.name} placeholder={BundlePlaceHolder} width={28} height={28} type="Images" /> 
                                                            </div>
                                                            {/* <div className={styles.icon}>
                                                                {(e.iconUrl !== null && e.iconUrl !== 'N/A' && e.iconUrl !== '') ? <img src={e.iconUrl} width={32} height={32}/> : ((e.iconUrl === null || e.iconUrl === 'N/A' || e.iconUrl === '') && type === 'Items') ?  <img src={ItemPlaceHolder} width={32} height={32}/> : ((e.iconUrl === null || e.iconUrl === 'N/A' || e.iconUrl === '') && type === 'Bundles') ?  <img src={BundlePlaceHolder} width={32} height={32}/> : ''}
                                                            </div> */}
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {editIndex === index && editField === 'name' ? 
                                                                <>
                                                                    <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='name'/></div><div><button className={styles.save} onClick={onSaveElement}>Save</button></div></div>
                                                                </>
                                                            :
                                                                <>
                                                                    <div className={styles.error}><ErrorTooltip position='bottom-left' errors={e.errors.nameErr}><div className={styles.content}>{e.name}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditElement(index, null, 'name', 'bundles')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                </>
                                                            }
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {editIndex === index && editField === 'bundleId' ? (
                                                                <>
                                                                    <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='bundleId'/></div><div><button className={styles.save} onClick={onSaveElement}>Save</button></div></div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className={styles.error}><ErrorTooltip position='bottom-left' errors={e.errors.idErr}><div className={e.errors.idErr?.length > 0 ? styles.title : styles.content}>{e.bundleId}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditElement(index, null, 'bundleId', 'bundles')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                </>
                                                            )}
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {editIndex === index && editField === 'itemType' ? (
                                                                <>
                                                                    <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='itemType'/></div><div><button className={styles.save} onClick={onSaveElement}>Save</button></div></div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className={styles.error}><ErrorTooltip position='bottom-left' errors={e.errors.typeErr}><div className={e.errors.typeErr?.length > 0 ? styles.title : styles.content}>{e.itemType === false ? 'Durable' : e.itemType === true ? 'Consumable' : e.itemType}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditElement(index, null, 'itemType', 'bundle')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                </>
                                                            )}
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {['isEquippable', 'isTradable', 'isStackable', 'isRentable', 'isTimeStackable'].filter(key => e[key] === true).slice(0,1).map((e) => {
                                                                    return(
                                                                        <>
                                                                            <span>{e === 'isEquippable' ? 'Equippable' : e === 'isTradable' ? 'Tradable' : e === 'isStackable' ? 'Stackable' : e === 'isRentable' ? 'Rentable' : e === 'isTimeStackable' ? 'Time Stackable' : ''}</span>
                                                                        </>
                                                                    )
                                                            })}
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {editIndex === index && editField === 'consumeByUses' ? (
                                                                <>
                                                                    <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='consumeByUses'/></div><div><button className={styles.save} onClick={onSaveElement}>Save</button></div></div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className={styles.error}><ErrorTooltip position='bottom-left' errors={e.errors.countErr}><div className={e.errors.countErr?.length > 0 ? styles.title : styles.content}>{e.consumeByUses ? e.consumeByUses : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditElement(index, null, 'consumeByUses', 'bundle')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                </>
                                                            )}
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {editIndex === index && editField === 'consumeByTime' ? (
                                                                <>
                                                                    <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='consumeByTime'/></div><div><button className={styles.save} onClick={onSaveElement}>Save</button></div></div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className={styles.error}><ErrorTooltip position='bottom-left' errors={e.errors.timeErr}><div className={e.errors.timeErr?.length > 0 ? styles.title : styles.content}>{(e.consumeByTime != null && e.consumeByTimeFormat == 'seconds') ? e.consumeByTime : (e.consumeByTime != null && e.consumeByTimeFormat == 'minutes') ? (e.consumeByTime/60) : (e.consumeByTime != null && e.consumeByTimeFormat == 'hours') ? (e.consumeByTime/(60*60)) : ((e.consumeByTime && e.consumeByTime !== null && e.consumeByTime !== '' && e.consumeByTime !== 'N/A') ? e.consumeByTime : '-')}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditElement(index, null, 'consumeByTime', 'bundle')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                </>
                                                            )}
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {editIndex === index && editField === 'consumeByTimeFormat' ? (
                                                                <>
                                                                    <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='consumeByTimeFormat'/></div><div><button className={styles.save} onClick={onSaveElement}>Save</button></div></div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className={styles.error}><ErrorTooltip position='bottom-left' errors={e.errors.unitErr}><div className={e.errors.unitErr?.length > 0 ? styles.title : styles.content}>{e.consumeByTimeFormat ? e.consumeByTimeFormat : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditElement(index, null, 'consumeByTimeFormat', 'bundle')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                </>
                                                            )}
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {editIndex === index && editField === 'maxCollectionInstance' ? (
                                                                <>
                                                                    <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='maxCollectionInstance'/></div><div><button className={styles.save} onClick={onSaveElement}>Save</button></div></div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className={styles.error}><ErrorTooltip position='bottom-left' errors={e.errors.maxInstanceErr}><div className={e.errors.maxInstanceErr?.length > 0 ? styles.title : styles.content}>{e.maxCollectionInstance ? e.maxCollectionInstance : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditElement(index, null, 'maxCollectionInstance', 'bundle')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                </>
                                                            )}
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {editIndex === index && editField === 'stackCapacity' ? (
                                                                <>
                                                                    <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='stackCapacity'/></div><div><button className={styles.save} onClick={onSaveElement}>Save</button></div></div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className={styles.error}><ErrorTooltip position='bottom-left' errors={e.errors.stackErr}><div className={e.errors.stackErr?.length > 0 ? styles.title : styles.content}>{e.stackCapacity ? e.stackCapacity : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditElement(index, null, 'stackCapacity', 'bundle')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                </>
                                                            )}
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {editIndex === index && subEditIndex === null && editField === 'quantity' ? (
                                                                <>
                                                                    <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='quantity'/></div><div><button className={styles.save} onClick={onSaveElement}>Save</button></div></div>
                                                                </>
                                                                ) : (
                                                                <>
                                                                    <div className={styles.error}><ErrorTooltip position='bottom-left' errors={e.errors.quantityErr}><div className={e.errors.quantityErr?.length > 0 ? styles.title : styles.content}>{e.quantity != null ? e.quantity : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditElement(index, null, 'quantity', 'bundle')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                </>
                                                            )}
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {e.bundleContent && e.bundleContent?.length > 0 ? <div className={styles.pricing} onClick={() => toggleAccordion(index, 'bundleContent')}>{(e?.bundleContent[0]?.currency && e.currency !== null) ? e?.bundleContent[0]?.currency : (e?.bundleContent[0]?.item && e?.bundleContent[0]?.item !== null) ? e?.bundleContent[0]?.item : (e?.bundleContent[0]?.bundle && e?.bundleContent[0]?.bundle !== null) ? e?.bundleContent[0]?.bundle : '-'} {e?.bundleContent?.length > 0 && <Icon name='arrow-down' size='12' />}</div> : '-'}
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {e.bundlePrices && e.bundlePrices?.length > 0 ? <div className={styles.pricing} onClick={() => toggleAccordion(index, 'bundlePrices')}>{e?.bundlePrices[0]?.price} {e?.bundlePrices?.length > 0 && <Icon name='arrow-down' size='12' />}</div> : '-'}
                                                        </td>
                                                        <td className={styles.tableCol}>
                                                            {e.rewardUnlockCondition && e.rewardUnlockCondition?.length > 0 ? <div className={styles.pricing} onClick={() => toggleAccordion(index, 'rewardUnlock')}>{e?.rewardUnlockCondition[0]?.unlockItemId !== null ? e?.rewardUnlockCondition[0]?.unlockItemId : e?.rewardUnlockCondition[0]?.unlockBundleId !== null ? e?.rewardUnlockCondition[0]?.unlockBundleId : e?.rewardUnlockCondition[0]?.unlockLevelSystemId !== null ? e?.rewardUnlockCondition[0]?.unlockLevelSystemId : null} {e?.rewardUnlockCondition?.length > 0 && <Icon name='arrow-down' size='12' />}</div> : '-'}
                                                        </td>
                                                    </tr>
                                                    {activeIndexes.includes(index) && type === 'Bundles'  && show === 'bundleContent' && e.bundleContent && e.bundleContent?.length > 0 && (
                                                        <>
                                                            <tr className={styles.tableRow}>
                                                                <td colspan={16} aria-colspan={16} colSpan={16}>
                                                                    <table className={styles.accordianTable}>
                                                                        <thead className={styles.accordianTableHead}>
                                                                            <th className={styles.accordianTableHeading}></th>
                                                                            <th className={styles.accordianTableHeading}></th>
                                                                            <th className={styles.accordianTableHeading}>Bundle ID</th>
                                                                            <th className={styles.accordianTableHeading}>Currency ID</th>
                                                                            <th className={styles.accordianTableHeading}>Item ID</th>
                                                                            <th className={styles.accordianTableHeading}>Bundle ID</th>
                                                                            <th className={styles.accordianTableHeading}>Quantity</th>
                                                                        </thead>
                                                                        <tbody className={styles.accordianTableBody}>
                                                                            {e.bundleContent && e.bundleContent.map((x, i) => {
                                                                                return(
                                                                                    <>
                                                                                        <tr className={styles.accordianTableRow}>
                                                                                            <td className={styles.accordianTableCol} colSpan={2}></td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {e.bundleId ? e.bundleId : '-'}
                                                                                            </td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {editIndex === index && subEditIndex === i && editField === 'currency' ? (
                                                                                                    <>
                                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='currency'/></div><div><button className={styles.save} onClick={onSaveContent}>Save</button></div></div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.currencyIdErr}><div className={x?.errors?.currencyIdErr?.length > 0 ? styles.title : styles.content}>{x.currency ? x.currency : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditContent(index, i, 'currency', 'contents')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                    </>
                                                                                                )}
                                                                                            </td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {editIndex === index && subEditIndex === i && editField === 'item' ? (
                                                                                                    <>
                                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='item'/></div><div><button className={styles.save} onClick={onSaveContent}>Save</button></div></div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.itemIdErr}><div className={x?.errors?.itemIdErr?.length > 0 ? styles.title : styles.content}>{x.item ? x.item : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditContent(index, i, 'item', 'contents')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                    </>
                                                                                                )}
                                                                                            </td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {editIndex === index && subEditIndex === i && editField === 'bundle' ? (
                                                                                                    <>
                                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='bundle'/></div><div><button className={styles.save} onClick={onSaveContent}>Save</button></div></div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.bundleIdErr}><div className={x?.errors?.bundleIdErr?.length > 0 ? styles.title : styles.content}>{x.bundle ? x.bundle : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditContent(index, i, 'bundle', 'contents')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                    </>
                                                                                                )}
                                                                                            </td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {editIndex === index && subEditIndex === i && editField === 'quantity' ? (
                                                                                                    <>
                                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='quantity'/></div><div><button className={styles.save} onClick={onSaveContent}>Save</button></div></div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.contentQuantityErr}><div className={x?.errors?.contentQuantityErr?.length > 0 ? styles.title : styles.content}>{x.quantity ? x.quantity : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditContent(index, i, 'quantity', 'contents')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                    </>
                                                                                                )}
                                                                                            </td>
                                                                                        </tr>
                                                                                    </>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )}
                                                    {activeIndexes.includes(index) && type === 'Bundles' && show === 'bundlePrices' && e.bundlePrices && e.bundlePrices?.length > 0 && (
                                                        <>
                                                            <tr className={styles.tableRow}>
                                                                <td colspan={16} aria-colspan={16} colSpan={16}>
                                                                    <table className={styles.accordianTable}>
                                                                        <thead className={styles.accordianTableHead}>
                                                                            <th className={styles.accordianTableHeading}></th>
                                                                            <th className={styles.accordianTableHeading}></th>
                                                                            <th className={styles.accordianTableHeading}>Currency Type</th>
                                                                            <th className={styles.accordianTableHeading}>Currency ID</th>
                                                                            <th className={styles.accordianTableHeading}>Price Amount</th>
                                                                            <th className={styles.accordianTableHeading}>Discount</th>
                                                                            <th className={styles.accordianTableHeading}>Bonus Cash Allowance</th>
                                                                            <th className={styles.accordianTableHeading}>Real World Currency ID</th>
                                                                            <th className={styles.accordianTableHeading}>Platform</th>
                                                                            <th className={styles.accordianTableHeading}>Product ID</th>
                                                                        </thead>
                                                                        <tbody className={styles.accordianTableBody}>
                                                                            {e.bundlePrices && e.bundlePrices.map((x, i) => {
                                                                                return(
                                                                                    <>
                                                                                        <tr className={styles.accordianTableRow}>
                                                                                            <td className={styles.accordianTableCol} colSpan={2}></td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {editIndex === index && subEditIndex === i && editField === 'priceTypes' ? (
                                                                                                    <>
                                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='priceTypes'/></div><div><button className={styles.save} onClick={() => onSavePricing('bundlePrices')}>Save</button></div></div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.currencyTypeErr}><div className={x?.errors?.currencyTypeErr.length > 0 ? styles.title : styles.content}>{x.priceTypes === 'virtual currency' ? 'virtual' : x.priceTypes === 'RMG' ? 'RMG' : x.priceTypes}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditPricing(index, i, 'priceTypes', 'pricing', 'bundlePrices')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                    </>
                                                                                                )}
                                                                                            </td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {editIndex === index && subEditIndex === i && editField === 'currencyClientID' ? (
                                                                                                    <>
                                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='currencyClientID'/></div><div><button className={styles.save} onClick={() => onSavePricing('bundlePrices')}>Save</button></div></div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.currencyIdErr}><div className={x?.errors?.currencyIdErr.length > 0 ? styles.title : styles.content}>{x.currencyClientID}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditPricing(index, i, 'currencyClientID', 'pricing', 'bundlePrices')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                    </>
                                                                                                )}
                                                                                            </td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {editIndex === index && subEditIndex === i && editField === 'price' ? (
                                                                                                    <>
                                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='price'/></div><div><button className={styles.save} onClick={() => onSavePricing('bundlePrices')}>Save</button></div></div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.amountErr}><div className={x?.errors?.amountErr.length > 0 ? styles.title : styles.content}>{x.price}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditPricing(index, i, 'price', 'pricing', 'bundlePrices')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                    </>
                                                                                                )}
                                                                                            </td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {editIndex === index && subEditIndex === i && editField === 'discount' ? (
                                                                                                    <>
                                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='discount'/></div><div><button className={styles.save} onClick={() => onSavePricing('bundlePrices')}>Save</button></div></div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.discountErr}><div className={x?.errors?.discountErr.length > 0 ? styles.title : styles.content}>{x.discount}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditPricing(index, i, 'discount', 'pricing', 'bundlePrices')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                    </>
                                                                                                )}
                                                                                            </td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {editIndex === index && subEditIndex === i && editField === 'bonusCashAllowance' ? (
                                                                                                    <>
                                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='bonusCashAllowance'/></div><div><button className={styles.save} onClick={() => onSavePricing('bundlePrices')}>Save</button></div></div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.BCAErr}><div className={x?.errors?.BCAErr.length > 0 ? styles.title : styles.content}>{x.bonusCashAllowance}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditPricing(index, i, 'bonusCashAllowance', 'pricing', 'bundlePrices')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                    </>
                                                                                                )}
                                                                                            </td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {x.realWorldCurrencyId ? x.realWorldCurrencyId : 'N/A'}
                                                                                            </td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {editIndex === index && subEditIndex === i && editField === 'gamePlatformMasterId' ? (
                                                                                                    <>
                                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='gamePlatformMasterId'/></div><div><button className={styles.save} onClick={() => onSavePricing('bundlePrices')}>Save</button></div></div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.platformErr}><div className={x?.errors?.platformErr.length > 0 ? styles.title : styles.content}>{x.gamePlatformMasterId === 1 ? 'Android' : x.gamePlatformMasterId === 2 ? 'iOS' : x.gamePlatformMasterId}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditPricing(index, i, 'gamePlatformMasterId', 'pricing', 'bundlePrices')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                    </>
                                                                                                )}
                                                                                            </td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {editIndex === index && subEditIndex === i && editField === 'productId' ? (
                                                                                                    <>
                                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='productId'/></div><div><button className={styles.save} onClick={() => onSavePricing('bundlePrices')}>Save</button></div></div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.productErr}><div className={x?.errors?.productErr.length > 0 ? styles.title : styles.content}>{x.productId}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditPricing(index, i, 'productId', 'pricing', 'bundlePrices')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                    </>
                                                                                                )}
                                                                                            </td>
                                                                                        </tr>
                                                                                    </>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )}

                                                    {activeIndexes.includes(index) && type === 'Bundles'  && show === 'rewardUnlock' && e.rewardUnlockCondition && e.rewardUnlockCondition?.length > 0 && (
                                                        <>
                                                            <tr className={styles.tableRow}>
                                                                <td colspan={16} aria-colspan={16} colSpan={16}>
                                                                    <table className={styles.accordianTable}>
                                                                        <thead className={styles.accordianTableHead}>
                                                                            <th className={styles.accordianTableHeading}></th>
                                                                            <th className={styles.accordianTableHeading}></th>
                                                                            <th className={styles.accordianTableHeading}>Bundle ID</th>
                                                                            <th className={styles.accordianTableHeading}>Unlock Item ID</th>
                                                                            <th className={styles.accordianTableHeading}>Unlock Bundle ID</th>
                                                                            <th className={styles.accordianTableHeading}>Unlock Progression System ID</th>
                                                                            <th className={styles.accordianTableHeading}>Unlock Progression System Level</th>
                                                                        </thead>
                                                                        <tbody className={styles.accordianTableBody}>
                                                                            {e.rewardUnlockCondition && e.rewardUnlockCondition.map((x, i) => {
                                                                                return(
                                                                                    <>
                                                                                        <tr className={styles.accordianTableRow}>
                                                                                            <td className={styles.accordianTableCol} colSpan={2}></td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {x.bundleId ? x.bundleId : '-'}
                                                                                            </td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {editIndex === index && subEditIndex === i && editField === 'unlockItemId' ? (
                                                                                                    <>
                                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='unlockItemId'/></div><div><button className={styles.save} onClick={onSaveLockBy}>Save</button></div></div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.lockItemIdErr}><div className={x?.errors?.lockItemIdErr?.length > 0 ? styles.title : styles.content}>{x.unlockItemId ? x.unlockItemId : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditLockBy(index, i, 'unlockItemId', 'lockBy')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                    </>
                                                                                                )}
                                                                                            </td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {editIndex === index && subEditIndex === i && editField === 'unlockBundleId' ? (
                                                                                                    <>
                                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='unlockBundleId'/></div><div><button className={styles.save} onClick={onSaveLockBy}>Save</button></div></div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.lockBundleIdErr}><div className={x?.errors?.lockBundleIdErr?.length > 0 ? styles.title : styles.content}>{x.unlockBundleId ? x.unlockBundleId : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditLockBy(index, i, 'unlockBundleId', 'lockBy')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                    </>
                                                                                                )}
                                                                                            </td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {editIndex === index && subEditIndex === i && editField === 'unlockLevelSystemId' ? (
                                                                                                    <>
                                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='unlockLevelSystemId'/></div><div><button className={styles.save} onClick={onSaveLockBy}>Save</button></div></div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.lockPSError}><div className={x?.errors?.lockPSError?.length > 0 ? styles.title : styles.content}>{x.unlockLevelSystemId ? x.unlockLevelSystemId : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditLockBy(index, i, 'unlockLevelSystemId', 'lockBy')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                    </>
                                                                                                )}
                                                                                            </td>
                                                                                            <td className={styles.accordianTableCol}>
                                                                                                {editIndex === index && subEditIndex === i && editField === 'lockedLevel' ? 
                                                                                                    <>
                                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='lockedLevel'/></div><div><button className={styles.save} onClick={onSaveLockBy}>Save</button></div></div>
                                                                                                    </>
                                                                                                :
                                                                                                    <>
                                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x.errors.lockPSLevelErr}><div className={x.errors.lockPSLevelErr?.length > 0 ? styles.title : styles.content}>{x.lockedLevel ? x.lockedLevel : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditLockBy(index, i, 'lockedLevel', 'lockBy')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                    </>
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                    </>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )}
                                                </>
                                            )
                                        })}           
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }
                    {type === 'Stores' &&
                        <>
                            <div className={styles.tableWrapper}>
                                <div className={styles.table}>
                                    <div className={styles.collapsableRow}>
                                        <table className={styles.tabularDataOutput}>
                                            <thead className={styles.tableHead}>
                                                <th className={styles.heading}></th>
                                                <th className={styles.heading}></th>
                                                <th className={styles.heading}>Icon</th>
                                                <th className={styles.heading}>Display Name</th>
                                                <th className={styles.heading}>Store ID</th>
                                                <th className={styles.heading}>Category Name</th>
                                                <th className={styles.heading}>Category ID</th>
                                                <th className={styles.heading}>Content ID</th>
                                                <th className={styles.heading}>Lock By</th>
                                            </thead>
                                            <tbody className={styles.tableBody}>
                                                <>
                                                    {data && data.flatMap((e, index) =>
                                                        e.storeCategories.length > 0 ? 
                                                        e.storeCategories && e.storeCategories.map((x, j) => (
                                                            <>
                                                                {x.storeContents?.length === 0 &&
                                                                    <>
                                                                        <tr className={styles.tableRow} onClick={() => {toggleAccordion(index); toggleCategoryAccordion(j)}}>
                                                                            <td className={styles.tableCol}></td>
                                                                            <td className={styles.tableCol}>
                                                                                {checkStoreContent(e) ? 
                                                                                    <Success /> 
                                                                                :
                                                                                    <div onMouseEnter={() => storeErrors(e)}><ErrorTooltip position='left' errors={errorList}><Error /></ErrorTooltip></div>
                                                                                }
                                                                            </td>
                                                                            <td className={styles.tableCol}>
                                                                                <div className={styles.icon}>
                                                                                    <SnapShot id={`stores_${e?.name}_${index}`} url={e.iconUrl} alt={e?.name} placeholder={StorePlaceHolder} width={28} height={28} type="Images" /> 
                                                                                </div>
                                                                                {/* <div className={styles.icon}>
                                                                                    {(e.iconUrl !== null && e.iconUrl !== 'N/A' && e.iconUrl !== '') ? <img src={e.iconUrl} width={32} height={32}/> : (e.iconUrl === null || e.iconUrl === 'N/A' || e.iconUrl === '') ?  <img src={StorePlaceHolder} width={32} height={32}/> : ''}
                                                                                </div> */}
                                                                            </td>
                                                                            <td className={styles.tableCol}>
                                                                                {editIndex === index && subEditIndex === j && section === null && editField === 'name' ? 
                                                                                    <>
                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='name'/></div><div><button className={styles.save} onClick={onSaveElement}>Save</button></div></div>
                                                                                    </>
                                                                                :
                                                                                    <>
                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={e.errors.nameErr}><div className={x?.errors?.nameErr?.length > 0 ? styles.title : styles.content}>{e.name}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditStore(index, j, null, null, 'name', 'store')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                    </>
                                                                                }
                                                                            </td>
                                                                            <td className={styles.tableCol}>
                                                                                {editIndex === index && subEditIndex === j && section === null && editField === 'storeId' ? (
                                                                                    <>
                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='storeId'/></div><div><button className={styles.save} onClick={() => {handleSaveStore('Store')}}>Save</button></div></div>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={e.errors.idErr}><div className={x?.errors?.idErr?.length > 0 ? styles.title : styles.content}>{e.storeId ? e.storeId : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditStore(index, j, null, null, 'storeId', 'store')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                    </>
                                                                                )}
                                                                            </td>
                                                                            <td className={styles.tableCol}>
                                                                                {x.name ? x.name : '-'}
                                                                            </td>
                                                                            <td className={styles.tableCol}>
                                                                                {editIndex === index && subEditIndex === j && section === null && editField === 'storeCategoryId' ? (
                                                                                    <>
                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='storeCategoryId'/></div><div><button className={styles.save} onClick={() => {handleSaveStore('category', x.storeCategoryId)}}>Save</button></div></div>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x.errors.catIdErr}><div className={x?.errors?.catIdErr?.length > 0 ? styles.title : styles.content}>{x.storeCategoryId ? x.storeCategoryId : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditStore(index, j, null, null, 'storeCategoryId', 'category')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                    </>
                                                                                )}
                                                                            </td>
                                                                            <td className={styles.tableCol}></td>
                                                                            <td className={styles.tableCol}></td>
                                                                        </tr>
                                                                    </>
                                                                }

                                                                {x.storeContents?.length > 0 && x.storeContents && x.storeContents.map((content, z) => (
                                                                    <>
                                                                        <tr className={styles.tableRow} onClick={() => {toggleAccordion(index); toggleCategoryAccordion(j); toggleContentAccordion(z)}}>
                                                                            <td className={styles.tableCol}></td>
                                                                            <td className={styles.tableCol}>
                                                                                {checkStoreContent(e) ? 
                                                                                    <Success /> 
                                                                                :
                                                                                    <div onMouseEnter={() => storeErrors(e)}><ErrorTooltip position='left' errors={errorList}><Error /></ErrorTooltip></div>
                                                                                }
                                                                            </td>
                                                                            <td className={styles.tableCol}>
                                                                                <div className={styles.icon}>
                                                                                    <SnapShot id={`stores_content_${x?.name}_${index}_${z}`} url={e.iconUrl} alt={e?.name} placeholder={StorePlaceHolder} width={28} height={28} type="Images" /> 
                                                                                </div>
                                                                                {/* <div className={styles.icon}>
                                                                                    {(e.iconUrl !== null && e.iconUrl !== 'N/A' && e.iconUrl !== '') ? <img src={e.iconUrl} width={32} height={32}/> : (e.iconUrl === null || e.iconUrl === 'N/A' || e.iconUrl === '') ?  <img src={StorePlaceHolder} width={32} height={32}/> : ''}
                                                                                </div> */}
                                                                            </td>
                                                                            <td className={styles.tableCol}>
                                                                            {editIndex === index && subEditIndex === j && section === z && editField === 'name' ? 
                                                                                <>
                                                                                    <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='name'/></div><div><button className={styles.save} onClick={onSaveElement}>Save</button></div></div>
                                                                                </>
                                                                            :
                                                                                <>
                                                                                    <div className={styles.error}><ErrorTooltip position='bottom-left' errors={e.errors.nameErr}><div className={e.errors.nameErr?.length > 0 ? styles.title : styles.content}>{e.name}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditStore(index, j, z, null, 'name', 'store')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                </>
                                                                            }
                                                                            </td>
                                                                            <td className={styles.tableCol}>
                                                                                {editIndex === index && subEditIndex === j && section === z && editField === 'storeId' ? (
                                                                                    <>
                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='storeId'/></div><div><button className={styles.save} onClick={() => {handleSaveStore('Store')}}>Save</button></div></div>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={e.errors.idErr}><div className={e.errors.idErr?.length > 0 ? styles.title : styles.content}>{e.storeId ? e.storeId : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditStore(index, j, z, null, 'storeId', 'store')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                    </>
                                                                                )}
                                                                            </td>
                                                                            <td className={styles.tableCol}>
                                                                                {x.name ? x.name : '-'}
                                                                            </td>
                                                                            <td className={styles.tableCol}>
                                                                                {editIndex === index && subEditIndex === j && section === z && editField === 'storeCategoryId' ? (
                                                                                    <>
                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='storeCategoryId'/></div><div><button className={styles.save} onClick={() => {handleSaveStore('category', x.storeCategoryId)}}>Save</button></div></div>
                                                                                    </>
                                                                                    ) : (
                                                                                    <>
                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x.errors.catIdErr}><div className={e.errors.catIdErr?.length > 0 ? styles.title : styles.content}>{x.storeCategoryId ? x.storeCategoryId : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditStore(index, j, z, null, 'storeCategoryId', 'category')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                    </>
                                                                                )}
                                                                            </td>
                                                                            <td className={styles.tableCol}>
                                                                                {editIndex === index && subEditIndex === j && section === z && editField === 'contentId' ? (
                                                                                    <>
                                                                                        <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='contentId'/></div><div><button className={styles.save} onClick={() => {handleSaveStore('Content')}}>Save</button></div></div>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <div className={styles.error}><ErrorTooltip position='bottom-left' errors={content.errors?.contentIdErr}><div className={content.errors.contentIdErr?.length > 0 ? styles.title : styles.content}>{content.contentId ? content.contentId : '-'}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditStore(index, j, z, null, 'contentId', 'content')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                    </>
                                                                                )}
                                                                            </td>
                                                                            <td className={styles.tableCol}>
                                                                                
                                                                            </td>
                                                                        </tr>
                                                                        {activeIndexes.includes(index) && categoryActiveIndex.includes(j) && contentActiveIndex.includes(z) && content.storeContentPrice && content.storeContentPrice?.length > 0 && (
                                                                            <>
                                                                                <tr className={styles.tableRow}>
                                                                                    <td colspan={16} aria-colspan={16} colSpan={16}>
                                                                                        <table className={styles.accordianTable}>
                                                                                            <thead className={styles.accordianTableHead}>
                                                                                                <th className={styles.accordianTableHeading}></th>
                                                                                                <th className={styles.accordianTableHeading}></th>
                                                                                                <th className={styles.accordianTableHeading}>Currency Type</th>
                                                                                                <th className={styles.accordianTableHeading}>Currency ID</th>
                                                                                                <th className={styles.accordianTableHeading}>Price Amount</th>
                                                                                                <th className={styles.accordianTableHeading}>Discount</th>
                                                                                                <th className={styles.accordianTableHeading}>Bonus Cash Allowance</th>
                                                                                                <th className={styles.accordianTableHeading}>Real World Currency ID</th>
                                                                                                <th className={styles.accordianTableHeading}>Platform</th>
                                                                                                <th className={styles.accordianTableHeading}>Product ID</th>
                                                                                            </thead>
                                                                                            <tbody className={styles.accordianTableBody}>
                                                                                                {content.storeContentPrice && content.storeContentPrice.map((x, i) => {
                                                                                                    return(
                                                                                                        <>
                                                                                                            <tr className={styles.accordianTableRow}>
                                                                                                                <td className={styles.accordianTableCol} colSpan={2}></td>
                                                                                                                <td className={styles.accordianTableCol}>
                                                                                                                    {editIndex === index && subEditIndex === j && section === z && segment === i && editField === 'priceTypes' ? (
                                                                                                                        <>
                                                                                                                            <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='priceTypes'/></div><div><button className={styles.save} onClick={() => {handleSaveStore('Pricing')}}>Save</button></div></div>
                                                                                                                        </>
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.currencyTypeErr}><div className={x?.errors?.currencyTypeErr?.length > 0 ? styles.title : styles.content}>{x.priceTypes === 'virtual currency' ? 'virtual' : x.priceTypes === 'RMG' ? 'RMG' : x.priceTypes}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditStore(index, j, z, i, 'priceTypes', 'pricing')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </td>
                                                                                                                <td className={styles.accordianTableCol}>
                                                                                                                    {editIndex === index && subEditIndex === j && section === z && segment === i &&  editField === 'currencyClientID' ? (
                                                                                                                        <>
                                                                                                                            <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='currencyClientID'/></div><div><button className={styles.save} onClick={() => {handleSaveStore('Pricing')}}>Save</button></div></div>
                                                                                                                        </>
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.currencyIdErr}><div className={x?.errors?.currencyIdErr?.length > 0 ? styles.title : styles.content}>{x.currencyClientID}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditStore(index, j, z, i, 'currencyClientID', 'pricing')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                                        </>
                                                                                                                    )}  
                                                                                                                </td>
                                                                                                                <td className={styles.accordianTableCol}>
                                                                                                                    {editIndex === index && subEditIndex === j && section === z && segment === i && editField === 'price' ? (
                                                                                                                        <>
                                                                                                                            <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='price'/></div><div><button className={styles.save} onClick={() => {handleSaveStore('Pricing')}}>Save</button></div></div>
                                                                                                                        </>
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.amountErr}><div className={x?.errors?.amountErr?.length > 0 ? styles.title : styles.content}>{x.price}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditStore(index, j, z, i, 'price', 'pricing')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </td>
                                                                                                                <td className={styles.accordianTableCol}>
                                                                                                                    {editIndex === index && subEditIndex === i && editField === 'discount' ? (
                                                                                                                        <>
                                                                                                                            <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='discount'/></div><div><button className={styles.save} onClick={() => {handleSaveStore('Pricing')}}>Save</button></div></div>
                                                                                                                        </>
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.discountErr}><div className={x?.errors?.discountErr?.length > 0 ? styles.title : styles.content}>{x.discount}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditStore(index, j, z, i, 'discount', 'pricing')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </td>
                                                                                                                <td className={styles.accordianTableCol}>
                                                                                                                    {editIndex === index && subEditIndex === i && editField === 'bonusCashAllowance' ? (
                                                                                                                        <>
                                                                                                                            <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='bonusCashAllowance'/></div><div><button className={styles.save} onClick={() => {handleSaveStore('Pricing')}}>Save</button></div></div>
                                                                                                                        </>
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.BCAErr}><div className={x?.errors?.BCAErr?.length > 0 ? styles.title : styles.content}>{x.bonusCashAllowance}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditStore(index, j, z, i, 'bonusCashAllowance', 'pricing')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </td>
                                                                                                                <td className={styles.accordianTableCol}>
                                                                                                                    {x.realWorldCurrencyId ? x.realWorldCurrencyId : 'N/A'}
                                                                                                                </td>
                                                                                                                <td className={styles.accordianTableCol}>
                                                                                                                    {editIndex === index && subEditIndex === i && editField === 'gamePlatformMasterId' ? (
                                                                                                                        <>
                                                                                                                            <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='gamePlatformMasterId'/></div><div><button className={styles.save} onClick={() => {handleSaveStore('Pricing')}}>Save</button></div></div>
                                                                                                                        </>
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.platformErr}><div className={x?.errors?.platformErr?.length > 0 ? styles.title : styles.content}>{x.gamePlatformMasterId === 1 ? 'Android' : x.gamePlatformMasterId === 2 ? 'iOS' : x.gamePlatformMasterId}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditStore(index, j, z, i, 'gamePlatformMasterId', 'pricing')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </td>
                                                                                                                <td className={styles.accordianTableCol}>
                                                                                                                    {editIndex === index && subEditIndex === i && editField === 'productId' ? (
                                                                                                                        <>
                                                                                                                            <div className={styles.error}><div><input className={styles.input} type="text" value={editValue} onChange={handleChange} name='productId'/></div><div><button className={styles.save} onClick={() => {handleSaveStore('Pricing')}}>Save</button></div></div>
                                                                                                                        </>
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            <div className={styles.error}><ErrorTooltip position='bottom-left' errors={x?.errors?.productErr}><div className={x?.errors?.productErr?.length > 0 ? styles.title : styles.content}>{x.productId}</div></ErrorTooltip><div className={styles.editIcon} onClick={() => handleEditStore(index, j, z, i, 'productId', 'pricing')}><Icon className={styles.edit} name='edit' size='12'/></div></div>
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </>
                                                                                                    )
                                                                                                })}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                            </>
                                                                        )}
                                                                    </>
                                                                ))}
                                                            </>
                                                        ))
                                                    : 
                                                        (<></>)
                                                )}
                                                </>   
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
               </div>
            </div>
        </>
    )
}

export default FileImport