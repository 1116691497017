import React, { useState } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../../../Checkbox";
import Icon from "../../../../../../Icon";
import Control from "./Control/index";
import { useParams } from "react-router-dom";
import {ReactComponent as Add} from '../../../../../../../media/images/icons/add_meta.svg'
import {ReactComponent as Remove} from '../../../../../../../media/images/icons/remove.svg'
import Bundles from '../../../../../../../media/images/placeholders/Bundles.png'
import { useEffect } from "react";
import CurrencyTooltip from '../../../../../../CustomTooltips/CurrencyTooltip'
import { extarctImageFromUrlAction } from "../../../../../../../redux/action/common/commonAction";
import { useDispatch } from "react-redux";
import SnapShot from "../../../../../../Images";
/* Deleted Schedule, Modal, Modal Products and Actions */

const Row = ({tableFor,fullList, item, items, index, setValue, setData,categoryIndex }) => {
    
    const {appid} = useParams()
    // const [show, setShow]=useState(false);
    // function doesItemExistInContent() {
    //     const contentsrow = JSON.parse(sessionStorage.getItem('contents'));
    //     const content=contentsrow[categoryIndex];
    //     if (!Array.isArray(content)) {
    //         setShow(false);
    //       }
    //     setShow(content?.some(val => val.bundleId === item.id) || setShow(false));
    // }

    const addContent = (item, index) => {
            let itemList = [...items]
            itemList[index].status = true
            let amp = fullList
            amp[categoryIndex]=itemList
            setData(amp)
            let content = JSON.parse(sessionStorage.getItem('contents'));
            let priceArr=[]
            item.inventoryBundlesPrices && item.inventoryBundlesPrices.map((e,i)=>{
                const { id, ...B } = e;
                priceArr.push(B)
            })
            let newContent = {iconUrl : item.iconUrl, name : item.name, bundleId : item.id, quantity : null, displayId : item.bundleId, priceList: priceArr}
            if (content[categoryIndex] === null || content[categoryIndex] === undefined) {
                let tmpARR=[]
                tmpARR.push(newContent)
                content[categoryIndex]={storeContent:tmpARR}
            } else {
                content[categoryIndex].storeContent.push(newContent)
            }
            // setValue(content)
            sessionStorage.setItem('contents', JSON.stringify(content))
            //doesItemExistInContent()
    }

    const RemoveContent = (item, id) => {
            let itemList = [...items]
            itemList[index].status=false
            itemList[index].quantity = null
            let amp = fullList
            amp[categoryIndex]=itemList
            setData(amp)
            let content = JSON.parse(sessionStorage.getItem('contents'))
            let removedReward = content[categoryIndex].storeContent
            let remainingRewards = removedReward.filter((e,i,c)=> {
                return e.bundleId != id
            })
            content[categoryIndex].storeContent=remainingRewards
            // setValue(content)
            sessionStorage.setItem('contents', JSON.stringify(content))
            //doesItemExistInContent()
    }

    const onInputChange = (e,id) => {
            let itemList = [...items]
            itemList[index].quantity = parseInt(e.target.value)
            let amp = fullList
            amp[categoryIndex]=itemList
            setData(amp)
            const content = JSON.parse(sessionStorage.getItem('contents'))
            const updatedArray = content[categoryIndex].storeContent.map(item => {
                if (item.bundleId === id) {
                return { ...item, quantity: parseInt(e.target.value) };
                }
                return item;
            });
            content[categoryIndex].storeContent=updatedArray
            // setValue(content)
            sessionStorage.setItem('contents', JSON.stringify(content))
    }

    useEffect(()=>{

    }, [item])

    return (
        <>
            <div className={styles.row}>
                {/* {show? */}
                {item.status !== false ?
                     <div className={styles.col}><Remove className={styles.addIcon} onClick={()=>{RemoveContent(item, item.id)}}/></div>
                :
                    <div className={styles.col}><Add className={styles.addIcon} onClick={()=>{addContent(item, index)}}/></div>
                }
                <div className={styles.col}>
                    <div className={styles.item}>
                        <div className={styles.modalPreview}>
                            <SnapShot id={`bundleIcon_${item.id}`} url={item.iconUrl} alt={item.name} placeholder={Bundles} type='Images' width={36} height={36}/>
                            {/* <img id={`bundleIcon_${item.id}`} alt="Bundle" /> */}
                        </div>
                        <div className={styles.details}>
                            <div>{item.name}</div>
                            <div className={styles.itemId}>{item.bundleId}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.col}><div className={styles.modalItemName}>{item.name}</div><div className={styles.modalItemId}>{item.bundleId}</div></div>
                <div className={styles.col}>
                    {item.inventoryBundlesPrices && item.inventoryBundlesPrices.slice(0,3).map((e,i)=>{
                        if(e.priceTypes == 'virtual currency' || e.priceTypes == 'RMG' || e.priceTypes == 'real money' || e.priceTypes == 'virtual'){
                            return (
                                <>
                                    <div className={styles.prices}>
                                        <span className={e.priceTypes == 'virtual currency' ? styles.virtual : styles.real}>{e.currency.code}<span>&nbsp;</span>{e.price}</span>
                                        {(item.inventoryBundlesPrices && item.inventoryBundlesPrices.length > 3 && i === 2) ?
                                            <CurrencyTooltip type='item' text='Currency' data={item.inventoryBundlesPrices}><span className={styles.count}>+ {item.inventoryBundlesPrices.length - 3}</span></CurrencyTooltip> 
                                        : ''}
                                    </div>
                                </>
                            )
                        }else if(e.priceTypes == 'IAP'){
                            return(
                                <>
                                    <div className={styles.prices}>
                                        <span className={styles.IAP}>{e.realWorldCurrency.code}<span>&nbsp;</span>{e.price}</span>
                                        {(item.inventoryItemPrices && item.inventoryItemPrices.length > 3 && i === 2) ? 
                                            <CurrencyTooltip type='item' text='Currency' data={item.inventoryItemPrices}><span className={styles.count}>+ {item.inventoryItemPrices.length - 3}</span></CurrencyTooltip> 
                                        : ''}
                                    </div>
                                </>
                            )
                        }
                    })} 
                </div>
                <div className={styles.col}><div className={styles.rewardsInput}><input disabled={item.status == false ? true : false} key={item.id} className={styles.quantity} type="number" onWheelCapture={e => {e.currentTarget.blur()}} value={items[index].status == false ? '' : items[index].quantity} onChange={(e)=>{onInputChange(e, item.id)}}/></div></div>
            </div>
        </>
    );
};

export default Row;
