import React, {useEffect, useRef, useState} from 'react'
import cn from "classnames";
import styles from './OrgProfile.module.sass'
import Card from "../../../../../components/Card";
import Form from '../../../../../components/Form';
import Profile from './Profile'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer , toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import Icon from '../../../../../components/Icon';
import TextInput from '../../../../../components/TextInput';
import Toast from '../../../../../components/Toast';
import { editOrgProfileAction, getOrgProfileAction, getUserProfileAction } from '../../../../../redux/action/organisation/ProfileAction/profileAction';
import Address from './Address';
import { extarctImageFromUrlAction, getAllMetaDataAction } from '../../../../../redux/action/common/commonAction';
import OrganizationHeader from '../../../../../components/Header/OrganizationHeader'
import axios from 'axios';
import { BASE_URL } from '../../../../../resources/APIEndpoints';
import ProfilePlaceholder from '../../../../../media/images/placeholders/Profile_Placeholder.png'
import { useFlags } from "launchdarkly-react-client-sdk";
import SnapShot from '../../../../../components/Images';
import { useAccess } from '../../../../../components/Page';
const OrgProfile = ({className, setAppName, setShowLoader}) => {

    const {appid} = useParams()
    const dispatch = useDispatch()
    const logoRef = useRef()

    const [UID, setUID] = useState('')
    const placeholder = ProfilePlaceholder
    const [{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'})
    const [profile, setProfile] = useState(null)
    const { accessPermissions } = useAccess(); 
    const { allowOrganizationSettingsEdit} = accessPermissions || {};

    const uploadLogo = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        } 
        let folderName = 'org-assets'
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}&category=${folderName}&fileName=${file.name}`)
        console.log(res)
        setProfile(res.data.getUrl)
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
            toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        })
        .catch(function (error) {
            toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        });
    }

        // To Convert Base64 to Binary
    function convertDataURIToBinary(dataURI) {
        var BASE64_MARKER = ';base64,';
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));     
        for(var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }

    const cancel = () => {
        setLogo({src: placeholder , alt: 'Upload an Image'})
        setProfile(null)
        logoRef.current.value = ''
    }

        
    // To Convert file into base64 format
    const convertbase64 = (file) => {
        return new Promise((resolve, err)=>{
            const fileReader = new FileReader();
            fileReader.onload = (eve) =>{
                resolve(fileReader.result)
            }
            fileReader.readAsDataURL(file)
        })
    }
    
    const copyUIDClipboard = () => {
        const tempTextArea = document.createElement("textarea");
        tempTextArea.value = UID;
        document.body.appendChild(tempTextArea);
        tempTextArea.select();
        document.execCommand("copy");
        document.body.removeChild(tempTextArea);
        toast.success(<Toast type='Success' messages='copied to clipboard'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
    }

    let OrgProfile = useSelector((state) => {
        return state.organisation.orgProfile
    })
    
    let locationOption = useSelector((state) => {
        return state.common.metaData.countryMaster
    })

    useEffect(() => {
        getAllLocation()
    }, [])

    const getAllLocation = () => {
        dispatch(getAllMetaDataAction())
    }

    const configureProfile = async() => {
        setLogo({src: OrgProfile.defaultLogoUrl, alt: 'Upload an Image'}); 
        setProfile(OrgProfile.defaultLogoUrl)
    }

    // const extractImageFromCdn = async(url) => {
    //     if(url !== null && url !== undefined && url.includes('cdn')){
    //         try {
    //             const response = await dispatch(extarctImageFromUrlAction(url))
    //             const logo = document.getElementById(`org_Logo`)
    //             if(logo){
    //                 logo.src = response
    //             }
    //         }catch (error){
    //             console.error('Error fetching image:', error);
    //         }
    //     }else{
    //         const logo = document.getElementById(`org_Logo`)
    //         if(logo){
    //             logo.src = ProfilePlaceholder
    //         }
    //     }
    // }

    useEffect(() => {
        configureProfile()
    }, [OrgProfile])

    const save = async () => {
        let orgDetails = {
            defaultLogoUrl : profile,
            organisationId: localStorage.getItem('orgId')
        }
        try {
            await dispatch(editOrgProfileAction(orgDetails, setAppName))
        } catch (error) {
            console.log(error)
        }
    }

    const [isChanged, setIsChanged] = useState(true)

    const checkUrlCheck = () => {
        if(profile !== OrgProfile.defaultLogoUrl){
            setIsChanged(false)
            return
        }
        setIsChanged(true)
    }

    useEffect(() => {
        checkUrlCheck()
    }, [profile])
 
    return (
        <>
            <ToastContainer />
            <OrganizationHeader title='Organisation Settings' />
            <div className={styles.wrapper}>
            <Card className={cn(styles.card, className)}>
                <div className={styles.groups}>
                    <div className={styles.playerInformation}>
                        <div className={styles.preview}>
                            <div className={styles.primaryInfo}>
                                <div className={styles.preview}>
                                    <div className={styles.previewImage}>
                                        <SnapShot id='org_Logo' url={profile} alt={OrgProfile.name} placeholder={ProfilePlaceholder} type='Images' width={100} height={100} borderRadius={16}/>
                                        {/* <img id='org_Logo' alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img> */}
                                        {allowOrganizationSettingsEdit&& <label for='icon' className={styles.edit}>
                                            <Icon name='edit' size='12' />
                                        </label>}
                                    </div>
                                    <div className={styles.actions}>
                                        <div className={styles.previewInput}> 
                                            <input disabled={false}  id={`icon`} type="file" className='file-input' accept="image/jpeg, image/png, .jpeg, .jpg, .png, .webp" ref={logoRef} onChange={(e)=>{uploadLogo(e)}}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.otherInfo}>
                                    <div className={styles.orgName}>
                                        <span className={styles.userName}>{OrgProfile && OrgProfile.name != null && OrgProfile.name != '' ? OrgProfile.name : '-'}</span>
                                    </div>

                                    <div className={styles.secondaryInfo}>
                                        <div className={styles.playerId}>
                                            <div className={styles.ids}>
                                                <span className={styles.label}>Org ID:&nbsp;</span><span className={styles.UIDNO}>{OrgProfile && OrgProfile.id != null && OrgProfile.id != '' ? OrgProfile.id : '-'}<span className={styles.copyToClipboard} onClick={copyUIDClipboard}><Icon name='copy' size='16' /></span></span>
                                            </div>
                                        </div>
                                        <div className={styles.playerLocation}>
                                            <div className={styles.ids}>
                                                <span className={styles.label}>Created At:&nbsp;</span><span className={styles.UUIDNO}>{OrgProfile && OrgProfile.createdAt != null && OrgProfile.createdAt != '' ? moment(OrgProfile.createdAt).format('DD/MM/YYYY HH:mm A') : '-'}</span>
                                            </div>
                                        </div>
                                    </div>
                                    {!isChanged &&
                                        <div className={styles.actionButtons}>
                                            <button className={cn(styles.button, 'button-tiny')} onClick={save}>Save</button>
                                            <button className={cn(styles.button, 'button-cancel-tiny')} onClick={cancel}>Cancel</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Card>
            <Profile data={OrgProfile} setAppName={setAppName} setShowLoader={setShowLoader} locationOption={locationOption}/>
            <Address data={OrgProfile} setAppName={setAppName} setShowLoader={setShowLoader} locationOption={locationOption}/>
            </div>
        </>
    )
}

export default OrgProfile