import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Dropdown.module.sass";
import { Link, NavLink, useLocation } from "react-router-dom";
import Icon from "../../Icon";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useAccess } from "../../Page";
const Dropdown = ({ className, email, item, visibleSidebar, setValue, onClose, onProceed, onCls, NavTo}) => {
    const [visible, setVisible] = useState(false);

    const { pathname } = useLocation();
    const { accessPermissions } = useAccess();  
    const { isAdminEmail,allowGames,allowMatches,allowPlayers,allowLeaderboard, allowVirtualCurrency,allowItem,allowBundle,allowStore,allowProgressionMarker,allowProgressionSystem,allowDefaultEvent,allowCustomEvent,allowRMGCurrency,allowSpecterBank,allowPayout,allowTask,allowMission,allowStepSeries,allowTournament,allowInstantBattle} = accessPermissions || {};
    
    const handleClick = () => {
        setVisible(!visible);
        setValue(true);
    };

    const normalizeString = (str) => {
        return str
          .replace(/[^a-zA-Z0-9]/g, '') // Remove all non-alphanumeric characters
          .toLowerCase();               // Convert to lowercase (optional based on case sensitivity)
    }

    const checkActiveLinks = (pathname, active) => {
        let current = normalizeString(pathname)
        let activeLink = normalizeString(active?.title)
        if(activeLink?.endsWith('s')){
            if(activeLink === 'achievements'){
                return (current.includes('task') || current.includes('mission') || current.includes('step'))
            }else if(activeLink === 'matches'){
                return current.includes(activeLink?.slice(0, -2)?.toLowerCase())
            }else{
                return current.includes(activeLink?.slice(0, -1)?.toLowerCase())
            }
        }else{
            if(activeLink === 'economy'){
                return (current.includes('currency') || current.includes('item') || current.includes('bundle')|| current.includes('store'))
            }else if(activeLink === 'rmg'){
                return (current.includes('rmg') || current.includes('specter') || current.includes('payout'))
            }
            else{
                return current.includes(activeLink?.toLowerCase())
            }
        }
    }

    const Head = () => {
        if (
            (!allowGames&&!allowMatches&&!allowVirtualCurrency&&!allowItem&&!allowBundle&&!allowStore&&!allowProgressionMarker&&!allowProgressionSystem&&!allowDefaultEvent&&!allowCustomEvent&&!allowRMGCurrency&&!allowSpecterBank&&!allowPayout&&item?.slug=="build")||
            (!allowPlayers&&!allowTask&&!allowMission&&!allowStepSeries&&!allowTournament&&!allowInstantBattle&&!allowLeaderboard&&item?.slug=="engage")
        ) {
            return
        }
        return (
            <button
                className={cn(
                    styles.head,
                    {
                        [styles.active]: pathname.includes(item.slug),
                    },
                    { [styles.wide]: visibleSidebar }
                )}
                onClick={() => handleClick()}
            >
                <span className={styles.sideBarIcon}>{item.icon}</span>
                {item.title}
                <Icon name="arrow-down" size="24" />
            </button>
        );
    };

    return (
        <div
            className={cn(
                styles.dropdown,
                className,
                { [styles.active]: visible },
                {
                    [styles.active]: pathname.includes(item.slug),
                },
                { [styles.wide]: visibleSidebar }
            )}
        >
            {item.add ? (
                <div
                    className={cn(styles.top, {
                        [styles.active]: pathname.startsWith("/products/add"),
                    })}
                >
                    <Head />
                    <Link
                        className={cn(styles.add, {
                            [styles.active]:
                                pathname.startsWith("/products/add"),
                        })}
                        to="/products/add"
                        onClick={onClose}
                    >
                        <Icon name="plus" size="10" />
                    </Link>
                </div>
            ) : (
                <Head />
            )}
            <div className={styles.body}>
                {item.dropdown.map((x, index) => (
                    ((
                        // (!isAdminEmail&& x?.url?.includes('/RMG'))||
                        (!allowGames && x?.url?.includes('/games'))||
                        (!allowMatches && x?.url?.includes('/matches'))||
                        (!allowPlayers && x?.url?.includes('/players'))||
                        (!allowLeaderboard && x?.url?.includes('/leaderboard'))||
                        (x?.url=='')
                    )?
                    ""
                    :
                    <div
                        className={cn(styles.link, {
                            [styles.active]:  checkActiveLinks(pathname, x)
                        })}
                        onClick={()=>NavTo(x.url)}
                        key={index}
                    >
                        {x.title}
                        {x.counter ? (
                            <div
                                className={styles.counter}
                                style={{ backgroundColor: x.colorCounter }}
                            >
                                {x.counter}
                            </div>
                        ) : (
                            <Icon name="arrow-next" size="24" />
                        )}
                    </div>)
                ))}
                {/* {item.dropdown.map((x, index) => (
                        <div
                            className={cn(styles.link, {
                                [styles.active]:  checkActiveLinks(pathname, x)
                            })}
                            onClick={()=>NavTo(x.url)}
                            key={index}
                        >
                            {x.title}
                            {x.counter ? (
                                <div
                                    className={styles.counter}
                                    style={{ backgroundColor: x.colorCounter }}
                                >
                                    {x.counter}
                                </div>
                            ) : (
                                <Icon name="arrow-next" size="24" />
                            )}
                        </div>
                    ))} */}
            </div>
        </div>
    );
};

export default Dropdown;
