import React, { useContext, useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./otp.module.sass";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProfilePlaceHolder from '../../../../media/images/placeholders/Profile_Placeholder.png'
import { extarctImageFromUrlAction } from "../../../../redux/action/common/commonAction";
import axios from "axios";
import { BASE_URL, GET_ACCESS_CONFIG} from '../../../../resources/APIEndpoints';
import Loader from "../../../Loaders/LoaderProgress";
import { useAccess } from "../../../Page";
const SpaceModal = ({onClose, setWorkSpace, spaces, showLoader, setShowLoader, setLoaderType, showModal, setShowQuickGuideModal}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selected, setSelected] = useState(null)
  const [show, setShow] = useState(false)
  // const { accessPermissions,setAccessPermissions } = useAccess();

  const configureOrg = async() => {
    let workSpacesList = await Promise.all(spaces && spaces.map(async element => (
      {
        ...element,
        default_logo_url : (element.default_logo_url != null ? await extractImageFromCdn(element.default_logo_url) : ProfilePlaceHolder),
      }
    )))
    setWorkSpace(workSpacesList)
    setShow(true)
  }
  
  const extractImageFromCdn = async(url) => {
    if(url !== null && url !== undefined && url.includes('cdn')){
      try {
        const token = localStorage.getItem('token')
        const headers = {responseType: 'blob', headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : spaces[0]?.api_keys?.devAPIKey}}
        let res = await axios.get(url, headers)
        let imageBlobUrl = await URL.createObjectURL(res.data)
        return imageBlobUrl
      }catch (error){
        console.error('Error fetching image:', error);
      }
    }
  }
  // const getOrgFlagsAccessConfig = async (orgId,userId,userEmail) => {
  //   const token = localStorage.getItem('token')
  //   const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ?  JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ?  JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey :  JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
  //   let getAppAccess = {
  //   "organisationId": orgId,
  //   // "memberIds": [userId],
  //   "memberEmails": [userEmail],
  //   "env": ["global"]
  //   // "projectIds": ["a5e0ea8a-f01b-4cf1-b08a-44dddf0a6b41"]
  //   }
  //   const orgFlagData = await axios.post(BASE_URL + GET_ACCESS_CONFIG, getAppAccess, headers);
  //   const orgFlagArr =orgFlagData.data.data.accessDetails.filter(e=>(e?.orgFlags?.length>0))?.[0]?.orgFlags
    
  //   const existingFlags = accessPermissions || {};
  //   // Update the existing Flags object with the new flags data
  //   orgFlagArr&&orgFlagArr.forEach(({ key, selected }) => {
  //       existingFlags[key] = selected; // Replace or add the flag
  //   });
  //   // Store the updated Flags object back in localStorage
  //   setAccessPermissions(existingFlags); 
  // }

  const save = () => {
    let selectedOrg = spaces && spaces.filter(e => e.id === selected)
    let apiKeys = selectedOrg[0].api_keys
    localStorage.setItem('apiKeys', JSON.stringify(apiKeys))
    let orgId = selectedOrg[0].id
    localStorage.setItem('orgId', orgId)
    let orgName = selectedOrg[0].name
    localStorage.setItem('orgName', JSON.stringify(orgName))
    let location = selectedOrg[0]?.location
    sessionStorage.setItem('location', JSON.stringify(location)) 
    let customerId = selectedOrg[0]?.external_customer_id
    sessionStorage.setItem('customerId', JSON.stringify(customerId)) 
    let gstTreatment = selectedOrg[0]?.gst_treatment
    sessionStorage.setItem('gstTreatment', JSON.stringify(gstTreatment))
    // getOrgFlagsAccessConfig(orgId,JSON.parse(localStorage.getItem('user'))?.id,JSON.parse(localStorage.getItem('user'))?.email)
    navigate('/home')
    setShowLoader(true)
    setLoaderType(1)
    setTimeout(() => {
      setShowLoader(false)
      if(showModal){
        setShowQuickGuideModal(true)
      }
    }, 4000)
  }

  const selectOrg = (x) => {
    let workSpace = spaces && spaces.map(item => item.id === x ? {...item, selected: true} : {...item, selected: false})
    setWorkSpace(workSpace)
    setSelected(x)
  }

  useEffect(() => {
    configureOrg()
  }, [showModal])

  return (
    <>
      <ToastContainer />
      <div className={styles.otpScreen}>
        <div className={styles.otp_outer_container}>
          <div className={styles.otp_inner_container}>
            <div className={styles.otpWrapper}>
              <div className={styles.wrapper}>
                <div className={styles.code}>
                  <div className={styles.body}>
                    <div className={styles.title}>Select Organisation<span className={styles.blueText}>!</span></div>
                    <div className={styles.info}>Please select one organisation to continue.</div>
                    
                    <div className={styles.selection}>
                      {!show && <div className={styles.loader}><Loader /></div>}
                      {show && spaces && spaces.map(item => (
                        <>
                          <div className={cn(styles.workSpace, {[styles.active] : item.selected})} onClick={() => selectOrg(item.id)}>
                            <div className={styles.logo}>
                              <img src={(item.default_logo_url && item.default_logo_url !== null) ? item.default_logo_url : ProfilePlaceHolder} alt={item.name}/>
                            </div>
                            <div className={styles.detail}>
                              <div className={styles.name}>{item.name}</div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>

                    <div className={styles.verifyAccount}>
                      <button disabled={selected === null} className={cn("button-save-small-full", styles.button)} onClick={save}>
                        <span>Continue</span>
                      </button>
                    </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpaceModal;
