import { takeEvery, call, put, delay } from 'redux-saga/effects';
import axios from 'axios';
import { START_STORE_API_CALLS, storeApiCallSuccess, storeApiCallFailure, endStoreApiCall } from '../../actions/stores/storeUploadAction';
import customAxios from '../../../utils/interceptor';
import { BASE_URL, ADD_STORES, EDIT_STORES } from '../../../resources/APIEndpoints';

// API function to call the placeholder endpoint
function fetchApi(payload) {
    // We're calling the posts API, and payload can be used for query params if needed
    const url = BASE_URL + ADD_STORES
    return customAxios.post(url, payload);
}

// Worker saga: handles the side effects
function* handleApiCalls(action) {
    const {payloads, getAction} = action; 
    const payload = payloads.stores
    // Array of payloads to send with each request
    for (let i = 0; i < payload.length; i++) {
        let data = {stores: [payload[i]]}
        try {
          console.log(data, 'Data To API')
          // Try to make the API call for the current payload
          const response = yield call(fetchApi, data);
          // Dispatch success action if the API call succeeds
          yield put(storeApiCallSuccess(response.data, payload.length));
      } catch (error) {
          // Dispatch failure action if the API call fails
          yield put(storeApiCallFailure(error.message, payload.length));
      }       
      // Wait for 5 seconds between each API call
      yield delay(10000);
    }
    yield delay(2000)
    getAction()
    yield put(endStoreApiCall());
}

// Watcher saga: watches for START_API_CALLS action
export default function* watchApiCallsStores() {
  yield takeEvery(START_STORE_API_CALLS, handleApiCalls);
}