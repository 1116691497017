import React, { useContext, useEffect, useState } from "react";
import cn from "classnames";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "./getSpecter.module.sass";
import Icon from "../../../../../../components/Icon";
import Card from "../../../../../../components/Card";
import CurrencyTable from "../../../../../../components/Table";
import { getAllCurrencyAction, addCurrecyAction, filterCurrencyAction, getRMGTransactionAction } from "../../../../../../redux/action/builds/economy/currency/currencyAction";
import Loader from "../../../../../../components/Loaders/LoaderProgress";
import EmptyState from '../../../../../../components/EmptyStates'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import { Skeleton } from '@mui/material';
import SkeletonTable from '../../../../../../components/Skeleton/TableSkeleton'
import SkeletonEmptyState from "../../../../../../components/Skeleton/EmptyStateSkeleton";
import GlobalStateContext from "../../../../../../components/Context/GlobalStates/GlobalStateContext";
import { ToastContainer } from "react-toastify";
import Navigation from '../../../../../../components/Header/SecondaryNavigation'
import TransactionTable from '../../../../../../components/BuildComponents/RMC/TransactionTable'
import EmptyStates from "../../../../../../components/EmptyStates";

const SpecterBank = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {id, appid} = useParams()

  // Pagination
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)

  // Search
  const [search, setSearch] = useState(null)
  const [currency, setCurrency] = useState(null)
  const [currencyId, setCurrencyId] = useState(null)
    
  const getTransaction = () => {
      let transaction = {
        currencyId: currencyId,
        projectId: appid,
        offset: 0,
        limit: 10,
        // Pagination
        offset: ((page-1)*limit),
        limit: limit
      }
      dispatch(getRMGTransactionAction(transaction))
  }

  const getCurrecy = async(search) => {
      const getCurrencyData = {
        projectId : appid,
        offset: 0,
        limit: 1000,
        //Sort
        sortOrder : 'asc',
        // Search
        ...(search && search !== null && search !== '' && {search : search}),
        // Filter
        types: ['real'],
      }
      await dispatch(filterCurrencyAction(getCurrencyData))
  }
  
  let currencies = useSelector((state)=>{ 
      return state.currencies.currency
  })
  
  let rmgTransaction = useSelector((state)=>{ 
    return state.currencies.rmgTransaction
  })
  
  let isLoading = useSelector((state) => {
    return state.currencies.isLoading
  })
  
  let totalCount = useSelector((state)=>{
    return state.currencies.totalCount
  })

  const configureSelectedCurrency = () => {
    if(currencies && currencies?.length > 0){
      setCurrency(currencies[0].name)
      setCurrencyId(currencies[0].id)
    }
  }

  useEffect(() => {
    configureSelectedCurrency()
  }, [currencies])
  
  useEffect(() => {
    getCurrecy()
  }, [])

  useEffect(() => {
    if(currencyId !== null){
      getTransaction()
    }
  }, [currencyId, page])

  const onSearch = (search) => {
    if(search == ''){
      setSearch(null)
      getCurrecy(null)
    }else{
      setSearch(search)
      getCurrecy(search)
    }
  }

  const handlePageChange = (event, value)  => {
    setPage(value)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  let terminalDetails = {search: search, setSearch: setSearch, value: currency, setValue: setCurrency, setId: setCurrencyId, onSearch: onSearch, options: currencies}

  // Loader
  if(isLoading){
    return(
      <>
        <div className={styles.loader}>
          <Loader />
        </div>
      </>
    )
  }

  return(
      <>
        <ToastContainer />
        <Navigation type='Specter Bank' terminalDetails={terminalDetails}/>
        <Card layOut={`Get`} className={styles.card} classCardHead={styles.head} >
            <div className={styles.wrapper}>
              {!isLoading && rmgTransaction?.length === 0 &&
                <EmptyStates type="Specter Bank" />
              }
              {!isLoading && rmgTransaction?.length > 0 &&
                <TransactionTable items={rmgTransaction}/>
              }
            </div>
            {rmgTransaction.length > 0 &&
            <div className={styles.pagination}>
              <Stack spacing={2}>
                <Pagination page={page} renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    sx={{
                      '&.MuiPaginationItem-root': {
                        minWidth: '28px',
                        height: '28px',
                        fontSize: '12px'
                      },
                      '&.Mui-selected': {
                        color: 'rgb(255, 255, 255)',
                        fontWeight : 'bold',
                        border : '1px solid rgb(42, 133, 255)',
                        background : 'rgba(42, 133, 255)'
                      },
                      '&.MuiPaginationItem-page.Mui-selected:hover' : {
                        color: 'rgb(255, 255, 255)',
                        fontWeight : 'bold',
                        border : '1px solid rgb(42, 133, 255)',
                        background : 'rgba(42, 133, 255)'
                      }
                    }}/>
                )} siblingCount={1} count={Math.ceil(totalCount/limit)} color="primary" onChange={handlePageChange} />
              </Stack>
            </div>
          }    
        </Card>
        <div className="divider"></div>
      </>
  )

};

export default SpecterBank;