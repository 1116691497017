import  {React, useEffect, useState } from 'react';
import styles from './Calendar.module.sass'
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Icon from '../../../../../components/Icon';
import moment from 'moment';
import './style.css'
import dayjs from 'dayjs';
import { Calendar, DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addDays, isWeekend, format } from 'date-fns';
import cn from 'classnames';
import Dropdown from '../../../../../components/Dropdown';
import LeaderboardCalendarDropdown from '../CalendarDropdown';


const CalendarRange = ({dates, setLeaderboardInstanceId, setLeaderboardStatus, setPlaceHolderDate, current, setCurrent, className, interval, selectionRange, setSelectionRange}) => {

    const [showCalendar, setShowCalendar] = useState(false)
    const [options, setOptions] = useState([]) 

    const configureOptions = () => {
        if(dates && dates.length > 0){
            let daily = dates && dates.map((e, i) => ({id : e.id, status: e.status, name: `Instance ${dates?.length - i} : ${moment(e.startDate).format('MMMM DD, YYYY hh:mm A')} - ${moment(e.endDate).format('MMMM DD, YYYY hh:mm A')}`}))
            const filteredObject = daily.find(obj => obj.name.includes(current));
            if(filteredObject){
                setCurrent(filteredObject.name)    
            }                                                                                   
            setOptions(daily)
            setPlaceHolderDate(false) 
        }
    }

    useEffect(() => {
        configureOptions()
    }, [dates])
    

    // const handleRangeChange = (ranges) => {
    //     if(interval === 1){
    //         setTimeout(() => {
    //             setShowCalendar(false)
    //         }, 150)
    //         setPlaceHolderDate(true)
    //         setCurrent(`${moment(ranges.selection.startDate).format('MMMM DD, YYYY')}`)
    //         setSelectionRange([{...ranges.selection, startDate : ranges.selection.startDate, endDate : ranges.selection.startDate}])
    //     }else if(interval === 2){
    //         setTimeout(() => {
    //             setShowCalendar(false)
    //         }, 150)
    //         setPlaceHolderDate(true)
    //         const weeklyRanges = getSelectedWeekInOrder(selectionRange, ranges.selection1.startDate)
    //         setCurrent(`${moment(ranges.selection1.startDate).startOf('week').format('MMMM DD, YYYY')} - ${moment(ranges.selection1.startDate).endOf('week').format('MMMM DD, YYYY')}`)
    //         setSelectionRange(weeklyRanges)
    //     }else if (interval === 3){
    //         setTimeout(() => {
    //             setShowCalendar(false)
    //         }, 150)
    //         setPlaceHolderDate(true)
    //         const monthlyRanges = getSelectedMonthInOrder(selectionRange, ranges.selection1.startDate)
    //         setCurrent(`${moment(ranges.selection1.startDate).startOf('month').format('MMMM DD, YYYY')} - ${moment(ranges.selection1.startDate).endOf('month').format('MMMM DD, YYYY')}`)
    //         setSelectionRange(monthlyRanges)
    //     }else if (interval === 4){
    //         setTimeout(() => {
    //             setShowCalendar(false)
    //         }, 150)
    //         setPlaceHolderDate(true)
    //         const yearlyRange = getSelectedYearInOrder(selectionRange, ranges.selection1.startDate)
    //         setCurrent(`${moment(ranges.selection1.startDate).startOf('year').format('MMMM DD, YYYY')} - ${moment(ranges.selection1.startDate).endOf('year').format('MMMM DD, YYYY')}`)
    //         setSelectionRange(yearlyRange)
    //     }else if(interval === 5 || interval === 6){
    //         setTimeout(() => {
    //             setShowCalendar(false)
    //         }, 150)
    //         setSelectionRange([{...ranges.selection, startDate : ranges.selection.startDate, endDate : ranges.selection.endDate}])
    //     }
    // }

    // const configureDateSelection = () => {
    //     if(interval === 1){
    //         setSelectionRange([{...selectionRange[0], startDate : moment().toDate(), endDate : moment().toDate()}])
    //     }else if(interval === 2){
    //         const startOfWeek = moment().startOf('week').toDate();
    //         const endOfWeek = moment().endOf('week').toDate();
    //         setSelectionRange([{...selectionRange[0], color: '#2A85FF', startDate : startOfWeek, endDate : endOfWeek}])
    //     }else if (interval === 3){
    //         const startOfMonth = moment().startOf('month').toDate();
    //         const endOfMonth = moment().endOf('month').toDate();
    //         setSelectionRange([{...selectionRange[0], color: '#2A85FF', startDate : startOfMonth, endDate : endOfMonth}])
    //     }else if (interval === 4){
    //         const startOfYear = moment().startOf('year').toDate();
    //         const endOfYear = moment().endOf('year').toDate();
    //         setSelectionRange([{...selectionRange[0], color: '#2A85FF', startDate : startOfYear, endDate : endOfYear}])
    //     }else if(interval === 5 || interval === 6){
    //         //setSelectionRange([{...selectionRange, startDate : ranges.selection.startDate, endDate : ranges.selection.endDate}])
    //     }
    // }

    // useEffect(() =>{
    //     configureDateSelection()
    // }, [])

    // function customDayContent(day) {
    //     if(interval == 1){
    //         let extraDot = null;
    //         const isSpecial = dates.some((specialDate) => {
    //             return (day.toDateString() === new Date(specialDate).toDateString());
    //         })
    //         if (isSpecial) {
    //         extraDot = (
    //             <div style={{height: "5px", width: "5px", borderRadius: "100%", background: "orange", position: "absolute", top: 2, right: 2}}/>
    //         )
    //         }
    //         return (
    //             <div>
    //                 {extraDot}
    //                 <span>{format(day, "d")}</span>
    //             </div>
    //         )
    //     }
    // }

    // const isCurrentDateInStartOfMonth = (currentDate, startDateOfMonth) => {
    //     const currentDateMoment = moment(currentDate);
    //     const startOfMonthMoment = moment(startDateOfMonth).startOf('month');
        
    //     return currentDateMoment.isSameOrAfter(startOfMonthMoment, 'day') && currentDateMoment.isSameOrBefore(startOfMonthMoment, 'month');
    // }

    // const getSelectedWeekInOrder = (data, current) => {
    //     // Get the current date
    //     const currentDate = new Date(current);
    //     // Find the index of the active week
    //     let activeWeekIndex = -1;
    //     data.forEach((item, index) => {
    //     if (currentDate >= item.startDate && currentDate <= item.endDate) {
    //         activeWeekIndex = index;
    //     }
    //     });

    //     // If active week is found, move it to the top and update keys
    //     if (activeWeekIndex !== -1) {
    //         const activeWeek = data.splice(activeWeekIndex, 1)[0];
    //         data.unshift(activeWeek);
    //         data.forEach((item, index) => {
    //             item.key = `selection${index + 1}`;
    //             item.color = (index === 0 ? '#2A85FF' : '#D6F1FC');
    //         });
    //     }
    //     return data;
    // }

    // const getSelectedMonthInOrder = (data, current) => {
    //     const currentDate = new Date(current); // Current date
    //     const currentMonth = currentDate.getMonth() + 1; // Get current month (January is 0, February is 1, ..., December is 11)
    //     const currentYear = currentDate.getFullYear(); // Get current year
    //     // Rearrange the array to move the current active month to the top
    //     const currentActiveMonthIndex = data.findIndex((item) => {
    //         const startDateMonth = item.startDate.getMonth() + 1;
    //         const startDateYear = item.startDate.getFullYear();
    //         return startDateMonth === currentMonth && startDateYear === currentYear;
    //     });
    //     if (currentActiveMonthIndex !== -1) {
    //         const currentActiveMonthData = data.splice(currentActiveMonthIndex, 1)[0];
    //         data.unshift(currentActiveMonthData);
    //         // Update the keys
    //         data.forEach((item, index) => {
    //             item.key = `selection${index + 1}`;
    //             item.color = (index === 0 ? '#2A85FF' : '#D6F1FC');
    //         })
    //     }
    //     return data;
    // }

    // const getSelectedYearInOrder = (data, current) => {
    //     const currentYear = new Date(current).getFullYear();
    //     // Find the index of the object that corresponds to the current year
    //     const currentIndex = data.findIndex(item => item.startDate.getFullYear() === currentYear);
    //     // Move the object to the top of the array
    //     if (currentIndex !== -1) {
    //         const currentObject = data.splice(currentIndex, 1)[0];
    //         data.unshift(currentObject);
    //     }
    //     // Update the key property of all objects based on their order in the array
    //     data.forEach((item, index) => {
    //         item.key = `selection${index + 1}`;
    //         item.color = (index === 0 ? '#2A85FF' : '#D6F1FC');
    //     });
    //     return data
    // }

    // const configureOptions = () => {
    //     if(dates && dates?.length > 0){
    //         if(interval === 1){
    //             let daily = dates && dates.map((e, i) => ({id : i+1, name: `Day ${i+1} : ${moment(e).format('MMMM DD, YYYY')}`}))
    //             const filteredObject = daily.find(obj => obj.name.includes(current));
    //             if(filteredObject){
    //                 setCurrent(filteredObject.name)    
    //             }                                                                                   
    //             setOptions(daily)
    //             setPlaceHolderDate(false)  
    //         }else if(interval === 2){
    //             let weeklyRange = dates && dates.map((e, i) => ({key: `selection${i}` ,color: (moment().isSame(moment(e), 'week') ? '#2A85FF' : '#D6F1FC'), startDate: moment(e).startOf('week').toDate() , endDate : moment(e).endOf('week').toDate()}))
    //             setSelectionRange(getSelectedWeekInOrder(weeklyRange, moment(current.split(' - ')[0]).toDate()))
    //             let weekly = dates && dates.map((e, i) => ({id : i+1, name: `Week ${i+1} : ${moment(e).startOf('week').format('MMMM DD, YYYY')} - ${moment(e).endOf('week').format('MMMM DD, YYYY')}`}))
    //             const filteredObject = weekly.find(obj => obj.name.includes(current));
    //             if(filteredObject){
    //                 setCurrent(filteredObject.name)    
    //             }                                                                                        
    //             setOptions(weekly)
    //             setPlaceHolderDate(false)
    //         }else if(interval === 3){
    //             let monthlyRange = dates && dates.map((e, i) => ({key: `selection${i}`, color: (isCurrentDateInStartOfMonth(moment().format('YYYY-MM-DD'), e) ? '#2A85FF' : '#D6F1FC'), startDate: moment(e).startOf('month').toDate() , endDate : moment(e).endOf('month').toDate()}))
    //             setSelectionRange(getSelectedMonthInOrder(monthlyRange, moment(current.split(' - ')[0]).toDate()))
    //             let monthly = dates && dates.map((e, i) => ({id : i+1, name: `Month ${i+1} : ${moment(e).startOf('month').format('MMMM DD, YYYY')} - ${moment(e).endOf('month').format('MMMM DD, YYYY')}`}))
    //             const filteredObject = monthly.find(obj => obj.name.includes(current));
    //             if(filteredObject){
    //                 setCurrent(filteredObject.name)    
    //             }                                                                                          
    //             setOptions(monthly)
    //             setPlaceHolderDate(false)
    //         }else if(interval === 4){
    //             let yearlyRange = dates && dates.map((e, i) => ({key: `selection${i}`, color: (moment().isSame(moment(e), 'year') ? '#2A85FF' : '#D6F1FC'), startDate: moment(e).startOf('year').toDate() , endDate : moment(e).endOf('year').toDate()}))
    //             setSelectionRange(getSelectedYearInOrder(yearlyRange, moment(current.split(' - ')[0]).toDate()))
    //             let yearly = dates && dates.map((e, i) => ({id : i+1, name: `Year ${i+1} : ${moment(e).startOf('year').format('MMMM DD, YYYY')} - ${moment(e).endOf('year').format('MMMM DD, YYYY')}`}))
    //             const filteredObject = yearly.find(obj => obj.name.includes(current));
    //             if(filteredObject){
    //                 setCurrent(filteredObject.name)    
    //             }                                                                                          
    //             setOptions(yearly)
    //             setPlaceHolderDate(false)
    //         }
    //     }
    // }
    
    // useEffect(() => {
    //     configureOptions()
    // }, [dates])
 
    return (
        <>
            <div className={cn(styles.calendar, className, {[styles.active] : showCalendar})}>
                <LeaderboardCalendarDropdown disabled={interval === 5} setPlaceHolderDate={setPlaceHolderDate}
                    // getSelectedYearInOrder={getSelectedYearInOrder} getSelectedWeekInOrder={getSelectedWeekInOrder} getSelectedMonthInOrder={getSelectedMonthInOrder} 
                    options={options} setLeaderboardInstanceId={setLeaderboardInstanceId} setLeaderboardStatus={setLeaderboardStatus}
                    value={current} setValue={setCurrent}
                    show={showCalendar} setShow={setShowCalendar} 
                    interval={interval} 
                    range={selectionRange} setRange={setSelectionRange} />
                {showCalendar &&
                    <>
                        <div className={styles.calendarBody}>
                            <DateRangePicker 
                            // dayContentRenderer={customDayContent} onChange={handleRangeChange} 
                            showSelectionPreview={true} moveRangeOnFirstSelection={false}
                            months={2} ranges={selectionRange} direction="horizontal" preventSnapRefocus={true} calendarFocus="backwards"/>
                        </div>
                    </>
                }
            </div>
        </>
    );
}

export default CalendarRange