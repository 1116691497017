import React, { useState, useRef, useEffect, useContext } from 'react'
import '../querybuilder.scss'
import cn from 'classnames'
import axios from 'axios'
import customAxios from '../../../../../../utils/interceptor'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch , useSelector } from 'react-redux';
import { WithContext as ReactTags } from "react-tag-input";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {QueryBuilder, add, ActionWithRulesProps, Group, ActionElement, Rule} from 'react-querybuilder'
import Card from '../../../../../../components/Card'
import TextInput from '../../../../../../components/TextInput'
import TextArea from '../../../../../../components/TextArea'
import Dropdown from '../../../../../../components/Dropdown';
// import LevelDropdown from '../../../../../../components/Dropdown/LevelDropdown';
import Radio from '../../../../../../components/Radio';
import Tooltip from "../../../../../../components/Tooltip";
import Checkbox from "../../../../../../components/Checkbox"
import CommonDropdown from '../../../../../../components/MultiselectDropDown/CommonDropdown'
import ContentTable from '../../../../../../components/BuildComponents/EconomyComponent/BundlesComponent/ContentTableLayout'
import Control from '../Control'
import styles from './taskInformation.module.sass'
import CustomCombinatorSelector from '../CustomQueryBuilder/CustomCombinatorSelector' 
import CustomFieldSelector from '../CustomQueryBuilder/CustomFieldSelector'
import CustomOperatorSelector from '../CustomQueryBuilder/CustomOperatorSelector'
import CustomValueEditor from '../CustomQueryBuilder/CustomValueEditor';
import {getCustomEventAction, getDefaultEventAction} from '../../../../../../redux/action/builds/events/eventActions'
import {createTaskAction} from '../../../../../../redux/action/engage/Achievement/Task/TaskAction'
import Task from '../../../../../../media/images/placeholders/TAsk.png'
import {ReactComponent as AddButtonIcon} from '../../../../../../media/images/icons/add_button.svg'
import {ReactComponent as AddMetaIcon} from '../../../../../../media/images/icons/add_meta.svg'
import {ReactComponent as AddRuleIcon} from '../../../../../../media/images/icons/Rule_Add_SVG.svg'
import {ReactComponent as RemoveIcon} from '../../../../../../media/images/icons/remove.svg'
import { BASE_URL, GET_PROGRESSION_SYSTEM } from '../../../../../../resources/APIEndpoints';
import { getAllCurrencyAction } from '../../../../../../redux/action/builds/economy/currency/currencyAction';
import { getAllItemAction } from '../../../../../../redux/action/builds/economy/items/itemAction';
import { getAllBundlesAction } from '../../../../../../redux/action/builds/economy/bundles/bundleAction';
import { getAllProgressionMarkerAction } from '../../../../../../redux/action/builds/progression/progressionMarker/progressionMarkerAction';
import Toast from '../../../../../../components/Toast';
import TagInputWithDropdown from "../../../../../../components/TagInputWithDropdown";
import FileUploadSidebar from '../../../../../../components/FileUploadSidebar';
import { getAllMediaAction } from '../../../../../../redux/action/AppSettings/Media/mediaAction';
import TooltipTitle from '../../../../../../Tooltip/TooltipTitle'
import JSONMetaDataValueInput from '../../../../../../components/MetaInput'
import ProgressionLevelMultiSelect from '../../../../../../components/Dropdown/ProgressionLevelTypeableDropdown';
import Schedule from './Schedule'
import Switch from '../../../../../../components/Switch'
import app,{increaseUnreadCountForAllUsers} from "../../../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";
import GlobalStateContext from '../../../../../../components/Context/GlobalStates/GlobalStateContext.js'
import { extarctImageFromUrlAction } from '../../../../../../redux/action/common/commonAction.js'
import SnapShot from '../../../../../../components/Images'
// Static Data


const TaskInformation = ({className, onClose,setIsChanged}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {appid} = useParams()
    const ref = useRef()
    const [called, setCalled] = useState(false)
    // Task Informations
    const placeholder = Task
    const globalStates = useContext(GlobalStateContext)
    const [{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'}); 
    const [iconUrl, setIconUrl] = useState()
    const [name, setName] = useState('')
    const [taskId, setTaskId] = useState('')
    const [description, setDescription] = useState()

    // Access and Eligibility
    const [PSOptions, setPSOptions] = useState([])
    const [PS, setPS] = useState([])
    const [lockPSErr, setLockPSErr] = useState(false)

    const uploadLogo = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        } 
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}`)
        setIconUrl(res.data.getUrl);
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
            toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        })
        .catch(function (error) {
            //toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        });
    }

    // To Convert Base64 to Binary
    function convertDataURIToBinary(dataURI) {
        var BASE64_MARKER = ';base64,';
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));
              
        for(var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }
        
    // To Convert file into base64 format
    const convertbase64 = (file) => {
        return new Promise((resolve, err)=>{
        const fileReader = new FileReader();
        fileReader.onload = (eve) =>{
            resolve(fileReader.result)
        }
        fileReader.readAsDataURL(file)
        })
    }

    const Cancel = () => {
        setIconUrl(null)
        setLogo({src: placeholder , alt: 'Upload an Image'})
        ref.current.value = ''
    }

    const onChangeName = (e) => {
        if(e.target.value == ''){
            setName('')
        }else{
            errors.nameError = ''
            setName(e.target.value)
        }
    }

    const onChangeId = (e) => {
        if(e.target.value == ''){
            setTaskId('')
        }else{
            errors.idError = ''
            setTaskId(e.target.value)
        }
    }

    const onChangeDescription = (e) => {
        setDescription(e.target.value)
    }

    // Add Rewards

    const [allCurrency, setAllCurrency] = useState([])
    const [currencyPage, setCurrencyPage] = useState(1)
    const [currencyLimit, setCurrencyLimit] = useState(8)

    const getAllCurrencyData = () => {
        let getAllCurrency = {
            projectId : appid,
            offset: ((currencyPage-1)*currencyLimit),
            limit: currencyLimit
        }
        dispatch(getAllCurrencyAction(getAllCurrency))
    }

    useEffect(() => {
        getAllCurrencyData()
    }, [currencyPage])

    const customizeCurrencyData = () => {
        if(currenciesData && currenciesData.length > 0){
            const updatedArray = currenciesData && currenciesData.map((ele) => {
                const matchObj = content.find(element => element.currencyId == ele.id)
                if(matchObj){
                    return {
                        ...ele,
                        quantity : matchObj.quantity,
                        status : true
                    }
                }else{
                    return {
                        ...ele,
                        quantity : null,
                        status : false
                    }
                }
            })
            setAllCurrency(updatedArray)
            //setAllCurrency(currenciesData && currenciesData.map(value => ({...value, status : false, quantity : null})))
        }
    }

    let currenciesData = useSelector((state) => {
        return state.currencies.currency
    })

    useEffect(()=>{
        customizeCurrencyData()
    }, [currenciesData,called])

    const [allItems, setAllItems] = useState([])
    const [itemPage, setItemPage] = useState(1)
    const [itemLimit, setItemLimit] = useState(8)
    
    const getAllItemsData = () => {
        let items = {
            projectId : appid,
            offset: ((itemPage-1)*itemLimit),
            limit: itemLimit
        }
        dispatch(getAllItemAction(items))
    }

    useEffect(() => {
        getAllItemsData()
    }, [itemPage])


    let itemsData = useSelector((state) => {
        return state.item.items
    })

    const customizeItemData = () => {
        if(itemsData && itemsData.length > 0){
            const updatedArray = itemsData && itemsData.map(ele => {
                const matchObj = content.find(element => element.itemId == ele.id)
                if(matchObj){
                    return {
                        ...ele,
                        quantity : matchObj.quantity,
                        status : true
                    }
                }else{
                    return {
                        ...ele,
                        quantity : null,
                        status : false
                    }
                }
            })
            setAllItems(updatedArray)
            //setAllItems(itemsData && itemsData.map(value => ({...value, status : false, quantity : null})))
        }
    }

    useEffect(() => {
        customizeItemData()
    }, [itemsData,called])

    const [getAllBundle, setAllBundle] = useState([])
    const [bundlePage, setBundlePage] = useState(1)
    const [bundleLimit, setBundleLimit] = useState(8)

    const getAllBundlesData = () => {
        let bundles = {
            projectId : appid,
            offset: ((bundlePage-1)*bundleLimit),
            limit: bundleLimit
        }
        dispatch(getAllBundlesAction(bundles))
    }

    let bundleData = useSelector((state) => {
        return state.bundles.bundles
    })

    useEffect(() => {
        getAllBundlesData()
    }, [bundlePage])

    const customizeBundleData = () => {
        if(bundleData && bundleData.length > 0){
            const updatedArray = bundleData && bundleData.map(ele => {
                const matchObj = content.find(element => element.bundleId == ele.id)
                if(matchObj){
                    return {
                        ...ele,
                        quantity : matchObj.quantity,
                        status : true
                    }
                }else{
                    return {
                        ...ele,
                        quantity : null,
                        status : false
                    }
                }
            })
            setAllBundle(updatedArray)
            //setAllBundle(bundleData && bundleData.map(value => ({...value, status : false, quantity : null})))
        }
    }

    useEffect(() => {
        customizeBundleData()
    }, [bundleData,called])

    const [allPM, setAllPM] = useState([])
    const [pmPage, setPMPage] = useState(1)
    const [pmLimit, setPMLimit] = useState(8)

    const getAllPMData = () => {
        let progressionMarkers = {
            projectId : appid,
            ids : [],
            offset: ((pmPage-1)*pmLimit),
            limit: pmLimit
        }
        dispatch(getAllProgressionMarkerAction(progressionMarkers))
    }

    let pmData = useSelector((state)=>{
        return state.progressionMarkers.progressionMarker
    })

    useEffect(() => {
        getAllPMData()
    }, [pmPage])

    const customizePMData = () => {
        if(pmData && pmData.length > 0){
            const updatedArray = pmData && pmData.map(ele => {
                const matchObj = content.find(element => element.progressionMarkerId == ele.id)
                if(matchObj){
                    return {
                        ...ele,
                        quantity : matchObj.quantity,
                        status : true
                    }
                }else{
                    return {
                        ...ele,
                        quantity : null,
                        status : false
                    }
                }
            })
            setAllPM(updatedArray)
            //setAllPM(pmData && pmData.map(value => ({...value, status : false, quantity : null})))
        }
    }

    useEffect(() => {
        customizePMData()
    }, [pmData,called])

    const [content, setContent] = useState([])
    const [isLink, setIsLink] = useState(false)
    const [isLinkRewards, setIsLinkRewards] = useState(false)
    const [linkRewards, setLinkRewards] = useState([])

    const storeContent = () => {
        sessionStorage.setItem('contents', JSON.stringify(content))
    }

    const storeLinkReward = () => {
        sessionStorage.setItem('link-rewards', JSON.stringify(linkRewards))
    }

    useEffect(()=>{

    }, [content, linkRewards])

    useEffect(()=> {
        getAllCurrencyData()
        getAllItemsData()
        getAllBundlesData()
        getAllPMData()
        storeContent()
        storeLinkReward()
    }, [])

    // Access and Eligibility

    const [lockBy, setLockBy] = useState(false)

    const getPS = async() => {
        let items = {
            projectId : appid
        }
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await customAxios.post(BASE_URL + GET_PROGRESSION_SYSTEM, items, headers)
        let progressionSystem = res.data.data.levelDetails
        progressionSystem && progressionSystem.forEach((e,i) => {
            return setPSOptions(prevState => [...prevState, {name : e.name, id : e.id, levelSystemLevelMapping:e.levelSystemLevelMapping}])
        })
    }

    const onChangeLevel = (value, index) => {
        let unlockLevelSystem = [...PS]
        unlockLevelSystem[index].lockedLevel = parseInt(value)
        setPS(unlockLevelSystem)
    }

    useEffect(() => {
        getPS()
    }, [])

    // Configure Data


    // Custom Data

    const [tags, setTags] = useState([]);

    const KeyCodes = { comma: 188, enter: 13 };
      
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleDelete = (i) => {
      setTags(tags.filter((tag, index) => index !== i));
    };
  
    const handleAddition = (tag) => {
        if(tags.length < 10){
            setTags([...tags, tag]); 
        }else{
            toast.warning(<Toast type='Warning' messages='Only 10 tags are allowed'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        }
    };
    
    const handleDrag = (tag, currPos, newPos) => {
      const newTags = [...tags].slice();
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
  
      setTags(newTags);
    };
  
    const handleTagClick = (index, event) => {
        event.stopPropagation();
    };
  
    const onClearAll = () => {
      setTags([]);
    };
  
    const onTagUpdate = (i, newTag) => {
      const updatedTags = tags.slice();
      updatedTags.splice(i, 1, newTag);
      setTags(updatedTags);
    };

    const onTagInput = () => {
        if(document.getElementById('tagsInput')){
            document.getElementById('tagsInput').focus()
        }
    }

    const [metaData, setMetaData] = useState([{key : '' , value : null}])

    const addMetaData = () => {
        const metaDataField = []
        metaDataField.push(...metaData, {key : '' , value : null})
        setMetaData(metaDataField)
    }

    const removeMetaData = (index) => {
        let removeField = metaData
        let metaFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setMetaData(metaFields)
    }

    const onChangeKey = (e, index) => {
        let keys = [...metaData]
        keys[index].key = e.target.value
        setMetaData(keys)
    }

    const onChangeValue = (e, index) => {
        let values = [...metaData]
        values[index].value = e.target.value
        setMetaData(values)
    }

    const [rule, setRule] = useState([])
    const [config, setConfig] = useState()

    const checkBooleanValue = (value) => {
        if (value === true || value === 'true') {
          return true;
        }
        return false;
    }

    const convertRule = (rules) => {
        var result = JSON.parse(JSON.stringify(rules))
        var configure = []
        function recurssive(result){
            for(var i = 0; i < result.length ; i++){
                var current = result[i]
                if(current.field != undefined){
                    configure.push({...(current.field.id && current.field.id != undefined && {parameterId : current.field.id.toString()}), parameterName : current.field.name, operator : current.operator, value : (current.field.dataType == 'integer' && current.value.value != null && current.value.value != '' ? parseInt(current.value.value) : current.field.dataType == 'integer' && (current.value.value == null || current.value.value == '') ? null : current.field.dataType == 'boolean' ? checkBooleanValue(current.value.value) : current.field.dataType == 'float' ? parseFloat(current.value.value) : current.value.value), incrementalType : (current.value.type == true ? 'cumulative' : 'one-shot'), noOfRecords : ((current.value.type == true && current.value.allTime == false && current.value.noOfRecords != null) ? parseInt(current.value.noOfRecords) : (current.value.type == true && current.value.allTime == true) ? 'all' : null), type : current.field.type, dataType: current.field.dataType})
                    current.fact = current.field.name;
                    // current.value = current.value.value
                    if(current.field.dataType == 'string'){
                        current.value = current.value.value
                    }else if(current.field.dataType == 'integer'){
                        current.value = ((current.value.value != null && current.value.value != '') ? parseInt(current.value.value) : null)
                    }else if(current.field.dataType == 'boolean'){
                        const booleanValue = checkBooleanValue(current.value.value)
                        current.value = booleanValue
                        // current.value = (current.value.value === 'true')
                    }else if(current.field.dataType == 'float'){
                        current.value = parseFloat(current.value.value)
                    }else{
                        current.value = current.value.value
                    }
                }
                delete current.valueSource
                delete current.field
                delete current.id
                if((current.rules || current.all || current.any) && (current.rules.length || current.all.length || current.any.length) > 0){
                    if(current.combinator == 'and'){
                        current.all = current['rules']
                    }else{
                        current.any = current['rules']
                    }
                    delete current.fact
                    recurssive(current.rules)
                    delete current.rules
                    delete current.combinator
                    delete current.not
                }
            }
        }
        recurssive(result.rules)
        setRule(result.rules)
        setConfig(configure)
        return { rulesData: result.rules,  configData : configure};
    }

    const [ruleData, setRuleData] = useState()
    const [configData, setConfigData] = useState()


    const [errors, setErrors] = useState({nameError : '', idError : '', typeErr : '', claimErr : '', eventIdErr : '', operatorErr : ''})

    const validation = (data) => {
        const error = {}
        let isValid = true

        if (data.name == '') {
            error.nameError = 'Display Name is required';
            isValid = false;
            const element = document.getElementById('taskName');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        }
      
        if (data.taskId == '') {
            error.idError = 'Task ID is required';
            isValid = false;
            if(data.name != ''){
                const element = document.getElementById('taskId');
                const offset = 140;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        if ((data.customEventId == undefined && (data.defaultEventId == '' || data.defaultEventId == null)) || (data.defaultEventId == undefined && (data.customEventId == '' || data.customEventId == null))) {
            error.eventIdErr = 'Event is required';
            isValid = false;
            if(data.name != '' && data.taskId != ''){
                const element = document.getElementById('event');
                const offset = 100;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        setErrors(error);

        if(isValid){
            return true
        }
    }

    const [id, setId] = useState()

    const validateQuery = (rules) => {
        var result = JSON.parse(JSON.stringify(rules))
        let isValid = true
        if(result.rules?.length > 0  && result.rules[0]?.field != ''){
            function recurssive(result){
                for(var i = 0; i < result.length ; i++){
                    var current = result[i]
                    if(current.rules === undefined || current.rules === null){
                        if(current.field === '' || current.field === undefined || current.field === null){
                            isValid = false
                            toast.error(<Toast type='Error' messages={ `Parameter cannot be empty.`}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
                        }
                        if(current.operator === '' || current.operator === undefined || current.operator === null || current.operator === '=' ){
                            isValid = false
                            toast.error(<Toast type='Error' messages={ `Operator cannot be empty.`}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
                        }
                        if(current.value?.value === '' || current.value?.value === undefined || current.value?.value === null){
                            isValid = false
                            toast.error(<Toast type='Error' messages={ `Value cannot be empty.`}/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
                        }
                    }
                    if(current.rules && current.rules?.length > 0){
                        recurssive(current.rules)
                    }
                }
            }
            recurssive(result.rules)
        }
        if(!isValid){
            const element = document.getElementById('query-builder');
            const offset = 100;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        }
        return isValid;
    }

    const db = getFirestore(app);

    const submit = async (visibleModal, setVisibleModal, setId) => {

        let conevertRules;
        let conevertConfig
        if(query.rules && query.rules.length > 0 && query.rules[0].field != ''){
            const {rulesData, configData} = convertRule(query);
            conevertRules = rulesData
            conevertConfig = configData
        }
        const metaDataList = [...metaData];
        const finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }

        let eventTags = []
        tags && tags.forEach((e)=>{
            eventTags.push(e.text)
        })

        const contents = JSON.parse(sessionStorage.getItem('contents'))
        const addedContent = contents && contents.map(({iconUrl, name, type, displayId, ...content}) => content)
        const contentTask = addedContent.filter(item => !item.archive)

        const rewards = JSON.parse(sessionStorage.getItem('link-rewards'))
        const addedRewards = rewards && rewards.map(({iconUrl, name, type, displayId, ...content}) => content)
        const rewardsTask = addedRewards && addedRewards.filter(item => !item?.archive)

        let unlockPS = []
        PS && PS.map((e,i) =>{
            unlockPS.push({archive: false, id: e.id, unlockItemId : null, levelSystemId : e.id, level : e.lockedLevel})
        })

        if (lockBy === false) {
            unlockPS = []
        }

        let unlockItemData = [...unlockPS]

        let createTask = {
            projectId : appid,
            name : name,
            // type : type,
            ...(eventType == 'custom' ? {customEventId : eventId} : {defaultEventId : eventId}),
            taskId : taskId,
            description : description,
            iconUrl : iconUrl,
            rewardClaim : (reward == false ? 'automatic' : 'on-claim'),
            isRecurring : frequency,
            isLockedByLevel : lockBy,
            ...(lockBy == true ? {levelDetails : unlockPS} : {levelDetails : []}),
            tags : eventTags,
            meta : finalMetaData,
            ...(query.rules && query.rules.length > 0 && conevertRules != undefined && {config : conevertConfig}),
            rewardDetails: contentTask,
            isLinkedReward: isLink,
            isLinkedRewardSameAsGeneralRewards : (isLink ? !isLinkRewards : false),
            linkedRewardDetails: rewardsTask && rewardsTask.map(e => ({...e, archive: false})),
            ...((query.combinator == 'and' && (query.rules && query.rules.length > 0 && conevertRules != undefined)) ? {businessLogic : {all : conevertRules}} : (query.combinator == 'or' && (query.rules && query.rules.length > 0 && conevertRules != undefined)) ? {businessLogic : {any : conevertRules}} : {})
        }
        if(validation(createTask)){
            try {
                // Await the response from createGameAction
                if(validateQuery(query)){
                    if(visibleModal){
                        setIsChanged(false)
                        await dispatch(createTaskAction(createTask, navigate, appid, globalStates.setShowOverlay, visibleModal, setVisibleModal, setId))
                    }else{
                        setIsChanged(false)
                        await dispatch(createTaskAction(createTask, navigate, appid, globalStates.setShowOverlay))
                    }
                }
                // Access the response data (e.g., game ID)
                    // const IdFromResponse = res?.data?.data?.id;

                    setIsChanged(false);
                    const user = JSON.parse(localStorage.getItem('user'))
                    const memberID = user?.id
                    const memberName = user?.name
                    const orgId = (localStorage.getItem('orgId'))
    
                    // Log activity in Firestore
                    const activityLog = {
                        timestamp: new Date().toISOString(),
                        action_type: "CREATE",
                        app_id:appid,
                        resource: "TASK",
                        // resource_id: IdFromResponse, // Use the ID from response
                        description: `Task '${name}' created successfully`,
                        quantity: 1,
                        ...(localStorage.getItem('environment')&&{environment:localStorage.getItem('environment')}),
                        operation_status: "SUCCESS",
                        activity_feed: true,
                        priority: "LOW",
                        performed_by_id: memberID,
                        organization_id: orgId,
                        performed_by:memberName,
                        request_json:JSON.stringify(createTask),
                    };
                    await addDoc(collection(db, "activityFeed"), activityLog);
                    await increaseUnreadCountForAllUsers( appid);
                    console.log("Activity logged successfully");      
            } catch (error) {
                console.error("Error creating game or logging activity:", error);
            }
        }
    }

    const cancel = () => {
        navigate(`/task/${appid}`)
        setIsChanged(false)
    }

    // Config
    const typesOption = [{id : 1, name : 'static'},{id : 2, name : 'daily'},{id : 3, name : 'weekly'}]
    const [type, setType] = useState(typesOption[0].name)
    const [typeId, setTypeId] = useState(typesOption[0].id)
    const [frequency, setFrequency] = useState(false)
    const [claim, setClaim] = useState(false)
    const [reward, setReward] = useState(true)
    const [event, setEvent] = useState('')
    const [eventOptions, setEventOption] = useState([])
    const [eventId, setEventId] = useState(null)

    const [fields, setFields] = useState([])

    const combinators = [{name : 'and', label : 'and'}, {name : 'or', label : 'or'}]

    const [query, setQuery] = useState({combinator : 'and', rules : [{field : '', value : {value : null, type : false, allTime : true, noOfRecords : null}, operator : ''}]})

    const onchangeQuery = (newQuery) => {
        setQuery(newQuery);
    };

    const buildClasses = {
        queryBuilder : "qb-container",
        ruleGroup : 'rule-group',
        rule : 'rule',
        removeRule : 'remove'
    }

    const [eventPage, setEventPage] = useState(1)
    const [eventLimit, setEventLimit] = useState(100)
    const [isCalled, setIsCalled] = useState(false)

    const getAllEvents = async() => {
        let getEvents = {
            projectId : appid,
            offset: ((eventPage-1)*eventLimit),
            limit: eventLimit
        }

        Promise.all([await dispatch(getCustomEventAction(getEvents)), await dispatch(getDefaultEventAction(getEvents))]).then(res =>{
            setIsCalled(true)
        })
        
    }

    let customeEvents = useSelector((state) => {
        return state.event.customEvents
    })
    
    let defaultEvents = useSelector((state) => {
        return state.event.defaultEvents
    })

    let totalCustomEvents = useSelector((state)=>{
        return state.event.totalCustomEventCount
    })
    
    let totalDefaultvents = useSelector((state)=>{
        return state.event.totalDefaultEventCount
    })

    const configureEvent = () => {
        if(isCalled){
            const customEventData = customeEvents.map(v => ({...v, type: 'custom'}))
            const defaultEventData = defaultEvents.map(v => ({...v, type: 'default'}))
            let events = [...customEventData, ...defaultEventData]
            let allEvents = []
            if(events.length > 0){
                events && events.forEach((e,i) => {
                    allEvents.push(e)
                })
                setEventOption(eventOption => [...eventOption, ...allEvents])
                setIsCalled(false)
            }
        }
    }

    useEffect(()=>{
        configureEvent()
    }, [defaultEvents, customeEvents])

    useEffect(() => {
        getAllEvents()
    }, [])

    const handleAddGroup = () => {
        const newGroup = {
            id: "new_group_" + Math.random(),
            combinator : 'and',
            not : false,
            rules: [],
        };
        const updatedQuery = {
            ...query,
            rules: [...(query.rules || []), newGroup],
        };
      
        setQuery(updatedQuery);
    }

    const AddRuleButton = (ActionWithRulesProps) => {
        const onClickFirst = () => setQuery(add(query,{ field: '', operator: '', value: {value : null, type : false, allTime : true, noOfRecords : null}}, ActionWithRulesProps.path))
        return (
        <>
            <button className={styles.ml} onClick={onClickFirst}><AddRuleIcon /></button>
        </>
        );
    };

    const addGroupButton = () => {
        return(
            <></>
        )
    }

    const [eventType, setEventType] = useState()
    const [displayId, setDisplayId] = useState(null)

    const [visibleHelp, setVisibleHelp] = useState(false)

    const [media, setMedia] = useState([])

    const getMedia = async() => {
        let getMediaData = {
            projectId : appid,
            category: 'icons',
            typeId : 0
        }
        await dispatch(getAllMediaAction(getMediaData))
    }

    let files = useSelector((state) => {
        return state.media.media.mediaDetails
    })

    const configureMedia = () => {
        if(files && files.length > 0){
            setMedia(files && files.map((e, i) => ({...e, status : false})))
        }else{
            setMedia([])
        }
    }

    useEffect(() => {
        getMedia()
    }, [])

    useEffect(() => {
        configureMedia()
    }, [files])

    const setchange = () => {
        if (
            (iconUrl==null||iconUrl==undefined)&&
            (name===''||name==="")&&
            (taskId===''||taskId==="")&&
            (description===undefined||description==''||description=="")&&
            (content.length==0)&&
            (type==typesOption[0].name)&&
            (frequency==false)&&
            (eventId===''||eventId===""||eventId==null)&&
            (reward==true)&&
            (lockBy==false)&&
            (PS.length==0)&&
            (query?.combinator=='and'&&query?.rules?.length==1&&(query?.rules[0]?.value?.value==null||query?.rules[0]?.value?.value=='')&&query?.rules[0]?.operator=='')&&
            (tags.length==0)&&
            (metaData.length==1&&metaData[0].key == "" && (metaData[0].value == "" || metaData[0].value == null))
        )
        {
            setIsChanged(false)
        }
        else 
        {
            setIsChanged(true)
        }
    }
    
    useEffect(()=>{
        setchange()
    },[iconUrl,name,taskId,description,content,type,frequency,eventId,reward,PS,query,eventType,displayId,tags,metaData])

    // Link Rewards 
    
    const onChangeLink = (e) => {
        if(e.target.checked){
            setIsLink(true)
            setLinkRewards(content)
            sessionStorage.setItem('link-rewards',  JSON.stringify(content))
        }else{
            setIsLink(false)
            setIsLinkRewards(false)
            setLinkRewards([])
            sessionStorage.setItem('link-rewards',  JSON.stringify([]))
        }
    }

    const onChangeLinkRewards = (e) => {
        if(isLinkRewards){
            setIsLinkRewards(false)
            setLinkRewards(content)
            sessionStorage.setItem('link-rewards',  JSON.stringify(content))
        }else{
            setIsLinkRewards(true)
            setLinkRewards([])
            sessionStorage.setItem('link-rewards',  JSON.stringify([]))
        }
    }

    const configureRewards = () => {
        if(isLink){
            if(!isLinkRewards){
                setLinkRewards(content)
                sessionStorage.setItem('link-rewards',  JSON.stringify(content))
            }
        }else{
            setLinkRewards([])
            sessionStorage.setItem('link-rewards',  JSON.stringify([]))
        }
    }

    useEffect(() => {
        configureRewards()
    }, [content])

    return(
        <>
            <Card className={cn(styles.card, className)} title="Information" classTitle="title-purple">
                <div className={styles.cardDescription}>
                    <div className={styles.preview}>   
                        <div className={styles.previewImage}>
                            <SnapShot id='logo' url={iconUrl} alt={name} placeholder={Task} type='Images' width={60} height={60} borderRadius={16}/>
                            {/* <img id='logo' alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img> */}
                        </div>
                        <div className={styles.previewInput}> 
                            <input type="file" className='file-input' accept="image/jpeg, image/png, image/webp, .jpeg, .jpg, .png, .webp" ref={ref} onChange={(e)=>{uploadLogo(e)}}/>
                            <label className={styles.button} onClick={() => setVisibleHelp(true)}><AddButtonIcon className={styles.addIcon}/>Upload Icon</label>
                        </div>
                        {iconUrl != null && 
                            <div className={styles.previewCancel}>
                                <button className={styles.buttonCancel} onClick={Cancel}>Remove</button>
                            </div>
                        }
                    </div>
                    <FileUploadSidebar media={media} setMedia={setMedia} types='Images' id={`icon`} multiple={false} visible={visibleHelp} setVisible={setVisibleHelp} icon={iconUrl} setIcon={setIconUrl} setValue={setLogo} onClose={onClose} />
                    <div className={styles.infoGroup}>
                        <div className={styles.group}>
                            <TextInput tooltip={TooltipTitle.TaskName} id='taskName' className={styles.field} errorMessage={errors.nameError} label="Display Name*" name="displayName" type="text" required onChange={(e)=>{onChangeName(e)}} />
                            <TextInput tooltip={TooltipTitle.TaskId} isId={true} value={taskId} id='taskId' className={styles.field} errorMessage={errors.idError} label="Task ID*" name="itemId" type="text" required onChange={(e)=>{onChangeId(e)}} />
                        </div>

                        <div className={styles.textArea}>
                            <TextArea type='TASK_DESCRIPTION' value={description} tooltip={TooltipTitle.TaskDescription} className={styles.field} rows={5} label="Task Description" onChange={(e)=>{onChangeDescription(e)}} />
                        </div>
                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Add Rewards" classTitle="title-red"
                head={
                    <div className={styles.accessEligibility}>
                        <div className={styles.groupHeading}>
                            <Control pageItem={itemPage} pageCurrnecy={currencyPage} pageBundle={bundlePage} pagePM={pmPage} type='Task' currency={allCurrency} setCurrency={setAllCurrency} currencyCount={currencyLimit} currencyPage={setCurrencyPage} item={allItems} setItem={setAllItems} itemCount={itemLimit} itemPage={setItemPage} bundles={getAllBundle} setBundle={setAllBundle} bundleCount={bundleLimit} bundlePage={setBundlePage} PM={allPM} setPM={setAllPM} PMCount={pmLimit} PMPage={setPMPage} value={content} setValue={setContent} setCalled={setCalled} width='medium'/>
                        </div>
                    </div>
                }
            >
                {content && content.length > 0 &&
                    <div className={styles.cardDescription}>
                            <>
                                <div className={styles.accessEligibility}>
                                    <div className={styles.content}>
                                        <ContentTable items={content} setValue={setContent} allCurrency={allCurrency} setAllCurrency={setAllCurrency} allItems={allItems} setAllItems={setAllItems} allBundles={getAllBundle} setAllBundle={setAllBundle} allPM={allPM} setAllPM={setAllPM}/>
                                    </div>
                                </div>                 
                            </>
                    </div>
                }
            </Card>
            
            <Card className={cn(styles.card, className)} title="Link Rewards" classTitle="title-red"
                head={
                    <>
                        <div className={styles.selection}>
                            <Switch value={isLink} onChange={(e) => {onChangeLink(e)}}/>
                        </div>
                    </>
                }
            >
                {isLink &&
                    <div className={styles.cardDescription}>
                        <div className={styles.accessEligibility}>
                            <div className={styles.group}>
                                <TextInput className={styles.field} label="Link Reward Type" name="linkeward" type="text" required disabled value='User Referral' />
                                <div className={styles.fieldRadio}>
                                    <div className={styles.radioLabel}>
                                        <span>Is link rewards same as task rewards?</span>
                                    </div>
                                    <div className={styles.variants}>
                                        <Radio className={styles.radioConfig} value={!isLinkRewards} name="isLinkRewards" onChange={(e) => {onChangeLinkRewards(e)}} content="Yes" />
                                        <Radio className={styles.radioConfig} value={isLinkRewards} name="isLinkRewards" onChange={(e) => {onChangeLinkRewards(e)}} content="No" />
                                    </div>
                                </div>
                            </div>
                                
                            {isLinkRewards &&
                                <div className={styles.accessEligibility}>
                                    <Control usedFor='Link Rewards' type='Task' pageItem={itemPage} pageCurrnecy={currencyPage} pageBundle={bundlePage} pagePM={pmPage} currency={allCurrency} setCurrency={setAllCurrency} currencyCount={currencyLimit} currencyPage={setCurrencyPage} item={allItems} setItem={setAllItems} itemCount={itemLimit} itemPage={setItemPage} bundles={getAllBundle} setBundle={setAllBundle} bundleCount={bundleLimit} bundlePage={setBundlePage} PM={allPM} setPM={setAllPM} PMCount={pmLimit} PMPage={setPMPage} value={linkRewards} setValue={setLinkRewards} setCalled={setCalled} width='medium'/>
                                </div>
                            } 
                        </div> 

                        {linkRewards && linkRewards?.length > 0 &&
                            <div className={styles.accessEligibility}>
                                <div className={styles.content}>
                                    <ContentTable usedFor='Link Rewards' disable={!isLinkRewards} items={linkRewards} setValue={setLinkRewards} allCurrency={allCurrency} setAllCurrency={setAllCurrency} allItems={allItems} setAllItems={setAllItems} allBundles={getAllBundle} setAllBundle={setAllBundle} allPM={allPM} setAllPM={setAllPM}/>
                                </div>
                            </div> 
                        }
                    </div>
                }
            </Card>

            <Card className={cn(styles.card, className)} title="Configure" classTitle="title-green">
                <div className={styles.cardDescription}>

                    <div className={styles.accessEligibility}>
                        <div className={styles.group}>
                            <div className={styles.fieldRadio}>
                                <div className={styles.radioLabel}>
                                    <span>Task Frequency</span>
                                </div>
                                <div className={styles.variants}>
                                    <Radio className={styles.radioConfig} value={!frequency} name="frequency" onChange={() => setFrequency(false)} content="One Time" />
                                    <Radio className={styles.radioConfig} value={frequency} name="frequency" onChange={() => setFrequency(true)} content="Every Time" />
                                </div>
                            </div>
                            <div className={styles.fieldRadio}>
                                <div className={styles.radioLabel}>
                                    <span>Grant Reward<span><Tooltip className={styles.tooltip} title={TooltipTitle.TaskGrant} icon="info"/></span></span>
                                </div>
                                <div className={styles.variants}>
                                    <Radio disabled={true} className={styles.radioConfig} value={!reward} name="reward" onChange={() => setReward(false)} content="Server Side" />
                                    <Radio className={styles.radioConfig} value={reward} name="reward" onChange={() => setReward(true)} content="Client Side" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.accessEligibility}>
                        <div className={styles.group}>
                            <div className={styles.field} id='event'>
                                <Dropdown setIsCalled={setIsCalled} eventPage={eventPage} setEventPage={setEventPage} eventLimit={eventLimit} limit={totalCustomEvents+totalDefaultvents} tooltip={TooltipTitle.TaskEvent} label='Event Name*' errorMessage={errors.eventIdErr} placeholder='Select Event' options={eventOptions} value={event} setValue={setEvent} setDisplayId={setDisplayId} setField={setFields} setId={setEventId} setType={setEventType}/>
                            </div>
                            <TextInput tooltip={TooltipTitle.TaskEventId} className={styles.field} disabled value={displayId} label="Event ID" name="itemId" type="text" />
                        </div>
                    </div>

                    <div className={styles.configure}>
                        <span className={styles.params}>Parameter Type<span><Tooltip className={styles.tooltip} title={TooltipTitle.TaskEventRuleEngine} icon="info"/></span><span className={styles.addGroup} onClick={handleAddGroup}><AddMetaIcon /></span></span>
                    </div>
                    <div className={styles.configure} id='query-builder'>
                        <QueryBuilder controlClassnames={buildClasses} combinators={combinators} query={query} fields={fields} onQueryChange={onchangeQuery} controlElements={{combinatorSelector : (props) => (<CustomCombinatorSelector {...props} />), fieldSelector : (props) => (<CustomFieldSelector {...props} />), operatorSelector : (props) => (<CustomOperatorSelector {...props} />)  ,valueEditor : CustomValueEditor, addRuleAction : AddRuleButton , addGroupAction : props => (props.level === 0 ? <addGroupButton {...props} /> : null)}}/>
                    </div>

                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Access & Eligibility" classTitle="title-blue">
                <div className={styles.cardDescription}>
                    <div className={styles.accessEligibility}>
                        <div className={styles.group}>
                            <div className={styles.fieldRadio}>
                                <div className={styles.radioLabel}>
                                    <span>Lock by Level<span><Tooltip className={styles.tooltip} title={TooltipTitle.TaskLock} icon="info"/></span></span>
                                </div>
                                <div className={styles.variants}>
                                    <Radio className={styles.radio} value={lockBy} name="lockBy" onChange={() => setLockBy(true)} content="Yes" />
                                    <Radio className={styles.radio} value={!lockBy} name="lockBy" onChange={() => setLockBy(false)} content="No" />
                                </div>
                            </div>
                            {lockBy &&
                                <div className={styles.field}>
                                    <div className={styles.lockGroup}>
                                        <div className={styles.radioLabel}>
                                            <span>Choose Progression System<span><Tooltip className={styles.tooltip} title={TooltipTitle.TaskPS} icon="info"/></span></span>
                                        </div>
                                        <CommonDropdown placeholder='Select Progression System' className={styles.selectField} options={PSOptions} value={PS} setValue={setPS} error={setLockPSErr}/>
                                        {lockPSErr && <span className={styles.danger}>*Progression System is required.</span>}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    
                    {lockBy &&
                        <div className={styles.accessEligibility}>
                            <div className={styles.group}>
                                {PS && PS.map((element, index) =>{
                                    return(
                                        <>
                                            <div style={{marginTop:"8px"}} className={styles.field}>
                                                <ProgressionLevelMultiSelect placeholder='Select Level' label={element.name} options={element.levelSystemLevelMapping} ind={index} value={PS} setValue={setPS}/>                                     
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    }
                </div>
            </Card>

            <Card className={cn(styles.card, className)} title="Custom Data" classTitle="title-red">
            <div className={styles.cardDescription}>
                    <div className={styles.customData}>
                        
                    <TagInputWithDropdown tooltip={TooltipTitle.TaskTags} tags={tags} setTags={setTags}/>

                        <div className={styles.groupHeading}>
                            <span className={styles.spanTitle}>Meta Data<span><Tooltip className={styles.tooltip} title={TooltipTitle.TaskMetaData} icon="info"/></span></span><span className={styles.addIcon} onClick={addMetaData}><AddMetaIcon /></span>
                        </div>

                        {metaData && metaData.length > 0 &&
                            <div className={styles.groupMeta}>
                                <div className={styles.field}>Key</div>
                                <div className={styles.field}>Value</div>
                            </div>
                        }

                        <div className={styles.groupMeta}>
                            {metaData && metaData.map((e,i)=>{
                                return(
                                    <>
                                        <TextInput className={styles.field} label="" name="key" type="text" required value={(metaData[i].key != '' || metaData[i].key != undefined || metaData[i].key != null) ? metaData[i].key : ''} onChange={(e)=>{onChangeKey(e, i)}}/>
                                        <JSONMetaDataValueInput key={i} className={styles.field} data={(typeof e.value == 'string') ? JSON.stringify(e.value) : e.value} value={metaData} setValue={setMetaData} index={i}/>
                                        {/* <TextInput className={styles.field} label="" name="value" type="text" required  value={(metaData[i].value != '' || metaData[i].value != undefined || metaData[i].value != null) ? metaData[i].value : ''} onChange={(e)=>{onChangeValue(e, i)}}/> */}
                                        <span className={styles.remove} onClick={()=>{removeMetaData(i)}}><RemoveIcon className={styles.icon} /></span>
                                    </>
                                )
                            })}
                        </div>

                    </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)}>
                <div className={styles.cardAction}>
                    <div className={styles.cardSave}>
                        <button className={cn(styles.button, 'button-save-small')} onClick={()=>submit(false, null , null)}>Save</button>
                    </div>
                    <div className={styles.cardSave}>
                        <Schedule id={id} setId={setId} as={`saveNschedule`} type='Task' saveTask={submit}/>
                    </div>
                    <div className={styles.cardCancel}>
                        <button className={cn(styles.button, 'button-cancel-small')} onClick={cancel}>Cancel</button>
                    </div>
                </div>
            </Card>
        </>
    )

}

export default TaskInformation