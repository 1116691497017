import React, {useEffect, useState} from 'react'
import cn from 'classnames'
import styles from './addContent.module.sass'
import Form from '../../../../../Form'
import Dropdown from '../../../../../Dropdown/CommonDropdown'
import MultiDropdown from '../../../../../MultiselectDropDown/CommonDropdown'
import ItemsTableLayout from '../../../../../../components/BuildComponents/EconomyComponent/StoreComponent/TableLayoutForModal/ModalItemTable'
import CurrencyTableLayout from '../../../../../../components/BuildComponents/EconomyComponent/StoreComponent/TableLayoutForModal/ModalCurrencyTable'
import BundleTableLayout from '../../../../../../components/BuildComponents/EconomyComponent/StoreComponent/TableLayoutForModal/ModalBundleTable'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAllItemAction } from '../../../../../../redux/action/builds/economy/items/itemAction'
import { getAllCurrencyAction } from '../../../../../../redux/action/builds/economy/currency/currencyAction'
import {ReactComponent as Previous} from '../../../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../../../media/images/icons/next.svg'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import { getTagsAction } from '../../../../../../redux/action/common/commonAction';
import { BASE_URL } from '../../../../../../resources/APIEndpoints'

const typesOption = ['Durable','Consumable']
const subTypesOption = ['Stackable','Equippable','Tradable','Rentable','Time Stackable']
const sorting = ["Items", "Currency", "Bundles"];

const AddContentModal = ({pageItem, pageBundle, className,fullCurrencyList,itemLimit,setItemPage,itemPage,setBundlePage,bundleLimit,bundlePage, fullBundleList, fullItemsList, type, onClose, setValue, categoryIndex, currency, setCurrency, item, setItem, bundles, setBundle, PM, setPM,fullCategories,customizeBundleData,customizeItemData}) => {
    
    const {appid} = useParams()
    const dispatch = useDispatch()
    
    const [tabIndex, setTabIndex] = useState(0)
    const [activeIndex, setActiveIndex] = useState(0);
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState([])
    const [tagsOption,setTagsOption] = useState([]);
    
    const [types, setTypes] = useState("")
    const [subTypes, setSubTypes] = useState([])
    const [tags, setTags] = useState([])

    const  Sorting = () => {
        if(type == 'Store'){
            setSort(["Items", "Bundles"])
        }else{
            setSort(["Items", "Bundles"])
        }  
    }

    const onPreviousItem = () => {
        setItemPage(prevPage => prevPage - 1)
    }
    
    const onNextItem = () => {
        setItemPage(nextPage => nextPage + 1)
    }

    const onPreviousBundle = () => {
        setBundlePage(prevPage => prevPage - 1)
    }
    
    const onNextBundle = () => {
        setBundlePage(nextPage => nextPage + 1)
    }

    

    useEffect(()=>{
        Sorting()
    }, [sort])
    const [searchPage, setSearchPage] = useState(1)
    const [itemsSearchCount,setItemsSearchCount]=useState(null)
    const [bundleSearchCount,setBundleSearchCount]=useState(null)
    const [currencySearchCount,setCurrencySearchCount]=useState(null)
    const [pmSearchCount,setPmSearchCount]=useState(null) 
    const [searchValue,setSearchValue]=useState('')
    
    const onSearch = () => {
        // if(searchValue == ''&&types==''&&subTypes.length==0){
        //     customizeBundleData(categoryIndex)
        //     customizeItemData(categoryIndex)
        //     let content = JSON.parse(sessionStorage.getItem('contents'))
        //     setValue(content)
        // }else
        {
            let filterArr=[]
            let subTypeVal=[]
            subTypes.map((e)=>{
                switch (e?.name) {
                    case 'Stackable':
                        subTypeVal.push("isStackable")
                        break;
                    case 'Equippable':
                        subTypeVal.push("isEquippable")
                        break;
                    case 'Tradable':
                        subTypeVal.push("isTradable")
                        break;
                    case 'Rentable':
                        subTypeVal.push("isRentable")
                        break;
                    case 'Time Stackable':
                        subTypeVal.push("isTimeStackable")
                        break;
                    default:
                        break;
                }
            })
            types!==''&& filterArr.push({"entity":"type","value":types=='Durable'?"isDurable":types=='Consumable'?"isConsumable":""})
            
            subTypeVal.length!==0 && filterArr.push({"entity":"sub-type","value":subTypeVal})
            let search = {
                projectId : appid,
                search : searchValue,
                offset: ((searchPage-1)*itemLimit),
                limit: itemLimit,
                filters:filterArr
            }
            let tgs=tags&& tags.length!==0 ?tags.map((e)=>{return e.name}):[]
            tags.length!==0 && filterArr.push({"entity":"tags","value":tgs})
            const token = localStorage.getItem('token')
            const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
            axios.post(`${BASE_URL}/inventory/search`, search, headers)
            .then(res=>{
                if(res.data.data.inventoryBundles && res.data.data.inventoryBundles.length > 0){
                    const updatedArray = res.data.data.inventoryBundles && res.data.data.inventoryBundles.map((value) => {
                        const matchObj = fullCategories[categoryIndex]&&fullCategories[categoryIndex].storeContent.find(element => element.bundleId == value.id)
                        if(matchObj){
                            return {
                                ...value,
                                quantity : matchObj.quantity,
                                status : true
                            }
                        }else{
                            return {
                                ...value,
                                quantity : null,
                                status : false
                            }
                        }
                    })
                    setBundle(prevState=>{
                        const updatedArr=[...prevState];
                        updatedArr[categoryIndex]=updatedArray;
                        return updatedArr
                    })
                    setBundleSearchCount(res?.data?.data?.bundlesSearchCount)
                }else{
                    setBundle(prevState=>{
                        const updatedArr=[...prevState];
                        updatedArr[categoryIndex]=[]
                        return updatedArr
                    })
                    setBundleSearchCount(res?.data?.data?.bundlesSearchCount) 
                }

                if(res.data.data.inventoryItems && res.data.data.inventoryItems.length > 0){
                    const updatedArray = res.data.data.inventoryItems && res.data.data.inventoryItems.map((value) => {
                        const matchObj = fullCategories[categoryIndex]&&fullCategories[categoryIndex].storeContent.find(element => element.itemId == value.id)
                        if(matchObj){
                            return {
                                ...value,
                                quantity : matchObj.quantity,
                                status : true
                            }
                        }else{
                            return {
                                ...value,
                                quantity : null,
                                status : false
                            }
                        }
                    })
                    setItem(prevState => {
                        const updatedArr = [...prevState];
                        updatedArr[categoryIndex] = updatedArray;
                        return updatedArr;
                    });
                    setItemsSearchCount(res?.data?.data?.itemsSearchCount)

                }else{
                    setItem(prevState => {
                        const updatedArr = [...prevState];
                        updatedArr[categoryIndex] = [];
                        return updatedArr;
                    });
                    setItemsSearchCount(res?.data?.data?.itemsSearchCount)

                }
            })
        }
    }

    let totalBundles = useSelector((state)=>{
        return state.bundles.totalCount
    })

    const handleBundleChange = (event, value)  => {
        setSearchPage(value)
    }

    let totalItems = useSelector((state)=>{
        return state.item.totalCount
    })

    const handleItemChange = (event, value)  => {
        setSearchPage(value)
    }

    const save = () => {
        let content = JSON.parse(sessionStorage.getItem('contents'))
        setValue(content)
        onClose()
    }

    const tagOpt= useSelector((state=>{
        return state.common.tags
    }))
      
    const configureTags = () =>{
        tagOpt?.tagDetails&&setTagsOption(tagOpt.tagDetails)
    }
      
    const getTags = async() => {
    let items = {
        projectId : appid
    }
    dispatch(getTagsAction(items))
    }
    
    useEffect(()=>{
        getTags()
    },[])

    useEffect(()=>{
        onSearch()
    },[searchValue,types,subTypes,tags,searchPage])

    // useEffect(()=>{
    //     setSearchPage(1)
    // },[searchValue,types,subTypes,tags])
    
    useEffect(()=>{
        configureTags()
    },[tagOpt])
    
    return (
        <>
            <div className={styles.addContentModal}>
                <div className={styles.addContentContainer}>
                    <div className={styles.addContent}>
                        <div className={styles.modalHeading}>
                            <div className={styles.block}></div><span>Add Content to Category</span>
                        </div>
                        <div className={styles.modalBody}>
                            <div className={styles.modalContent}>
                                <div className={styles.modalSearch}>
                                    <Form className={styles.form} placeholder="Search" type="text" name="search" icon="search" onChange={(e)=>{setSearchValue(e?.target?.value)}}/>
                                </div>
                                <div className={styles.contentBody}>
                                    <div className={styles.toggle}>
                                        {sort && sort.map((x, index) => (
                                            <button className={cn(styles.link, { [styles.active]: index === activeIndex,})} onClick={() => setActiveIndex(index)} key={index}>
                                                <p>{x}</p>
                                            </button>
                                        ))}
                                    </div>
                                    {(type == 'Store') &&
                                        <>
                                            <div className={styles.wrap}>
                                                {activeIndex === 0 && 
                                                <>
                                                    <ItemsTableLayout type='Item' fullList={fullItemsList} items={item} setData={setItem} categoryIndex={categoryIndex} setValue={setValue} title="Last edited" />
                                                    <div className={styles.pagination}>
                                                        <Stack spacing={2}>
                                                            <Pagination renderItem={(item) => (
                                                                <PaginationItem
                                                                    {...item}
                                                                    sx={{
                                                                        '&.MuiPaginationItem-root': {
                                                                          minWidth: '28px',
                                                                          height: '28px',
                                                                          fontSize: '12px'
                                                                        },
                                                                        '&.Mui-selected': {
                                                                          color: 'rgb(255, 255, 255)',
                                                                          fontWeight : 'bold',
                                                                          border : '1px solid rgb(42, 133, 255)',
                                                                          background : 'rgba(42, 133, 255)'
                                                                        },
                                                                        '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                                                          color: 'rgb(255, 255, 255)',
                                                                          fontWeight : 'bold',
                                                                          border : '1px solid rgb(42, 133, 255)',
                                                                          background : 'rgba(42, 133, 255)'
                                                                        }
                                                                    }}
                                                                />
                                                            )} page={searchPage} siblingCount={1} count={Math.ceil((searchValue !== '')?(itemsSearchCount/itemLimit):(totalItems/itemLimit))} color="primary" onChange={handleItemChange} />
                                                        </Stack>
                                                    </div>
                                                </>    
                                                }
                                            </div>
                                            <div className={styles.wrap}>
                                                {activeIndex === 1 && 
                                                <>
                                                    <BundleTableLayout type='Bundles' fullList={fullBundleList} items={bundles} setData={setBundle} categoryIndex={categoryIndex} setValue={setValue} title="Last edited" />
                                                    <div className={styles.pagination}>
                                                        <Stack spacing={2}>
                                                            <Pagination renderItem={(item) => (
                                                                <PaginationItem
                                                                    {...item}
                                                                    sx={{
                                                                        '&.MuiPaginationItem-root': {
                                                                          minWidth: '28px',
                                                                          height: '28px',
                                                                          fontSize: '12px'
                                                                        },
                                                                        '&.Mui-selected': {
                                                                          color: 'rgb(255, 255, 255)',
                                                                          fontWeight : 'bold',
                                                                          border : '1px solid rgb(42, 133, 255)',
                                                                          background : 'rgba(42, 133, 255)'
                                                                        },
                                                                        '&.MuiPaginationItem-page.Mui-selected:hover' : {
                                                                          color: 'rgb(255, 255, 255)',
                                                                          fontWeight : 'bold',
                                                                          border : '1px solid rgb(42, 133, 255)',
                                                                          background : 'rgba(42, 133, 255)'
                                                                        }
                                                                    }}
                                                                />
                                                            )} page={searchPage} siblingCount={1} count={Math.ceil((searchValue !== '')?(bundleSearchCount/bundleLimit):(totalBundles/bundleLimit))} color="primary" onChange={handleBundleChange} />
                                                        </Stack>
                                                    </div>
                                                </>
                                                }
                                            </div>
                                        </>
                                    }
                                    
                                </div>
                            </div>
                            <div className={styles.modalFilter}>
                                <div className={styles.filterContent}>
                                    <div className={styles.filter}>
                                        <div className={styles.filterHead}>
                                            <h4>Filters</h4>
                                        </div>
                                        <div className={styles.filterType}>
                                            <Dropdown value={types} placeholder={"Types"} setValue={setTypes} options={typesOption.map((e)=>{return {name:e}})}/>
                                        </div>
                                        <div className={styles.filterSubType}>
                                            <MultiDropdown value={subTypes} placeholder={"Sub Types"} setValue={setSubTypes} options={subTypesOption.map((e)=>{return {name:e}})}/>
                                        </div>
                                        <div className={styles.filterTags}>
                                            <MultiDropdown value={tags} placeholder={"Tags"} setValue={setTags} options={tagsOption.map((e)=>{return {name:e.name}})}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.button}>
                            <button onClick={save} style={{marginRight : '10px'}} className={cn("button-small", styles.button)}>Save</button>
                            <button onClick={onClose} className={cn("button-white-small", styles.button)}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddContentModal