import React, {useEffect, useState} from 'react'
import cn from 'classnames'
import styles from './previewModal.module.sass'
import { useDispatch } from 'react-redux'
import { extarctImageFromUrlAction } from '../../../../redux/action/common/commonAction'
import SnapShot from '../../../Images'

const PreviewModal = ({className, onClose, src, type, id}) => {

    const dispatch = useDispatch()

    return (
        <div className={styles.previewModal}>
        <div className={styles.previewContent}>
            {type == 'Images' ?
                <div className={styles.preview}>
                    <SnapShot id={`media_preview_${id}`} alt={`${id}`} url={src} width={300} height={300} type="Images"/>
                </div>
            : type == 'Videos' ?
                <div className={styles.preview}>
                    <SnapShot id={`media_preview_${id}`} alt={`${id}`} url={src} width={300} height={300} type="Videos"/>
                    {/* <video className={styles.videos} id={`media_preview_${id}`} controls></video> */}
                </div>
            : ''}
        </div>
        </div>
    )
}

export default PreviewModal