import React, {useEffect, useState} from 'react'
import cn from 'classnames'
import styles from './createPayout.module.sass'
import Icon from '../../../../../components/Icon'
import TextInput from '../../../../../components/TextInput'
import Radio from '../../../../Radio'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector} from 'react-redux'
import { createUserPayoutAction, getAllPlayersAction } from '../../../../../redux/action/engage/Players/PlayersAction'
import Dropdown from '../../../../Dropdown'
import { BASE_URL, GET_USER_WALLET } from '../../../../../resources/APIEndpoints'
import axios from 'axios'
import app,{increaseUnreadCountForAllUsers} from "../../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";
import TextArea from '../../../../TextArea'
 
const AddPlayer = ({className, type, onClose, activeIndex, act}) => {

    const {appid} = useParams()
    const {id} = useParams()
    const dispatch = useDispatch()
    const [playerId, setPlayerId] = useState(null)
    const [player, setPlayer] = useState(null)
    const [payoutOptions, setPayoutOptions] = useState([])
    const [types, setTypes] = useState(false)
    const [wallets, setWallets] = useState()
    const [amount, setAmount] = useState(null)
    const [payout, setPayout] = useState(false)
    const [walletId, setWalletId] = useState(null)
    const [walletType, setWalletType] = useState(null)
    const [description, setDescription] = useState(null)

    const onChangeDetail = (e) => {
        setDescription(e.target.value)
    }

    const getAllPlayers = () => {
        let players = {
            projectId: appid
        }
        dispatch(getAllPlayersAction(players))
    }

    let allPlayers = useSelector((state) => {
        return state.players.players
    })

    const db = getFirestore(app);
    const playerProfile = useSelector((state) => {
        return state.players.profile
    })

    const getWalletDetails = async() => {
        let details = {
            userId: (id ? id : playerId ? playerId : null),
            projectId : appid,
            offset: 0, 
            limit: 1000
        }
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await axios.post(BASE_URL + GET_USER_WALLET, details, headers)
        if(type){
            let filterCurrency = await res.data.data && res.data.data.filter(x => (type !== 'virtual' ? x?.type.includes('rm') : x?.type === type.toLowerCase()))
            setPayoutOptions(filterCurrency && filterCurrency.map(x => ({...x, name: `${x.name} (${x.type !== 'virtual' ? x?.type?.substring(3)?.charAt(0)?.toUpperCase()  + x?.type?.slice(4) : x?.type.charAt(0)?.toUpperCase() + x?.type?.slice(1)})`})))
        }else{
            let filterCurrency = await res.data.data && res.data.data.filter(x => x?.type !== 'virtual')
            setPayoutOptions(filterCurrency && filterCurrency.map(x => ({...x, name: `${x.name} (${x.type !== 'virtual' ? x?.type?.substring(3)?.charAt(0)?.toUpperCase()  + x?.type?.slice(4) : x?.type.charAt(0)?.toUpperCase() + x?.type?.slice(1)})`})))
        }
        
    }

    const onAmount = (e) => {
        setAmount(e.target.value)
    }

    const createPayout = async () => {
        try {
            let addPayout = {
                projectId: appid,
                userId: (id ?  id : playerId ? playerId : null),
                amount: Number(amount),
                currencyId: walletId,
                operation: types === false ? 'add' : 'subtract',
                walletType: walletType,
                note: description
            };
            // Dispatch the action to create payout
            const res = await dispatch(createUserPayoutAction(addPayout, appid, id, activeIndex, act))
            console.log(res, 'This Response')
            if(res){
                console.log('In On Close')
                onClose();
            }
            // If dispatch is successful, continue to log the activity
            const user = JSON.parse(localStorage.getItem('user'));
            const memberID = user?.id;
            const memberName = user?.name;
            const orgId = localStorage.getItem('orgId');
            // Log activity in Firestore
            const activityLog = {
                timestamp: new Date().toISOString(),
                action_type: "CREATE",
                app_id: appid,
                resource: "PLAYER",
                resource_id: id, // Use the ID from response
                description: `Payout for player '${playerProfile?.[0]?.username??playerProfile?.[0]?.email??playerProfile?.[0]?.linkedAccounts[0]?.userId??playerProfile?.[0]?.linkedAccounts[0]?.phoneNumber??playerProfile?.[0]?.customId}' created successfully`,
                quantity: 1,
                ...(localStorage.getItem('environment')&&{environment:localStorage.getItem('environment')}),
                operation_status: "SUCCESS",
                activity_feed: true,
                priority: "LOW",
                performed_by_id: memberID,
                organization_id: orgId,
                performed_by: memberName,
                request_json: JSON.stringify(addPayout),
            };
            await addDoc(collection(db, "activityFeed"), activityLog);
            await increaseUnreadCountForAllUsers( appid);
        } catch (error) {
            console.error("Error creating payout or logging activity:", error);
        }
    };
    
    useEffect(() => {
        if(act !== 'Payout'){
            getWalletDetails()
        }
        if(act === 'Payout'){
            getAllPlayers()
        }
    }, [])

    useEffect(() => {
        if(player !== null && act === 'Payout'){
            getWalletDetails()
        }
    }, [player])

    return (
        <>
            <div className={styles.addContentModal}>
                <div className={styles.addContentContainer}>
                    <div className={styles.addContent}>
                        <div className={styles.modalHeading}>
                            <div className={styles.block}></div><span>Create Payout</span>
                        </div>
                        <div className={styles.modalBody}>
                            <div className={styles.contentBody}>
                                <div className={styles.payout}>
                                    <div className={styles.group}>
                                        <div className={styles.fieldRadio}>
                                            <div className={styles.radioLabel}>
                                                <span>Add/Subtract</span>
                                            </div>
                                            <div className={styles.variants}>
                                                <Radio className={styles.radio} name="type" value={!types} onChange={()=>{setTypes(false)}} content="Add Currency" />
                                                <Radio className={styles.radio} name="type" value={types} onChange={()=>{setTypes(true)}} content="Subtract Currency" />
                                            </div>
                                        </div>
                                        {act === 'Payout' &&
                                            <div className={styles.field}>
                                                <Dropdown label='Select Player' placeholder='Select Player' options={allPlayers && allPlayers.map(item => ({...item, name: (item.displayName != null && item.displayName != '') ? item.displayName : (item.username != null && item.username != '') ? item.username : (item.firstName != null && item.firstName != '') ? `${item.firstName} ${item.lastName}`: (item.customId != null && item.customId != '') ? item.customId : (item.email != null && item.email != '') ? item.email : '-'}))} value={player} setValue={setPlayer} setId={setPlayerId} />
                                            </div>
                                        }
                                        {act !== 'Payout' &&
                                            <div className={styles.field}>
                                                <Dropdown label='Choose Wallet' placeholder='Select Wallet' options={payoutOptions} value={wallets} setValue={setWallets} setId={setWalletId} setType={setWalletType}/>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {act === 'Payout' &&
                                    <div className={styles.payout}>
                                        <div className={styles.group}>
                                            <div className={styles.field}>
                                                <Dropdown label='Choose Wallet' placeholder='Select Wallet' options={payoutOptions} value={wallets} setValue={setWallets} setId={setWalletId} setType={setWalletType}/>
                                            </div>
                                        </div>
                                    </div>
                                }
                                
                                <div className={styles.payout}>
                                    <div className={styles.group}>
                                        <div className={styles.fieldRadio}>
                                            <div className={styles.radioLabel}>
                                                <span>Schedule Payout</span>
                                            </div>
                                            <div className={styles.variants}>
                                                <Radio className={styles.radio} name="schedule" value={!payout} onChange={()=>{setPayout(false)}} content="Immediate" />
                                                <Radio disabled={true} className={styles.radio} name="schedule" value={payout} onChange={()=>{setPayout(true)}} content="Set Date/Time" />
                                            </div>
                                        </div>
                                        <TextInput disabled={walletId == null ? true : false} className={styles.field} label='Set Amount' type='text' onChange={(e)=>{onAmount(e)}}/>
                                    </div>
                                </div>
                                {act === 'Payout' &&
                                    <div className={styles.group}>
                                        <TextArea rows={5} value={description} onChange={(e)=>{onChangeDetail(e)}} />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={styles.modalFooter}>
                            <div className={styles.btnGroup}>
                                <button className={styles.save} onClick={createPayout}>Save</button>
                                <button className={styles.cancel} onClick={onClose}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddPlayer