import React, { useState } from 'react'
import styles from './tooltip.module.sass'

const Tooltip = ({ text, data, children }) => {
    const [showTooltip, setShowTooltip] = useState(false)

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };
    
    const handleMouseLeave = () => {
        setShowTooltip(false);
    };
    
    return (
        <div className={styles.custom} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {children}
            {showTooltip && 
                <div className={styles.tooltip}>
                    <div className={styles.groupHeading}>
                        <span className={styles.heading}>{text}</span>
                    </div>
                    {data && data.map((e, i) => {
                        return(
                            <>
                                <span className={e.type == 'Currency' ? styles.rewardsCurrency : e.type == 'Items' ? styles.rewardsItem : ''}>
                                    <span>&nbsp;</span><span className={e.type == 'Currency' ? styles.currencyContent : e.type == 'Items' ? styles.itemContent : ''}>{e.name}</span>
                                    <span className={e.type == 'Currency' ? styles.currencyQuantity : e.type == 'Items' ? styles.itemQuantity : ''}>&nbsp;({e.quantity})</span>
                                </span> 
                                <br />
                            </>
                        )
                    })}
                </div>
            }
        </div>
    )
}

export default Tooltip