import React, { useState, useEffect } from "react";
import styles from "./row.module.sass";
import { useNavigate,useParams } from "react-router-dom";
import TextInput from "../../../../../TextInput";
import removeIcon from '../../../../../../media/images/icons/remove.png'
import {ReactComponent as EditIcon} from '../../../../../../media/images/icons/editSvg.svg'
import currencyPlaceHolder from '../../../../../../media/images/placeholders/Currency.png'
import itemPlaceHolder from '../../../../../../media/images/placeholders/Items.png'
import progressionMarkerPlaceholder from '../../../../../../media/images/placeholders/Progression_Marker.png'
import BundlePlaceholder from '../../../../../../media/images/placeholders/Bundles.png'
import CurrencyTooltip from '../../../../../CustomTooltips/CurrencyTooltip'
import addIcon from "../../../../../../media/images/icons/add.png"
import {ReactComponent as AddMetaIcon} from '../../../../../../media/images/icons/add_meta.svg'
import Modal from "../../../../../../components/Modals/ModalComponent";
import Card from "../../../../../../components/Card";
import cn from "classnames";
import Multiselect from "../../../../../../components/MultiselectDropDown/PricingDropdown";
import CurrencyDropdown from "../../../../../../components/Dropdown/CurrencyDropdown";
import PlatformDropdown from "../../../../../../components/Dropdown/PlatformDropdown"
import { editItem, getAllItemAction } from "../../../../../../redux/action/builds/economy/items/itemAction";
import { useDispatch, useSelector } from "react-redux";
import {getAllCurrencyAction} from '../../../../../../redux/action/builds/economy/currency/currencyAction'
import { useRef } from "react";
import axios from "axios";
import { BASE_URL} from "../../../../../../resources/APIEndpoints";
import {ReactComponent as RemoveIcon} from '../../../../../../media/images/icons/remove.svg';
import { extarctImageFromUrlAction } from "../../../../../../redux/action/common/commonAction";
import SnapShot from "../../../../../Images";

const pricingOption = [{id: 1, name : "Virtual"}, {id: 2, name :"In-App Purchase"}, {id: 3, name: "RMG"}]


const Row = ({ platformOption, realWorldCurrencyOptions, item, access, onChange,categoryIndex, index, total, setValue, className, allItems, setAllItems, allBundles, setAllBundle}) => {
    
    const {appid} = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const ref = useRef()
    const onInputChange = (e) => {
        const content = JSON.parse(sessionStorage.getItem('contents'))
        content[categoryIndex].storeContent[index].quantity = (e.target.value == '' ? null : parseInt(e.target.value))
        setValue(content)
        sessionStorage.setItem('contents', JSON.stringify(content))
    }

    const onRemove = (item) => {
        let content = JSON.parse(sessionStorage.getItem('contents'))
        let removedCont = content[categoryIndex].storeContent
        removedCont.map((e)=>{
            if (item.itemId&& e.itemId==item.itemId) {
                e.archive=true;
            }
            else if (item.bundleId&& e.bundleId==item.bundleId) {
                e.archive=true;
            }
            else if (item.currencyId&& e.currencyId==item.currencyId) {
                e.archive=true;
            }
        })
        content[categoryIndex].storeContent=removedCont
        setValue(content)
        if(item && item.itemId != null){
            let itemList = [...allItems[categoryIndex]]
            itemList = itemList.map(obj => {
                if (obj.id === item.itemId ) {
                    return { ...obj, status: false, quantity: null };
                }
                return obj;
            })
            allItems[categoryIndex] = itemList
            setAllItems(allItems)
        }else if(item && item.bundleId != null){
            let bundleList = [...allBundles[categoryIndex]]
            bundleList = bundleList.map(obj => {
                if (obj.id === item.bundleId) {
                    return { ...obj, status: false, quantity: null };
                }
                return obj;
            })
            allBundles[categoryIndex] = bundleList
            setAllBundle(allBundles)
        }
        sessionStorage.setItem('contents', JSON.stringify(content))
    }
    const [pricing, setPricing] = useState([])
    const [visibleModal, setVisibleModal] = useState(false);
    const [showVirtual, setShowVirtual] = useState(false)
    const [showInApp, setShowInApp] = useState(false)
    const [showRMG, setShowRMG] = useState(false)
    const [virtualCurrencyData, setVirtualCurrencyData] = useState([])
    const [realMoneyData, setRealMoneyData] = useState([])
    const[virtualCurrency, setVirtualCurrency] = useState([])
    const[IAP, setIAP] = useState([])
    const[realMoney, setRealMoney] = useState([])
    // const [realWorldCurrency, setRealWorldCurrency] = useState([])
    const [disable, setDisable] =  useState(false)
    const [platform, setPlatform] = useState([])
    const [platformOptions, setPlatformOptions] = useState([])
    const [content, setContent] = useState([])

    const addVirtualCurrency = () => {
        const currencyFields = []
        currencyFields.push(...virtualCurrency, {priceTypes: '', currencyId : null, price : null , discount : null})
        setVirtualCurrency(currencyFields)
    }

    const onVirtualCurrency = (e, index, type) => {

        let virtualCurrencyList = [...virtualCurrency]
        virtualCurrencyList[index][e.target.name] = parseInt(e.target.value);
        setVirtualCurrency(virtualCurrencyList)
    }
    const removeVirtualCurrency = (index) => {
        let removeField = virtualCurrency
        let itemToRemove = removeField.filter((e,i,c)=>{
            return i == index
        })
        let hasArcBoolean = typeof itemToRemove[0].archive === 'boolean';
        if (hasArcBoolean) {
            let currentFields = removeField.filter((e,i,c)=>{
                return (i == index ? e.archive = true : e.archive == false)
            })
            setVirtualCurrency(currentFields)
            const activeVirtualCurrency = currentFields.filter(item => !item.archive)
            if(activeVirtualCurrency.length == 0){
                setPricing(pricing.filter((values) => values.name != 'Virtual'))
                setShowVirtual(false)
            }
        }
        else{
            let currentFields = removeField.filter((e,i,c)=>{
                return i != index
            })
            setVirtualCurrency(currentFields)
            if(currentFields.length == 0){
                setPricing(pricing.filter((values) => values.name != 'Virtual'))
                setShowVirtual(false)
            }
        }

    }

    const addIAP = () => {
        const IAPFields = []
        IAPFields.push(...IAP, {priceTypes: '', realWorldCurrencyId : null, gamePlatformMasterId: null, gamePlatformName:'',  price : null , discount : null})
        setIAP(IAPFields)
    }

    const removeIAP = (index) => {
        
        let removeField = IAP
        let itemToRemove = removeField.filter((e,i,c)=>{
            return i == index
        })
        let hasArcBoolean = typeof itemToRemove[0].archive === 'boolean';

        if (hasArcBoolean) {
            let currentFields = removeField.filter((e,i,c)=>{
                return (i == index ? e.archive = true : e.archive == false)
            })
            setIAP(currentFields)
            const activeVirtualCurrency = currentFields.filter(item => !item.archive)
            if(activeVirtualCurrency.length == 0){
                setPricing(pricing.filter((values) => values.name != 'In-App Purchase'))
                setShowInApp(false)
            }
        }
        else{
            let currentFields = removeField.filter((e,i,c)=>{
                return i != index
            })
            setIAP(currentFields)
            if(currentFields.length == 0){
                setPricing(pricing.filter((values) => values.name != 'In-App Purchase'))
                setShowInApp(false)
            }
        } 
    }

    const onIAP = (e, index, type) => {
        let IAPList = [...IAP]
        IAPList[index][e.target.name] = ( type == "product") ? e.target.value : parseInt(e.target.value)
        setIAP(IAPList)
    }
    const addRealMoney = () => {
        const realMoneyFields = []
        realMoneyFields.push(...realMoney, {priceTypes: '', currencyId : null, bonusCashAllowance : null,  price : null , discount : null})
        setRealMoney(realMoneyFields)
    }

    let currenciesData = useSelector((state) => {
        return state.currencies.currency
    })
    const customizeCurrencyData = () => {
        if(currenciesData && currenciesData.length > 0){
            const updatedArray = currenciesData && currenciesData.map((ele) => {                
                    return {
                        ...ele,
                        quantity : null,
                        status : false
                    }
            })
            setAllCurrency(updatedArray)
            // setAllCurrency(currenciesData && currenciesData.map(value => ({...value, status : false, quantity : null})))
            let vc = currenciesData && currenciesData.filter((e,i) => e.type == 'virtual')
            let vcData = []
            vc && vc.forEach((e,i) =>{
                vcData.push( {name : e.name, id : e.id, type : 'virtual currency',code:e.code})
            })
            setVirtualCurrencyData(vcData)
            let rm = currenciesData && currenciesData.filter((e,i) => e.type == 'real')
            let rmData = []
            rm && rm.forEach((e,i) =>{
                rmData.push({name : e.name, id : e.id, type : 'RMG',code:e.code})
            })
            setRealMoneyData(rmData)
        }
    }
    
    // const getRealWorldCurrency = async() => {
    //     const token = localStorage.getItem('token')
    //     const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
    //     let res = await axios.post(`${BASE_URL}/currencies/get-world-currency`, {}, headers)
    //     res.data.data && res.data.data.forEach((element) => {
    //         setRealWorldCurrency(prevState => [...prevState, {realWorldCurrencyId : element.id, name : element.code.concat(" (", `${element.symbol}`, ")"),code:element.code, type : 'IAP'}])
    //     })
    // }

    const [allCurrency, setAllCurrency] = useState([])
    const getAllCurrencyData = async() => {
        let getAllCurrency = {
            projectId : appid,
        }
        await dispatch(getAllCurrencyAction(getAllCurrency))
    }

    const loadPricingData = () =>{
        const newPricing=[...pricing]
        item.priceList&& item.priceList.map(
        (priceItem,index) => {
            switch(priceItem.priceTypes) {
              case "virtual currency":
                let amt={
                    storeContentId:priceItem?.storeContentId,
                    gamePlatformMasterId:priceItem?.gamePlatformMasterId,
                    id:priceItem?.id,
                    code: priceItem?.currency?.code,
                    currencyName: priceItem?.currency?.name,
                    currencyId:priceItem?.currencyId,
                    priceTypes:priceItem?.priceTypes,
                    price: priceItem?.price,
                    discount: priceItem?.discount,
                    archive:priceItem?.archive,
                }
                if (!newPricing.some(el=>el.id===1)) {
                    newPricing.push({id: 1, name : "Virtual"})
                    setPricing(prevState => [...prevState, {id: 1, name : "Virtual"}])
                }
                setShowVirtual(true)
                setVirtualCurrency(prevState => [...prevState, amt])
                break;
              case "IAP":
                let obj ={
                    storeContentId:priceItem?.storeContentId,
                    gamePlatformMasterId:priceItem?.gamePlatformMasterId,
                    id:priceItem?.id,
                    productId:priceItem?.productId,
                    code: priceItem?.realWorldCurrency?.code,
                    currencyName:priceItem?.realWorldCurrency?.code.concat(" (", `${priceItem?.realWorldCurrency?.symbol}`, ")"),
                    currencyId: priceItem?.realWorldCurrency?.id,
                    priceTypes:priceItem?.priceTypes,
                    price: priceItem?.price,
                    discount: priceItem?.discount,
                    archive:priceItem?.archive,
                }
                if (!newPricing.some(el=>el.id===2)) {
                    newPricing.push({id: 2, name :"In-App Purchase"})
                    setPricing(prevState => [...prevState, {id: 2, name :"In-App Purchase"}]);
                }
                setShowInApp(true)
                setIAP(prevState => [...prevState, obj])
                break;
              case "RMG":
                let abc={
                    storeContentId:priceItem?.storeContentId,
                    gamePlatformMasterId:priceItem?.gamePlatformMasterId,
                    id:priceItem?.id,
                    currencyName: priceItem?.currency?.name,
                    code: priceItem?.currency?.code,
                    currencyId:priceItem?.currencyId,
                    priceTypes:priceItem?.priceTypes,
                    price: priceItem?.price,
                    discount: priceItem?.discount,
                    bonusCashAllowance:priceItem?.bonusCashAllowance,
                    archive:priceItem?.archive,
                }
                setShowRMG(true)
                if (!newPricing.some(el=>el.id===3)) {
                    newPricing.push({id: 3, name: "RMG"})
                    setPricing(prevState => [...prevState, {id: 3, name: "RMG"}])
                }
                setRealMoney(prevState => [...prevState, abc])
                break;
              default:
                console.log("Unknown price type:", priceItem.priceTypes);
            }
          });
         

    }

    useEffect(()=>{
        loadPricingData()
    },[])

    useEffect(()=>{
        customizeCurrencyData()
    }, [currenciesData, content])


    useEffect(()=>{ 
        if(categoryIndex === 0 && index === 0){
            // getOptionalData()
            // getRealWorldCurrency()
            getAllCurrencyData()
            console.log("Called Multiple Time")
        }
    }, [])


    const submit=()=>{
        let newPrice=[]
        virtualCurrency&& virtualCurrency.map((e,i)=>{
            let currency={name:e.currencyName,code:(e.code?e.code:e.name)}
            let abc={
                storeContentId:e.storeContentId,
                gamePlatformMasterId:e.gamePlatformMasterId,
                id:e?.id,
                currency: currency,
                currencyId:e.currencyId,
                priceTypes:e.priceTypes,
                price: e.price,
                discount: e.discount,
                archive:e.archive,
            }
            newPrice.push(abc)
        })
        IAP&& IAP.map((e,i)=>{
            let realWorldCurrency={currency:e.currencyName,id:e?.currencyId,code:(e.code?e.code:e.name)}
            let abc={
                storeContentId:e.storeContentId,
                gamePlatformMasterId:e.gamePlatformMasterId,
                id:e?.id,
                productId:e?.productId,
                realWorldCurrency: realWorldCurrency,
                realWorldCurrencyId: e.realWorldCurrencyId,
                priceTypes:e.priceTypes,
                price: e.price,
                discount: e.discount,
                archive:e.archive,
            }
            newPrice.push(abc)
        })
        realMoney&& realMoney.map((e,i)=>{
            let currency={name:e.currencyName,code:(e.code?e.code:e.name)}
            let abc={
                storeContentId:e.storeContentId,
                gamePlatformMasterId:e.gamePlatformMasterId,
                id:e?.id,
                currency: currency,
                currencyId:e.currencyId,
                priceTypes:e.priceTypes,
                price: e.price,
                discount: e.discount,
                archive:e.archive,
                bonusCashAllowance:e.bonusCashAllowance
            }
            newPrice.push(abc)
        })

        item.priceList=newPrice;
        setVisibleModal(false)


        const content = JSON.parse(sessionStorage.getItem('contents'))
        content[categoryIndex].storeContent[index].priceList = newPrice
        setValue(content)
        sessionStorage.setItem('contents', JSON.stringify(content))

    }
    const cancel=()=>{
        setVisibleModal(false)
    }

    const removeRealMoney = (index) => {
        let removeField = realMoney
        let itemToRemove = removeField.filter((e,i,c)=>{
            return i == index
        })
        let hasArcBoolean = typeof itemToRemove[0].archive === 'boolean';

        if (hasArcBoolean) {
            let currentFields = removeField.filter((e,i,c)=>{
                return (i == index ? e.archive = true : e.archive == false)
            })
            setRealMoney(currentFields)
            const activeVirtualCurrency = currentFields.filter(item => !item.archive)
            if(activeVirtualCurrency.length == 0){
                setPricing(pricing.filter((values) => values.name != 'RMG'))
                setShowRMG(false)
            }
        }
        else{
            let currentFields = removeField.filter((e,i,c)=>{
                return i != index
            })
            setRealMoney(currentFields)
            if(currentFields.length == 0){
                setPricing(pricing.filter((values) => values.name != 'RMG'))
                setShowRMG(false)
            }
        }        
    }
    const onRealMoney = (e, index, type) => {
        let realMoneyList = [...realMoney]
        realMoneyList[index][e.target.name] = (type == 'price' ? parseInt(e.target.value) : parseInt(e.target.value))
        setRealMoney(realMoneyList)
    }

    // const getOptionalData = async() => {
    //     const token = localStorage.getItem('token')
    //     const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
    //     let res = await axios.get(`${BASE_URL}/master-data`, headers)

    //     let Platforms = []
    //     res.data.data.gamePlatforms && res.data.data.gamePlatforms.map((e, i) =>{
    //         Platforms.push({platformId : e.id, name : (element.platformName !== 'ios' ? element.platformName.charAt(0).toUpperCase() + element.platformName.slice(1) : element.platformName === 'ios' ? 'iOS' : '-'), fileName : '', assetBundleUrl : '', assetBundleVersion : '', minimumAppVersion : ''})
    //     })
    //     setPlatformOptions(Platforms)
    // }

    return (
        <>
            {(item.archive == false || item.archive == undefined) &&
            <div className={styles.row}>
                <div className={styles.col}>
                    <div className={styles.item}>
                        <div className={styles.preview}>
                            <SnapShot id={`contentIcon_${categoryIndex}_${item.displayId}`} url={item.iconUrl} alt={item.name} placeholder={(item?.currencyId ? currencyPlaceHolder : item?.itemId ? itemPlaceHolder : item?.bundleId ? BundlePlaceholder : null)} type='Images' width={36} height={36}/>
                            {/* <img id={`contentIcon_${categoryIndex}_${item.displayId}`} alt="Item" /> */}
                        </div>
                        <div className={styles.details}>
                            <div>{item.name}</div>
                            <div className={styles.itemId}>{item.displayId}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.itemName}>{item.name}</div>
                    <div className={styles.itemId}>{item.displayId}</div>
                </div>
                <div className={styles.col}><input disabled={access} type='text' key={index} className={styles.inputWidth} value={item.quantity} onChange={(e)=>{onInputChange(e, index)}} /></div>
                <div className={styles.col}></div>
                <div className={styles.col}>
                {(item.priceList)? item.priceList.slice(0,3).map((e,i)=>{
                        if(e.priceTypes === 'virtual currency' || e.priceTypes === 'RMG' || e.priceTypes === 'real money' || e.priceTypes === 'virtual'){
                            return (
                                <>
                                    {(e.archive == false || e.archive == undefined) &&
                                    <div className={styles.prices}>
                                        <span className={e.priceTypes == 'virtual currency' ? styles.virtual : styles.real}>{e.currency.code}<span>&nbsp;</span>{e.price}</span>
                                        {(item.priceList && item.priceList.length > 3 && i === 2) ?
                                            <CurrencyTooltip type='item' text='Currency' data={item.priceList}><span className={styles.count}>+ {item.priceList.length - 3}</span></CurrencyTooltip> 
                                        : ''}
                                    </div>
                                    }
                                </>
                            )
                        }else if(e.priceTypes == 'IAP'){
                            return(
                                <>
                                {(e.archive == false || e.archive == undefined) &&
                                    <div className={styles.prices}>
                                        <span className={styles.IAP}>{(e.realWorldCurrency?.code)?e.realWorldCurrency.code:e.code}<span>&nbsp;</span>{e.price}</span>
                                        {(item.priceList && item.priceList.length > 3 && i === 2) ? 
                                            <CurrencyTooltip type='item' text='Currency' data={item.priceList}><span className={styles.count}>+ {item.priceList.length - 3}</span></CurrencyTooltip> 
                                        : ''}
                                    </div>
                                }
                                </>
                            )
                        
                        }
                    })
                    :<div className={styles.col}>-</div>}
                    </div>
                    <div className={styles.col}></div>
                    <div className={styles.col}></div>
                    <div className={styles.col}>
                        {!access && <EditIcon onClick={() => setVisibleModal(true)} className={styles.editIcon}/>}
                    </div>
                    
                
                    {!access && <div className={styles.col} style={{width:50}} onClick={()=>{onRemove(item)}}><RemoveIcon className={styles.icon} /></div>}
                </div>
                }
                    <Modal heading={true} visible={visibleModal} width="medium" onClose={() => setVisibleModal(false)}>
                        {/* {item.priceList.slice(0,3).map((e,i)=>{ */}
                        <div className={styles.container}>
                            <div className={styles.head}>
                                <div className={styles.block}></div>
                                <div className={styles.title}>Pricing</div>
                            </div>
                            <div className={styles.cardDescription}>
                                <div className={styles.createItem}>
                                    <div className={styles.labelHeading}>
                                        <span>Choose Pricing Options</span>
                                    </div>
                                    <div className={styles.selectInput}>
                                        <Multiselect as='bundle' type='add' placeholder='Choose Pricing Options' options={pricingOption} value={pricing} setValue={setPricing} setShowVirtual={setShowVirtual} setShowRMG={setShowRMG} setShowInApp={setShowInApp} setVirtualCurrency={setVirtualCurrency} setIAP={setIAP} setRealMoney={setRealMoney}/>
                                    </div>
                                    {showVirtual && virtualCurrency && virtualCurrency.some(e=>e.archive!==true)&&
                                        <>
                                            <div className={styles.groupHeading}>
                                                <span>Virtual</span><span className={styles.addIcon} onClick={addVirtualCurrency}><AddMetaIcon /></span>
                                            </div>

                                            <div className={styles.groupByThree}>
                                                {virtualCurrency && virtualCurrency.map((e,index)=>{
                                                    return(
                                                        <>  
                                                        {(e.archive == false || e.archive == undefined) &&
                                                        <>
                                                            <div className={styles.field}>
                                                                <CurrencyDropdown as='bundle' type="virtual currency" label="Select Currency" options={virtualCurrencyData} value={e.currencyName} data={virtualCurrency} setData={setVirtualCurrency} index={index}/>
                                                            </div> 
                                                            <TextInput className={styles.field} label="Price" name="price" type="number" onWheelCapture={e => {e.currentTarget.blur()}} required value={e.price} onChange={(e)=>{onVirtualCurrency(e, index, "price")}}/>
                                                            <TextInput className={styles.field} label="Discount" name="discount" type="number" onWheelCapture={e => {e.currentTarget.blur()}} required value={e.discount} onChange={(e)=>{onVirtualCurrency(e, index)}}/>
                                                            <span className={styles.remove} onClick={()=>{removeVirtualCurrency(index)}}><RemoveIcon className={styles.icon} /></span>
                                                        </>
                                                        }
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </>
                                    }
                                </div>

                                <div className={styles.createItem}>
                                    {showInApp && IAP && IAP.some(e=>e.archive!==true)&&
                                        <>
                                            <div className={styles.groupHeading}>
                                                <span>In-App Purchase</span><span className={styles.addIcon} onClick={addIAP}><AddMetaIcon /></span>
                                            </div>
                                            <div className={styles.groupByFive}>
                                                {IAP && IAP.map((e,index)=>{
                                                    return(
                                                        <>
                                                        {(e.archive == false || e.archive == undefined) &&
                                                        <>
                                                            <div className={styles.field}>
                                                                <CurrencyDropdown as='bundle' type="IAP" label="Select Currency" options={realWorldCurrencyOptions} value={e.currencyName} data={IAP} setData={setIAP} index={index}/>
                                                            </div>
                                                            <div className={styles.field}>
                                                                <PlatformDropdown label="Select Platform" options={platformOption} value={platformOption.find(x => x.platformId===e.gamePlatformMasterId)?platformOption.find(x => x.platformId===e.gamePlatformMasterId)?.name:e?.gamePlatformName} data={IAP} setData={setIAP} index={index}/>
                                                            </div>
                                                            <TextInput className={styles.field} label="Product ID" name="productId" type="text" required defaultValue={e.productId} onChange={(e)=>{onIAP(e, index, "product")}}/>
                                                            <TextInput className={styles.field} label="Price" name="price" type="number" onWheelCapture={e => {e.currentTarget.blur()}} required value={e.price} onChange={(e)=>{onIAP(e, index, "price")}}/>
                                                            <TextInput className={styles.field} label="Discount" name="discount" type="number" onWheelCapture={e => {e.currentTarget.blur()}} required value={e.discount} onChange={(e)=>{onIAP(e, index)}}/>
                                                            <span className={styles.remove} onClick={()=>{removeIAP(index)}}><RemoveIcon className={styles.icon} /></span>
                                                        </>
                                                        }
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </>
                                    }
                                </div>

                                <div className={styles.createItem}>
                                    {showRMG && realMoney && realMoney.some(e=>e.archive!==true)&&
                                        <>
                                            <div className={styles.groupHeading}>
                                                <span>RMG</span><span className={styles.addIcon} onClick={addRealMoney}><AddMetaIcon /></span>
                                            </div>
                                            <div className={styles.groupByFour}>
                                                {realMoney && realMoney.map((e, index) => {
                                                    return (
                                                        <>
                                                        {(e.archive == false || e.archive == undefined) &&
                                                        <>
                                                            <div className={styles.field}>
                                                                <CurrencyDropdown as='bundle' type="RMG" label="Select Currency" options={realMoneyData} value={e.currencyName} data={realMoney} setData={setRealMoney} index={index}/>
                                                            </div>
                                                            <TextInput className={styles.field} label="Price" name="price" type="number" onWheelCapture={e => {e.currentTarget.blur()}} required value={e.price} onChange={(e)=>{onRealMoney(e, index, "price")}} />
                                                            <TextInput className={styles.field} label="Discount" name="discount" type="number" onWheelCapture={e => {e.currentTarget.blur()}} required value={e.discount} onChange={(e)=>{onRealMoney(e, index)}} />
                                                            <TextInput className={styles.field} label="Bonus Cash Allowance" name="bonusCashAllowance" type="number" onWheelCapture={e => {e.currentTarget.blur()}} value={e.bonusCashAllowance} required onChange={(e)=>{onRealMoney(e, index)}} />
                                                            <span className={styles.remove} onClick={()=>{removeRealMoney(index)}}><RemoveIcon className={styles.icon} /></span>
                                                        </>
                                                        }
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className={styles.cardAction}>
                                <div className={styles.cardSave}>
                                    <button disabled={disable} className={styles.Save} onClick={submit}>Confirm</button>
                                </div>
                                <div className={styles.cardCancel}>
                                    <button className={styles.Cancel} onClick={cancel}>Cancel</button>
                                </div>
                            </div>  
                        </div>
                        {/* // })} */}
                    </Modal>
        
        </>
    );
};

export default Row;
