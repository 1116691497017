import React , { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import cn from 'classnames'
import styles from './apps.module.sass'
import Card from '../../../components/Card'
import Form from '../../../components/Form'
import Icon from '../../../components/Icon'
import addIcon from '../../../media/images/icons/light.png'
import ProductView from './ProductView'
import Overview from './Overview'
import GetApps from './GetApps'
import Features from './Features'
import Modal from '../../../components/Modals/ModalComponent'
import IntroductionModal from '../../../components/Modals/IntroductionModal'
import Lottie from 'lottie-react'
import Spectre from '../../../media/lottieAnimation/Celebration.json'
import GlobalStateContext from '../../../components/Context/GlobalStates/GlobalStateContext'
import { BASE_URL,UPDATE_ACCESS_CONFIG, GET_ACCESS_CONFIG} from '../../../resources/APIEndpoints';
import axios from 'axios';
import { useAccess } from '../../../components/Page'
const Dashboard = ({setShowLoader, setAppName , setLoaderType}) => {

  const globalStates = useContext(GlobalStateContext)
  const location = useLocation()
// const { accessPermissions,setAccessPermissions } = useAccess();
//   const getOrgFlagsAccessConfig = async () => {
//     const token = localStorage.getItem('token')
//     const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ?  JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ?  JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey :  JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
//     let getAppAccess = {
//     "organisationId": localStorage.getItem('orgId'),
//     // "memberIds": [JSON.parse(localStorage.getItem('user'))?.id],
//     "memberEmails": [JSON.parse(localStorage.getItem('user'))?.email],
//     "env": ["global"]
//     // "projectIds": ["a5e0ea8a-f01b-4cf1-b08a-44dddf0a6b41"]
//     }
//     const orgFlagData = await axios.post(BASE_URL + GET_ACCESS_CONFIG, getAppAccess, headers);
//     const orgFlagArr =orgFlagData.data.data.accessDetails.filter(e=>(e?.orgFlags?.length>0))?.[0]?.orgFlags
    
//     // const existingFlags = accessPermissions || {};
//     const existingFlags = {};
//     // Update the existing Flags object with the new flags data
//     orgFlagArr&&orgFlagArr.forEach(({ key, selected }) => {
//         existingFlags[key] = selected; // Replace or add the flag
//     });
//     // Store the updated Flags object back in localStorage
//     setAccessPermissions(existingFlags); 

//   }

//   useEffect(()=>{
//     getOrgFlagsAccessConfig()
//   },[])
  
  const style = {
    width : '100%',
    height : '100%',
  }

  const getPlanCode = () => {
    const searchParams = new URLSearchParams(location.search)
    const planCodeParam = searchParams.get('hostedpage_id')
    if(planCodeParam){
      localStorage.setItem('showModal', JSON.parse(true))
      globalStates.setShowQuickGuideModal(true)
    }
  }
  
  useEffect(() => {
    getPlanCode()
  }, [location])

  return (
    <>
        <div className={styles.appWrapper}>
          {globalStates.showAnimation &&
            <div className={styles.animation}>
              <div className={styles.celebration}>
                <Lottie animationData={Spectre} loop={true} autoPlay={true} style={style} />
              </div>
            </div>
          }
          <div className={styles.apps} >
            <GetApps users={globalStates?.user?.type} setLoaderType={setLoaderType} setAppName={setAppName} setShowLoader={setShowLoader}/>
          </div>
          <div className={styles.apps} >
            <Features />
          </div>
        </div>
    </>
  )
}

export default Dashboard