import React, {useState} from "react";
import styles from "./tooltipGrid.module.sass";
import cn from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';

const TooltipGlodal = ({show, className, title, width, itemId, children}) => {

    const [visible, setVisible] = useState(false);

    const showTooltip = () => {
        let element = document.getElementById(itemId)
        console.log(element.offsetWidth, width)
        if(element && element.offsetWidth > width){
            setVisible(true)
        }
    }

    return (
        <OutsideClickHandler  onOutsideClick={() => setVisible(false)}>
        <div className={cn(styles.custom, className, {[styles.active] : visible})}>
            <div className={styles.children} onMouseMove={showTooltip} onMouseLeave={() => setVisible(false)}>{children}</div>
            <div className={styles.tooltipContainer}>
                {title}
            </div>
        </div>
        </OutsideClickHandler>
    );
};

export default TooltipGlodal;
