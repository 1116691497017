import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import Icon from "../../../../../Icon";
import {ReactComponent as ArchiveSvg} from '../../../../../../media/images/icons/archive.svg'
import {ReactComponent as Trophy} from '../../../../../../media/images/icons/trophy.svg'
import Modal from "../../../../../Modals/ModalComponent";
import DeleteMember from '../../../DeleteMemberModal'
import AddMemberModal from "../../../AddMembersToOrganisation";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useAccess } from "../../../../../Page";

const Control = ({className, to, memberAction,memberEmail, id, appid, apps, setApps, permissions}) => {

  const [visibleModal, setVisibleModal] = useState(false)
  const { accessPermissions } = useAccess(); 
  const {allowAddInviteMembers,allowEditMemberAccess} = accessPermissions || {};
  const actions = [
    {
      icon: "edit",
      action:  () => setVisibleModal(true),
    }
  ];

  return (
    <>
      <div className={cn(styles.control, className)}>
        {allowEditMemberAccess&&actions.map((x, index) => {
            return(
              <Link to={x.pathName} state={{id : id, appid : appid}} className={styles.button} key={index} onClick={x.action}>
                <Icon name={x.icon} size="20" className={styles.icon}/>
              </Link>
            )
        })}
      </div>
      <Modal visible={visibleModal} width='max' onClose={() => setVisibleModal(false)}>
        <AddMemberModal to={to} memberEmail={memberEmail} id={id} permissions={permissions} apps={apps} setApps={setApps} memberAction={memberAction} onClose={() => setVisibleModal(false)}/>
        {/* <DeleteMember id={id} onClose={() => setVisibleModal(false)}/> */}
      </Modal>
    </>
  );
};

export default Control;
