import React, { useEffect, useState } from "react";
import styles from "./row.module.sass";
import Control from "./Control/index";
import { Link, useParams } from "react-router-dom";
import TooltipGlodal from "../../../../TooltipGlodal";
import Match from '../../../../../media/images/placeholders/Mission.png'
import cn from "classnames";
import { useDispatch } from "react-redux";
import { extarctImageFromUrlAction } from "../../../../../redux/action/common/commonAction";
import SnapShot from "../../../../Images";

const Row = ({ item, className, access, value, onChange }) => {
    
    const {appid} = useParams()

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                    {item.logo !== undefined &&
                        <SnapShot id={`mactchIcon_${item.id}`} url={item.logo} alt={item.name} placeholder={Match} type='Images' width={36} height={36}/>
                    }
                </div>
                <div className={styles.col}>
                    <Link to={`/view-match/${appid}/${item.id}`}>
                        <TooltipGlodal width={180} itemId={`matchName${item.id}`} title={item.name}><div className={styles.itemName}><span id={`matchName${item.id}`}>{item.name}</span></div></TooltipGlodal>
                        <div className={styles.itemId}>{item.matchId}</div>
                    </Link>
                </div>
                <div className={styles.col}>{item.matchFormatTypeMasterId == 1 ? 'Single Player' : item.matchFormatTypeMasterId == 2 ? 'Multiplayer' : item.matchFormatTypeMasterId === 3 ? 'Multiplayer Team' : null}</div>
                <div className={styles.col}>{item.minPlayers}</div>
                <div className={styles.col}>{item.maxPlayers}</div>
                <div className={styles.col}>{item.matchOutcomeTypeMasterId == 1 ? 'Score' : item.matchOutcomeTypeMasterId == 2 ? 'Completion Time' : item.matchOutcomeTypeMasterId == 3 ? 'Win Loss Draw' : item.matchOutcomeTypeMasterId == 4 ? 'Finish Position' : item.matchOutcomeTypeMasterId == 5 ? 'Winnings Collected' : ''}</div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 &&
                        <Control access={access} className={styles.control} appid={appid} id={item.id} name={item.name} status={item.archive} type='matches'/>
                    }
                </div>
            </div>
        </>
    );
};

export default Row;
