import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import Icon from "../../../../../Icon";
import Modal from "../../../../../Modals/ModalComponent";
import {ReactComponent as ArchiveSvg} from '../../../../../../media/images/icons/archive.svg'
import { useAccess } from "../../../../../Page";

const Control = ({className, id, appid, playerName}) => {

  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleArchiveModal, setVisibleArchiveModal] = useState(false)
  const { accessPermissions } = useAccess();
  const { allowPlayersEdit} = accessPermissions || {};

  const actions = [
    {
      icon: "edit",
      pathName : `/player-details/${appid}/${id}`,
      action: () => console.log("Player Detail"),
    },
    // {
    //   icon: "download",
    //   action: () => setVisibleArchiveModal(true),
    // },
    // {
    //   icon: "trash",
    //   action:  () => setVisibleModal(true),
    // }
  ];

  return (
    <>
      <div className={cn(styles.control, className)}>
        {actions.map((x, index) => {
            if(
              (!allowPlayersEdit && x?.pathName?.includes("/player-details"))
            ){
              return ;
            }
            return(
              <Link to={x.pathName} state={{id : id, appid : appid, playerName : playerName}} className={styles.button} key={index} onClick={x.action}>
                {x.icon == 'download' ?  <ArchiveSvg /> : <Icon name={x.icon} size="20" className={styles.icon}/>}
              </Link>
            )
        })}
      </div>
    </>
  );
};

export default Control;
