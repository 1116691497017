import React, { useState } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../../../Checkbox";
import Icon from "../../../../../../Icon";
import Control from "./Control/index";
import { useParams } from "react-router-dom";
import {ReactComponent as Add} from '../../../../../../../media/images/icons/add_meta.svg'
import {ReactComponent as Remove} from '../../../../../../../media/images/icons/remove.svg'
import Items from '../../../../../../../media/images/placeholders/Items.png'
import { useEffect } from "react";
import CurrencyTooltip from '../../../../../../CustomTooltips/CurrencyTooltip'
import { useDispatch } from "react-redux";
import { extarctImageFromUrlAction } from "../../../../../../../redux/action/common/commonAction";
import SnapShot from "../../../../../../Images";
/* Deleted Schedule, Modal, Modal Products and Actions */

const Row = ({usedFor, tableFor, item, items, index, setValue, setData }) => {
    
    const {appid} = useParams()

    const addContent = (item, index) => {
        if(tableFor == 'configure'){
            if(usedFor === 'Link Rewards'){
                let itemList = [...items]
                itemList[index].status = true
                setData(itemList)
                let content = JSON.parse(sessionStorage.getItem('link-task-rewards'))
                let newContent = {iconUrl : item.iconUrl, name : item.name, itemId : item.id, type : (item.isConsumable == true ? 'Consumable' : 'Durable'), quantity : null, displayId : item.itemId}
                content.push(newContent)
                setValue(content)
                sessionStorage.setItem('link-task-rewards', JSON.stringify(content))
            }else{
                let itemList = [...items]
                itemList[index].status = true
                setData(itemList)
                let content = JSON.parse(sessionStorage.getItem('task-rewards'))
                let newContent = {iconUrl : item.iconUrl, name : item.name, itemId : item.id, type : (item.isConsumable == true ? 'Consumable' : 'Durable'), quantity : null, displayId : item.itemId}
                content.push(newContent)
                setValue(content)
                sessionStorage.setItem('task-rewards', JSON.stringify(content))
            }
        }else{
            if(usedFor === 'Link Rewards'){
                let itemList = [...items]
                itemList[index].status = true
                setData(itemList)
                let content = JSON.parse(sessionStorage.getItem('link-rewards'))
                let newContent = {iconUrl : item.iconUrl, name : item.name, itemId : item.id, type : (item.isConsumable == true ? 'Consumable' : 'Durable'), quantity : null, displayId : item.itemId, priceList: item.inventoryItemPrices}
                content.push(newContent)
                // setValue(content)
                sessionStorage.setItem('link-rewards', JSON.stringify(content))
            }else{
                let itemList = [...items]
                itemList[index].status = true
                setData(itemList)
                let content = JSON.parse(sessionStorage.getItem('contents'))
                let newContent = {iconUrl : item.iconUrl, name : item.name, itemId : item.id, type : (item.isConsumable == true ? 'Consumable' : 'Durable'), quantity : null, displayId : item.itemId, priceList: item.inventoryItemPrices}
                content.push(newContent)
                // setValue(content)
                sessionStorage.setItem('contents', JSON.stringify(content))
            }
        }
    }

    const RemoveContent = (item, id) => {
        if(tableFor == 'configure'){
            if(usedFor === 'Link Rewards'){
                let itemList = [...items]
                itemList[index].quantity = null
                itemList[index].status = false
                itemList[index].archive = true
                setData(itemList)
                item.status = false
                let content = JSON.parse(sessionStorage.getItem('link-task-rewards'))
                let removedReward = [...content]
                let remainingRewards = removedReward && removedReward.map(e => e.itemId === id ? {...e, archive: true} : e)
                setValue(remainingRewards)
                sessionStorage.setItem('link-task-rewards', JSON.stringify(remainingRewards))
            }else{
                let itemList = [...items]
                itemList[index].quantity = null
                itemList[index].status = false
                setData(itemList)
                item.status = false
                let content = JSON.parse(sessionStorage.getItem('task-rewards'))
                let removedReward = [...content]
                let remainingRewards = removedReward && removedReward.map(e => e.itemId === id ? {...e, archive: true} : e)
                setValue(remainingRewards)
                sessionStorage.setItem('task-rewards', JSON.stringify(remainingRewards))
            }
        }else{
            if(usedFor === 'Link Rewards'){
                let itemList = [...items]
                itemList[index].quantity = null
                itemList[index].status = false
                setData(itemList)
                item.status = false
                let content = JSON.parse(sessionStorage.getItem('link-rewards'))
                let removedReward = [...content]
                // setValue(remainingRewards)
                let remainingRewards = removedReward && removedReward.map(e => e.itemId === id ? {...e, archive: true} : e)
                sessionStorage.setItem('link-rewards', JSON.stringify(remainingRewards))
            }else{
                let itemList = [...items]
                itemList[index].quantity = null
                itemList[index].status = false
                setData(itemList)
                item.status = false
                let content = JSON.parse(sessionStorage.getItem('contents'))
                let removedReward = [...content]
                // setValue(remainingRewards)
                let remainingRewards = removedReward && removedReward.map(e => e.itemId === id ? {...e, archive: true} : e)
                sessionStorage.setItem('contents', JSON.stringify(remainingRewards))
            }
        }
    }

    const onInputChange = (e,id) => {
        if(tableFor == 'configure'){
            if(usedFor === 'Link Rewards'){
                let itemList = [...items]
                itemList[index].quantity = parseInt(e.target.value)
                setData(itemList)
                const content = JSON.parse(sessionStorage.getItem('link-task-rewards'))
                const updatedArray = content.map(item => {
                    if (item.itemId === id) {
                    return { ...item, quantity: parseInt(e.target.value) };
                    }
                    return item;
                });
                setValue(updatedArray)
                sessionStorage.setItem('link-task-rewards', JSON.stringify(updatedArray))
            }else{
                let itemList = [...items]
                itemList[index].quantity = parseInt(e.target.value)
                setData(itemList)
                const content = JSON.parse(sessionStorage.getItem('task-rewards'))
                const updatedArray = content.map(item => {
                    if (item.itemId === id) {
                    return { ...item, quantity: parseInt(e.target.value) };
                    }
                    return item;
                });
                setValue(updatedArray)
                sessionStorage.setItem('task-rewards', JSON.stringify(updatedArray))
            }
        }else{
            if(usedFor === 'Link Rewards'){
                let itemList = [...items]
                itemList[index].quantity = parseInt(e.target.value)
                setData(itemList)
                const content = JSON.parse(sessionStorage.getItem('link-rewards'))
                const updatedArray = content.map(item => {
                    if (item.itemId === id) {
                    return { ...item, quantity: parseInt(e.target.value) };
                    }
                    return item;
                });
                // setValue(updatedArray)
                sessionStorage.setItem('link-rewards', JSON.stringify(updatedArray))
            }else{
                let itemList = [...items]
                itemList[index].quantity = parseInt(e.target.value)
                setData(itemList)
                const content = JSON.parse(sessionStorage.getItem('contents'))
                const updatedArray = content.map(item => {
                    if (item.itemId === id) {
                    return { ...item, quantity: parseInt(e.target.value) };
                    }
                    return item;
                });
                // setValue(updatedArray)
                sessionStorage.setItem('contents', JSON.stringify(updatedArray))
            }
        }
    }

    useEffect(()=>{

    }, [item])

    return (
        <>
            <div className={styles.row}>
                {item.status == false ?
                    <div className={styles.col}><Add className={styles.addIcon} onClick={()=>{addContent(item, index)}}/></div>
                :item.status == true ?
                    <div className={styles.col}><Remove className={styles.addIcon} onClick={()=>{RemoveContent(item, item.id)}}/></div>
                : ''}
                <div className={styles.col}>
                    <div className={styles.item}>
                        <div className={styles.modalPreview}>
                            <SnapShot id={`itemIcon${usedFor && usedFor}_${item.id}`} url={item.iconUrl} alt={item.name} placeholder={Items} type='Images' width={36} height={36}/>
                            {/* <img id={`itemIcon_${item.id}`} alt="Item" /> */}
                        </div>
                        <div className={styles.details}>
                            <div>{item.name}</div>
                            <div className={styles.itemId}>{item.itemId}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.col}><div className={styles.modalItemName}>{item.name}</div><div className={styles.modalItemId}>{item.itemId}</div></div>
                <div className={styles.col}>
                    {item.inventoryItemPrices && item.inventoryItemPrices.slice(0,1).map((e,i)=>{
                        if(e.priceTypes == 'virtual currency' || e.priceTypes == 'RMG' || e.priceTypes == 'real money' || e.priceTypes == 'virtual'){
                            return (
                                <>
                                    <div className={styles.prices}>
                                        <span className={e.priceTypes == 'virtual currency' ? styles.virtual : styles.real}>{e.currency.code}<span>&nbsp;</span>{e.price}</span>
                                        {(item.inventoryItemPrices && item.inventoryItemPrices.length > 1 && i === 0) ? 
                                            <CurrencyTooltip type='item' text='Currency' data={item.inventoryItemPrices}><span className={styles.count}>+ {item.inventoryItemPrices.length - 1}</span></CurrencyTooltip> 
                                        : ''}
                                    </div>
                                </>
                            )
                        }else if(e.priceTypes == 'IAP'){
                            return(
                                <>
                                    <div className={styles.prices}>
                                        <span className={styles.IAP}>{e.realWorldCurrency.code}<span>&nbsp;</span>{e.price}</span>
                                        {(item.inventoryItemPrices && item.inventoryItemPrices.length > 1 && i === 0) ? 
                                            <CurrencyTooltip type='item' text='Currency' data={item.inventoryItemPrices}><span className={styles.count}>+ {item.inventoryItemPrices.length - 1}</span></CurrencyTooltip> 
                                        : ''}
                                    </div>
                                </>
                            )
                        }
                    })} 
                </div>
                <div className={styles.col}><div className={styles.rewardsInput}><input disabled={item.status == false ? true : false} key={item.id} className={styles.quantity} type="number" onWheelCapture={e => {e.currentTarget.blur()}} value={items[index].status == false ? '' : items[index].quantity} onChange={(e)=>{onInputChange(e, item.id)}}/></div></div>
            </div>
        </>
    );
};

export default Row;
