import React, {useState, useEffect, useContext} from 'react'
import cn from 'classnames'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styles from './getMembers.module.sass'
import Card from '../../../../components/Card'
import Icon from '../../../../components/Icon'
import addIcon from '../../../../media/images/icons/light.png'
import Form from '../../../../components/Form'
import { ToastContainer } from 'react-toastify';
import MembersTableLayout from '../../../../components/AppSettingComponent/MemberComponent/OrgMemberTableComponent'
import Control from './Control'
import { BASE_URL, GET_APPS } from '../../../../resources/APIEndpoints'
import axios from 'axios'
import { getallAppAction } from '../../../../redux/action/apps/appsAction'
import { getAcceptedMembersAction, getInvitedMembersAction } from '../../../../redux/action/AppSettings/Members/MembersAction'
import EmptyStates from '../../../../components/EmptyStates'
import Loader from '../../../../components/Loaders/LoaderProgress'
import { Pagination, PaginationItem, Stack } from '@mui/material'
import {ReactComponent as ArchiveSvg} from '../../../../media/images/icons/archive.svg'
import GlobalStateContext from '../../../../components/Context/GlobalStates/GlobalStateContext'
import SkeletonEmptyState from '../../../../components/Skeleton/EmptyStateSkeleton'
import SkeletonTable from '../../../../components/Skeleton/TableSkeleton'
import OrganizationHeader from '../../../../components/Header/OrganizationHeader'

const Tabs = ["Accepted", "Invited"];

const GetMembers = ({className}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {appid} = useParams()
  const globalStates = useContext(GlobalStateContext)
  
  const [activeIndex, setActiveIndex] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState()
  const [apps, setApps] = useState([])
  // Skeleton
  const [showSkeleton, setShowSkeleton] = useState(true)
  const [appList, setAppsList] = useState([])

  // const getAllApps = () => {
  //   let apps = {
  //     organisationId: localStorage.getItem('orgId'),
  //     getAllApps:true
  //   }
  //   dispatch(getallAppAction(apps))
  // }

  const getAllApps = async(orgID,api_keys) => {
    let appsDetails = []
      const token = localStorage.getItem('token')
      const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ?  JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ?  JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey :  JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}

      let getAllAppsByOrg = {
        organisationId: localStorage.getItem('orgId'),
        getAllApps:true
      }
      // let appid
      let res = await axios.post(BASE_URL + GET_APPS, getAllAppsByOrg, headers)
      if(res && res.status == 200 && res?.data?.data?.projectDetails){
          // appid=res.data.data.projectDetails && res.data.data.projectDetails[0].id
          setAppsList(res.data.data.projectDetails);
      }
  }

  useEffect(()=>{
    getAllApps()
  }, [])

  // let appList = useSelector((state) => {
  //   return state.apps.app
  // })

  const configureApps = () => {
    if(appList && appList.length > 0){
      setApps(appList && appList.map((e) => ({...e, checked : true, permission : {id : 1, name : 'View Only'}})))
    }
  }

  useEffect(() => {
    configureApps()
  }, [appList])

  const getAllAppMembers = () => {
    let getMember = {
      organisationId : localStorage.getItem('orgId'),
      projectId : appid
    }
    dispatch(getAcceptedMembersAction(getMember, configureSkeleton))
  }

  useEffect(() =>{
    getAllAppMembers()
  }, [page])

  const configureSkeleton = () => {
    setShowSkeleton(true)
    setTimeout(() => {
      setShowSkeleton(false)
    }, 1000)
  }

  let acceptedMembers = useSelector((state) => {
    return state.members.acceptedMembers
  })

  let invitedMembers = useSelector((state) => {
    return state.members.invitedMembers
  })

  let isLoading = useSelector((state)=>{
    return state.members.isLoading
  })

  const onSearch = (search) => {
    if(search == ''){
      getAllApps()
    }else{
      let searchMembers = {search : search, projectId : appid}
    }
  }

  // Loader
  if(isLoading){
    return(
      <>
        <div className={styles.loading}>
          <Loader />
        </div>
      </>
    )
  }

  return(
    <>
        <ToastContainer />
        <OrganizationHeader title='Accepted Members' apps={apps} setApps={setApps}/>
        <Card layOut={`Get`} className={styles.card} classCardHead={styles.head} >
          <div className={styles.member}>
            <div className={styles.wrapper}>
                {showSkeleton && acceptedMembers?.length === 0 && 
                  <SkeletonEmptyState />
                }
                {showSkeleton && acceptedMembers?.length > 0 && 
                  <SkeletonTable rows={acceptedMembers.length} column={5} controls={1} type={['recatangle', 'recatangle', 'recatangle', 'recatangle', 'circular']} colWidth={[36, 100, 100, 36, 36]} colHeight={[36, 15, 25, 36, 36]} />
                }
                {!showSkeleton && acceptedMembers?.length === 0 &&
                  <EmptyStates useFor='org' type="OrgMembers" memberAction='add' apps={apps} setApps={setApps} access={globalStates?.user?.type !== 'admin'} />
                }
                {!showSkeleton && acceptedMembers?.length > 0 &&
                  <MembersTableLayout to='accepted' items={acceptedMembers} title="Last edited" apps={apps} setApps={setApps} userType={globalStates?.user?.type} />
                }
            </div>
          </div>
        </Card>        
    </>
  )

}

export default GetMembers