import React, { useEffect, useState, useRef, useContext} from "react";
import cn from "classnames";
import axios from "axios";
import customAxios from "../../../../../../utils/interceptor";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { WithContext as ReactTags } from "react-tag-input";
import { useDispatch, useSelector } from "react-redux";
import styles from "./StoreInformation.module.sass";
import Card from "../../../../../../components/Card";
import TextInput from "../../../../../../components/TextInput";
import TextArea from "../../../../../../components/TextArea";
import Tooltip from "../../../../../../components/Tooltip";
import PlatformDropdown from '../../../../../../components/MultiselectDropDown/PlatformDropdown'
import LocationDropdown from '../../../../../../components/MultiselectDropDown/LocationDropdown';
import ProgressionLevelMultiSelect from '../../../../../../components/Dropdown/ProgressionLevelTypeableDropdown';
import {ReactComponent as AddButtonIcon} from '../../../../../../media/images/icons/add_button.svg';
import {ReactComponent as AddMetaIcon} from '../../../../../../media/images/icons/add_meta.svg';
import {ReactComponent as RemoveIcon} from '../../../../../../media/images/icons/remove.svg';
import Store from '../../../../../../media/images/placeholders/Store.png'
import CommonDropdown from '../../../../../../components/MultiselectDropDown/CommonDropdown'
import { BASE_URL, GET_APPS, GET_BUNDLES, GET_ITEMS, GET_PROGRESSION_SYSTEM } from '../../../../../../resources/APIEndpoints';
import Control from "../Control";
import ContentTable from '../../../../../../components/BuildComponents/EconomyComponent/StoreComponent/ContentTableLayout'
import Checkbox from "../../../../../../components/Checkbox"
import ItemDropdown from "../../../../../../components/Dropdown/ItemDropdown"
import { getAllItemAction } from "../../../../../../redux/action/builds/economy/items/itemAction";
import { createStoreAction } from '../../../../../../redux/action/builds/economy/stores/storeAction';
import { getAllBundlesAction } from '../../../../../../redux/action/builds/economy/bundles/bundleAction';
import {getAllCurrencyAction} from '../../../../../../redux/action/builds/economy/currency/currencyAction'
import TagInputWithDropdown from "../../../../../../components/TagInputWithDropdown";
import Toast from "../../../../../../components/Toast";
import FileUploadSidebar from "../../../../../../components/FileUploadSidebar";
import {addMediaAction, getAllMediaAction } from "../../../../../../redux/action/AppSettings/Media/mediaAction";
import TooltipTitle from "../../../../../../Tooltip/TooltipTitle";
import JSONMetaDataValueInput from "../../../../../../components/MetaInput";
import app,{increaseUnreadCountForAllUsers} from "../../../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";
import GlobalStateContext from "../../../../../../components/Context/GlobalStates/GlobalStateContext.js";
import { extarctImageFromUrlAction } from "../../../../../../redux/action/common/commonAction.js";
import SnapShot from "../../../../../../components/Images";
const lockBy = [{id: 1, title : "Item/Bundle"}, {id : 2, title : "Progression System"}]


const StoreInformation = ({className,isChanged, setIsChanged, onClose}) => {

    const [platform, setPlatform] = useState([])
    // const [platId, setPlatId] = useState()
    const [platformOption, setPlatformOption] = useState([])
    const dispatch = useDispatch()
    const [locations, setLocations] = useState([])
    const [locationOption, setLocationOption] = useState([])


    const navigate = useNavigate()
    const {appid} = useParams()

    const globalStates = useContext(GlobalStateContext)
    const [storeIcon, setStoreIcon] = useState()
    const [allCurrency, setAllCurrency] = useState([])
    const [allItems, setAllItems] = useState([])
    const [getAllBundle, setAllBundle] = useState([])
    const [items, setItems] = useState()
    const [unlockItemId, setUnlockItemId] = useState()
    const [lockItemErr, setLockItemErr] = useState(false)
    
    const [itemIcon, setItemIcon] = useState([])

    const [lockItems, setLockItems] = useState()
    const [lockProgression, setLockProgression] = useState()


    const [tags, setTags] = useState([]);

    const [LevelLock, SetLevelLock] = useState(false);

    const [uploadingMedia, setUploadingMedia] = useState([]);


    const [PSOptions, setPSOptions] = useState([])
    const [PS, setPS] = useState([])
    const [lockPSErr, setLockPSErr] = useState(false)
        
    const onTagInput = () => {
        if(document.getElementById('tagsInput')){
            document.getElementById('tagsInput').focus()
        }
    }
    const KeyCodes = { comma: 188, enter: 13 };
    
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = (tag) => {
        if(tags.length < 10){
            setTags([...tags, tag]); 
        }else{
            toast.warning(<Toast type='Warning' messages='Only 10 tags are allowed'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        }
    };
    
    const handleDrag = (tag, currPos, newPos) => {
        const newTags = [...tags].slice();
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        setTags(newTags);
    };

    const handleTagClick = (index, event) => {
        event.stopPropagation();
    };

    const onClearAll = () => {
    setTags([]);
    };

    const onTagUpdate = (i, newTag) => {
    const updatedTags = tags.slice();
    updatedTags.splice(i, 1, newTag);
    setTags(updatedTags);
    };

    const [storeName, setStoreName] = useState('')
    const [storeID, setStoreID] = useState('')
    const [storeDescription, setStoreDescription] = useState()
    const [metaData, setMetaData] = useState([{key : '' , value : null}])
    const [Category, setCategory] = useState([{catName : 'Default Category' , catID : '', ID:'',categoryIcon:'',storeContent:[]}])


    const addMetaData = () => {
        const metaDataField = []
        metaDataField.push(...metaData, {key : '' , value : null})
        setMetaData(metaDataField)
    }

    const addCategory = (i) => {
        const CategoryField = []
        CategoryField.push(...Category, {catName : '' , catID : '', ID:'',categoryIcon:'',storeContent:[]})
        setCategory(CategoryField)
        const IconSrc = []
        IconSrc.push(...logo1, {src1: placeholder , alt1: 'Upload an Image'})
        setLogo1(IconSrc)
        sessionStorage.setItem('contents', JSON.stringify(CategoryField))
        customizeCurrencyData(i+1)
        customizeBundleData(i+1)
        customizeItemData(i+1)
    }

    const removeCategory = (index) => {
        let removeCatField = Category
        let CatField = removeCatField.filter((e,i,c)=>{
            return i != index
        })
        setCategory(CatField)
        sessionStorage.setItem('contents', JSON.stringify(CatField))
    }

    const removeMetaData = (index) => {
        let removeField = metaData
        let metaFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setMetaData(metaFields)
    }

    const onChangeKey = (e, index) => {
        let keys = [...metaData]
        keys[index].key = e.target.value
        setMetaData(keys)
    }

    const onChangeValue = (e, index) => {
        let values = [...metaData]
        values[index].value = e.target.value
        setMetaData(values)
    }

    const onChangeCategoryID = (e, index) => {
        let val = [...Category]
        val[index].catID = e.target.value
        setCategory(val)
        sessionStorage.setItem('contents', JSON.stringify(val))
    }
    const onChangeCategoryName = (e, index) => {
        let val = [...Category]
        val[index].catName = e.target.value
        setCategory(val)
        sessionStorage.setItem('contents', JSON.stringify(val))
    }

    const cancel = () => {
        setIsChanged(false)
        navigate(`/store/${appid}`)
    }
    const onChangeLevel = (value, index) => {
        let unlockLevelSystem = [...PS]
        unlockLevelSystem[index].lockedLevel = parseInt(value)
        setPS(unlockLevelSystem)
    }
    
    const onChangeStoreName = (e) => {
        if(e.target.value == ''){
            setStoreName('')
        }else{
            errors.nameError = ''
            setStoreName(e.target.value)
        }
    }

    const onChangeStoreID = (e) => {
        if(e.target.value == ''){
            setStoreID('')
        }else{
            errors.idError = ''
            setStoreID(e.target.value)
        }
    }

    const onChangeStoreDescription = (e) => {
        setStoreDescription(e.target.value)
    }

    const ref = useRef()
    const ref1 = useRef()
    
    
    const placeholder = Store;

    const [{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'});
    const [logo1, setLogo1] = useState([{src1: placeholder , alt1: 'Upload an Image'}])

    const uploadLogo = async(e) =>{
      
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        }

        var file = e.target.files[0];
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}`)
        setStoreIcon(res.data.getUrl);
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
            toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        })
        .catch(function (error) {
            toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        });
    }

    const onSelectedCategoryLogo = async(e,i) =>{
        
        logo1[i].src1 = e
        logo1[i].alt1 = e
        const tempCategory=[...Category]
        tempCategory[i].categoryIcon=e
        setCategory(tempCategory)
    }

    const progressToastId = useRef(null);
    const [uploadProgress, setUploadProgress] = useState(0);




// A) Add item
    const addUploadingItem = (file) => {
        const newItem = { fileName: file.name, progress: 0 };
        setUploadingMedia((prev) => [...prev, newItem]);
        return newItem;
    };
    
    // B) Update item
    const updateUploadingItem = (fileName, progress) => {
        setUploadingMedia((prev) =>
        prev.map((item) =>
            item.fileName === fileName ? { ...item, progress } : item
        )
        );
    };
    
    // C) Remove item
    const removeUploadingItem = (fileName) => {
        setUploadingMedia((prev) =>
        prev.filter((item) => item.fileName !== fileName)
        );
    };



    // Show a toast if one doesn’t already exist
  const startProgressToast = () => {
    // if (!progressToastId.current) {
    //   progressToastId.current = toast(
    //     <Toast type="Info" messages="Starting upload..." />,
    //     {
    //       autoClose: false,
    //       closeOnClick: false,
    //       draggable: false,
    //       progress: 0,
    //     }
    //   );
    // }
  };


  const handleUploadProgress = (progressEvent,name) => {
    // if (!progressToastId.current) return; // no toast to update yet

    const { loaded, total } = progressEvent;
    const percent = Math.floor((loaded * 100) / total);
    updateUploadingItem(name, percent);
    // const fraction = percent / 100; // toastify expects a fraction (0-1)

    // toast.update(progressToastId.current, {
    //   progress: fraction,
    //   render: (
    //     <Toast
    //       type="Info"
    //       messages={`Uploading file... ${percent}%`}
    //     />
    //   ),
    // });
  };

  const dismissProgressToast = () => {
    // if (progressToastId.current) {
    //   toast.dismiss(progressToastId.current);
    //   progressToastId.current = null;
    // }
  };
  

    const uploadCategoryLogo = async(e,i,getMedia) =>{
        if(e.target.files[0]) {
            logo1[i].src1 = URL.createObjectURL(e.target.files[0]);
            logo1[i].alt1 = e.target.files[0].name;

        }
        startProgressToast();
        // Converting Image to base64 and then converting to binary to upload
        var file1 = e.target.files[0];
        addUploadingItem(file1);
        const file_binary1 = await convertbase64(file1)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file1.type}&projectId=${appid}&category=${`icons`}&fileName=${file1.name}`)

        const tempCategory=[...Category]
        tempCategory[i].categoryIcon=res.data.getUrl;
        setCategory(tempCategory);
        var config1 = {
            headers : {'Content-Type' : `${file1.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary1),
            onUploadProgress: ((z)=> handleUploadProgress(z,e.target.files[0].name)),
        }
        sessionStorage.setItem('contents', JSON.stringify(tempCategory))
    
        axios(config1)
        .then(function (response) {
            let setSidebar=true
            if(setSidebar){
                let uploadLogo = {
                    projectId : appid,
                    contents : [{
                        contentType : file1.type,
                        fileName : file1.name,
                        category : "icons",
                        url : res.data.getUrl,
                        fileSize : file1.size/1024
                    }]
                }
                dispatch(addMediaAction(uploadLogo, null, null, appid, 0, null,'icons'))
                // setSidebar(false)
            }
            setUploadProgress(0);
            removeUploadingItem(e.target.files[0].name);
            getMedia()
            setTimeout(()=>{
                dismissProgressToast();
            },[1500])
            toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        })
        .catch(function (error) {
            toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        });
        
    }

    const convertbase64 = (file) => {
        return new Promise((resolve, err)=>{
        const fileReader = new FileReader();
        fileReader.onload = (eve) =>{
            resolve(fileReader.result)
        }
        fileReader.readAsDataURL(file)
        })
    }


    function convertDataURIToBinary(dataURI) {
        var BASE64_MARKER = ';base64,';
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));
              
        for(var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }
    const [CategoryIcon, setCategoryIcon] = useState([])

    const Cancel = () => {
        setStoreIcon()
        setLogo({src: placeholder , alt: 'Upload an Image'})
        ref.current.value = ''
    }

    const CancelCategoryIcon = (i) => {

        const refLog=[...logo1];

        Category[i].categoryIcon= null
        refLog[i].src1=placeholder;
        refLog[i].alt1='Upload an Image';
        setLogo1(refLog);

        sessionStorage.setItem('contents', JSON.stringify(Category))
    }

    const onRemove = (selectedList, removedItem) => {
        setPlatform(oldValues => {return oldValues.filter(platform => platform.name !== removedItem.name)})
    }
    const removeFile = (index) => {
        let platformList = [...platform]
        platformList[index].fileName = ''
        platformList[index].assetBundleUrl = ''
        setPlatform(platformList)
    }


    const uploadFile = async(e) => {
        try{
            var file = e.target.files[0]; 
            const file_binary = await convertbase64(file)
            const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=octet-stream`)
            var config = {
                headers : {'Content-Type' : `${file.type}`},
                method: 'put',
                url: res.data.postUrl,
                data :convertDataURIToBinary(file_binary),
            }
            let response = await axios(config)
            if(response){
                toast.success('File Uploaded Successfully')
                if(response.status == 200){
                    return res.data.getUrl
                }
            }
            return false
        }catch(err){
            return false
        }
    }


    const getOptionalData = async() => {
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await axios.get(`${BASE_URL}/master-data`, headers)
        setLocationOption(res.data.data.countryMaster)

        let Platforms = []
        res.data.data.gamePlatforms && res.data.data.gamePlatforms.map((e, i) =>{
            Platforms.push({id : e.id, name : (e.platformName !== 'ios' ? e.platformName.charAt(0).toUpperCase() + e.platformName.slice(1) : e.platformName === 'ios' ? 'iOS' : '-')})
        })
        setPlatformOption(Platforms)
        
    }
    const getPS = async() => {
        let items = {
            projectId : appid
        }
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await customAxios.post(BASE_URL + GET_PROGRESSION_SYSTEM, items, headers)
        let progressionSystem = res.data.data.levelDetails
        progressionSystem && progressionSystem.forEach((e,i) => {
            return setPSOptions(prevState => [...prevState, {name : e.name, id : e.id, levelSystemLevelMapping:e.levelSystemLevelMapping}])
        })
    }
    const onLockBy = (e, title) => {
        if(e.target.checked){
            if(title == 'Item/Bundle'){
                setLockItems(true)
            }else{
                setLockProgression(true)
            }
        }else{
            if(title == 'Item/Bundle'){
                setLockItems(false)
            }else{
                setLockProgression(false)
            }
        }
    }


    const getAllCurrencyData = () => {
        let getAllCurrency = {
            projectId : appid,
        }
        dispatch(getAllCurrencyAction(getAllCurrency))
    }

    // const [virtualCurrencyData, setVirtualCurrencyData] = useState([])
    // const [realMoneyData, setRealMoneyData] = useState([])

    const customizeCurrencyData = (index=0) => {
        if(currenciesData && currenciesData.length > 0){
            const updatedArray = currenciesData && currenciesData.map((ele) => {
                const matchObj = Category[index]&& Category[index].storeContent.find(element => element.currencyId == ele.id)
                if(matchObj){
                    return {
                        ...ele,
                        quantity : matchObj.quantity,
                        status : true
                    }
                }else{
                    return {
                        ...ele,
                        quantity : null,
                        status : false
                    }
                }
            })
            let updatedArr=allCurrency;
            updatedArr[index]=updatedArray
            setAllCurrency(updatedArr)
           
           
        }
    }

    // Get Item API Call
    const [itemOptions, setItemOption] = useState()
    const [lockByOptionType, setLockByOptionType] = useState(null)

    const getItems = async() => {
        let items = {
            projectId : appid,
            offset: 0,
            limit: 1000
        }
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await customAxios.post(BASE_URL + GET_ITEMS, items, headers)
        return res.data.data.items
    }

    const getBundles = async() => {
        let bundles = {
            projectId : appid,
            offset: 0,
            limit: 1000
        }
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await customAxios.post(BASE_URL + GET_BUNDLES, bundles, headers)
        return res.data.data.bundles
    }

    const getLockByOptions = async() => {
        try {
            const [items, bundles] = await Promise.all([getItems(), getBundles()])
            const lockByOptions = [...items && items.map(x => ({...x, lockByOptionType: 'Item'})), ...bundles && bundles.map(x => ({...x, lockByOptionType: 'Bundle'}))]
            setItemOption(lockByOptions)
        }catch{
            console.log('Please Refresh')
        }finally{
            console.log('Data Fetched')
        }
    }

    useEffect(() => {
        getLockByOptions()
    }, [])

    const getAllItemsData = () => {
        let items = {
            offset: ((itemPage-1)*itemLimit),
            limit:itemLimit,
            projectId : appid
        }
        dispatch(getAllItemAction(items))
    }

    const getAllBundlesData = () => {
        let bundles = {
            offset: ((bundlePage-1)*bundleLimit),
            limit:bundleLimit,
            projectId : appid
        }
        dispatch(getAllBundlesAction(bundles))
    }

    function customizeItemData(index) {
        if(itemsData && itemsData.length > 0){
            const updatedArray = itemsData && itemsData.map((value) => {
                const matchObj = Category[index]&&Category[index].storeContent.find(element => element.itemId == value.id)
                if(matchObj){
                    return {
                        ...value,
                        quantity : matchObj.quantity,
                        status : true
                    }
                }else{
                    return {
                        ...value,
                        quantity : null,
                        status : false
                    }
                }
            })
            let updatedArr=allItems;
            updatedArr[index]=updatedArray
            setAllItems(updatedArr)
        }
    }

    // const customizeItemData = (index=0) => {
    //     if(itemsData && itemsData.length > 0){
    //         const updatedArray = itemsData && itemsData.map(value => ({...value, status : false, quantity : null}))
    //         let updatedArr=allItems;
    //         updatedArr[index]=updatedArray
    //         setAllItems(updatedArr)
    //         setItemOption(itemsData)
    //     }
    // }

    function customizeBundleData (index) {
        if(bundleData && bundleData.length > 0){
            const updatedArray = bundleData && bundleData.map((value) => {
                const matchObj = Category[index]&&Category[index].storeContent.find(element => element.bundleId == value.id)
                if(matchObj){
                    return {
                        ...value,
                        quantity : matchObj.quantity,
                        status : true
                    }
                }else{
                    return {
                        ...value,
                        quantity : null,
                        status : false
                    }
                }
            })
            let updatedArr=getAllBundle;
            updatedArr[index]=updatedArray;
            setAllBundle(updatedArr)
        }
    }
    // const customizeBundleData = (index=0) => {
    //         if(bundleData && bundleData.length > 0){
    //             const updatedArray = bundleData && bundleData.map(value => ({...value, status : false, quantity : null}))
    //             let updatedArr=getAllBundle;
    //             updatedArr[index]=updatedArray
    //             setAllBundle(updatedArr)
    //         }
    //     }

    const [itemPage,setItemPage]=useState(1);
    const [itemLimit,setItemLimit]=useState(8);
    const [bundlePage,setBundlePage]=useState(1);
    const [bundleLimit,setBundleLimit]=useState(8);

    let currenciesData = useSelector((state) => {
        return state.currencies.currency
    })

    let itemsData = useSelector((state) => {
        return state.item.items
    })

    let bundleData = useSelector((state) => {
        return state.bundles.bundles
    })

    const [errors, setErrors] = useState({nameError : '', idError : ''})

    const validation = (data) => {
        const error = {}
        let isValid = true

        if (data.stores[0].name == '') {
            error.nameError = 'Display Name is required';
            isValid = false;
            const element = document.getElementById('storeName');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        }
      
        if (data.stores[0].storeId == '') {
            error.idError = 'Store ID is required';
            isValid = false;
            if(data.stores[0].name != ''){
                const element = document.getElementById('storeId');
                const offset = 140;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        setErrors(error);

        if(isValid){
            return true
        }
    }

    const db = getFirestore(app);

    const submit = async () => {
        
        let isLock=false;
        let UnlockOperator=[];
        if (lockItems) {
            isLock=true;
            const obj={storeId:storeID,unlockItemId:unlockItemId}
            UnlockOperator.push(obj);
        }
        if (lockProgression) {
            isLock=true;
            PS.forEach((e)=>{
                UnlockOperator.push({storeId:storeID,levelSystemId:e.id,lockedLevel:e.lockedLevel})
            })
        }
        let unlockItems = []
        if(unlockItemId){
            if(lockByOptionType === 'Item'){
                unlockItems.push({ unlockItemId : unlockItemId, unlockBundleId: null, unlockLevelSystemId: null, lockByOptionType: 'Item'})
            }else{
                unlockItems.push({ unlockItemId : null, unlockBundleId: unlockItemId, unlockLevelSystemId: null, lockByOptionType: 'Bundle'})
            }
        }
        let unlockPS = []
        PS && PS.map((e,i) =>{
            unlockPS.push({id: e.dbID, unlockItemId : null, unlockLevelSystemId : e.id, lockedLevel : e.lockedLevel})
        })

        let unlockItemData = [...unlockItems, ...unlockPS]
        const strCategories = [];
        for(var i = 0; i < Category.length ; i++){
            const catData={
                storeCategoryId:Category[i].catID,
                name: Category[i].catName,
                iconUrl: Category[i].categoryIcon,
                isDefault:i==0,
                storeContents:[]
            }
            if (Array.isArray(Category)){
                for (let c = 0; c < Category[i].storeContent.length; c++) {
                const contData = {
                    quantity: Category[i].storeContent[c].quantity,
                    itemId: (Category[i].storeContent[c].itemId!==null?Category[i].storeContent[c].itemId:null),
                    bundleId:(Category[i].storeContent[c].bundleId!==null?Category[i].storeContent[c].bundleId:null),
                    currencyId:(Category[i].storeContent[c].currencyId!==null?Category[i].storeContent[c].currencyId:null),
                    storeContentPrice: []
                }
                if (Array.isArray(Category[i].storeContent[c].priceList)) {
                for (let z = 0; z < Category[i].storeContent[c].priceList.length; z++) {
                    const contPriceData = {
                        priceTypes: Category[i].storeContent[c].priceList[z].priceTypes,
                        price: Category[i].storeContent[c].priceList[z].price,
                        discount: Category[i].storeContent[c].priceList[z].discount,
                        productId: Category[i].storeContent[c].priceList[z]?.productId,
                        bonusCashAllowance: Category[i].storeContent[c].priceList[z].bonusCashAllowance,
                        gamePlatformMasterId: (Category[i].storeContent[c].priceList[z].gamePlatformMasterId===null ? 1 : Category[i].storeContent[c].priceList[z].gamePlatformMasterId),
                        currencyId: ((Category[i].storeContent[c].priceList[z].priceTypes === 'virtual currency' || Category[i].storeContent[c].priceList[z].priceTypes === 'RMG' || Category[i].storeContent[c].priceList[z].priceTypes === 'real money' || Category[i].storeContent[c].priceList[z].priceTypes === 'virtual') ?  Category[i].storeContent[c].priceList[z].currencyId :null),
                        realWorldCurrencyId:(Category[i].storeContent[c].priceList[z].priceTypes==="IAP" ? Category[i].storeContent[c].priceList[z].realWorldCurrencyId : null),
                    };
                    contData.storeContentPrice.push(contPriceData)
                }
                
                }
                catData.storeContents.push(contData)
            }
            }
            strCategories.push(catData)
        }

        const metaDataList = [...metaData];
        const finalMetaData = {};
        for(var m = 0; m < metaDataList.length ; m++){
            finalMetaData[metaDataList[m].key] = metaDataList[m].value;
        }

        let storeTags = []
        tags && tags.forEach((e)=>{
            storeTags.push(e.text)
        })
        let platformIds=[]
        let locationIds=[]

        platform&& platform.map((e,i)=>{
            platformIds.push(e.id)
        })
        locations&& locations.map((e,i)=>{
            locationIds.push(e.id)
        })


    const createStore ={

        stores: [
            {
                storeId: storeID,
                name: storeName,
                description: storeDescription,
                iconUrl: (storeIcon!==null||storeIcon!==undefined?storeIcon:""),
                storePlatformIds:platformIds,
                storeLocationIds:locationIds,
                isLocked: isLock,
                meta: finalMetaData,
                tags: storeTags,
                projectId:appid,
                storeCategories: strCategories,
                ...((lockItems || lockProgression) && {rewardUnlockCondition : unlockItemData}),
            }
        ]
    }
    if(validation(createStore)){
        try {
            // Await the response from createGameAction
            await dispatch(createStoreAction(createStore, navigate, appid, globalStates.setShowOverlay))
            // Access the response data (e.g., game ID)
                // const IdFromResponse = res?.data?.data?.id;

                setIsChanged(false);
                const user = JSON.parse(localStorage.getItem('user'))
                const memberID = user?.id
                const memberName = user?.name
                const orgId = (localStorage.getItem('orgId'))

                // Log activity in Firestore
                const activityLog = {
                    timestamp: new Date().toISOString(),
                    action_type: "CREATE",
                    app_id:appid,
                    resource: "STORE",
                    // resource_id: IdFromResponse, // Use the ID from response
                    description: `Store '${storeName}' created successfully`,
                    quantity: 1,
...(localStorage.getItem('environment')&&{environment:localStorage.getItem('environment')}),
operation_status: "SUCCESS",
                    activity_feed: true,
                    priority: "LOW",
                    performed_by_id: memberID,
                    organization_id: orgId,
                    performed_by:memberName,
                    request_json:JSON.stringify(createStore),
                };
                await addDoc(collection(db, "activityFeed"), activityLog);
await increaseUnreadCountForAllUsers( appid);
                console.log("Activity logged successfully");
            
        } catch (error) {
            console.error("Error creating game or logging activity:", error);
        }
    }
    setIsChanged(false)
}

    // useEffect(() => {
    //     customizeBundleData()
    // }, [bundleData])


    // useEffect(() => {
    //     customizeItemData()
    // }, [itemsData])

    // useEffect(()=>{
    //     customizeCurrencyData()
    // }, [currenciesData])


    useEffect(()=>{
        // getAllBundlesData()
        // getAllItemsData()
        getAllCurrencyData()
        storeContent()
    }, [])

    useEffect(()=>{
        getAllItemsData()
    },[itemPage])

    useEffect(()=>{
        getAllBundlesData()
    },[bundlePage])



        const contents = JSON.parse(sessionStorage.getItem('contents'))

        const storeContent = () => {
            sessionStorage.setItem('contents', JSON.stringify(Category))
        }

        useEffect(() => {
            for (let index = 0; index < Category.length; index++) {
                customizeItemData(index)
            }
        }, [itemsData])

        useEffect(() => {
            for (let index = 0; index <= Category.length; index++) {
                customizeBundleData(index)
            }        
        }, [bundleData])


    useEffect(()=> {
        getPS()
        setLocations([])
        setPlatform([])
        getOptionalData()
        storeContent()
    }, [appid])


    const setchange = () => {
        if (
            (storeIcon==null||storeIcon==undefined)&&
            (storeName===''||storeName==="")&&
            (storeID===''||storeID==="")&&
            (storeDescription===undefined||storeDescription==''||storeDescription=="")&&
            (platform.length==0)&&
            (locations.length==0)&&
            (Category.length==1 && Category[0].catName == "Default Category" && Category[0].catID == "" && Category[0].ID=="" && (Category[0].categoryIcon==""||Category[0].categoryIcon==null) && Category[0].storeContent.filter(item => !item?.archive).length==0)&&
            (lockItems===false||lockItems==undefined)&&
            (lockProgression===false||lockProgression==undefined)&&
            (tags.length==0)&&
            (metaData.length==1&&metaData[0].key == "" && (metaData[0].value == "" || metaData[0].value == null))
        )
        {
            setIsChanged(false)
        }
        else 
        {
            setIsChanged(true)
        }
    }

    useEffect(()=>{
        setchange()
    },[platform,locations,items,unlockItemId,lockItems,lockProgression,tags,PS,storeName,storeID,storeDescription,metaData,Category,storeIcon])
    
    const [realWorldCurrencyOptions, setRealWorldCurrencyOptions] = useState([])
    
    const getRealWorldCurrency = async() => {
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await axios.post(`${BASE_URL}/currencies/get-world-currency`, {}, headers)
        res.data.data && res.data.data.forEach((element) => {
            setRealWorldCurrencyOptions(prevState => [...prevState, {realWorldCurrencyId : element.id, name : element.code.concat(" (", `${element.symbol}`, ")"),code:element.code, type : 'IAP'}])
        })
    }


    const [visibleHelp, setVisibleHelp] = useState(false)
    const [visibleCategorySidebar, setVisibleCategorySidebar] = useState(false)

    const [media, setMedia] = useState([])

    const getMedia = async() => {
        let getMediaData = {
            projectId : appid,
            category: 'icons',
            typeId : 0
        }
        await dispatch(getAllMediaAction(getMediaData))
    }

    let files = useSelector((state) => {
        return state.media.media.mediaDetails
    })

    const configureMedia = () => {
        if(files && files.length > 0){
            setMedia(files && files.map((e, i) => ({...e, status : false})))
        }else{
            setMedia([])
        }
    }

    const [catIndex,setCatIndex]= useState(null)

    useEffect(() => {
        getMedia()
        getRealWorldCurrency()
    }, [])

    useEffect(() => {
        configureMedia()
    }, [files])

    return (
        <>
            <ToastContainer />
            <Card className={cn(styles.card, className)} title="Store Information" classTitle="title-purple">
            <div className={styles.cardDescription}>
            <div className={styles.preview}>
                <div className={styles.previewImage}>
                    <SnapShot id='logo' url={storeIcon} alt={storeName} placeholder={Store} type='Images' width={60} height={60} borderRadius={16}/>
                    {/* <img id='logo' alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img> */}
                </div>
                <div className={styles.previewInput}> 
                    <input type="file" className='file-input' accept="image/jpeg, image/png, image/webp, .jpeg, .jpg, .png, .webp" ref={ref} onChange={(e)=>{uploadLogo(e)}}/>
                    <label className={styles.button} onClick={() => setVisibleHelp(true)}><AddButtonIcon className={styles.addIcon}/>Upload Icon</label>
                </div>
                {storeIcon != null && 
                    <div className={styles.previewCancel}>
                        <button className={styles.buttonCancel} onClick={Cancel}>Remove</button>
                    </div>
                }
            </div>
            <FileUploadSidebar media={media} setMedia={setMedia} types='Images' id={`icon`} multiple={false} visible={visibleHelp} setVisible={setVisibleHelp} icon={storeIcon} setIcon={setStoreIcon} setValue={setLogo} onClose={onClose} />
            <div className={styles.createItem}>
                <div className={styles.group}>
                    <TextInput tooltip={TooltipTitle.StoreName} id='storeName' className={styles.field} errorMessage={errors.nameError} label="Display Name*" name="displayName" type="text" required onChange={(e)=>{onChangeStoreName(e)}}/>
                    <TextInput value={storeID} isId={true} tooltip={TooltipTitle.StoreId} id='storeId' className={styles.field} errorMessage={errors.idError} label="Store ID*" name="itemId" type="text" required onChange={(e)=>{onChangeStoreID(e)}} />
                </div>

                <div className={styles.textArea}>
                    <TextArea type='STORE_DESCRIPTION' tooltip={TooltipTitle.StoreDescription} value={storeDescription} className={styles.field} rows={5} label="Store Description" onChange={(e)=>{onChangeStoreDescription(e)}}/>
                </div>
                

            </div>

            <div className={styles.createItem}>   
                    <div className={styles.group}>
                        <div className={styles.lockGroup}>
                            <PlatformDropdown className={styles.selexctField} label="Select Platform" placeholder="Choose Game Platform" options={platformOption} value={platform} setValue={setPlatform} />
                        </div>                
                        <div className={styles.lockGroup}>
                            <LocationDropdown className={styles.selectField} label="Choose Location" placeholder="Choose Location" options={locationOption} value={locations} setValue={setLocations} />
                        </div>
                    </div>
                </div>
            </div>
            </Card>



            {Category && Category.map((e,i)=>{
                return(
                    <>
                    <Card className={cn(styles.card, className)} title={(i==0)?`Default Category `:`Category ${i+1}`} classTitle="title-purple" style={{margin:"0px"}} head={<div>{Category && i === 0? <span className={styles.addIcon} onClick={()=>addCategory(i)}><AddMetaIcon /></span>:""}{Category && i > 0? <span style={{position:"absolute", right:"26px"}} onClick={()=>removeCategory(i)}><RemoveIcon /></span>:""}</div>}>
                <div className={styles.cardDescription}>
                    <div className={styles.preview}>
                        <div className={styles.previewImage}>
                            <SnapShot id={`storeCategory_${i}`} url={e?.categoryIcon} alt={e?.catName} placeholder={Store} type='Images' width={60} height={60} borderRadius={16}/>
                            {/* <img src={logo1[i].src1} alt={logo1[i].alt1} style={{maxWidth: "100px", maxHeight: "100px"}}></img> */}
                        </div>
                        <div className={styles.previewInput} onClick={() => {setVisibleCategorySidebar(true);setCatIndex(i)}}> 
                            <input type="file" /*id={`icon${i}`}*/ className='file-input' accept="image/jpeg, image/png, image/webp, .jpeg, .jpg, .png, .webp" ref={ref1} onChange={(e)=>{uploadCategoryLogo(e,i)}}/>
                            <label /*for={`icon${i}`}*/ className={styles.button}><AddButtonIcon className={styles.addIcon}/>Upload Icon</label>
                        </div>
                        {Category[i].categoryIcon &&
                            <div className={styles.previewCancel}>
                                <button className={styles.buttonCancel} id={`removeButton${i}`} onClick={()=>{CancelCategoryIcon(i)}}>Remove</button>
                            </div>
                        }
                    </div>

                    
                    <div className={styles.createItem}>
                    <div className={styles.group}>
                        <TextInput tooltip={TooltipTitle.StoreCategoryName} className={styles.field} label="Category Name" name="catName" type="text" required value={(Category[i].catName != '' || Category[i].catName != undefined || Category[i].catName != null) ? Category[i].catName : ''} onChange={(e)=>{onChangeCategoryName(e, i)}}/>
                        <TextInput isId={true} tooltip={TooltipTitle.StoreCategoryId} className={styles.field} label="Category ID" name="catID" type="text" required value={(Category[i].catID != '' || Category[i].catID != undefined || Category[i].catID != null) ? Category[i].catID : ''} onChange={(e)=>{onChangeCategoryID(e, i)}}/>
                        
                    </div>
                    </div>
                    <div className={styles.cardDescription}>
                    <div className={styles.createBundle}>
                        <div className={styles.groupHeading}>
                            <Control pageItem={itemPage} pageBundle={bundlePage} type='Store' currency={allCurrency[i]} itemLimit={itemLimit} itemPage={itemPage} setItemPage={setItemPage} bundleLimit={bundleLimit} bundlePage={bundlePage} setBundlePage={setBundlePage} fullCurrencyList={allCurrency} setCurrency={setAllCurrency} bundle={getAllBundle[i]} fullBundleList={getAllBundle} setBundle={setAllBundle} categoryIndex={i} item={allItems[i]} fullItemsList={allItems} setItem={setAllItems} setValue={setCategory} width='medium' fullCategories={Category} customizeBundleData={customizeBundleData} customizeItemData={customizeItemData}/>
                        </div>
                    </div>

                    {Category[i].storeContent && Category[i].storeContent.length > 0 &&Category[i].storeContent.some(e=>e.archive!==true) &&
                        <div className={styles.createBundle}>
                            <div className={styles.content}>
                                <div className={styles.wrapper}>
                                    <ContentTable platformOption={platformOption} realWorldCurrencyOptions={realWorldCurrencyOptions} items={Category} categoryIndex={i} setValue={setCategory} allItems={allItems} setAllItems={setAllItems} allBundles={getAllBundle} setAllBundle={setAllBundle}/>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                </div>
                    </Card>

                    </>
                )
            })}
            <FileUploadSidebar type={"store"} catIndex={catIndex} customUploadFunction={uploadCategoryLogo} customUploadingMedia={uploadingMedia} customSelectMediaFunction={onSelectedCategoryLogo} media={media} setMedia={setMedia} types='Images' id={`icon${'-'}`} multiple={false} visible={visibleCategorySidebar} setVisible={setVisibleCategorySidebar} onClose={onClose} />

            <Card className={cn(styles.card, className)} title="Access & Eligibility" classTitle="title-blue">
                <div className={styles.cardDescription}>
                    <div className={styles.createItem}>
                        <div className={styles.group}>
                            <div className={styles.fieldRadio}>
                                <div className={styles.radioLabel}>
                                    <span>Lock by<span><Tooltip className={styles.tooltip} title={TooltipTitle.StoreLock} icon="info"/></span></span>
                                </div>
                                <div className={styles.variants}>
                                    {lockBy.map((x, index) => (
                                        <Checkbox key={x.id} className={styles.checkbox} content={x.title} onChange={(e)=>{onLockBy(e, x.title)}} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.createItem}>

                        {lockItems &&
                            <div className={styles.group}>
                                <div className={styles.lockGroup}>
                                    <div className={styles.radioLabel}>
                                        <span>Choose Item/Bundle<span><Tooltip className={styles.tooltip} title={TooltipTitle.StoreItemBundle} icon="info"/></span></span>
                                    </div>
                                    <ItemDropdown placeholder='Select Item/Bundle' className={styles.selectField} groupBy={1} options={itemOptions} value={items} setValue={setItems} setUnlockItemId={setUnlockItemId} error={setLockItemErr} setType={setLockByOptionType}/>
                                    {lockItemErr && <span className={styles.danger}>*Item is required.</span>}
                                </div>
                            </div>
                            }
                        <div className={styles.group}>
                            {lockProgression ?
                                <div className={styles.lockGroup} style={{marginTop:"8px"}}>
                                    <div className={styles.radioLabel}>
                                        <span>Choose Progression System<span><Tooltip className={styles.tooltip} title={TooltipTitle.StorePS} icon="info"/></span></span>
                                    </div>
                                    <CommonDropdown placeholder='Select Progression System' className={styles.selectField} options={PSOptions} value={PS} setValue={setPS} error={setLockPSErr}/>
                                    {lockPSErr && <span className={styles.danger}>*Progression System is required.</span>}
                                </div>
                            : ''}
                            {lockProgression &&
                            <div className={styles.lockGroup} >
                                {PS && PS.map((element, index) =>{
                                    return(
                                        <>  
                                        <div style={{marginTop:"8px"}}>
                                            <ProgressionLevelMultiSelect placeholder='Select Level' label={element.name} className={styles.selectField} options={element.levelSystemLevelMapping} ind={index} value={PS} setValue={setPS}/>                                     
                                        </div>
                                        </>
                                    )
                                })}
                            </div>
                            }
                        </div>
                            
                    </div>
                </div>

            </Card>

            <Card className={cn(styles.card, className)} title="Custom Data" classTitle="title-red">
                
                <div className={styles.cardDescription}>
                <div className={styles.createItem}>
                    
                <TagInputWithDropdown tooltip={TooltipTitle.StoreTags} tags={tags} setTags={setTags}/>

                    <div className={styles.groupHeading}>
                        <span>Meta Data</span><span><Tooltip className={styles.tooltip} title={TooltipTitle.StoreMetaData} icon="info"/></span><span className={styles.addIcon} onClick={addMetaData}><AddMetaIcon /></span>
                    </div>

                    {metaData && metaData.length > 0 &&
                        <div className={styles.groupMeta}>
                            <div className={styles.field}>Key</div>
                            <div className={styles.field}>Value</div>
                        </div>
                    }

                    <div className={styles.groupMeta}>
                        {metaData && metaData.map((e,i)=>{
                            return(
                                <>
                                    <TextInput className={styles.field} label="" name="key" type="text" required value={(metaData[i].key != '' || metaData[i].key != undefined || metaData[i].key != null) ? metaData[i].key : ''} onChange={(e)=>{onChangeKey(e, i)}}/>
                                    <JSONMetaDataValueInput key={i} className={styles.field} data={(typeof e.value == 'string') ? JSON.stringify(e.value) : e.value} value={metaData} setValue={setMetaData} index={i}/>
                                    {/* <TextInput className={styles.field} label="" name="value" type="text" required  value={(metaData[i].value != '' || metaData[i].value != undefined || metaData[i].value != null) ? metaData[i].value : ''} onChange={(e)=>{onChangeValue(e, i)}}/> */}
                                    <span className={styles.remove} onClick={()=>{removeMetaData(i)}}><RemoveIcon className={styles.icon} /></span>
                                </>
                            )
                        })}
                    </div>
                </div>
                </div>
            </Card>

            <Card className={cn(styles.card, className)}>
                <div className={styles.cardAction}>
                    <div className={styles.cardSave}>
                        <button className={cn(styles.button, 'button-save-small')} onClick={submit}>Save</button>
                    </div>
                    <div className={styles.cardCancel}>
                        <button className={cn(styles.button, 'button-cancel-small')} onClick={cancel}>Cancel</button>
                    </div>
                </div>
            </Card>




        </>
    )
}

export default StoreInformation;
