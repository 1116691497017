import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./schedule.module.sass";
import {ReactComponent as Result} from '../../../../../../media/images/icons/Result.svg'
import {ReactComponent as Play} from '../../../../../../media/images/icons/Play.svg'
import {ReactComponent as Pause} from '../../../../../../media/images/icons/Pause.svg'
import {ReactComponent as Stop} from '../../../../../../media/images/icons/Stop.svg'
import {ReactComponent as Calendar} from '../../../../../../media/images/icons/Calendar.svg'
import {ReactComponent as OutlineCalendar} from '../../../../../../media/images/icons/OutlineCalendar.svg'
import Modal from "../../../../../Modals/ModalComponent";
import ScheduleModal from "../../../../ScheduleModal";
import moment from "moment";
import 'moment-timezone';
import StopScheduleModal from "../../../../StopScheduleModal";
import ResumenPauseScheduleModal from "../../../../Resume&PauseModal";
import DateTooltip from "../../../../../CustomTooltips/DateTooltip";
import { useAccess } from "../../../../../Page";

const Control = ({className, userTimeZone, isLast, timeZone, status, usedFor, offset, type, as, schedule, instanceId, scheduleId, id, interval, name, appid}) => {

    const [visibleModal, setVisibleModal] = useState(false);
    const [visibleStopModal, setVisibleStopModal] = useState(false);
    const [visiblePauseResumeModal, setVisiblePauseResumeModal] = useState(false)
    const { accessPermissions } = useAccess();
    const {allowInstantBattleSchedule,allowInstantBattleScheduleStop} = accessPermissions || {};

    const actions = [
        {
            name: 'Play',
            icon: <Play />,
            action : () => setVisiblePauseResumeModal(true),
        },
        {
            name: 'Pause',
            icon: <Pause />,
            action : null
        },
        {
            name: 'Stop',
            icon: <Stop />,
            action : null
        },
        {
            name: 'Calendar',
            icon: <Calendar />,
            action: () => setVisibleModal(true),
        },
    ];

  return (
    <>
        {usedFor === 'instance' &&
            <div className={cn(styles.control, className)}>
                {schedule && schedule?.length > 0 &&
                    <>
                        <div className={styles.status}>
                            <div className={schedule[0].status == 'yet to start' ? styles.purple : schedule[0].status == 'in progress' ? styles.green : schedule[0].status == 'completed' ? styles.grey : schedule[0].status == 'stopped' ? styles.orange : schedule[0].status == 'in review' ? styles.yellow : schedule[0].status == 'failed' ? styles.red : ''}>{schedule && schedule.length > 0 ? (schedule[0].status == 'yet to start' ? 'Yet-to-Start' : schedule[0].status == 'in progress' ? 'Live' : schedule[0].status == 'completed' ? 'Completed' : schedule[0].status == 'in review' ? 'In-Review' : schedule[0].status == 'stopped' ? 'Stopped' : schedule[0].status == 'failed' ? 'Failed' : '') : ''}</div>
                        </div>
                        <DateTooltip userTimeZone={userTimeZone} isLast={isLast} timeZone={timeZone} startDate={schedule[0].startDate != null ? schedule[0].startDate : null} endDate={schedule[0].endDate != null ? schedule[0].endDate : null}>
                            <div className={styles.leaderboardActions}>
                                {schedule[0].startDate != null ? <span className={styles.date}>{moment(schedule[0].startDate).tz(userTimeZone).format('MMM DD')}</span> : ''}  {schedule[0].endDate != null ? <> &#8208;&nbsp; <span className={styles.date}>{moment(schedule[0].endDate).tz(userTimeZone).format('MMM DD')}</span></> : <span className={styles.icon}><span>&#45;</span> &#8734;</span>}
                            </div>
                        </DateTooltip>
                    </>
                }
            </div>
        }
        {usedFor === 'status' &&
            <div className={cn(styles.control, className)}>
                {schedule && schedule?.length > 0 &&
                    <div className={styles.status}>
                        <div className={styles.green}>{status == 'inactive' ? 'Inactive' : status == 'active' ? 'Active' : status == 'paused' ? 'Paused' : status == 'stopped' ? 'Stopped' : ''}</div>
                    </div>
                }
                <div className={styles.leaderboardActions}>
                    {schedule && schedule?.length > 0 &&
                        <div className={styles.iconContainer}>
                            {schedule && schedule?.length > 0 && schedule[0].status === 'yet to start' ?
                                (actions && actions.map((x,i) => {
                                    if(
                                        (!allowInstantBattleSchedule && x?.name=='Calendar')||
                                        (!allowInstantBattleScheduleStop && x?.name=='Play')||
                                        (!allowInstantBattleScheduleStop && x?.name=='Pause')||
                                        (!allowInstantBattleScheduleStop && x?.name== "Stop")
                                    ){
                                        return ;
                                    }
                                    if(status === 'inactive'){
                                        return(
                                            <div className={cn(styles.icons, {[styles.disabled] : (x.name === 'Result' || x.name === 'Pause' || x.name === 'Play')})} onClick={x.action}>
                                                {x.icon}
                                            </div>
                                        )
                                    }
                                }))
                            : schedule && schedule?.length > 0 && schedule[0].status === 'in progress' ?
                                (actions && actions.slice(0, -1).map((x,i) => {
                                    if(
                                        (!allowInstantBattleSchedule && x?.name=='Calendar')||
                                        (!allowInstantBattleScheduleStop && x?.name=='Play')||
                                        (!allowInstantBattleScheduleStop && x?.name=='Pause')||
                                        (!allowInstantBattleScheduleStop && x?.name== "Stop")
                                    ){
                                        return ;
                                    }
                                    if(status !== 'inactive'){
                                        return(
                                            <div className={cn(styles.icons, {[styles.disabled] : ((x.name === 'Play' && status === 'active') || (x.name === 'Pause' && status === 'paused'))})} onClick={x.action}>
                                                {x.icon}
                                            </div>
                                        )
                                    }
                                }))
                            : schedule && schedule?.length > 0 && schedule[0].status === 'completed' ?
                                (actions && actions.slice(0, -1).map((x,i) => {
                                    if(
                                        (!allowInstantBattleSchedule && x?.name=='Calendar')||
                                        (!allowInstantBattleScheduleStop && x?.name=='Play')||
                                        (!allowInstantBattleScheduleStop && x?.name=='Pause')||
                                        (!allowInstantBattleScheduleStop && x?.name== "Stop")
                                    ){
                                        return ;
                                    }
                                    if(status !== 'inactive'){
                                        return(
                                            <div className={cn(styles.icons, {[styles.disabled] : ((x.name === 'Play' && status === 'active') || (x.name === 'Pause' && status === 'paused'))})} onClick={x.action}>
                                                {x.icon}
                                            </div>
                                        )
                                    }
                                }))
                            : ''}
                        </div>
                        }
                        {schedule?.length == 0 &&
                            <>
                                <div className={styles.iconContainer}>
                                    <div className={styles.icons} onClick={() => setVisibleModal(true)}>
                                        <OutlineCalendar />
                                    </div>
                                </div>
                            </>
                        }
                </div> 
            </div>
        }
        <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
            <ScheduleModal timeZone={timeZone} name={name} offset={offset} as={as} type={type} scheduleId={scheduleId} liveOps={schedule} id={id} onClose={() => setVisibleModal(false)}  />
        </Modal>
        <Modal visible={visibleStopModal} onClose={() => setVisibleStopModal(false)}>
            <StopScheduleModal name={name} instanceId={instanceId} as={as} type={type} scheduleId={scheduleId} liveOps={schedule} id={id} onClose={() => setVisibleStopModal(false)}  />
        </Modal>
        <Modal visible={visiblePauseResumeModal} onClose={() => setVisiblePauseResumeModal(false)}>
            <ResumenPauseScheduleModal name={name} instanceId={instanceId} as={as} type={type} scheduleId={scheduleId} liveOps={schedule} id={id} onClose={() => setVisiblePauseResumeModal(false)} status={status}/>
        </Modal>
    </>
  );
};

export default Control;
