import React, { useState } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../../../Checkbox";
import Icon from "../../../../Icon";
import Control from "./Control";
import { useParams } from "react-router-dom";
import Game from '../../../../../media/images/placeholders/Game.png'
import {ReactComponent as GoldMedal} from '../../../../../media/images/placeholders/Gold.svg'
import {ReactComponent as SilverMedal} from '../../../../../media/images/placeholders/Silver.svg'
import {ReactComponent as BronzeMedal} from '../../../../../media/images/placeholders/Bronze.svg'
import MemberPermissionDropdown from "../../MemberPermissionDropdown";
import UserProfile from '../../../../../media/images/icons/Player_Icon.svg'
import SnapShot from "../../../../Images";
/* Deleted Schedule, Modal, Modal Products and Actions */

const Row = ({type, item, value, onChange, to, access }) => {

    const {appid} = useParams()
    const [permissionOption, setPermissionOption] = useState([{id : 1, name : 'View Only'},{id : 2, name : 'Can Edit'}])
    const [permissionId, setPermissionId] = useState(appid && (item.permissions && item.permissions?.length > 0 && (item.permissions && item.permissions.find(item => item.projectId === appid))?.permissionId === 1) ? 1 : appid && (item.permissions && item.permissions.length > 0 && (item.permissions && item.permissions.find(item => item.projectId === appid))?.permissionId === 2) ? 2  : null)
    const [permission, setPermission] = useState(appid && (item.permissions && item.permissions?.length > 0 && (item.permissions && item.permissions.find(item => item.projectId === appid))?.permissionId === 1) ? 'View Only' : appid && (item.permissions && item.permissions.length > 0 && (item.permissions && item.permissions.find(item => item.projectId === appid))?.permissionId === 2) ? 'Can Edit' : '')

    return (
        <>
            {to == 'accepted' &&
                <div className={styles.row}>
                    <div className={styles.col}>
                        <div className={styles.player}>
                        {Object.keys(item).length !== 0 &&
                            <>
                                <div className={styles.memberIcon}>
                                    <SnapShot id={`memberIcon_accepted_${item.id}`} url={item?.memberDetails?.profile_url} placeholder={UserProfile} width={32} height={32} type="Images" />
                                    {/* <img src={(item.memberDetails != null && item.memberDetails.profile_url != null) ? item.memberDetails.profile_url : UserProfile} alt={item.name}/> */}
                                </div>
                                <div className={styles.playerDetails}>
                                    <div className={styles.userName}>{(item.name != null) ? item.name : '-'}</div>
                                    <div className={styles.playerId}>{(item.email != null) ? item.email : '-'}</div>
                                </div>
                            </>
                        }          
                        </div>
                    </div>
                    <div className={styles.col}>{item.active == true ? <div className={styles.price}>Active</div> : item.active == false ? <div className={styles.price}>InActive</div> : ''}</div>
                    <div className={styles.col}>
                        {Object.keys(item).length !== 0 &&
                            <MemberPermissionDropdown type='table' id={item.id} options={permissionOption} value={permission} setValue={setPermission} setPermission={setPermissionId} to={to} disabled={access}/>
                        }
                    </div>
                    <div className={styles.col}>
                        {!access && Object.keys(item).length !== 0 && <Control className={styles.control} id={(item && item.memberDetails != null && item.memberDetails != undefined) ? item.memberDetails.id : null}/>}
                    </div>
                </div>
            }
            {to == 'invited' &&
                <div className={styles.row}>
                    <div className={styles.col}>
                        <div className={styles.player}>
                        {Object.keys(item).length !== 0 &&
                            <>
                                <div className={styles.memberIcon}>
                                    <SnapShot id={`memberIcon_invited_${item.id}`} url={item?.memberDetails?.profile_url} placeholder={UserProfile} width={32} height={32} type="Images" />
                                </div>
                                <div className={styles.playerDetails}>
                                    <div className={styles.userName}>{item.name ? item.name : '-'}</div>
                                    <div className={styles.playerId}>{item.email ? item.email : '-'}</div>
                                </div>
                            </>
                        }
                        </div>
                    </div>
                    <div className={styles.col}>{item.active == true ? <div className={styles.price}>Active</div> : item.active == false ? <div className={styles.price}>InActive</div> : ''}</div>
                    <div className={styles.col}>
                        {Object.keys(item).length !== 0 &&
                            <MemberPermissionDropdown type='table' id={item.id} options={permissionOption} value={permission} setValue={setPermission} setPermission={setPermissionId} to={to} disabled={access}/>
                        }
                    </div>
                    <div className={styles.col}>
                        
                    </div>
                </div>
            }
        </>
    );
};

export default Row;
