import React, {useState, useEffect, useContext} from 'react'
import cn from 'classnames'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styles from './getAPI.module.sass'
import Card from '../../../../components/Card'
import Icon from '../../../../components/Icon'
import { ToastContainer, toast } from 'react-toastify';
import TextInput from '../../../../components/TextInput'
import ProjectPlaceHolder from '../../../../media/images/placeholders/App.png'
import { addAPIKeyAction, getAppDetailsAction, getallAppAction } from '../../../../redux/action/apps/appsAction'
import moment from 'moment'
import Toast from '../../../../components/Toast'
import axios from 'axios'
import { BASE_URL } from '../../../../resources/APIEndpoints'
import customAxios from '../../../../utils/interceptor'
import Modal from '../../../../components/Modals/ModalComponent'
import ConfirmModal from '../../../../components/AppSettingComponent/APIComponent/ConfirmModal'
import GlobalStateContext from '../../../../components/Context/GlobalStates/GlobalStateContext'
import Loader from '../../../../components/Loaders/LoaderProgress'
import { extarctImageFromUrlAction } from '../../../../redux/action/common/commonAction'

const Tabs = ["Accepted", "Invited"];

const GetAPI = ({className}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {appid} = useParams()

  const [show, setShow] = useState(false)

  const [appName, setAppName] = useState('')
  const [appIcon, setAppIcon] = useState(null)
  const [createdAt, setCreatedAt] = useState(null)
  const [apiKeys, setAPIKey] = useState(null)

  const getAppDetails = () => {
    let appDetails = {ids : [appid], organisationId: localStorage.getItem('orgId')}
    dispatch(getAppDetailsAction(appDetails))
  }

  let appDetail = useSelector((state) => {
    return state.apps.appDetails
  })

  let isLoading = useSelector((state) => {
    return state.apps.isLoading
  })

  const [userAccess, setUserAccess] = useState(false)
  const globalStates = useContext(GlobalStateContext)

  const getUserAccess = () => {
    if(globalStates && globalStates.user && globalStates.user.type === 'admin'){
      setUserAccess(false)
    }else if(globalStates && globalStates.user && globalStates.user.type === 'invited_member' && globalStates.user.memberAccessConfig?.length > 0){
        let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
        if(appPermission?.length > 0){
          setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
        }
    }else{

    }
  }

  const extractImageFromCdn = async(url, id) => {
    if(url !== null && url !== undefined && url.includes('cdn')){
      try {
        const response = await dispatch(extarctImageFromUrlAction(url))
        const logo = document.getElementById(`appIcon`)
        if(logo){
          logo.src = response
        }
      }catch (error){
        console.error('Error fetching image:', error);
      }
    }else{
      const logo = document.getElementById(`appIcon`)
      if(logo){
        logo.src = ProjectPlaceHolder
      }
    }
  }

  const configApp = async() => {
    if(appDetail && appDetail.length > 0){
      let profile = await extractImageFromCdn(appDetail[0].iconUrl)
      setAppName(appDetail[0].name)
      setAppIcon(profile)
      setCreatedAt(moment(appDetail[0].createdAt).format('DD-MM-YYYY HH:mm A'))
      setAPIKey(appDetail[0].apiKeys)
    }
  }

  useEffect(() => {
    getUserAccess()
    getAppDetails()
  }, [])

  useEffect(() => {
    configApp()
  }, [appDetail])

  const copyToClipboard = (value) => {
    const tempTextArea = document.createElement("textarea");
    tempTextArea.value = (value != null || value == '') ? value : '-';
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand("copy");
    document.body.removeChild(tempTextArea);
    toast.success(<Toast type='Success' messages='copied to clipboard'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
  }

  const [envId, setEnvId] = useState(null)

  const addKey = (env) => {
    setEnvId(env)
    setShow(true)
  }

  if(isLoading){
    return(
      <>
        <div className={styles.loader}>
          <Loader />
        </div>
      </>
    )
  }

  return(
    <>
      <div>
      <ToastContainer />
        <Card className={styles.card} classCardHead={styles.head}>
          <div className={styles.group}>
            <div className={styles.playerInformation}>
                <div className={styles.preview}>
                    <div className={styles.primaryInfo}>
                        <div className={styles.profile}>
                            <img id='appIcon' alt={`Players_${'1'}`} />
                        </div>
                        <div className={styles.otherInfo}>
                            <div className={styles.playerName}>
                                <span className={styles.userName}>{appName}</span>
                            </div>
                            <div className={styles.secondaryInfo}>
                                <div className={styles.playerId}>
                                    <div className={styles.ids}>
                                        <span className={styles.label}>App ID:&nbsp;</span><span className={styles.UIDNO}>{appid}<span className={styles.copyToClipboard} onClick={()=>{copyToClipboard(appid)}}><Icon name='copy' size='16' /></span></span>
                                    </div>
                                    <div className={styles.ids}>
                                        <span className={styles.label}>No of Active Users:&nbsp;</span><span className={styles.UUIDNO}>10</span>
                                    </div>
                                </div>
                                <div className={styles.playerLocation}>
                                    <div className={styles.ids}>
                                        <span className={styles.label}>Location:&nbsp;</span><span className={styles.UUIDNO}>Mumbai, India</span>
                                    </div>
                                    <div className={styles.ids}>
                                        <span className={styles.label}>Created At:&nbsp;</span><span className={styles.UUIDNO}>{createdAt}</span>
                                    </div>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </Card>
        <Card className={styles.card} classCardHead={styles.head} title="Production" classTitle={cn("title-green", styles.title)} head={
            <>
            {!userAccess &&
              <div className={styles.add} onClick={() => {addKey(3)}}>
                <div className={styles.addApiKey}>
                  <Icon name='plus-circle' size='16' />Generate API Key
                </div>
              </div>
            }
            </>
          }>
          <div className={styles.productionInfo}>
              <div className={styles.url}>
                <div className={styles.inputIcon} onClick={()=>{copyToClipboard('https://client.staging.specterapp.xyz/')}}><Icon name='copy' width='16'/></div>
                <TextInput className={styles.field} disabled value='https://client.staging.specterapp.xyz/' style='grey' type='text' currency='url' />
              </div>

              {apiKeys && apiKeys.clientProductionAPIKey && apiKeys.clientProductionAPIKey.map((e, i) => {
                return(
                  <div className={styles.token}>
                    <div className={styles.inputIcon} onClick={()=>{copyToClipboard(e)}}><Icon name='copy' width='16'/></div>
                    <TextInput className={styles.field} value={e} disabled style='grey' type='text' currency={apiKeys && apiKeys.clientProductionAPIKey && apiKeys.clientProductionAPIKey.length > 1 ? `API Key ${i+1}` : `API Key`} />
                  </div>
                )
              })}

          </div>
        </Card>  

        <Card className={styles.card} classCardHead={styles.head} title="Quality Assurance" classTitle={cn("title-purple", styles.title)} head={
            <>
            {!userAccess &&
              <div className={styles.add} onClick={() => {addKey(2)}}>
                <div className={styles.addApiKey}>
                  <Icon name='plus-circle' size='16' />Generate API Key
                </div>
              </div>
            }
            </>
          }>
          <div className={styles.staggingInfo}>
              <div className={styles.url}>
                  <div className={styles.inputIcon} onClick={()=>{copyToClipboard('https://client.staging.specterapp.xyz/')}}><Icon name='copy' width='16'/></div>
                  <TextInput className={styles.field} disabled value='https://client.staging.specterapp.xyz/' style='grey' type='text' currency='url' />
              </div>
              {apiKeys && apiKeys.clientStagingAPIKey && apiKeys.clientStagingAPIKey.map((e, i) => {
                return(
                  <div className={styles.token}>
                    <div className={styles.inputIcon} onClick={()=>{copyToClipboard(e)}}><Icon name='copy' width='16'/></div>
                    <TextInput className={styles.field} value={e} disabled style='grey' type='text' currency={apiKeys && apiKeys.clientStagingAPIKey && apiKeys.clientStagingAPIKey.length > 1 ? `API Key ${i+1}` : `API Key`} />
                  </div>
                )
              })}
          </div>
        </Card> 

        <Card className={styles.card} classCardHead={styles.head} title="Development" classTitle={cn("title-yellow", styles.title)} head={
            <>
            {!userAccess &&
              <div className={styles.add} onClick={() => {addKey(1)}}>
                <div className={styles.addApiKey}>
                  <Icon name='plus-circle' size='16' />Generate API Key
                </div>
              </div>
            }
            </>
          }>
          <div className={styles.staggingInfo}>
              <div className={styles.url}>
                  <div className={styles.inputIcon} onClick={()=>{copyToClipboard('https://client.staging.specterapp.xyz/')}}><Icon name='copy' width='16'/></div>
                  <TextInput className={styles.field} disabled value='https://client.staging.specterapp.xyz/' style='grey' type='text' currency='url' />
              </div>
              {apiKeys && apiKeys.clientDevAPIKey && apiKeys.clientDevAPIKey.map((e, i) => {
                return(
                  <div className={styles.token}>
                    <div className={styles.inputIcon} onClick={()=>{copyToClipboard(e)}}><Icon name='copy' width='20'/></div>
                    <TextInput className={styles.field} value={e} disabled style='grey' type='text' currency={apiKeys && apiKeys.clientDevAPIKey && apiKeys.clientDevAPIKey.length > 1 ? `API Key ${i+1}` : `API Key`} />
                  </div>
                )
              })}

          </div>
        </Card> 
        <Modal visible={show} onClose={() => setShow(false)} >
          <ConfirmModal appid={appid} envId={envId} visible={show} onClose={() => setShow(false)} />
        </Modal>
      </div>
    </>
  )

}

export default GetAPI