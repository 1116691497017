import React , { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import cn from 'classnames'
import styles from './items.module.sass'
import Card from '../../../../../components/Card'
import Form from '../../../../../components/Form'
import Icon from '../../../../../components/Icon'
import Table from '../../../../../components/BuildComponents/EconomyComponent/ItemsComponent/ItemsTableLayout'
import ItemCardLayout from '../../../../../components/BuildComponents/EconomyComponent/ItemsComponent/ItemsCardLayout'
import addIcon from '../../../../../media/images/icons/light.png'
import { addItemAction, editItemAction, filterItemAction, getAllItemAction } from '../../../../../redux/action/builds/economy/items/itemAction'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as EmptyCurrencyState } from "../../../../../media/images/icons/Currency_Empty_State.svg";
import Loader from "../../../../../components/Loaders/LoaderProgress";
import EmptyState from '../../../../../components/EmptyStates'
import {ReactComponent as Previous} from '../../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../../media/images/icons/next.svg'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import {ReactComponent as ArchiveSvg} from '../../../../../media/images/icons/archive.svg'
import {ReactComponent as Check} from '../../../../../media/images/icons/Check_Svg.svg'
import { Skeleton } from '@mui/material'
import SkeletonTable from '../../../../../components/Skeleton/TableSkeleton'
import SkeletonEmptyState from '../../../../../components/Skeleton/EmptyStateSkeleton'
import GlobalStateContext from '../../../../../components/Context/GlobalStates/GlobalStateContext'
import ControlFileUpload from './Control'
import Control from './Filters/Control'
import XLSX from 'xlsx';
import { BASE_URL, GET_ITEMS } from '../../../../../resources/APIEndpoints'
import axios from 'axios'
import { getAllCurrencyAction } from '../../../../../redux/action/builds/economy/currency/currencyAction'
import { ToastContainer } from 'react-toastify'
import customAxios from '../../../../../utils/interceptor'
import ExportTooltip from '../../../../../components/CustomTooltips/ExportTooltip'
import { getAllProgressionSystemAction } from '../../../../../redux/action/builds/progression/progressionSystem/progressionSystemAction'
import { getAllBundlesAction } from '../../../../../redux/action/builds/economy/bundles/bundleAction'
import { endBulkUploadCall, startBulkUploadItemsNBundles } from '../../../../../sagas/actions/itemsNbundles/bulkFileUploadAction'
import Navigation from '../../../../../components/Header/SecondaryNavigation'

const sorting = ["list", "grid"];

const GetItems = () => {

  const dispatch = useDispatch()
  const {appid} = useParams()

  // Pagination
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState()
  // Archive
  const [showArchive, setShowArchive] = useState(false)
  // Filters
  const [type, setType] = useState([])
  const [subType, setSubType] = useState([])
  const [isLimited, setIsLimited] = useState(null)
  const filters = {type, setType, subType, setSubType, isLimited, setIsLimited}
  // Search
  const [search, setSearch] = useState(null);
  // Skeleton
  const [showSkeleton, setShowSkeleton] = useState(true)
  // Sort
  const sortList = [{id: 1, name: 'Item Name', value: 'name'}, {id: 2, name: 'Created At', value: 'created_at'}, {id: 3, name: 'Updated At', value: 'updated_at'}]
  const [sortValue, setSortValue] = useState({name: 'updated_at', value: 0})
  const [selectedSort, setSelectedSort] = useState('updated_at')
  const [sort, setSort] = useState([{id: 1, name: 'Ascending'}, {id : 2, name : 'Descending'}])
  const [sortIndex, setSortIndex] = useState(0)
  // User Access
  const [userAccess, setUserAccess] = useState(false)
  const globalStates = useContext(GlobalStateContext)

  const getUserAccess = () => {
    if(globalStates && globalStates.user && globalStates.user.memberAccessConfig?.length > 0){
      let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
      if(appPermission?.length > 0){
        setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
      }
    }
  }
  
  useEffect(() => {
    getUserAccess()
  }, [])

  let getAllItems = async(search) => {
    let allItem = {
      projectId : appid,
      offset: ((page-1)*limit),
      limit: limit,
      // Search
      ...(search && search !== null && search !== '' && {search : search}),
      // Archive
      showArchived: showArchive,
      //filter
      types: type,
      subTypes: subType,
      ...(isLimited != null && {isLimited: isLimited}),
      //Sort
      sortOrder : (sortIndex == 0 ? 'asc' : 'desc'),
      sortField : selectedSort,
    }
    let keysToCheck = ['type', 'subType']
    let isFiltered = await keysToCheck.every(key => filters[key]?.length === 0)
    if(!search && isFiltered){
      await dispatch(getAllItemAction(allItem, configureSkeleton))
    }else{
      await dispatch(filterItemAction(allItem, configureSkeleton, !isFiltered))
    }
  }

  let allItems = useSelector((state)=>{
    return state.item.items
  })

  let isLoading = useSelector((state)=>{
    return state.item.isLoading
  })

  let isFiltered = useSelector((state)=>{
    return state.item.isFiltered
  })

  let totalItems = useSelector((state)=>{
    return state.item.totalCount
  })

  const configureSkeleton = () => {
    setShowSkeleton(true)
    setTimeout(() => {
      setShowSkeleton(false)
    }, 1000)
  }

  useEffect(()=>{
    if(selectedSort !== null){
      getAllItems(search)
    }
  }, [page, selectedSort, showArchive])
  
  const handlePageChange = (event, value)  => {
    setPage(value)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const onSearch = (search) => {
    if(search == ''){
      setSearch(null)
      getAllItems(null)
    }else{
      setSearch(search)
      getAllItems(search)
    }
  }

  // Import
  
  const [itemData, setItemData] = useState([])
  const [itemFile, setItemFile] = useState(null)
  const [pricingFile, setPricingFile] = useState(null)
  const [lockByFile, setLockByFile] = useState(null)

  // File Upload 

  const onCSVItem = (e) => {
    setItemFile(e)
  }

  const onCSVPricing = (e) => {
    setPricingFile(e)
  }

  const onCSVFile = (e) => {
    setLockByFile(e)
  }

  // API Requests

  const [currencyList, setCurrencyList] = useState([])
  const [realWorldCurrency, setRealWorldCurrency] = useState([])
  const getRealWorldCurrency = async() => {
    let currencies = {
      projectId : appid,
      offset: 0,
      limit: 50
    }
    dispatch(getAllCurrencyAction(currencies))
    const token = localStorage.getItem('token')
    const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
    let res = await axios.post(`${BASE_URL}/currencies/get-world-currency`, {}, headers)
    res.data.data && res.data.data.forEach((element) => {
      setRealWorldCurrency(prevState => [...prevState, {realWorldCurrencyId : element.id, name : element.code.concat(" (", `${element.symbol}`, ")"), type : 'IAP'}])
    })
  }

  let currency = useSelector((state) => {
    return state.currencies.currency
  })

  const configureCurrency = () => {
    if(currency && currency?.length > 0){
      setCurrencyList(currency)
    }
  }

  useEffect(() => {
    configureCurrency()
  }, [currency])

  useEffect(() => {
    getRealWorldCurrency()
    getAllProgressionSystem()
    getAllBundles()
  }, [])

  // API Call For Progression System
  const [PSList, setPSList] = useState([])
  const getAllProgressionSystem = () => {
    let PS = {
      projectId : appid,
      offset : 0,
      limit : 1000
    }
    dispatch(getAllProgressionSystemAction(PS))
  }

  let PS = useSelector((state) => {
    return state.progressionSystem.progressionSystem
  })

  const configurePS = () => {
    if(PS && PS?.length > 0){
      setPSList(PS)
    }
  }

  useEffect(() => {
    configurePS()
  }, [PS])

  // API Call For Bundle

  const [BundleList, setBundleList] = useState([])
  const getAllBundles = () => {
    let bundles = {
      projectId : appid,
      offset : 0,
      limit : 1000
    }
    dispatch(getAllBundlesAction(bundles))
  }

  let Bundles = useSelector((state) => {
    return state.bundles.bundles
  })

  const configureBundle = () => {
    if(Bundles && Bundles?.length > 0){
      setBundleList(Bundles)
    }
  }

  useEffect(() => {
    configureBundle()
  }, [Bundles])

  // Excel to JSON Data

  // 1. Item File

  const [itemFileErrors, setItemFileErrors] = useState(null)
  const [pricingFileErrors, setPricingFileErrors] = useState(null)
  const [lockByFileError, setLockByFileError] = useState(null)

  const checkErrors = (header) => {
    let newErrors = []
    let isError = true;
    if (header[0] !== 'Item Icon') {
      newErrors.push(`Please change 1st Column name from ${header[0]} to Item Icon.`)
      isError = false
    }
    if (header[1] !== 'Display Name') {
      newErrors.push(`Please change 2nd Column name from ${header[1]} to Display Name.`)
      isError = false
    }
    if (header[2] !== 'Item ID') {
      newErrors.push(`Please change 3rd Column name from ${header[2]} to Item ID.`)
      isError = false
    }
    if (header[3] !== 'Item Description') {
      newErrors.push(`Please change 4th Column name from ${header[3]} to Item Description.`)
      isError = false
    }
    if (header[4] !== 'Item Type') {
      newErrors.push(`Please change 5th Column name from ${header[4]} to Item Type.`)
      isError = false
    }
    if (header[5] !== 'Count/Time Usage') {
      newErrors.push(`Please change 6th Column name from ${header[5]} to Count/Time Usage.`)
      isError = false
    }
    if (header[6] !== 'Uses (if Count)') {
      newErrors.push(`Please change 7th Column name from ${header[6]} to Uses (if Count).`)
      isError = false
    }
    if (header[7] !== 'Time Value (if Time)') {
      newErrors.push(`Please change 8th Column name from ${header[7]} to Time Value (if Time).`)
      isError = false
    }
    if (header[8] !== 'Time Unit (if Time)') {
      newErrors.push(`Please change 9th Column name from ${header[8]} to Time Unit (if Time).`)
      isError = false
    }
    if (header[9] !== 'Item Subtypes') {
      newErrors.push(`Please change 10th Column name from ${header[9]} to Item Subtypes.`)
      isError = false
    }
    if (header[10] !== 'Max Stack Count') {
      newErrors.push(`Please change 11th Column name from ${header[10]} to Max Stack Count.`)
      isError = false
    }
    if (header[11] !== 'Max Collection Instances') {
      newErrors.push(`Please change 12th Column name from ${header[11]} to Max Collection Instances.`)
      isError = false
    }
    if (header[12] !== 'Limited Availability') {
      newErrors.push(`Please change 13th Column name from ${header[12]} to Limited Availability.`)
      isError = false
    }
    if (header[13] !== 'Limited Quantity') {
      newErrors.push(`Please change 14th Column name from ${header[13]} to Limited Quantity.`)
      isError = false
    }
    if (header[14] !== 'Default Load Out') {
      newErrors.push(`Please change 15th Column name from ${header[14]} to Default Load Out.`)
      isError = false
    }
    if (header[16] !== 'Tags') {
      newErrors.push(`Please change 17th Column name from ${header[16]} to Tags.`)
      isError = false
    }
    if (header[17] !== 'Meta Data') {
      newErrors.push(`Please change 18th Column name from ${header[17]} to Meta Data.`)
      isError = false
    }
    setItemFileErrors(newErrors);
    if(isError){
      return true
    }else{
      return false
    }
  }

    const validateItemExistance = async(rewards, data) => {
      const token = localStorage.getItem('token')
      const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
      let res = await axios.post(`${BASE_URL}/rewards-details/validate`, rewards, headers)
      if(res.data.data){
        let content = res.data.data
        let existingData = content.itemDetails.existingItems
        if(existingData?.length > 0){
          const updatedData = data && data.map((e) => {
            // let matchObj = existingData && existingData.find(x => x === e.itemId)
            let matchObj = existingData && existingData.find(item => item.itemId === e.itemId)
            if(matchObj) {
              return {
                ...e,
                errors: {
                  ...e.errors,
                  idErr: [
                    ...(e.errors && e.errors.idErr ? e.errors.idErr : []), ...(matchObj.archive ? [`Item Id with ${e.itemId} archived and already exist.`] : [`Item Id with ${e.itemId} already exists.`])
                  ]
                }
              };
            }else {
              return {
                ...e,
                errors: {
                  ...e.errors,
                  idErr: e.errors && e.errors.idErr ? [...e.errors.idErr] : [] 
                }
              };
            }
          })
          return updatedData
        }else{
          return data
        }
      }
    }

    const validateCurrency = async(rewards, data) => {
      const token = localStorage.getItem('token')
      const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
      let res = await axios.post(`${BASE_URL}/rewards-details/validate`, rewards, headers)
      if(res.data.data){
        let content = res.data.data
        let existingData = content.currenciesDetails.existingCurrencies
        let errorRewards = content.currenciesDetails.nonExistingCurrencies
        if(errorRewards?.length > 0){
          let updatedData = data && data.flatMap(e => ({
            ...e,
            itemPrices: e.itemPrices && e.itemPrices.map((x, i) => {
              let matchObj = errorRewards && errorRewards.find(currencyId => currencyId === x.currencyClientID)
              if(matchObj){
                return{
                  ...x,
                  errors: {
                    ...x.errors, 
                    currencyIdErr: [
                      ...(x.errors && x.errors.currencyIdErr ? x.errors.currencyIdErr : []), `Currency Id with ${x.currencyClientID} does not exist in your project.`
                    ]
                  }
                }
              }else{
                let matchObj = existingData && existingData.find(currency => currency.currencyId === x.currencyClientID)
                return{
                  ...x,
                  errors: {
                    ...x.errors,
                    currencyIdErr: [
                      ...(x.errors && x.errors.currencyIdErr ? [...x.errors.currencyIdErr] : []),
                      ...(matchObj && matchObj.archive ? [`Currency Id with ${x.currencyClientID} archived and already exist.`] : [])
                    ]
                  }
                }
              }
            })
          }))
          return updatedData
        }else{
          let updatedData = data && data.flatMap(e => ({
            ...e,
            itemPrices: e.itemPrices && e.itemPrices.map((x, i) => {
              let matchObj = existingData && existingData.find(currency => currency.currencyId === x.currencyClientID)
              if(matchObj){
                return{
                  ...x,
                  errors: {
                    ...x.errors, 
                    currencyIdErr: [
                      ...(x.errors && x.errors.currencyIdErr ? [...x.errors.currencyIdErr] : []),
                      ...(matchObj && matchObj.archive ? [`Currency Id with ${x.currencyClientID} archived and already exist.`] : [])
                    ]
                  }
                }
              }else{
                return{
                  ...x
                }
              }
            })
          }))
          return updatedData
        }
      }
    }

  const checkRewards = async(data, type) => {
    const rewards = {
      projectId : appid,
      rewardDetails : {
        items : {ids:[]},
        currencies : {ids:[]},
        bundles : {ids:[]},
        progressionMarkers : {ids:[]},
        progressionSystem: { ids: [] },
        stores : {ids : []}
      }
    }

    if(type === 'Items'){
      data && data.forEach((e, index) => {
        if(e.itemId !== null && !rewards.rewardDetails.items.ids.includes(e.itemId)){
          rewards.rewardDetails.items.ids.push((e.itemId).toString());
        }
      })
      let validateItemData = await validateItemExistance(rewards, data)
      return validateItemData
    }else if(type === 'Pricing'){
      data && data.map((e, index) => {
        e.itemPrices && e.itemPrices.forEach((x, i) => {
          if(x.currencyClientID !== null && !rewards.rewardDetails.currencies.ids.includes(x.currencyClientID)){
              rewards.rewardDetails.currencies.ids.push(x.currencyClientID);
            }
          })
      })
      let validateItemData = await validateCurrency(rewards, data)
      return validateItemData
    }
  }

  const checkItemFileDataType = (obj) => {
      let errors = {iconErr: [], nameErr: [], idErr: [], descriptionErr: [], typeErr: [], byErr: [], countErr: [], timeErr: [], unitErr: [], subTypeErr: [], isLimitedErr: [], quantityErr: [], maxInstanceErr:[], stackErr:[], defaultLoadOutErr: [], equippedByDefaultErr: []}
      
      if(obj.iconUrl !== null && typeof obj.iconUrl !== 'string'){
        errors.iconErr.push(`Item Icon should be a valid string.`);
      }
      if(typeof obj.name !== 'string'){
        errors.nameErr.push(`Display Name should be a valid string.`);
      }
      if(typeof obj.itemId !== 'string'){
        errors.idErr.push(`Item ID should be a valid string.`);
      }
      if(obj.description !== null &&  typeof obj.description !== 'string'){
        errors.descriptionErr.push(`Item Description should be a valid string.`);
      }
      if(obj.itemType !== null && typeof obj.itemType !== 'boolean'){
        errors.typeErr.push(`Item Type should be a valid string and Either Durable or Consumable`);
      }
      if(!obj.itemType){
        if(obj.consumeByUses && obj.consumeByUses !== null){
          errors.countErr.push(`Item Type Should be Consumable.`);
        }
        if(obj.consumeByTime && obj.consumeByTime !== null){
          errors.timeErr.push(`Item Type Should be Consumable.`);
        }
        if(obj.consumeByTimeFormat && obj.consumeByTimeFormat !== null){
          errors.unitErr.push(`Item Type Should be Consumable.`);
        }
      }
      if(obj.consumeByUses && obj.consumeByUses !== null && typeof obj.consumeByUses !== 'number'){
        errors.countErr.push(`Uses (if Count) should be a valid integer.`);
      }
      if(obj.consumeByTime && obj.consumeByTime !== null && typeof obj.consumeByTime !== 'number'){
        errors.timeErr.push(`Time Value (if Time) should be a valid integer.`);
      }
      if(obj.consumeByTimeFormat && obj.consumeByTimeFormat !== null && typeof obj.consumeByTimeFormat !== 'string'){
        errors.unitErr.push(`Time Unit (if Time) should be a valid string.`);
      }
      if(obj.stackCapacity !== null && typeof obj.stackCapacity !== 'number'){
        errors.stackErr.push(`Stack Capacity should be a valid integer.`);
      }
      if(obj.maxCollectionInstance !== null && typeof obj.maxCollectionInstance !== 'number'){
        errors.maxInstanceErr.push(`Max Collection Instance should be a valid integer.`);
      }
      if(obj.quantity !== null && typeof obj.quantity !== 'number'){
        errors.quantityErr.push(`Limited Quantity should be a valid integer.`);
      }
      if(typeof obj.isDefaultLoadout !== 'string' && typeof obj.isDefaultLoadout !== 'boolean'){
        errors.defaultLoadOutErr.push(`Default Load Out should be a valid string.`);
      }
      if(typeof obj.equippedByDefault !== 'string' && typeof obj.equippedByDefault !== 'boolean'){
        errors.equippedByDefaultErr.push(`Equip By Default should be a valid string.`);
      }
      return errors
  }

  const checkDataType = (obj) => {
    return obj && obj.map((e, i) => ({
      ...e,
      errors: checkItemFileDataType(e)
    }))
  }

  const transformItemData = (items) => {
    return items && items.map((entry, index) => ({
      projectId : appid,
      iconUrl : ((entry["Item Icon"] !== null && entry["Item Icon"] !== '' && entry["Item Icon"] !== 'N/A') ? entry["Item Icon"] : null),
      name : ((entry["Display Name"] !== null && entry["Display Name"] !== '' && entry["Display Name"] !== 'N/A') ? entry["Display Name"] : null),
      itemId : ((entry["Item ID"] !== null && entry["Item ID"] !== '' && entry["Item ID"] !== 'N/A') ? entry["Item ID"] : null),
      description : ((entry["Item Description"] !== null && entry["Item Description"] !== '' && entry["Item Description"] !== 'N/A') ? entry["Item Description"] : null),
      isEquippable : (entry["Item Subtypes"] != null && entry["Item Subtypes"] != '' && entry["Item Subtypes"] !== 'N/A' && entry["Item Subtypes"].includes("Equippable") ? true : false),
      isTradable : (entry["Item Subtypes"] != null && entry["Item Subtypes"] != '' && entry["Item Subtypes"] !== 'N/A' && entry["Item Subtypes"].includes("Tradable") ? true : false),
      isStackable : (entry["Item Subtypes"] != null && entry["Item Subtypes"] != '' && entry["Item Subtypes"] !== 'N/A' && entry["Item Subtypes"].includes("Stackable") ? true : false),
      isRentable : (entry["Item Subtypes"] != null && entry["Item Subtypes"] != '' && entry["Item Subtypes"] !== 'N/A' && entry["Item Subtypes"].includes("Rentable") ? true : false),
      isTimeStackable : (entry["Item Subtypes"] != null && entry["Item Subtypes"] != '' && entry["Item Subtypes"] !== 'N/A' && entry["Item Subtypes"].includes("TimeStackable") ? true : false),
      itemType : ((entry["Item Type"] !== null && entry["Item Type"] !== '' && entry["Item Type"] !== 'N/A' && entry["Item Type"] === 'Durable') ? false : (entry["Item Type"] !== null && entry["Item Type"] !== '' && entry["Item Type"] !== 'N/A' && entry["Item Type"] === 'Consumable') ? true : (entry["Item Type"] === null || entry["Item Type"] === '' || entry["Item Type"] === 'N/A') ? false : entry["Item Type"]),
      isConsumable : ((entry["Item Type"] !== null && entry["Item Type"] !== '' && entry["Item Type"] !== 'N/A' && entry["Item Type"] === 'Durable') ? false : (entry["Item Type"] !== null && entry["Item Type"] !== '' && entry["Item Type"] !== 'N/A' && entry["Item Type"] === 'Consumable') ? true : (entry["Item Type"] === null || entry["Item Type"] === '' || entry["Item Type"] === 'N/A') ? false : entry["Item Type"]),
      ...((entry["Uses (if Count)"] !== null && entry["Uses (if Count)"] !== '' && entry["Uses (if Count)"] !== 'N/A') && {consumeByUses: parseValidInt(entry["Uses (if Count)"])}),
      ...((entry["Time Value (if Time)"] !== null && entry["Time Value (if Time)"] !== '' && entry["Time Value (if Time)"] !== 'N/A') && {consumeByTime: ((entry["Time Value (if Time)"] !== null && entry["Time Value (if Time)"] !== '' && entry["Time Value (if Time)"] !== 'N/A' && entry["Time Unit (if Time)"] === 'seconds') ? parseValidInt(entry["Time Value (if Time)"]) : (entry["Time Value (if Time)"] !== null && entry["Time Value (if Time)"] !== '' && entry["Time Value (if Time)"] !== 'N/A' && entry["Time Unit (if Time)"] === 'minutes') ? parseValidInt(entry["Time Value (if Time)"]*60) : (entry["Time Value (if Time)"] !== null && entry["Time Value (if Time)"] !== '' && entry["Time Value (if Time)"] !== 'N/A' && entry["Time Unit (if Time)"] === 'hours') ? parseValidInt(entry["Time Value (if Time)"]*60*60) : (entry["Time Value (if Time)"] !== null && entry["Time Value (if Time)"] !== '' && entry["Time Value (if Time)"] !== 'N/A' && entry["Time Unit (if Time)"] === 'days') ? parseValidInt(entry["Time Value (if Time)"]*24*60*60) : (entry["Time Value (if Time)"] !== null && entry["Time Value (if Time)"] !== '' && entry["Time Value (if Time)"] !== 'N/A' && entry["Time Unit (if Time)"] === 'months') ? parseValidInt(entry["Time Value (if Time)"]*30.42*24*60*60) : (entry["Time Value (if Time)"] !== null && entry["Time Value (if Time)"] !== '' && entry["Time Value (if Time)"] !== 'N/A' && entry["Time Unit (if Time)"] === 'years') ? parseValidInt(entry["Time Value (if Time)"]*365*24*60*60) : parseValidInt(entry["Time Value (if Time)"]))}),
      ...(entry["Time Unit (if Time)"] !== null && entry["Time Unit (if Time)"] !== '' && entry["Time Unit (if Time)"] !== 'N/A' && {consumeByTimeFormat: entry["Time Unit (if Time)"]}),
      quantity : ((entry["Limited Quantity"] !== null && entry["Limited Quantity"] !== '' && entry["Limited Quantity"] !== 'N/A') ? parseValidInt(entry["Limited Quantity"]) : null),
      isDefaultLoadout: ((entry["Default Load Out"] !== null && entry["Default Load Out"] !== '' && entry["Default Load Out"] !== 'N/A' && entry["Default Load Out"] === 'Yes') ? true : (entry["Default Load Out"] !== null && entry["Default Load Out"] !== '' && entry["Default Load Out"] !== 'N/A' && entry["Default Load Out"] === 'No') ? false  : entry["Default Load Out"]),
      equippedByDefault: ((entry["Equip By Default"] !== null && entry["Equip By Default"] !== '' && entry["Equip By Default"] !== 'N/A' && entry["Equip By Default"] === 'Yes') ? true : (entry["Equip By Default"] !== null && entry["Equip By Default"] !== '' && entry["Equip By Default"] !== 'N/A' && entry["Equip By Default"] === 'No') ? false  : entry["Equip By Default"]),
      stackCapacity : ((entry["Max Stack Count"] !== null && entry["Max Stack Count"] !== '' && entry["Max Stack Count"] !== 'N/A') ? parseValidInt(entry["Max Stack Count"]) : null),
      maxCollectionInstance: ((entry["Max Collection Instances"] !== null && entry["Max Collection Instances"] !== '' && entry["Max Collection Instances"] !== 'N/A') ? parseValidInt(entry["Max Collection Instances"]) : null),
      itemPrices: [],
      rewardUnlockCondition : [],
      typeId : ((entry["Item Type"] !== null && entry["Item Type"] !== '' && entry["Item Type"] !== 'N/A' && entry["Item Type"] === 'Durable') ? "1" : (entry["Item Type"] !== null && entry["Item Type"] !== '' && entry["Item Type"] !== 'N/A' && entry["Item Type"] === 'Consumable') ? "2" : (entry["Item Type"] === null || entry["Item Type"] === '' || entry["Item Type"] === 'N/A') ? 1 : entry["Item Type"]),
      meta : ((entry["Meta Data"] && entry["Meta Data"] !== null && entry["Meta Data"] !== '' && entry["Meta Data"] !== 'N/A') ? JSON.parse(entry["Meta Data"]) : null),
      tags : ((entry["Tags"] && entry["Tags"] !== null && entry["Tags"] !== 'N/A'  && entry["Tags"] !== '') ? entry["Tags"].split(", ") : null),
      active : true,
      archive : false
    }));
  }


  const convertItemToJson = async(data, headers) => {
    if(data.length > 1000){
      setItemFileErrors([`Total number of rows should be less than 1000.`])
    }else{
      const dataRows = data && data.map(row => {
        // Create an object with the headers and corresponding row values
        const rowData = {};
        headers.forEach((header, index) => {
          rowData[header] = row[header] !== undefined ? row[header] : null; // Handle blank cells
        });
        return rowData;
      })
      return transformItemData(dataRows)
    }
  }

  const validateItemCSV = () => {
    return new Promise((resolve, reject) => {
      if(itemFile && (itemFile.size/1024).toFixed(2) < 10000){
        const fileExtension = itemFile.name.split('.').pop().toLowerCase();
        const reader = new FileReader();
        reader.onload = async(e) => {
          if(fileExtension === 'csv'){
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'string', raw: true });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const columnsArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {header: 1, defval: ''});
            const headers = columnsArray[0];
            if(checkErrors(headers)){
              if(pricingFile === null){
                resolve(true);
              }
              const json = XLSX.utils.sheet_to_json(worksheet);
              let itemJSON = await convertItemToJson(json, headers)
              let itemsInfo = await checkDataType(itemJSON)
              let checkItems = await checkRewards(itemsInfo, "Items")
              if(checkItems){
                setItemData(checkItems)
              }
              if(checkItems && pricingFile){
                let resultPricing = await validatePricingCSV(checkItems);
                if(!lockByFile){
                  resolve(resultPricing.status)
                }else{
                  let resultLockBy = await validateLockByCSV(resultPricing.data)
                  resolve(resultPricing.status && resultLockBy)
                }
              }else{
                let resultLockBy = await validateLockByCSV(checkItems)
                resolve(resultLockBy)
              }
            }else{
              resolve(false)
            }
          }else{
            const arrayBuffer = e.target.result;
            const data = new Uint8Array(arrayBuffer);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const columnsArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {header: 1, defval: ''});
            const headers = columnsArray[0];
            if(checkErrors(headers)){
              if(pricingFile === null){
                resolve(true);
              }
              const json = XLSX.utils.sheet_to_json(worksheet);
              let itemJSON = await convertItemToJson(json, headers)
              let itemsInfo = await checkDataType(itemJSON)
              let checkItems = await checkRewards(itemsInfo, "Items")
              if(checkItems){
                setItemData(checkItems)
              }
              if(checkItems && pricingFile){
                let resultPricing = await validatePricingCSV(checkItems);
                if(!lockByFile){
                  resolve(resultPricing.status)
                }else{
                  let resultLockBy = await validateLockByCSV(resultPricing.data)
                  resolve(resultPricing.status && resultLockBy)
                }
              }else{
                let resultLockBy = await validateLockByCSV(checkItems)
                resolve(resultLockBy)
              }
            }else{
              resolve(false)
            }
          }
        };  
        if(fileExtension === 'csv'){
          reader.readAsText(itemFile);
        }else{
          reader.readAsArrayBuffer(itemFile);
        }
      }else if(itemFile && (itemFile.size/1024).toFixed(2) > 10000){
        setItemFileErrors([`File Size Should be less than 10Mb`])
        resolve(false)
      }
    })
  }

  // 2. Pricing File

  const checkPricingError = (header) => {
    let newErrors = []
    let isError = true;
    if (header[0] !== 'Item ID') {
      newErrors.push(`Please change 1st Column name from ${header[0]} to Item ID.`);
      isError = false
    }
    if (header[1] !== 'Currency Type') {
      newErrors.push(`Please change 2nd Column name from ${header[1]} to Currency Type.`);
      isError = false
    }
    if (header[2] !== 'Currency ID') {
      newErrors.push(`Please change 3rd Column name from ${header[2]} to Currency ID.`);
      isError = false
    }
    if (header[3] !== 'Real World Currency ID') {
      newErrors.push(`Please change 4th Column name from ${header[3]} to Real World Currency ID.`);
      isError = false
    }
    if (header[4] !== 'Price Amount') {
      newErrors.push(`Please change 5th Column name from ${header[4]} to Price Amount.`);
      isError = false
    }
    if (header[5] !== 'Discount') {
      newErrors.push(`Please change 6th Column name from ${header[5]} to Discount.`);
      isError = false
    }
    if (header[6] !== 'Bonus Cash Allowance (RMG Only)') {
      newErrors.push(`Please change 7th Column name from ${header[6]} to Bonus Cash Allowance.`);
      isError = false
    }
    if (header[7] !== 'Select Platform (IAP Only)') {
      newErrors.push(`Please change 8th Column name from ${header[7]} to Select Platform (IAP Only).`);
      isError = false
    }
    if (header[8] !== 'Product ID (IAP Only)') {
      newErrors.push(`Please change 9th Column name from ${header[8]} to Product ID.`);
      isError = false
    }
    setPricingFileErrors(newErrors)
    if(isError){
      return true
    }
  }

  const checkPricingFileDataType = (obj) => {
    let errors = {currencyTypeErr: [], currencyIdErr: [], realWorldCurrencyErr: [], amountErr: [], discountErr: [], BCAErr: [], platformErr: [], productErr: []}
    
    if(typeof obj.priceTypes !== 'string'){
      errors.currencyTypeErr.push(`Currency Type should be a valid string.`)
    }

    if(obj.discount !== null && typeof obj.discount !== 'number'){
      errors.discountErr.push(`Discount should be a valid integer.`)
    }
    
    if(obj.bonusCashAllowance !== null && obj.bonusCashAllowance !== undefined && (typeof obj.bonusCashAllowance !== 'number' || !Number.isInteger(obj.bonusCashAllowance))){
      errors.BCAErr.push(`Bonus Cash Allowance should be a valid integer.`)
    }
    
    if(obj.gamePlatformMasterId !== null && obj.gamePlatformMasterId !== undefined && (typeof obj.gamePlatformMasterId !== 'number' || !Number.isInteger(obj.gamePlatformMasterId))){
      errors.platformErr.push(`Select Platform (IAP Only) should be a valid string.`)
    }

    if(obj.productId !== undefined && typeof obj.productId !== 'string'){
      errors.productErr.push(`Product ID should be a valid string.`)
    }

    return errors
  }

  const checkPricingDataType = (obj) => {
    return obj && obj.map(item => ({
      ...item,
      itemPrices: item.itemPrices && item.itemPrices.map(price => ({
        ...price,
        errors: checkPricingFileDataType(price)
      }))
    }))
  }

  const parseValidInt = (str) => {
    if (!isNaN(str) && !isNaN(parseInt(str))) {
      return parseInt(str);
    }
    return str;
  }

  const transformPricingData = (data, items) => {
    const filterPricing = data && data.map((entry, index) => ({
      itemId : (entry["Item ID"] && entry["Item ID"] !== null ? (entry["Item ID"]).toString() : null),
      priceTypes : (entry["Currency Type"] === 'Virtual' ? 'virtual currency' : entry["Currency Type"] === 'Real Money Gaming Currency' ? 'RMG' : entry["Currency Type"] === 'IAP' ? 'IAP' : entry["Currency Type"]),
      currencyClientID : (entry["Currency ID"] && entry["Currency ID"] !== null && entry["Currency ID"] !== 'N/A' && entry["Currency ID"] !== '' ? entry["Currency ID"] : null),
      ...((entry["Currency Type"] === 'Virtual' || entry["Currency Type"] === 'Real Money Gaming Currency') && {currencyId: (entry["Currency ID"] && entry["Currency ID"] !== null && entry["Currency ID"] !== 'N/A' && entry["Currency ID"] !== '' ? currencyList && currencyList.filter(item => item.currencyId == entry["Currency ID"]).map(item => item.id)[0] : null)}),
      ...(entry["Currency Type"] === 'IAP' && {realWorldCurrencyId: (entry["Real World Currency ID"] && entry["Real World Currency ID"] !== null && entry["Real World Currency ID"] !== 'N/A' && entry["Real World Currency ID"] !== '' ? realWorldCurrency && realWorldCurrency.filter(item => item.name.includes(entry["Real World Currency ID"])).map(item => item.realWorldCurrencyId)[0] : null)}),
      discount : (entry["Discount"] !== null && entry["Discount"] !== 'N/A' && entry["Discount"] !== '') ? entry["Discount"] : null,
      price : ((entry["Price Amount"] !== null && entry["Price Amount"] != 'N/A' && entry["Price Amount"] != '') ? entry["Price Amount"].toString()  : "0"),
      ...(entry["Currency Type"] === 'Real Money Gaming Currency' && {bonusCashAllowance :  ((entry["Bonus Cash Allowance (RMG Only)"] && entry["Bonus Cash Allowance (RMG Only)"] !== null && entry["Bonus Cash Allowance (RMG Only)"] != 'N/A' && entry["Bonus Cash Allowance (RMG Only)"] != '') ? parseValidInt(entry["Bonus Cash Allowance (RMG Only)"]) : null)}),
      ...(entry["Currency Type"] === 'IAP' && {gamePlatformMasterId :  ((entry["Select Platform (IAP Only)"] && entry["Select Platform (IAP Only)"] !== null && entry["Select Platform (IAP Only)"] != 'N/A' && entry["Select Platform (IAP Only)"] != '') ? (entry["Select Platform (IAP Only)"] === 'Android' ? 1 : entry["Select Platform (IAP Only)"] === 'iOS' ? 2 : entry["Select Platform (IAP Only)"])  : entry["Select Platform (IAP Only)"])}),
      ...(entry["Currency Type"] === 'IAP' && {productId : ((entry["Product ID (IAP Only)"] !== null && entry["Product ID (IAP Only)"] != 'N/A' && entry["Product ID (IAP Only)"] != '') ? entry["Product ID (IAP Only)"]  : "")})
    }));
    return items && items.map((e, i) => {
      const matchObject = filterPricing && filterPricing.filter(x => x.itemId === e.itemId)
      matchObject && matchObject.forEach(match => {
        e.itemPrices.push(
          {
            itemId: match.itemId,
            priceTypes: match.priceTypes,
            discount: match.discount, 
            price: match.price,
            currencyClientID: match.currencyClientID,  
            ...(match.priceTypes !== 'IAP' && {currencyId: match.currencyId}), 
            ...(match.priceTypes === 'RMG' && {bonusCashAllowance: match.bonusCashAllowance}),
            ...(match.priceTypes === 'IAP' && {realWorldCurrencyId: match.realWorldCurrencyId}),
            ...(match.priceTypes === 'IAP' && {gamePlatformMasterId: match.gamePlatformMasterId}),
            ...(match.priceTypes === 'IAP' && {productId: match.productId}),
          }
        )
      })
      return e
    })
  }

  const convertPricingToJson = async(data, headers, items) => {
    if(data.length > 1000){
      setPricingFileErrors([`Total number of rows should be less than 1000.`])
    }else{
      const dataRows = data && data.map(row => {
        // Create an object with the headers and corresponding row values
        const rowData = {};
        headers.forEach((header, index) => {
          rowData[header] = row[header] !== undefined ? row[header] : null; // Handle blank cells
        });
        return rowData;
      })
      return transformPricingData(dataRows, items)
    }
  }

  const validatePricingCSV = (items) => {
    return new Promise((resolve, reject) => {
      if(pricingFile && (pricingFile.size/1024).toFixed(2) < 10000){
        const fileExtension = pricingFile.name.split('.').pop().toLowerCase();
        const reader = new FileReader();
        reader.onload = async(e) => {
          if(fileExtension === 'csv'){
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'string', raw: true });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const columnsArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {header: 1, defval: ''});
            const headers = columnsArray[0];
            if(checkPricingError(headers)){
              const json = XLSX.utils.sheet_to_json(worksheet);
              let pricingJSON = await convertPricingToJson(json, headers, items)
              let pricingInfo = await checkPricingDataType(pricingJSON)
              let checkPricing = await checkRewards(pricingInfo, 'Pricing')
              if(checkPricing){
                setItemData(checkPricing)
                resolve({status: true, data: checkPricing});
              }
            }else{
              resolve({status: false, data: []});
            }
          }else{
            const arrayBuffer = e.target.result;
            const data = new Uint8Array(arrayBuffer);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const columnsArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
            const headers = columnsArray[0];
            if(checkPricingError(headers)){
              const json = XLSX.utils.sheet_to_json(worksheet);
              let pricingJSON = await convertPricingToJson(json, headers, items)
              let pricingInfo = await checkPricingDataType(pricingJSON)
              let checkPricing = await checkRewards(pricingInfo, 'Pricing')
              if(checkPricing){
                setItemData(checkPricing)
                resolve({status: true, data: checkPricing});
              }
            }else{
              resolve({status: false, data: []});
            }
          }
        };  
        if(fileExtension === 'csv'){
          reader.readAsText(pricingFile);
        }else{
          reader.readAsArrayBuffer(pricingFile);
        }
      }else if(pricingFile && (pricingFile.size/1024).toFixed(2) > 10000){
        setPricingFileErrors([`File Size Should be less than 10Mb`])
        resolve({status: false, data: []});
      }
    })
  }

  // Item LockBy

  const checkLockByError = (header) => {
    let newErrors = []
    let isError = true;
    if (header[0] !== 'Item ID') {
      newErrors.push(`Please change 1st Column name from ${header[0]} to Item ID.`);
      isError = false
    }
    if (header[1] !== 'Lock By Item') {
      newErrors.push(`Please change 2nd Column name from ${header[1]} to Lock By Item.`);
      isError = false
    }
    if (header[2] !== 'Lock By Bundle') {
      newErrors.push(`Please change 3rd Column name from ${header[2]} to Lock By Bundle.`);
      isError = false
    }
    if (header[3] !== 'Lock By Progression Systems') {
      newErrors.push(`Please change 4th Column name from ${header[3]} to Lock By Lock By Progression Systems.`);
      isError = false
    }
    if (header[4] !== 'Lock By Progression System Levels') {
      newErrors.push(`Please change 5th Column name from ${header[4]} to Lock By Progression System Levels.`);
      isError = false
    }

    setLockByFileError(newErrors)
    if(isError){
      return true
    }
  }

  const checkItemExistInCSV = (data, csv, existing) => {
    const itemIDs = csv.map(obj => obj.itemId);
    const missingIds = data.rewardDetails.items.ids && data.rewardDetails.items.ids.filter(id => !itemIDs.includes(id));
    const filterIds = missingIds && missingIds.filter(id => !existing.map(obj => obj.itemId).includes(id))
    if (filterIds.length > 0) {
      return {
        result: false,
        missingIds: filterIds
      };
    }
    // If all IDs are present, return true
    return {
      result: true,
      missingIds: []
    };

  }

  const validateLockByRewards = async(data, csv) => {
    const token = localStorage.getItem('token')
    const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
    let res = await axios.post(`${BASE_URL}/rewards-details/validate`, data, headers)
    if(res.data.data){
      let content = res.data.data
      let errorRewards = [...content.itemDetails.nonExistingItems, ...content.bundlesDetails.nonExistingBundles, ...content.currenciesDetails.nonExistingCurrencies, ...content.progressionMarkersDetails.nonExistingProgressionMarkers, ...content.progressionSystemsDetails.nonExistingProgressionSystems]
      let existingItems = content.itemDetails.existingItems
      let existingBundles = content.bundlesDetails.existingBundles
      let existingPS = content.progressionSystemsDetails.existingProgressionSystems
      if(errorRewards?.length > 0){
        if(content.itemDetails.nonExistingItems?.length > 0 && content.bundlesDetails.nonExistingBundles?.length === 0 && content.currenciesDetails.nonExistingCurrencies?.length === 0 && content.progressionMarkersDetails.nonExistingProgressionMarkers?.length === 0 && content.progressionSystemsDetails.nonExistingProgressionSystems?.length === 0){
          let checkItems = await checkItemExistInCSV(data, csv, content.itemDetails.existingItems)
          if(checkItems.result === true && checkItems.missingIds?.length === 0){
            let updatedData = csv && csv.flatMap(item => ({
              ...item,
              rewardUnlockCondition : item.rewardUnlockCondition && item.rewardUnlockCondition.map((x, index) => {
                if(x.unlockItemId !== null){
                  let matchObj = checkItems?.missingIds && checkItems?.missingIds.find(id => id === x.unlockItemId)
                  if(matchObj){
                    return{
                      ...x,
                      errors: {
                        ...x.errors,
                        lockItemIdErr : [
                          ...(x.errors && x.errors.lockItemIdErr ? x.errors.lockItemIdErr : []), `Item Id with ${x.unlockItemId} does not exists`
                        ]
                      }
                    }
                  }else{
                    let matchItem = existingItems && existingItems.find(item => item.itemId === x.unlockItemId)
                    return{
                      ...x,
                      errors: {
                        ...x.errors,
                        lockItemIdErr: [
                          ...(x.errors && x.errors.lockItemIdErr ? x.errors.lockItemIdErr : []), ...(matchItem && matchItem?.archive ? [`Item Id with ${x.unlockItemId} archived and already exist.`] : [])
                        ]
                      }
                    }
                  }
                }else if(x.unlockBundleId !== null){
                  let matchObj = errorRewards && errorRewards.find(id => id === x.unlockBundleId)
                  if(matchObj){
                    return{
                      ...x,
                      errors: {
                        ...x.errors, 
                        lockBundleIdErr: [
                          ...(x.errors && x.errors.lockBundleIdErr ? x.errors.lockBundleIdErr : []), `Bundle with Id ${x.unlockBundleId} does not exist in your project as well as in file.`
                        ]
                      }
                    }
                  }else{
                    let matchItem = existingBundles && existingBundles.find(bundle => bundle.bundleId === x.unlockBundleId)
                    return{
                      ...x,
                      errors: {
                        ...x.errors,
                        lockBundleIdErr: [
                          ...(x.errors && x.errors.lockBundleIdErr ? x.errors.lockBundleIdErr : []), ...(matchItem && matchItem?.archive ? [`Bundle Id with ${x.unlockBundleId} archived and already exist.`] : [])
                        ]
                      }
                    }
                    // return{
                    //   ...x,
                    //   errors: {
                    //     ...x.errors,
                    //     lockBundleIdErr: x.errors && x.errors.lockBundleIdErr ? [...x.errors.lockBundleIdErr] : [] 
                    //   }
                    // }
                  }
                }else if(x.unlockLevelSystemId !== null){
                  let matchObj = errorRewards && errorRewards.find(id => id === x.unlockLevelSystemId)
                  if(matchObj){
                    return{
                      ...x,
                      errors: {
                        ...x.errors, 
                        lockPSError: [
                          ...(x.errors && x.errors.lockPSError ? x.errors.lockPSError : []), `PS with Id ${x.unlockLevelSystemId} does not exist in your project as well as in file.`
                        ]
                      }
                    }
                  }else{
                    let matchItem = existingPS && existingPS.find(ps => ps.levelSystemId === x.unlockLevelSystemId)
                    return{
                      ...x,
                      errors: {
                        ...x.errors,
                        lockPSError: [
                          ...(x.errors && x.errors.lockPSError ? x.errors.lockPSError : []), ...(matchItem && matchItem?.archive ? [`PS Id with ${x.unlockLevelSystemId} archived and already exist.`] : [])
                        ]
                      }
                    }
                    // return{
                    //   ...x,
                    //   errors: {
                    //     ...x.errors,
                    //     lockPSError: x.errors && x.errors.lockPSError ? [...x.errors.lockPSError] : [] 
                    //   }
                    // }
                  }
                }
              })
            }))
            return updatedData
          }else{
            let updatedData = csv && csv.flatMap(item => ({
              ...item,
              rewardUnlockCondition : item.rewardUnlockCondition && item.rewardUnlockCondition.map((x, index) => {
                if(x.unlockItemId !== null){
                  let matchObj = checkItems?.missingIds && checkItems?.missingIds.find(id => id === x.unlockItemId)
                  if(matchObj){
                    return{
                      ...x,
                      errors: {
                        ...x.errors, 
                        lockItemIdErr: [
                          ...(x.errors && x.errors.lockItemIdErr ? x.errors.lockItemIdErr : []), `Item with Id ${x.unlockItemId} does not exist in your project as well as in file.`
                        ]
                      }
                    }
                  }else{
                    let matchItem = existingItems && existingItems.find(item => item.itemId === x.unlockItemId)
                    return{
                      ...x,
                      errors: {
                        ...x.errors,
                        lockItemIdErr: [
                          ...(x.errors && x.errors.lockItemIdErr ? x.errors.lockItemIdErr : []), ...(matchItem && matchItem?.archive ? [`Item Id with ${x.unlockItemId} archived and already exist.`] : [])
                        ]
                      }
                    }
                  }
                }else if(x.unlockBundleId !== null){
                  let matchObj = errorRewards && errorRewards.find(id => id === x.unlockBundleId)
                  if(matchObj){
                    return{
                      ...x,
                      errors: {
                        ...x.errors, 
                        lockBundleIdErr: [
                          ...(x.errors && x.errors.lockBundleIdErr ? x.errors.lockBundleIdErr : []), `Bundle with Id ${x.unlockBundleId} does not exist in your project as well as in file.`
                        ]
                      }
                    }
                  }else{
                    let matchItem = existingBundles && existingBundles.find(bundle => bundle.bundleId === x.unlockBundleId)
                    return{
                      ...x,
                      errors: {
                        ...x.errors,
                        lockBundleIdErr: [
                          ...(x.errors && x.errors.lockBundleIdErr ? x.errors.lockBundleIdErr : []), ...(matchItem && matchItem?.archive ? [`Bundle Id with ${x.unlockBundleId} archived and already exist.`] : [])
                        ]
                      }
                    }
                    // return{
                    //   ...x,
                    //   errors: {
                    //     ...x.errors,
                    //     lockBundleIdErr: x.errors && x.errors.lockBundleIdErr ? [...x.errors.lockBundleIdErr] : [] 
                    //   }
                    // }
                  }
                }else if(x.unlockLevelSystemId !== null){
                  let matchObj = errorRewards && errorRewards.find(id => id === x.unlockLevelSystemId)
                  if(matchObj){
                    return{
                      ...x,
                      errors: {
                        ...x.errors, 
                        lockPSError: [
                          ...(x.errors && x.errors.lockPSError ? x.errors.lockPSError : []), `PS with Id ${x.unlockLevelSystemId} does not exist in your project as well as in file.`
                        ]
                      }
                    }
                  }else{
                    let matchItem = existingPS && existingPS.find(ps => ps.levelSystemId === x.unlockLevelSystemId)
                    return{
                      ...x,
                      errors: {
                        ...x.errors,
                        lockPSError: [
                          ...(x.errors && x.errors.lockPSError ? x.errors.lockPSError : []), ...(matchItem && matchItem?.archive ? [`PS Id with ${x.unlockLevelSystemId} archived and already exist.`] : [])
                        ]
                      }
                    }
                    // return{
                    //   ...x,
                    //   errors: {
                    //     ...x.errors,
                    //     lockPSError: x.errors && x.errors.lockPSError ? [...x.errors.lockPSError] : [] 
                    //   }
                    // }
                  }
                }
              })
            }))
            return updatedData
          }
        }else{
          let checkItems = await checkItemExistInCSV(data, csv, content.itemDetails.existingItems)
          if(checkItems.result === true && checkItems.missingIds?.length === 0){
            let updatedData = csv && csv.flatMap(item => ({
              ...item,
              rewardUnlockCondition : item.rewardUnlockCondition && item.rewardUnlockCondition.map((x, index) => {
                if(x.unlockItemId !== null){
                  let matchObj = checkItems?.missingIds && checkItems?.missingIds.find(id => id === x.unlockItemId)
                  if(matchObj){
                    return{
                      ...x,
                      errors: {
                        ...x.errors,
                        lockItemIdErr: x.errors && x.errors.lockItemIdErr ? [...x.errors.lockItemIdErr] : [] 
                      }
                    }
                  }else{
                    let matchItem = existingItems && existingItems.find(item => item.itemId === x.unlockItemId)
                    return{
                      ...x,
                      errors: {
                        ...x.errors,
                        lockItemIdErr: [
                          ...(x.errors && x.errors.lockItemIdErr ? x.errors.lockItemIdErr : []), ...(matchItem && matchItem?.archive ? [`Item Id with ${x.unlockItemId} archived and already exist.`] : [])
                        ]
                      }
                    }
                  }
                }else if(x.unlockBundleId !== null){
                  let matchObj = errorRewards && errorRewards.find(id => id === x.unlockBundleId)
                  if(matchObj){
                    return{
                      ...x,
                      errors: {
                        ...x.errors, 
                        lockBundleIdErr: [
                          ...(x.errors && x.errors.lockBundleIdErr ? x.errors.lockBundleIdErr : []), `Bundle with Id ${x.unlockBundleId} does not exist in your project as well as in file.`
                        ]
                      }
                    }
                  }else{
                    let matchItem = existingBundles && existingBundles.find(bundle => bundle.bundleId === x.unlockBundleId)
                    return{
                      ...x,
                      errors: {
                        ...x.errors,
                        lockBundleIdErr: [
                          ...(x.errors && x.errors.lockBundleIdErr ? x.errors.lockBundleIdErr : []), ...(matchItem && matchItem?.archive ? [`Bundle Id with ${x.unlockBundleId} archived and already exist.`] : [])
                        ]
                      }
                    }
                    // return{
                    //   ...x,
                    //   errors: {
                    //     ...x.errors,
                    //     lockBundleIdErr: x.errors && x.errors.lockBundleIdErr ? [...x.errors.lockBundleIdErr] : [] 
                    //   }
                    // }
                  }
                }else if(x.unlockLevelSystemId !== null){
                  let matchObj = errorRewards && errorRewards.find(id => id === x.unlockLevelSystemId)
                  if(matchObj){
                    return{
                      ...x,
                      errors: {
                        ...x.errors, 
                        lockPSError: [
                          ...(x.errors && x.errors.lockPSError ? x.errors.lockPSError : []), `PS with Id ${x.unlockLevelSystemId} does not exist in your project as well as in file.`
                        ]
                      }
                    }
                  }else{
                    let matchItem = existingPS && existingPS.find(ps => ps.levelSystemId === x.unlockLevelSystemId)
                    return{
                      ...x,
                      errors: {
                        ...x.errors,
                        lockPSError: [
                          ...(x.errors && x.errors.lockPSError ? x.errors.lockPSError : []), ...(matchItem && matchItem?.archive ? [`PS Id with ${x.unlockLevelSystemId} archived and already exist.`] : [])
                        ]
                      }
                    }
                    // return{
                    //   ...x,
                    //   errors: {
                    //     ...x.errors,
                    //     lockPSError: x.errors && x.errors.lockPSError ? [...x.errors.lockPSError] : [] 
                    //   }
                    // }
                  }
                }
              })
            }))
            return updatedData
          }else{
            let updatedData = csv && csv.flatMap(item => ({
              ...item,
              rewardUnlockCondition : item.rewardUnlockCondition && item.rewardUnlockCondition.map((x, index) => {
                if(x.unlockItemId !== null){
                  let matchObj = checkItems?.missingIds && checkItems?.missingIds.find(id => id === x.unlockItemId)
                  if(matchObj){
                    return{
                      ...x,
                      errors: {
                        ...x.errors, 
                        lockItemIdErr: [
                          ...(x.errors && x.errors.lockItemIdErr ? x.errors.lockItemIdErr : []), `Item with Id ${x.unlockItemId} does not exist in your project as well as in file.`
                        ]
                      }
                    }
                  }else{
                    let matchItem = existingItems && existingItems.find(item => item.itemId === x.unlockItemId)
                    return{
                      ...x,
                      errors: {
                        ...x.errors,
                        lockItemIdErr: [
                          ...(x.errors && x.errors.lockItemIdErr ? x.errors.lockItemIdErr : []), ...(matchItem && matchItem?.archive ? [`Item Id with ${x.unlockItemId} archived and already exist.`] : [])
                        ]
                      }
                    }
                  }
                }else if(x.unlockBundleId !== null){
                  let matchObj = errorRewards && errorRewards.find(id => id === x.unlockBundleId)
                  if(matchObj){
                    return{
                      ...x,
                      errors: {
                        ...x.errors, 
                        lockBundleIdErr: [
                          ...(x.errors && x.errors.lockBundleIdErr ? x.errors.lockBundleIdErr : []), `Bundle with Id ${x.unlockBundleId} does not exist in your project as well as in file.`
                        ]
                      }
                    }
                  }else{
                    let matchItem = existingBundles && existingBundles.find(bundle => bundle.bundleId === x.unlockBundleId)
                    return{
                      ...x,
                      errors: {
                        ...x.errors,
                        lockBundleIdErr: [
                          ...(x.errors && x.errors.lockBundleIdErr ? x.errors.lockBundleIdErr : []), ...(matchItem && matchItem?.archive ? [`Bundle Id with ${x.unlockBundleId} archived and already exist.`] : [])
                        ]
                      }
                    }
                    // return{
                    //   ...x,
                    //   errors: {
                    //     ...x.errors,
                    //     lockBundleIdErr: x.errors && x.errors.lockBundleIdErr ? [...x.errors.lockBundleIdErr] : [] 
                    //   }
                    // }
                  }
                }else if(x.unlockLevelSystemId !== null){
                  let matchObj = errorRewards && errorRewards.find(id => id === x.unlockLevelSystemId)
                  if(matchObj){
                    return{
                      ...x,
                      errors: {
                        ...x.errors, 
                        lockPSError: [
                          ...(x.errors && x.errors.lockPSError ? x.errors.lockPSError : []), `PS with Id ${x.unlockLevelSystemId} does not exist in your project as well as in file.`
                        ]
                      }
                    }
                  }else{
                    let matchItem = existingPS && existingPS.find(ps => ps.levelSystemId === x.unlockLevelSystemId)
                    return{
                      ...x,
                      errors: {
                        ...x.errors,
                        lockPSError: [
                          ...(x.errors && x.errors.lockPSError ? x.errors.lockPSError : []), ...(matchItem && matchItem?.archive ? [`PS Id with ${x.unlockLevelSystemId} archived and already exist.`] : [])
                        ]
                      }
                    }
                    // return{
                    //   ...x,
                    //   errors: {
                    //     ...x.errors,
                    //     lockPSError: x.errors && x.errors.lockPSError ? [...x.errors.lockPSError] : [] 
                    //   }
                    // }
                  }
                }
              })
            }))
            return updatedData
          }
        }
      }else{
        return csv
      }
    }
  }
  
  const checkLockByItemRewards = async(data) => {
    const rewards = {
      projectId : appid,
      rewardDetails : {
        items : {ids:[]},
        currencies : {ids:[]},
        bundles : {ids:[]},
        progressionMarkers : {ids:[]},
        progressionSystem: { ids: [] },
        stores : {ids : []}
      }
    }
    
    data && data.map((e, index) => {
      e.rewardUnlockCondition && e.rewardUnlockCondition.forEach((x, i) => {
        if(x.unlockItemId !== '' && x.unlockItemId !== undefined && x.unlockItemId !== null && x.unlockItemId != 'N/A' && !rewards.rewardDetails.items.ids.includes(x.unlockItemId)){
          rewards.rewardDetails.items.ids.push((x.unlockItemId).toString());
        }
        if(x.unlockBundleId !== '' && x.unlockBundleId !== undefined && x.unlockBundleId !== null && x.unlockBundleId != 'N/A' && !rewards.rewardDetails.bundles.ids.includes(x.unlockBundleId)){
          rewards.rewardDetails.bundles.ids.push((x.unlockBundleId).toString());
        }
  
        const extractIds = (id) => {
          if(id){
            const items = id.split(",");
            return items.map(item => item.match(/^([^\(]+)/)[1]);
          }
          return [];
        }
  
        const addUniqueIds = (ids, key) => {
          ids && ids.forEach(id => {
            if(x.unlockLevelSystemId !== undefined && x.unlockLevelSystemId !== null && x.unlockLevelSystemId != 'N/A' && !rewards.rewardDetails.progressionSystem.ids.includes(x.unlockLevelSystemId)){
              rewards.rewardDetails.progressionSystem.ids.push((x.unlockLevelSystemId).toString());
            }
          });
        };
        addUniqueIds(extractIds(x.unlockLevelSystemId));
      })
    })

    let validateRewardData = await validateLockByRewards(rewards, data)
    return validateRewardData
  }

  const convertUnlockCondition = (arr, data) => {
    let result = []
    arr && arr.forEach(obj => {
      if(obj.unlockItemId){
        result.push({
          itemId : obj.itemId,
          unlockItemId: obj.unlockItemId,
          unlockBundleId : null,
          unlockLevelSystemId: null,
          lockedLevel: null
        });
      }else if(obj.unlockBundleId){
        result.push({
          itemId : obj.itemId,
          unlockItemId: null,
          unlockBundleId : obj.unlockBundleId,
          unlockLevelSystemId: null,
          lockedLevel: null
        });
      }else if(obj.unlockLevelSystemId){
        // Split unlockLevelSystemId and lockedLevel by comma
        const unlockPSIDs = obj.unlockLevelSystemId.split(', ');
        const PSLevels = typeof obj.lockedLevel === 'string' ? obj.lockedLevel.split(',').map(level => parseInt(level.trim(), 10)) : [null];
        // Create separate object for each combination of unlockLevelSystemId and lockedLevel
        const maxLength = Math.max(unlockPSIDs.length, PSLevels.length);
        for (let i = 0; i < maxLength; i++) {
          result.push({
            itemId : obj.itemId,
            unlockItemId: null,
            unlockBundleId : null,
            // unlockLevelSystemId: unlockPSIDs[i] || null,
            unlockLevelSystemId: unlockPSIDs[i],
            lockedLevel: PSLevels[i] !== null ? PSLevels[i] : null
          });
        }
      }
    })
    return result
  };

  const transformLockByData = (data, items) => {
    const lockByFileData = data && data.map((entry, index) => ({
      itemId : entry["Item ID"],
      unlockItemId : (entry["Lock By Item"] && entry["Lock By Item"] !== null && entry["Lock By Item"] !== 'N/A' ? entry["Lock By Item"] : null),
      unlockBundleId : (entry["Lock By Bundle"] && entry["Lock By Bundle"] !== null && entry["Lock By Bundle"] !== 'N/A' ? entry["Lock By Bundle"] : null),
      unlockLevelSystemId : (entry["Lock By Progression Systems"] && entry["Lock By Progression Systems"] !== null && entry["Lock By Progression Systems"] !== 'N/A' ? entry["Lock By Progression Systems"] : null),
      lockedLevel : (entry["Lock By Progression System Levels"] && entry["Lock By Progression System Levels"] !== null && entry["Lock By Progression System Levels"] !== 'N/A' ? entry["Lock By Progression System Levels"].toString() : null),
    }))
    const updatedLockByData = convertUnlockCondition(lockByFileData, items)

    return items && items.map((e, i) => {
      const matchObject = updatedLockByData && updatedLockByData.filter(x => x.itemId === e.itemId)
      matchObject && matchObject.forEach(match => {
        e.rewardUnlockCondition.push(
          {
            itemId: match.itemId,
            unlockItemId: match.unlockItemId,
            unlockBundleId: match.unlockBundleId,
            unlockLevelSystemId: match.unlockLevelSystemId,
            lockedLevel: match.lockedLevel,
          }
        )
      })
      return e
    })
    // return updatedLockByData
    // const updatedItemWithLockBy = transformArrays(items, updatedLockBy)
    // return updatedItemWithLockBy
  }

  const convertLockByToJSON = async(data, headers, items) => {
    if(data.length > 1000){
      setLockByFileError([`Number of rows should be less than 1000.`])
    }else{
      const dataRows = data && data.map(row => {
        // Create an object with the headers and corresponding row values
        const rowData = {};
        headers.forEach((header, index) => {
          rowData[header] = row[header] !== undefined ? row[header] : null; // Handle blank cells
        });
        return rowData;
      })
      return transformLockByData(dataRows, items)
    }
  }

  const checkLockByFileDataType = (obj) => {
    let errors = {itemIdErr: [], lockItemIdErr: [], lockBundleIdErr: [], lockPSError: [], lockPSLevelErr: []}

    if(obj.itemId !== null && typeof obj.itemId !== 'string'){
      errors.itemIdErr.push(`Item ID should be a valid string.`)
    }

    if(obj.unlockItemId !== null && typeof obj.unlockItemId !== 'string'){
      errors.lockItemIdErr.push(`Lock By Item should be a valid string.`)
    }

    if(obj.unlockBundleId !== null && typeof obj.unlockBundleId !== 'string'){
      errors.lockBundleIdErr.push(`Lock By Bundle should be a valid string.`)
    }

    if(obj.unlockLevelSystemId !== null && typeof obj.unlockLevelSystemId !== 'string'){
      errors.lockPSError.push(`Lock By Progression Systems should be a valid string.`)
    }

    if(obj.lockedLevel !== null && typeof obj.lockedLevel !== 'number'){
      errors.lockPSLevelErr.push(`Lock By Progression System Levels should be a valid integer.`)
    }
    return errors
  }
  

  const checkLockByDataType = (obj) => {
    return obj && obj.map(item => ({
      ...item,
      rewardUnlockCondition: item.rewardUnlockCondition && item.rewardUnlockCondition.map(unlockCondition => ({
        ...unlockCondition,
        errors: checkLockByFileDataType(unlockCondition)
      }))
    }))
  }

  const validateLockByCSV = (items) => {
    return new Promise((resolve, reject) => {
      if(lockByFile && (lockByFile.size/1024).toFixed(2) < 10000){
        const fileExtension = lockByFile.name.split('.').pop().toLowerCase();
        const reader = new FileReader();
        reader.onload = async(e) => {
          if(fileExtension === 'csv'){
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'string', raw: true });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const columnsArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {header: 1, defval: ''});
            const headers = columnsArray[0];
            if(checkLockByError(headers)){
              const json = XLSX.utils.sheet_to_json(worksheet);
              let lockByJSON = await convertLockByToJSON(json, headers, items)
              let lockByDataCheck = checkLockByDataType(lockByJSON)
              let checkRewardData = await checkLockByItemRewards(lockByDataCheck)
              if(checkRewardData){
                resolve(true)
                setItemData(checkRewardData)
              }
            }else{
              resolve(false)
            }
          }else{
            const arrayBuffer = e.target.result;
            const data = new Uint8Array(arrayBuffer);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const columnsArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
            const headers = columnsArray[0];
            if(checkLockByError(headers)){
              const json = XLSX.utils.sheet_to_json(worksheet);
              let lockByJSON = await convertLockByToJSON(json, headers, items)
              let lockByDataCheck = checkLockByDataType(lockByJSON)
              let checkRewardData = await checkLockByItemRewards(lockByDataCheck)
              if(checkRewardData){
                setItemData(checkRewardData)
                resolve(true)
              }
            }else{
              resolve(false)
            }
          }
        };  
        if(fileExtension === 'csv'){
          reader.readAsText(lockByFile);
        }else{
          reader.readAsArrayBuffer(lockByFile);
        }
      }else if(lockByFile && (lockByFile.size/1024).toFixed(2) > 10000){
        setLockByFileError([`File Size Should be less than 10Mb`])
        resolve(false)
      }
    })
  }


  // Re-validate

  const revalidate = async(data) => {
    let itemsInfo = await checkDataType(data)
    let checkItems = await checkRewards(itemsInfo, "Items")
    if(checkItems){
      setItemData(checkItems)
      if(pricingFile){
        let pricingInfo = await checkPricingDataType(checkItems)
        let checkPricing = await checkRewards(pricingInfo, 'Pricing')
        setItemData(checkPricing)
        if(lockByFile){
          let lockByDataCheck = await checkLockByDataType(checkPricing)
          let checkRewardData = await checkLockByItemRewards(lockByDataCheck)
          setItemData(checkRewardData)
        }
      }else if(lockByFile){
          let lockByDataCheck = await checkLockByDataType(checkItems)
          let checkRewardData = await checkLockByItemRewards(lockByDataCheck)
          setItemData(checkRewardData)
      }
    }
  }

  // Export

  const getSubType = (obj) => {
    return Object.entries({
      isEquippable: 'Equippable',
      isTradable: 'Tradable',
      isStackable: 'Stackable',
      isRentable: 'Rentable',
      isTimeStackable: 'TimeStackable'
    }).filter(([key]) => obj[key])
      .map(([, value]) => value)
      .join(', ');
  }

  const transformItemKeys = (data) => {
    const keysToCheck = {isEquippable : 'Equippable',isTradable: 'Tradable',isStackable : 'Stackable',isRentable : 'Rentable',isTimeStackable : 'Time Stackable'}
    return data.map(item => ({
      "Item Icon": ((item.iconUrl !== null && item.iconUrl !== '') ? item.iconUrl : 'N/A'),
      "Display Name": item.name,
      "Item ID": item.itemId,
      "Item Description": ((item.description !== null && item.description !== '') ? item.description : 'N/A'),
      "Item Type": (item.isConsumable === true ? 'Consumable' : item.isConsumable === false ? 'Durable' : ''),
      "Count/Time Usage": ((item.consumeByUses !== null && item.consumeByTime === null) ? 'Count' : (item.consumeByUses === null && item.consumeByTime !== null) ? 'Time' : ''),
      "Uses (if Count)": ((item.consumeByUses !== null && item.consumeByUses !== '') ? parseInt(item.consumeByUses) : 'N/A'),
      "Time Value (if Time)": ((item.consumeByTime !== null && item.consumeByTime !== '') ? parseInt(item.consumeByTime) : 'N/A'),
      "Time Unit (if Time)": ((item.consumeByTimeFormat && item.consumeByTimeFormat !== null && item.consumeByTimeFormat !== '') ? (item.consumeByTimeFormat).toLowerCase() : 'N/A'),
      "Item Subtypes": getSubType(item),
      "Max Stack Count": ((item.stackCapacity !== null && item.stackCapacity !== '') ? parseInt(item.stackCapacity) : 'N/A'),
      "Max Collection Instances": ((item.maxCollectionInstance !== null && item.maxCollectionInstance !== '') ? parseInt(item.maxCollectionInstance) : 'N/A'),
      "Limited Availability": (item.quantity !== null ? 'Yes' : item.quantity === null ? 'No' : ''),
      "Limited Quantity": ((item.quantity !== null && item.quantity !== '') ? parseInt(item.quantity) : null),
      "Default Load Out": (item.isDefaultLoadout === true ? 'Yes' : item.isDefaultLoadout === false ? 'No' : ''),
      "Equip By Default": (item.equippedByDefault === true ? 'Yes' : item.equippedByDefault === false ? 'No' : ''),
      "Tags": ((item.tagsMapping !== null && item.tagsMapping?.length > 0) ? item.tagsMapping && item.tagsMapping.map(mapping => mapping?.tags?.name).join(', ') : 'N/A'),
      "Meta Data": JSON.stringify(item.meta),
      // Add more transformations if needed
    }));
  };

  const transformPricing = (data) => {
    const headers = [
      'Item ID',
      'Currency Type',
      'Currency ID',
      'Real World Currency ID',
      'Price Amount',
      'Discount',
      'Bonus Cash Allowance (RMG Only)',
      'Select Platform (IAP Only)',
      'Product ID (IAP Only)',
    ];
    let pricingData = data && data.flatMap(item => {
      if (item.inventoryItemPrices && item.inventoryItemPrices.length > 0) {
        return item.inventoryItemPrices && item.inventoryItemPrices.map(pricing => ({
          'Item ID': item.itemId,
          'Currency Type' : (pricing.priceTypes === 'virtual currency' ? 'Virtual' : pricing.priceTypes === 'RMG' ? 'Real Money Gaming Currency' : pricing.priceTypes === 'IAP' ? 'IAP' : 'N/A'),
          'Currency ID' : ((pricing.currency !== null && pricing.currency.currencyId !== null && pricing.currency.currencyId !== '') ? pricing.currency.currencyId : (pricing.currency !== null && (pricing.currency.currencyId === null || pricing.currency.currencyId === '')) ? 'N/A' : pricing.currency === null ? 'N/A' : ''),
          'Real World Currency ID' : ((pricing.realWorldCurrency !== null && pricing.realWorldCurrencyId !== null && pricing.realWorldCurrencyId !== '') ? pricing.realWorldCurrency.code : (pricing.realWorldCurrency !== null && (pricing.realWorldCurrencyId === null || pricing.realWorldCurrencyId === '')) ? 'N/A' : pricing.realWorldCurrency === null ? 'N/A' : ''),
          'Price Amount' : ((pricing.price !== null && pricing.price !== '') ? pricing.price : (pricing.price === null || pricing.price === '') ? 'N/A' : ''),
          'Discount' : ((pricing.discount !== null && pricing.discount !== '') ? pricing.discount : (pricing.discount === null || pricing.discount === '') ? 'N/A' : ''),
          'Bonus Cash Allowance (RMG Only)' : ((pricing.bonusCashAllowance !== null && pricing.bonusCashAllowance !== '') ? pricing.bonusCashAllowance : (pricing.bonusCashAllowance === null || pricing.bonusCashAllowance === '') ? 'N/A' : ''),
          'Select Platform (IAP Only)' : ((pricing.gamePlatformMasterId !== null && pricing.gamePlatformMasterId !== '' && pricing.gamePlatformMasterId === 1) ? 'Android' : (pricing.gamePlatformMasterId !== null && pricing.gamePlatformMasterId !== '' && pricing.gamePlatformMasterId === 2) ? 'iOS' : (pricing.gamePlatformMasterId === null || pricing.gamePlatformMasterId === '') ? 'N/A' : ''),
          'Product ID (IAP Only)' : ((pricing.productId !== null && pricing.productId !== '') ? pricing.productId : (pricing.productId === null || pricing.productId === '') ? 'N/A' : ''),
        }));
      }
      return [];
    });

    if (pricingData?.length === 0) {
      pricingData.push(headers.reduce((acc, header) => ({ ...acc, [header]: '' }), {}));
    }
    return pricingData
  }

  const transformLockBy = (data) => {
    const headers = [
      'Item ID',
      'Lock By Item',
      'Lock By Bundle',
      'Lock By Progression Systems',
      'Lock By Progression System Levels',
    ];
    let result = [];
    data && data.forEach(item => {
      item.rewardsUnlockConditions && item.rewardsUnlockConditions.forEach(condition => {
        if (condition.unlockItemId) {
          result.push({
            "Item ID" : item.itemId,
            'Lock By Item': condition.item.itemId,
            'Lock By Bundle' : null,
            'Lock By Progression Systems': null,
            'Lock By Progression System Levels': null
          });
        }else if (condition.unlockBundleId) {
          result.push({
            "Item ID" : item.itemId,
            'Lock By Item': null,
            'Lock By Bundle' : condition.bundle.bundleId,
            'Lock By Progression Systems': null,
            'Lock By Progression System Levels': null
          });
        }
        else if (condition.unlockLevelSystemId) {
          result.push({
            "Item ID" : item.itemId,
            'Lock By Item': null,
            'Lock By Bundle' : null,
            'Lock By Progression Systems': condition.unlockLevel.levelSystemId,
            'Lock By Progression System Levels': condition.lockedLevel
          });
        }
      });
    });
    if(result?.length === 0) {
      result.push(headers.reduce((acc, header) => ({ ...acc, [header]: '' }), {}));
    }
    return result;
  }

  const [exportOptions, setExportOptions] = useState([{id:1, name: 'Import'}, {id: 2, name: 'Export', options: [{id: 1, name: 'As XLSX'}, {id: 2, name: 'As CSV'}]}])

  const getItemsDetails = async() => {
    let exportedData = []
    let ids;
    let count = Math.ceil(totalItems/100)
    for(var i = 0; i < count ; i++){
      let items = {
        projectId : appid,
        offset : (i === 0 ? 0 : (i*100)),
        limit : 100
      }
      const token = localStorage.getItem('token')
      const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
      let res = await customAxios.post(BASE_URL + GET_ITEMS, items, headers)
      if(res){
        let itemInstance = res.data.data.items
        ids = itemInstance && itemInstance.map(e => e.id)
        exportedData.push(...itemInstance)
      }
    }
    if(exportedData){
      return {data : exportedData, ids: ids}
    }
  }

  const exportData = async(type) => {
    let itemsData = await getItemsDetails()
    let convertedItems = await transformItemKeys(itemsData.data)
    let convertedPricing = await transformPricing(itemsData.data)
    let convertedLockBy = await transformLockBy(itemsData.data)
    // Items
    const worksheetItems = XLSX.utils.json_to_sheet(convertedItems);
    const workbookItems = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbookItems, worksheetItems, "Sheet1");
    // Item Pricing
    const worksheetPricing = XLSX.utils.json_to_sheet(convertedPricing);
    const workbookPricing = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbookPricing, worksheetPricing, "Sheet1");
    // Item Lock By
    const worksheetLockBy = XLSX.utils.json_to_sheet(convertedLockBy);
    const workbookLockBy = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbookLockBy, worksheetLockBy, "Sheet1");
    if(type === 'As XLSX'){

      XLSX.writeFile(workbookItems, "File_Items.xlsx");
      XLSX.writeFile(workbookPricing, "File_Pricing.xlsx");
      XLSX.writeFile(workbookLockBy, "File_LockBy.xlsx");
    }else{
      XLSX.writeFile(workbookItems, "File_Items.csv");
      XLSX.writeFile(workbookPricing, "File_Pricing.csv");
      XLSX.writeFile(workbookLockBy, "File_LockBy.csv");
    }
  }

  const uploadFunctions = {onCSVItem: onCSVItem, onCSVPricing: onCSVPricing, onCSVFile: onCSVFile}
  const files = {itemFile: itemFile, setItemFile: setItemFile, pricingFile: pricingFile, setPricingFile: setPricingFile, lockByFile: lockByFile, setLockByFile: setLockByFile}
  const fileErrors = {itemFileError: itemFileErrors, pricingFileErrors: pricingFileErrors, lockByFileError: lockByFileError, setItemFileErrors: setItemFileErrors, setPricingFileErrors: setPricingFileErrors, setLockByFileError: setLockByFileError}

  const [bulkUpload, setBulkUpload] = useState(false)

  const {loading, economy, completedCalls, failedCalls, totalCalls} = useSelector((state) => state.economyItemsNBundle)

  const [progress, setProgress] = useState(false)
  const [total, setTotal] = useState(0)
  const [concluded, setConcluded] = useState(0)

  const radius = 150; // Radius of the circle
  const stroke = 20; // Stroke width
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const progressOffset = circumference - (completedCalls / totalCalls) * circumference;
  const [action, setActions] = useState([{id: 1, name: 'Upload File', status: 'completed'}, {id: 2, name: 'Validate File', status: 'completed'}, {id: 3, name: 'Repair', status: 'completed'}, {id: 4, name: 'Import', status: 'completed'}])

  const getAllRequiredData = async() => {
    let exportedData = []
    // let count = Math.ceil(totalItems/10)
    for(var i = 0; i < 1 ; i++){
      let items = {
        projectId : appid,
        offset: 0,
        // offset : (i === 0 ? 0 : (i*10)),
        limit : 1000
      }
      const token = localStorage.getItem('token')
      const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
      let res = await customAxios.post(BASE_URL + GET_ITEMS, items, headers)
      if(res){
        let itemInstance = res.data.data.items
        exportedData.push(...itemInstance)
      }
    }
    updateBulkLockByItems(exportedData)
  }

  // Bulk Item Upload

  // Remove Unwanted Data
  const removeErrorKeyObject = (data) => {
    const rewardUnlock = [];
    let updatedData = data && data.map(item => {
      const { errors, rewardUnlockCondition, ...rest } = item;
      rewardUnlock.push(...rewardUnlockCondition)
      const updatedPrices = item.itemPrices && item.itemPrices.map(pricing => {
        const { errors, currencyClientID, ...priceRest } = pricing;
        return priceRest;
      })
      return {
        ...rest,
        itemPrices : updatedPrices
      }
    })
    return {data: updatedData, lockByData: rewardUnlock}
  }

  const uploadBulkItems = async() => {
    // Dispatch the action to start API calls
    let items = await removeErrorKeyObject(itemData)
    let itemList = {
      items: items.data
    }
    sessionStorage.setItem('lockBy', JSON.stringify(items.lockByData))
    dispatch(startBulkUploadItemsNBundles(itemList, (items.lockByData?.length > 0), reset, getAllRequiredData, 'Items', 'POST'))
  };

  // Bulk Lock By Item

  const convertItemUnlockCondition = (arr, data) => {
    let result = [];
    arr && arr.forEach(obj => {
      let matchObj = data && data.filter(e => e.itemId == obj.unlockItemId)
      if (obj.unlockItemId) {
        // Create separate object for unlockItemId
        result.push({
          itemId : obj.itemId,
          unlockItemId: (matchObj && matchObj !== null && matchObj?.length > 0 ? matchObj[0].id : null),
          unlockBundleId : null,
          unlockLevelSystemId: null,
          lockedLevel: null
        });
      }
      if(obj.unlockBundleId) {
        let matchObj = BundleList && BundleList.filter(e => e.bundleId == obj.unlockBundleId)
        result.push({
          itemId : obj.itemId,
          unlockItemId: null,
          unlockBundleId : (matchObj && matchObj !== null && matchObj?.length > 0 ? matchObj[0].id : null),
          unlockLevelSystemId: null,
          lockedLevel: null
        })
      }
      if (obj.unlockLevelSystemId) {
        // Split unlockLevelSystemId and PSLevel by comma
        const unlockPSIDs = obj.unlockLevelSystemId.split(', ');
        const PSLevels = typeof obj.lockedLevel === 'string' ? obj.lockedLevel.split(',').map(level => parseInt(level.trim(), 10)) : typeof obj.lockedLevel === 'number' ? [obj.lockedLevel] : [null];
        // Create separate object for each combination of unlockLevelSystemId and PSLevel
        const maxLength = Math.max(unlockPSIDs.length, PSLevels.length);
        for (let i = 0; i < maxLength; i++) {
          let matchPSObj = PSList && PSList.filter(e => e.levelSystemId === unlockPSIDs[i])
          result.push({
            itemId : obj.itemId,
            unlockItemId: null,
            unlockBundleId : null,
            unlockLevelSystemId: (matchPSObj && matchPSObj !== null && matchPSObj?.length > 0 ? matchPSObj[0].id : null),
            lockedLevel: PSLevels[i] !== null ? PSLevels[i] : null
          });
        }
      }
    });
    return result;
  }

  const transformArrays = (arr1, arr2) => {
    // Filter first array to include only objects with itemId present in the second array
    const filteredFirstArray = arr1.filter(obj1 => arr2.some(obj2 => obj2.itemId === obj1.itemId));
    // Map over the filtered first array to add the corresponding objects from the second array
    const result = filteredFirstArray && filteredFirstArray.map(obj1 => {
      const rewardUnlockCondition = arr2.filter(obj2 => obj2.itemId === obj1.itemId).map(({ unlockItemId, unlockBundleId, unlockLevelSystemId, lockedLevel }) => ({ unlockItemId, unlockBundleId, unlockLevelSystemId, lockedLevel }));
      return { id: obj1.id, rewardUnlockCondition };
    });
    return result;
  }

  const getlockByDetail = async(data, items) => {
    const updatedLockBy = await convertItemUnlockCondition(data, items)
    const updatedItemWithLockBy = await transformArrays(items, updatedLockBy)
    return updatedItemWithLockBy
  }

  const updateBulkLockByItems = async(items) => {
    let lockByItemData = JSON.parse(sessionStorage.getItem('lockBy'))
    const filterLockByData = await getlockByDetail(lockByItemData, items)
    const itemList = {
      items : filterLockByData
    }
    dispatch(startBulkUploadItemsNBundles(itemList, null, reset, null, 'Items', 'PUT'))
  }

  // Reset Data
  const reset = () => {
    getAllItems()
    setBulkUpload(false)
    setItemFile(null)
    setPricingFile(null)
    setLockByFile(null)
    setItemFileErrors(null)
    setLockByFileError(null)
    setPricingFileErrors(null)
  }

  useEffect(() => {
    if(bulkUpload){
      uploadBulkItems()
    }
  }, [bulkUpload])

  // Loader
  if(isLoading){
    return(
      <>
        <div className={styles.loader}>
          <Loader />
        </div>
      </>
    )
  }

  if(loading && economy === 'Items'){
    return(
      <>
        <Card layOut={`Get`} className={styles.card} classCardHead={styles.head} title={showSkeleton ? <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '4px'}} animation="wave" variant="recatangle" width={110} height={32}/> : 'Items'} classTitle={cn("title-purple", styles.title)}>
          <div className={styles.wrapper}>
          <div className={styles.progressContainer}>
            <div className={styles.progress}>
              {action && action.map((e, i) => (
                <>
                  <div className={styles.progressContent}>
                    {e.status === 'remaining' && <div className={styles.progressStatus}></div>}
                    {e.status === 'completed' && <Check />}
                    <div className={styles.content}>{e.name}</div>
                    <div className={styles.icon}><Icon name='arrow-next' size='16' /></div>
                  </div>
                </>
              ))}
            </div>
            <div className={styles.svgContainer}>
              <div className={styles.progressEvents}>
                <div className={styles.title}>Items Uploaded</div>
                <div className={styles.events}>
                  <div className={styles.success}>
                    <div className={styles.items}></div>
                    <div className={styles.eventTitle}>{completedCalls}/{totalCalls}</div>
                  </div>
                  <div className={styles.failure}>
                    <div className={styles.error}></div>
                    <div className={styles.eventTitle}>{failedCalls}/{totalCalls}</div>
                  </div>
                </div>
              </div>
              <svg height={radius * 2} width={radius * 2} className={styles.circleSvg}>
                  <circle stroke="#EFEFEF" fill="transparent" strokeWidth={stroke} r={normalizedRadius} cx={radius} cy={radius} />
                  <circle stroke="#2A85FF" fill="transparent" strokeWidth={stroke} strokeDasharray={circumference + ' ' + circumference} style={{borderRadius: '20px', strokeDashoffset: progressOffset, transition: 'stroke-dashoffset 1s ease-in-out' }} r={normalizedRadius} cx={radius} cy={radius} strokeLinecap="round" />
              </svg>
            </div>
          </div>
          </div>
        </Card>
      </>
    )
  }

  return(
    <>
      <ToastContainer />
      <Navigation type='Items' filters={filters} exportAction={exportData} exportOption={exportOptions} search={search} setSearch={onSearch} archive={showArchive} setArchive={setShowArchive} data={sortList} setPage={setPage} sortIndex={sortIndex} setSortIndex={setSortIndex} selected={selectedSort} setSelected={setSelectedSort} sort={sort} setSort={setSort} value={sortValue} setValue={setSortValue} 
        fileUpload={<><ControlFileUpload usedFor='navigation' size='large' type='Items' onFileChange={uploadFunctions} onValidation={validateItemCSV} revalidate={revalidate} data={itemData} setData={setItemData} files={files} fileErrors={fileErrors} action={getAllItems} setBulkUpload={setBulkUpload} override={getItemsDetails}/></>}
      />
      <Card layOut={`Get`} className={styles.card} classCardHead={styles.head}>

        <div className={styles.wrapper}>
          {/* Item Table View */}
          {showSkeleton && allItems?.length === 0 && 
            <SkeletonEmptyState />
          }
          {showSkeleton && allItems?.length > 0 && 
            <SkeletonTable usedFor='Items' rows={allItems.length} column={5} controls={2} type={['recatangle', 'recatangle', 'recatangle', 'reactangle', 'circular']} colWidth={[36, 110, 110, 110, 36]} colHeight={[36, 15, 25, 25, 36]} />
          }
          {!showSkeleton && !isFiltered && allItems?.length === 0 &&
            <EmptyState access={userAccess} route={`/create-item/${appid}`} type="Item" exportOption={<><ControlFileUpload usedFor='empty state' size='large' type='Items' onFileChange={uploadFunctions} onValidation={validateItemCSV} revalidate={revalidate} data={itemData} setData={setItemData} files={files} fileErrors={fileErrors} action={getAllItems} setBulkUpload={setBulkUpload} override={getItemsDetails}/></>}/>
          }
          {!showSkeleton && !isFiltered && allItems?.length > 0 &&
            <Table access={userAccess} items={allItems} title="Last edited" /> 
          }
          {!showSkeleton && isFiltered &&
            (allItems?.length > 0 ?
              <Table access={userAccess} items={allItems} title="Last edited" /> 
            :
              <div className={styles.message}>No Result Found</div>
            )
          }

        </div>
        
        {!isLoading && allItems.length > 0 &&
          <div className={styles.pagination}>
            <Stack spacing={2}>
              <Pagination page={page} renderItem={(item) => (
                <PaginationItem
                  {...item}
                  sx={{
                    '&.MuiPaginationItem-root': {
                      minWidth: '28px',
                      height: '28px',
                      fontSize: '12px'
                    },
                    '&.Mui-selected': {
                      color: 'rgb(255, 255, 255)',
                      fontWeight : 'bold',
                      border : '1px solid rgb(42, 133, 255)',
                      background : 'rgba(42, 133, 255)'
                    },
                    '&.MuiPaginationItem-page.Mui-selected:hover' : {
                      color: 'rgb(255, 255, 255)',
                      fontWeight : 'bold',
                      border : '1px solid rgb(42, 133, 255)',
                      background : 'rgba(42, 133, 255)'
                    }
                  }}
                />
              )} siblingCount={1} count={Math.ceil(totalItems/limit)} color="primary" onChange={handlePageChange} />
            </Stack>
          </div>
        }
      </Card>
      <div className="divider"></div>
      <div className={styles.msg}>
        <p className={styles.alert}>Learn more about items <a className={styles.infoLink} href="https://manual.specterapp.xyz/specter-user-manual/build/economy/items" target="_blank">Click Here</a></p>
      </div>
    </>
  )

}

export default GetItems