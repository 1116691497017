import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./control.module.sass";
import Icon from "../../../../../../Icon";
import Modal from "../../../../../../Modals/ModalComponent";
import ArchiveModal from "../../../../../../Modals/Others/ArchieveModal";
import {ReactComponent as ArchiveSvg} from '../../../../../../../media/images/icons/archive.svg'
import EyeIcon from '../../../../../../../media/images/icons/eye.png'
import { useAccess } from "../../../../../../Page";

const Control = ({className, access, id, appid, status, name, type}) => {

  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleArchiveModal, setVisibleArchiveModal] = useState(false)
  const { accessPermissions } = useAccess();
  const { allowProgressionSystemEdit, allowProgressionSystemArchive} = accessPermissions || {};

  const actions = [
    {
      pathName : `/view-progression-system/${appid}/${id}`,
      action: () => console.log("view"),
    },
    {
      icon: "edit",
      pathName : `/edit-progression-system/${appid}/${id}`,
      action: () => console.log("edit"),
    },
    {
      icon: "download",
      action: () => setVisibleArchiveModal(true),
    }
  ];

  return (
    <>
      <div className={cn(styles.control, className)}>
      {access ? 
          (actions && actions.slice(0, 1).map((x, index) => {
            if(
              (!allowProgressionSystemEdit &&x?.pathName?.includes("/edit-item"))||
              (!allowProgressionSystemArchive && x?.icon== "download")
            ){
              return ;
            }
            return(
              <Link to={x.pathName} state={{id : id, appid : appid, markerName : name, archiveStatus : status}} className={styles.button} key={index} onClick={x.action}>
                <img src={EyeIcon} width={20} height={20} className={styles.icon} />
              </Link>
            )
          }))
        : 
          (actions && actions.slice(1).map((x, index) => {
            if(
              (!allowProgressionSystemEdit &&x?.pathName?.includes("/edit-item"))||
              (!allowProgressionSystemArchive && x?.icon== "download")
            ){
              return ;
            }
            return(
              <Link to={x.pathName} state={{id : id, appid : appid, markerName : name, archiveStatus : status}} className={styles.button} key={index} onClick={x.action}>
                {x.icon == 'download' ?  <ArchiveSvg /> : <Icon name={x.icon} size="20" className={styles.icon}/>}
              </Link>
            )
          }))
        } 
      </div>
      <Modal visible={visibleArchiveModal} onClose={() => setVisibleArchiveModal(false)}>
        <ArchiveModal id={id} name={name} type={type} status={status} onClose={() => setVisibleArchiveModal(false)}/>
      </Modal>
    </>
  );
};

export default Control;
