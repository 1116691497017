import React, {useContext, useEffect, useState} from 'react'
import axios from 'axios';
import customAxios from '../../../../../../utils/interceptor';
import { Link, json, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './progressionSystem.module.sass'
import Card from '../../../../../../components/Card';
import TextInput from '../../../../../../components/TextInput';
import TextArea from '../../../../../../components/TextArea';
import Radio from '../../../../../../components/Radio';
import Dropdown from '../../../../../../components/Dropdown/CreatableDropdown';
import Icon from '../../../../../../components/Icon';
import addIcon from '../../../../../../media/images/icons/add.png'
import editIcon from '../../../../../../media/images/icons/edit.png'
import { createProgressionSystemAction, editProgressionSystemAction } from '../../../../../../redux/action/builds/progression/progressionSystem/progressionSystemAction';
import { getAllProgressionMarkerAction } from '../../../../../../redux/action/builds/progression/progressionMarker/progressionMarkerAction';
import Rewards from './Rewards'
import Control from './Control';
import JsonControl from './JsonControl';
import { WithContext as ReactTags } from "react-tag-input";
import Tooltip from '../../../../../../components/Tooltip';
import { BASE_URL, GET_PROGRESSION_SYSTEM } from '../../../../../../resources/APIEndpoints';
import ProgressionSystem from '../../../../../../media/images/placeholders/Progression_System.png'
import { useRef } from 'react';
import Toast from '../../../../../../components/Toast';
import {ReactComponent as AddButtonIcon} from '../../../../../../media/images/icons/add_button.svg'
import {ReactComponent as AddIcon} from '../../../../../../media/images/icons/add_meta.svg'
import {ReactComponent as EditIcon} from '../../../../../../media/images/icons/editSvg.svg'
import {ReactComponent as RemoveIcon} from '../../../../../../media/images/icons/remove.svg'
import currencyPlaceholder from '../../../../../../media/images/placeholders/Currency.png'
import itemPlaceholder from '../../../../../../media/images/placeholders/Items.png'
import {ReactComponent as Previous} from '../../../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../../../media/images/icons/next.svg'
import TagInputWithDropdown from "../../../../../../components/TagInputWithDropdown";
import FileUploadSidebar from '../../../../../../components/FileUploadSidebar';
import { getAllMediaAction } from '../../../../../../redux/action/AppSettings/Media/mediaAction';
import TooltipTitle from '../../../../../../Tooltip/TooltipTitle';
import JSONMetaDataValueInput from '../../../../../../components/MetaInput';
import XLSX from 'xlsx';
import bundlePlaceHolder from '../../../../../../media/images/placeholders/Bundles.png'
import PMPlaceHolder from '../../../../../../media/images/placeholders/Progression_Marker.png'
import currencyPlaceHolder from '../../../../../../media/images/placeholders/Currency.png'
import itemPlaceHolder from '../../../../../../media/images/placeholders/Items.png'
import AddContentModal from "../../../../../../components/Modals/AddRewardModal";
import Modal from "../../../../../../components/Modals/ModalComponent";
import _ from 'lodash'
import app,{increaseUnreadCountForAllUsers} from "../../../../../../firebase.js"
import { getFirestore, collection, addDoc } from "firebase/firestore";
import GlobalStateContext from '../../../../../../components/Context/GlobalStates/GlobalStateContext.js';
import { extarctImageFromUrlAction } from '../../../../../../redux/action/common/commonAction.js';
import SnapShot from '../../../../../../components/Images';

const eventOption = ['Select Events','option 1','option 2', 'option 3']

const ProgressionSystemInfo = ({className, onClose, generateData,isChanged, setIsChanged}) => {

    const {appid} = useParams()
    const {id} = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const ref = useRef()

    const [visibleHelp, setVisibleHelp] = useState(false);
    const globalStates = useContext(GlobalStateContext)
    const placeholder = ProgressionSystem
    const [{alt, src}, setLogo] = useState({src: placeholder , alt: 'Upload an Image'}); 
    const [type, setType] = useState()
    const [progressionIcon, setProgressionIcon] = useState()
    const [changedIcon, setChangedIcon] = useState(null)
    const [displayName, setDisplayName] = useState()
    const [progressionId, setProgressionId] = useState()
    const [progressionDescription, setProgressionDescription] = useState()
    const [parameter, setParameter] = useState(true)
    const [system, setSystem] = useState(true)
    const [progression, setProgression] = useState(null)
    const [events, setEvents] = useState(eventOption[0])
    const [progressionType, setProgressionType] = useState(false)
    const [progressionSchedule, setProgressionSchdule] = useState(false)
    const [progressionTime, setProgressionTime] = useState()
    const [indexLevel, setIndexLevel] = useState()
    const [tags, setTags] = useState([])
    const [metaData, setMetaData] = useState([{key : '' , value : null}])
    const [levels, setLevels] = useState([])
    const [psId, setPSID] = useState()
    const [page, setPage] = useState(1)
    
    const uploadLogo = async(e) =>{
        if(e.target.files[0]) {
            setLogo({src: URL.createObjectURL(e.target.files[0]), alt: e.target.files[0].name});    
        } 
        // Converting Image to base64 and then converting to binary to upload
        var file = e.target.files[0];   
        const file_binary = await convertbase64(file)
        const res = await axios.get(`${BASE_URL}/admin/upload-url?contentType=${file.type}`)
        setProgressionIcon(res.data.getUrl)
        //setChangedIcon(res.data.getUrl);
        var config = {
            headers : {'Content-Type' : `${file.type}`},
            method: 'put',
            url: res.data.postUrl,
            data :convertDataURIToBinary(file_binary),
        }
        axios(config)
        .then(function (response) {
 toast.success(<Toast type='Success' messages='Logo Uploaded Successfully'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        })
        .catch(function (error) {
            //toast.error(<Toast type='Error' messages='Logo not uploaded. Please try again!'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        });
    }

    const onPrevious = () => {
        setPage(prevPage => prevPage - 1)
    }

    const onNext = () => {
        setPage(nextPage => nextPage + 1)
    }

    // To Convert Base64 to Binary
    function convertDataURIToBinary(dataURI) {
        var BASE64_MARKER = ';base64,';
        var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = dataURI.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));
              
        for(var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }

        return array;
    }
        
    // To Convert file into base64 format
    const convertbase64 = (file) => {
        return new Promise((resolve, err)=>{
        const fileReader = new FileReader();
        fileReader.onload = (eve) =>{
            resolve(fileReader.result)
        }
        fileReader.readAsDataURL(file)
        })
    }

    const Cancel = () => {
        setLogo({src: placeholder , alt: 'Upload an Image'})
        setProgressionIcon(null)
        ref.current.value = ''
    }

    const KeyCodes = { comma: 188, enter: 13 };
      
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleDelete = (i) => {
      setTags(tags.filter((tag, index) => index !== i));
    };
  
    const handleAddition = (tag) => {
        if(tags.length < 10){
            setTags([...tags, tag]);
        }else{
            toast.warning(<Toast type='Warning' messages='Only 10 tags are allowed'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000})
        }
    };
    
    const handleDrag = (tag, currPos, newPos) => {
      const newTags = [...tags].slice();
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
  
      setTags(newTags);
    };
  
    const handleTagClick = (index, event) => {
        event.stopPropagation();
    };
  
    const onClearAll = () => {
      setTags([]);
    };
  
    const onTagUpdate = (i, newTag) => {
      const updatedTags = tags.slice();
      updatedTags.splice(i, 1, newTag);
      setTags(updatedTags);
    };
    const [checkObject, setCheckObject] = useState()

    const getProgressionSystemDetail = async() => {
        let getPSBYID = {
            ids: [id],
            projectId : appid
        }
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await customAxios.post(BASE_URL + GET_PROGRESSION_SYSTEM, getPSBYID, headers)
        if(res){
            let PSID = res.data.data.levelDetails
            setCheckObject(PSID)
            setDisplayName(PSID.name)
            setPSID(PSID.levelSystemId)
            setProgressionDescription(PSID.description)
            setProgressionIcon(PSID.iconUrl)
            setLogo({src: (PSID.iconUrl != null ? PSID.iconUrl : ProgressionSystem), alt: 'Upload an Image'})
            setParameter(PSID.appEventId == null ? true : false)
            setProgressionId(PSID.progressionMarker.id)
            setProgression(PSID.progressionMarker.name)
            setProgressionSchdule(PSID.rewardGrantScheduleType == 'on-completion' ? false : true)
            if(PSID.levelDetails == null || PSID.levelDetails?.length == 0){
                let initialLevels = [{startRank : null, endRank : null, type: 'add', archive: false, rewardDetails : {currencies: [], items:[], bundles: [], progressionMarkers:[], rewardSets: []}}]
                setLevels(initialLevels)
                sessionStorage.setItem("Level", JSON.stringify(initialLevels))
            }else{
                let levels = []
                for(var i = 0 ; i < PSID.levelDetails.length ; i++){
                    let levelSystemReward = {currencies: [], items:[], bundles: [], progressionMarkers:[], rewardSets: []}
                    for(var j = 0 ; j < PSID.levelDetails[i].rewardDetails.length ; j++){
                        if(PSID.levelDetails[i].rewardDetails[j].itemId != null){
                            levelSystemReward.items.push({uuid: PSID.levelDetails[i].rewardDetails[j].itemDetail.id, id:PSID.levelDetails[i].rewardDetails[j].itemDetail.itemId, rewardId : PSID.levelDetails[i].rewardDetails[j].itemDetail.itemId, name: PSID.levelDetails[i].rewardDetails[j].itemDetail.name, quantity: PSID.levelDetails[i].rewardDetails[j].quantity, rewardType: 'Items', archive: false, rewardStatus: null})
                        }else if(PSID.levelDetails[i].rewardDetails[j].currencyId != null){
                            levelSystemReward.currencies.push({uuid: PSID.levelDetails[i].rewardDetails[j].currencyDetail.id, id:PSID.levelDetails[i].rewardDetails[j].currencyDetail.currencyId, rewardId : PSID.levelDetails[i].rewardDetails[j].currencyDetail.currencyId, name: PSID.levelDetails[i].rewardDetails[j].currencyDetail.name, quantity: PSID.levelDetails[i].rewardDetails[j].quantity, rewardType: 'Currency', archive: false,  rewardStatus: null})
                        }else if(PSID.levelDetails[i].rewardDetails[j].bundleId != null){
                            levelSystemReward.bundles.push({uuid: PSID.levelDetails[i].rewardDetails[j].bundleDetail.id, id:PSID.levelDetails[i].rewardDetails[j].bundleDetail.bundleId, rewardId : PSID.levelDetails[i].rewardDetails[j].bundleDetail.bundleId, name: PSID.levelDetails[i].rewardDetails[j].bundleDetail.name, quantity: PSID.levelDetails[i].rewardDetails[j].quantity, rewardType: 'Bundle', archive: false,  rewardStatus: null})
                        }else if(PSID.levelDetails[i].rewardDetails[j].progressionMarkerId != null){
                            levelSystemReward.progressionMarkers.push({uuid: PSID.levelDetails[i].rewardDetails[j].progressionMarkerDetail.id, id:PSID.levelDetails[i].rewardDetails[j].progressionMarkerDetail.progressionMarkerId, rewardId : PSID.levelDetails[i].rewardDetails[j].progressionMarkerDetail.progressionMarkerId, name: PSID.levelDetails[i].rewardDetails[j].progressionMarkerDetail.name, quantity: PSID.levelDetails[i].rewardDetails[j].quantity, rewardType: 'PM', archive: false,  rewardStatus: null})
                        }
                    }
                    levels.push({no : i+1, id: PSID.levelDetails[i].id, name : PSID.levelDetails[i].levelName, incrementalParameterValue : PSID.levelDetails[i].incrementalParameterValue, cumulativeParameterValue : PSID.levelDetails[i].cumulativeParameterValue, type: 'edit', archive: false, rewardDetails : levelSystemReward})
                }
                sessionStorage.setItem("Level", JSON.stringify(levels))
                setLevels(levels)
            }
            PSID.tagsMapping && PSID.tagsMapping.forEach(e => {
                e.tag && setTags(tags => [...tags, {id : e.tag.id, text : e.tag.name}])
            });
            let meta = []
            if(PSID.meta == null || JSON.stringify(PSID.meta) == '{}'){
                setMetaData([{key : '', value : null}])
            }else{
                PSID.meta && Object.keys(PSID.meta).forEach(e => {
                    meta.push({key : e, value : PSID.meta[e]})
                });
                setMetaData(meta)
            }
        }
    }

    const getProgressionMarker = () => {
        let PM = {
            projectId : appid,
            ids : [],
            offset : 0,
            limit : 500
        }
        dispatch(getAllProgressionMarkerAction(PM))
    }

    useEffect(()=>{
        getProgressionSystemDetail()
        getProgressionMarker()
    }, [])

    let progressionMarker = useSelector((state)=>{
        return state.progressionMarkers.progressionMarker
    })

    const addMetaData = () => {
        const metaDataField = []
        metaDataField.push(...metaData, {key : '' , value : null})
        setMetaData(metaDataField)
    }

    const removeMetaData = (index) => {
        let removeField = metaData
        let metaFields = removeField.filter((e,i,c)=>{
            return i != index
        })
        setMetaData(metaFields)
    }

    const onChangeKey = (e, index) => {
        let keys = [...metaData]
        keys[index].key = e.target.value
        setMetaData(keys)
    }

    const onChangeValue = (e, index) => {
        let values = [...metaData]
        values[index].value = e.target.value
        setMetaData(values)
    }

    const [errors, setErrors] = useState({nameError : '', idError : '', pmError : ''})

    const validation = (data) => {
        const error = {}
        let isValid = true

        if (data.name && data.name == '') {
            error.nameError = 'Display Name is required';
            isValid = false;
            const element = document.getElementById('psName');
            const offset = 140;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        }
      
        if (data.levelSystemId && data.levelSystemId == '') {
            error.idError = 'PS ID is required';
            isValid = false;
            if(data.name && data.name != ''){
                const element = document.getElementById('psId');
                const offset = 140;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        if (data.progressionMarkerId && data.progressionMarkerId == null) {
            error.pmError = 'PM is required';
            isValid = false;
            if((data.name && data.name != '') && (data.levelSystemId && data.levelSystemId != '')){
                const element = document.getElementById('pmName');
                const offset = 100;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({top: offsetPosition, behavior: 'smooth'});
            }
        }

        setErrors(error);

        if(isValid){
            return true
        }
    }

    const parseRewards = (rewards, type) => {
        if (!rewards) return [];
        let rows = rewards.split(', ').map(reward => {
          const match = reward.match(/(.+)\((\d+)\)/);
          return match ? {name: match[1], id: match[1], quantity: parseInt(match[2]), rewardType: type } : null;
        })
        return rows
    };

    const transformData = (data) => {
        return data.map((entry, index) => ({
            no: index+1,
            name : entry['Level'],
            incrementalParameterValue: entry["Incremental Parameter"],
            cumulativeParameterValue: entry["Cummulative Parameter"],
            // rewardDetails: [...parseRewards(entry.Items, 'Items'), ...parseRewards(entry.Bundles, 'Bundle'), ...parseRewards(entry.Currencies, 'Currency'), ...parseRewards(entry["Progression Markers"], 'PM')]
            rewardDetails: {
                items: parseRewards(entry.Items, 'Items'),
                bundles: parseRewards(entry.Bundles, 'Bundle'),
                currencies: parseRewards(entry.Currencies, 'Currency'),
                progressionMarkers: parseRewards(entry["Progression Markers"], 'PM'),
                rewardSets : []
            }
        }));
      };

    const convertToJson = (data) => {
        let rows = transformData(data)
        return rows
    }

    const convertIds = (data) => {
        const categorizedRewards = {
            rewardDetails : {
                items: { ids: [] },
                bundles: { ids: [] },
                currencies: { ids: [] },
                progressionMarkers: { ids: [] }
            },
            projectId : appid
        }

        data && data.forEach(entry => {
            const { rewardDetails } = entry;
            rewardDetails.currencies.forEach(currency => {
                if (!categorizedRewards.rewardDetails.currencies.ids.includes(currency.id)) {
                    categorizedRewards.rewardDetails.currencies.ids.push(currency.id);
                }
            });
        
            rewardDetails.items.forEach(item => {
                if (!categorizedRewards.rewardDetails.items.ids.includes(item.id)) {
                    categorizedRewards.rewardDetails.items.ids.push(item.id);
                }
            });
        
            rewardDetails.bundles.forEach(bundle => {
                if (!categorizedRewards.rewardDetails.bundles.ids.includes(bundle.id)) {
                    categorizedRewards.rewardDetails.bundles.ids.push(bundle.id);
                }
            });
        
            rewardDetails.progressionMarkers.forEach(marker => {
                if (!categorizedRewards.rewardDetails.progressionMarkers.ids.includes(marker.id)) {
                    categorizedRewards.rewardDetails.progressionMarkers.ids.push(marker.id);
                }
            });
        });
        return categorizedRewards;
    }

    const convertToDesiredData = (data1, data2) => {
        const lookupMap = data2.reduce((acc, item) => {
            acc.id = acc.uuid
            acc[item.id] = item;
            return acc;
        }, {});
      
        // Enhance data1 with additional fields from data2
        const enhanced = data1.map(entry => {
            const { rewardDetails } = entry;
      
            const enhancedRewardDetails = {
              items: rewardDetails.items.map(item => ({
                ...item,
                ...lookupMap[item.id]
              })),
              bundles: rewardDetails.bundles.map(bundle => ({
                ...bundle,
                ...lookupMap[bundle.id]
              })),
              currencies: rewardDetails.currencies.map(currency => ({
                ...currency,
                ...lookupMap[currency.id]
              })),
              progressionMarkers: rewardDetails.progressionMarkers.map(marker => ({
                ...marker,
                ...lookupMap[marker.id]
              })),
              rewardSets : []
            };
      
            return {
              ...entry,
              rewardDetails: enhancedRewardDetails
            };
        });
        return enhanced;
    }

    const onCSV = async(event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = async(e) => {
            const arrayBuffer = e.target.result;
            const data = new Uint8Array(arrayBuffer);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet);
            let xyz = await convertToJson(json)
            let testObject = convertIds(xyz)
            const token = localStorage.getItem('token')
            const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
            axios.post(`${BASE_URL}/rewards-details/get`, testObject, headers)
            .then(res=>{
                let currencies = res.data.currencies.map(i => ({uuid : i.id, id : i.currencyId, rewardId : i.currencyId, description: i.description, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? currencyPlaceHolder : i.iconUrl), name : i.name, status : false, rewardType: 'Currency'}));
                let items = res.data.items.map(i => ({uuid : i.id, id : i.itemId, rewardId : i.itemId, description: i.description, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? itemPlaceHolder : i.iconUrl), name : i.name, status : false, rewardType: 'Items'}));
                let bundles = res.data.bundles.map(i => ({uuid : i.id, id : i.bundleId, rewardId : i.bundleId, description: i.description, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? bundlePlaceHolder : i.iconUrl), name : i.name, status : false, rewardType: 'Bundle'}));
                let progressionMarkers = res.data.progressionMarkers.map(i => ({uuid : i.id, id : i.progressionMarkerId, rewardId : i.progressionMarkerId, description: i.description, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? PMPlaceHolder : i.iconUrl), name : i.name, status : false, rewardType: 'PM'}));
                const rewardList = [...currencies, ...items, ...bundles, ...progressionMarkers]
                const mergedRewards = convertToDesiredData(xyz, rewardList);
                let existingLevels = JSON.parse(sessionStorage.getItem('Level')).map(x => ({...x, no: 0, archive: true}))
                let allLevels = [...mergedRewards, ...existingLevels]
                setLevels(allLevels)
                sessionStorage.setItem('Level', JSON.stringify(allLevels))
            })
        };   
        reader.readAsArrayBuffer(file);
    }

    function compareRewards(rewards1, rewards2, key, type) {
        // Reduce over the rewards from the first set (rewards1)
        const changesFromExistingRewards = rewards1 && rewards1.reduce((acc, reward1) => {
          const reward2 = rewards2 && rewards2.find((rwd) => rwd.uuid === reward1[key]);
          
          // If there's no matching reward in rewards2, skip this reward.
          if (!reward2) return acc;
      
          const rewardChanges = {};
          // Compare quantity
          if (reward1.quantity !== reward2.quantity) {
            rewardChanges.quantity = reward2.quantity;
            rewardChanges.archive = reward2.archive;
            rewardChanges.rewardStatus = reward2.rewardStatus
          }
          // Compare archive status
          if (reward1.archive !== reward2.archive) {
            rewardChanges.quantity = reward2.quantity;
            rewardChanges.archive = reward2.archive;
            rewardChanges.rewardStatus = reward2.rewardStatus
          }
          // Add reward changes to result if there are any
          if (!_.isEmpty(rewardChanges)) {
            acc.push({ id: (reward1.itemId !== null ? reward1.itemId : reward1.currencyId !== null ? reward1.currencyId : reward1.bundleId !== null ? reward1.bundleId : reward1.progressionMarkerId !== null ? reward1.progressionMarkerId : null), ...rewardChanges, type });
          }
      
          return acc;
        }, []);
      
        // Check for newly added rewards in rewards2 that don't exist in rewards1
        const newlyAddedRewards = rewards2.filter((reward2) => {
          return !rewards1.some((reward1) => reward1[key] === reward2.uuid) && reward2.archive === false;
        }).map(reward2 => {
          return {
            id: reward2.id,
            quantity: reward2.quantity,
            archive: reward2.archive,
            rewardStatus: reward2.rewardStatus,
            type
          };
        });
      
        // Return both the changed rewards and newly added rewards
        return [...changesFromExistingRewards, ...newlyAddedRewards];
    } 


    const compareLevels = (levels1, levels2) => {
        const levelChanges =  levels1 && levels1.map((level1) => {
          const level2 = levels2 && levels2.find((lvl) => lvl.id === level1.id);
      
          if (!level2) return null; // Skip if no matching level is found
      
          // Initialize an object to always include these fields
          let changes = {
                id: level1.id,
                no: level1.levelNo, // Always include levelNo
                name: level1.levelName, // Always include levelName
                incrementalParameterValue: level1.incrementalParameterValue, // Always include incrementalParameterValue
                cumulativeParameterValue: level1.cumulativeParameterValue, // Always include cumulativeParameterValue
                archive: level1.archive, // Always include archive status
                rewardDetails: {currencies: [], items: [], bundles: [], progressionMarkers: [], rewardSets: []}
          };
      
          let hasChanges = false;
      
          // Compare level fields
          if (level1.levelName !== level2.name) {
            changes.name = level2.name;
            hasChanges = true;
          }
          if (level1.incrementalParameterValue !== level2.incrementalParameterValue) {
            changes.incrementalParameterValue = level2.incrementalParameterValue;
            hasChanges = true;
          }
          if (level1.cumulativeParameterValue !== level2.cumulativeParameterValue) {
            changes.cumulativeParameterValue = level2.cumulativeParameterValue;
            hasChanges = true;
          }
          if (level1.archive !== level2.archive) {
            changes.archive = level2.archive;
            hasChanges = true;
          }
      
          // Compare rewardDetails (currencies, items, bundles, progression markers)
          const rewardChanges = {currencies: [], items: [], bundles: [], progressionMarkers: [], rewardSets: []};
      
          // Compare currencies
          const currencyDiffs = compareRewards(level1.rewardDetails, level2.rewardDetails.currencies, 'currencyId', 'currencies');
          if (currencyDiffs.length > 0) {
            rewardChanges.currencies = currencyDiffs;
            hasChanges = true;
          }
      
          // Compare items
          const itemDiffs = compareRewards(level1.rewardDetails, level2.rewardDetails.items, 'itemId', 'items');
          if (itemDiffs.length > 0) {
            rewardChanges.items = itemDiffs;
            hasChanges = true;
          }
      
          // Compare bundles
          const bundleDiffs = compareRewards(level1.rewardDetails, level2.rewardDetails.bundles, 'bundleId', 'bundles');
          if (bundleDiffs.length > 0) {
            rewardChanges.bundles = bundleDiffs;
            hasChanges = true;
          }
      
          // Compare progression markers
          const progressionMarkerDiffs = compareRewards(level1.rewardDetails, level2.rewardDetails.progressionMarkers, 'progressionMarkerId', 'progressionMarkers');
          if (progressionMarkerDiffs.length > 0) {
            rewardChanges.progressionMarkers = progressionMarkerDiffs;
            hasChanges = true;
          }
      
          // Only include reward changes if there are any
          if (!_.isEmpty(rewardChanges)) {
            changes.rewardDetails = rewardChanges;
          }
          // Return the object if there are any changes, otherwise return null
          return hasChanges ? changes : null;
        }).filter(level => level !== null); 
        // Handle newly added levels in levels2 that don't exist in levels1
        const newLevels = levels2 && levels2.filter(level2 => !levels1.some(level1 => level1.id === level2.id)).map(level2 => ({
            id: level2.id,
            no: level2.no, // Always include levelNo
            name: level2.name, // Always include levelName
            incrementalParameterValue: level2.incrementalParameterValue,
            cumulativeParameterValue: level2.cumulativeParameterValue,
            archive: level2.archive,
            rewardDetails: level2.rewardDetails // Include the full reward details for newly added levels
        }));
        // Return both the changed levels and the newly added levels
        return [...levelChanges, ...newLevels]
    }; 

    const compareMeta = (meta1, meta2) => {
        const keys1 = Object.keys(meta1);
        const keys2 = Object.keys(meta2);
        // If the number of keys or their values differ, return the whole meta2
        if (keys1.length !== keys2.length || !_.isEqual(meta1, meta2)) {
            return meta2; // Return the whole meta2 object
        }
        // Return null if there are no changes
        return null;
    }
    
    const filterChanged = (obj1, obj2) => {
        return _.reduce(obj2, (result, value, key) => {
          // Always include 'id' and 'uuid' keys
          if (key === 'id' || key === 'projectId') {
            result[key] = value;
          }
          // Special case: compare 'tags' in obj2 with 'tagsMapping' in obj1
          else if (key === 'tags') {
            const tagsFromMapping = obj1['tagsMapping'] && obj1['tagsMapping'].map(mapping => mapping.tag.name);
            if (!_.isEqual(tagsFromMapping, value)) {
              result[key] = value; // map tags changes to 'tagsMapping'
            }
          }
          else if(key === 'levelDetails'){
            let levels = compareLevels(obj1.levelDetails, obj2.levelDetails)
            if(levels?.length > 0){
                result[key] = levels
            }
          }
          // Compare nested objects (like 'meta')
          else if (key === 'meta' && _.isObject(value) && _.isObject(obj1[key])) {
            const metaChanges = compareMeta(obj1.meta, obj2.meta);
            if (metaChanges) {
              result.meta = metaChanges; // Include the whole new meta if there are changes
            }
          }
          // Normal comparison: add the key if values differ
          else if (!_.isEqual(obj1[key === 'numberOfPostion' ? 'numberOfPostions' : key], value)) {
            result[key] = value;
          }
          return result;
        }, {})
    }
    const db = getFirestore(app);
    const edit = async() => {
        const metaDataList = [...metaData];
        const finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }
        let PSTags = []
        tags && tags.forEach((e)=>{
            PSTags.push(e.text)
        })

        // const levelDetails = await removeIconKey(levels)

        let editProgressionSystem = {
            projectId : appid,
            id : id,
            name : displayName,
            levelSystemId : psId,
            iconUrl : progressionIcon,
            description : progressionDescription,
            progressionMarkerId : progressionId,
            appEventId : null,
            levelSystemTypeId: 1,
            ...(levels?.length > 0 && {levelDetails : levels}),
            rewardGrantScheduleType : (progressionSchedule ? "custom" : "on-completion"),
            tags: PSTags,
            meta: finalMetaData
        }
        let progressionSystem = await filterChanged(checkObject, editProgressionSystem)
        if(validation(progressionSystem)){
            dispatch(editProgressionSystemAction(progressionSystem, navigate, appid, globalStates.setShowOverlay))
            // Access the response data (e.g., game ID)
                 
            setIsChanged(false);
            const user = JSON.parse(localStorage.getItem('user'))
            const memberID = user?.id
            const memberName = user?.name
            const orgId = (localStorage.getItem('orgId'))

            // Log activity in Firestore
            const activityLog = {
                timestamp: new Date().toISOString(),
                action_type: "UPDATE",
                app_id:appid,
                resource: "PROGRESSION SYSTEM",
                resource_id: id, // Use the ID from response
                description: `Progression System '${displayName}' updated successfully`,
                quantity: 1,
...(localStorage.getItem('environment')&&{environment:localStorage.getItem('environment')}),
operation_status: "SUCCESS",
                activity_feed: true,
                priority: "LOW",
                performed_by_id: memberID,
                organization_id: orgId,
                performed_by:memberName
            };
            await addDoc(collection(db, "activityFeed"), activityLog);
await increaseUnreadCountForAllUsers( appid);
            console.log("Activity logged successfully");
        
            setIsChanged(false)
        }
    }

    const addLevels = () => {
        const levelFields = [...levels]
        levelFields.push({no: levels.length + 1, name : '', incrementalParameterValue : null, cumulativeParameterValue : null, type: 'add', archive: false, rewardDetails : {currencies: [], items:[], bundles: [], progressionMarkers:[], rewardSets: []}})
        setLevels(levelFields)
        sessionStorage.setItem('Level', JSON.stringify(levelFields))
    }

    const onChangeLevel = (index, value) => {
        if (/^[a-zA-Z0-9- ]+$/i.test(value) || value == '') {
            const updatedInputData = [...levels];
            updatedInputData[index].name = value;
            setLevels(updatedInputData);
            sessionStorage.setItem('Level', JSON.stringify(levels))
        }
    }

    const onChangeIncrementalParameter = (index, value) => {
        const updatedInputData = [...levels];
        updatedInputData[index].incrementalParameterValue = parseInt(value);
        setLevels(updatedInputData);
        let testValue = 0
        for(var i = 0 ; i < updatedInputData.length ; i++){
            testValue = testValue + updatedInputData[i].incrementalParameterValue
            updatedInputData[i].cumulativeParameterValue = parseInt(testValue);
            setLevels(updatedInputData);
        }
        setLevels(updatedInputData);
        sessionStorage.setItem('Level', JSON.stringify(updatedInputData))
    }


    const handleKeyDown = (event) => {
        if (event.key === '.') {
          event.preventDefault();
        }
    };

    const removeLevel = (index) => {
        let removeField = [...levels]
        let removeLevelFields =  removeField && removeField.map((item, i) => {
            const updatedRewards = {};
            // Iterate over each key in rewards object
            if(index === item.no){
                Object.keys(item.rewardDetails).forEach(key => {
                    updatedRewards[key] = item.rewardDetails[key].map(rewardItem => ({
                      ...rewardItem,
                      archive: true,
                    }));
                });
                return {
                    ...item,
                    archive: true,
                    rewardDetails: updatedRewards,
                };
            }else{
                return item
            }
        })
        let testValue = 0
        for(var i = 0 ; i < removeLevelFields.length ; i++){
            if(removeLevelFields[i].archive === false){
                testValue = testValue + removeLevelFields[i].incrementalParameterValue
                removeLevelFields[i].cumulativeParameterValue = parseInt(testValue);
                setLevels(removeLevelFields);
            }
        }
        sessionStorage.setItem('Level', JSON.stringify(removeLevelFields))
    }

    const data = (data) => {
        let existingLevels = JSON.parse(sessionStorage.getItem('Level')).map(x => ({...x, no: 0, archive: true}))
        let newLevels = [{no: 1, name : `Level 1`, incrementalParameterValue : 0, cumulativeParameterValue : 0, archive: false, rewardDetails : {currencies: [], items:[], bundles: [], progressionMarkers:[], rewardSets: []}}]
        const levelFields = [...existingLevels, ...newLevels]
        let baseValueParam = data.baseValue
        let incrementalParameter = [1, baseValueParam]
        let cumulativeParameter = [1, baseValueParam]
        for(var i = 1 ; i < data.levels ; i++){
            let incrementalValueParameter = incrementalParameter[i] * data.multiplyer
            let cumulativeValueParameter = cumulativeParameter[i] + incrementalValueParameter
            cumulativeParameter.push(cumulativeValueParameter)
            incrementalParameter.push(incrementalValueParameter)
            if(incrementalParameter[i] > Number.MAX_SAFE_INTEGER || incrementalParameter[i] > Number.MAX_SAFE_INTEGER){
                toast.error(<Toast type='Error' messages='Value Exceeds largest Integer value'/>, {position : 'top-center', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FF6A55'}})
                break;
            }else{
                levelFields.push({no: i+1, name : `Level ${(i+1).toString()}`, incrementalParameterValue : Math.round(incrementalParameter[i]), cumulativeParameterValue : parseInt(cumulativeParameter[i]), archive: false, rewardDetails : {currencies: [], items:[], bundles: [], progressionMarkers:[], rewardSets: []}})
            }
        }
        setLevels(levelFields.sort((a, b) => (a.archive === b.archive) ? 0 : a.archive ? 1 : -1))
        sessionStorage.setItem('Level', JSON.stringify(levelFields.sort((a, b) => (a.archive === b.archive) ? 0 : a.archive ? 1 : -1)))
    }


    const onTagInput = () => {
        if(document.getElementById('PSTagInput')){
            document.getElementById('PSTagInput').focus()
        }
    }

    const cancel = () => {
        navigate(`/progression-system/${appid}`)
        setIsChanged(false)
    }

    const [visibleFile, setVisibleFile] = useState(false)

    function compareContentArrays(arr1, arr2) {
        let array1=arr1&& arr1.filter(item => !item?.archive)
        let array2=arr2&& arr2.filter(item => !item?.archive)
        if (array1?.length !== array2?.length) {
            return false;
        }
    
        // Iterate over the arrays
        for (let i = 0; i < array1.length; i++) {
            if (
                (array1[i]?.id===array2[i]?.item?.itemId|| array2[i]?.item?.itemId==undefined|| array2[i]?.item?.itemId==null)&&
                (array1[i]?.id===array2[i]?.currency?.currencyId || array2[i]?.currency?.currencyId==undefined|| array2[i]?.currency?.currencyId==null)&&
                parseInt(array1[i].quantity)===array2[i].quantity
            ) {
            }
            else{
                return false;
            }
        }

        return true;
    }


    function compareLevelsArray(arr1, arr2) {
        let array1=arr1&& arr1.filter(item => !item?.archive)
        let array2=arr2&& arr2.filter(item => !item?.archive)
        if (array1?.length !== array2?.length) {
            return false
        }
        for (let i = 0; i < array1.length; i++) {
            let contentCheck = compareContentArrays(array1[i].rewardDetails, array2[i].rewardDetails)
            if (
                (array1[i].name==array2[i].levelName)&&
                (array1[i].incrementalParameterValue==array2[i].incrementalParameterValue)&&
                (array1[i].no==array2[i].levelNo)&&
                (contentCheck)&&
                (array1[i].cumulativeParameterValue==array2[i].cumulativeParameterValue)
            ) {
            }
            else{
                return false;
            }
        }

        return true;
    }


    const setchange = () => {

        let metaDataList = [...metaData];
        let finalMetaData = {};
        for(var i = 0; i < metaDataList.length ; i++){
            finalMetaData[metaDataList[i].key] = metaDataList[i].value;
        }

        let itemTags = []
        tags && tags.forEach((e)=>{
            itemTags.push(e.text)
        })
        let checkTags = []
        checkObject?.tagsMapping && checkObject?.tagsMapping.forEach((e)=>{
            if (e.tag!==null&&e.tag!==undefined) {
            checkTags.push(e?.tag?.name)
            }
        })
        
        // let levelsCheck = checkObject&& ((checkObject.levelDetails&& compareLevelsArray(levels, checkObject.levelDetails))||!checkObject?.levelDetails)
        
        if (checkObject&&
            (displayName==checkObject.name)&&
            (psId==checkObject.levelSystemId)&&
            (progressionIcon==checkObject.iconUrl)&&
            (((progressionDescription==undefined||progressionDescription==''||progressionDescription==null)&& checkObject.description==null)||progressionDescription==checkObject.description)&&
            (progressionId==checkObject.progressionMarkerId)&&
            // (levelsCheck)&&
            //((JSON.stringify(finalMetaData) == JSON.stringify(checkObject.meta))||((JSON.stringify(finalMetaData)=='{"":""}')&&(checkObject.meta==null)))&&
            (JSON.stringify(itemTags) == JSON.stringify(checkTags)|| (checkObject.tagsMapping==null && itemTags.length==0))
        )
        {
            setIsChanged(false)
        }
        else 
        {
            setIsChanged(true)
        }
    }


    useEffect(()=>{
        setchange()
    },[type,progressionIcon,displayName,psId,progressionId,progressionDescription,parameter,system,progression,progressionSchedule,metaData,levels,tags])
    
    const [media, setMedia] = useState([])

    const getMedia = async() => {
        let getMediaData = {
            projectId : appid,
            category: 'icons',
            typeId : 0
        }
        await dispatch(getAllMediaAction(getMediaData))
    }

    let files = useSelector((state) => {
        return state.media.media.mediaDetails
    })

    const configureMedia = () => {
        if(files && files.length > 0){
            setMedia(files && files.map((e, i) => ({...e, status : false})))
        }else{
            setMedia([])
        }
    }

    useEffect(() => {
        getMedia()
        getAllRewards()
    }, [])

    useEffect(() => {
        configureMedia()
    }, [files])

  const [item, setItem] = useState([])
  const [currency, setCurrency] = useState([])
  const [bundle, setBundle] = useState([])
  const [pm, setPM] = useState([])

  const getAllRewards = () => {
      let search = {
          projectId : appid,
          search : ''
      }
      const token = localStorage.getItem('token')
      const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
      axios.post(`${BASE_URL}/inventory/search`, search, headers)
      .then(res=>{
          let currencies = res.data.data.currencies.map(i => ({uuid:i.id, id : i.currencyId, description: i.description,  iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? null : i.iconUrl), name : i.name, status : false, rewardType: 'Currency', type:i.type}));
          let items = res.data.data.inventoryItems.map(i => ({uuid:i.id, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? null : i.iconUrl), id : i.itemId, description: i.description,  name : i.name, status : false, rewardType: 'Items'}));
          let bundles = res.data.data.inventoryBundles.map(i => ({uuid: i.id, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? null : i.iconUrl), id : i.bundleId, name : i.name, status : false, rewardType: 'Bundle'}));
          let pm = res.data.data.progressionMarkers.map(i => ({uuid: i.id, iconUrl : ((i.iconUrl == null || i.iconUrl == '') ? null : i.iconUrl), id : i.progressionMarkerId, name : i.name, status : false, rewardType: 'PM'}));
          setItem(items)
          setCurrency(currencies)
          setBundle(bundles)
          setPM(pm)
      })
    }

    return (
        <>
            <Card className={cn(styles.card, className)} title="Information" classTitle="title-purple">
                    <div className={styles.description}>
                    
                        <div className={styles.preview}>
                            <div className={styles.previewImage}>
                                <SnapShot id='logo' url={progressionIcon} alt={displayName} placeholder={ProgressionSystem} type='Images' width={60} height={60} borderRadius={16}/>
                                {/* <img id='logo' alt={alt} style={{maxWidth: "100px", maxHeight: "100px"}}></img> */}
                            </div>
                            <div className={styles.previewInput}> 
                                <input type="file" className='file-input' accept="image/jpeg, image/png, image/webp, .jpeg, .jpg, .png, .webp" ref={ref} onChange={(e)=>{uploadLogo(e)}}/>
                                <label className={styles.button} onClick={() => setVisibleFile(true)}><AddButtonIcon className={styles.addIcon}/>Upload Icon</label>
                            </div>
                            {progressionIcon != null &&
                            <div className={styles.previewCancel}>
                                <button className={styles.buttonCancel} onClick={Cancel}>Remove</button>
                            </div>
                            }
                        </div>
                        <FileUploadSidebar media={media} setMedia={setMedia} types='Images' id={`icon`} multiple={false} visible={visibleFile} setVisible={setVisibleFile} icon={progressionIcon} setIcon={setProgressionIcon} setValue={setLogo} onClose={onClose} />
                        <div className={styles.group}>
                            <TextInput id='psName' className={styles.field} errorMessage={errors.nameError} tooltip={TooltipTitle.PSName} label="Display Name*" name="DisplayName" type="text" required value={displayName} onChange={(e)=>{e.target.value == '' ? setDisplayName('') : setDisplayName(e.target.value); errors.nameError = ''}} />
                            <TextInput isId={true} id='psId' className={styles.field} errorMessage={errors.idError} tooltip={TooltipTitle.PSID} label="Progression System ID*" name="progressionId" type="text" required value={psId} onChange={(e)=>{e.target.value == '' ? setPSID('') : setPSID(e.target.value); errors.idError = ''}}/>
                        </div>
                        <TextArea type= 'PROGRESSION_SYSTEM_DESCRIPTION' className={styles.field} rows={5} tooltip={TooltipTitle.PSDescription} label="Progression System Description" value={progressionDescription} onChange={(e)=>{setProgressionDescription(e.target.value)}}/>
                        <div className={styles.group}>
                            <div className={styles.fieldRadio}>
                                <div className={styles.radioLabel}>
                                    <span>Select Parameter<span><Tooltip className={styles.tooltip} title={TooltipTitle.PSParameter} icon="info"/></span></span>
                                </div>
                                <div className={styles.variants}>
                                    <Radio className={styles.radio} name="parameter" value={parameter} onChange={() => setParameter(true)} content="Progression Marker" />
                                    <Radio className={styles.radio} name="parameter" value={!parameter} onChange={() => setParameter(false)} content="Statistics" />
                                </div>
                            </div>
                            {parameter ?
                                <>
                                    <Dropdown id='pmName' errorMessage={errors.pmError} placeholder='Select Progression Marker*'  label="Choose Progression Marker" tooltip={TooltipTitle.PSPM} type={"progression marker"} options={progressionMarker} value={progression} setValue={setProgression} setId={setProgressionId} groupBy={2} />
                                </>
                            : ''}
                        </div>
                    </div>
                </Card>

                <Card className={cn(styles.card, className)} title="Type" classTitle="title-blue">
                    <div className={styles.cardDescription}>
                        <div className={styles.createProgressionSystem}>
                            <div className={styles.group}>
                                <div className={styles.fieldRadio}>
                                    <div className={styles.radioLabel}>
                                        <span>Progression System Type<span><Tooltip className={styles.tooltip} title={TooltipTitle.PSType} icon="info"/></span></span>
                                    </div>
                                    <div className={styles.variants}>
                                        <Radio className={styles.radio} name="PSType" value={system} onChange={() => setSystem(true)} content="Linear" />
                                        <Radio disabled={true} className={styles.radio} name="PSType" value={!system} onChange={() => setSystem(false)} content="Non-Linear" />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.group}>
                                <div className={styles.levelSystem}>
                                    <div className={styles.levelSystemGroup}>
                                        <div className={styles.levelLabel}>
                                            <span className={styles.levelLabelTxt}>Level</span><span className={styles.levelLabelImg} onClick={addLevels}><AddIcon /></span>
                                        </div>
                                        <div className={styles.configure}>
                                            <Control width="small" levelsDetail={levels} setter={data} className={styles.control} />
                                        </div>
                                        <label className={styles.pdUpload}>
                                            <div className={styles.previewInput}> 
                                              <input id='csv' type="file" className='file-input' accept=".xlsx, .xls, .csv" onChange={(e) => {onCSV(e)}}/>
                                              <label for='csv' className={styles.button}><Icon name='upload' size='20' /> CSV</label>
                                            </div>
                                        </label>
                                        <JsonControl data={levels} value={levels} setValue={setLevels}/>
                                    </div>
                                </div>
                            </div>

                            {levels && levels.length > 0 ?
                                <div className={styles.groupByThree}>
                                    <p></p>
                                    <p className={styles.fieldTitle}>Level Name</p>
                                    <p className={styles.fieldTitle}>Incremental Parameter Value</p>
                                    <p className={styles.fieldTitle}>Cumulative Parameter Value</p>
                                    <p className={styles.fieldTitle}>Rewards</p>
                                </div>
                            : ''} 

                            {levels && levels.filter(x => !x.archive).map((e,i)=>{
                                if(i < page*10 && i > (page-1)*10-1) {
                                return(
                                    <>
                                        <div key={i} className={styles.groupByThree}>
                                        <p className={styles.index}>{i+1}</p>
                                            <TextInput className={styles.field} type="text" key={i} value={e.name} onChange={(e)=> onChangeLevel(i, e.target.value) }/>
                                            <TextInput disabled={i == 0 ? true : false} className={styles.field} type="number" onWheelCapture={e => {e.currentTarget.blur()}} key={i} value={i== 0 ? 0 : e.incrementalParameterValue} onChange={(e) => onChangeIncrementalParameter(i, e.target.value)} onKeyDown={handleKeyDown}/>
                                            <TextInput className={styles.field} type="number" onWheelCapture={e => {e.currentTarget.blur()}} key={i} value={e.cumulativeParameterValue} disabled onKeyDown={handleKeyDown}/>
                                            <p className={styles.field}>
                                                {(Object.values(e.rewardDetails).flat().filter(item => !item.archive)?.length === 0 && i !== 0)?
                                                    <span key={i} onClick={() => {setVisibleHelp(true); setIndexLevel(e.no-1) ; setType('add')}}><AddIcon className={styles.icon}/></span> 
                                                : (Object.values(e.rewardDetails).flat().filter(item => !item.archive)?.length > 0 && i !== 0) ?
                                                    <div className={styles.levelRewards}>
                                                        <div className={styles.reward}>
                                                            {e.rewardDetails && [...e.rewardDetails?.currencies, ...e.rewardDetails?.items, ...e.rewardDetails?.bundles, ...e.rewardDetails?.progressionMarkers].filter(x => !x.archive).slice(0, 2).map((x, index) => {
                                                                return (<span><span>{index > 0 && ', '}</span><span>{x.name}</span><span>&nbsp;</span><span>({x.quantity})</span></span>)
                                                            })}
                                                        </div>
                                                        <div className={styles.editIcon} key={i} onClick={() => {setVisibleHelp(true); setIndexLevel(e.no-1) ; setType('edit')}}><EditIcon className={styles.icon}/></div>
                                                    </div>
                                                : ''}
                                            </p>
                                            <p className={styles.remove}>
                                                {i != 0 ? <span className={styles.removeIcon} onClick={()=>{removeLevel(e.no)}}><RemoveIcon /></span> : ''}
                                            </p>
                                        </div>
                                    </>
                                )}
                            })}
                            {/* {console.log(levels, 'Levels')} */}
                            <div>
                                <Modal heading={true} width={'medium'} visible={visibleHelp} onClose={() => setVisibleHelp(false)}>
                                    <AddContentModal amountKey="quantity" categoryIndex={indexLevel} onClose={() => setVisibleHelp(false)} type={"Task"} currency={currency} setCurrency={setCurrency} item={item} setItem={setItem} bundles={bundle} setBundle={setBundle} PM={pm} setPM={setPM} value={levels} setValue={setLevels} />
                                </Modal>
                            </div>
                            {levels.filter(x => !x.archive).length>10 && <div className={styles.pagination}>
                                <div className={styles.icons}>
                                    <button disabled={page <= 1 ? true : false}>
                                    <Previous className={styles.previous} disabled={true} onClick={onPrevious}/>
                                    </button>
                                    <span className={styles.pageno}>{page+"/"+ Math.ceil(levels.filter(x => !x.archive).length/10)}</span>
                                    <button disabled={levels.filter(x => !x.archive).length <= page*10 ? true : false}>
                                    <Next className={styles.next} onClick={onNext}/>
                                    </button>
                                </div>
                            </div>}
                        </div>
                    </div>
                </Card>

                <Card className={cn(styles.card, className)} title="Custom Data" classTitle="title-red">
                    <div className={styles.cardDescription}>
                        <TagInputWithDropdown tooltip={TooltipTitle.PSTags} tags={tags} setTags={setTags}/>
                        <div className={styles.createProgressionSystem}>
                            <div className={styles.groupHeading}>
                                <span>Meta Data</span><span><Tooltip className={styles.tooltip} title={TooltipTitle.PSMetaData} icon="info"/></span><span className={styles.addIcon} onClick={addMetaData}><AddIcon /></span>
                            </div>

                            {metaData && metaData.length > 0 &&
                                <div className={styles.groupMeta}>
                                    <div className={styles.field}>Key</div>
                                    <div className={styles.field}>Value</div>
                                </div>
                            }

                            <div className={styles.groupMeta}>
                                {metaData && metaData.map((e,i)=>{
                                    return(
                                        <>
                                            <TextInput className={styles.field} label="" name="key" type="text" required value={(metaData[i].key != '' || metaData[i].key != undefined || metaData[i].key != null) ? metaData[i].key : ''} onChange={(e)=>{onChangeKey(e, i)}}/>
                                            <JSONMetaDataValueInput key={i} className={styles.field} data={(typeof e.value == 'string') ? JSON.stringify(e.value) : e.value} value={metaData} setValue={setMetaData} index={i}/>
                                            {/* <TextInput className={styles.field} label="" name="value" type="text" required value={(metaData[i].value != '' || metaData[i].value != undefined || metaData[i].value != null) ? metaData[i].value : ''} onChange={(e)=>{onChangeValue(e, i)}}/> */}
                                            <span className={styles.remove} onClick={()=>{removeMetaData(i)}}><RemoveIcon className={styles.icon}/></span>
                                        </>
                                    )
                                })}
                            </div>

                        </div>
                    </div>
                </Card>

                <Card className={cn(styles.card, className)}>
                    <div className={styles.cardAction}>
                        <div className={styles.cardSave}>
                            <button className={cn(styles.button, 'button-save-small')} onClick={edit}>Save</button>
                        </div>
                        <div className={styles.cardCancel}>
                            <button className={cn(styles.button, 'button-cancel-small')} onClick={cancel}>Cancel</button>
                        </div>
                    </div>
                </Card>
        </>
    )
}

export default ProgressionSystemInfo