import React, { useContext, useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./otp.module.sass";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OtpInput from 'react-otp-input';
import Lottie from 'lottie-react'
import { getEmailVerificationAction, verifyEmailAction } from "../../redux/action/authenticate/authenticationAction";
import {ReactComponent as Gmail} from '../../media/images/icons/gmail.svg'
import {ReactComponent as Outlook} from '../../media/images/icons/outlook.svg'
import {ReactComponent as Yahoo} from '../../media/images/icons/yahoo.svg'
import GlobalStateContext from "../Context/GlobalStates/GlobalStateContext";

const Code = ({onClose, email, hideModal}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const globalStates = useContext(GlobalStateContext)

    const socialLogin = [{url : 'https://accounts.google.com/InteractiveLogin?continue=https://mail.google.com/mail/u/1/&emr=1&followup=https://mail.google.com/mail/u/1/&osid=1&passive=1209600&service=mail&ifkv=ASKXGp2jKpFmMGcbTE09R6bb7-R03_R0V3X7d2G5FcKGfyroZSm9yehTGF9FHryrAwSuTq6iJX9M', icon : <Gmail />, name : 'Gmail'}, {url : 'https://outlook.live.com/mail/0/?nlp=1', icon : <Outlook />, name : 'Outlook'}, {url : 'https://login.yahoo.com/?add=1&done=https%3A%2F%2Fwww.yahoo.com', icon : <Yahoo />,  name : 'Yahoo'}]

    const style = {
      width : 560,
      height : 608,
  }

    const [otp, setOtp] = useState();
    const [user, setUser] = useState()


    const getEmail = () => {
        let user = JSON.parse(sessionStorage.getItem('Information'))
        setUser(user)
    }

    useEffect(()=>{
        getEmail()
    }, [])

    const verifyAccount = () => {
      let userEmail = {
        email : email,
        code : parseInt(otp)
      }
      dispatch(verifyEmailAction(userEmail, navigate, hideModal, globalStates.setShowLoader, globalStates.setLoaderType))
      onClose()
    }

    const resendEmail = () => {
      let sendOtp = {
        email : email
      }
      dispatch(getEmailVerificationAction(sendOtp, navigate, hideModal))
  }

  const handleKeyDown = (e) => {
    if(e.key === 'Enter'){
      verifyAccount()
    }
  }

  return (
    <>
      <ToastContainer />
      <div className={styles.otpScreen}>
        <div className={styles.otp_outer_container}>
          <div className={styles.otp_inner_container}>
            <div className={styles.otpWrapper}>
              <div className={styles.wrapper}>
                <div className={styles.code}>
                  <div className={styles.body}>
                    <div className={styles.title}>Check Your Inbox<span className={styles.blueText}>!</span></div>
                    <div className={styles.info}>We've sent a verification code to your email. Just enter it below to confirm and you're all set.</div>
                    <div className={styles.fieldset}>
                      <div className={styles.field}>
                        <OtpInput inputType='number' inputStyle={styles.input} containerStyle={styles.fieldset} value={otp}
                        onChange={setOtp} numInputs={4} renderInput={(props) => <input {...props} onKeyDown={handleKeyDown}/>} />
                      </div>
                    </div>

                    <div className={styles.socialLogin}>
                      {socialLogin && socialLogin.map((e, i) => {
                        return (
                          <>
                            <a href={e.url} target="_blank">
                              <div className={styles.socialContainer}>
                                <div className={styles.socialIcon}>{e.icon}</div>
                                <div className={styles.socialContent}>{e.name}</div>
                              </div>
                            </a>
                          </>
                        )
                      })}
                    </div>

                    <div className={styles.verifyAccount}>
                      <button className={cn("button", styles.button)} onClick={verifyAccount}>
                        <span>Verify Email</span>
                      </button>
                    </div>
                    </div>
                    <div className={styles.resendLink}>
                      <span>Didn't receive an email? Check your spam folder or <span onClick={resendEmail} className={styles.blueText}>resend email.</span></span>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Code;
