import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import cn from 'classnames'
import styles from "./competitionToggle.module.sass"

const CompetitionToggle = ({className, classLabel, type, classDropdownHead, classDropdownLabel, value, setValue, options, label, small, upBody, tooltip}) => {
    
    const [visible, setVisible] = useState(false);

    const handleClick = (value) => {
        setValue(value.name)
        setVisible(false);
    };

    return(
        <>
            <div className={type == 'competition' ? styles.competition : type == 'event' ? styles.event : styles.competition}>
                <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
                {label && (
                    <div className={cn(classLabel, styles.label)}>
                    {label}{" "}
                    </div>
                )}
                <div className={cn(styles.dropdown, className, { [styles.small]: small },{[styles.active]: visible,})}>
                    <div className={cn(styles.head, classDropdownHead)} onClick={() => setVisible(!visible)}>
                        <div className={(value == 'Subscribed' || value === 'Live') ? styles.toggleGreen : styles.toggleRed}></div>
                        <div className={styles.selection}>{value}</div>
                    </div>
                    <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
                    {options && options.map((x, index) => (
                        <div onClick={() => handleClick(x, index)} className={cn(styles.option, {[styles.selectioned]: x === value,})} key={index}>
                            <div className={(x.name == 'Subscribed' || x.name === 'Live') ? styles.toggleGreen : styles.toggleRed}></div> {x.name}
                        </div>
                    ))}
                    </div>
                </div>
                </OutsideClickHandler>
            </div>
        </>
    )
}

export default CompetitionToggle