import React, { useState, useEffect } from "react";
import styles from "./row.module.sass";
import Checkbox from "../../Checkbox";
import Icon from "../../Icon";
import Control from "./Control";
import { Link, useParams } from "react-router-dom";
import {ReactComponent as Add} from '../../../media/images/icons/add_meta.svg'
import {ReactComponent as Remove} from '../../../media/images/icons/remove.svg'
import Currency from '../../../media/images/placeholders/Currency.png'
import TooltipGlodal from "../../TooltipGlodal";
import cn from "classnames";
import { useDispatch } from "react-redux";
import { extarctImageFromUrlAction } from "../../../redux/action/common/commonAction";
import SnapShot from "../../Images";

const Row = ({ item, className, access, value, onChange, index, type, setValue, act }) => {

    const {appid} = useParams()

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                    <div className={styles.item}>
                        {item.iconUrl !== undefined &&
                            <SnapShot id={`currencyIcon_${item.id}`} url={item.iconUrl} alt={item.name} placeholder={Currency} type='Images' width={36} height={36}/>
                        }
                        <div className={styles.details}>
                            <div>{item.name}</div>
                            <div className={styles.price}>{item.type == 'real' ? "RMG" : item.type == 'virtual' ? "Virtual" : ""}</div>
                            <div className={item.type == 'real' ? styles.real : styles.virtual }>{item.type}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.col}>
                    {item.type === 'real' &&
                        <Link to={act === 'Specter Bank' ? `/specter-bank/transactions/${appid}/${item.id}` :  `/edit-RMG/${appid}/${item.id}`}>
                            <TooltipGlodal width={180} itemId={`currencyName${item.id}`} title={item.name}><div className={styles.itemName}><span id={`currencyName${item.id}`}>{item.name}</span></div></TooltipGlodal>
                            <div className={styles.itemId}>{item.currencyId}</div>
                        </Link>
                    }
                    {item.type !== 'real' &&
                        <Link to={`/view-currency/${appid}/${item.id}`} state={{id : item.id, appid : appid}}>
                            <TooltipGlodal width={180} itemId={`currencyName${item.id}`} title={item.name}><div className={styles.itemName}><span id={`currencyName${item.id}`}>{item.name}</span></div></TooltipGlodal>
                            <div className={styles.itemId}>{item.currencyId}</div>
                        </Link>
                    }  
                </div>
                <div className={styles.col}><div className={item.archive == false ? styles.price : item.archive == true ? styles.archive : ''}>{ item.archive == false ? 'Active' : item.archive == true ? 'Archive' : ''}</div></div>
                <div className={styles.col}>{item.code}</div>
                <div className={styles.col}><div className={item.type == 'real' ? styles.real : item.type == 'virtual' ? styles.virtual : ''}>{item.type == 'real' ? "RMG" : item.type == 'virtual' ? "Virtual" : ""}</div></div>
                <div className={styles.col}>
                    {Object.keys(item).length !== 0 &&
                        <Control act={act} access={access} className={styles.control} appid={appid} id={item.id} status={item.archive} name={item.name} type={type} />
                    }
                </div>
            </div>
        </>
    );
};

export default Row;
